import { flowRight as compose } from "lodash";
import * as React from "react";
import moment from "moment";
import LoadingContainer from "components/LoadingContainer";
import Table, { TableProps } from "components/Table";
import Cell from "components/Table/Cell";
import Row from "components/Table/Row";
import Text from "components/LegacyText";
import Form from "components/Form";
import { withSearch, SearchContext } from "components/Search";
import TableFilters from "components/TableFilters";
import { Document } from "../../../interfaces/Document";
import styles from "./styles.scss";
import { DocumentLink } from "modules/Dashboard/Schema/Documents/DocumentLink";

export interface DocumentsQuery {
  documents: Document[];
}

interface Props extends SearchContext<Document> {
  data: {
    loading: boolean;
  } & DocumentsQuery;

  tableProps?: Partial<TableProps>;
}

const COLUMNS = [
  { label: "File name", size: 200 },
  { label: "Type", size: 80 },
  { label: "Upload date" },
  { label: "Description" },
  { label: "URL" },
];

function DocumentsTable({ data: { documents }, tableProps, search, onSearchChange, results }: Props) {
  if (!documents) {
    return <LoadingContainer message={"Loading your documents..."} />;
  }

  return (
    <React.Fragment>
      <TableFilters>
        <Form.TextBox
          label={"SEARCH"}
          placeholder={"by name, description, or url address"}
          onChange={onSearchChange}
          value={search}
        />
      </TableFilters>
      <Table
        columns={COLUMNS}
        visibleColumns={3}
        selectable={true}
        rowHeight={"small"}
        filter={results ? results.map(d => d.id) : undefined}
        {...tableProps}
      >
        {documents.map(document => {
          const isLink = !document.url.startsWith("https://cdn.filestackcontent.com/");

          return (
            <Row key={document.id} id={document.id} label={document.name}>
              <Cell size={200}>
                <Text.CellValue className={styles.documentName}>{document.name}</Text.CellValue>
              </Cell>
              <Cell size={80}>
                <Text.CellValue>{isLink ? "URL" : "Doc"}</Text.CellValue>
              </Cell>
              <Cell>
                <Text.CellValue>{moment(document.createdAt).format("MM/DD/YYYY")}</Text.CellValue>
              </Cell>
              <Cell>
                <Text.CellValue>{document.description}</Text.CellValue>
              </Cell>
              <Cell>
                <DocumentLink document={document} />
              </Cell>
            </Row>
          );
        })}
      </Table>
    </React.Fragment>
  );
}

export default compose(
  withSearch<Document, Props>({
    mapPropsToItems: props => props.data.documents,
    keys: ["name", "description", "url"],
  }),
)(DocumentsTable);
