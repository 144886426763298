import * as React from "react";

import fileIcon from "assets/file.svg";
import { colors } from "styles/variables";

import Icon from "components/Icon";
import Text from "components/LegacyText";

import * as FieldValue from "../FieldValue";

export default function ProgramLogistics() {
  return (
    <React.Fragment>
      <FieldValue.Text name="demoKit.client" label="Client provides" />
      <FieldValue.Option name="demoKit.shippingRequirements" label="Shipping" />
      <FieldValue.Text name="demoKit.agency" label="Partner provides" />
      <FieldValue.Text name="expenses" label="Expenses" />

      <FieldValue.Base label={"Program Files"}>
        {({ internalFiles }) =>
          internalFiles?.length > 0 ? (
            <div>
              {internalFiles.map((file: { url: string; caption: string }) => (
                <div style={{ display: "flex", gap: "10px" }}>
                  <Icon size={10} src={fileIcon} fill={colors.blue2} />

                  <Text.NewLink4 href={`${file.url}`} target="_blank">
                    {file.caption ?? "File"}
                  </Text.NewLink4>
                </div>
              ))}
            </div>
          ) : null
        }
      </FieldValue.Base>
    </React.Fragment>
  );
}
