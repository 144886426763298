// @deprecated Use Form.FilePicker instead

import * as React from "react";
import _ from "lodash";
import * as filestack from "filestack-js";
import cx from "classnames";
import Text from "components/LegacyText";
import Icon from "components/Icon";
import ImageOpener from "components/ImageGroup/ImageOpener";
import Attachment from "assets/attachment.svg";
import Cancel from "assets/cancel.svg";
import { transformImage, FS_API_KEY } from "utilities/filestack";
import styles from "./styles.scss";
import { ExifRestorer } from "utilities/exif";

type ImageUrl = string;
export interface OptimisticImage {
  promise: Promise<ImageUrl> | null;
  result: ImageUrl;
}

interface Props {
  value?: any;
  label?: string | null;
  uploadValue?: boolean | null;
  buttonClassName?: string;
  imageClassName?: string;
  onChange?: (x: any) => any;
  onChangeOptimistic?: (x: any) => any;
  onProgress?: (param0: number) => any;
}

interface State {
  currentImage?: ImageUrl | null;
  loading: boolean;
  token: { cancel?: () => void };
  error: string | null;
  uploadProgress: number;
}

const takingTooLongError =
  "Hmm, it looks like this is taking too long. Please check your internet connection and try again.";

function compressImage(file: any): Promise<any> {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  const img = new Image();
  const reader: any = new FileReader();

  const maxSize = 1080;
  const promise = new Promise(resolve => {
    reader.onloadend = () => {
      img.onload = (event: any) => {
        let newHeight, newWidth;
        if (img.width > img.height) {
          newWidth = maxSize;
          newHeight = Math.floor((img.height / img.width) * maxSize);
        } else {
          newHeight = maxSize;
          newWidth = Math.floor((img.width / img.height) * maxSize);
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        const newImageUrl = canvas.toDataURL(file.type);

        resolve(ExifRestorer.restore(event.target.currentSrc, newImageUrl));
      };
      img.src = reader.result;
    };
  });

  reader.readAsDataURL(file);
  return promise;
}

const Loader = ({ progress }: { progress: number }) => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default class ImageSelector extends React.Component<Props, State> {
  readonly state: State = {
    currentImage: this.props.value || null,
    loading: false,
    token: {},
    error: null,
    uploadProgress: 0,
  };

  public UNSAFE_componentWillReceiveProps({ value }: Props) {
    if (value !== this.state.currentImage) {
      this.setState({ currentImage: value });
    }
  }

  public componentDidMount() {
    const { value, uploadValue, onChangeOptimistic } = this.props;
    if (uploadValue && value) {
      const token = {};
      const uploadImage = this.uploadImage(value, token);

      if (onChangeOptimistic) {
        onChangeOptimistic({ promise: uploadImage, result: value });
      }
    }
  }
  public cancelUpload = () => {
    const cancel = this.state.token.cancel || _.noop;
    cancel();
    this.setState({ loading: false, token: {}, error: null });
  };
  public uploadImage = (file: Blob | ImageUrl, token: any) => {
    const onProgress = ({ totalPercent }: { totalPercent: number }) => {
      this.props.onProgress && this.props.onProgress(totalPercent);
      this.setState({ uploadProgress: Math.max(totalPercent, 5) });
    };

    return filestack
      .init(FS_API_KEY)
      .upload(file, { onProgress }, {}, token)
      .then(({ url }) => {
        return url;
      })
      .catch(console.error);
  };
  public handleUpload = async (event: any) => {
    const { onChange, onChangeOptimistic } = this.props;
    const token = {};
    const file = event.target.files[0];

    this.setState({ loading: true, error: null, uploadProgress: 5, token });

    const compressedFile = await compressImage(file);
    const uploadImage = this.uploadImage(compressedFile, token);

    const compressedUrl = "data:image/jpeg;base64," + compressedFile;

    if (onChangeOptimistic) {
      this.setState({ currentImage: compressedUrl, loading: false, error: null, token: {} });
      onChangeOptimistic({ promise: uploadImage, result: compressedUrl });
      return;
    }

    const takingTooLong = setTimeout(() => this.setState({ error: takingTooLongError }), 30000);
    uploadImage
      .then(url => {
        clearTimeout(takingTooLong);
        this.setState({ currentImage: url, loading: false, error: null, token: {} });
        if (onChange) onChange(url);
      })
      .catch(error => {
        if (error.message !== "Upload cancelled") {
          this.setState({
            loading: false,
            token: {},
            error: "Uh oh, something went wrong. Please try again.",
          });
        }
      });
  };
  public clearImage = (event: React.MouseEvent) => {
    const { onChangeOptimistic, onChange } = this.props;
    if (onChangeOptimistic) {
      onChangeOptimistic(null);
    } else if (onChange) {
      onChange(null);
    }
    this.setState({ currentImage: null });

    event.stopPropagation();
  };

  public renderButton() {
    return (
      <label className={this.props.buttonClassName}>
        <span className={styles.button}>
          <span className={styles.attachment}>
            <Icon size={16} src={Attachment} fill={"white"} />
          </span>
          <Text.Button kind="reverse">Upload Image</Text.Button>
        </span>
        <Text.ErrorMessage>{this.state.error}</Text.ErrorMessage>
        <input type="file" accept="image/*, image/heic" style={{ display: "none" }} onChange={this.handleUpload} />
      </label>
    );
  }

  public renderLoading() {
    return [
      <div key="loading" className={cx(styles.imageContainer, styles.loading, this.props.imageClassName)}>
        <span className={cx(styles.x, styles.adjustUp)} onClick={this.cancelUpload}>
          <Icon medium src={Cancel} />
        </span>
        <Loader progress={this.state.uploadProgress} />
      </div>,
      <Text.ErrorMessage key="error">{this.state.error}</Text.ErrorMessage>,
    ];
  }

  public renderImage({ currentImage }: { currentImage: string }) {
    // TODO -- this can probably be DRYed up.
    if (currentImage.match(/^http/)) {
      const resizedImage =
        currentImage &&
        transformImage(currentImage, {
          resize: { fit: "crop", align: "center", width: "300" },
        });

      return (
        <ImageOpener
          src={currentImage}
          caption={""}
          className={cx(styles.imageContainer, this.props.imageClassName)}
          style={resizedImage && { backgroundImage: `url(${resizedImage})` }}
        >
          <span className={styles.x} onClick={this.clearImage}>
            <Icon medium src={Cancel} />
          </span>
        </ImageOpener>
      );
    } else {
      return (
        <ImageOpener src={currentImage} caption={""} className={cx(styles.imageContainer, this.props.imageClassName)}>
          <div className={styles.absoluteImageContainer}>
            <img src={currentImage} style={{ width: 300 }} alt="Uploaded" />
          </div>
          <span className={styles.x} style={{ position: "absolute" }} onClick={this.clearImage}>
            <Icon medium src={Cancel} />
          </span>
        </ImageOpener>
      );
    }
  }

  public renderSelector() {
    const { loading, currentImage } = this.state;
    if (loading) return this.renderLoading();
    else if (currentImage) return this.renderImage({ currentImage });
    else return this.renderButton();
  }

  public render() {
    const { label } = this.props;
    return label ? (
      <div className={styles.imageSection}>
        <div className={styles.imageTitle}>
          <Text.Label2>{label}</Text.Label2>
        </div>
        {this.renderSelector()}
      </div>
    ) : (
      this.renderSelector()
    );
  }
}

function replace<T>(array: T[], ix: number, value: T | null): Array<T | null> {
  // @ts-ignore: ¯\_(ツ)_/¯ I don't feel like dealing with this
  // We shouldn't use this component anyway
  return ix < array.length ? [...array.slice(0, ix), value, ...array.slice(ix + 1)] : array.concat(value);
}

interface ImageSelectorsProps {
  value: ImageUrl[] | null;
  valueOptimistic: OptimisticImage[] | null;
  onChange?: (param0: ImageUrl[]) => any;
  onChangeOptimistic?: (param0: OptimisticImage[]) => any;
  errorMessage?: string | null;
  label?: string | null;
}

export class ImageSelectors extends React.Component<ImageSelectorsProps> {
  public renderImageSelectorsPessimistic({
    value: _value,
    onChange,
  }: {
    value: ImageUrl[] | null;
    onChange: (param0: ImageUrl[]) => any;
  }) {
    const value = _value || [];
    return value
      .map((image, ix) => (
        <ImageSelector
          imageClassName={styles.bottomSpace}
          key={ix}
          value={image}
          onChange={(url: any) => onChange(_.compact(replace(value, ix, url)))}
        />
      ))
      .concat(
        <ImageSelector
          imageClassName={styles.bottomSpace}
          key={value.length}
          onChange={(url: any) => onChange(_.compact(value.concat(url)))}
        />,
      );
  }

  public renderImageSelectorsOptimistic({
    valueOptimistic,
    onChangeOptimistic,
  }: {
    valueOptimistic: OptimisticImage[] | null;
    onChangeOptimistic: (param0: OptimisticImage[]) => any;
  }) {
    const values = valueOptimistic || [];

    return values
      .map((optimisticValue, ix) => (
        <ImageSelector
          imageClassName={styles.bottomSpace}
          key={ix}
          uploadValue={!optimisticValue.promise}
          value={optimisticValue.result}
          onChangeOptimistic={(optimistic: any) => onChangeOptimistic(_.compact(replace(values, ix, optimistic)))}
        />
      ))
      .concat(
        <ImageSelector
          imageClassName={styles.bottomSpace}
          key={values.length}
          onChangeOptimistic={(optimistic: any) => onChangeOptimistic(_.compact(values.concat(optimistic)))}
        />,
      );
  }

  public renderSingleImageSelector({
    onChange,
    onChangeOptimistic,
  }: {
    onChange?: (param0: ImageUrl[]) => any;
    onChangeOptimistic?: (param0: OptimisticImage[]) => any;
  }) {
    if (onChange)
      return (
        <ImageSelector
          imageClassName={styles.bottomSpace}
          onChange={(url: any) => onChange && onChange(_.compact([url]))}
        />
      );
    else if (onChangeOptimistic)
      return (
        <ImageSelector
          imageClassName={styles.bottomSpace}
          onChangeOptimistic={(deferred: any) => onChangeOptimistic && onChangeOptimistic(_.compact([deferred]))}
        />
      );
  }

  public renderImageSelectors() {
    const { value, valueOptimistic, onChange, onChangeOptimistic } = this.props;

    if ((!Array.isArray(value) || !value.length) && (!Array.isArray(valueOptimistic) || !valueOptimistic.length)) {
      return this.renderSingleImageSelector({ onChange, onChangeOptimistic });
    }

    if (valueOptimistic && onChangeOptimistic) {
      return this.renderImageSelectorsOptimistic({ valueOptimistic, onChangeOptimistic });
    } else if (value && onChange) {
      return this.renderImageSelectorsPessimistic({ value, onChange });
    }
  }

  public render() {
    const { errorMessage, label } = this.props;

    return (
      <div className={styles.imageSection}>
        <div className={styles.imageTitle}>
          <Text.Label2>{label}</Text.Label2>
        </div>
        {this.renderImageSelectors()}
        <Text.ErrorMessage>{errorMessage}</Text.ErrorMessage>
      </div>
    );
  }
}
