import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Icon from "components/Icon";
import Text from "components/Text";
import Clickable from "components/Clickable";
import Popup from "components/Popup";
import filterIcon from "assets/filter.svg";
import backspaceIcon from "assets/backspace.svg";
import variables from "styles/variables";
import { Column, FilterDefinition } from "../../types";
import styles from "./styles.scss";
import { TableMode } from "..";
import { useFilterCollectionCtx } from "modules/Connection/FilterCollection";

interface Props<T> {
  column: Column<T>;
  mode: TableMode;
  setFiltersDraftState: (filterId: string) => (state: any) => void;
}

export function ColumnHeader<T>({ column, mode, setFiltersDraftState }: Props<T>) {
  const { t } = useTranslation();
  const label = t(`${column.translationPath}.columns.${column.id}.name`);

  const [open, setOpen] = useState(false);
  const togglePopup = () => setOpen(!open);

  const { state, filters, clear, setFilterState } = useFilterCollectionCtx();

  const filter = filters[column.filterId];
  const filterState = state[column.filterId];

  const active = open || filterState;

  const isCompact = mode === "compact";
  const headerBody = (
    <>
      <div className={styles.icon}>
        <Icon size={isCompact ? 25 : 15} src={column.cell.icon} fill={variables.white} />
      </div>
      <Text
        font="wes"
        bold
        size={isCompact ? 14 : 10}
        color={"white"}
        left={"xs"}
        weight={900}
        letterSpacing={1.2}
        className={styles.title}
        noSelect
      >
        {label.toUpperCase()}
      </Text>
      <div className={"spacer"} />
      {filter && (
        <>
          {!isCompact && (
            <div className={active ? styles.expandedFilterIcon : styles.filterIcon}>
              <Icon size={8.5} src={filterIcon} fill={variables.teal1} />
            </div>
          )}
          {!open && (
            <Clickable
              actionLabel={t("components.table.clearFilter", { column: label })}
              className={cx(
                filterState ? styles.clearIconVisible : styles.clearIcon,
                isCompact && styles.clearIconInitial,
              )}
              onClick={e => {
                clear([column.id]);
                if (!open && filterState) e.stopPropagation();
              }}
            >
              <Icon size={isCompact ? 20 : 16} src={backspaceIcon} fill={variables.white} />
            </Clickable>
          )}
        </>
      )}
    </>
  );

  type F = typeof filter;

  const header = (
    <div
      className={cx(styles.columnHeaderBody, isCompact && styles.compactColumnHeaderBody, isCompact && styles.compact)}
      data-test={`workflow.filter.${column.id}`}
    >
      {isCompact ? (
        <Clickable
          onClick={() => setOpen(!open)}
          className={cx(styles.compactHeaderBody, isCompact && active && styles.activeCompact)}
        >
          {headerBody}
        </Clickable>
      ) : (
        headerBody
      )}
      {isCompact && (
        <div className={open ? styles.open : styles.closed}>
          <FilterWrapper<F>
            filter={filter!}
            close={() => setOpen(false)}
            clear={() => clear([column.id])}
            state={filterState || null}
            setState={filterState => {
              setFilterState(column.filterId, filterState);
            }}
            mode={mode}
            setFiltersDraftState={setFiltersDraftState(column.filterId)}
          />
        </div>
      )}
    </div>
  );

  if (filter && !isCompact) {
    return (
      <Popup
        body={props => (
          <FilterWrapper<F>
            {...props}
            filter={filter!}
            clear={() => clear([column.filterId])}
            state={filterState || null}
            setState={filterState => {
              setFilterState(column.filterId, filterState);
            }}
            mode={mode}
          />
        )}
        onOpen={togglePopup}
        onClose={togglePopup}
        childrenWrapperProps={{
          className: styles.columnHeader,
          role: "columnheader",
        }}
      >
        {header}
      </Popup>
    );
  }

  return (
    <div
      className={cx(styles.columnHeader, isCompact && styles.compactColumnHeader, isCompact && styles.ieHeader)}
      role="columnheader"
    >
      {header}
    </div>
  );
}

interface SetInternalState<F> {
  (partialState: Partial<F>, clear?: false): void;
  (state: F, clear: true): void;
}

type FilterWrapperProps<F> = {
  filter: FilterDefinition<F>;
  state: F;
  close: () => void;
  clear: () => void;
  setState: (state: F) => void;
  mode: TableMode;
  setFiltersDraftState?: (state: any) => void;
};

export function FilterWrapper<F>({
  state,
  setState,
  close,
  clear,
  filter,
  mode,
  setFiltersDraftState,
}: FilterWrapperProps<F>) {
  const [internalState, _setInternalState] = useState<F>(state || filter.initialState());

  const setInternalState: SetInternalState<F> = (state: any, clear?: boolean) => {
    const newState = clear ? state : Object.assign({}, internalState, state);
    if (setFiltersDraftState) {
      setFiltersDraftState(newState);
    }
    _setInternalState(newState);
  };

  const apply = () => {
    setState(internalState);
    close();
  };

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        apply();
      }}
    >
      {React.createElement(filter.component, {
        state: internalState,
        setState: setInternalState,
        close,
        clear: () => {
          setInternalState(filter.initialState());
          clear();
        },
        apply,
        mode,
      })}
    </form>
  );
}
