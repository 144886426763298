import React, { Component } from "react";
import { Route, Switch, Redirect, RouteComponentProps, match } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";
import { flowRight as compose } from "lodash";

import gigsIcon from "assets/create-gig.svg";
import homeIcon from "assets/home.svg";
import reportsIcon from "assets/data.svg";
import financialsIcon from "assets/financials.svg";
import calendarIcon from "assets/calendar-range.svg";

import { isHayday, isPinata } from "utilities/flavor";
import Shortcuts from "components/Shortcuts";
import Text from "components/LegacyText";
import { Mobile } from "components/Layout/Responsive";
import Button from "components/Button";
import DotDotDot from "components/DotDotDot";
import {
  getUrlQueries,
  getProgramIdFromSearch,
  stringifyQuery,
  omitQueries,
  USER_ORGANIZATION_PLACEHOLDER,
} from "utilities/routes";
import LoadingContainer from "components/LoadingContainer";
import { UserInfoQuery, withUserInfoNoCache } from "modules/Dashboard/UserInfo";
import { BookAGig } from "modules/Dashboard/Schema/Gigs/Create";
import { GigsUploader } from "modules/Dashboard/Schema/Gigs/Upload";
import { UpdateGigs } from "modules/Dashboard/Schema/Gigs/Update";
import GigDetailsModal from "modules/Dashboard/Schema/Gigs/Details";
import { GigRoster } from "modules/Dashboard/Schema/Gigs/Roster";
import { isUserAtLeast } from "interfaces/user";

import { Program } from "../../interfaces/Program";
import BigSelect from "./BigSelect";
import ErrorPage from "../../../../components/ErrorPage";
import SelectProgramModal from "./SelectProgramModal";
import { OrganizationRouteParams } from "../index";
import NavHeader, { Tab } from "../NavHeader";
import DashboardCollection from "./DashboardCollection";
import CreateProgramRequestModal from "./CreateProgramRequestModal";
import ProgramCreation from "./Creation";
import ProgramOverview from "./Overview";

import styles from "./styles.scss";

import programsQuery from "./programsQuery.gql";
import userOrganizationByProgramsQuery from "./userOrganizationByProgramsQuery.gql";

const Gigs = React.lazy(() => import("./Gigs"));

interface ContinueParams {
  placeholder: string;
  value: string;
}

export const programsRouteName = isHayday ? "groups" : "programs";

export interface ProgramsRouteParams extends OrganizationRouteParams {
  programGroupId: string;
}

type Props = RouteComponentProps<ProgramsRouteParams> &
  UserInfoQuery & {
    me: {
      isAdmin?: boolean;
      programs: Program[];
    };
    userOrganizationByPrograms: {
      id: string | null;
    };
    myPrograms: Program[];

    loading?: boolean;
    error: ApolloError;
    refetch: () => Promise<{}>;
    organization?: {
      id: string;
      bulkUploaderActive: boolean;
    };
  };

class Programs extends Component<Props> {
  private openModal = (name: "select" | "book-gigs" | "book-gigs-like-a-boss") => {
    if (!this.isModalOpen()) {
      this.props.history.push({
        pathname: this.props.location.pathname + "/+" + name,
        search: omitQueries(this.props.location.search, ["goto", "from", "date"]),
      });

      return false;
    }
  };

  private shortcuts = [{ combo: ["command+k", "ctrl+k"], handler: () => this.openModal("select") }];

  // Disabled because of #516
  // newProgram = () => {
  //   const { history, match } = this.props;
  //   history.push(`/${match.params.orgId || ""}/programs/${getNewProgramId()}/request`);
  // };

  private isModalOpen() {
    return this.props.location.pathname.includes("/+");
  }

  private getTabs(isNew: boolean) {
    const { match, userInfo } = this.props;
    const disabled = isNew ? "You need to submit your program first" : null;
    const me = userInfo?.me;

    const tabs = [
      isPinata || me?.isAdmin
        ? {
            name: "Overview",
            link: `${match.url}/${isNew ? "request" : "overview"}`,
            icon: homeIcon,
            disabled: null,
          }
        : undefined,
      isPinata
        ? {
            name: "Calendar",
            link: `${match.url}/gigs/calendar`,
            disabled,
            icon: calendarIcon,
          }
        : undefined,
      isPinata
        ? {
            name: "Tasks",
            link: `${match.url}/gigs`,
            disabled,
            icon: gigsIcon,
          }
        : undefined,
      {
        name: "Reports",
        link: `${match.url}/reports`,
        disabled,
        icon: reportsIcon,
      },
      isPinata
        ? {
            name: "Financials",
            link: `${match.url}/financials`,
            disabled,
            icon: financialsIcon,
          }
        : undefined,
    ].filter(x => x) as Tab[];

    return tabs.length === 1 ? [] : tabs;
  }

  // Really ugly, hacky way to redirect a URL without org to one having a good fitting
  // also, error case of no appropriate org could be found is still missing
  public componentDidUpdate(prevProps: Props) {
    const { userOrganizationByPrograms, history, location } = this.props;
    if (
      userOrganizationByPrograms &&
      userOrganizationByPrograms.id &&
      (!prevProps.userOrganizationByPrograms ||
        userOrganizationByPrograms.id !== prevProps.userOrganizationByPrograms.id)
    ) {
      const path = location.pathname.replace(USER_ORGANIZATION_PLACEHOLDER, userOrganizationByPrograms.id);
      history.replace(path + location.search);
    }
  }

  private closeModal = (modalMatch: match) => (modalParams: string[] = [], continueParams: ContinueParams) => {
    const { history, location } = this.props;
    const params = getUrlQueries(location.search);

    if (params.continue) {
      let continueUrl = params.continue;

      if (continueParams) {
        continueUrl = continueUrl.replace(continueParams.placeholder, continueParams.value);
      }

      history.push(continueUrl);
    } else {
      history.push({
        search: stringifyQuery(_.omit(params, modalParams)),
        pathname: modalMatch.url.split("/+")[0],
      });
    }
  };

  private wrapModal(Component: any) {
    return (props: RouteComponentProps) => <Component {...props} handleClose={this.closeModal(props.match)} />;
  }

  public render() {
    const { match, location, error, userInfo, refetch, myPrograms } = this.props;
    const me = userInfo?.me;
    const id = getProgramIdFromSearch(location.search);
    const isAdmin = me?.isAdmin;

    const loading = id !== "all" && (this.props.loading || !me);

    if (error) {
      console.error({ error });
      return <ErrorPage error={error} retry={refetch} retryAutomatically={true} />;
    }

    const programs = myPrograms;

    let errorMessage;
    let isNew = false;
    let title: React.ReactNode;

    if (error) {
      title = "Error";
    } else if (id === "all") {
      title = "All My Programs";
    } else if (programs) {
      if (programs.length) {
        title = programs.length > 1 ? `${programs.length} ${"Programs"} Selected` : programs[0].name;
        isNew = !!programs.find(p => p.managementState !== "accepted");
      } else if (!location.pathname.includes("/request")) {
        title = "Unknown Program(s)";
        errorMessage = (
          <div className={styles.empty}>
            <Text.Display2 className={styles.emptyTitle}>LOADING ...</Text.Display2>
            <Text.P2 className={styles.emptySubtitle}>Click the button below to select another</Text.P2>
            <Button kind={"primary"} onClick={() => this.openModal("select")}>
              PROGRAM
            </Button>
          </div>
        );
      } else {
        title = "New Program";
        isNew = true;
      }
    }

    if (loading) {
      title = <DotDotDot ticks={5} />;
    }

    const includeAcceptedRoutes = loading || !isNew;

    if (match && match.params && match.params.orgId === USER_ORGANIZATION_PLACEHOLDER) {
      return <LoadingContainer message="Looking up your organization ..." />;
    }

    const orgUser = userInfo?.me?.organizationUser;

    return (
      <Shortcuts shortcuts={this.shortcuts} enabled={!this.isModalOpen()}>
        <NavHeader
          action={
            isPinata
              ? {
                  text: "CREATE TASKS",
                  onClick: e => this.openModal(e.altKey && isAdmin ? "book-gigs-like-a-boss" : "book-gigs"),
                  testId: "programs.header.createGigs",
                }
              : undefined
          }
          tabs={this.getTabs(isNew)}
        >
          <Mobile>
            {mobile => (
              <BigSelect
                name={isHayday ? "Check In Group" : "Program"}
                useText={true}
                onClick={() => this.openModal("select")}
                compact={mobile}
                className={styles.compactFlex}
                testId={"navHeader.programSelector"}
              >
                {title}
              </BigSelect>
            )}
          </Mobile>
        </NavHeader>

        {errorMessage}

        <React.Suspense fallback={<LoadingContainer />}>
          <Switch>
            {(loading || isNew) && (isPinata || (isHayday && isAdmin)) && (
              <Route
                path={match.path + `/request`}
                render={props => <ProgramCreation onCreate={refetch} {...props} />}
              />
            )}

            {((includeAcceptedRoutes && isPinata) || (isHayday && isAdmin)) && (
              <Route path={match.path + "/overview"} component={ProgramOverview} />
            )}

            {includeAcceptedRoutes && (
              <Route
                path={match.path + "/reports"}
                render={props => <DashboardCollection {...props} collection={"reports"} />}
              />
            )}

            {includeAcceptedRoutes && isPinata && (
              <Route
                path={match.path + "/financials"}
                render={props => <DashboardCollection {...props} collection={"financials"} />}
              />
            )}

            {includeAcceptedRoutes && isPinata && <Route path={match.path + "/gigs"} component={Gigs} />}

            {!loading && !errorMessage && (
              <Redirect
                to={{
                  pathname: match.url + (isHayday ? "/reports" : isNew ? "/request" : "/gigs"),
                  search: location.search,
                }}
              />
            )}
          </Switch>
          <Route path={`${match.path}/:page*/+select`} component={SelectProgramModal} />
          {isHayday && isAdmin && (
            <Route
              path={`${match.path}/:page*/+create-check-in-group`}
              component={this.wrapModal(CreateProgramRequestModal)}
            />
          )}
          {isPinata && (
            <>
              <Route
                path={`${match.path}/:page*/+create-program`}
                component={this.wrapModal(CreateProgramRequestModal)}
              />

              <Route path={`${match.path}/:page*/+book-gigs`} component={BookAGig} />
              {orgUser && isUserAtLeast({ organizationUser: orgUser }, "manager") && (
                <Route path={`${match.path}/:page*/+gigs-bulk-uploader/:programId`} component={GigsUploader} />
              )}
              <Route path={`${match.path}/:page*/+book-gigs-like-a-boss`} component={GigsUploader} />
              <Route path={`${match.path}/:page*/+update-gigs/:variables`} component={UpdateGigs} />
              <Route path={`${match.path}/:page*/+open-to/:variables`} component={GigRoster} />
              <Route path={`${match.path}/:page*/+send-to/:variables`} component={GigRoster} />
              <Route path={`${match.path}/:page*/+view/:gigId`} component={GigDetailsModal} />
              <Switch>
                {/** Workflow v1 to v2 redirects */}

                <Redirect from={`${match.path}/:page*/+edit-gigs/:gigId`} to={`${match.path}/gigs/+view/:gigId`} />

                <Redirect
                  from={`${match.path}/:page*/+duplicate-gig/:gigId`}
                  to={`${match.path}/gigs/+view/:gigId/duplication`}
                />

                <Redirect
                  from={`${match.path}/:page*/+assign-gigs/:gigId`}
                  to={`${match.path}/gigs/+view/:gigId/assignment`}
                />

                <Redirect
                  from={`${match.path}/:page*/+cancel-gigs/:gigId`}
                  to={`${match.path}/gigs/+view/:gigId/cancellation`}
                />

                <Redirect
                  from={`${match.path}/:page*/+approve-gig/:gigId`}
                  to={`${match.path}/gigs/+view/:gigId/approval`}
                />
                <Redirect
                  from={`${match.path}/:page*/+verify-gig/:gigId`}
                  to={`${match.path}/gigs/+view/:gigId/verification`}
                />

                <Redirect from={`${match.path}/:page*/+gig-report/:gigId`} to={`${match.path}/gigs/+view/:gigId`} />
                <Route />
              </Switch>
            </>
          )}
        </React.Suspense>
      </Shortcuts>
    );
  }
}

export default compose(
  graphql<any, any, any, any>(programsQuery, {
    skip: ({
      match: {
        params: { orgId },
      },
      location: { search },
    }) => {
      const id = getProgramIdFromSearch(search);
      return id === "all" || orgId === USER_ORGANIZATION_PLACEHOLDER;
    },
    options({
      match: {
        params: { orgId },
      },
      location: { search },
    }) {
      const id = getProgramIdFromSearch(search);
      const ids = id.split(",");
      return {
        variables: {
          ids,
          includeUnaccepted: !!(ids && ids.length === 1),
          includeArchived: !!(ids && ids.length === 1),
          orgId,
        },
      };
    },
    props: ({ data }) => ({ myPrograms: data?.me?.programs || [] }),
  }),

  graphql<any, any, any, any>(userOrganizationByProgramsQuery, {
    skip: ({
      match: {
        params: { orgId },
      },
    }) => orgId !== USER_ORGANIZATION_PLACEHOLDER,
    options({
      match: {
        params: { orgId },
      },
      location: { search },
    }) {
      const id = getProgramIdFromSearch(search);
      const programIds = id === "all" ? undefined : id.split(",");
      return {
        variables: {
          programIds,
          orgId,
        },
      };
    },
    props: ({ data }) => data,
  }),
  withUserInfoNoCache,
)(Programs);
