import React from "react";
import { FieldProps } from "formik";
import styles from "./styles.scss";
import cx from "classnames";
import variables from "styles/variables";

export function WindowAssign({
  form,
  className,
  darkBackground,
  onChange,
}: FieldProps & { className?: string; darkBackground?: boolean; onChange?: (checked: boolean) => void }) {
  const id = "windowAssignSwitch";
  const color = !!darkBackground ? variables.white : variables.gray1;

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.switch} htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          onChange={() => {
            form.setFieldValue("windowAssign", !form.values.windowAssign);
            onChange && onChange(!form.values.windowAssign);
          }}
          checked={!!form.values.windowAssign}
        />
        <div className={cx(styles.slider, styles.round)} />
      </label>
      <p className={styles.labelText} style={{ color }}>
        This task can happen at any time on the scheduled date
      </p>
    </div>
  );
}
