import { useContext, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { IAction } from "modules/Connection/types";
import downloadIcon from "assets/download.svg";
import Text from "components/Text";
import { colors } from "styles/variables";
import downloadReceipts from "./downloadReceipts.gql";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { DownloadsCtx } from "modules/Dashboard/Downloads";
import { format } from "date-fns";
import { ActionBody } from "modules/Connection/ActionBody";
import React from "react";
import { useTranslation } from "react-i18next";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = "gigs.actions.downloadReceipts";

export const DownloadReceiptsAction: IAction = {
  key: "downloadReceipts",
  icon: downloadIcon,
  labelKey: `${i18n}.label`,
  color: colors.blue2,
  allowInline: true,
  allowBatch: true,
  multiSelectWarning: false,

  component: ({ variables, done, renderSubmit }) => {
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);
    const { t } = useTranslation();

    const apollo = useApolloClient();

    const downloads = useContext(DownloadsCtx);
    const [status, setStatus] = useState<"loading" | "error" | "noReceipts" | "standby">("standby");

    const run = () => {
      setStatus("loading");

      apollo
        .query({
          query: downloadReceipts,

          variables: {
            ...variables,
            programIds: id === "all" ? [] : id.split(","),
          },
        })
        .then(({ data }: any) => {
          let index = 1;

          if (data.gigReceiptsZipUrl.length === 0) {
            setStatus("noReceipts");
            return;
          }

          for (const url of data.gigReceiptsZipUrl) {
            downloads.createFile(`receipts-${format(new Date(), "MM-dd-yy")}-batch-${index++}.zip`, {
              pollUntilType: "application/x-zip-compressed",
              url,
            });
          }

          done();
        })
        .catch((error: any) => {
          setStatus("error");
          throw error;
        });
    };

    return (
      <ActionBody done={done}>
        <Text color="white" top="xs" bottom="s" size={14}>
          {t(`${i18n}.explanation`)}
        </Text>
        <Text color="white" bottom="xs" size={14}>
          {t(`${i18n}.timeWarning`)}
        </Text>
        {status === "noReceipts" && (
          <Text color="pink1" top="xs" size={14}>
            {t(`${i18n}.noReceipts`)}
          </Text>
        )}
        {status === "error" && (
          <Text color="pink1" top="xs" size={14}>
            {t(`${i18n}.unknownError`)}
          </Text>
        )}
        {renderSubmit({ i18n, handleSubmit: run, isSubmitting: status === "loading" })}
      </ActionBody>
    );
  },
};
