import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { withFormik, FormikProps } from "formik";
import * as yup from "yup";
import _ from "lodash";
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";

import cx from "classnames";
import Geosuggest from "components/GeoSuggest";
import LegacyModal from "components/LegacyModal";
import Text from "components/LegacyText";
import Form from "components/Form";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import BaseFormElement from "components/Form/BaseFormElement";
import TagsInput from "../../../../../components/TagsInput";
import { inputProps } from "utilities/formik";
import { getUrlQueries } from "utilities/routes";
import retailIcon from "assets/retailSquare.svg";
import styles from "./styles.scss";
import organizationQuery from "./organizationQuery.gql";
import upsertLocation from "./upsertLocation.gql";
import organizationLocationsQuery from "../LocationsTable/locations.gql";
import locationQuery from "./locationDetails.gql";
import { OrganizationLocation, PLocation } from "interfaces/OrganizationLocation";
import { CloseModalHandler } from "utilities/modals";
import { OrganizationRouteParams } from "../../index";
import { ApolloClient } from "@apollo/client";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { TagGroup } from "gql-gen";
import { fromBase64 } from "utilities/base64";
import { flowRight as compose } from "lodash";
import { AddressComponents } from "modules/Dashboard/Schema/Gigs/locations";

export const NEW_ID_PLACEHOLDER = "NEW_ORG_LOCATION_ID";

export interface LocationSuggestion {
  placeId: string;
  location: {
    lat: number;
    lng: number;
  };

  gmaps: {
    formatted_address: string;
    address_components: AddressComponents;
  };

  label: string;
}

interface RouteParams extends OrganizationRouteParams {
  locationId: string;
}

interface LocationFormValues {
  locationName: string;
  internalIdentifier: string | null;
  tagIds: string[];
  contactName: string;
  contactTitle: string;
  contactEmail: string;
  contactPhone: string;
  contactUrl: string;
  schedulingNotes: string;
  otherNotes: string;
  searchLocation: PLocation | null;
  productIds: string[];
  hasProducts: boolean;
  failedUpsert?: never;
}

interface Props extends RouteComponentProps<RouteParams>, FormikProps<LocationFormValues> {
  client: ApolloClient<any>;
  organizationLocation: OrganizationLocation;
  organization: { id: string; name: string };
  handleClose: CloseModalHandler;
}

class LocationFormModal extends React.PureComponent<Props> {
  public geoSuggest: any;

  private handleGeosuggestChanged = (value: string) => {
    if (value.replace(/\s/g, "") === "") {
      this.props.setFieldValue("searchLocation", null);
    }
  };

  private handleLocationChanged = (suggestion: LocationSuggestion) => {
    let searchLocation = null;

    if (suggestion) {
      const { placeId, location: coords, gmaps, label } = suggestion;

      searchLocation = {
        externalId: placeId,
        name: label,
        address: gmaps.formatted_address,
        addressJson: gmaps.address_components,
        latitude: coords.lat,
        longitude: coords.lng,
      };

      this.props.setFieldValue("locationName", label);
    }

    this.props.setFieldValue("searchLocation", searchLocation);
  };

  public render() {
    const {
      handleSubmit,
      handleClose,
      values,
      errors,
      status,
      setFieldValue,
      organization,
      isSubmitting,
      location,
      values: { searchLocation },
      match: {
        url,
        params: { locationId, orgId },
      },
    } = this.props;

    const input = inputProps(this.props);

    return (
      <LegacyModal noPadding className={styles.modal}>
        <ModalHeader
          icon={retailIcon}
          mainAction={locationId ? "Edit location" : "Add a new location"}
          onClose={this.props.handleClose}
        />

        <div className={styles.content}>
          <Text.P4 className={styles.copy}>
            Saved locations make it easy to create new tasks, share location-specific information, and sync reporting
            data with your internal databases.
          </Text.P4>
          <Form.Section>
            <BaseFormElement
              label={searchLocation ? "SELECTED LOCATION" : "FIRST, FIND YOUR LOCATION"}
              labelReverse={!!searchLocation}
              inputClassNameProp="inputClassName"
              className={cx(searchLocation && styles.locationAddress)}
              element={
                <Geosuggest
                  className={cx(styles.geosuggest, searchLocation && styles.geosuggestText)}
                  initialValue={searchLocation ? searchLocation.name : ""}
                  placeholder="Type a name or address and choose from list..."
                  country={["us", "ca"]}
                  autoActivateFirstSuggest={true}
                  onChange={this.handleGeosuggestChanged}
                  onSuggestSelect={this.handleLocationChanged}
                />
              }
            />
          </Form.Section>
          {searchLocation && organization && (
            <React.Fragment key="locationModalTransition">
              <Text.Display4 className={styles.sectionTitle}>
                {organization.name} classification <Text.P4 inline>(Optional)</Text.P4>
              </Text.Display4>
              <Form.HorizontalSectionGroup>
                <Form.Section className={styles.noMinWidth}>
                  <Form.TextBox
                    {...input("locationName")}
                    label={
                      <React.Fragment>
                        <Text.Display5 className={styles.labelTitle}>
                          NAME{" "}
                          <Text.P4 inline className={styles.labelOptional}>
                            (defaults to Google’s suggested name)
                          </Text.P4>
                        </Text.Display5>
                      </React.Fragment>
                    }
                    value={values.locationName}
                  />
                </Form.Section>
                <Form.Section className={cx(styles.noMinWidth, styles.locationIdentifier)}>
                  <Form.TextBox
                    {...input("internalIdentifier")}
                    label={"ID"}
                    placeholder={"00000"}
                    className={cx(styles.noMinWidth, styles.idTextBox)}
                  />
                </Form.Section>
              </Form.HorizontalSectionGroup>
              <Form.Section>
                <TagsInput
                  organizationId={orgId}
                  group={TagGroup.Locations}
                  value={values.tagIds}
                  onChange={t => setFieldValue("tagIds", t)}
                />
              </Form.Section>
              <Text.Display4 className={styles.sectionTitle}>
                Contact at this location <Text.P4 inline>(Optional)</Text.P4>
              </Text.Display4>
              <Form.Section>
                <Form.HorizontalGroup>
                  <Form.TextBox
                    {...input("contactName")}
                    label={"PERSON"}
                    value={values.contactName}
                    placeholder={"Write here..."}
                  />

                  <Form.TextBox
                    {...input("contactTitle")}
                    label={"TITLE"}
                    value={values.contactTitle}
                    placeholder={"Write here..."}
                  />
                </Form.HorizontalGroup>
              </Form.Section>
              <Form.HorizontalSectionGroup>
                <Form.Section className={styles.noMinWidth}>
                  <Form.TextBox
                    {...input("contactEmail")}
                    label={"EMAIL"}
                    value={values.contactEmail}
                    placeholder={"Write here..."}
                  />
                </Form.Section>
                <Form.Section className={cx(styles.noMinWidth, styles.locationIdentifier)}>
                  <Form.TextBox
                    {...input("contactPhone")}
                    label={"PHONE"}
                    value={values.contactPhone}
                    placeholder={"Write here..."}
                  />
                </Form.Section>
              </Form.HorizontalSectionGroup>
              <Form.Section>
                <Form.TextBox
                  {...input("contactUrl")}
                  label={"URL"}
                  value={values.contactUrl}
                  placeholder={"Write here..."}
                />
              </Form.Section>
              <Text.Display4 className={styles.sectionTitle}>
                Additional information <Text.P4 inline>(Optional)</Text.P4>
              </Text.Display4>
              <Form.Section>
                <Form.TextBox
                  {...input("schedulingNotes")}
                  label={"HOW TO SCHEDULE"}
                  multiline
                  placeholder={"Write here..."}
                  rows={3}
                />
              </Form.Section>
              <Form.Section>
                <Form.TextBox
                  {...input("otherNotes")}
                  label={"OTHER NOTES"}
                  multiline
                  placeholder={"Write here..."}
                  rows={3}
                />
              </Form.Section>
            </React.Fragment>
          )}

          {status?.location ? (
            <Text.Message kind={"error"}>
              This location is already saved under a different name.
              <br /> To view/edit click here:{" "}
              <Link
                to={{ ...location, pathname: url.split("+add-location")[0] + "+edit-location/" + status.location.id }}
                className={styles.url}
              >
                {status.location.name +
                  (status.location.internalIdentifier ? " (" + status.location.internalIdentifier + ")" : "")}
              </Link>
              .
            </Text.Message>
          ) : (
            errors.failedUpsert && <Text.Message kind={"error"}>{errors.failedUpsert}</Text.Message>
          )}
        </div>

        <ModalFooter
          actionName={locationId ? "SAVE LOCATION" : "CREATE LOCATION"}
          actionButtonType={"submit"}
          actionDisabled={!searchLocation || !organization || isSubmitting}
          onAction={() => handleSubmit()}
          onCancel={() => handleClose()}
        />
      </LegacyModal>
    );
  }
}

export default compose(
  graphql<any, any, any, any>(organizationQuery, {
    options: ({
      match: {
        params: { orgId },
      },
    }) => {
      return { variables: { orgId } };
    },
    props: ({ data: { organization } = {} as any }) => {
      return { organization };
    },
  }),

  graphql(upsertLocation),
  graphql<Props, any, any, any>(locationQuery, {
    options: props => ({
      variables: {
        id: props.match.params.locationId,
      },
    }),

    skip: props => !props.match.params.locationId,
    props: ({ data: { organizationLocation } = {} as any }) => ({ organizationLocation }),
  }),

  withApollo,

  withFormik<
    Props & {
      mutate: (
        v: any,
      ) => Promise<{ data: { upsertOrganizationLocations: { id: string; errors: { error: string }[] } } }>;
    },
    LocationFormValues
  >({
    enableReinitialize: true,

    mapPropsToValues: props => {
      if (props.organizationLocation) {
        const {
          contacts,
          internalIdentifier,
          name,
          otherNotes,
          products,
          schedulingNotes,
          tags,
          location,
        } = props.organizationLocation;
        const contact = contacts && contacts[0];

        return {
          locationName: name || "",
          internalIdentifier: internalIdentifier || null,
          tagIds: tags ? tags.map(t => t.id) : [],
          contactName: (contact && contact.name) || "",
          contactTitle: (contact && contact.title) || "",
          contactEmail: (contact && contact.email) || "",
          contactPhone: (contact && contact.phone) || "",
          contactUrl: (contact && contact.url) || "",
          schedulingNotes: schedulingNotes || "",
          otherNotes: otherNotes || "",
          searchLocation: _.omit(location, ["__typename", "id"]) as PLocation,
          productIds: products ? products.map(p => p.id) : [],
          hasProducts: !!(products && products.length),
        };
      } else {
        let locationName = "",
          searchLocation = null;

        const { search } = props.location;
        const { selectedItem } = getUrlQueries(search);

        if (selectedItem) {
          const parsedItem = JSON.parse(fromBase64(selectedItem));

          const { name, latitude, longitude, address, addressJson, externalId } = parsedItem;
          locationName = name;
          searchLocation = {
            externalId,
            name,
            address,
            addressJson,
            latitude,
            longitude,
          };
        }

        return {
          locationName,
          internalIdentifier: null,
          tagIds: [],
          contactName: "",
          contactTitle: "",
          contactEmail: "",
          contactPhone: "",
          contactUrl: "",
          schedulingNotes: "",
          otherNotes: "",
          searchLocation,
          productIds: [],
          hasProducts: false,
        };
      }
    },

    validationSchema: yup.object().shape({
      internalIdentifier: yup
        .string()
        .nullable()
        .max(60, "ID is too long"),
      contactEmail: yup.string().email("Email is not valid"),
    }),

    handleSubmit: async (values, formikProps) => {
      const {
        client,
        mutate,
        handleClose,
        match: {
          params: { locationId },
        },
      } = formikProps.props;
      const { setFieldError, setStatus, setSubmitting } = formikProps;
      const {
        locationName,
        internalIdentifier,
        tagIds,
        contactName,
        contactTitle,
        contactEmail,
        contactPhone,
        contactUrl,
        schedulingNotes,
        otherNotes,
        searchLocation,
        productIds,
      } = values;

      setStatus(null);

      try {
        const refetchQueries = [];

        for (const { query, variables } of (client?.cache as any)?.watches || []) {
          if (query.definitions[0].name.value === organizationLocationsQuery.definitions[0].name.value) {
            refetchQueries.push({ query, variables });
          }
        }

        const {
          data: {
            upsertOrganizationLocations: { id, errors },
          },
        } = await mutate({
          variables: {
            input: {
              id: locationId,
              location: searchLocation,
              name: locationName,
              internalIdentifier,
              otherNotes,
              productIds,
              tagIds,
              schedulingNotes,
              contacts: [
                { name: contactName, title: contactTitle, email: contactEmail, phone: contactPhone, url: contactUrl },
              ],
            },
          },

          refetchQueries,
        });

        if (errors.length > 0) {
          throw new Error(errors[0].error);
        }

        handleClose([], { placeholder: NEW_ID_PLACEHOLDER, value: id });
      } catch (error) {
        parseErrorString(error.message)().then((result: E.Either<Error, ParsedErrorObject>) => {
          E.fold(
            (_: Error) => {
              setFieldError(
                "failedUpsert",
                `There was a problem ${locationId ? "updating" : "creating"} this location, try again.`,
              );
            },
            (parsedObject: ParsedErrorObject) => setStatus({ location: parsedObject }),
          )(result);
        });
      } finally {
        setSubmitting(false);
      }
    },
  }),
)(LocationFormModal);

interface ParsedErrorObject {
  name: string;
  id: string;
  internalIdentifier: string;
}

const parseErrorString = (errorString: string): TE.TaskEither<Error, ParsedErrorObject> => {
  return TE.tryCatch(
    async () => {
      // match on the error string shape we get from backend  and keep only whats between () eg
      // orgLocationAlreadyExists ({
      //   "name": "LIBERATORE RIST ELDER(FINTECH",
      //   "id": "64eafb97-ac8e-4aed-b5d9-7440e5658506",
      //   "internalIdentifier": "25615"
      // })
      // and parse it to JSON

      const regex = /\(\s*(\{[\s\S]*\})\s*\)/;
      const match = errorString.match(regex);

      if (!match) {
        throw new Error("No match found");
      }

      const jsonString = match[1];
      const parsed = JSON.parse(jsonString);

      if (
        typeof parsed.name === "string" &&
        typeof parsed.id === "string" &&
        typeof parsed.internalIdentifier === "string"
      ) {
        return parsed as ParsedErrorObject;
      } else {
        throw new Error("Validation failed");
      }
    },
    reason => new Error(reason as string),
  );
};
