import * as React from "react";
import cx from "classnames";
import { Set } from "immutable";

import Icon from "components/Icon";
import Text from "components/LegacyText";
import ConfirmBox from "components/ConfirmBox";
import chevronIcon from "assets/chevron.svg";
import variables from "styles/variables";
import { ControlledPagination } from "interfaces/Pagination";
import Row, { RowProps } from "./Row";
import Cell from "./Cell";
import { IAction } from "./actions";
import { ColumnInfo } from "./columns";
import SelectCell from "./SelectCell";
import SelectedItemsList from "./SelectedItemsList";
import PaginationFooter from "./PaginationFooter";

import styles from "./styles.scss";
import Clickable from "components/Clickable";
import { Ordering, Direction } from "gql-gen";

export type RowElement = React.ReactElement<RowProps> | null;

export type TableChildren = RowElement[];
export type SetOfIds = Set<string>;

const TABLE_HEADER_SIZE = 34;

const ROW_HEIGHTS = {
  small: 32,
  large: 64,
};

export type SelectedItem = { label: string; id: string };

export interface TableProps {
  children: TableChildren;
  rowHeight?: "small" | "large";
  columns?: ColumnInfo[];
  visibleColumns?: number;
  selectable?: boolean;
  className?: string;
  actions?: IAction[];
  listMode?: boolean;
  smallPagination?: boolean;
  displaySelected?: {
    data?: SelectedItem[] | null;
    renderCounter: (count: number) => string;
    disableRemove?: boolean;
    renderItemToggle?: (id: string) => React.ReactElement<any>;
    renderToggleAll?: React.ReactElement<any>;
  };
  selectAll?: boolean;
  selected?: string[];
  selectedItems?: SelectedItem[];
  selectMultiple?: boolean;
  onSelectionChange?: (ids: string[]) => unknown;
  isUserTypeSelectable?: (userType: string) => boolean;
  onAction?: (action: IAction, ids: SetOfIds) => Promise<unknown> | unknown;
  filter?: string[];
  emptyMessage?: React.ReactNode;
  noResultsMessage?: React.ReactNode;
  rowContainerClassName?: string;
  renderActions?: boolean;
  itemsPerPage?: number;
  controlledPagination?: ControlledPagination;
  order?: Ordering | null;
  onOrderChanged?: (order: Ordering) => void;
  headerClassName?: string;
  limitByProgram?: boolean;
  viewOnly?: boolean;
}

export interface State {
  page: number;
  selected: SetOfIds;
  selectMode: boolean;
  runningActions: SetOfIds;
  expandedRow: string | null;
  actionConfirmation: {
    action: IAction;
    ids: SetOfIds;
  } | null;
  selectedItems: SelectedItem[];
}

const DEFAULT_ITEMS_PPAGE = 10;

export default class Table extends React.Component<Partial<TableProps>, State> {
  constructor(props: Partial<TableProps>) {
    super(props);
    this.state = {
      page: 0,
      selected: Set(),
      runningActions: Set(),
      selectMode: false,
      expandedRow: null,
      actionConfirmation: null,
      selectedItems: !!props.selectedItems ? props.selectedItems : [],
    };
  }

  static defaultProps = {
    itemsPerPage: DEFAULT_ITEMS_PPAGE,
    selectMultiple: true,
    emptyMessage: "Empty",
    noResultsMessage: "No results",
  };

  componentDidMount() {
    if (this.props.selectAll) {
      this.selectAll();
    }
  }

  toggleSelectMode = () => {
    this.setState({ selectMode: !this.state.selectMode });
  };

  handleRowSelected = (id?: string, label?: string) => () => {
    if (!id || !label) return;

    const { selectMultiple } = this.props;

    const selected = this.getSelected();

    if (selected.has(id)) {
      this.setState(state => ({ selectedItems: state.selectedItems.filter(selectedItem => selectedItem.id !== id) }));
      this.setSelected(selected.remove(id));
    } else {
      this.setState(state => ({ selectedItems: [...state.selectedItems, { label, id }] }));
      if (selectMultiple) {
        this.setSelected(selected.add(id));
      } else {
        this.setSelected(Set([id]));
      }
    }
  };

  handleRowExpanded = (id: string) => () => {
    this.setState({ expandedRow: this.state.expandedRow === id ? null : id });
  };

  handlePageChange = (pages: number) => (page: number) => () => {
    if (page < 0) {
      page = pages - 1;
    } else if (page >= pages) {
      page = 0;
    }

    if (this.props.controlledPagination) {
      this.props.controlledPagination.onChange(page);
    } else {
      this.setState({ page });
    }
  };

  handleOnAction = (ids: SetOfIds) => (action: IAction, skipConfirmation: boolean = false) => {
    if (ids.size > 0) {
      if (!skipConfirmation && typeof action.confirm === "function") {
        this.setState({
          actionConfirmation: { ids, action },
        });
      } else if (this.props.onAction) {
        const result = this.props.onAction(action, ids);

        if (result instanceof Promise) {
          this.setState({
            runningActions: this.state.runningActions.concat(ids),
          });

          const onEnd = () => {
            setTimeout(() => {
              this.setState({
                runningActions: this.state.runningActions.subtract(ids),
              });
            }, 1000);
          };

          result.then(onEnd).catch(error => {
            onEnd();
            console.error(`Error running action ${action.name}`, error);
          });
        }
      }
    }
  };

  selectAll = (filter?: boolean) => {
    const allItems = this.getAllItems();
    const allIds = this.getAllIds();

    if (this.allSelected()) {
      this.setSelected(this.getSelected().subtract(Set(allIds)));
      this.setState(state => ({
        selectedItems: state.selectedItems.filter(item => !allItems.some(({ id }) => item.id === id)),
      }));
    } else if (filter && this.props.filter) {
      this.setSelected(Set(this.props.filter));
    } else {
      this.setSelected(Set.union([this.state.selected, Set(allIds)]));
      this.setState(state => ({ selectedItems: [...state.selectedItems, ...allItems] }));
    }
  };

  getSelected = () => this.state.selected;

  static getDerivedStateFromProps({ selected }: TableProps) {
    if (selected) {
      return { selected: Set(selected) };
    }

    return null;
  }

  setSelected = (selected: SetOfIds) => {
    const { selected: selectedProp, onSelectionChange } = this.props;

    if (selectedProp) {
      if (onSelectionChange) {
        onSelectionChange(selected.toArray());
      } else {
        console.warn(`You're using Table in controlled mode but not handling changes!
        Add "onSelectionChanged" to props or remove "selected"`);
      }
    } else {
      this.setState({ selected });
    }
  };

  allSelected = () => !this.getAllIds().find(id => !this.state.selected.has(id));

  getAllIds = () =>
    ((React.Children.toArray(this.props.children) as TableChildren).filter(
      r => r && !r.props.header,
    ) as React.ReactElement<RowProps>[]).map(r => r.props.id);

  getAllItems = () =>
    ((React.Children.toArray(this.props.children) as TableChildren).filter(
      r => r && !r.props.header,
    ) as React.ReactElement<RowProps>[]).map(r => ({ id: r.props.id, label: r.props.label! }));

  renderFooter(allSelected: boolean, filter?: boolean) {
    const { actions, listMode, selectMultiple } = this.props;

    if (!actions || !selectMultiple) return;

    const { selected, selectMode } = this.state;
    const disabled = selected.size === 0;

    const handleAction = this.handleOnAction(selected);

    const actionViews = actions
      .filter(a => a.multiple)
      .map(action => (
        <button
          aria-label={action.description}
          key={action.name}
          className={styles.selectedAction}
          disabled={disabled}
          style={{ backgroundColor: action.color }}
          onClick={() => handleAction(action)}
        >
          <div className={styles.icon}>
            {action.icon && <Icon src={action.icon} size={18} fill={variables.white} />}
          </div>
          <Text.Label2 kind={"reverse"}>{action.description.toUpperCase()}</Text.Label2>
        </button>
      ));

    return (
      <div
        key={"selectedActions"}
        className={!listMode || selectMode ? styles.visibleSelectedActions : styles.selectedActions}
      >
        <SelectCell
          visible={true}
          selected={allSelected}
          onSelect={() => this.selectAll(filter)}
          batchActions={true}
          selectMultiple={selectMultiple}
        />
        {actionViews}
      </div>
    );
  }

  componentDidUpdate({ filter: prevFilter }: TableProps) {
    const { filter } = this.props;

    if (
      (filter && prevFilter && filter.length !== prevFilter.length) ||
      (filter && !prevFilter) ||
      (!filter && prevFilter)
    ) {
      this.setState({ page: 0 });
    }
  }

  renderRow(row: RowElement, selected: SetOfIds, allSelected: boolean) {
    const { selectable, actions, listMode, columns, visibleColumns, selectMultiple, renderActions } = this.props;
    const { selectMode, expandedRow } = this.state;
    if (!row) return null;

    const { id, header, label } = row.props;

    let props: RowProps;

    props = {
      ...row.props,
      columns,
      visibleColumns,
      selectMultiple,
      renderActions,
      expanded: expandedRow === id,
      selectable: row.props.selectable === false ? false : selectable && (!listMode || !!selectMode),
      listMode: row.props.listMode === undefined ? listMode : row.props.listMode,
      disabled: this.state.runningActions.has(id),
      selected: header ? allSelected : selected.has(id),
      onSelect: header ? this.selectAll : this.handleRowSelected(id, label),
      onAction: this.handleOnAction(Set([id])),
      onExpand: this.handleRowExpanded(id),
    };

    if (actions && !row.props.actions) {
      props = { ...props, actions };
    }

    return React.cloneElement(row, props);
  }

  renderConfirmation() {
    const { actionConfirmation } = this.state;

    if (!actionConfirmation) return null;

    const { action, ids } = actionConfirmation;
    const { icon, title, yesText, children } = action.confirm!(action, ids.toArray());

    const clearConfirm = () => this.setState({ actionConfirmation: null });

    return (
      <ConfirmBox
        icon={icon}
        title={title}
        yesText={yesText}
        onNo={clearConfirm}
        onYes={() => {
          this.handleOnAction(ids)(action, true);
          clearConfirm();
        }}
      >
        {children}
      </ConfirmBox>
    );
  }

  render() {
    const {
      itemsPerPage,
      children,
      selectable,
      className,
      actions,
      listMode,
      columns,
      headerClassName,
      visibleColumns,
      rowHeight = "large",
      smallPagination,
      displaySelected,
      emptyMessage,
      noResultsMessage,
      filter,
      rowContainerClassName,
      controlledPagination,
      order,
      onOrderChanged,
      viewOnly,
    } = this.props;

    const { selectMode, page: statePage } = this.state;

    const allSelected = this.allSelected();
    const selected = this.getSelected();

    let rows: any[] = React.Children.toArray(children);

    const dataRows = rows;

    if (filter) {
      rows = filter.map(id => rows.find(r => !!(r && r.props.id === id)));
    }
    let pages;
    let page;

    if (controlledPagination) {
      pages = controlledPagination.pageCount;
      page = controlledPagination.currentPage;
    } else {
      page = statePage;

      const rowsTotal = rows.length;
      pages = itemsPerPage && itemsPerPage < rowsTotal ? Math.ceil(rowsTotal / itemsPerPage) : 0;

      if (pages && itemsPerPage) {
        const first = itemsPerPage * page;
        rows = rows.slice(first, first + itemsPerPage);
      }
    }

    rows = rows.map(row => row && this.renderRow(row, selected, allSelected));

    let isEmptyMessage;

    if (rows.length === 0) {
      if (dataRows.length > 0 && filter) {
        isEmptyMessage = noResultsMessage;
      } else {
        isEmptyMessage = emptyMessage;
      }
    }

    if (columns) {
      const visibleColumnsArr = visibleColumns ? columns.slice(0, visibleColumns) : columns;

      const headerRow: RowElement = (
        <Row header id={"$header"} key="header" className={headerClassName}>
          {visibleColumnsArr.map((column, index) => (
            <Cell key={index} size={column.size}>
              <Clickable
                className={styles.orderButton}
                actionLabel={column.orderId && "Order by " + column.label}
                onClick={() => {
                  if (column.orderId && onOrderChanged) {
                    onOrderChanged({
                      sort: column.orderId,
                      direction:
                        column.orderId === order?.sort && order.direction === Direction.Asc
                          ? Direction.DescNullsLast
                          : Direction.Asc,
                    });
                  }
                }}
              >
                {column.icon ? <Icon src={column.icon} fill={variables.white} size={16} /> : column.label}
                {column.orderId && column.orderId === order?.sort && (
                  <Icon
                    src={chevronIcon}
                    rotate={order?.direction === Direction.Asc ? 180 : 0}
                    fill={variables.white}
                    size={12}
                  />
                )}
              </Clickable>
            </Cell>
          ))}
        </Row>
      );

      rows = [this.renderRow(headerRow, selected, allSelected), ...rows];
    }

    const fixedHeightStyles =
      (pages > 1 || (!!filter && filter.length === 0)) && !viewOnly
        ? { minHeight: ROW_HEIGHTS[rowHeight] * (itemsPerPage || DEFAULT_ITEMS_PPAGE) + TABLE_HEADER_SIZE + 66 }
        : {};

    return (
      <div
        className={cx(
          styles.table,
          listMode && styles.listMode,
          rowHeight && rowHeight === "small" ? styles.rowHeightSmall : styles.rowHeightLarge,
          className,
        )}
      >
        {this.renderConfirmation()}
        {selectable && listMode && (
          <div className={styles.listHeader}>
            <Text.NewLink4 underline={"never"} onClick={this.toggleSelectMode}>
              {selectMode ? "CANCEL SELECTION" : "SELECT MULTIPLE"}
            </Text.NewLink4>
          </div>
        )}
        <div className={styles.fixedHeightContent} style={fixedHeightStyles}>
          <div className={cx(styles.rows, rowContainerClassName)}>
            {rows}

            {isEmptyMessage && (
              <div className={styles.emptyMessage}>
                {typeof isEmptyMessage === "string" ? (
                  <Text.P3 kind={"secondary"}>{isEmptyMessage}</Text.P3>
                ) : (
                  isEmptyMessage
                )}
              </div>
            )}
          </div>

          {pages > 1 && (
            <PaginationFooter
              page={page}
              lastPage={pages}
              goTo={this.handlePageChange(pages)}
              forceSmall={smallPagination}
            />
          )}
        </div>

        {selectable && actions && actions.find(a => !!a.multiple) && this.renderFooter(allSelected, !!filter)}
        {displaySelected && (
          <SelectedItemsList
            selected={displaySelected.data || this.state.selectedItems}
            renderCounter={displaySelected.renderCounter}
            renderItemToggle={displaySelected.renderItemToggle}
            onRemove={displaySelected.disableRemove ? undefined : this.handleRowSelected}
            renderToggleAll={displaySelected.renderToggleAll}
          />
        )}
      </div>
    );
  }
}
