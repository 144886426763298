import React, { useRef } from "react";
import { IAction } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";

import icon from "assets/goGetterSquare.svg";
import { colors } from "styles/variables";
import { GigAssignmentField } from "../../Fields/Assignment";
import { ScopeErrors } from "modules/Connection/ScopeErrors";
import { Form } from "modules/Connection/Form";
import styles from "./styles.scss";

export const AssignAction: IAction = {
  key: "assignment",
  icon,
  labelKey: "gigs.actions.assign.label",
  batchLabelKey: "gigs.actions.assign.assignShare",
  color: colors.blue2,
  allowInline: true,
  allowBatch: true,

  component: ({ isBatchAction, done, renderSubmit }) => {
    return (
      <ActionBody large done={done}>
        <Form
          isBatchForm={isBatchAction}
          form={GigAssignmentField}
          close={done}
          renderSubmit={fp => {
            const originalTalentId = useRef<string | null>(fp.values.talentId);
            const i18n = fp.values.talentId
              ? originalTalentId?.current === fp.values.talentId
                ? "gigs.actions.unassign"
                : "gigs.actions.assign"
              : "gigs.actions.unassign";
            return (
              <>
                <ScopeErrors />
                <div
                  className={
                    fp.values.talentId && originalTalentId?.current !== fp.values.talentId
                      ? ""
                      : styles.unassignSubmitBtn
                  }
                >
                  {fp.values.talentId === originalTalentId.current && !isBatchAction
                    ? null
                    : renderSubmit({
                        ...fp,
                        i18n,
                      })}
                </div>
              </>
            );
          }}
        />
      </ActionBody>
    );
  },
};
