import React from "react";

import { TrackingVisibleFragment } from "gql-gen";

import icon from "assets/clock.svg";

import { ICell } from "modules/Connection/types";
import { CellBase, CR1 } from "modules/Connection/CellBase";

export const TrackingCell: ICell<TrackingVisibleFragment> = {
  component: props => {
    return <CellBase {...props}>{() => <CR1>Tracking</CR1>}</CellBase>;
  },
  icon,
};
