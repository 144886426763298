import * as React from "react";
import _ from "lodash";
import SearchableSelect, { SearchableSelectProps } from "components/LegacySearchable/SearchableSelect";
import { FieldProps } from "formik";
import { parse, isValid, format, addMinutes, startOfDay } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseFormElement from "components/Form/BaseFormElement";

interface Props extends Pick<SearchableSelectProps, Exclude<keyof SearchableSelectProps, "items">> {
  value: Date | null;
  onChange: (v: Date | null) => void;
  timeFormat?: string;
  placeholder?: string;
  startInterval?: number;
  initiallyFocusedInterval?: number | null;
  baseDate?: Date;
}

export default function TimePicker({
  value: time,
  onChange,
  onBlur: providedOnBlur,
  startInterval = 0,
  initiallyFocusedInterval = 18,
  timeFormat = "h:mma",
  placeholder,
  baseDate = new Date(),
  ...props
}: Props) {
  const { t } = useTranslation();

  placeholder = placeholder ?? t("components.timePicker.placeholder");

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      const parsed = parse(e.target.value, timeFormat, baseDate);
      onChange(isValid(parsed) ? parsed : null);
    }

    if (providedOnBlur) {
      providedOnBlur(e);
    }
  };

  const { hours, timeISO, initialFocused } = useMemo(() => {
    let hours = _.times(MINUTES_PER_DAY / INTERVAL - startInterval, i => {
      const m = addMinutes(startOfDay(baseDate), (startInterval + i) * INTERVAL);
      return {
        id: m.toISOString(),
        name: format(m, timeFormat),
      };
    });

    let timeISO: string | null = null;

    if (time) {
      timeISO = time.toISOString();

      if (!hours.find(h => h.id === timeISO)) {
        hours = [{ id: timeISO, name: format(time, timeFormat) }, ...hours];
      }
    }

    return {
      hours,
      timeISO,
      initialFocused: initiallyFocusedInterval ? hours[(30 / INTERVAL) * initiallyFocusedInterval] : undefined,
    };
  }, [timeFormat, time, initiallyFocusedInterval]);

  return (
    <SearchableSelect
      items={hours}
      placeholder={placeholder}
      compact={true}
      selectedItem={timeISO}
      initialFocusedItem={initialFocused}
      search={searchFn}
      onBlur={onBlur}
      optionsYOffset={-8}
      emptyMessage={t("components.timePicker.noMatchingPresets")}
      {...props}
    />
  );
}

const INTERVAL = 30; // minutes
const MINUTES_PER_DAY = 24 * 60;

const searchFn = (query: string, { name }: { name: string }) => {
  return normalize(name).indexOf(normalize(query)) === 0;
};

const USELESS_CHARACTERS = /(^0)|(:)|(\s)/g;
const AMPM_REGEX = /^(\d\d?)([ap])m?/;

const normalize = (query: string) => {
  return query.toLowerCase().replace(AMPM_REGEX, "$100$2m").replace(USELESS_CHARACTERS, "");
};

export function FTimePicker({
  field,
  form,
  label,
  testId,
  ...props
}: FieldProps & Omit<Props, "onChange" | "value" | "name"> & { label: string }) {
  return (
    <TimePicker
      name={label}
      value={field.value}
      onChange={time => {
        form.setFieldValue(field.name, time);
      }}
      onBlur={() => {
        form.setFieldTouched(field.name, true);
      }}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      {...props}
      testId={testId}
    />
  );
}

export function FDuration({
  field,
  form,
  label,
  testId,
  value,
  ...props
}: FieldProps & Omit<Props, "onChange" | "value" | "name"> & { label: string; value?: string }) {
  return (
    <BaseFormElement
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      className={props.className}
      label={label}
      element={<input {...props} value={value || ""} />}
      testId={testId}
    />
  );
}
