import { GigLocationFieldFragment, Location } from "gql-gen";
import { AddressComponent, AddressComponents, findAddressComponents } from "modules/Dashboard/Schema/Gigs/locations";
import { addQueries } from "utilities/routes";
import { getRustBackendUrl } from "./getServerRootUrl";
import { getJwt } from "./authentication";

// IMPORTANT: Since this record will have different UUIDs in different environments,
//            we have to use the `externalId` to check for equality.
export const NO_LOCATION: GigLocationFieldFragment["location"] = {
  __typename: "Location",
  id: "<ignored>",
  externalId: "1",
  name: "No location",
  address: "No location",
  latitude: undefined,
  longitude: undefined,
  addressJson: [],
};

export const isVirtualLocation = (location?: Pick<Location, "externalId" | "latitude" | "longitude">) =>
  location ? location.externalId === NO_LOCATION.externalId || (!location.latitude && !location.longitude) : false;

export const isNoLocation = (location?: Pick<Location, "externalId">) =>
  location ? location.externalId === NO_LOCATION.externalId : false;

const GPLACES_KEY = "AIzaSyAnFuY-FC8FP0Ohh1JwGvWmpLODY7I5Jfc";
const PLACE_DETAILS_URL = `https://d3laaxlefu9eva.cloudfront.net/details/json`;
const FIND_PLACE_URL = `https://d3laaxlefu9eva.cloudfront.net/findplacefromtext/json`;

const urlCache: { [key: string]: any } = {};

function fetchCache(url: string) {
  if (url in urlCache) {
    return urlCache[url];
  }

  return (urlCache[url] = fetch(url).then(resp => resp.json()));
}

async function getAddressComponents(placeId: string) {
  const place = await fetchCache(
    addQueries(PLACE_DETAILS_URL, {
      key: GPLACES_KEY,
      place_id: placeId,
      fields: "address_components",
    }),
  );

  return place.result.address_components;
}

export async function findPlace(input: string, withAddressComponents: boolean) {
  const placeSearch = await fetchCache(
    addQueries(FIND_PLACE_URL, {
      key: GPLACES_KEY,
      input: input.trim(),
      inputtype: "textquery",
      fields: ["place_id", "name", "formatted_address", "geometry"].join(","),
    }),
  );

  const candidate = placeSearch.candidates[0];

  return (
    candidate && {
      ...candidate,
      address_components: withAddressComponents ? await getAddressComponents(candidate.place_id) : null,
    }
  );
}

export type RustLocation = {
  address: string;
  addressComponents: JSON;
  externalId: string;
  uuid: string;
  latLng: {
    lat: number;
    lng: number;
  };
  name: String;
  id: number;
};

const rustendUrl = `${getRustBackendUrl()}/api/v1`;

export async function createLocationForOrgLocation(
  orgLocation: { id: string; displayAddress: string; name: string },
  orgId: string,
): Promise<RustLocation> {
  let body;
  let [city, state, country]: (AddressComponent | undefined)[] = [undefined, undefined, undefined];
  try {
    const place = await findPlace(orgLocation.displayAddress, true);
    [city, state, country] = findAddressComponents(place.address_components, [
      ["locality", "administrative_area_level_3"],
      "administrative_area_level_1",
      "country",
    ]);
    body = {
      external_id: place.place_id,
      external_provider: "google",
      name: place.name,
      address_components: place.address_components,
      address: place.formatted_address,
      lat_lng: {
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      },
      city: city?.long_name,
      state: state?.short_name,
      country: country?.short_name,
    };
  } catch (e) {
    body = { name: orgLocation.name };
    console.error(e);
  }

  const response = await fetch(`${rustendUrl}/organizations/${orgId}/locations/${orgLocation.id}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwt()}`,
      "X-Pinata-Organization-Id": orgId,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to create location for org location ${orgLocation.id}. Status: ${response.status}`);
  }

  return await response.json();
}

type AdHocInput = {
  place_id: string;
  name: string;
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  address_components: AddressComponents;
};

export async function createAdHocLocation(
  { place_id, geometry, name, formatted_address, address_components }: AdHocInput,
  orgId: string,
): Promise<RustLocation> {
  const locationConfig = {
    external_id: place_id,
    external_provider: "google",
    lat_lng: {
      lat: geometry.location.lat,
      lng: geometry.location.lng,
    },
    address_components,
    address: formatted_address,
    name,
  };
  const response = await fetch(`${rustendUrl}/organizations/${orgId}/ad_hoc/locations`, {
    method: "POST",
    body: JSON.stringify(locationConfig),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwt()}`,
      "X-Pinata-Organization-Id": orgId,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to create ad hoc location. Status: ${response.status}`);
  }

  return await response.json();
}
