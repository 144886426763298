import React, { useRef, useEffect } from "react";
import { GigDescriptionFieldFragment, UpdateGigsInput } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import TextBox from "components/Form/TextBox";
import { GigProgramValue } from "../Program";
import descriptionFragment from "./description.gql";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";
import Section from "components/Form/Section";
import { Mobile } from "components/Layout/Responsive";

type Values = Pick<UpdateGigsInput, "notes">;

export const GigDescriptionField: ComposableField<GigDescriptionFieldFragment, Values, Values, GigProgramValue> = {
  id: "description",

  component: ({ inline, values: { notes }, setFieldValue }) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (ref.current) ref.current.style.height = ref.current.scrollHeight + "px";
    });

    return (
      <Mobile>
        {isMobile => {
          const boxed = isMobile || inline;

          const field = (
            <TextBox
              name="notes"
              inputRef={ref}
              label={boxed ? t("gigs.fields.description.description") : undefined}
              testId={"gigs.fields.description"}
              placeholder={
                boxed ? t("gigs.fields.description.placeholder") : t("gigs.fields.description.descriptionPlaceholder")
              }
              inputClassName={boxed ? "" : styles.descriptionBox}
              multiline
              value={notes || ""}
              onChange={e => {
                setFieldValue("notes", e.target.value);
              }}
              rows={2}
            />
          );

          return boxed ? <Section>{field}</Section> : field;
        }}
      </Mobile>
    );
  },

  initialize: data => {
    return {
      notes: data?.notes ?? "",
    };
  },

  fragment: descriptionFragment,
};
