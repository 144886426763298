import React from "react";
import { useTranslation } from "react-i18next";
import { GigsRoleOverviewFragment } from "gql-gen";
import { ICell } from "modules/Connection/types";
import Text from "components/Text";
import icon from "assets/info-circle.svg";
import fragment from "./role.gql";
import Money from "components/Money";
import styles from "./styles.scss";
import { DetailCell } from "components/DetailCell";

const i18n = `gigs.cells.roleOverview`;

export const RoleOverviewCell: ICell<GigsRoleOverviewFragment> = {
  component: props => {
    const { t } = useTranslation();
    const { data } = props;

    if (!data || !data.programRole) {
      return null;
    }

    const {
      myRate,
      programRole: { uniform, duties, profile },
    } = data;

    return (
      <div className={styles.roleOverview}>
        <DetailCell title={t(`${i18n}.rate.title`)}>
          {myRate && (
            <Text>
              <Money value={myRate} />
            </Text>
          )}
        </DetailCell>

        <DetailCell key="duties" title={t(`${i18n}.responsibilities.title`)}>
          {duties && duties.trim() && <Text>{duties}</Text>}
        </DetailCell>

        <DetailCell key="uniform" title={t(`${i18n}.attire.title`)}>
          {uniform && uniform.key && (
            <>
              <Text bold size={12} bottom="xs">
                {uniform.label}
              </Text>
              <Text>{uniform.description}</Text>
            </>
          )}
        </DetailCell>

        <DetailCell key="profile" title={t(`${i18n}.profile.title`)}>
          {profile && profile.trim() && <Text>{profile}</Text>}
        </DetailCell>
      </div>
    );
  },
  icon: icon,
  fragment: fragment,
};
