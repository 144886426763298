import * as React from "react";
import cx from "classnames";
import Spinner from "components/Spinner";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  message?: string;
  tip?: string;
  color?: "white" | "blue";
  center?: boolean;
  className?: string;
}

export default function LoadingContainer({ message = "Loading...", center, color, tip, className }: Props) {
  return (
    <div className={cx(styles.loadingContainer, center && styles.center, className)}>
      <Spinner scale={tip ? 0.7 : 0.5} color={color} />
      <Text.P2 kind={color === "white" ? "reverse" : "secondary"}>{message}</Text.P2>
      {tip && (
        <Text.P4 kind="tertiary" className={styles.tip}>
          {tip}
        </Text.P4>
      )}
    </div>
  );
}
