import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Formik, setNestedObjectValues } from "formik";
import * as yup from "yup";
import { IAction } from "modules/Connection/types";
import cancelIcon from "assets/ban.svg";
import { colors } from "styles/variables";
import Form from "components/Form";
import Section from "components/Form/Section";
import { inputProps } from "utilities/formik";
import { getProgramIdFromSearch } from "utilities/routes";
import { ActionBody } from "modules/Connection/ActionBody";
import cancelGigs from "./cancelGigs.gql";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import styles from "./styles.scss";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { CancellationReasonsQuery } from "gql-gen";
import cancellationReasons from "./cancellationReasons.gql";

const tBase = `gigs.actions.cancel`;

export const CancelAction: IAction = {
  key: "cancellation",
  icon: cancelIcon,
  labelKey: "gigs.actions.cancel.label",
  color: colors.pink1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, done, renderSubmit }) => {
    const { t } = useTranslation();

    const [mutate] = useMutation(cancelGigs);
    const { data, loading } = useQuery<CancellationReasonsQuery>(cancellationReasons);

    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return loading ? (
      <>Loading reasons...</>
    ) : (
      <Formik
        initialValues={{ reasonKey: "", reasonDescription: "", cancellationType: "cancelledUnpaid" }}
        validationSchema={validationSchema}
        onSubmit={async values => {
          await mutate({
            variables: {
              ...variables,
              programIds: id === "all" ? [] : id.split(","),
              reasonKey: values.reasonKey,
              reasonDescription: values.reasonDescription,
              cancellationType: values.cancellationType,
            },
          });
          done();
        }}
      >
        {formik => {
          const input = inputProps(formik);

          return (
            <ActionBody done={done}>
              <form onSubmit={formik.handleSubmit}>
                <Section noMargin>
                  <Form.Dropdown {...input("reasonKey")} label={t(`${tBase}.reason`)}>
                    {data?.CancellationReasons?.map(reason => (
                      <option key={reason?.key} value={reason?.key}>
                        {reason?.label}
                      </option>
                    ))}
                  </Form.Dropdown>
                  <Form.TextBox
                    placeholder={t(`${tBase}.descriptionPlaceholder`)}
                    multiline
                    {...input("reasonDescription")}
                    label={t(`${tBase}.description`)}
                    disabled={!formik.values.reasonKey || formik.values.reasonKey === ""}
                  />

                  <RadioGroup
                    value={formik.values.cancellationType}
                    onChange={v => formik.setFieldValue("cancellationType", v)}
                    className={styles.radiosContainer}
                  >
                    <RadioButton value={"cancelledUnpaid"}>{t(`${tBase}.type.cancelledUnpaid`)}</RadioButton>
                    <RadioButton value={"cancelledPaymentPending"}>
                      {t(`${tBase}.type.cancelledPaymentPending`)}
                    </RadioButton>
                  </RadioGroup>
                </Section>

                {renderSubmit({
                  ...formik,
                  i18n: "gigs.actions.cancel",
                })}
              </form>
            </ActionBody>
          );
        }}
      </Formik>
    );
  },
};

const validationSchema = yup.object().shape({
  reasonKey: yup.string().required("Please select a cancellation reason"),
  reasonDescription: yup.string(),
});
