import React from "react";
import { Field } from "formik";
import { GigTitleFieldFragment, UpdateGigsInput } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import { FTextBox } from "components/Form/TextBox";
import { GigProgramValue } from "../Program";
import titleFragment from "./title.gql";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";
import { Mobile } from "components/Layout/Responsive";
import Section from "components/Form/Section";

type Values = Pick<UpdateGigsInput, "title">;

export const GigTitleField: ComposableField<GigTitleFieldFragment, Values, Values, GigProgramValue> = {
  id: "title",

  component: ({ inline }) => {
    const { t } = useTranslation();

    return (
      <Mobile>
        {isMobile => {
          const boxed = isMobile || inline;

          const field = (
            <Field
              name="title"
              component={FTextBox}
              label={boxed ? t("gigs.fields.title.title") : undefined}
              testId={"gigs.fields.title"}
              title="Giving your event a title can help provide a bit more details and distinctions. Ex: Store # Product X, Special Trade Show, X Product Event"
              placeholder={boxed ? t("gigs.fields.title.placeholder") : t("gigs.fields.title.titleEllipsis")}
              inputClassName={boxed ? "" : styles.titleBox}
              className={boxed ? "" : styles.titleContainer}
            />
          );

          return boxed ? <Section className={styles.boxMargin}>{field}</Section> : field;
        }}
      </Mobile>
    );
  },

  initialize: data => {
    return {
      title: data?.title ?? "",
    };
  },

  fragment: titleFragment,
};
