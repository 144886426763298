import React, { ReactNode, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InfiniteLoader, AutoSizer, List } from "react-virtualized";
import { DocumentNode } from "graphql";

import { useConnectionConfig } from "hooks/useConnectionConfig";
import { useConnectionItems, ConnectionItems } from "hooks/useConnectionItems";
import { useDebouncedState } from "hooks/useDebouncedState";
import { useInfiniteLoader } from "hooks/useInfiniteLoader";
import { SrcConnection, ConnectionConfig } from "utilities/connections";
import Checkbox from "components/Form/Checkbox";
import Clickable from "components/Clickable";
import Text, { TextProps } from "components/Text";
import Icon from "components/Icon";
import { useReloaderCallback } from "modules/Connection/Reloader";

import styles from "./styles.scss";
import plusIcon from "assets/plus.svg";

export interface SearchableItem {
  id: string;
}

export const INIT_COUNT = 30; // please update doc if changed

/** Properties that LegacySearchable children receive */
export interface ChildrenProps<T> {
  /** Props to pass to the search input */
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  /** The list of matched items, can be rendered wherever  */
  list: ReactNode;
  /** Map containing all selected nodes by id */
  selectedNodes: ConnectionItems<T>;
  /** List of all the loaded nodes */
  loadedNodes: T[];
  /** Total count of options matching variables */
  totalCount: null | number;
}

interface AddNewConfig {
  text: string;
  onClick?: () => void | Promise<{ id: string } | null>;
}

export type ZeroStateFn = (term: string) => ReactNode;

export type ZeroState =
  | ZeroStateFn
  | {
      title: string;
      subtitle?: string;
    };

export interface SearchableProps<Node extends SearchableItem, CC extends ConnectionConfig<Node>>
  extends SrcConnection<CC> {
  /** Array of selected ids, even if not multiple */
  value: string[];
  /** Whether the user should be able to pick more than one item */
  multiple?: boolean;
  onChange: (selected: string[], nodes: Node[], autoSelected?: boolean) => void;
  /** Function that renders each item in the list */
  renderName: (node: Node) => ReactNode;
  /** Render something to show when there are no items to pick from */
  emptyState?: ZeroState;
  /** Render something to show when there no items matched the search */
  noMatchState?: ZeroState;
  /** Consumer */
  children: (childrenProps: ChildrenProps<Node>) => ReactNode;
  /** The GraphQL fragment needed for displaying each item */
  fragment: DocumentNode;
  /** Select first item automatically */
  selectFirst?: boolean;
  /** Skip request */
  skip?: boolean;
  /** Add new button configuration */
  addNewConfig?: AddNewConfig | ((search: string) => AddNewConfig);
  /** Should clear search on selection */
  clearSearchOnSelect?: boolean;
}

/**
 * Creates an infinite scrolling searchable list from a GraphQL connection query
 * leaving the layout up to the user.
 *
 * Behaviour:
 * - It gets the first 30 items as soon as it's rendered.
 * - It debounces the search (300ms).
 * - It renders ellipsis while items are loaded.
 * - Selected items are rendered at the bottom of the list too.
 * - If one or many selected items are not found in the cache,
 *   a second query is fired to fetch their details.
 * - The aforementioned query only includes the missing items.
 *
 * __Note__: The connection is required to take a `search` argument and accept
 *           filtering by `id`. Nodes must contain an `id` field.
 *
 * @param props {@link SearchableProps}
 */
export function Searchable<Node extends SearchableItem, CC extends ConnectionConfig<Node>>({
  connection,
  variables: extraVars,
  renderName,
  emptyState = { title: "components.searchable.noItems" },
  noMatchState = { title: "components.searchable.noResults", subtitle: "components.searchable.noItemsMatching" },
  value,
  onChange,
  children,
  multiple,
  fragment,
  selectFirst,
  addNewConfig,
  clearSearchOnSelect,
}: SearchableProps<Node, CC>) {
  const { t } = useTranslation();
  const [search, debouncedSearch, setSearch] = useDebouncedState("", 300);

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    placeholder: "Search...",
    value: search,
    onChange: e => setSearch(e.target.value),
  };

  const variables = {
    filters: undefined,
    first: INIT_COUNT,
    offset: 0,
    skipPageInfo: false,
    ...extraVars,
    search: debouncedSearch,
  };

  const fragments = useMemo(() => [fragment], [fragment]);
  const query = useConnectionConfig(connection, fragments);

  const { loaderRef, loadRows, loading, loaded, reload, totalCount, isRowLoaded } = useInfiniteLoader({
    initialCount: INIT_COUNT,
    entryName: connection.entry.name,
    variables,
    query,
    sendFirstQuery: true,
  });

  useReloaderCallback(connection.name, reload);

  useEffect(() => {
    if (selectFirst && !value.length && loaded.length === 1 && search === "") {
      onChange([loaded[0].node.id], loaded, true);
    }
  }, [loaded]);

  const [selectedNodes] = useConnectionItems<Node, CC>(connection, fragments, value, extraVars);

  const handleOnChange = (v: string[]) => {
    onChange(
      v,
      loaded.map(n => n.node),
    );

    if (!!clearSearchOnSelect || (totalCount === 1 && !!multiple)) {
      setSearch("");
    }
  };

  const renderRow = (node: Node | null) => {
    let content;

    if (node) {
      content = renderName(node);

      if (multiple) {
        content = (
          <Checkbox
            className={styles.item}
            checked={value.includes(node.id)}
            onCheck={checked => {
              if (checked) {
                handleOnChange([...value, node.id]);
              } else {
                handleOnChange(value.filter(id => id !== node.id));
              }
            }}
            testId={`searchable.item.${node.id}`}
          >
            <ItemLabel left={"s"}>{content}</ItemLabel>
          </Checkbox>
        );
      } else {
        content = (
          <Clickable
            className={styles.item}
            onClick={() => {
              handleOnChange([node.id]);
            }}
          >
            <ItemLabel>{content}</ItemLabel>
          </Clickable>
        );
      }
    } else {
      content = "...";

      if (multiple) {
        content = (
          <Checkbox className={styles.item}>
            <ItemLabel left={"s"}>{content}</ItemLabel>
          </Checkbox>
        );
      } else {
        content = <ItemLabel className={styles.item}>{content}</ItemLabel>;
      }
    }

    return content;
  };

  const multipleSelected = value.length > 1;

  let list;

  let addNew = null;

  if (addNewConfig) {
    const { onClick, text } = typeof addNewConfig === "function" ? addNewConfig(search) : addNewConfig;

    addNew = (
      <button
        onClick={async e => {
          e.preventDefault();
          if (onClick) {
            const result = onClick();

            if (result instanceof Promise) {
              const data = await result;

              reload();

              if (data?.id) {
                handleOnChange([...value, data.id]);
              }
            }
          }
        }}
        className={styles.addNewBtn}
      >
        <ItemLabel left={"s"} bold right={"xs"}>
          {text}
        </ItemLabel>
        <Icon src={plusIcon} size={14} fill={variables.teal1} />
      </button>
    );
  }

  if (totalCount === 0 && !loading) {
    const renderZero = (zs: ZeroState) => {
      if (typeof zs === "function") {
        return zs(search);
      } else {
        return (
          <div className={styles.emptyState}>
            <Text size={16} font="wes" bottom="xs" ellipsis>
              {t(zs.title, { term: search })}
            </Text>
            {zs.subtitle && (
              <Text size={12} ellipsis className={styles.subtitle}>
                {t(zs.subtitle, { term: search })}
              </Text>
            )}
          </div>
        );
      }
    };

    if (search.length === 0) {
      list = renderZero(emptyState);
    } else {
      list = renderZero(noMatchState);
    }

    list = (
      <div className={styles.zeroWrapper}>
        {addNew}
        {list}
      </div>
    );
  } else {
    list = (
      <div className={styles.items}>
        {addNew}
        <InfiniteLoader
          ref={loaderRef}
          rowCount={totalCount ?? 100}
          isRowLoaded={isRowLoaded}
          minimumBatchSize={25}
          loadMoreRows={indexRange => loadRows(indexRange)}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <List
                    className={styles.rows}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    style={{ paddingBottom: value.length ? 40 : 0 }}
                    height={height}
                    width={width}
                    rowCount={totalCount ?? 100}
                    rowHeight={30}
                    rowRenderer={({ index, key, style }) => (
                      <div key={key} style={style}>
                        {renderRow(index in loaded ? loaded[index].node : null)}
                      </div>
                    )}
                  />
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
        {value.length !== 0 && multiple && (
          <div className={multipleSelected ? styles.selectedItemsMultiple : styles.selectedItems}>
            {value.map(id => (
              <div key={id} className={styles.selectedItem}>
                {renderRow(selectedNodes[id])}
              </div>
            ))}
            {multipleSelected && (
              <Text color={"gray1"} size={12} className={styles.countBadge}>
                + {value.length - 1} more
              </Text>
            )}
          </div>
        )}
      </div>
    );
  }
  return <>{children({ inputProps, list, selectedNodes, loadedNodes: loaded, totalCount: totalCount ?? null })}</>;
}

export const ItemLabel = (props: TextProps) => (
  <Text {...props} size={12} ellipsis noSelect inline>
    {props.children}
  </Text>
); // <Text {...props} inline size={12} ellipsis noSelect />;
