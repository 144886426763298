import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import deleteIcon from "assets/delete.svg";
import { colors } from "styles/variables";
import Text from "components/Text";
import { useMutation } from "@apollo/client";
import { ActionBody } from "modules/Connection/ActionBody";
import deleteGigs from "./delete.gql";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = "gigs.actions.delete";

export const DeleteAction: IAction = {
  key: "deletion",
  icon: deleteIcon,
  labelKey: `${i18n}.label`,
  color: colors.pink1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, count, done, renderSubmit }) => {
    const { t } = useTranslation();
    const [mutate] = useMutation(deleteGigs);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deleteError, setDeleteError] = useState<string | null>(null);
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Text color="white">{t(`${i18n}.areYouSure`, { count })}</Text>

        {renderSubmit({
          i18n,
          isSubmitting,
          handleSubmit: async () => {
            setIsSubmitting(true);
            const { data, errors } = await mutate({
              variables: { ...variables, programIds: id === "all" ? [] : id.split(",") },
            });
            setIsSubmitting(false);

            if (!!data && (data as any).deleteDraftGigs == 0) {
              setDeleteError("No tasks affected, make sure you selected tasks that can be deleted.");
            } else if (!!errors) {
              setDeleteError("Unknown error when deleting tasks.");
            } else {
              done();
            }
          },
        })}

        {!!deleteError && (
          <Text color="pink1" top="m">
            {deleteError}
          </Text>
        )}
      </ActionBody>
    );
  },
};
