import React from "react";
import { Field } from "formik";
import { GigProgramFieldFragment } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import programFragment from "./program.gql";
import FProgramPicker from "./ProgramPicker/ProgramPicker";
import Section from "components/Form/Section";

export interface GigProgramValue {
  programId?: string;
}

export const GigProgramField: ComposableField<GigProgramFieldFragment, GigProgramValue> = {
  id: "program",

  component: ({ inline }) => {
    const field = (
      <Field name="programId" component={FProgramPicker} testId={"gigs.field.details.programPicker"} disabled={false} />
    );

    return inline ? <Section>{field}</Section> : field;
  },

  initialize: data => {
    return {
      programId: data && data.program.id,
    };
  },

  validate: ({ programId }, { partialBatchUpdate }) => {
    if (!programId && !partialBatchUpdate) return { programId: "Please pick a program" };
    return {};
  },

  fragment: programFragment,
  // dependency: GigTalentField,
};
