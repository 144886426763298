import * as React from "react";
import ReactModal from "react-modal";
import cx from "classnames";

import Clickable from "components/Clickable";
import Icon from "components/Icon";
import { colors } from "styles/variables";
import closeIcon from "assets/times.svg";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  isOpen?: boolean;
}

export const Modal = React.forwardRef<HTMLDivElement, Props>(({ children, className, isOpen = true, onClose }, ref) => {
  return (
    <ReactModal
      contentRef={ref as any}
      isOpen={isOpen}
      className={cx(styles.content, className)}
      overlayClassName={styles.overlay}
      onRequestClose={onClose}
      appElement={getRoot()}
    >
      {onClose && (
        <Clickable className={styles.closeButton} onClick={onClose} testId="modal.close">
          <Icon src={closeIcon} fill={colors.white} size={14} />
        </Clickable>
      )}
      {children}
    </ReactModal>
  );
});

let root: HTMLElement | undefined;

function getRoot() {
  if (!root) root = document.getElementById("root") || undefined;

  return root;
}
