import React from "react";
import { FieldProps } from "formik";
import DecimalBox from "components/Form/DecimalBox";
import Text, { Color } from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";
import { AssignmentTalentQuery } from "gql-gen";

function BoldLabel({ txt, color }: { txt: string; color: Color }) {
  return (
    <Text font="wes" color={color} lineHeight={15} size={12} className={styles.label}>
      {txt}
    </Text>
  );
}

export default function FRateField({
  field,
  form,
  gigRate,
  testId,
  talent,
}: FieldProps & { gigRate: number; testId?: string; talent: AssignmentTalentQuery["user"] }) {
  const { talentRate } = form.values;
  const selectedTalentRate = talent?.organizationUser?.defaultRate;

  return (
    <div className={styles.rateContainer}>
      <DecimalBox
        errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
        label="HOURLY RATE"
        optionalLabel
        value={field.value}
        onChange={v => form.setFieldValue(field.name, v)}
        testId={testId}
      />
      {talent && selectedTalentRate && talentRate !== selectedTalentRate ? (
        <div className={styles.changeContainer}>
          <Text font="wes" color={"teal1"} lineHeight={15} size={12}>
            RATE CHANGE
          </Text>
          <Text font="wes" color={"gray1"} lineHeight={15} size={12}>
            {`Update with ${talent.firstName}'s rate?`}
          </Text>
          <div className={styles.updateRow}>
            <Text font="wes" color={"gray1"} lineHeight={15} size={12}>
              {`$${talent.organizationUser?.defaultRate}/hr`}
            </Text>
            <Clickable onClick={() => form.setFieldValue(field.name, selectedTalentRate)}>
              <Text underline="always" font="wes" color={"blue2"} left={"xs"} lineHeight={15} size={12}>
                UPDATE
              </Text>
            </Clickable>
          </div>
        </div>
      ) : null}
    </div>
  );
}
