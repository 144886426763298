import * as React from "react";
import cx from "classnames";
import { CheckboxProps, CheckboxValue } from "../Checkbox";
import styles from "./styles.scss";

interface Props {
  className?: string;
  direction?: "horizontal" | "vertical";
  disabled?: boolean;
  values: string[];
  onChange: (selectedValues: CheckboxValue[]) => void;
  children: React.ReactElement<CheckboxProps>[];
}

export default function CheckboxGroup({ className, children, direction = "vertical", values, onChange }: Props) {
  const onCheck = (key: string) => () =>
    onChange(values.includes(key) ? values.filter(val => val !== key) : [...values, key]);

  const items = React.Children.map(children, (item, i) => {
    if (!item) return item;

    return React.cloneElement(item, {
      checked: item.props.value ? values.includes(item.props.value) : false,
      disabled: item.props.disabled,
      onCheck: onCheck(item.props.value || i.toString()),
    });
  });

  return (
    <div className={cx(styles.group, styles[direction], className)} role={"radiogroup"}>
      {items}
    </div>
  );
}
