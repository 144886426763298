export function scrollIntoViewIfNeeded(element: HTMLElement) {
  const { left, top, right, bottom } = element.getBoundingClientRect();
  const parent = getScrollParent(element).getBoundingClientRect();

  if (left < parent.left || top < parent.top || right > parent.right || bottom > parent.bottom) {
    element.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  }
}

export function getScrollParent(element: HTMLElement): HTMLElement {
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }

  return element.parentElement ? getScrollParent(element.parentElement) : element;
}

export function syncScroll(element1: HTMLElement, element2: HTMLElement) {
  let ignore1 = false;
  let ignore2 = false;

  const move2 = () => {
    if (ignore1) return (ignore1 = false);
    ignore2 = true;
    element2.style.willChange = "scroll";
    element2.scrollTop = element1.scrollTop;
  };

  const move1 = () => {
    if (ignore2) return (ignore2 = false);
    ignore1 = true;
    element1.style.willChange = "scroll";
    element1.scrollTop = element2.scrollTop;
  };

  element1.addEventListener("scroll", move2, { passive: true });
  element2.addEventListener("scroll", move1, { passive: true });

  return () => {
    element1.removeEventListener("scroll", move2);
    element2.removeEventListener("scroll", move1);
  };
}
