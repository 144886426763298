import * as React from "react";
import cx from "classnames";
import { RouteComponentProps, withRouter, NavLink } from "react-router-dom";
import styles from "./styles.scss";
export interface IRouteTab {
  title: string;
  path: string;
}

interface Props extends RouteComponentProps {
  compact?: boolean;
  className?: string;
  invertedBg?: boolean;
  tabs: Array<IRouteTab | null>;
}

class RouteTabs extends React.PureComponent<Props> {
  public render() {
    const {
      match: { url },
      compact,
      location,
      tabs,
      invertedBg,
      className,
    } = this.props;

    return (
      <div className={cx(compact ? styles.compactTabs : styles.tabs, className)}>
        {tabs.map((tab, index) => {
          return (
            tab && (
              <NavLink
                key={index}
                to={{ ...location, pathname: url + `/${tab.path}` }}
                className={cx(styles.tab, compact && styles.compactTab, invertedBg && styles.invertedBg)}
                activeClassName={styles.activeTab}
              >
                {tab.title}
              </NavLink>
            )
          );
        })}
      </div>
    );
  }
}

export default withRouter(RouteTabs);
