import { graphql } from "@apollo/client/react/hoc";
import { MutationFunction } from "@apollo/client";
import * as React from "react";
import { withFormik, FormikProps } from "formik";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as yup from "yup";
import Form from "components/Form";
import LegacyModal from "components/LegacyModal";
import Shortcuts, { shortcut } from "components/Shortcuts";
import Text from "components/LegacyText";
import { inputProps } from "utilities/formik";
import { getUrlQueries, omitQueries } from "utilities/routes";
import upsertBillingContact from "./addBillingContact.gql";
import styles from "./styles.scss";
import statesQuery from "../../states.gql";
import billingContactsQuery from "../BillingContactsTable/billingContacts.gql";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { State } from "../../../interfaces/State";
import { CloseModalHandler, ModalRouteParams } from "utilities/modals";
import { OrganizationRouteParams } from "../../index";
import { BillingContact } from "modules/Dashboard/interfaces/BillingContact";
import { IReloadContext, withReloader } from "modules/Connection/Reloader";
import { flowRight as compose } from "lodash";
interface BillingContactFormValues {
  nickname: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  default?: never;
}

interface Props
  extends FormikProps<BillingContactFormValues>,
    RouteComponentProps<ModalRouteParams & OrganizationRouteParams> {
  statesQuery: {
    states: State[];
  };

  mutate: MutationFunction<
    { addBillingContact: BillingContact },
    { contact: BillingContactFormValues; organizationId: string | null }
  >;
  handleClose: CloseModalHandler;
  reloader: IReloadContext;
}

export const NEW_ID_PLACEHOLDER = "NEW_ID_HERE";

class BillingContactFormModal extends React.Component<Props, any> {
  public handleClose = (newId?: string) => {
    const {
      handleClose,
      location: { search },
      history,
    } = this.props;
    const urlQueries = getUrlQueries(omitQueries(search, ["forOrgId"]));
    let { next } = urlQueries;

    if (next) {
      if (newId) {
        next = next.replace(NEW_ID_PLACEHOLDER, newId);
      }

      history.replace(next);
    } else {
      history.replace(urlQueries);
      handleClose();
    }
  };

  public render() {
    const {
      handleSubmit,
      errors,
      isSubmitting,
      statesQuery: { states },
    } = this.props;

    let message = <Text.Message>&nbsp;&nbsp;</Text.Message>;

    if (isSubmitting) {
      message = <Text.Message kind={"neutral"}>Saving…</Text.Message>;
    } else if (errors && errors.default) {
      message = <Text.Message kind={"error"}>An error has occurred. Try again.</Text.Message>;
    }

    const input = inputProps(this.props, [
      "nickname",
      "firstName",
      "lastName",
      "email",
      "company",
      "address1",
      "address2",
      "city",
      "state",
      "zip",
    ]);

    const handleClose = () => this.handleClose();
    const hs = () => handleSubmit();

    return (
      <Shortcuts shortcuts={[shortcut("s s", hs)]}>
        <LegacyModal noPadding className={styles.modal}>
          <ModalHeader mainAction={"New Billing Contact"} onClose={handleClose} />

          <div className={styles.form}>
            <Form.Section>
              <Form.TextBox {...input(`nickname`)} label={"LABEL"} placeholder={"Write here…"} />
            </Form.Section>
            <Form.HorizontalSectionGroup>
              <Form.Section>
                <Form.HorizontalGroup>
                  <Form.TextBox {...input(`firstName`)} label={"FIRST NAME"} placeholder={"Write here…"} />
                  <Form.TextBox {...input(`lastName`)} label={"LAST NAME"} placeholder={"Write here…"} />
                </Form.HorizontalGroup>
              </Form.Section>
            </Form.HorizontalSectionGroup>
            <Form.Section>
              <Form.TextBox {...input(`email`)} label={"EMAIL ADDRESS"} placeholder={"Write here…"} />
            </Form.Section>

            <Form.Section>
              <Form.TextBox {...input(`company`)} label={"COMPANY"} placeholder={"Write here…"} />
            </Form.Section>

            <Form.Section>
              <Form.TextBox {...input(`address1`)} label={"ADDRESS LINE 1"} placeholder={"Write here…"} />
              <Form.TextBox {...input(`address2`)} label={"ADDRESS LINE 2"} placeholder={"Write here…"} />
              <Form.HorizontalGroup>
                <Form.TextBox {...input(`city`)} label={"CITY"} placeholder={"Write here…"} />

                <Form.Dropdown {...input(`state`)} label={"STATE"}>
                  <option value={""}>...</option>
                  {states &&
                    states.map(state => (
                      <option key={state.key} value={state.key}>
                        {state.name}
                      </option>
                    ))}
                </Form.Dropdown>
                <Form.TextBox {...input(`zip`)} label={"ZIP"} placeholder={"10014"} />
              </Form.HorizontalGroup>
            </Form.Section>
            {message}
          </div>

          <ModalFooter
            actionName={"SAVE CONTACT"}
            actionButtonType={"submit"}
            actionDisabled={isSubmitting}
            onAction={hs}
            onCancel={handleClose}
          />
        </LegacyModal>
      </Shortcuts>
    );
  }
}

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
});

export default compose(
  withRouter,
  graphql<any, any, any, any>(statesQuery, { name: "statesQuery" }),
  graphql(upsertBillingContact),

  withReloader,
  withFormik<Props, BillingContactFormValues>({
    mapPropsToValues: () => ({
      nickname: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    }),

    validationSchema: VALIDATION_SCHEMA,

    handleSubmit: async (
      values,
      {
        setSubmitting,
        setErrors,
        props: {
          history,
          mutate,
          match: {
            params: { orgId },
          },
          location: { search },
          handleClose,
          reloader,
        },
      },
    ) => {
      const { forOrgId } = getUrlQueries(search);
      try {
        const result = await mutate({
          variables: {
            contact: values,
            organizationId: forOrgId && forOrgId !== orgId ? forOrgId : null,
          },

          update: (cache, { data }) => {
            if (!forOrgId) {
              const query = { query: billingContactsQuery, variables: { orgId } };
              const queryResult = cache.readQuery<{ billingContacts: BillingContact[] }>(query);
              if (!data || !queryResult) return;
              cache.writeQuery({
                ...query,
                data: { billingContacts: queryResult.billingContacts.concat([data.addBillingContact]) },
              });
            }
          },
        });
        reloader.reload("OrgBillingContactOptions");

        if (!result || !result.data || !result.data.addBillingContact) throw new Error("Couldn't save billing contact");

        const urlQueries = getUrlQueries(omitQueries(search, ["forOrgId"]));
        history.replace(urlQueries);
        handleClose([result.data.addBillingContact.id]);
      } catch (e) {
        console.error(e);
        setErrors({ default: e.message });
      }

      setSubmitting(false);
    },
  }),
)(BillingContactFormModal);
