import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  inline?: boolean;
}

export default function ErrorMessage({ children, inline, className }: Props) {
  return <p className={cx(styles.message, { inline }, className)}>{children}</p>;
}
