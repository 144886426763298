import ImageBlobReduce from "image-blob-reduce";

export function downloadUrl(url: string, fileName: string) {
  const a = window.document.createElement("a");
  a.href = url;
  a.download = fileName;
  window.document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadBlob(blob: Blob, fileName: string) {
  downloadUrl(window.URL.createObjectURL(blob), fileName);
}

export async function resizeImage(file: File, maxSize: number) {
  const reducer = new ImageBlobReduce();

  return reducer.toBlob(file, { max: maxSize });
}
