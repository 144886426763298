import React from "react";
import taskManagerFragment from "./taskManager.gql";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { TaskManagerPickerFieldFragment } from "gql-gen";
import { connection, ConnectionConfig } from "utilities/connections";
import { useTranslation, Trans } from "react-i18next";
import { TextGroup } from "components/Text";
import Section from "components/Form/Section";
import { FieldOptionsBold } from "../../FieldOptionsText";

export const taskManagerConnection: ConnectionConfig<TaskManagerPickerFieldFragment> = connection({
  name: "ProgramTaskManagerOptions",
  entry: { name: "organizationConnectionsPartners" },
  variables: { search: "String" },
});

export default function FTaskManagerPicker({
  field,
  form,
  inlineForm,
  partialBatchUpdate,
}: FieldProps & { inlineForm: boolean; partialBatchUpdate?: boolean }) {
  const { t } = useTranslation();

  return (
    <SearchableSelect<TaskManagerPickerFieldFragment>
      label={t("gigs.fields.taskManager.taskManager")}
      multiple={false}
      connection={taskManagerConnection}
      fragment={taskManagerFragment}
      value={field.value ? [field.value] : []}
      disabled={!partialBatchUpdate}
      variables={null}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      renderName={({ name }) => name}
      selectFirst
      onChange={r => form.setFieldValue(field.name, r[0])}
      clearSearchOnSelect
    >
      {({ select, count }) => (
        <>
          {!partialBatchUpdate && (
            <TextGroup
              font="lato"
              color={inlineForm ? "white" : "gray1"}
              size={14}
              top={inlineForm ? undefined : "l"}
              bottom="l"
              lineHeight={18}
            >
              <Trans i18nKey={`gigs.fields.taskManager.options`} count={count ?? 0}>
                Based on your selections, you can assign this task to one of&nbsp;
                <FieldOptionsBold inlineForm={inlineForm}>X task managers</FieldOptionsBold>. Select the one that's
                right for this task.
              </Trans>
            </TextGroup>
          )}
          <Section>{select}</Section>
        </>
      )}
    </SearchableSelect>
  );
}
