import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import cx from "classnames";

import { GigLocationFieldFragment, CreateGigsInput } from "gql-gen";

import { ComposableField } from "modules/Connection/ComposableForm";
import Section from "components/Form/Section";
import LocationFinder from "components/LocationFinder";
import { isNoLocation, isVirtualLocation } from "utilities/location";

import styles from "./styles.scss";

import locationFragment from "./location.gql";

import FCustomLocationName from "./CustomLocationNameField";
import { LeaveBlank } from "../LeaveBlank";
import { FieldOptionsText } from "../FieldOptionsText";
import { GigDateField } from "../Date";
import { GigRoleField } from "../Role";
import Text from "components/Text";
import { getTimeWithZone } from "utilities/time";

export interface GigLocationValues {
  location: GigLocationFieldFragment["location"] | undefined;
  locationName: string;
}

export const GigLocationField: ComposableField<GigLocationFieldFragment, GigLocationValues, CreateGigsInput> = {
  id: "location",

  component: ({ inline, values, setFieldValue }) => {
    const { t } = useTranslation();

    const resetLocation = () => {
      setFieldValue("orgLocationId", null);
      setFieldValue("location", null);
      setFieldValue("locationName", "");
    };

    if (isVirtualLocation(values?.location)) {
      if (isNoLocation(values?.location)) {
        return <FieldOptionsText inlineForm={inline}>The selected Activity requires no location.</FieldOptionsText>;
      }
      return (
        <div>
          <button onClick={resetLocation} className={styles.resetLocation}>
            <Text font="wes" size={12} color="blue2" noSelect>
              {location ? t(`components.locationFinder.clearSelection`) : t(`components.filters.resetAll`)}
            </Text>
          </button>
          <Section>
            <Field
              name="locationName"
              component={FCustomLocationName}
              testId={"gigs.fields.location.customLocationName"}
            />
          </Section>
        </div>
      );
    }

    return (
      <div className={cx(inline && styles.locationForm, inline && styles.locationFormTable)}>
        <FieldOptionsText inlineForm={inline}>{t("gigs.fields.location.copy")}</FieldOptionsText>
        <Field
          name="location"
          component={LocationFinder}
          testId={"gigs.fields.location.locationFinder"}
          inline={inline}
        />
        <Section>
          <Field
            name="locationName"
            component={FCustomLocationName}
            testId={"gigs.fields.location.customLocationName"}
          />
        </Section>
        <Field name="location" component={LeaveBlank} extra={["locationName"]} white={inline} />
      </div>
    );
  },

  initialize: data => {
    return {
      orgLocationId: data && data.organizationLocationId,
      location: data && data.location,
      locationName: (data && data.customLocationName) || "",
    };
  },

  finalize: async ({ location, locationName }, data) => {
    const newTz = location?.id && data?.location?.id !== location.id;
    const getTimezone = async () => {
      if (newTz) {
        const { timezone } = await getTimeWithZone(new Date(), location);
        return timezone;
      } else {
        return null;
      }
    };
    const timezone = await getTimezone();

    if (!location && !locationName) {
      return {
        locationId: null,
        locationName: null,
      };
    } else if (isVirtualLocation(location)) {
      return {
        locationName,
        locationId: location && location.id,
        ...(newTz && { timezone }),
      };
    }

    return {
      locationId: location && location.id,
      locationName,
      ...(newTz && { timezone }),
    };
  },

  fragment: locationFragment,

  dependencies: () => [
    { dep: GigDateField, save: true },
    { dep: GigRoleField, save: true },
  ],
};
