import React from "react";
import { Query } from "@apollo/client/react/components";

import { BrandFilterItemsQueryVariables, BrandFilterItemsQuery } from "gql-gen";

import brandsQuery from "./brands.gql";

import SearchableMultiSelect from "../LegacySearchable/SearchableMultiSelect";

interface Props {
  value: string[];
  onChange: (brands: string[] | null) => void;
}

export function BrandFilter({ value, onChange }: Props) {
  return (
    <Query<BrandFilterItemsQuery, BrandFilterItemsQueryVariables> query={brandsQuery}>
      {({ data: { brands } = { brands: null } }) => {
        return (
          <SearchableMultiSelect
            name={"GROUP"}
            placeholder={"All Groups"}
            selectedItems={value}
            items={brands?.edges.map(t => t.node) ?? null}
            onChange={onChange}
          />
        );
      }}
    </Query>
  );
}
