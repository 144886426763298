import i18n from "i18next";
import * as react_i18next from "react-i18next";
import { format as formatDate } from "date-fns";
import resources from "./en-US.json";

i18n.use(react_i18next.initReactI18next).init({
  lng: "en-US",
  defaultNS: "default",

  interpolation: {
    escapeValue: false,

    format: function (value, format) {
      if (value instanceof Date && format) {
        return formatDate(value, format);
      }

      return value;
    },
  },
});

i18n.addResourceBundle("en-US", "default", resources);

type JSON = string | number | boolean | Array<JSON> | JsonRecord;

interface JsonRecord {
  [key: string]: JSON;
}

type GetPaths<Obj extends JsonRecord> = {
  [K in Extract<keyof Obj, string>]: Obj[K] extends JsonRecord
    ? GetPaths<Obj[K]> extends infer Inner
      ? Inner extends string
        ? `${K}.${Inner}`
        : never
      : never
    : K;
}[Extract<keyof Obj, string>];

type i18nPaths = GetPaths<typeof resources>;

export function useTranslation() {
  const [t] = react_i18next.useTranslation();
  return {
    t: (path: i18nPaths, options?: i18n.TOptions) => t(path, options),
  };
}

export function isValid18nPath(path: string): path is i18nPaths {
  return i18n.exists(path);
}

export default i18n;
