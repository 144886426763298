import * as React from "react";
import styles from "./styles.scss";
import { TextGroup, TextNode } from "components/Text";
import { useTranslation } from "react-i18next";

export default function EmptyTableMessage() {
  const { t } = useTranslation();
  return (
    <TextGroup align="center" color="gray1" size={14} className={styles.container}>
      <TextNode bold color={"gray2"}>
        {t("program.forms.team.noUsers")}
      </TextNode>
      <TextNode color={"gray2"}>{t("program.forms.team.reminder")}</TextNode>
    </TextGroup>
  );
}
