import * as React from "react";
import moment from "moment";

import Text from "components/LegacyText";

import styles from "./styles.scss";

import * as FieldValue from "../FieldValue";
import ProgramInfo from "../ProgramInfo";

interface Props {
  executionType?: string;
}

export default function Overview({ executionType }: Props) {
  return (
    <React.Fragment>
      <FieldValue.Base label="Target Dates">
        {({ startDate, endDate }) =>
          startDate && endDate ? moment(startDate).format("ll") + " — " + moment(endDate).format("ll") : null
        }
      </FieldValue.Base>

      {executionType !== "solo" && (
        <FieldValue.Base label="Markets">
          {({ states }) => {
            return states ? states.map((s: { label: string }) => s.label).join("; ") : null;
          }}
        </FieldValue.Base>
      )}
      <FieldValue.Text name="description" label="Program desc." />
      <FieldValue.Text name="locationDescription" label="Location desc." />
      <ProgramInfo.Consumer>
        {programInfo => {
          const { singleTalentOrganization } = programInfo;
          return singleTalentOrganization ? (
            <>
              <FieldValue.Text name="budget" label="Target (Spend)" />
              {programInfo.capacityLimit === null ? (
                <FieldValue.Text data-test="target-tasks" name="estimatedGigsCount" label="Target (Tasks)" />
              ) : (
                <FieldValue.Base data-test="task-limit" label="Task Limit">
                  {() => {
                    return `${programInfo.capacityGigCount} / ${programInfo.capacityLimit}`;
                  }}
                </FieldValue.Base>
              )}
            </>
          ) : null;
        }}
      </ProgramInfo.Consumer>
      <Text.P4 kind="secondary" className={styles.note}>
        Note: Information on this page was generated at the time of the original program request
      </Text.P4>
    </React.Fragment>
  );
}
