import _ from "lodash";

/*
 * @deprecated
 * Please use utilities/storageScope instead
 */

const localStorage = window.localStorage || { getItem: _.noop, setItem: _.noop };

export interface LocalStorage<T> {
  get: (path?: string) => T | {};
  update: (prop: string, value: {}) => T | {};
  clear: () => void;
}

export default <T>(section: string): LocalStorage<T> => ({
  get(path) {
    try {
      const parsed = JSON.parse(localStorage.getItem(section) || "{}") || {};
      return path ? _.get(parsed, path) : parsed;
    } catch (e) {
      console.log(e);
      return {};
    }
  },

  update(prop: string, value: any) {
    const storage: any = this.get();

    try {
      storage[prop] = value;
      localStorage.setItem(section, JSON.stringify(storage));
    } catch (e) {
      console.log(e);
    }
    return this.get();
  },

  clear() {
    // We're having some trouble with this method on safari. Try multiple
    // fallbacks before giving up.
    try {
      localStorage.setItem(section, "{}");
    } catch (e) {
      try {
        localStorage[section] = "{}";
      } catch (_e) {
        console.log(_e);
      }
    }
  },
});
