import * as React from "react";
import _ from "lodash";
import cx from "classnames";
import Icon from "components/Icon";
import { transformImage } from "utilities/filestack";
import userIcon from "assets/user.svg";
import variables from "styles/variables";
import styles from "./styles.scss";

interface Props {
  user?: { assets?: any[] } | null;
  small?: boolean;
  square?: boolean;
  fadeToWhite?: boolean;
  className?: string;
  xl?: boolean;
  testId?: string;
}

export default function UserAvatar({ user, small, square, fadeToWhite, className, xl, testId }: Props) {
  let imageUrl;

  if (user && user.assets) {
    const profileImageAsset = _.find(
      _.sortBy(user.assets, a => (a.isPrimary ? 0 : 1)),
      {
        assetType: "profile_image",
      } as any,
    );

    imageUrl =
      profileImageAsset &&
      profileImageAsset.url &&
      transformImage(profileImageAsset.url, {
        resize: { width: 1000, fit: "max" },
      });
  }

  const createImageStyle = (imageUrl?: string | null) => {
    if (!imageUrl) {
      return {};
    }
    if (fadeToWhite) {
      return {
        backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 70%,
        rgba(242,246,250, 0.2) 80%,
        rgba(242,246,250, 0.6) 90%,
        rgba(242,246,250, 1) 100%
      ), url(${imageUrl})`,
      };
    } else {
      return { backgroundImage: `url(${imageUrl})` };
    }
  };

  return (
    <div
      className={cx(
        styles.avatar,
        small && styles.small,
        xl && styles.xl,
        square && styles.square,
        className,
        !imageUrl && styles.noImage,
      )}
      style={createImageStyle(imageUrl)}
      data-test={testId}
    >
      {!imageUrl && <Icon src={userIcon} size={30} fill={variables.white} />}
    </div>
  );
}
