import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose, groupBy, some } from "lodash";
import * as React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { withFormik, FormikProps } from "formik";
import * as yup from "yup";
import Switch from "components/Switch";
import Form from "components/Form";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { withAccordionContext, AccordionContextProps } from "components/AccordionLayout/AccordionContext";
import Text from "components/LegacyText";
import { User } from "interfaces/user";
import { Helper1, Helper2, HelperHiLi } from "../FormHelpers";
import { withProgramFormContext, validateAndNotify, ProgramFormContext } from "../Context";
import userDetails from "./userDetails.gql";
import usersQuery from "./users.gql";
import styles from "./styles.scss";
import SaveButton from "../SaveButton";

import EmptyTableMessage from "./EmptyTableMessage";
import UsersTable from "../../../LegacySettings/UsersTable/Table";
import { ProgramsRouteParams } from "../../index";
import { withUserInfo, UserInfoQuery } from "modules/Dashboard/UserInfo";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import { useState } from "react";
import Clickable from "components/Clickable";
import { IProgramTeamMember } from "../IProgramRequest";
import ProgramInfo from "modules/Dashboard/Organization/Programs/Overview/ProgramInfo";

interface TeamValues {
  dashboardUsers: IProgramTeamMember[];
  selfSchedulers: IProgramTeamMember[];
  requesters: IProgramTeamMember[];
  isApprovalRequired: boolean;
}

interface UserDetailsQuery {
  organization: { id: string; name: string; gigSelfAssignmentActive: boolean; gigRequestsActive: boolean } | null;
  me: User | null;
}

interface UsersQuery {
  users: User[];
  loading: boolean;
}

interface Props extends FormikProps<TeamValues>, RouteComponentProps<ProgramsRouteParams>, UserInfoQuery {
  programForm: ProgramFormContext;
  data: UsersQuery;
  userDetailsQuery: UserDetailsQuery;
  accordionState: AccordionContextProps;
}

type Access = "all" | "specific" | "none";
type UserScope = "dashboardUsers" | "selfSchedulers" | "requesters";

const ID = "team";

interface Available {
  selfSchedulers: User[];
  dashboardUsers: User[];
  requesters: User[];
}

const sortAvailableUsersByFirstNameAlphabetically = (a: User, b: User) => a.firstName.localeCompare(b.firstName);

function Team(props: Props) {
  const {
    values,
    programForm: { canEdit },
    setFieldValue,
    match: {
      url,
      params: { orgId },
    },

    location,
    accordionState: { showHelpersColumn },
    data: { users, loading: usersLoading },
    userDetailsQuery: { organization },
    userInfo,
  } = props;

  const initialUsers = { dashboardUsers: [], selfSchedulers: [], requesters: [] };
  const [available, setAvailable] = useState<Available>(initialUsers);

  const sortedUsers = users ? users.sort(sortAvailableUsersByFirstNameAlphabetically) : [];
  const stableUsersString = sortedUsers.map(x => x.id).join(",");

  React.useEffect(() => {
    if (users) {
      setAvailable({
        ...available,
        ...groupBy(sortedUsers, ({ userType, organizationUser }) => {
          if (userType !== "gogetter") {
            return "dashboardUsers";
          } else if (organizationUser.canSelfAssign) {
            return "selfSchedulers";
          } else if (organizationUser.canRequest) {
            return "requesters";
          } else {
            return "ignored";
          }
        }),
      } as { selfSchedulers: User[]; dashboardUsers: User[]; requesters: User[] });
    }
  }, [stableUsersString]);

  const [dashboardAccess, setDashboardAccess] = useState<Access>("all");
  const [selfSchedulersAccess, setSelfSchedulersAccess] = useState<Access>("none");
  const [requestersAccess, setRequestersAccesss] = useState<Access>("none");

  const programInfo = React.useContext(ProgramInfo);
  const { team }: { team?: Array<{ userId: string; isContact: boolean }> } = programInfo;

  React.useEffect(() => {
    if (available) {
      if (team !== undefined && team.length > 0) {
        const initiallySelectedDashboardUsers = available.dashboardUsers
          .map(availableDashboardUser =>
            makeTeamMember({ isContact: !!team.find(x => x.userId === availableDashboardUser.id)?.isContact })(
              availableDashboardUser,
            ),
          )
          .filter(user => team.map(({ userId }) => userId).includes(user.userId));

        // Initialize current team members as selected
        setFieldValue("dashboardUsers", initiallySelectedDashboardUsers);

        // Use initiallySelectedDashboardUsers, not values.dashboardUsers,
        // when calculating dashboard access radio button value
        setDashboardAccess(getDashboardAccessSetting(available.dashboardUsers, initiallySelectedDashboardUsers));
      } else if (userInfo.me) {
        // In "Sharing & Notifications" section in "Create a new program" view,
        // where `team` has not been built yet & is not available for use.
        // We also need to add current user to the list, otherwise they won't be able to see the program.
        const users = [
          ...available.dashboardUsers,
          {
            userId: userInfo.me.id,
            userType: userInfo.me.organizationUser.type,
            ...userInfo.me,
          },
        ];

        const adminsAndOwners = users
          .map(makeTeamMember({ isContact: true }))
          .filter(user => user.userType === "admin" || user.userType === "owner" || user.userId === userInfo.me?.id);

        setFieldValue("dashboardUsers", adminsAndOwners);

        setDashboardAccess(getDashboardAccessSetting(users, adminsAndOwners));
      }

      setSelfSchedulersAccess(getAccessSetting(available.selfSchedulers, values.selfSchedulers));
      setRequestersAccesss(getAccessSetting(available.requesters, values.requesters));
    }
  }, [available, userInfo.me]);

  const updateSelectedDashboardUsers = (params: Partial<IProgramTeamMember>) => (ids: string[]) => {
    const previouslySelectedUsers = values.dashboardUsers.map(u => u.userId);
    const diffIds = ids.filter(id => !previouslySelectedUsers.includes(id));

    if (!available || !available.dashboardUsers.length) return;

    const adminsAndOwners = available.dashboardUsers
      .filter(user => user.userType === "admin" || user.userType === "owner")
      .map(u => u.id);

    const newUsers = (userIds: string[]) =>
      userIds.map(id => {
        const user = available.dashboardUsers.find(u => u.id === id)!;
        const teamMember = values.dashboardUsers.find(u => u.userId === id)!;
        if (diffIds.includes(id)) {
          return makeTeamMember(params)(user);
        }
        return makeTeamMember({
          isContact: teamMember.isContact,
        })(user);
      });

    setFieldValue("dashboardUsers", ids.length === 0 ? newUsers(adminsAndOwners) : newUsers(ids));
  };

  const updateSelectedSelfSchedulers = (params: Partial<IProgramTeamMember>) => (ids: string[]) => {
    const previouslySelectedUsers = values.selfSchedulers.map(u => u.userId);
    const diffIds = ids.filter(id => !previouslySelectedUsers.includes(id));

    if (!available || !available.selfSchedulers.length) return;

    const newUsers = (userIds: string[]) =>
      userIds.map(id => {
        const user = available.selfSchedulers.find(u => u.id === id)!;
        const teamMember = values.selfSchedulers.find(u => u.userId === id)!;
        if (diffIds.includes(id)) {
          return makeTeamMember(params)(user);
        }
        return makeTeamMember({
          canSelfAssign: !!teamMember?.canSelfAssign,
        })(user);
      });

    setFieldValue("selfSchedulers", ids.length === 0 ? previouslySelectedUsers : newUsers(ids));
  };

  const updateSelectedRequesters = (params: Partial<IProgramTeamMember>) => (ids: string[]) => {
    const previouslySelectedUsers = values.requesters.map(u => u.userId);
    const diffIds = ids.filter(id => !previouslySelectedUsers.includes(id));

    if (!available || !available.requesters.length) return;

    const newUsers = (userIds: string[]) =>
      userIds.map(id => {
        const user = available.requesters.find(u => u.id === id)!;
        const teamMember = values.requesters.find(u => u.userId === id)!;
        if (diffIds.includes(id)) {
          return makeTeamMember(params)(user);
        }
        return makeTeamMember({
          canRequest: !!teamMember?.canRequest,
        })(user);
      });

    setFieldValue("requesters", ids.length === 0 ? previouslySelectedUsers : newUsers(ids));
  };

  return (
    <React.Fragment>
      <AccordionPart>
        <AccordionPartHelper>
          <Helper1>
            All users within your PINATA organization are shown in the table to the right. Select any additional users
            who should have access to this program.
          </Helper1>
          <HelperHiLi>
            By default, this Program will be visible to Owners and Adminstrators on your team, in addition to any
            Partners selected above.
          </HelperHiLi>
          <Helper2>You can also set Program-specific notification preferences for any user.</Helper2>
          {showHelpersColumn && (
            <Helper2>
              Don’t see someone you’re looking for?
              <br />
              <Link to={{ ...location, pathname: url + "/+invite-users" }}>
                <Text.NewLink4>Add a new user</Text.NewLink4>
              </Link>
            </Helper2>
          )}
        </AccordionPartHelper>

        <AccordionPartContent>
          <div className={styles.usersSection}>
            <Text.H2>Dashboard Access</Text.H2>
            <Text.P4 kind="secondary">
              Your company currently has {(available?.dashboardUsers ?? []).length} Dashboard User(s) who could access
              this dashboard to create and manage tasks, view reports, and more. Which ones should have access to this
              Program?
            </Text.P4>
            <RadioGroup
              value={available?.dashboardUsers.length > 0 ? dashboardAccess : undefined}
              onChange={(newAccessStr: string | null) => {
                if (!available) return;

                const newAccess = newAccessStr as Access;
                setDashboardAccess(newAccess);
                if (newAccess === "all") {
                  setFieldValue("dashboardUsers", available.dashboardUsers.map(makeTeamMember({ isContact: true })));
                } else if (newAccess === "none") {
                  setFieldValue(
                    "dashboardUsers",
                    values.dashboardUsers
                      .filter(user => user.userType === "admin" || user.userType === "owner")
                      .map(user => ({ ...user, isContact: false })),
                  );
                }
              }}
            >
              <RadioButton value="all">All current Dashboard Users</RadioButton>
              <RadioButton value="specific">Specific users...</RadioButton>
              <RadioButton value="none">Only me</RadioButton>
            </RadioGroup>
            {dashboardAccess === "specific" && (
              <UsersTable
                userInfo={userInfo}
                orgId={orgId}
                data={{
                  loading: usersLoading,
                  users: available?.dashboardUsers ?? [],
                }}
                isUserTypeSelectable={userType => userType !== "owner" && userType !== "admin"}
                tableProps={{
                  emptyMessage: <EmptyTableMessage />,
                  itemsPerPage: 10,
                  selected: values.dashboardUsers.map(u => u.userId),
                  onSelectionChange:
                    canEdit && available?.dashboardUsers
                      ? updateSelectedDashboardUsers({ isContact: true })
                      : undefined,
                  displaySelected: {
                    renderCounter: count =>
                      count === 1 ? "One dashboard user selected" : `${count} dashboard users selected`,
                    data: values.dashboardUsers.map(makeSelectedLabel),
                    renderItemToggle: userId => {
                      const users = values.dashboardUsers;
                      const user = users.find(u => u.userId === userId);
                      const isChecked = user ? user.isContact : false;
                      return (
                        <Switch
                          onChange={() => {
                            setFieldValue(
                              "dashboardUsers",
                              values.dashboardUsers.map(user => {
                                if (user.userId === userId) {
                                  return { ...user, isContact: !user.isContact };
                                }
                                return { ...user };
                              }),
                            );
                          }}
                          checked={isChecked}
                          labelText={"Receive notifications?"}
                          id={userId}
                        />
                      );
                    },
                    renderToggleAll: (
                      <Clickable
                        onClick={() => {
                          const anyUnselected = some(values.dashboardUsers, { isContact: false });
                          setFieldValue(
                            "dashboardUsers",
                            values.dashboardUsers.map(user => {
                              return { ...user, isContact: anyUnselected };
                            }),
                          );
                        }}
                      >
                        <Text.H3 className={styles.toggleAll}>Toggle All</Text.H3>
                      </Clickable>
                    ),
                    disableRemove: !canEdit,
                  },
                }}
              />
            )}
          </div>

          <div className={styles.usersSection}>
            <Text.H2>Anyone can be assigned to work in this Program</Text.H2>
            <Text.P4 kind="secondary">
              All GoGetters can work on tasks in this Program. You do not need to select them here.
            </Text.P4>
          </div>

          {organization?.gigSelfAssignmentActive && (
            <div className={styles.usersSection}>
              <Text.H2>Who can create tasks Autonomously?</Text.H2>
              <Text.P4 kind="secondary">
                Your company currently has {available?.selfSchedulers.length} Gogetter(s) who are Autonomous. Which ones
                should be eligible to create their own tasks in this Program? As a reminder, all dashboard users always
                have this ability.
              </Text.P4>

              <RadioGroup
                value={selfSchedulersAccess}
                onChange={(newAccessStr: string | null) => {
                  if (!available) return;

                  const newAccess = newAccessStr as Access;

                  setSelfSchedulersAccess(newAccess);

                  if (newAccess === "all") {
                    setFieldValue(
                      "selfSchedulers",
                      available.selfSchedulers.map(makeTeamMember({ canSelfAssign: true })),
                    );
                  } else {
                    setFieldValue("selfSchedulers", []);
                  }
                }}
              >
                <RadioButton value="all">All current Autonomous users</RadioButton>
                <RadioButton value="specific">Specific users...</RadioButton>
                <RadioButton value="none">No one can create tasks autonomously in this program</RadioButton>
              </RadioGroup>

              <Text.P4 className={styles.note}>
                Note: Autonomous users will receive standard notifciations about their own tasks
              </Text.P4>

              {selfSchedulersAccess === "specific" && (
                <UsersTable
                  userInfo={userInfo}
                  orgId={orgId}
                  data={{
                    loading: usersLoading,
                    users: available?.selfSchedulers ?? [],
                  }}
                  tableProps={{
                    emptyMessage: <EmptyTableMessage />,
                    itemsPerPage: 10,
                    selected: values.selfSchedulers.map(u => u.userId),
                    onSelectionChange:
                      canEdit && available?.selfSchedulers
                        ? updateSelectedSelfSchedulers({ canSelfAssign: true })
                        : undefined,
                    displaySelected: {
                      renderCounter: count =>
                        count === 1 ? "One Self-scheduler selected" : `${count} Self-scheduler selected`,
                      data: values.selfSchedulers.map(makeSelectedLabel),
                      disableRemove: !canEdit,
                    },
                  }}
                />
              )}
            </div>
          )}

          {organization?.gigRequestsActive && (
            <div className={styles.usersSection}>
              <Text.H2>Who can Request tasks?</Text.H2>
              <Text.P4 kind="secondary">
                Your company curently has {available?.requesters.length} Gogetter(s) who can submit requests for
                designated tasks. Which ones should be allowed to submit requests in this Program? As a reminder, all
                dashboard users always have this ability.
              </Text.P4>

              <RadioGroup
                value={requestersAccess}
                onChange={(newAccessStr: string | null) => {
                  if (!available) return;

                  const newAccess = newAccessStr as Access;

                  setRequestersAccesss(newAccess);

                  if (newAccess === "all") {
                    setFieldValue("requesters", available.requesters.map(makeTeamMember({ canRequest: true })));
                  } else {
                    setFieldValue("requesters", []);
                  }
                }}
              >
                <RadioButton value="all">All current Requesters</RadioButton>
                <RadioButton value="specific">Specific users...</RadioButton>
                <RadioButton value="none">No one can request in this Program</RadioButton>
              </RadioGroup>

              <Text.P4 className={styles.note}>Note: Requesters do not receive email notifications</Text.P4>

              {requestersAccess === "specific" && (
                <UsersTable
                  userInfo={userInfo}
                  orgId={orgId}
                  data={{
                    loading: usersLoading,
                    users: available?.requesters ?? [],
                  }}
                  tableProps={{
                    emptyMessage: <EmptyTableMessage />,
                    itemsPerPage: 10,
                    selected: values.requesters.map(u => u.userId),
                    onSelectionChange:
                      canEdit && available?.requesters ? updateSelectedRequesters({ canRequest: true }) : undefined,
                    displaySelected: {
                      renderCounter: count => (count === 1 ? "One Requester selected" : `${count} Requester selected`),
                      data: values.requesters.map(makeSelectedLabel),
                      disableRemove: !canEdit,
                    },
                  }}
                />
              )}
            </div>
          )}

          {((!!userInfo.me?.userType && userInfo.me.userType === "super_admin") || userInfo.me?.isAdmin) && (
            <Form.Section className={styles.toggleSection}>
              <div className={styles.toggleContainer}>
                <div className={styles.gigApprovalBody}>
                  <Switch
                    id="approvalRequiredSwitch"
                    onChange={() => {
                      setFieldValue("isApprovalRequired", !values.isApprovalRequired);
                    }}
                    checked={values.isApprovalRequired}
                    className={styles.toggle}
                  />

                  <Text.Display5 className={styles.toggleLabel}>Enable Gig Approvals for this program</Text.Display5>
                </div>
              </div>
            </Form.Section>
          )}
          <SaveButton id={ID} topMargin />
        </AccordionPartContent>
      </AccordionPart>
    </React.Fragment>
  );
}

function getDashboardAccessSetting(available: User[], selected: IProgramTeamMember[]): Access {
  const selectedAdminsOwners = selected.filter(
    teamMember => teamMember.userType === "admin" || teamMember.userType === "owner",
  );
  const availableAdminsOwners = available.filter(user => user.userType === "admin" || user.userType === "owner");

  const onlyAdminsOwnersSelected = selectedAdminsOwners.length === selected.length;
  const teamOnlyHasAdminsOwners = availableAdminsOwners.length === available.length;
  const allAvailableSelected = available.length === selected.length;
  const noneAvailableSelected = selected.length === 0;

  const allSelectedNotificationsOn = selected.every(member => member.isContact);
  const allAdminsOwnersNotificationsOn = selectedAdminsOwners.every(member => member.isContact);
  const allAdminsOwnersNotificationsOff = selectedAdminsOwners.every(member => !member.isContact);

  if ((onlyAdminsOwnersSelected && allAdminsOwnersNotificationsOff) || noneAvailableSelected) {
    return "none";
  } else if (
    (teamOnlyHasAdminsOwners && allAdminsOwnersNotificationsOn) ||
    (allAvailableSelected && allSelectedNotificationsOn)
  ) {
    return "all";
  } else {
    return "specific";
  }
}

function getAccessSetting(available: User[], selected: IProgramTeamMember[]): Access {
  if (selected.length === 0) {
    return "none";
  } else if (available.length === selected.length) {
    return "all";
  } else {
    return "specific";
  }
}

const makeTeamMember = (params: Partial<IProgramTeamMember>) => (u: User): IProgramTeamMember => ({
  userId: u.id,
  userType: u.userType,
  firstName: u.firstName,
  lastName: u.lastName,
  isContact: false,
  canSelfAssign: false,
  canRequest: false,
  ...params,
});

const makeSelectedLabel = ({ firstName, lastName, userId }: IProgramTeamMember) => ({
  label: firstName + " " + lastName,
  id: userId,
});

const schema = yup.object().shape({
  team: yup.array().of(
    yup
      .object()
      .shape({
        userId: yup
          .string()
          .required()
          .nullable(),
        isContact: yup
          .boolean()
          .required()
          .nullable(),
      })
      .nullable(),
  ),
});

export default compose(
  withRouter,
  withAccordionContext,
  withProgramFormContext,
  withUserInfo,

  graphql<Props, UserDetailsQuery, { orgId: string }, unknown>(userDetails, {
    name: "userDetailsQuery",
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
      fetchPolicy: "network-only",
    }),
  }),

  graphql<Props, UsersQuery, { orgId: string }, unknown>(usersQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
      fetchPolicy: "network-only",
    }),
  }),

  withFormik<Props, TeamValues>({
    mapPropsToValues: ({
      programForm: {
        values: { dashboardUsers = [], selfSchedulers = [], requesters = [], isApprovalRequired },
      },
    }) => {
      const filteredDashboardUsers = dashboardUsers.filter((x: IProgramTeamMember) => x.userType !== "gogetter");

      const filteredSelfSchedulers: Array<IProgramTeamMember> = selfSchedulers.filter(
        (x: IProgramTeamMember) => x.userType === "gogetter" && x.canSelfAssign,
      );

      const filteredRequesters = requesters.filter(
        (x: IProgramTeamMember) =>
          x.userType === "gogetter" &&
          x.canRequest &&
          !filteredSelfSchedulers.map((y: IProgramTeamMember) => y.userId).includes(x.userId),
      );

      return {
        // all non-gogetters with access
        dashboardUsers: filteredDashboardUsers,
        // all autonomous gogetters
        selfSchedulers: filteredSelfSchedulers,
        // gogetters who can request, but are not autonomous
        requesters: filteredRequesters,
        isApprovalRequired,
      };
    },

    validate: validateAndNotify(schema, schema, schema, ID),
    handleSubmit: () => { },
  }),
)(Team);
