import React, { useState, useMemo, ReactNode, useEffect, useRef } from "react";
import { InfiniteLoader, List, AutoSizer, WindowScroller } from "react-virtualized";
import _, { pick } from "lodash";
import cx from "classnames";
import { DocumentNode } from "graphql";
import useRouter from "use-react-router";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { NetworkStatus } from "@apollo/client";

import closeIcon from "assets/cancel-nobg.svg";
import warnIcon from "assets/warn-circle.svg";

import Clickable from "components/Clickable";
import LoadingContainer from "components/LoadingContainer";
import Icon from "components/Icon";
import Button from "components/Button";
import Text from "components/Text";
import { Mobile, useTabletQuery } from "components/Layout/Responsive";
import Popup from "components/Popup";
import ConfirmBox from "components/ConfirmBox";
import { colors } from "styles/variables";
import { useInfiniteLoader } from "hooks/useInfiniteLoader";
import { usePromise } from "hooks/usePromise";
import { useFilterCollectionCtx } from "modules/Connection/FilterCollection";
import { ConnectionConfig } from "utilities/connections";
import { useReloaderCallback } from "modules/Connection/Reloader";
import { useScope, ScopeCtx, SrcScope } from "modules/Connection/Scope";
import { getUrlQueries, omitQueries } from "utilities/routes";
import { buildLogicalExpression, idIn, idNotIn } from "utilities/knueppel";
import { toBase64 } from "utilities/base64";

import { ActionTitle } from "../ActionTitle";
import FiltersGroup from "../Filters/FiltersGroup";
import styles from "./styles.scss";

import { Row, RowPlaceholder, RowProps } from "./Row";
import { Item, Column, IAction } from "../types";

export type TableMode = "compact" | "regular" | "detailed";

interface Props<CC extends ConnectionConfig<Item>> extends SrcScope<CC> {
  columns: Column<any>[];
  children: (info: {
    totalCount: number | null;
    loading: boolean;
    error: boolean;
    setFiltersVisible: (v: boolean) => void;
    filtersVisible: boolean;
    columns: Column<any>[];
  }) => ReactNode;
  renderFooterButtons?: (className: string) => ReactNode;
  renderCounter: (count: { count: number; totalCount: number }) => ReactNode;
  renderRow?: (props: RowProps<Item>) => ReactNode;
  multiSelectWarning: (count: number) => string;
  itemIndexQuery: DocumentNode;
  buildViewLink: (id: string) => string;
  onCellClick?: (id: string) => void;
  canBatchSelect?: boolean;
  onTotalCountChange?: (count: number | null) => void;
}

const INIT_COUNT = 10;
const defaultRowRenderer = (props: RowProps<Item>) => <Row {...props} />;

export function Table<CC extends ConnectionConfig<Item>>({
  connection,
  columns,
  actions,
  onSave,
  variables,
  children,
  renderRow = defaultRowRenderer,
  renderFooterButtons,
  renderCounter,
  getErrorMessage,
  itemIndexQuery,
  multiSelectWarning,
  onCellClick,
  buildViewLink,
  onTotalCountChange,
  canBatchSelect = true,
}: Props<CC>) {
  const {
    entry: { name: entry },
  } = connection;

  const { location } = useRouter();

  const { ast, ready, count: filterCount, reset: resetFilters } = useFilterCollectionCtx();

  const windowScrollerRef = useRef<WindowScroller>(null);
  let listRef = useRef<List>(null);

  useEffect(() => {
    setTimeout(() => {
      if (windowScrollerRef.current) windowScrollerRef.current.updatePosition();
    }, 3000);
  }, []);

  const { t } = useTranslation();

  const blocks = useMemo(
    () => columns.reduce((blocks, col) => ({ ...blocks, [col.id]: { cell: col.cell, form: col.form } }), {}),
    [columns],
  );

  const vars = useMemo(
    () => ({
      filters: ast,
      ...variables,
    }),
    [ast, variables],
  );

  const scope = useScope({ connection, variables: vars, ready, blocks, onSave, actions, getErrorMessage });

  const {
    latestResult,
    loaded,
    totalCount,
    loading,
    loadRows,
    loaderRef,
    isRowLoaded,
    reload,
    reset: resetLoader,
  } = useInfiniteLoader({
    query: scope.query,
    initialCount: INIT_COUNT,
    variables: vars,
    entryName: entry,
  });

  const [showError, setShowError] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    setShowError(!!latestResult?.error || latestResult?.networkStatus === NetworkStatus.error);
  }, [latestResult?.error, latestResult?.networkStatus]);

  useEffect(() => {
    if (onTotalCountChange) onTotalCountChange(totalCount);
  }, [totalCount, onTotalCountChange]);

  const [selected, setSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [batchLoading, setBatchLoading] = useState<boolean>(false);
  const [confirmBox, setConfirmBox] = useState<{
    visible: boolean;
    label: string;
    onYes: (e?: any) => void;
    icon: React.ComponentType<any>;
  } | null>(null);

  const { history } = useRouter();

  const { goto } = getUrlQueries(location.search);
  const wentTo = useRef<string | null>(null);

  const { data: gigIndex } = useQuery<{ connection: { pageInfo: { offset: number } } }>(itemIndexQuery, {
    variables: {
      filters: ast,
      after: goto,
    },
    fetchPolicy: "no-cache",
    skip: !goto,
  });

  const resetGoto = () => {
    if (wentTo.current === goto) {
      history.push({ ...history, search: omitQueries(location.search, ["goto"]) });
      wentTo.current = null;
    }
  };

  useEffect(() => {
    if (gigIndex && gigIndex.connection && listRef.current && latestResult && goto && wentTo.current !== goto) {
      wentTo.current = goto;
      listRef.current.scrollToRow(gigIndex.connection.pageInfo.offset);
    }
  }, [gigIndex, goto, latestResult]);

  useReloaderCallback(
    connection.name,
    async () => {
      resetGoto();
      await reload();
      setSelected([]);
      setSelectAll(false);
      setConfirmBox(null);
    },
    [reload],
  );

  useEffect(() => {
    resetGoto();
    setSelected([]);
    setSelectAll(false);
    setConfirmBox(null);
  }, [ast]);

  const selectedScope = useScope({
    connection: scope.connection,
    variables: {
      ...scope.variables,
      filters: buildLogicalExpression("&&", [scope.variables.filters, selectAll ? idNotIn(selected) : idIn(selected)]),
    },
    blocks: null,
    ready: true,
    onSave: scope.onSave,
    actions: scope.actions,
    getErrorMessage: scope.getErrorMessage,
  });

  const batchActions = usePromise(
    () =>
      selected.length || selectAll
        ? selectedScope.getActions(true).then(({ actions }) => actions)
        : Promise.resolve([]),
    [selected, selectAll],
  );

  function handleSelect(id: string) {
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
    } else {
      setSelected(_.without(selected, id));
    }
  }

  const [isBottomBarVisible, setBottomBarVisibility] = useState(false);

  function handleScroll() {
    if (!isBottomBarVisible && window.scrollY >= 375) {
      setBottomBarVisibility(true);
    } else if (isBottomBarVisible && window.scrollY <= 374) {
      setBottomBarVisibility(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const toggleSelectAll = () => {
    setSelected([]);
    setSelectAll(!selectAll);
  };

  const selectedLength = selectAll && totalCount ? totalCount - selected.length : selected.length;

  return (
    <ScopeCtx.Provider value={scope}>
      {children({
        totalCount: latestResult ? totalCount : null,
        loading,
        error: showError,
        setFiltersVisible,
        filtersVisible,
        columns,
      })}
      <Mobile>
        {isMobile => {
          return (
            <div
              role="table"
              className={styles.table}
              aria-colcount={columns.length}
              aria-rowcount={totalCount ?? undefined}
            >
              <FiltersGroup
                className={styles.stickyHeader}
                columns={columns}
                mode={isMobile ? "compact" : "regular"}
                onReset={resetFilters}
                anyFilters={filterCount > 0}
                filtersVisible={filtersVisible}
                setFiltersVisible={setFiltersVisible}
                count={totalCount ?? 0}
                loading={loading}
                selectAllActive={selectAll}
                onSelectAll={toggleSelectAll}
                allSelected={selectAll && selected.length === 0}
                isSelectable={canBatchSelect}
              />

              {showError && (
                <div className={styles.errorWrapper}>
                  <div className={styles.error}>
                    <div className={styles.errorHeader}>
                      <Text size={22} font="wes">
                        {t("components.table.error.title")}
                      </Text>
                      <Clickable onClick={() => setShowError(false)}>
                        <Icon src={closeIcon} size={20} fill={colors.gray3} />
                      </Clickable>
                    </div>
                    <Text size={14} top={"s"} bottom={"l"} lineHeight={18}>
                      {t("components.table.error.somethingWentWrong")}
                      <a className={"intercom-selector"}>
                        <Text size={14} inline underline="always">
                          {t("components.table.error.contactSupport")}
                        </Text>{" "}
                      </a>

                      {t("components.table.error.ifErrorPersists")}
                    </Text>
                    <Button
                      kind="primary"
                      className={styles.tryAgain}
                      onClick={() => {
                        resetLoader().then(() => setShowError(false));
                      }}
                    >
                      {t("components.table.error.tryAgain")}
                    </Button>
                  </div>
                </div>
              )}

              <InfiniteLoader
                ref={loaderRef}
                rowCount={totalCount ?? 300}
                isRowLoaded={isRowLoaded}
                minimumBatchSize={20}
                loadMoreRows={indexRange => loadRows(indexRange)}
              >
                {({ onRowsRendered, registerChild }) => (
                  <WindowScroller ref={windowScrollerRef}>
                    {({ height, isScrolling, onChildScroll, scrollTop }) => (
                      <AutoSizer disableHeight>
                        {({ width }) => {
                          const w = width - 10;

                          return (
                            <List
                              className={styles.rows}
                              role={"rowgroup"}
                              ref={ref => {
                                if (ref) {
                                  listRef = { ...listRef, current: ref };
                                }
                                return registerChild(ref);
                              }}
                              autoHeight
                              height={height}
                              isScrolling={isScrolling}
                              onScroll={onChildScroll}
                              scrollTop={scrollTop}
                              scrollToAlignment="center"
                              onRowsRendered={onRowsRendered}
                              overscanRowCount={30}
                              width={width}
                              rowCount={totalCount ?? 300}
                              rowHeight={isMobile ? 185 : 81}
                              rowRenderer={({ key, index, style }) => {
                                let content;

                                if (index in loaded) {
                                  const data = loaded[index].node;
                                  const checked = selectAll ? !selected.includes(data.id) : selected.includes(data.id);
                                  content = renderRow({
                                    mode: isMobile ? "compact" : "regular",
                                    data: data,
                                    columns: columns,
                                    checked: checked,
                                    onCheck: handleSelect,
                                    rowNumber: index + 1,
                                    highlight: data.id === goto,
                                    onCellClick,
                                    buildViewLink,
                                    isSelectable: canBatchSelect,
                                    isEditable: !data.finalVerification?.isVerified,
                                  });
                                } else {
                                  content = <RowPlaceholder width={w} height={isMobile ? 164 : 80} />;
                                }

                                return (
                                  <div key={key} style={style} data-test="connections.table.row">
                                    {content}
                                  </div>
                                );
                              }}
                            />
                          );
                        }}
                      </AutoSizer>
                    )}
                  </WindowScroller>
                )}
              </InfiniteLoader>

              <ScopeCtx.Provider value={selectedScope}>
                {" "}
                <div
                  className={cx(
                    styles.actionBar,
                    (selectedLength > 0 || selectAll || isBottomBarVisible) && styles.showActions,
                  )}
                >
                  <div className={cx(styles.contentWrapper, styles.bottomBar)}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Text color={"white"} font={"lato"} size={16}>
                        {renderCounter({
                          count: selectedLength,
                          totalCount: totalCount ?? 0,
                        })}
                      </Text>
                    </div>

                    <div className={styles.actions}>
                      {isBottomBarVisible && (!batchActions.result || batchActions.result.length <= 0) && (
                        <>
                          <div className={styles.thinButton}>
                            <Button kind={"greenGradient"} onClick={() => setFiltersVisible(!filtersVisible)}>
                              {t("components.table.editFilters")}
                            </Button>
                          </div>
                          {renderFooterButtons && renderFooterButtons(styles.thinButton)}
                        </>
                      )}

                      {batchActions.result &&
                        batchActions.result.length > 0 &&
                        batchActions.result.map(({ action, count }) => {
                          const warn = count < selectedLength && batchActions.state === "resolved";

                          return (
                            <>
                              {confirmBox && (
                                <ConfirmBox
                                  icon={confirmBox.icon}
                                  title={t(confirmBox.label)}
                                  onNo={() => {
                                    setBatchLoading(false);
                                    setConfirmBox(null);
                                  }}
                                  onYes={confirmBox.onYes}
                                  yesDisabled={batchLoading}
                                >
                                  {selectedScope.error ? (
                                    t("components.table.batchActionsError")
                                  ) : batchLoading ? (
                                    <LoadingContainer color="white" />
                                  ) : (
                                    multiSelectWarning(selectedLength)
                                  )}
                                </ConfirmBox>
                              )}
                              <div key={action.key} className={styles.thinButton}>
                                {action.type === "modal" ? (
                                  <ActionButtonContent
                                    action={action}
                                    warn={warn}
                                    onClick={() => {
                                      history.push({
                                        pathname:
                                          location.pathname +
                                          action.path +
                                          toBase64(JSON.stringify(selectedScope.variables)),
                                        search: location.search,
                                      });
                                    }}
                                  />
                                ) : (
                                  <Popup
                                    onOpen={selectedScope.clearErrors}
                                    body={({ close }) => {
                                      const actionContent = React.createElement(action.component, {
                                        isBatchAction: true,
                                        done: () => {
                                          close();
                                          selectedScope.reload();
                                          setBatchLoading(false);
                                        },
                                        count,
                                        connection: selectedScope.connection,
                                        variables: pick(selectedScope.variables, [
                                          "filters",
                                          ...Object.keys(variables),
                                        ]),
                                        renderSubmit: ({ disabled, handleSubmit, i18n }) => {
                                          return (
                                            <Button
                                              type="submit"
                                              kind="primaryGradient"
                                              disabled={disabled}
                                              onClick={
                                                selectAll && selectedLength > 10 && action.multiSelectWarning !== false
                                                  ? () =>
                                                      setConfirmBox({
                                                        visible: true,
                                                        label: action.labelKey,
                                                        onYes: () => {
                                                          handleSubmit();
                                                          setBatchLoading(true);
                                                        },
                                                        icon: action.icon,
                                                      })
                                                  : handleSubmit
                                              }
                                              className={styles.actionSubmit}
                                            >
                                              {t(`${i18n}.submit`)}
                                            </Button>
                                          );
                                        },
                                        renderNavButtons: () => {
                                          return (
                                            <Button onClick={close}>{t("components.table.row.closeAction")}</Button>
                                          );
                                        },
                                      });

                                      return (
                                        <div className={styles.actionPopup}>
                                          {warn ? (
                                            <div className={styles.partialRun}>
                                              <div className={styles.warnIconVisible}>
                                                <Icon src={warnIcon} size={14} fill={"white"} />
                                              </div>

                                              <Text color="white" left={"s"}>
                                                {t("components.table.batchActionsWarning", {
                                                  totalCount: selectedLength,
                                                  runCount: count,
                                                  problemCount: selectedLength - count,
                                                })}
                                                <br />
                                                {t("components.table.affectedGigs", {
                                                  totalCount: selectedLength,
                                                  runCount: count,
                                                  problemCount: selectedLength - count,
                                                })}
                                              </Text>
                                            </div>
                                          ) : (
                                            <div className={styles.batchActionInfoHeader}>
                                              <div className={styles.warnIconVisible}>
                                                <Icon src={warnIcon} size={14} fill={"white"} />
                                              </div>

                                              <Text color="white" left={"s"}>
                                                {t("components.table.batchActionsInfo", {
                                                  totalCount: selectedLength,
                                                })}
                                                <br />
                                              </Text>
                                            </div>
                                          )}
                                          <div className={styles.actionContent}>
                                            <ActionTitle>{t(action.labelKey)}</ActionTitle>
                                            {actionContent}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  >
                                    <ActionButtonContent action={action} warn={warn} />
                                  </Popup>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </ScopeCtx.Provider>
            </div>
          );
        }}
      </Mobile>
    </ScopeCtx.Provider>
  );
}

function ActionButtonContent({ action, warn, onClick }: { warn: boolean; action: IAction; onClick?: () => void }) {
  const { t } = useTranslation();
  const isTablet = useTabletQuery();
  return (
    <Button kind={"primaryGradient"} style={{ background: action.color }} onClick={onClick}>
      <Icon src={action.icon} size={14} fill={"white"} />
      {!isTablet ? t(action.batchLabelKey || action.labelKey) : null}
      <div className={"span"} />
      <div className={cx(warn ? styles.warnIconVisible : styles.warnIcon, isTablet && styles.warnIconMobile)}>
        <Icon src={warnIcon} size={14} fill={"white"} />
      </div>
    </Button>
  );
}
