import React, { ReactNode } from "react";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
};

export function DetailCell({ title, children, className }: Props) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Text font="wes" bottom="xs" bold size={10}>
        {title}
      </Text>
      {children || <Text color={"gray2"}>{t("components.detailCell.notSpecified")}</Text>}
    </div>
  );
}
