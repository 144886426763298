import * as React from "react";
import Lightbox from "react-images";
import Spinner from "components/Spinner";
import FileOptions from "components/FileOptions";
import { transformImage, getFsId } from "utilities/filestack";
import { ImageInfo } from "../ImageGroup/context";

interface Props {
  images: ImageInfo[];
  currentImage: number | null;
  onImageChange: (param0: number) => void;
  isOpen: boolean;
  onClose?: () => void;
}

export default class ImageGallery extends React.PureComponent<Props> {
  private jump = (backwards: boolean) => () => {
    const { currentImage, onImageChange, images } = this.props;

    let newImage = 0;

    if (currentImage || currentImage === 0) {
      if (backwards) {
        newImage = currentImage > 0 ? currentImage - 1 : images.length - 1;
      } else {
        newImage = currentImage < images.length - 1 ? currentImage + 1 : 0;
      }
    }

    onImageChange(newImage);
  };

  private handleThumbClick = (index: number) => {
    this.props.onImageChange(index);
  };

  private getImages() {
    return this.props.images.map(image => {
      const id = getFsId(image.src);

      return {
        id,
        caption: image.caption,
        src: id ? transformImage(id) : image.src,
        thumbnail: id ? transformImage(id, { resize: { width: 100, fit: "max" } }) : image.src,
      };
    });
  }

  public render() {
    const { currentImage, isOpen, onClose } = this.props;

    const images = this.getImages();
    const currentObj = images[currentImage || 0];

    return (
      <Lightbox
        images={images}
        isOpen={isOpen}
        currentImage={currentImage || undefined}
        onClose={onClose || (() => {})}
        showCloseButton={!!onClose}
        onClickPrev={this.jump(true)}
        onClickNext={this.jump(false)}
        onClickThumbnail={this.handleThumbClick}
        spinner={() => <Spinner color={"white"} />}
        showThumbnails={true}
        closeButtonTitle={"Close (⎋)"}
        leftArrowTitle={"Previous (←)"}
        rightArrowTitle={"Next (→)"}
        customControls={
          currentObj && currentObj.id
            ? ([<FileOptions key={"controls"} fileId={currentObj.id} caption={currentObj.caption} />] as any)
            : undefined
        }
      />
    );
  }
}
