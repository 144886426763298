import { useEffect } from "react";
import { IAction } from "modules/Connection/types";
import duplicateIcon from "assets/duplicate.svg";
import { colors } from "styles/variables";
import useRouter from "use-react-router";
import { useScopeContext } from "modules/Connection/Scope";
import { addQueries, openModalPath } from "utilities/routes";

const i18n = "gigs.actions.duplicate";

export const DuplicateAction: IAction = {
  key: "duplication",
  icon: duplicateIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowInline: true,
  allowBatch: false,

  component: ({ done }) => {
    const {
      history,
      location: { search, pathname },
    } = useRouter();
    const { ids } = useScopeContext();

    useEffect(() => {
      if (ids && ids.length === 1) {
        done();
        history.push({
          search: addQueries(search, { from: ids[0] }),
          pathname: openModalPath(pathname, "book-gigs"),
        });
      } else {
        throw new Error("Tried to duplicate multiple tasks");
      }
    }, [ids]);

    return null;
  },
};
