@import "~styles/variables";

.container {
  display: flex;
  flex-direction: row;
}

.compactContainer {
  flex-direction: column;
}

.navigation {
  background-color: $gray-1;
  display: flex;
  flex-direction: column;
  min-width: 115px;
  box-sizing: border-box;
  justify-content: space-between;
}

.compactNavigation {
  padding: 0 5px;
  flex-direction: row;
  overflow-x: scroll;
}

.tab {
  display: flex;
  height: 28px;
  padding: 0 $s;
  align-items: center;
  margin-bottom: $s;
  @include transitionify(background-color);

  &:focus {
    border-radius: 0;
    box-shadow: none;
  }
}

.compactTab {
  height: 35px;
  margin: $s $s 0px $s;
  width: 100%;
}

.activeTab {
  composes: tab;
  background-color: $white;
}

.body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}

.footer {
  width: 100%;
  background-color: $gray-1;
}

.chevron {
  opacity: 0;
  @include transitionify(opacity);
}

.chevronVisible {
  opacity: 1;
}

.clearButton {
  height: 30px;
  padding: $s $l;
}

.compactClearButton {
  margin: $s 0;
  height: unset;
  align-self: center;
  padding: 0;
  flex: 1;
  text-align: end;
}

.tabs {
  padding: 30px 0 $l $m;
  flex: 1;
}

.compactTabs {
  display: flex;
  padding: 0;
}

.close {
  padding: $s;
}
