import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export type HVariant = "v1" | "v2" | "v3" | "v4";

export interface HProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  inline?: boolean;
  variant?: HVariant;
  kind?: "normal" | "secondary" | "tertiary" | "active" | "error" | "reverse";
}

export default function H({ children, inline, variant = "v1", kind = "normal", className, ...props }: HProps) {
  return (
    <div className={cx(styles.h, styles[kind], styles[variant], inline && styles.inline, className)} {...props}>
      {children}
    </div>
  );
}
