import React from "react";
import { useTranslation } from "react-i18next";
import icon from "assets/handoff.svg";
import { GigsCompanyFragment } from "gql-gen";
import { ICell } from "modules/Connection/types";
import { CellBase, CR1, CR2, AddDetails, CRNone } from "modules/Connection/CellBase";
import companyFragment from "./company.gql";
import Popup from "components/Popup";
import styles from "./styles.scss";
import { UserLink } from "../../../../UserLink";

export const CompanyCell: ICell<GigsCompanyFragment> = {
  component: props => {
    const { t } = useTranslation();

    const { data, handleOpenForm } = props;

    if (!data?.partnerOrganization && !data?.talentOrganization) {
      return (
        <CellBase {...props}>
          {() =>
            data?.program?.gigPartnerAssignment.canAssign ? (
              <AddDetails onClick={handleOpenForm}>{t("gigs.columns.company.assign")}</AddDetails>
            ) : (
              <CRNone>{t("gigs.columns.company.notAssigned")}</CRNone>
            )
          }
        </CellBase>
      );
    }

    const company = data.partnerOrganization ?? data.talentOrganization;

    const { talentContacts = [], id: gigId } = data;
    const [firstContact] = talentContacts;

    const contactRow = firstContact && (
      <CR2 underline={"hover"} color="gray1">
        {firstContact.firstName} {firstContact.lastName && firstContact.lastName[0]}.
        {talentContacts.length > 1 ? ` + ${talentContacts.length - 1}` : ""}
      </CR2>
    );

    return (
      <CellBase {...props}>
        {() => (
          <>
            <CR1>{company ? company.name : t("gigs.columns.company.internal")}</CR1>
            {firstContact &&
              (talentContacts.length > 1 ? (
                <Popup
                  body={() => (
                    <div className={styles.contactListWrapper}>
                      <ul className={styles.contactList}>
                        {talentContacts.map(({ id, firstName, lastName }) => (
                          <li key={id}>
                            <UserLink id={id} gigId={gigId} isOwner>
                              {firstName} {lastName}
                            </UserLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                >
                  {contactRow}
                </Popup>
              ) : (
                <UserLink id={firstContact.id}>{contactRow}</UserLink>
              ))}
          </>
        )}
      </CellBase>
    );
  },
  icon,
  fragment: companyFragment,
};
