@import "~styles/variables";

.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include print {
    display: none;

    .ReactModal__Content {
      display: block;
      height: auto;
      overflow-y: visible;
    }
  }
}

$side-padding: 50px;
$max-page-width: 1100px;

@mixin max-page-width($offset: 0) {
  max-width: $max-page-width - $offset;
  margin: 0 auto;
}

$field-height: 78px;

.cardDateField {
  margin-top: $s;

  @include mobile {
    margin-right: 0;
    max-width: 100%;
  }

  @include card;
  display: flex;
  flex-direction: column;
  padding: 17px ($form-el-side-padding - 8px) 9px;
  height: $field-height;
  box-sizing: border-box;

  :global {
    .SingleDatePickerInput {
      border: 0;
      background-color: transparent;
    }

    .DateInput {
      padding: 0;
    }

    .DateInput__display-text {
      color: $black;
      font-family: $Lato;
      font-size: 14px;
    }

    .DateInput__display-text--focused {
      background-color: transparent;
      color: inherit;
    }

    .DateInput--disabled {
      background: rgb(248, 250, 252) !important;

      .DateInput__display-text {
        font-style: normal;
      }
    }
  }
}
