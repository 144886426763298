import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";
import { ColumnSource } from "./Column";
import { ValueSource } from "./Value";
import { ISourceConfig } from "../../sources";
import { FieldConfig } from "../../fields";
import { UploaderContext } from "modules/Uploader/context";

export interface Props<T> {
  field: T;
  value: ISourceConfig;
  addedToPrevious: boolean;
  onChange: (value: ISourceConfig) => void;
}

export const defaultSource: ISourceConfig = { type: "column", join: "+" };

const ns = "importer.source";

export function Source<T extends FieldConfig>({
  field,
  value,
  value: { type },
  onChange,
  addedToPrevious: addedToPrevious,
}: Props<T>) {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UploaderContext);

  const SourceOption = ({ type: optionType }: { type: ISourceConfig["type"] }) => {
    const path = optionType === "value" ? `${optionType}.${field.type === "text" ? "text" : "pinata"}` : optionType;
    return (
      <Clickable
        className={type === optionType ? styles.sourceOptionActive : styles.sourceOption}
        data-test={`${ns}.options.value`}
        onClick={() => onChange({ type: optionType, join: "+" })}
      >
        <Text color="white" font="wes" bold size={12} noSelect>
          {t(`${ns}.options.${isAdmin ? "admin" : "user"}.${path}`)}
        </Text>
      </Clickable>
    );
  };

  return (
    <div className={styles.source} data-test={ns}>
      <div className={styles.sourceOptions} data-test={`${ns}.options`}>
        <SourceOption type={"column"} />
        <SourceOption type={"value"} />
      </div>

      {value.type === "column" ? (
        <ColumnSource value={value} onChange={onChange} field={field} />
      ) : (
        <ValueSource value={value} onChange={onChange} field={field} addedToPrevious={addedToPrevious} />
      )}
    </div>
  );
}
