import * as React from "react";
import copyToClipboard from "copy-to-clipboard";
import Icon from "components/Icon";
import Clickable from "components/Clickable";
import { getAppUrl } from "utilities/routes";
import { FS_CDN_BASE } from "utilities/filestack";
import downloadIcon from "assets/download.svg";
import arrow from "assets/arrow-up-right-from-square-solid.svg";
import linkIcon from "assets/link.svg";
import styles from "./styles.scss";
import { downloadBlob } from "utilities/files";
import sanitizeFilename from "sanitize-filename";

interface Props {
  fileId: string;
  caption?: string;
  fileName?: string;
}

async function downloadFile(url: string, fileName: string) {
  const response = await fetch(url, { method: "GET" });
  const blob = await response.blob();

  downloadBlob(blob, sanitizeFilename(fileName));
}

export default function FileOptions({ fileId, caption, fileName }: Props) {
  const imgUrl = `${FS_CDN_BASE}${fileId}`;

  const copyLink = () => {
    copyToClipboard(getAppUrl("open", fileId, caption ? { c: caption } : {}));
  };

  const defaultFileName = (caption ? caption : "image") + ".jpg";

  return (
    <div className={styles.fileOptions}>
      <a
        className={styles.downloadBtn}
        onClick={_ => downloadFile(`${imgUrl}?dl=true`, fileName || defaultFileName)}
        title="Download"
      >
        <Icon src={downloadIcon} fill={"white"} size={24} />
      </a>

      <Clickable actionLabel={"Copy link"} onClick={copyLink} className={styles.copyLinkBtn}>
        <Icon src={linkIcon} fill={"white"} size={20} />
      </Clickable>

      <Clickable
        actionLabel={"Open image"}
        onClick={() => window.open(imgUrl, "_blank")}
        className={styles.openImageBtn}
      >
        <Icon src={arrow} fill={"white"} size={20} />
      </Clickable>
    </div>
  );
}
