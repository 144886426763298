import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import approveIcon from "assets/check.svg";
import { colors } from "styles/variables";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import Section from "components/Form/Section";
import TextBox from "components/Form/TextBox";
import { inputProps } from "utilities/formik";
import { getProgramIdFromSearch } from "utilities/routes";
import approveGig from "./approveGig.gql";
import returnGig from "./returnGig.gql";
import cancelGig from "./cancelGig.gql";
import styles from "./styles.scss";
import { useApolloClient } from "@apollo/client";
import { ActionBody } from "modules/Connection/ActionBody";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";

const responses = [
  { key: "yes", mutation: approveGig },
  { key: "tbd", mutation: returnGig },
  { key: "no", mutation: cancelGig },
];

export const ApproveAction: IAction = {
  key: "approval",
  icon: approveIcon,
  labelKey: "gigs.actions.approve.label",
  color: colors.teal1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, done, renderSubmit }) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Formik
          initialValues={{ message: "", responseKey: responses[0].key }}
          onSubmit={async ({ message, responseKey }, { setStatus }) => {
            const { mutation } = responses.find(r => r.key === responseKey)!;

            try {
              await client.mutate({
                mutation,
                variables: {
                  ...variables,
                  programIds: id === "all" ? [] : id.split(","),
                  message,
                },
              });

              done();
            } catch (e) {
              setStatus({ error: true });
            }
          }}
        >
          {formik => {
            const {
              values: { responseKey },
            } = formik;
            const input = inputProps(formik);

            return (
              <form onSubmit={formik.handleSubmit}>
                <RadioGroup {...input("responseKey", { dom: false })} reverse className={styles.responses}>
                  {responses.map(response => (
                    <RadioButton value={response.key}>
                      {t(`gigs.actions.approve.responses.${response.key}.label`)}
                    </RadioButton>
                  ))}
                </RadioGroup>

                <Section>
                  <TextBox
                    {...input("message")}
                    label={t("gigs.actions.approve.message")}
                    placeholder={t("gigs.actions.approve.writeHere")}
                    multiline
                    rows={4}
                  />
                </Section>

                {renderSubmit({
                  ...formik,
                  i18n: `gigs.actions.approve.responses.${responseKey}`,
                })}
              </form>
            );
          }}
        </Formik>
      </ActionBody>
    );
  },
};
