import * as React from "react";
import cx from "classnames";
import uuid from "uuid/v4";
import _ from "lodash";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import Note from "components/Note";
import Clickable from "components/Clickable";
import Spinner from "components/Spinner";
import variables from "styles/variables";
import downloadIcon from "assets/download.svg";
import linkIcon from "assets/open-external.svg";
import { match } from "ts-pattern";
import removeIcon from "assets/cancel-nobg.svg";
import checkIcon from "assets/check.svg";
import clockIcon from "assets/clock.svg";
import failedIcon from "assets/ban.svg";
import { inflect } from "utilities/string";
import { downloadBlob, downloadUrl } from "utilities/files";
import { makeContextHoC } from "utilities/context";
import sanitizeFilename from "sanitize-filename";
import storage from "../storage";
import styles from "./styles.scss";
import { getJwt } from "utilities/authentication";

interface Props {
  children: React.ReactNode;
}

interface File {
  kind: "file";
  content: Blob;
}

interface Link {
  kind: "link";
  content: string;
}

type Content = File | Link;

interface FileDetails {
  id: string;
  name: string;
  pollUrl?: string;
  url?: string;
  pollUntilType?: string;
  content?: Content;
  error?: string;
  withAuth?: boolean;
}

interface State {
  preparing: FileDetails[];
  ready: FileDetails[];
  failed: FileDetails[];
  redownloading: FileDetails[];
  attention: boolean;
}

const downloadsStorage = storage.subScope<Omit<State, "attention">>("downloads");

export default class Downloads extends React.Component<Props, State> {
  readonly state: State = {
    preparing: [],
    ready: [],
    failed: [],
    redownloading: [],
    attention: false,
  };

  public intervalId: number | null = null;

  public getPollingFiles(): FileDetails[] {
    return this.state.preparing.filter(f => f.url && f.pollUntilType);
  }

  public componentDidMount() {
    if (downloadsStorage.value) {
      this.setState(downloadsStorage.value);
    }
  }

  public componentDidUpdate() {
    const polling = this.getPollingFiles();

    if (polling && !this.intervalId) {
      this.intervalId = window.setInterval(this.checkFiles, 3000);
    } else if (!polling && this.intervalId) {
      window.clearInterval(this.intervalId);
    }

    this.saveState();
  }

  private saveState = _.debounce(() => {
    downloadsStorage.value = _.omit(this.state, ["attention"]);
  }, 300);

  private attentionTimeout?: number;

  private updateFileList = (fn: (state: State) => Partial<State>) => {
    this.setState(current => {
      let updated = {
        ...current,
        ...fn(current),
      };

      if (
        (current.preparing.length > 0 && updated.preparing.length === 0) ||
        (updated.preparing.length > 0 && current.preparing.length === 0)
      ) {
        updated = { ...updated, attention: true };

        if (this.attentionTimeout) {
          window.clearTimeout(this.attentionTimeout);
        }

        this.attentionTimeout = window.setTimeout(() => {
          this.setState({ attention: false });
          this.attentionTimeout = undefined;
        }, 2000);
      }

      return updated;
    });
  };

  private checking: boolean = false;

  private fetchFile = async (file: FileDetails) => {
    if (!file.url) return null;

    const headers = new Headers();

    if (file.withAuth) {
      headers.set("Authorization", `Bearer ${getJwt()}`);
    }

    return fetch(file.pollUrl || file.url, { method: "GET", headers });
  };

  private checkFiles = async () => {
    if (this.checking) return;

    this.checking = true;

    try {
      const jobs = this.getPollingFiles().map(async file => {
        if (file.url && file.pollUntilType) {
          try {
            const response = (await this.fetchFile(file))!;
            const contentType = response.headers.get("content-type");
            const error = response.status >= 400;

            if (error) {
              this.updateFileList(({ preparing, failed }) => ({
                preparing: preparing.filter(f => f.id !== file.id),
                failed: failed.concat([file]),
              }));
            } else if (contentType && contentType.startsWith(file.pollUntilType)) {
              let content: Content = contentType.startsWith("text/plain")
                ? {
                  kind: "link",
                  content: await response.text(),
                }
                : { kind: "file", content: await response.blob() };

              this.updateFileList(({ preparing, ready }) => ({
                preparing: preparing.filter(f => f.id != file.id),
                ready: ready.concat([
                  {
                    ...file,
                    content,
                  },
                ]),
              }));
            }
          } catch (e) {
            this.updateFileList(({ preparing, failed }) => ({
              preparing: preparing.filter(f => f.id != file.id),
              failed: failed.concat([file]),
            }));
            console.error(e);
          }
        }
      });

      await Promise.all(jobs);
    } catch (e) {
      throw e;
    } finally {
      this.checking = false;
    }
  };
  private createFile = (name: string, details: Omit<FileDetails, "name" | "id"> = {}) => {
    const file = {
      ...details,
      id: uuid(),
      name: sanitizeFilename(name),
    };

    this.updateFileList(state => ({
      preparing: state.preparing.concat([file]),
    }));

    return file.id;
  };

  private setFileUrl = (id: string, url?: string, pollUrl?: string, pollUntilType?: string) => {
    this.updateFileList(({ preparing }) => {
      const file = preparing.find(f => f.id === id);

      if (!file) throw new Error(`Could not find file with id: ${id}`);

      const index = preparing.indexOf(file);
      return {
        preparing: [
          ...preparing.slice(0, index),
          { ...file, url, pollUrl, pollUntilType },
          ...preparing.slice(index + 1),
        ],
      };
    });
  };

  private markFileAsFailed = (id: string, error?: string) => {
    this.updateFileList(({ preparing, failed }) => {
      const file = preparing.find(f => f.id === id);

      if (!file) throw new Error(`Could not find file with id: ${id}`);

      return {
        preparing: preparing.filter(f => f.id !== id),
        failed: failed.concat([{ ...file, error }]),
      };
    });
  };

  private handleDownload = (file: FileDetails) => async () => {
    if (file.content instanceof Blob) {
      downloadBlob(file.content, file.name);
    } else if (file.url && file.withAuth) {
      // Files with authorization need to be redownloaded after refreshes

      this.updateFileList(({ redownloading, ready }) => ({
        redownloading: redownloading.concat(file),
        ready: ready.filter(f => f.id !== file.id),
      }));

      const response = (await this.fetchFile(file))!;
      const blob = await response.blob();

      downloadBlob(blob, file.name);

      this.updateFileList(({ redownloading, ready }) => ({
        redownloading: redownloading.filter(f => f.id != file.id),
        ready: ready.concat([{ ...file, content: { kind: "file", content: blob } }]),
      }));
    } else if (file.url) {
      downloadUrl(file.url, file.name);
    }
  };

  private renderFiles(state: "preparing" | "failed" | "ready" | "redownloading") {
    const removeFile = (file: FileDetails) => {
      this.updateFileList(allState => {
        const newFiles = allState[state].filter(f => f.id !== file.id);

        if (state === "preparing") {
          return { preparing: newFiles };
        } else if (state === "failed") {
          return { failed: newFiles };
        } else if (state === "ready") {
          return { ready: newFiles };
        }

        return {};
      });
    };

    const files = this.state[state];

    return files.map((file, index) => (
      <div key={index} className={styles.file}>
        {state === "ready" ? (
          <div className={styles.iconMargin}>
            <Icon src={checkIcon} size={18} fill={variables.teal1} />
          </div>
        ) : state === "failed" ? (
          <div className={styles.iconMargin}>
            <Icon src={failedIcon} size={18} fill={variables.pink1} />
          </div>
        ) : (
          <TinySpinner color={"blue"} />
        )}

        <div>
          <Text.P4>{file.name}</Text.P4>
          {file.error && <Text.ErrorMessage>{file.error}</Text.ErrorMessage>}
        </div>
        <span className={"spacer"} />

        <span className={"spacer"} />

        {state === "ready" && this.renderFileAction(file)}
        <Clickable actionLabel={"Remove"} onClick={() => removeFile(file)}>
          <Icon src={removeIcon} size={18} fill={variables.gray1} />
        </Clickable>
      </div>
    ));
  }

  renderFileAction(file: FileDetails) {
    if (!file.content) return null;

    return match(file.content)
      .with({ kind: "link" }, ({ content }) => {
        return (
          <Clickable actionLabel={"Open"} className={styles.iconMargin} onClick={() => window.open(content)}>
            <Icon src={linkIcon} size={18} fill={variables.blue2} />
          </Clickable>
        );
      })
      .with({ kind: "file" }, _ => {
        return (
          <Clickable actionLabel={"Download"} className={styles.iconMargin} onClick={this.handleDownload(file)}>
            <Icon src={downloadIcon} size={18} fill={variables.gray1} />
          </Clickable>
        );
      })
      .exhaustive();
  }

  public render() {
    const { children } = this.props;
    const { preparing, failed, ready, redownloading, attention } = this.state;

    const notFailed = preparing.length + ready.length + redownloading.length;
    const failedTotal = failed.length;
    const total = notFailed + failedTotal;
    const preparingAny = preparing.length + redownloading.length;

    return (
      <DownloadsCtx.Provider
        value={{ createFile: this.createFile, setFileUrl: this.setFileUrl, markFileAsFailed: this.markFileAsFailed }}
      >
        <div className={cx(styles.bar, total ? styles.barWithFiles : "", attention ? styles.attention : "")}>
          <div className={styles.header}>
            {total ? (
              <React.Fragment>
                {preparingAny ? (
                  <TinySpinner color={"white"} />
                ) : failedTotal > 0 ? (
                  <div className={styles.iconMargin}>
                    <Icon src={failedIcon} size={18} fill={variables.pink1} />
                  </div>
                ) : (
                  <div className={styles.iconMargin}>
                    <Icon src={checkIcon} size={18} fill={variables.teal1} />
                  </div>
                )}

                <Text.P4 kind={"reverse"}>
                  {notFailed > 0 ? (
                    <>
                      {ready.length === 0
                        ? notFailed === 1
                          ? "Preparing your file"
                          : `Preparing your ${inflect(total, "file", "files", true)}`
                        : notFailed === 1
                          ? "Your file is ready"
                          : `${ready.length} of ${total} files ready`}
                      &nbsp;
                      {failedTotal > 0 && `(${inflect(failedTotal, "file", "files", true)} failed)`}
                    </>
                  ) : failedTotal === 1 ? (
                    "Your file failed"
                  ) : (
                    failedTotal > 0 && `${inflect(failedTotal, "file", "files", true)} failed`
                  )}
                  &nbsp;
                </Text.P4>
                <Clickable
                  onClick={() => this.setState({ preparing: [], ready: [], failed: [], attention: false })}
                  className={styles.closeHeader}
                >
                  <Icon size={18} src={removeIcon} fill={variables.gray5} />
                </Clickable>
              </React.Fragment>
            ) : null}
          </div>

          <div className={styles.files}>
            <div className={preparingAny ? "" : styles.noteWrapper}>
              <Note className={styles.note}>
                <div className={styles.iconMargin}>
                  <Icon src={clockIcon} size={18} />
                </div>
                <Text.P4>This may take a few minutes</Text.P4>
              </Note>
            </div>
            {this.renderFiles("redownloading")}
            {this.renderFiles("preparing")}
            {this.renderFiles("ready")}
            {this.renderFiles("failed")}
          </div>
        </div>

        {children}
      </DownloadsCtx.Provider>
    );
  }
}

function TinySpinner({ color }: { color: "blue" | "white" }) {
  return (
    <div className={styles.spinner}>
      <Spinner scale={0.2} color={color} />
    </div>
  );
}

export type DownloadsContext = {
  createFile: (name: string, details?: Omit<FileDetails, "name" | "id">) => string;
  setFileUrl: (id: string, url: string, pollUrl?: string, pollUntilType?: string) => void;
  markFileAsFailed: (id: string, error?: string) => void;
};

export const DownloadsCtx = React.createContext<DownloadsContext>({
  createFile: () => "",
  setFileUrl: () => { },
  markFileAsFailed: () => { },
});

export const withDownloads = makeContextHoC(DownloadsCtx, "downloads");
