import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import untrashIcon from "assets/untrash.svg";
import { colors } from "styles/variables";
import Text from "components/Text";
import { useMutation } from "@apollo/client";
import { ActionBody } from "modules/Connection/ActionBody";
import untrashGigs from "./untrashGigs.gql";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = "gigs.actions.untrash";

export const UntrashAction: IAction = {
  key: "untrash",
  icon: untrashIcon,
  labelKey: `${i18n}.label`,
  color: colors.pink1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, count, done, renderSubmit }) => {
    const { t } = useTranslation();
    const [mutate] = useMutation(untrashGigs);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Text color="white">{t(`${i18n}.areYouSure`, { count })}</Text>

        {renderSubmit({
          i18n,
          isSubmitting,
          handleSubmit: async () => {
            setIsSubmitting(true);
            await mutate({ variables: { ...variables, programIds: id === "all" ? [] : id.split(",") } });
            setIsSubmitting(false);
            done();
          },
        })}
      </ActionBody>
    );
  },
};
