import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { withFormik, FieldArray, FormikProps } from "formik";
import _ from "lodash";
import * as yup from "yup";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import Text from "components/LegacyText";
import Checkbox from "components/Form/Checkbox";
import LoadingContainer from "components/LoadingContainer";
import Tooltip from "components/Tooltip";
import { Role } from "../../../../interfaces/Role";
import { Helper1, Helper2 } from "../FormHelpers";
import SaveButton from "../SaveButton";
import { withProgramFormContext, validateAndNotify, ProgramFormContext } from "../Context";
import { IProgramRole, IProgramRoles } from "../IProgramRequest";
import rolesQuery from "../roles.gql";
import styles from "./styles.scss";
import { ProgramsRouteParams } from "../../index";

export const ID = "activities";

const programRole = (orgRole: Role): IProgramRole => ({
  roleId: orgRole.id,
  duties: orgRole.duties,
  profile: orgRole.profile,
  uniform: { key: orgRole.uniformKey || "", description: orgRole.uniformKey ? orgRole.uniform : "" },
  requestable: orgRole.requestable,
  selfAssignable: orgRole.selfAssignable,
});

const Fields: IProgramRoles = {
  roles: [],
};

interface RolesQuery {
  organization: {
    id: string;
    roles: Array<{
      id: string;
      title: string;
      uniform: string;
      duties: string;
      profile: string;
    }>;
  };
}

interface Props extends RouteComponentProps<ProgramsRouteParams>, FormikProps<IProgramRoles> {
  programForm: ProgramFormContext;
  data: {
    loading: boolean;
  } & RolesQuery;
}

class Activities extends React.Component<Props> {
  componentDidMount() {
    this.props.validateForm();
  }

  public shouldComponentUpdate(nextProps: Props, nextState: {}) {
    // This is a workaround for apollographql/react-apollo#1314.
    // Without it, inputs will lose focus while saving.
    if (!nextProps.data.loading && !nextProps.data.organization) {
      return false;
    }

    return nextProps !== this.props || nextState !== this.state;
  }

  public renderTable() {
    const {
      data: { organization },
      values: { roles },
      programForm: { canEdit, sectionsBeingSaved },
    } = this.props;

    const isEditingDisabled = sectionsBeingSaved.includes("partnerOptions");

    if (!organization) {
      return <LoadingContainer message={"Loading your roles"} />;
    }

    return (
      <div className={styles.content}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th />
                <th className={styles.roleTitle}>
                  <Text.Label2 kind={"reverse"}>Activity</Text.Label2>
                </th>

                <th>
                  <Text.Label2 kind={"reverse"}>Details</Text.Label2>
                </th>
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name="roles"
                render={({ push, remove }) =>
                  organization.roles.map(orgRole => {
                    const { id, title, uniform, duties, profile } = orgRole;
                    const roleSettings = roles.find(r => r.roleId === id);
                    const active = !!roleSettings;

                    return (
                      <tr key={id}>
                        <td>
                          <Checkbox
                            checked={active}
                            disabled={!canEdit || isEditingDisabled}
                            onCheck={() =>
                              active ? remove(roles.indexOf(roleSettings!)) : push(programRole(orgRole as Role))
                            }
                          />
                        </td>
                        <td className={styles.roleTitle}>
                          <Text.P4>{title}</Text.P4>
                        </td>
                        <td>
                          <Tooltip
                            title="ACTIVITY DETAILS"
                            content={`These are the default settings for ${title}. If needed, you can tailor it
                          to this program in the Talent section, below.`}
                          >
                            <div className={styles.items}>
                              {duties && (
                                <div>
                                  <div>Responsibilities</div>
                                  <div>{duties}</div>
                                </div>
                              )}

                              {profile && (
                                <div>
                                  <div>Profile</div>
                                  <div>{profile} </div>
                                </div>
                              )}

                              {uniform && (
                                <div>
                                  <div>Dress Code</div>
                                  <div>{uniform}</div>
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })
                }
              />
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  public render() {
    const {
      match: { url },
    } = this.props;
    return (
      <React.Fragment>
        <AccordionPart>
          <AccordionPartHelper>
            <Helper1>
              On PINATA, “Activities” determine the instructions and reporting requirements for a task. Your team’s
              Activities are shown here. Please select all that will be used over the course of this Program.
            </Helper1>
            <Helper2>
              When scheduling an individual task, Power Users will be able to narrow down the selections you make here,
              choosing a single Activity that is appropriate for the specific task.
            </Helper2>
          </AccordionPartHelper>
          <AccordionPartContent>
            {this.renderTable()}

            <SaveButton id={ID} />
          </AccordionPartContent>
        </AccordionPart>
      </React.Fragment>
    );
  }
}

const transactionalSchema = yup.object().shape({
  roles: yup
    .array()
    .min(1, "Please select at least one activity")
    .required("Please select at least one activity")
    .of(
      yup
        .object()
        .shape({
          clientRate: yup.number().nullable(),
        })
        .nullable(),
    )
    .nullable(),
});

export default compose(
  withRouter,
  withProgramFormContext,

  withFormik<Props, IProgramRoles>({
    mapPropsToValues: props => ({ ...Fields, ...props.programForm.values }),
    validate: validateAndNotify(transactionalSchema, transactionalSchema, transactionalSchema, ID),
    handleSubmit: () => {},
  }),

  graphql<Props, RolesQuery, { orgId: string; active: boolean }, unknown>(rolesQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
        active: true,
      },
    }),
  }),
)(Activities);
