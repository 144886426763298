import React from "react";
import { ICell, Item } from "modules/Connection/types";
import { CellBase, CR1 } from "modules/Connection/CellBase";
import icon from "assets/circle-dollar-solid.svg";
import { useTranslation } from "react-i18next";

export const BillingCell: ICell<Item> = {
  component: props => {
    const { t } = useTranslation();
    return <CellBase {...props}>{() => <CR1>{t("gigs.fields.billing.title")}</CR1>}</CellBase>;
  },
  icon,
};
