import React, { useContext } from "react";
import { range } from "lodash";
import { useTranslation } from "react-i18next";

import Dropdown from "components/Form/Dropdown";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import Text from "components/Text";
import HorizontalGroup from "components/Form/HorizontalGroup";
import TextBox from "components/Form/TextBox";
import { UploaderContext } from "modules/Uploader/context";
import { RangeSelect } from "modules/Uploader/RangeSelect";
import { IColumnSource } from "modules/Uploader/sources";
import { FieldConfig } from "modules/Uploader/fields";
import cutIcon from "assets/cut.svg";
import styles from "./styles.scss";

const ns = "importer.source.column";

export interface Props {
  value: IColumnSource;
  field: FieldConfig;
  onChange: (value: IColumnSource) => void;
}

export function ColumnSource({ value, onChange }: Props) {
  const { t } = useTranslation();
  const { requestSelect, sheetInfo, headerIndex, isAdmin } = useContext(UploaderContext);

  return (
    <div className={styles.columnSource}>
      <RangeSelect onSelectStart={() => requestSelect("column", range => onChange({ ...value, range }))}>
        <Dropdown
          testId={`${ns}.columnSelector`}
          value={value.range || ""}
          dropdownClassName={styles.columnSelect}
          arrowClassName={styles.columnArrowSelect}
          onChange={e => onChange({ ...value, range: parseInt(e.target.value) })}
        >
          <option disabled value="" />
          {sheetInfo &&
            sheetInfo.matrix &&
            sheetInfo.matrix[headerIndex].map((col, index) => (
              <option key={index} value={index + 1} className={styles.option}>
                {col} ({sheetInfo.matrix[0][index]})
              </option>
            ))}
        </Dropdown>
      </RangeSelect>

      {isAdmin && (
        <Clickable
          className={styles.useSegment}
          testId={`${ns}.segment.use`}
          onClick={() => onChange({ ...value, segment: value.segment ? undefined : { separator: "", position: 1 } })}
        >
          <Icon src={cutIcon} fill="white" size={10} />
          <Text color="white" size={10} left="xs" underline="hover">
            {t(`${ns}.${value.segment ? "useWholeColumn" : "segment.use"}`)}
          </Text>
        </Clickable>
      )}

      {value.segment && (
        <div className={styles.segment}>
          <HorizontalGroup>
            <TextBox
              testId={`${ns}.segment.separator`}
              onChange={e => onChange({ ...value, segment: { ...value.segment!, separator: e.target.value } })}
              label={t(`${ns}.segment.separator`)}
              placeholder="e.g: /"
            />
            <Dropdown
              testId={`${ns}.segment.position`}
              label={t(`${ns}.segment.position`)}
              onChange={e => onChange({ ...value, segment: { ...value.segment!, position: parseInt(e.target.value) } })}
            >
              {range(1, 21).map(index => (
                <option key={index}>{index}</option>
              ))}
            </Dropdown>
          </HorizontalGroup>
        </div>
      )}
    </div>
  );
}
