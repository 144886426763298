import { QueryConfig } from "houbolt";
import { VarsConfig } from "houbolt/lib/variables";
import { Env } from "houbolt/lib/environment";
import { getArguments } from "houbolt/lib/arguments";
import { FetchMoreQueryOptions, FetchMoreOptions } from "@apollo/client";
import { Scalars, Query } from "gql-gen";
import { FieldNode } from "graphql";

export type Cursor = string;

/** Generic connection edge type */
export type Edge<T> = { __typename: string; node: T };

/** Generic result of a connection */
export type ConnectionResult<Node> = {
  __typename: string;
  edges: Edge<Node>[];
  pageInfo: {
    __typename: string;
    endCursor: Cursor;
    hasNextPage: boolean;
    totalCount: number;
  };
};

/** PINATA's GraphQL environment type */
export interface PEnv extends Env {
  types: Scalars;
  entries: Query;
}

/** Houbolt utility types for PINATA  */
export type PVarsConfig = VarsConfig<PEnv>;

/** Variables used in connections */
export const connectionVars: ConnectionVars = {
  first: "Int",
  offset: "Int",
  filters: "JSON",
  skipPageInfo: ["Boolean", "!"],
};

/** Type of variables used in connections */
export type ConnectionVars = {
  first: "Int";
  offset: "Int";
  filters: "JSON";
  skipPageInfo: ["Boolean", "!"];
};

/** A partial QueryConfig object without fragments representing a GraphQL
 * connection to our backend.
 *
 * As opposed to QueryConfig, this type takes a "Node" generic (1st position)
 * which is the type of the nodes returned by the query. This can be used in components
 * that expect an specific node shape.
 *
 * @see ExtractNode
 */
export type ConnectionConfig<Node = any, Vars extends ConnectionVars = any> = QueryConfig<
  PEnv,
  Vars,
  ConnectionResult<Node>
>;

/** Returns the type of the nodes for a given connection */
export type ExtractNode<CC extends ConnectionConfig> = CC extends ConnectionConfig<infer Node> ? Node : never;

/** Configuration necessary to execute a connection query  */
export interface SrcConnection<CC extends ConnectionConfig> {
  connection: CC;
  variables: any; //FIXME
}

/** Creates a partial QueryConfig for connections. @see ConnectionConfig */
export function connection<Node, Vars extends PVarsConfig>(
  config: Omit<QueryConfig<PEnv, Vars, ConnectionResult<Node>>, "fragments">,
): ConnectionConfig<Node, Vars & ConnectionVars> {
  return {
    ...config,
    variables: {
      ...config.variables,
      ...connectionVars,
    },
    fragments: [],
    entry: {
      ...config.entry,
      args: [...getArguments<PEnv, Vars>(config.variables, config.entry.args), "first", "offset", "filters"],
      mapSelect: children => {
        const edges: FieldNode = {
          kind: "Field",
          name: {
            kind: "Name",
            value: "edges",
          },
          arguments: [],
          directives: [],
          selectionSet: {
            kind: "SelectionSet",
            selections: [
              {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node",
                },
                arguments: [],
                directives: [],
                selectionSet: children,
              },
            ],
          },
        };

        return {
          kind: "SelectionSet",
          selections: children.selections.length ? [edges, PAGE_INFO] : [PAGE_INFO],
        };
      },
    },
  };
}

const PAGE_INFO: FieldNode = {
  kind: "Field",
  name: {
    kind: "Name",
    value: "pageInfo",
  },
  arguments: [],
  directives: [
    {
      kind: "Directive",
      name: {
        kind: "Name",
        value: "skip",
      },
      arguments: [
        {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "if",
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "skipPageInfo",
            },
          },
        },
      ],
    },
  ],
  selectionSet: {
    kind: "SelectionSet",
    selections: [
      {
        kind: "Field",
        name: {
          kind: "Name",
          value: "totalCount",
        },
        arguments: [],
        directives: [],
      },
    ],
  },
};

/** Removes all __typename properties from all objects recursively */
export function omitTypeName(payload: {}) {
  return JSON.parse(JSON.stringify(payload), (key, value) => (key === "__typename" ? undefined : value));
}

/** @deprecated */
export function getFetchMoreUpdateQuery<T extends ConnectionResult<any>, K extends string, Q extends { [key in K]: T }>(
  key: K,
): FetchMoreOptions<Q>["updateQuery"] {
  return (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) return previousResult;

    const { edges: newEdges, pageInfo } = fetchMoreResult[key];
    const { __typename, edges } = previousResult[key];

    return newEdges.length
      ? ({
          [key]: {
            __typename,
            edges: [...edges, ...newEdges],
            pageInfo,
          },
        } as Q)
      : previousResult;
  };
}

/** @deprecated */
export function getFetchMoreOptions<X, T extends ConnectionResult<X>, K extends string, Q extends { [key in K]: T }>(
  current: T,
  key: K,
): FetchMoreQueryOptions<{ cursor: Cursor }, "cursor"> & FetchMoreOptions<Q> {
  return {
    variables: {
      cursor: current.pageInfo.endCursor,
    },

    updateQuery: getFetchMoreUpdateQuery(key),
  };
}
