import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.scss";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import closeIcon from "assets/cancel-nobg.svg";
import { colors } from "styles/variables";
import { ActionProps } from "../types";
import useRouter from "use-react-router";

interface Props extends Partial<Pick<ActionProps, "done">> {
  children: ReactNode;
  large?: boolean;
  hideClose?: boolean;
}

export function ActionBody({ children, large, done, hideClose }: Props) {
  const {
    match: {
      params: { gigId },
    },
  } = useRouter<{ gigId?: string }>();

  const hideCloseButton = hideClose || !!gigId;

  return (
    <div className={cx(styles.container, large && styles.large)}>
      {hideCloseButton ? null : (
        <Clickable onClick={done} className={styles.closeBtn}>
          <Icon src={closeIcon} fill={colors.white} size={18} />
        </Clickable>
      )}
      {children}
    </div>
  );
}
