import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { ExpenseInput } from "gql-gen";

import Text from "components/Text";
import DecimalBox from "components/Form/DecimalBox";
import Dropdown from "components/Form/Dropdown";
import Icon from "components/Icon";
import deleteIcon from "assets/delete.svg";
import FilePicker from "components/Form/FilePicker";

import styles from "./styles.scss";
import Clickable from "components/Clickable";
import { ExpenseType } from "modules/Dashboard/Organization/Invoices/Invoice/ExpensePopup";
import { transformImage } from "utilities/filestack";
import OptionsDropdown from "components/OptionsDropdown";

interface Props {
  className?: string;
  value: ExpenseInput;
  onChange: (value: ExpenseInput) => void;
  onRemove?: () => void;
  withType?: boolean;
  disabled?: boolean;
  requiredReceipt?: boolean;
  onExpenseTypeChange?: (type: ExpenseType) => void;
  canUploadImage?: boolean;
  error?: string;
}

export default function ExpenseCard({
  className,
  value,
  onChange,
  onRemove,
  withType = true,
  requiredReceipt = true,
  disabled,
  onExpenseTypeChange,
  canUploadImage,
  error,
}: Props) {
  const { t } = useTranslation();

  const { images, amount, personal, memo, classKey } = value;

  const image = !!images ? images[0] : null;

  return (
    <div className={cx(styles.cardContainer, className)}>
      <div className={cx(styles.expenseCard, error?.length && styles.expenseCardError)}>
        <div className={styles.expenseImage}>
          {!!canUploadImage ? (
            <FilePicker
              size={240}
              value={image ? [{ url: image }] : []}
              onChange={values => {
                onChange({ ...value, images: values.map(({ url }) => url) || [] });
              }}
              accepts={".pdf, image/*, image/heic"}
              testId={"expenseCard.image"}
              multi={false}
              disabled={disabled}
            />
          ) : (
            image && (
              <img
                src={transformImage(image, {
                  resize: { height: 240 },
                })}
                style={{ width: "100%", maxHeight: "240px" }}
              />
            )
          )}

          {onRemove && !disabled && (
            <div style={{ position: "relative" }}>
              <Clickable onClick={onRemove} className={styles.removeExpense}>
                <Icon size={18} src={deleteIcon} fill={"white"} />
              </Clickable>
            </div>
          )}
        </div>

        <div className={styles.expenseData}>
          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform="uppercase" className={styles.itemText}>
              {t("components.expenseCard.receiptValue")}
            </Text>
            <DecimalBox
              value={amount}
              onChange={amount => onChange({ ...value, amount })}
              className={styles.basicMoney}
              disabled={disabled}
            />
          </div>
          {onExpenseTypeChange && (
            <div className={styles.expenseItem}>
              <Text font={"wes"} size={10} transform="uppercase" className={styles.itemText}>
                {t("components.expenseCard.expenseType")}
              </Text>
              <Dropdown
                className={styles.dropdown}
                onChange={e => onExpenseTypeChange(e.target.value as ExpenseType)}
                testId="expenseCard.expenseType"
              >
                <option value="debit">{t("components.expenseCard.debit")}</option>
                <option value="credit">{t("components.expenseCard.credit")}</option>
              </Dropdown>
            </div>
          )}

          {withType && (
            <div className={styles.expenseItem}>
              <Text font={"wes"} size={10} transform="uppercase" className={styles.itemText}>
                {t("components.expenseCard.paymentType")}
              </Text>
              <Dropdown
                className={styles.dropdown}
                onChange={e => onChange({ ...value, personal: e.target.value === "personal" })}
                value={personal ? "personal" : "company"}
                disabled={disabled}
                testId="expenseCard.paymentType"
              >
                <option value="personal">{t("components.expenseCard.personal")}</option>
                <option value="company">{t("components.expenseCard.company")}</option>
              </Dropdown>
            </div>
          )}

          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform="uppercase" className={styles.itemText}>
              {t("components.expenseCard.caption")}
            </Text>

            <input
              type="text"
              placeholder={t("components.expenseCard.typeHere")}
              value={memo || ""}
              onChange={e => onChange({ ...value, memo: e.target.value })}
              disabled={disabled}
            />
          </div>

          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform="uppercase" className={styles.itemText}>
              {t("components.expenseCard.classifyAs")}
            </Text>
            <OptionsDropdown
              className={styles.dropdown}
              group="expenseClass"
              ddProps={{
                onChange: e => onChange({ ...value, classKey: e.target.value }),
                value: classKey || undefined,
              }}
              testId="expenseCard.classify"
            ></OptionsDropdown>
            {error?.length ? (
              <div className={styles.expenseItem}>
                <Text font={"wes"} size={10} color="pink1" transform="uppercase" className={styles.errorText}>
                  {error}
                </Text>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
