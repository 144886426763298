import * as React from "react";
import cx from "classnames";
import AccordionSection from "./AccordionSection";
import { AccordionContext } from "./AccordionContext";
import styles from "./styles.scss";

interface Props {
  className?: string | null;
  openSections?: string[] | null;
  showHelpersColumn?: boolean | null;
  onSectionsChanged?: (sections: string[]) => void;
  children: React.ReactElement<typeof AccordionSection>[];
}

interface State {
  openSections: string[];
}

export default class AccordionLayout extends React.Component<Props, State> {
  public state: State = {
    openSections: [],
  };

  public static defaultProps = {
    showHelpersColumn: true,
  };

  public static getDerivedStateFromProps({ openSections }: Props, state: State) {
    if (openSections && openSections !== state.openSections) {
      return { openSections };
    }

    return null;
  }

  public toggleSection = (section: string, event: React.KeyboardEvent | React.MouseEvent) => {
    const { openSections, onSectionsChanged } = this.props;
    const { openSections: currentOpenSections } = this.state;

    let newSections: string[];

    if (currentOpenSections.includes(section)) {
      if (event.altKey) {
        newSections = [];
      } else {
        newSections = currentOpenSections.filter(s => s !== section);
      }
    } else if (event.altKey) {
      newSections = [section];
    } else {
      newSections = [...currentOpenSections, section];
    }

    if (openSections) {
      if (onSectionsChanged) {
        onSectionsChanged(newSections);
      } else {
        console.warn(`You're using AccordionLayout in controlled mode but not handling changes! 
        Add "onSectionChanged" to props or remove "openSection"`);
      }
    }

    this.setState({ openSections: newSections });
  };

  public render() {
    const { children, className, showHelpersColumn } = this.props;

    return (
      <AccordionContext.Provider
        value={{
          sections: this.state.openSections,
          toggle: this.toggleSection,
          showHelpersColumn: !!showHelpersColumn,
        }}
      >
        <div className={cx(styles.accLayout, className)}>{children}</div>
      </AccordionContext.Provider>
    );
  }
}
