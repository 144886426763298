import * as R from "pinata-common/routes";
export {
  getApps,
  getDomainLevel,
  AppName,
  QueryMap,
  getUrlQueries,
  stringifyQuery,
  addQueries,
} from "pinata-common/routes";

import { omit } from "lodash";
import { getEnvironment } from "utilities/environment";

/**
 * Returns a new url excluding the provided query parameters
 *
 * @param url The current url with the queries to exclude
 * @param params The names of the query parameters to omit
 */
export function omitQueries(url: string, params: string[]) {
  return R.stringifyQuery(omit(R.getUrlQueries(url), params));
}

/** Returns a url to another PINATA app under the same environment */
export const getAppUrl = R.getAppUrl(getEnvironment())();

/** Returns the root url to a PINATA app under the same environment  */
export const getBaseAppUrl = R.getBaseAppUrl(getEnvironment())();

export const USER_ORGANIZATION_PLACEHOLDER = "auto-user-org";

export const APPS = R.getApps();

/** Returns the name of the app in use */
export function getCurrentApp(): R.AppName | null {
  const env = getEnvironment();
  const { hostname, pathname } = window.location;

  for (const key in APPS) {
    if (APPS.hasOwnProperty(key)) {
      const app = key as R.AppName;

      const { domain, dev } = APPS[app];

      if (env === "development") {
        if (pathname.startsWith("/" + dev)) {
          return app;
        }
      } else if (env === "sandbox" && process.env.BASE_URL) {
        if (pathname.startsWith(process.env.BASE_URL + "/" + dev)) {
          return app;
        }
      } else {
        if (R.addEnvToDomain(env)(domain) === hostname) {
          return app;
        }
      }
    }
  }

  return null;
}

export function getNextRoute(): string {
  return R.getUrlQueries()["next"] || "";
}

/**
 * Replaces the current modal path with a new one. If no modal is current open, the new one is simply added at the end of path.
 * Does NOT handle query parameters. It can be used alongside `addQueries`, or `stringifyQuery` for that purpose.
 *
 * @param currentPath The current location pathname (i.e. props.location.pathname)
 * @param destinationModal The modal mount path relative to the current one. For example, "book-gigs"
 */
export function openModalPath(currentPath: string, destinationModal: string) {
  return currentPath.split("/+")[0] + "/+" + destinationModal;
}

export function getUrlOrgId() {
  const env = getEnvironment();

  if (env === "development") {
    return window.location.href.split("/")[4];
  }

  return window.location.pathname.split("/")[1];
}

export function getProgramIdFromSearch(search: string) {
  const queries = R.getUrlQueries(search);
  return queries["program"] || "all";
}
