import React from "react";
import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { GigOwnerOptionsFragment } from "gql-gen";

import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";

import ownerFragment from "./owner.gql";

export const ownersConnection: ConnectionConfig<GigOwnerOptionsFragment> = connection({
  name: "GigsWorkflowOwners",
  entry: { name: "userConnectionsByProgramPartners" },
  variables: { search: "String", programId: "ID" },
});

export default function FOwnerPicker({
  field,
  form,
  programId,
  testId,
  partialBatchUpdate,
}: FieldProps & { programId: string; testId?: string; partialBatchUpdate?: boolean }) {
  const { t } = useTranslation();
  const {
    match: {
      params: { orgId },
    },
  } = useRouter<OrganizationRouteParams>();
  return (
    <SearchableSelect<GigOwnerOptionsFragment>
      label={t("gigs.fields.owner.owner")}
      clearSearchOnSelect
      multiple={false}
      fragment={ownerFragment}
      connection={ownersConnection}
      value={field.value ? [field.value] : []}
      renderName={u => ownerNameRenderer(u) + (u && u.organizationId !== orgId ? ` [${u.organizationName}]` : "")}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      variables={{ ...ownerFilters, programId }}
      onChange={i => form.setFieldValue(field.name, i[0])}
      disabled={!programId && !partialBatchUpdate}
      skip={!programId && !partialBatchUpdate}
      testId={testId}
    />
  );
}

export const ownerNameRenderer = (u: GigOwnerOptionsFragment) => (u ? `${u.firstName} ${u.lastName}` : "...");

const notAGogetter = {
  type: "BinaryExpression",
  left: {
    type: "Identifier",
    name: "usertype",
  },
  operator: "!=",
  right: {
    type: "Literal",
    value: "gogetter",
  },
};

const userCheck = {
  type: "LogicalExpression",
  left: notAGogetter,
  operator: "||",
  right: {
    type: "Identifier",
    name: "canrequest",
  },
};

export const ownerFilters = {
  filters: {
    type: "LogicalExpression",
    left: userCheck,
    operator: "&&",
    right: {
      type: "BinaryExpression",
      left: {
        type: "Identifier",
        name: "state",
      },
      operator: "=",
      right: {
        type: "Literal",
        value: "active",
      },
    },
  },
};
