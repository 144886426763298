import React from "react";
import { Form } from "formik";
import { GigDocumentsFragment } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import styles from "./styles.scss";
import documentsFragment from "./documents.gql";
import FilePicker, { IFile } from "components/Form/FilePicker";
import useRouter from "use-react-router";
import { omitTypeName } from "utilities/connections";

type Values = Omit<GigDocumentsFragment, "__typename" | "id">;

export const GigFilesField: ComposableField<GigDocumentsFragment, Values> = {
  id: "files",

  component: ({ values: { documents, isEditable }, setFieldValue }) => {
    const { location } = useRouter();
    const editing = location.pathname.includes("+view");

    return (
      <Form>
        <div className={editing ? styles.editingFilesContainer : styles.filesContainer}>
          <FilePicker
            disabled={!isEditable}
            size={66}
            onChange={(docs: IFile[]) =>
              setFieldValue(
                "documents",
                docs.map(({ url, caption }) => ({ url, name: caption })),
              )
            }
            value={documents?.map((doc: any) => ({ url: doc ? doc.url : "", caption: doc ? doc.name : "" })) || []}
            caption
            accepts={"*"}
            testId={"gigs.forms.fileUpload"}
            secure
          />
        </div>
      </Form>
    );
  },

  initialize: data => {
    return {
      documents: data ? data.documents : [],
      isEditable: !data?.finalVerification?.isVerified,
    };
  },

  finalize: ({ documents }) => ({ documents: documents && omitTypeName(documents) }),

  fragment: documentsFragment,
};
