import * as React from "react";
import _ from "lodash";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import ArrowButton from "components/ArrowButton";
import Clickable from "components/Clickable";
import { Mobile } from "components/Layout/Responsive";
import arrowIcon from "assets/chevron.svg";
import styles from "./styles.scss";

type GoTo = (page: number) => () => void;

interface Props {
  forceSmall?: boolean;
  page: number;
  lastPage: number;
  goTo: GoTo;
}

interface State {
  jumpTo: string;
}

export default class PaginationFooter extends React.Component<Props, State> {
  readonly state: State = {
    jumpTo: "",
  };

  private jump = () => {
    const { jumpTo } = this.state;
    const { lastPage } = this.props;

    let jumpPage = parseInt(jumpTo, 10);

    if (!Number.isNaN(jumpPage)) {
      if (jumpPage < 1) {
        jumpPage = lastPage + jumpPage;
      }

      this.props.goTo(jumpPage - 1)();
      this.setState({
        jumpTo: "",
      });
    }
  };

  private jumpIfEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      this.jump();
    }
  };

  private renderContent(isMobile: boolean) {
    const { page, lastPage, goTo } = this.props;
    const { jumpTo } = this.state;

    const pageNum = page + 1;

    return (
      <div className={styles.pagination}>
        <div className={styles.spacer} />
        <Clickable actionLabel={"Go to first page"} onClick={goTo(0)} className={styles.first}>
          <Text.H3 kind={"secondary"}>First</Text.H3>
        </Clickable>
        <ArrowButton direction={"back"} actionLabel={"Previous Page"} onClick={goTo(page - 1)} />
        <div className={styles.pages}>
          {getOptions(pageNum, lastPage, !isMobile).map((tpage, index) => (
            <Page key={index} page={tpage} currentPage={page} onClick={goTo} />
          ))}
        </div>
        <ArrowButton direction={"forward"} actionLabel={"Next Page"} onClick={goTo(page + 1)} />
        <Clickable actionLabel={"Go to first page"} onClick={goTo(lastPage - 1)} className={styles.last}>
          <Text.H3 kind={"secondary"}>
            Last <span className={styles.lastPage}>({lastPage})</span>
          </Text.H3>
        </Clickable>

        {!isMobile ? (
          <div className={styles.jumpTo}>
            <Text.P4 kind={"secondary"}>Jump to:</Text.P4>

            <input
              className={styles.jumpInput}
              value={jumpTo}
              onKeyUp={this.jumpIfEnter}
              onChange={e => this.setState({ jumpTo: e.target.value })}
              placeholder="#"
            />

            <Clickable
              onClick={this.jump}
              className={jumpTo ? styles.jumpButton : styles.jumpButtonDisabled}
              actionLabel={"Jump to selected page"}
            >
              <Icon src={arrowIcon} size={15} fill={"white"} rotate={-90} />
            </Clickable>
          </div>
        ) : (
          <div className={styles.spacer} />
        )}
      </div>
    );
  }

  public render() {
    if (this.props.forceSmall) {
      return this.renderContent(true);
    }

    return <Mobile>{isMobile => this.renderContent(isMobile)}</Mobile>;
  }
}

function Page({ page, onClick, currentPage }: { page: number; currentPage: number; onClick: GoTo }) {
  const pageIndex = page - 1;

  return (
    <Clickable
      actionLabel={`Go to page ${page}`}
      onClick={onClick(pageIndex)}
      className={currentPage === pageIndex ? styles.activePage : styles.page}
    >
      <Text.Label1>{page}</Text.Label1>
    </Clickable>
  );
}

const SURROUND_COUNT = 2;

function getOptions(num: number, max: number, surround: boolean): number[] {
  if (!surround) {
    return [num];
  }

  if (max < SURROUND_COUNT * 2 + 1) {
    return _.range(1, max + 1);
  }

  let toLeft = SURROUND_COUNT;
  let toRight = SURROUND_COUNT;

  if (num <= SURROUND_COUNT) {
    toLeft = num - 1;
    toRight += SURROUND_COUNT - toLeft;
  } else if (num + SURROUND_COUNT > max) {
    toRight = max - num;
    toLeft += SURROUND_COUNT - toRight;
  }

  const pages = [];

  for (let x = toLeft; x >= 1; x--) {
    pages.push(num - x);
  }

  pages.push(num);

  for (let x = 1; x <= toRight; x++) {
    pages.push(num + x);
  }

  return pages.sort((a, b) => a - b);
}
