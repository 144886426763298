import React from "react";
import productsFragment from "./products.gql";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { connection, ConnectionConfig } from "utilities/connections";
import { GigProductOptionsFragment } from "gql-gen";
import { bin } from "utilities/knueppel";
import Section from "components/Form/Section";
import { Trans, useTranslation } from "react-i18next";
import { FieldOptionsText, FieldOptionsBold } from "../../FieldOptionsText";

export const productsConnection: ConnectionConfig<GigProductOptionsFragment> = connection({
  name: "GigsWorkflowProducts",
  entry: { name: "productConnectionsByPrograms" },
  variables: { programIds: ["ID"], search: "String" },
});

export default function FProductPicker({
  field,
  form,
  testId,
  inlineForm,
  partialBatchUpdate,
}: FieldProps & { name: string; testId?: string; inlineForm: boolean; partialBatchUpdate?: boolean }) {
  const { t } = useTranslation();

  return (
    <SearchableSelect<GigProductOptionsFragment>
      testId={testId}
      label={t("gigs.fields.products.products")}
      disabled={!partialBatchUpdate && !form.values["programId"]}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      multiple={true}
      connection={productsConnection}
      variables={{
        programIds: partialBatchUpdate ? [] : [form.values["programId"]],
        filters: bin("active", "=", true),
      }}
      fragment={productsFragment}
      value={field.value ? field.value : []}
      renderName={product => product.name}
      onChange={ids => form.setFieldValue(field.name, ids)}
      selectFirst
      itemNamePlural="products"
    >
      {({ select, count }) => (
        <>
          {!partialBatchUpdate && (
            <FieldOptionsText inlineForm={inlineForm}>
              <Trans i18nKey={`gigs.fields.products.programProducts`} count={count ?? 0}>
                This program includes&nbsp;
                <FieldOptionsBold inlineForm={inlineForm}>5 products.</FieldOptionsBold>
                &nbsp;Select at least one for this task.
              </Trans>
            </FieldOptionsText>
          )}

          <Section>{select}</Section>
        </>
      )}
    </SearchableSelect>
  );
}
