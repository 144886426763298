import * as React from "react";
import cx from "classnames";
import Text from "components/LegacyText";
import NumberEasing from "components/NumberEasing";
import Money from "components/Money";
import SiblingBox, { SiblingBoxProps } from "components/SiblingBox";
import { formatNumber } from "utilities/number";
import variables from "styles/variables";
import styles from "./styles.scss";

export type StatItemFormat = "number" | "money" | "percentage" | "temperature";

interface Props extends Pick<SiblingBoxProps, Exclude<keyof SiblingBoxProps, "children">> {
  name: string;
  format?: StatItemFormat;
  textColor?: string;
  value?: number | string;
  flip?: boolean;
  titleColor?: string;
  transformVertically?: boolean;
  onClick?: React.MouseEventHandler;
}

const renderValue = (value: React.ReactNode, color: string) => (
  <Text.Display1 className={styles.value} tooltip={""} kind={"reverse"}>
    <span style={{ color }}>{value}</span>
  </Text.Display1>
);

export default function StatsItem({
  value,
  name,
  textColor = variables.white,
  format = "number",
  flip,
  titleColor,
  transformVertically,
  className,
  onClick,
  ...boxProps
}: Props) {
  const formatter = (v: number) => {
    if (format === "number") {
      return formatNumber(v);
    } else if (format === "percentage") {
      return <React.Fragment>{formatNumber(v)}%</React.Fragment>;
    } else if (format === "money") {
      return <Money value={v} />;
    }
  };

  return (
    <SiblingBox
      {...boxProps}
      className={cx(transformVertically && styles.transformVertically, className)}
      onClick={onClick}
    >
      <Text.H3
        kind={"reverse"}
        className={cx(styles.name, flip && styles.flip, transformVertically && styles.transformVertically)}
      >
        <span style={{ color: titleColor || textColor }}>{name}</span>
      </Text.H3>

      {(value && !Number.isNaN(value) && Number.isFinite(value)) || value === 0 ? (
        format === "temperature" ? (
          <Text.P4 kind={value ? undefined : "pink"}>{value}</Text.P4>
        ) : (
          <NumberEasing value={value as number} duration={400}>
            {v => renderValue(formatter(v), textColor)}
          </NumberEasing>
        )
      ) : (
        renderValue(value === undefined ? "—" : "n/a", textColor)
      )}
    </SiblingBox>
  );
}
