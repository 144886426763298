import React from "react";
import { ICell, Item } from "modules/Connection/types";
import { CellBase, CR1 } from "modules/Connection/CellBase";
import icon from "assets/attachment.svg";
import { useTranslation } from "react-i18next";

export const AttachmentsCell: ICell<Item> = {
  component: props => {
    const { t } = useTranslation();
    return <CellBase {...props}>{() => <CR1>{t("gigs.fields.documents.attachments")}</CR1>}</CellBase>;
  },
  icon,
};
