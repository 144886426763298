import _ from "lodash";
import { GigLocation } from "interfaces/GigLocation";
import { OrganizationLocation } from "interfaces/OrganizationLocation";
type PlaceType = string;
type ComponentTypes = PlaceType[];
type TypeGroup = PlaceType | ComponentTypes;

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: ComponentTypes;
}
export type AddressComponents = AddressComponent[];

export function findAddressComponents(addressComponents: AddressComponents, types: TypeGroup[]) {
  if (!addressComponents) return [];

  const normalized: ComponentTypes[] = types.map(placeType => {
    if (typeof placeType === "string") {
      return [placeType];
    }
    return placeType;
  });

  return normalized.map(typeGroup => addressComponents.find(cmp => _.intersection(cmp.types, typeGroup).length > 0));
}

export function formatGigLocation({ name, address, addressJson }: GigLocation) {
  const addressComponents = JSON.parse(addressJson);

  if (!addressComponents) {
    return [name.split(",")[0], address];
  }

  const [
    establishment,
    street_number,
    route,
    postal_code,
    sublocality,
    city,
    state,
  ] = findAddressComponents(addressComponents, [
    "establishment",
    "street_number",
    "route",
    "postal_code",
    "sublocality",
    ["locality", "administrative_area_level_3"],
    "administrative_area_level_1",
  ]);

  let secondLineParams: string[] = [];

  if (city) {
    secondLineParams.push(city.long_name);
  } else if (sublocality) {
    secondLineParams.push(sublocality.long_name);
  }

  if (state || postal_code) {
    const param: string[] = [];

    if (state) {
      param.push(state.short_name);
    }

    if (postal_code) {
      param.push(postal_code.long_name);
    }

    secondLineParams.push(param.join(" "));
  }

  if (route) {
    secondLineParams.unshift(`${street_number ? street_number.long_name + " " : ""}${route.long_name}`);
  } else {
    secondLineParams = [address];
  }

  let firstLine: string;

  if (establishment) {
    firstLine = establishment.long_name;
  } else {
    firstLine = name.split(",")[0];
  }

  const secondLine = secondLineParams.join(", ");

  return [firstLine, secondLine];
}

export function getGoogleMapsUrl(
  placeId: string | undefined,
  locationName: string,
  locationAddress?: string | undefined,
) {
  return `https://maps.google.com?q=${encodeURIComponent(
    placeId ? `place_id:${placeId}` : `${locationName} ${locationAddress || ""}`,
  )}`;
}

export function getMobileGoogleMapsUrl(locationName: string, locationAddress?: string | undefined) {
  return `https://maps.google.com?q=${encodeURIComponent(`${locationName} ${locationAddress || ""}`)}`;
}

export function getFormattedGigLocation(
  customLocationName: string | undefined,
  organizationLocation: OrganizationLocation | undefined,
  locationName: string = "No Location",
): string {
  if (customLocationName) return customLocationName.split(",")[0];
  if (organizationLocation) {
    return `${organizationLocation.name} ${
      organizationLocation.internalIdentifier ? `(${organizationLocation.internalIdentifier})` : ""
    }`;
  }

  return locationName;
}
