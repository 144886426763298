import * as React from "react";
import { makeContextHoC } from "utilities/context";

export interface ImageInfo {
  src: string;
  caption: string;
  fileName?: string;
}

export type IImageGroupContext = {
  addImage: (image: ImageInfo) => void;
  removeImage: (image: ImageInfo) => void;
  openImage: (image: ImageInfo) => void;
};

export const ImageGroupContext = React.createContext<IImageGroupContext>({
  addImage: () => {},
  removeImage: () => {},
  openImage: () => {},
});

export default ImageGroupContext;

export const withImageGroupContext = makeContextHoC(ImageGroupContext, "imageGroup");
