import * as filestack from "filestack-js";

export const FS_CDN_BASE = `https://cdn.filestackcontent.com/`;

export const FS_API_KEY = "AjMiH9rNZR5KimfGpvJDBz";
export const FS_SECURE_API_KEY = "ABY0xEf3bSAeWnphSDaRYz";
export const FS_SECURITY = {
  // This might look sketchy but it's actually fine.
  // This policy only allows the frontend to upload files, not read, or update them.
  policy: "eyJleHBpcnkiOjE4OTM0NjY4MDAsImNhbGwiOlsicGljayJdLCJtYXhTaXplIjoxMDAwMDAwMDB9",
  signature: "50f097264d4e547401227e3fb061bb36378b3e322bb6fdb19f4be58ad77a081a",
};

export const FS_PROCESS_URL = `https://process.filestackapi.com/${FS_API_KEY}`;

interface TransformOption {
  [option: string]: string | number;
}

export interface Transform {
  [transform: string]: TransformOption | null;
}

export function transformImage(url: string, options: Transform = {}): string {
  const params: string[] = [];
  const transfomOptions: Transform = { output: { format: "jpg" }, rotate: { deg: "exif" }, ...options };

  for (const option in transfomOptions) {
    const subParams: string[] = [];
    const subOps = transfomOptions[option];

    if (!subOps) continue;

    for (const subOp in subOps) {
      subParams.push(`${subOp}:${subOps[subOp]}`);
    }

    params.push(`${option}=${subParams.join(",")}`);
  }

  return `${FS_PROCESS_URL}/${params.join("/")}/${url}`;
}

export function getFsId(url: string): string | null {
  if (url.startsWith(FS_CDN_BASE)) {
    return url.split(FS_CDN_BASE)[1];
  }

  return null;
}

export function zipFile(files: string[]): string {
  return `${FS_PROCESS_URL}/zip/[${files.join()}]`;
}
