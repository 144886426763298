import * as React from "react";

// This function creates a type safe HoC out of a React Context
export function makeContextHoC<T, X>(Context: React.Context<T>, prop: keyof X) {
  return function <P extends X>(Component: React.ComponentType<P>) {
    return function (props: Pick<P, Exclude<keyof P, keyof X>>) {
      return (
        <Context.Consumer>{(context: T) => <Component {...{ ...(props as P), [prop]: context }} />}</Context.Consumer>
      );
    };
  };
}
