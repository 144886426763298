import React, { useEffect, useMemo, useContext } from "react";
import { Field } from "formik";
import _ from "lodash";
import { ComposableField } from "modules/Connection/ComposableForm";
import assignmentFragment from "./assignment.gql";
import assignmentProviderFragment from "./assignmentProvider.gql";
import FTalentPicker from "./Form/TalentPicker";
import { useTranslation } from "react-i18next";
import FRateField from "./Form/RateField";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { useQuery } from "@apollo/client";
import talentQuery from "./talent.gql";
import organizationQuery from "./organization.gql";
import {
  GigAssignmentFieldFragment,
  GigAssignmentProviderFieldFragment,
  AssignmentTalentQuery,
  AssignmentTalentQueryVariables,
  AssignmentOrganizationQuery,
  AssignmentOrganizationQueryVariables,
  PartnerFieldMyOrganizationQuery,
  PartnerFieldMyOrganizationQueryVariables,
} from "gql-gen";
import { GigProgramField, GigProgramValue } from "../Program";
import Section from "components/Form/Section";
import Text from "components/Text";
import { toBase64 } from "utilities/base64";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";
import { useScopeContext } from "modules/Connection/Scope";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import myOrganizationQuery from "../Partner/myOrganization.gql";
import { FormProvider } from "modules/Connection/types";
import { BlockFormContext } from "modules/Connection/Block";
import LoadingContainer from "components/LoadingContainer";

export interface GigAssignmentValues {
  talentId?: string | null;
  partnerOrganizationId?: string | null;
  talentRate?: number;
}

type Props = {
  talent?: AssignmentTalentQuery["user"];
  organization?: AssignmentOrganizationQuery["organization"];
  data?: GigAssignmentFieldFragment;
  allItems?: GigAssignmentFieldFragment[];
  close: () => void;
};

const InternalTeam = ({ talent, organization, data, allItems, close }: Props) => {
  const { me } = useUserInfo();
  const { location } = useRouter<OrganizationRouteParams>();
  const { variables, ids } = useScopeContext();
  const [openToLink, _sendToLink] = useMemo(() => {
    return [
      {
        pathname: location.pathname + "/+open-to/" + toBase64(JSON.stringify(variables)),
        search: location.search,
      },
      {
        pathname: location.pathname + "/+send-to/" + toBase64(JSON.stringify(variables)),
        search: location.search,
      },
    ];
  }, [variables, location.search]);

  const salaried = talent?.organizationUser?.salaried;
  const closeDeferred = () => {
    if (!ids || ids.length > 1) {
      setTimeout(() => close(), 10);
    }
  };

  return (
    <>
      {organization?.gigApplyActive === true && (
        <Text color="white" size={16} font="wes" bold bottom="m">
          <div style={{ marginBottom: 10 }}>Not sure who to assign?</div>
          <Link to={openToLink} onClick={closeDeferred}>
            <Text color="blue2" font="wes" bold inline size={16}>
              Open for application
            </Text>
          </Link>
          &nbsp;or&nbsp;
          <Link to={openToLink} onClick={closeDeferred}>
            <Text color="blue2" font="wes" bold inline size={16}>
              first-come-first-serve
            </Text>
          </Link>
        </Text>
      )}

      {!salaried && (
        <Section>
          <Field
            name="talentRate"
            component={FRateField}
            compact
            gigRate={data && data.talentRate}
            talent={talent}
            testId="talent-rate"
          />
        </Section>
      )}
      <Text color="white" size={14} top="l" bottom="m">
        Select a GoGetter to assign directly.
      </Text>

      <Field
        compact
        name="talentId"
        component={FTalentPicker}
        data={data}
        orgId={me?.organizationUser?.organizationId}
        gigs={allItems}
        userData={talent}
        testId="talent-picker"
      />
    </>
  );
};

export const GigAssignmentField: ComposableField<
  GigAssignmentFieldFragment,
  GigAssignmentValues,
  GigAssignmentValues,
  GigProgramValue
> = {
  id: "talentPerson",

  component: ({ values, data, allItems, close }) => {
    const { talentId } = values;
    const {
      match: {
        params: { orgId },
      },
    } = useRouter<OrganizationRouteParams>();

    const { t } = useTranslation();
    const { setCanSave } = useContext(BlockFormContext);

    const { data: talentData } = useQuery<AssignmentTalentQuery, AssignmentTalentQueryVariables>(talentQuery, {
      skip: !talentId || !data?.id,
      variables: {
        id: talentId!!,
        gigId: data?.id!!,
      },
    });

    const { data: selectedPartnerData, loading } = useQuery<
      AssignmentOrganizationQuery,
      AssignmentOrganizationQueryVariables
    >(organizationQuery, {
      variables: {
        orgId: data?.partnerOrganization?.id!!,
      },
      skip: !data?.partnerOrganization?.id,
    });

    const talent = talentData?.user;

    const { data: organizationData } = useQuery<AssignmentOrganizationQuery, AssignmentOrganizationQueryVariables>(
      organizationQuery,
      {
        variables: {
          orgId,
        },
      },
    );

    const organization = organizationData?.organization;

    if (loading) {
      return <LoadingContainer />;
    }

    const partnerOrg = selectedPartnerData?.organization;

    if (partnerOrg && !partnerOrg.isWorkspace) {
      setCanSave(false);
      return (
        <Text color="pink1" font="wes" bold inline size={16}>
          {t("gigs.actions.assign.cantAssign")}
        </Text>
      );
    }

    return <InternalTeam talent={talent} organization={organization} data={data} allItems={allItems} close={close} />;
  },

  initialize: (data, formMeta) => {
    return {
      talentId: formMeta.isBatchForm ? undefined : data?.talent?.id ?? undefined,
      talentRate: data?.talentRate || undefined,
    };
  },

  finalize: ({ talentId, talentRate }) => {
    return {
      talentId: talentId || null,
      talentRate,
    };
  },

  fragment: assignmentFragment,

  dependencies: [GigProgramField],
};

export const GigAssignmentFormProvider: FormProvider<GigAssignmentProviderFieldFragment> = {
  provide: () => {
    const {
      match: {
        params: { orgId },
      },
    } = useRouter<OrganizationRouteParams>();

    const { data: myOrganizationData } = useQuery<
      PartnerFieldMyOrganizationQuery,
      PartnerFieldMyOrganizationQueryVariables
    >(myOrganizationQuery, {
      variables: { organizationId: orgId },
    });

    const isMyOrganizationWorkspace = myOrganizationData?.organization.isWorkspace || false;

    if (isMyOrganizationWorkspace) {
      return [];
    }

    return [GigAssignmentField];
  },
  providerFragment: assignmentProviderFragment,
};
