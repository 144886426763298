import { graphql } from "@apollo/client/react/hoc";
import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AccordionSection from "components/AccordionLayout/AccordionSection";
import Clickable from "components/Clickable";
import Text from "components/LegacyText";
import styles from "./styles.scss";
import { ProgramFormCtx, ProgramFormContext } from "../../Form/Context";
import { getRequiredSectionsForUser } from "../sections";
import setProgramManagementState from "./setProgramManagementState.gql";
import mStateProgramFragment from "./mStateProgramFragment.gql";
import { withUserInfo } from "../../../../UserInfo";
import { UserInfoQuery } from "../../../../UserInfo";
import { ProgramsRouteParams } from "../../index";
import { flowRight as compose } from "lodash";
import { getProgramIdFromSearch } from "utilities/routes";
interface Props extends RouteComponentProps<ProgramsRouteParams>, UserInfoQuery {
  setManagementState: (state: string) => Promise<{}>;
  isFinancialPackageActive: boolean;
}

function ShareProgramRequest({
  setManagementState,
  history,
  match,
  userInfo: { me, impersonator },
  isFinancialPackageActive,
}: Props) {
  return (
    <ProgramFormCtx.Consumer>
      {({
        executionType,
        validation,
        status: { status },
        values: { managementState, programPartnerIds, clientOrganizations },
        saveAll,
        setStatus,
      }: ProgramFormContext) => {
        const shared = managementState === "handed_off" || managementState === "sow";
        const sow = managementState === "sow";
        let shareEnabled = true;
        const isAdmin = me && me.isAdmin;

        if (sow && !isAdmin) {
          history.push(`/${match.params.orgId || ""}`);
        }

        if (status === "handingOff") {
          shareEnabled = false;
        } else {
          const isPartnerOptionsVisible =
            (impersonator && impersonator.isAdmin) ||
            (me && me.isAdmin) ||
            (isFinancialPackageActive && executionType !== "solo");
          const isInvitedByVisible = !!clientOrganizations && clientOrganizations.length > 0;

          for (const section of getRequiredSectionsForUser(me, isPartnerOptionsVisible, isInvitedByVisible)) {
            if (!validation[section.id] && !section.optional) {
              shareEnabled = false;
              break;
            }
          }
        }

        async function shareProgram(newState: string) {
          await saveAll();
          setStatus("handingOff");

          try {
            await setManagementState(newState);

            if (executionType !== "solo") {
              history.push(`/${match.params.orgId || ""}`);
            }

            // Note: We only need to handle redirection on !solo because the router
            // automatically takes you to dashboard when program is accepted
          } catch (e) {
            await setStatus("handOffError");
            throw e;
          }
        }

        let button;

        const autoStart = executionType !== "maestro";

        if (!shared) {
          button = (
            <Clickable
              className={styles.shareWithPinata}
              onClick={shareEnabled ? () => shareProgram(autoStart ? "accepted" : "handed_off") : undefined}
              disabled={!shareEnabled}
              actionLabel={"Save new program"}
            >
              <Text.Display2 className={styles.shareWithPinataText} kind={"reverse"}>
                {autoStart ? "Start program" : "Save new program"}
              </Text.Display2>
            </Clickable>
          );
        } else if (sow && me?.isAdmin) {
          button = (
            <Clickable
              className={styles.shareWithPinata}
              onClick={() => shareProgram("accepted")}
              actionLabel={"Unarchive"}
            >
              <Text.Display2 className={styles.shareWithPinataText} kind={"reverse"}>
                Unarchive program
              </Text.Display2>
            </Clickable>
          );
        }

        return (
          <AccordionSection
            id={"finish"}
            title={""}
            header={
              <div className={styles.save}>
                {button}
                {!autoStart && !isAdmin && shared && (
                  <Text.P3 kind={"secondary"}>
                    This information has been shared with your PINATA Account Manager for review.
                  </Text.P3>
                )}
              </div>
            }
          />
        );
      }}
    </ProgramFormCtx.Consumer>
  );
}

export default compose(
  withRouter,
  withUserInfo,
  graphql<Props, { updatedProgram: any }, unknown, unknown>(setProgramManagementState, {
    props: ({ mutate, ownProps: { location } }) => {
      const id = getProgramIdFromSearch(location.search);
      return {
        setManagementState: (state: string) =>
          mutate &&
          mutate({
            variables: {
              id,
              state,
            },

            update: (client, { data }) => {
              if (data && data.updatedProgram)
                client.writeFragment({
                  id: `Program_${id}`,
                  fragment: mStateProgramFragment,
                  data: {
                    ...data.updatedProgram,
                    __typename: "Program",
                  },
                });
            },
          }),
      };
    },
  }),
)(ShareProgramRequest);
