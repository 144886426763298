import * as React from "react";
import { ApolloClient, useQuery, useMutation } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import LoadingContainer from "components/LoadingContainer";
import pinataLogo from "assets/brand-logo.svg";
import variables from "styles/variables";
import styles from "./styles.scss";
import { logout } from "utilities/authentication";
import { User } from "interfaces/user";
import { getDomainLevel } from "utilities/routes";
import userInvitesQuery from "./userInvites.gql";
import createUserInviteUrlMutation from "./createUserInviteUrl.gql";

interface Props {
  client?: ApolloClient<any>;
  userInfo: User;
}

interface UserInvitesQuery {
  me: {
    userInvites: {
      uuid: string;
      acceptedAt: string | null;
      organizationName: string;
      organizationUuid: string;
    }[];
  };
}

function PageContainer(props: { children: React.ReactNode }) {
  return (
    <div className={styles.noOrgPage}>
      <div className={styles.content}>
        <div className={styles.pinataLogo}>
          <Icon src={pinataLogo} fill={variables.pink1} width={150} />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default withApollo<Props>(function UserNoOrgPage(props: Props) {
  const { data: userInvitesData, loading: userInvitesLoading } = useQuery<UserInvitesQuery>(userInvitesQuery, {
    context: { clientName: "new-api" },
  });
  const [createUserInviteUrl, { data: createUserInviteUrlData }] = useMutation<
    { createUserInviteUrl: string },
    { userInviteUuid: string }
  >(createUserInviteUrlMutation);
  React.useEffect(() => {
    if (!!createUserInviteUrlData) {
      const { createUserInviteUrl: url } = createUserInviteUrlData;

      window.location.href = url;
    }
  }, [createUserInviteUrlData]);

  if (userInvitesLoading) {
    return (
      <PageContainer>
        <LoadingContainer color="white" />
      </PageContainer>
    );
  }

  const { userInfo } = props;
  const userInvites = userInvitesData?.me.userInvites?.filter(invite => !invite.acceptedAt);

  return (
    <PageContainer>
      {userInvites && userInvites.length > 0 ? (
        <>
          <Text.Display2 kind={"reverse"} className={styles.title}>
            {`You have ${userInvites.length} open ${userInvites.length > 1 ? "invitations" : "invitation"}.`}
          </Text.Display2>
          <Text.Display3 kind={"reverse"}>To accept, click here:</Text.Display3>
          <ul className={styles.invitationList}>
            {userInvites.map(invitation => (
              <li className={styles.invitation} key={invitation.uuid}>
                <Text.NewLink2
                  onClick={() =>
                    createUserInviteUrl({
                      variables: { userInviteUuid: invitation.uuid },
                      context: {
                        clientName: "new-api",
                        headers: {
                          "X-Pinata-Organization-Id": invitation.organizationUuid,
                        },
                      },
                    })
                  }
                >
                  {`Invitation from ${invitation.organizationName} »`}
                </Text.NewLink2>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <Text.Display2 kind={"reverse"} className={styles.title}>
            Oops! You're not part of any company
          </Text.Display2>
          <Text.P3 kind={"reverse"} className={styles.description}>
            You’ve logged in to the PINATA platform with {userInfo.email || userInfo.unconfirmedEmail}. Unfortunately,
            your email address is not linked to any company on the platform — such as a brand or staffing agency. There
            are several possible explanations:
          </Text.P3>
          <ul>
            <li>
              <Text.P3 kind={"reverse"} className={styles.description}>
                • You’ve connected to a company using a different email address. If that may be the case,{" "}
                <Text.NewLink3 onClick={() => logout()}>log out</Text.NewLink3> and try that address.
              </Text.P3>
            </li>
            <li>
              <Text.P3 kind={"reverse"} className={styles.description}>
                • You may have created an account with PINATA a long time ago as a freelancer. If so, we’d be glad to,{" "}
                <Text.NewLink3 href={`mailto:support@${getDomainLevel(2)}?subject=Intros to local staffing agencies`}>
                  introduce you to local staffing agencies
                </Text.NewLink3>
                .
              </Text.P3>
            </li>
            <li>
              <Text.P3 kind={"reverse"} className={styles.description}>
                • A company you were connected to has removed you from its team or no longer uses PINATA.
              </Text.P3>
            </li>
          </ul>
        </>
      )}
    </PageContainer>
  );
});
