import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  kind?: "normal" | "reverse";
  className?: string;
  style?: { [prop: string]: {} };
}

export default function Label2({ children, className, style, kind = "normal" }: Props) {
  return (
    <label className={cx(styles.label, kind === "reverse" && styles.reverse, className)} style={style}>
      {children}
    </label>
  );
}
