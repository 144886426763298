import * as React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import moment from "moment-timezone";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import ImageGroup from "components/ImageGroup";
import ImageOpener from "components/ImageGroup/ImageOpener";
import ArrowButton from "components/ArrowButton";
import { getGoogleMapsUrl } from "modules/Dashboard/Schema/Gigs/locations";
import { transformImage, zipFile, getFsId } from "utilities/filestack";
import multipleIcon from "assets/duplicate.svg";
import selectedIcon from "assets/check.svg";
import styles from "./styles.scss";
import variables from "styles/variables";
import Button from "components/Button";
import { find, filter } from "lodash";

export interface ReportDashboardImageAnswer {
  id: string;
  gigId: string;
  gigStartTime: string | null;
  gigEndTime: string | null;
  gigTimeZone: string | null;
  locationName: string;
  locationAddress: string;
  locationExternalId: string;
  locationInternalIdentifier: string;
  gogetterId: string;
  gogetterName: string;
  reportQuestionPkey: number;
  urls: string[];
}

export interface ReportDashboardImageQuestion {
  id: string;
  title: string;
  images: ReportDashboardImageAnswer[];
}

interface Props {
  question: ReportDashboardImageQuestion;
}

interface State {
  shown: number;
  selectable: boolean;
  readyForDownload: ReportDashboardImageAnswer[];
}

const COLLAPSED_IMAGE_COUNT = 5;

export default class ImageQuestionGallery extends React.Component<Props, State> {
  readonly state: State = {
    shown: COLLAPSED_IMAGE_COUNT,
    selectable: false,
    readyForDownload: [],
  };
  public showAll = (e: Event) => {
    e.stopPropagation();

    this.setState({
      shown: this.props.question.images.length,
    });
  };

  private setBulkDownload = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    this.setState(state => ({
      selectable: !state.selectable,
      readyForDownload: [],
    }));
  };

  private downloadImages = (e: React.MouseEvent | React.KeyboardEvent) => {
    let imageArray: string[] = [];
    this.state.readyForDownload.forEach(image => {
      image.urls.forEach((url: string) => {
        let fsId = getFsId(url);
        if (fsId) {
          imageArray.push(fsId);
        }
      });
    });
    this.setBulkDownload(e);
    if (imageArray.length > 0) {
      window.open(zipFile(imageArray));
    }
  };

  private toggleImageForDownload = (image: ReportDashboardImageAnswer) => {
    const imagesArray = [...this.state.readyForDownload];

    let index = imagesArray.findIndex(readyImage => readyImage.id === image.id);

    this.setState({
      readyForDownload:
        index === -1 ? [...imagesArray, image] : filter(imagesArray, readyImg => readyImg.id !== image.id),
    });
  };

  public render() {
    const { question } = this.props;
    const { shown, selectable, readyForDownload } = this.state;

    const images = question.images;
    const moreToShow = images.length > shown;
    const imageViews = images.slice(0, shown).map(image => (
      <ImageAnswer
        visible={true}
        key={image.id}
        image={image}
        selectable={selectable}
        isSelected={
          !!find(readyForDownload, function (img) {
            return image.id === img.id;
          })
        }
        onToggleSelected={this.toggleImageForDownload}
      />
    ));

    if (!images.length) {
      return null;
    }

    const totalCount = readyForDownload.reduce((acc, gig) => acc + gig.urls.length, 0);

    return (
      <ImageGroup>
        <div className={styles.imageQuestion}>
          <div className={styles.imageQuestionHeader}>
            <Text.H2>{question.title}</Text.H2>
            <Button kind={"blueTranslucent"} onClick={this.setBulkDownload}>
              {selectable ? "CANCEL" : "SELECT"}
            </Button>
          </div>

          <div className={styles.qGallery}>
            {images.length === 0 && <Text.P3>No pictures</Text.P3>}
            {imageViews}

            {moreToShow && (
              <div
                className={styles.showMore}
                onClick={() => this.setState({ shown: shown + COLLAPSED_IMAGE_COUNT + 1 })}
              >
                <Text.Display2 kind={"reverse"}>Show more</Text.Display2>

                <Text.P3 kind={"reverse"} className={styles.showAll}>
                  or&nbsp;
                  <Text.NewLink3 kind={"reverse"} onClick={this.showAll}>
                    Show All ({images.flatMap(i => i.urls).length}) Photos
                  </Text.NewLink3>
                </Text.P3>
              </div>
            )}

            {selectable && (
              <div className={styles.selectedFooter}>
                <Text.P2 kind="reverse">{`${readyForDownload.length} tasks selected ${
                  totalCount !== readyForDownload.length ? `(${totalCount} photos)` : ""
                }`}</Text.P2>
                <div>
                  <Button kind="redGradient" onClick={this.setBulkDownload}>
                    Cancel
                  </Button>
                  <Button kind="primaryGradient" onClick={this.downloadImages} disabled={readyForDownload.length === 0}>
                    Download
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </ImageGroup>
    );
  }
}
type ImageAnswerProps = RouteComponentProps & {
  image: ReportDashboardImageAnswer;
  visible: boolean;
  selectable: boolean;
  isSelected: boolean;
  onToggleSelected: (image: ReportDashboardImageAnswer) => void;
};

interface ImageAnswerState {
  current: number;
}

const IMAGE_SIZE = 305;

const ImageAnswer = withRouter(
  class extends React.Component<ImageAnswerProps, ImageAnswerState> {
    readonly state: ImageAnswerState = {
      current: 0,
    };

    private handleNext = (e: React.MouseEvent | React.KeyboardEvent) => {
      e.stopPropagation();

      this.setState(state => ({
        current: state.current + 1,
      }));
    };

    private handleBack = (e: React.MouseEvent | React.KeyboardEvent) => {
      e.stopPropagation();

      this.setState(state => ({
        current: state.current - 1,
      }));
    };

    private handleSelection = (e: React.MouseEvent | React.KeyboardEvent, image: ReportDashboardImageAnswer) => {
      e.stopPropagation();
      this.props.onToggleSelected(image);
    };

    public render() {
      const { image, visible, match, selectable, isSelected } = this.props;
      const { current } = this.state;

      const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

      const length = image.urls.length;

      return (
        <div className={styles.imageAnswer} style={visible ? {} : { display: "none" }}>
          {selectable && (
            <div
              className={cx(styles.selectable, isSelected && styles.selected)}
              onClick={e => {
                this.handleSelection(e, image);
              }}
            >
              <div className={styles.selectableIcon}>
                {selectable && (
                  <Icon src={selectedIcon} size={24} fill={isSelected ? variables.blue2 : variables.gray4}></Icon>
                )}
              </div>
            </div>
          )}
          <div className={styles.imagesScroll} style={{ transform: `translateX(-${IMAGE_SIZE * current}px)` }}>
            {image.urls.map((url, index) => {
              const date =
                image.gigStartTime && image.gigTimeZone
                  ? moment.tz(image.gigStartTime, image.gigTimeZone).format("YYYYMMDD")
                  : "";
              let fileName = `${date}-${image.locationName}`;

              if (image.locationInternalIdentifier) {
                fileName = fileName + `-${image.locationInternalIdentifier}`;
              }

              fileName = fileName + `-${image.gogetterName}`;

              return (
                <ImageAnswerItem
                  visible={visible && (current === index - 1 || current === index || current === index + 1)}
                  key={url}
                  url={url}
                  fileName={fileName}
                />
              );
            })}
          </div>

          {visible && (
            <React.Fragment>
              {length > 1 && (
                <React.Fragment>
                  <div className={styles.multipleImages}>
                    <Icon src={multipleIcon} fill={"white"} size={16} />
                  </div>
                  {current > 0 && <ArrowButton direction={"back"} className={styles.back} onClick={this.handleBack} />}
                  {current < length - 1 && (
                    <ArrowButton direction={"forward"} className={styles.next} onClick={this.handleNext} />
                  )}
                </React.Fragment>
              )}

              <div className={cx(styles.imageInfo, selectable && styles.noHover)}>
                <div className={styles.mainInfo}>
                  <Text.P3 kind="reverse" title={image.locationName}>
                    {image.locationName.split(",")[0]}
                  </Text.P3>
                  {image.gigStartTime && image.gigTimeZone && (
                    <Text.P3
                      kind="reverse"
                      title={[
                        moment.tz(image.gigStartTime, image.gigTimeZone).format("M/DD/YY h:mma"),
                        image.gigEndTime && moment.tz(image.gigEndTime, image.gigTimeZone).format("h:mma"),
                      ]
                        .filter(i => i)
                        .join(" - ")}
                    >
                      {moment.tz(image.gigStartTime, image.gigTimeZone).format("MMM D")}
                    </Text.P3>
                  )}
                </div>
                <div className={styles.extraInfo}>
                  <Text.NewLink4
                    kind="reverse"
                    href={getGoogleMapsUrl(image.locationExternalId, image.locationName, image.locationAddress)}
                    target={"_blank"}
                    rel={"noopener noreferer"}
                    onClick={stopPropagation}
                  >
                    {image.locationAddress}
                  </Text.NewLink4>
                  <Text.NewLink4
                    kind="reverse"
                    component={Link}
                    to={`${match.url}/+user-profile/${image.gogetterId}`}
                    onClick={stopPropagation}
                  >
                    {image.gogetterName}
                  </Text.NewLink4>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }
  },
);

interface ImageAnswerItemProps {
  url: string;
  visible: boolean;
  fileName: string;
}

function ImageAnswerItem({ url, visible, fileName }: ImageAnswerItemProps) {
  const thumbUrl = transformImage(url, {
    resize: { width: IMAGE_SIZE, fit: "max" },
  });

  return (
    <ImageOpener
      className={styles.imageAnswerItem}
      src={url}
      caption={""}
      style={visible ? { backgroundImage: `url(${thumbUrl})` } : null}
      fileName={fileName}
    >
      <span />
    </ImageOpener>
  );
}
