module.exports={
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ProductCategories"
      },
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "productCategories"
            },
            "name": {
              "kind": "Name",
              "value": "options"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "group"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "productCategory",
                  "block": false
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "key"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "label"
                  },
                  "arguments": [],
                  "directives": []
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 117
  }
};