export const EMAIL_REGEX = /.*@.*\..*/;

export function validateEmail(email: string = ""): boolean {
  return EMAIL_REGEX.test(email);
}

export const PASSWORD_LETTER_REGEX = /[a-z]/i;

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{12,}$/;

export function getPasswordErrors(password: string = ""): string | null {
  if (!password || password.trim().length === 0) {
    return `Sorry, your password can't be a blank space`;
  } else if (password.length < 12) {
    return "We need at least 12 characters here";
  } else if (!PASSWORD_REGEX.test(password)) {
    return "Your password needs to have at least 12 characters, one lowercase letter, one capital letter, one number and one symbol";
  }

  return null;
}

export function getPhoneErrors(phone: string = "", required: boolean = true, tooShortMsg?: string): string | null {
  const matches = (phone || "").match(/\d/g);
  const numbersCount = matches ? matches.length : 0;

  if (numbersCount === 0) {
    if (required) return "You need to fill this out";
  } else {
    let maxNumbers = 10;

    if (phone[0] === "+") {
      maxNumbers = 15;
    } else if (numbersCount < 10) {
      return tooShortMsg ? tooShortMsg : "We need some more digits";
    }

    if (numbersCount > maxNumbers) {
      return "Too many digits";
    }
  }

  return null;
}
