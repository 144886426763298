import * as React from "react";
import cx from "classnames";
import { RadioValue, RadioButtonProps } from "components/RadioButton";
import styles from "./styles.scss";

interface Props {
  className?: string;
  direction?: "horizontal" | "vertical";
  reverse?: boolean;
  value?: RadioValue | null;
  onChange?: (val: RadioValue | null) => void;
  children: (React.ReactElement<RadioButtonProps> | null | false)[];
}

export default function RadioGroup({
  className,
  children,
  direction = "vertical",
  value,
  onChange,
  reverse = false,
}: Props) {
  const items = React.Children.map(children, item => {
    if (!item) return item;

    return React.cloneElement(item, {
      selected: value === item.props.value,
      reverse: "reverse" in item.props ? item.props.reverse : reverse,
      onChange,
    });
  });

  return (
    <div className={cx(styles.group, styles[direction], className)} role={"radiogroup"}>
      {items}
    </div>
  );
}
