import * as React from "react";
import _ from "lodash";
import TextComponents from "components/LegacyText";
import ProgramInfo, { IProgramInfo } from "../ProgramInfo";
import { Document } from "../../../../interfaces/Document";
import styles from "./styles.scss";

interface BaseProps {
  label: string;
}

export const Base = ({
  label,
  children,
  ...rest
}: BaseProps & { children: (info: IProgramInfo) => React.ReactNode }) => (
  <ProgramInfo.Consumer>
    {data => {
      const field = children(data || {});

      return field ? (
        <div {...rest} className={styles.field}>
          <TextComponents.H3 className={styles.label}>{label}</TextComponents.H3>
          <TextComponents.CellValue placeholder={"Not Entered"}>{field}</TextComponents.CellValue>
        </div>
      ) : null;
    }}
  </ProgramInfo.Consumer>
);
type FieldBaseProps = BaseProps & {
  name: string;
};

export const Text = ({ name, ...props }: FieldBaseProps) => <Base {...props}>{data => _.get(data, name)}</Base>;

export const Toggle = ({ name, ...props }: FieldBaseProps) => (
  <Base {...props}>{data => (_.get(data, name) ? "On" : "Off")}</Base>
);

export const Option = ({ name, ...props }: FieldBaseProps) => (
  <Base {...props}>
    {data => {
      const label = _.get(data, name + ".label");
      const description = _.get(data, name + ".description");

      return label && description ? (
        <React.Fragment>
          {label}
          <i>
            <br />
            {description}
          </i>
        </React.Fragment>
      ) : label ? (
        label
      ) : description ? (
        <i>{description}</i>
      ) : null;
    }}
  </Base>
);

export const Documents = ({ name, ...props }: FieldBaseProps) => (
  <Base {...props}>
    {data => {
      const documents: Document[] | undefined = _.get(data, name);

      return documents && documents.length > 0
        ? documents.map((doc, index) => (
            <React.Fragment key={doc.id}>
              {doc.name}{" "}
              <TextComponents.NewLink3 href={doc.url} target={"_blank"}>
                Download
              </TextComponents.NewLink3>
              {index + 1 < documents.length ? "; " : ""}
            </React.Fragment>
          ))
        : null;
    }}
  </Base>
);
