import React, { useEffect } from "react";
import { Field } from "formik";

import { GigOwnerFieldFragment } from "gql-gen";

import { ComposableField } from "modules/Connection/ComposableForm";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { GigProgramField } from "modules/Dashboard/Schema/Gigs/Fields/Program";
import Section from "components/Form/Section";

import ownerFragment from "./owner.gql";
import FOwnerPicker from "./Picker";

export interface GigOwnerValues {
  managerId?: string | null;
}

export const GigOwnerField: ComposableField<GigOwnerFieldFragment, GigOwnerValues> = {
  id: "manager",

  component: ({ data, values: { programId, managerId }, setFieldValue, partialBatchUpdate }) => {
    const { me } = useUserInfo();

    useEffect(() => {
      if (me && !data && !managerId) {
        setFieldValue("managerId", me.id);
      }
    }, [me]);

    return (
      <Section noMargin>
        <Field
          name="managerId"
          programId={programId}
          partialBatchUpdate={partialBatchUpdate}
          component={FOwnerPicker}
          compact
          testId="select-owner-partner"
        />
      </Section>
    );
  },

  initialize: data => {
    return { managerId: data && data.manager && data.manager.id };
  },

  fragment: ownerFragment,

  dependencies: [GigProgramField],
};
