import React from "react";
import { IAction } from "modules/Connection/types";
import approveIcon from "assets/check.svg";
import { colors } from "styles/variables";
import { useScopeContext } from "modules/Connection/Scope";
import { ActionProps } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { Elm } from "components/Elm";
import ElmVerify from "../Verify/Verify.elm";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { getProgramIdFromSearch } from "utilities/routes";
import { Formik, Field } from "formik";
import Section from "components/Form/Section";
import addToInvoice from "./addToInvoice.gql";
import { useMutation } from "@apollo/client";
import InvoicePicker from "./InvoicePicker";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const i18n = "gigs.actions.addToInvoice";

export const AddToInvoice: IAction = {
  key: "addToInvoice",
  icon: approveIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowInline: false,
  allowBatch: true,

  component: props => {
    const { variables, done } = props;
    const {
      match: {
        params: { gigId },
      },
    } = useRouter<ProgramsRouteParams & { gigId?: string }>();

    return (
      <ActionBody done={done} hideClose>
        {gigId ? <AddOne filters={variables.filters} /> : <AddMany {...props} />}
      </ActionBody>
    );
  },
};

function AddMany(props: ActionProps) {
  const { variables, done, renderSubmit } = props;
  const { t } = useTranslation();
  const [mutate, { loading }] = useMutation(addToInvoice);
  const [error, setError] = React.useState<string[] | null>(null);
  return (
    <ActionBody done={done}>
      <Formik
        initialValues={{ invoiceVersionId: "" }}
        onSubmit={async ({ invoiceVersionId }, { setStatus }) => {
          try {
            await mutate({
              variables: {
                ...variables,
                invoiceVersionId,
              },
            });

            done();
          } catch (e) {
            console.log(e);
            setError(Object.values(e.graphQLErrors?.[0]?.extensions?.exception?.args?.validations)?.[0] as string[]);
            setStatus({ error: true });
          }
        }}
      >
        {formik => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <Section>
                <Field component={InvoicePicker} name="invoiceVersionId"></Field>
              </Section>

              {error && (
                <Text color="pink1" size={12} key={error[0]}>
                  {t(`gigs.errors.${error[0]}`)}
                </Text>
              )}
              {renderSubmit({
                ...formik,
                i18n,
                disabled: !formik.values.invoiceVersionId || loading,
              })}
            </form>
          );
        }}
      </Formik>
    </ActionBody>
  );
}

function AddOne({ filters }: { filters: string }) {
  const {
    match: {
      url,
      params: { orgId, gigId },
    },
    history,
  } = useRouter<ProgramsRouteParams & { gigId?: string }>();
  const { reload } = useScopeContext();
  const id = getProgramIdFromSearch(location.search);
  return (
    <Elm
      key={"active"}
      src={ElmVerify.Elm.Verify}
      flags={{
        session: {
          serverUrl: getServerRootUrl() + "/graphql", // getNewServerRootUrl()
          haskellBackendBaseUrl: getHaskellBackendUrl(),
          rustBackendBaseUrl: getRustBackendUrl(),
          authToken: getJwt(),
        },
        gigId: gigId,
        organizationId: orgId,
        programIds: id === "all" ? [] : id.split(","),
        filters: filters,
      }}
      ports={ports => {
        ports.showUserModal.subscribe(userId => {
          history.push({ ...location, pathname: `${url}/+user-profile/${userId}` });
        });
        ports.showInvoice.subscribe(invoiceId => {
          history.push(`/${orgId}/invoices/${invoiceId}`);
        });
        ports.reloadGigsAndClose.subscribe(_ => {
          reload();
        });
      }}
    />
  );
}
