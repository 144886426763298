import * as React from "react";
import { makeContextHoC } from "utilities/context";

export interface AccordionContextProps {
  sections: string[];
  showHelpersColumn: boolean | null;
  toggle: (section: string, event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const AccordionContext = React.createContext<AccordionContextProps>({
  showHelpersColumn: true,
  sections: [],
  toggle: (section: string) => {},
});

export const withAccordionContext = makeContextHoC(AccordionContext, "accordionState");
