import * as React from "react";
// @ts-ignore: Update to tippy-js
import { Tooltip as Tippy } from "react-tippy";
import infoCircle from "assets/info-circle.svg";
import Icon from "components/Icon";
import styles from "./styles.scss";
import variables from "styles/variables";

export interface Props {
  title?: string;
  content: string;
  children?: React.ReactNode;
}

const Tooltip = (props: Props) => {
  return (
    <Tippy animation={"perspective"} html={onRenderContent(props)} position="bottom" trigger="click">
      <Icon src={infoCircle} small fill={variables.blue2} />
    </Tippy>
  );
};

function onRenderContent({ title, content, children }: Props) {
  return (
    <div className={styles.tooltipBody}>
      {title && <div className={styles.title}>{title}</div>}
      {content && <div className={styles.content}>{content}</div>}
      {children}
    </div>
  );
}

export default Tooltip;
