import * as React from "react";

import TextBox from "../TextBox";
import { validateEmail, getPhoneErrors } from "utilities/validation";
import styles from "./styles.scss";

export interface ContactQuestionFieldValue {
  name?: string;
  phone?: string | null;
  email?: string | null;
}

interface Props {
  placeholder: string;
  disabled?: boolean;
  setFieldValue: (fieldName: keyof ContactQuestionFieldValue) => (e: any) => void;
  value: ContactQuestionFieldValue | null;
}

function formattedPhoneValue(value: ContactQuestionFieldValue | null) {
  const phoneNum = value && value.phone;
  if (!phoneNum) {
    return "";
  }
  const input = phoneNum.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  let formattedNum;
  if (input.length > 6) {
    formattedNum = `(${zip})-${middle}-${last}`;
  } else if (input.length > 3) {
    formattedNum = `(${zip})-${middle}`;
  } else if (input.length > 0) {
    formattedNum = `(${zip}`;
  }
  return formattedNum;
}

export default function ContactBox(props: Props) {
  const { setFieldValue, placeholder, value, disabled } = props;
  const [namePlaceholder, phonePlaceholder, emailPlaceholder] = placeholder.split(", ");
  return (
    <React.Fragment>
      <div className={styles.contactFirstRow}>
        <TextBox
          placeholder={namePlaceholder}
          onChange={setFieldValue("name")}
          value={(value && value.name) || ""}
          label={"NAME"}
          disabled={disabled}
        />

        <TextBox
          placeholder={phonePlaceholder}
          onChange={setFieldValue("phone")}
          value={formattedPhoneValue(value)}
          label={"PHONE"}
          disabled={disabled}
          maxLength={14}
          optionalLabel
          errorMessage={(value && value.phone && getPhoneErrors(value.phone, false, "Invalid number")) || ""}
        />
      </div>
      <TextBox
        placeholder={emailPlaceholder}
        onChange={setFieldValue("email")}
        value={(value && value.email) || ""}
        label={"EMAIL"}
        disabled={disabled}
        optionalLabel
        errorMessage={value && value.email && !validateEmail(value && value.email) ? "Incorrect e-mail" : false}
      />
    </React.Fragment>
  );
}
