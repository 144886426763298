import * as React from "react";
import cx from "classnames";
import { FieldProps as FFieldProps } from "formik";
import Text from "components/LegacyText";
import { FieldProps } from "components/Form/BaseFormElement";
import Clickable from "components/Clickable";
import styles from "./styles.scss";

export interface NumberBoxProps extends FieldProps {
  value?: number | null;
  onChange: (value: number | null) => void;
  min?: number;
  max?: number;
  inputClassName?: string;
  className?: string;
  hideErrorMessage?: boolean;
  id?: string;
}

export default function NumberBox({
  label,
  value,
  onChange,
  min,
  max,
  inputClassName,
  errorMessage,
  hideErrorMessage,
  className,
  disabled,
  id,
}: NumberBoxProps) {
  const canGoDown = !disabled && (typeof min !== "number" || (value || 0) > min);
  const canGoUp = !disabled && (typeof max !== "number" || (value || 0) < max);

  const up = (e: any) => canGoUp && onChange((value || 0) + (e.altKey ? 10 : 1));
  const down = (e: any) => canGoDown && onChange((value || 0) - (e.altKey ? 10 : 1));

  const handleKeyPress = (e: any) => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowUp") {
      up(e);
      e.preventDefault();
    } else if (e.key === "ArrowDown") {
      down(e);
      e.preventDefault();
    }
  };

  const handleInputChange = (e: any) => {
    const number = parseInt(e.target.value, 10);
    onChange(Number.isNaN(number) ? null : number);
  };

  return (
    <div>
      <div className={cx(errorMessage ? styles.errorNumberBox : styles.numberBox, className)}>
        <div>
          {label && <Text.Label2 className={styles.label}>{label}</Text.Label2>}
          <input
            id={id}
            className={cx(styles.input, inputClassName)}
            value={value === null ? "" : value}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            disabled={disabled}
            placeholder={"–"}
            data-test="numberBox.input"
          />
        </div>
        <div className={styles.arrowButtons}>
          <NumberChangeButton direction={"up"} onClick={up} disabled={!canGoUp} testId="numberBox.up" />
          <NumberChangeButton direction={"down"} onClick={down} disabled={!canGoDown} testId="numberBox.down" />
        </div>
      </div>

      {errorMessage && !hideErrorMessage && <Text.Message kind={"error"}>{errorMessage}</Text.Message>}
    </div>
  );
}

function NumberChangeButton({
  direction,
  onClick,
  disabled,
  testId,
}: {
  direction: "up" | "down";
  onClick: (param0: {}) => void;
  disabled?: boolean;
  testId?: string;
}) {
  return (
    <Clickable
      disabled={disabled}
      className={styles.numberChangeButton}
      actionLabel={direction === "up" ? "Increase" : "Decrease"}
      onClick={onClick}
      testId={testId}
    >
      <svg width={10} height={5}>
        <polygon points={direction === "up" ? "0,5 5,0 10,5" : "0,0 5,5 10,0"} fill={"white"} />
      </svg>
    </Clickable>
  );
}

export function FNumberBox({
  field,
  form,
  ...props
}: FFieldProps & Omit<NumberBoxProps, "onChange" | "onBlur" | "value">) {
  return (
    <NumberBox
      {...props}
      value={form.values[field.name]}
      onChange={v => form.setFieldValue(field.name, v)}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
    />
  );
}
