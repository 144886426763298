import * as React from "react";
import cx from "classnames";
import variables from "styles/variables";
import styles from "./styles.scss";
export interface SiblingBoxProps {
  children: React.ReactNode;
  bgColor?: string;
  className?: string;
  tooltip?: string;
  onClick?: React.MouseEventHandler;
}

export default function SiblingBox({
  children,
  className,
  bgColor = variables.gray1,
  tooltip,
  onClick,
}: SiblingBoxProps) {
  return (
    <div
      className={cx(styles.siblingBox, className)}
      style={bgColor ? { backgroundColor: bgColor } : {}}
      data-tooltip={tooltip}
      data-tooltip-right
      onClick={onClick}
    >
      {children}
    </div>
  );
}
