import React, { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { GigExpenseInput } from "gql-gen";
import Text from "components/Text";
import Money from "components/Money";
import FilePicker from "components/Form/FilePicker";
import styles from "./styles.scss";
import DecimalBox from "components/Form/DecimalBox";
import OptionsDropdown from "components/OptionsDropdown";
import ImageGroup from "components/ImageGroup";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import deleteIcon from "assets/delete.svg";
import Thumbnail from "components/Thumbnail";
import * as fp from "fp-ts";

interface Props {
  className?: string;
  value: GigExpenseInput;
  onChange: (value: GigExpenseInput) => void;
  onRemove?: () => void;
  withType?: boolean;
  disabled?: boolean;
  fileName?: string;
  requiredReceipt?: boolean;
}

const eqExpenseInput = fp.eq.struct<GigExpenseInput["expense"]>({
  amount: fp.number.Eq,
  classKey: fp.eq.eqStrict,
  images: fp.function.pipe(
    fp.option.getEq(fp.array.getEq(fp.string.Eq)),
    fp.eq.contramap(
      fp.function.flow(fp.option.fromNullable, fp.option.map(fp.array.filterMap(fp.option.fromNullable))),
    ),
  ),
  memo: fp.eq.eqStrict,
  personal: fp.eq.eqStrict,
});

const eqGigExpenseInput = fp.eq.struct<GigExpenseInput>({
  expense: eqExpenseInput,
  byAgency: fp.eq.eqStrict,
  chargeAmount: fp.eq.eqStrict,
  expenseId: fp.eq.eqStrict,
  reimburseAmount: fp.eq.eqStrict,
});

function ClientExpenseCard({
  className,
  value: gigExpenseInput,
  onChange,
  onRemove,
  withType = true,
  requiredReceipt = true,
  fileName,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const i18nBase = "components.detailsModal.expensePayment.";

  const [draftGigExpenseInput, setDraftGigExpenseInput] = React.useState(gigExpenseInput);
  const {
    expense: { images, amount, personal, memo, classKey },
    chargeAmount,
    reimburseAmount,
    byAgency,
  } = draftGigExpenseInput;

  const image = !!images ? images[0] : null;

  React.useEffect(() => {
    if (!eqGigExpenseInput.equals(draftGigExpenseInput, gigExpenseInput)) {
      onChange(draftGigExpenseInput);
    }
  });

  return (
    <div className={styles.expenseCard}>
      <ImageGroup>
        {byAgency || !image ? (
          <FilePicker
            size={160}
            value={image ? [{ url: image }] : []}
            onChange={values => {
              setDraftGigExpenseInput({
                ...draftGigExpenseInput,
                expense: { ...draftGigExpenseInput.expense, images: values.map(({ url }) => url) || [] },
              });
            }}
            accepts={".pdf, image/*, image/heic"}
            testId={"expenseCard.image"}
            multi={false}
            compactRemove={true}
            fileName={fileName}
            disabled={disabled}
          />
        ) : (
          <Thumbnail size={160} image={image} fileName={fileName} />
        )}
      </ImageGroup>
      <div className={styles.expenseInfo}>
        <Text font={"wes"} size={10} transform={"uppercase"}>
          {byAgency ? t(`${i18nBase}agencyExpense`) : t(`${i18nBase}talentExpense`)}
        </Text>
        <div data-test="expenseCard.expenseAmount">
          <Money value={amount}></Money>
        </div>
        <Text size={12}>{personal ? t(`${i18nBase}personalCard`) : t(`${i18nBase}agencyAccount`)}</Text>
        <Text size={12} ellipsis className={styles.memo}>
          {memo ? `"${memo}"` : ``}
        </Text>
      </div>
      <div className={styles.itemsAndActions}>
        <div className={cx(styles.expenseItems)}>
          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform={"uppercase"}>
              {t(`${i18nBase}finalCharge`)}
            </Text>
            <DecimalBox
              value={chargeAmount}
              onChange={chargeAmount =>
                setDraftGigExpenseInput({
                  ...draftGigExpenseInput,
                  chargeAmount,
                  expense: { ...draftGigExpenseInput.expense },
                })
              }
              className={styles.basicMoney}
              disabled={disabled}
              testId={"expenseCard.finalCharge"}
            />
          </div>
          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform={"uppercase"}>
              {t(`${i18nBase}reimburseTalent`)}
            </Text>
            <DecimalBox
              value={reimburseAmount}
              onChange={reimburseAmount => {
                setDraftGigExpenseInput({
                  ...draftGigExpenseInput,
                  reimburseAmount,
                  expense: { ...draftGigExpenseInput.expense },
                });
              }}
              className={styles.basicMoney}
              disabled={disabled}
              testId={"expenseCard.reimburseTalent"}
            />
          </div>
          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform={"uppercase"}>
              {t(`${i18nBase}classifyAs`)}
            </Text>
            <div className={styles.classify}>
              <OptionsDropdown
                group="expenseClass"
                ddProps={{
                  onChange: e =>
                    setDraftGigExpenseInput({
                      ...draftGigExpenseInput,
                      expense: { ...draftGigExpenseInput.expense, classKey: e.target.value },
                    }),
                  value: classKey || undefined,
                  disabled,
                }}
                testId="expenseCard.classify"
              ></OptionsDropdown>
            </div>
          </div>
          <div className={styles.expenseItem}>
            <Text font={"wes"} size={10} transform={"uppercase"}>
              {t(`${i18nBase}memo`)}
            </Text>
            <input
              type="text"
              placeholder={t("components.expenseCard.typeHere")}
              value={memo || ""}
              onChange={e =>
                setDraftGigExpenseInput({
                  ...draftGigExpenseInput,
                  expense: { ...draftGigExpenseInput.expense, memo: e.target.value },
                })
              }
              disabled={disabled}
            />
          </div>
        </div>

        {onRemove && !disabled && (
          <div style={{ position: "relative" }}>
            <Clickable onClick={onRemove} className={styles.removeExpense}>
              <Icon size={18} src={deleteIcon} fill={"white"} />
            </Clickable>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientExpenseCard;
