import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import getServerRootUrl, { Endpoint, getNewServerRootUrl } from "utilities/getServerRootUrl";
import introspectionQueryResultData from "../generated/ts/fragmentTypes";
import { getBaseAppUrl } from "utilities/routes";
import { TypedTypePolicies } from "../generated/ts/apollo-helpers";
import Accounts from "@pinata-llc/elm-shared/src/Accounts";

export const createClient = (
  endpoint: Endpoint,
  getJwt: () => string | null = () => null,
  links?: ApolloLink[],
): ApolloClient<any> => {
  const networkUri = `${getServerRootUrl()}/graphql`;

  const batchNachoHttpLink = new BatchHttpLink({
    uri: networkUri,
    batchInterval: 10,
  });

  const nachoHttpLink = new HttpLink({
    uri: networkUri,
  });

  const apiHttpLink = new HttpLink({
    uri: `${getNewServerRootUrl()}/graphql`,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const headers = (context && context.headers) || {};
    headers.authorization = getJwt();
    operation.setContext({ headers });
    return forward ? forward(operation) : null;
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        Accounts.handleResponseError(error.message, getBaseAppUrl("accounts"));
      }
    }
  });

  // Leaving this for now in case we have cache issues in the future
  // cacheRedirects: {
  //   Query: {
  //     organizationLocation: (_, args, { getCacheKey }) =>
  //       args.id &&
  //       getCacheKey({
  //         __typename: "OrganizationLocation",
  //         id: args.id,
  //       }),
  //   },
  // },

  const typePolicies: TypedTypePolicies = {
    Query: {
      fields: {
        organizationLocation: {
          read: (_, { args, toReference }) =>
            args &&
            toReference({
              __typename: "OrganizationLocation",
              id: args.id,
            }),
        },
        gigs: {
          keyArgs: ["filters", "programIds", "orderBy"],
        },
      },
    },
  };

  const cache = new InMemoryCache({
    // Behold the most appropriate "any" ever
    dataIdFromObject: (o: any) => {
      switch (o.__typename) {
        case "OrganizationUser":
          return o.id && o.organizationId ? `OrganizationUser_${o.id}_${o.organizationId}` : undefined;
        case "OptionConfig":
          return o.group ? `OptionConfig_${o.group}_${o.key || o.id}` : undefined;
        case "Option":
          return o.group ? `Option_${o.group}_${o.key || o.id}` : undefined;
        case "State":
        case "ProgramType":
          return `${o.__typename}_${o.key || o.id}`;
        case "ReportDashboard":
        case "ReportDashboardImageQuestion":
          return undefined; // FIXME #899: Use program_id + role_id
        case "ReportGroup":
          return o.productId ? `${o.__typename}_${o.id}_${o.productId}` : `${o.__typename}_${o.id}`;
        case "ReportSubgroup":
        case "ReportGroupQuestion":
          return undefined;
        case "Program":
          return o.id ? `${o.__typename}_${o.id}` : undefined;
        default:
          return o.id ? `${o.__typename}_${o.id}` : undefined;
      }
    },
    typePolicies,
    possibleTypes: introspectionQueryResultData.possibleTypes,
  });

  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      ...(links || []),
      errorLink.split(
        o => {
          return o.getContext().clientName === "new-api";
        },
        apiHttpLink,
        ApolloLink.split(o => o.operationName.startsWith("_"), nachoHttpLink, batchNachoHttpLink),
      ),
    ]),
    cache: cache.restore((window as any).APOLLO_STATE__Internal || {}),
  });
};
