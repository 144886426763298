import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  tooltip?: string | null;
  inverse?: boolean;
  tertiary?: boolean;
}

export default function Label1({ children, inverse, tertiary, tooltip, className }: Props) {
  return (
    <label
      className={cx(styles.label, className, inverse && styles.inverse, tertiary && styles.tertiary)}
      data-tooltip={tooltip}
    >
      {children}
    </label>
  );
}
