import * as React from "react";
import { ComposableField } from "modules/Connection/ComposableForm";
import { FNumberBox } from "components/Form/NumberBox";
import { Field } from "formik";
import styles from "./styles.scss";

interface Copies {
  copies: number;
}

export const CopiesField: ComposableField<Copies, Copies> = {
  id: "copies",

  component: () => {
    return (
      <Field
        name={"copies"}
        component={FNumberBox}
        className={styles.copiesBox}
        inputClassName={styles.copiesInput}
        hideErrorMessage
        min={1}
        max={99}
      />
    );
  },

  validate: ({ copies }, { t }) => {
    if (copies > 99) {
      return { copies: t("gigs.create.maxCopies") };
    }
    return {};
  },

  initialize: () => {
    return { copies: 1 };
  },
};
