import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import ImageOpener from "components/ImageGroup/ImageOpener";
import Clickable from "components/Clickable";
import { transformImage } from "utilities/filestack";
import removeIcon from "assets/delete.svg";
import selectedIcon from "assets/check.svg";
import variables from "styles/variables";
import styles from "./styles.scss";
import { useState, useEffect } from "react";

interface Props {
  caption?: string;
  className?: string;
  image: string | null;
  size?: number;
  title?: string | null;
  onRemove?: () => void;
  missing?: boolean;
  selectable?: boolean;
  updateSelected?: (image: string) => void;
  compactRemove?: boolean;
  fileName?: string;
}

function getThumbnail(imageUrl: string, size: number) {
  return transformImage(imageUrl, {
    resize: { fit: "crop", width: size * 2, height: size * 2 },
  });
}

function Thumbnail({
  missing,
  className,
  image,
  size = 50,
  title,
  onRemove,
  selectable,
  updateSelected,
  compactRemove,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (!selectable) {
      setIsSelected(false);
    }
  }, [selectable]);

  return (
    <div
      className={cx(styles.default, className)}
      style={{
        backgroundImage: image ? `url(${getThumbnail(image, size)})` : "",
        backgroundColor: missing ? variables.pink2 : variables.gray5,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {image && onRemove && (
        <Clickable
          className={cx(styles.remove, compactRemove && styles.compactRemove)}
          actionLabel={"Remove"}
          onClick={e => {
            if (onRemove) onRemove();
            e.stopPropagation();
          }}
        >
          <Icon src={removeIcon} size={15} fill={"white"} />
        </Clickable>
      )}

      {!image && (
        <div>
          <Text.Display5 className={styles.none} kind={missing ? "highlight" : "secondary"}>
            None
          </Text.Display5>
        </div>
      )}

      {title && (
        <div className={styles.title}>
          <Text.H4 kind="reverse" className={styles.titleText}>
            {title}
          </Text.H4>
        </div>
      )}
      {selectable && image && (
        <div
          className={cx(styles.selectable, isSelected && styles.selected)}
          onClick={e => {
            e.stopPropagation();
            setIsSelected(!isSelected);
            updateSelected && updateSelected(image);
          }}
        >
          <div className={styles.selectableIcon}>
            {selectable && <Icon src={selectedIcon} size={18} fill={isSelected ? variables.blue2 : "#393937"}></Icon>}
          </div>
        </div>
      )}
    </div>
  );
}

export default function (props: Props) {
  const thumb = <Thumbnail {...props} />;

  if (props.image) {
    return (
      <ImageOpener
        src={props.image}
        caption={props.title || ""}
        className={cx(styles.default, props.className)}
        fileName={props.fileName}
      >
        {thumb}
      </ImageOpener>
    );
  }

  return thumb;
}
