import React from "react";
import programFragment from "./program.gql";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { connection, ConnectionConfig } from "utilities/connections";
import { GigProgramOptionsFragment } from "gql-gen";
import { bin } from "utilities/knueppel";
import { useTranslation } from "react-i18next";

export const programConnection: ConnectionConfig<any> = connection({
  name: "GigsWorkflowPrograms",
  entry: { name: "programConnections" },
  variables: { programIds: ["ID"], search: "String" },
});

export default function FProgramPicker({
  field,
  form,
  disabled,
  testId,
}: FieldProps & { name: string; disabled: boolean; testId?: string }) {
  const { t } = useTranslation();
  return (
    <SearchableSelect<GigProgramOptionsFragment>
      testId={testId}
      disabled={disabled}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      label={t("gigs.fields.program.program")}
      fragment={programFragment}
      multiple={false}
      connection={programConnection}
      value={field.value ? [field.value] : []}
      variables={{
        programIds: [],
        filters: {
          type: "LogicalExpression",
          left: bin("active", "=", true),
          operator: "&&",
          right: bin("atcapacity", "=", false),
        },
      }}
      renderName={program => program?.name ?? "Unnamed"}
      selectFirst
      clearSearchOnSelect
      onChange={ids => {
        if (!disabled) {
          form.setFieldValue(field.name, ids[0]);
          form.setFieldValue("productIds", []);
          form.setFieldValue("roleId", null);
          form.setFieldValue("partnerOrganizationId", undefined);
        }
      }}
    />
  );
}
