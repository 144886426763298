export function toBase64(value: string) {
  return btoa(toBinary(value));
}

export function fromBase64(binary: string) {
  try {
    return fromBinary(atob(binary));
  } catch (e) {
    if (e instanceof RangeError) {
      return atob(binary);
    } else {
      throw e;
    }
  }
}

export function toBinary(value: string) {
  const codeUnits = new Uint16Array(value.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = value.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

export function fromBinary(binary: string) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}
