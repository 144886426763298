import * as React from "react";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  duration: string;
  invariant?: string;
  primaryInvalid?: boolean;
}

export default function TotalDurationItem(props: Props) {
  return (
    <div className={styles.totalDurationItem}>
      <div>
        <Text.H4 className={styles.title}>Total duration:&nbsp;</Text.H4>
        <Text.Display4 kind={props.primaryInvalid ? "highlight" : "green"} className={styles.primary}>
          {props.duration}
        </Text.Display4>
      </div>
      <Text.P5 kind="secondary" className={styles.invariant}>
        <em>{props.invariant || <React.Fragment>&nbsp;</React.Fragment>}</em>
      </Text.P5>
    </div>
  );
}
