import * as React from "react";
import styles from "./styles.scss";
import cx from "classnames";

interface Props {
  distance: number;
  children?: React.ReactNode;
  className?: string;
}

export default function FooterDistance({ distance, children, className }: Props) {
  return (
    <div className={cx(styles.fd, className)} style={{ bottom: `calc(var(--footer-pos, 0px) + ${distance}px)` }}>
      {children}
    </div>
  );
}
