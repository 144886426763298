import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import Text, { Color } from "components/Text";

import { ValidationError } from "../Scope";
import styles from "./styles.scss";

const BUDGET_VALIDATION_ERRORS = ["brandsWithMissingBudget", "budgetExceeded"];

export const isBudgetError = (errKey: string) => BUDGET_VALIDATION_ERRORS.includes(errKey);

const isExceeded = (errKey: string) => errKey === "budgetExceeded";
const isMissing = (errKey: string) => errKey === "brandsWithMissingBudget";

type ActivityType = {
  name: string;
  internalIdentifier: string;
};

type Brand = {
  name?: string;
  identifier: string;
};

type Budget = {
  name?: string;
  brandName?: string;
  internalIdentifier: string;
};

type Exceeded = {
  budgets: Budget[];
  activityType?: ActivityType;
};

type Missing = {
  brands: Brand[];
  activityType?: ActivityType;
};

export type Error = ({ scope: "budget"; tag: "exceeded" } & Exceeded) | ({ scope: "budget"; tag: "missing" } & Missing);

export const extractErrors = (error: ValidationError): Error[] => {
  const budgetExceeded = error.details
    .filter(d => isExceeded(d.resCode))
    .flatMap(d => d?.conditions?.map(c => c?.values as Exceeded))
    .filter((m): m is Exceeded => Boolean(m))
    .map((values): Error => ({ scope: "budget", tag: "exceeded", ...values }));

  const budgetMissing = error.details
    .filter(d => isMissing(d.resCode))
    .flatMap(d => d?.conditions?.map(c => c?.values as Missing))
    .filter((m): m is Missing => Boolean(m))
    .map((values): Error => ({ scope: "budget", tag: "missing", ...values }));

  return [...budgetExceeded, ...budgetMissing];
};

const dashboardUrl = (orgUuid: string) => `/${orgUuid}/program-group/all/programs/financials/vendorBudgets`;

type Props = { message: Error; color?: Color };

export const Display = ({ message, color = "pink1" }: Props) => {
  const { t } = useTranslation();

  const { match } = useRouter<{ orgId: string }>();

  return (
    <div>
      {message.tag === "exceeded" ? (
        <BudgetExceededError message={message} color={color} />
      ) : message.tag === "missing" ? (
        <BudgetMissingError message={message} color={color} />
      ) : null}

      <Text color="white" size={12} style={{ marginTop: 10 }}>
        {t("components.scopeErrors.budget.view_dashboard")}{" "}
        <a href={dashboardUrl(match.params.orgId)} className={styles.errorLink}>
          {t("components.scopeErrors.budget.dashboard_name")}
        </a>
      </Text>
    </div>
  );
};

const displayBudget = (budget: Budget) => {
  const { brandName, name, internalIdentifier } = budget;

  if (brandName && name) {
    return `${brandName} (${name}: ${internalIdentifier})`;
  }

  if (brandName) {
    return `${brandName} (${internalIdentifier})`;
  }

  if (name) {
    return `${name} (${internalIdentifier})`;
  }

  return internalIdentifier;
};

const BudgetExceededError = ({ message, color }: { message: Exceeded; color: Color }) => {
  const { t } = useTranslation();

  const { budgets, activityType } = message;

  const activityType_ = activityType ? activityType.name : "<missing activity type>";

  return (
    <Text color={color} size={12}>
      {message.budgets.length === 1 ? (
        <p>
          {t("components.scopeErrors.budget.one_exceeded", {
            brand: displayBudget(budgets[0]),
            activityType: activityType_,
          })}{" "}
          {t("components.scopeErrors.budget.contact_gallo")}
        </p>
      ) : (
        <>
          <p>{t("components.scopeErrors.budget.many_exceeded", { activityType: activityType_ })}</p>
          <ul>
            {message.budgets.map(budget => (
              <li key={budget.name}>- {displayBudget(budget)}</li>
            ))}
          </ul>
          <p>{t("components.scopeErrors.budget.contact_gallo")}</p>
        </>
      )}
    </Text>
  );
};

const displayBrand = (brand: Brand) => (brand.name ? `${brand.name} (${brand.identifier})` : brand.identifier);

const BudgetMissingError = ({ message, color }: { message: Missing; color: Color }) => {
  const { t } = useTranslation();

  const { brands, activityType } = message;

  const activityType_ = activityType ? activityType.name : "<missing activity type>";

  return (
    <Text color={color} size={12}>
      {message.brands.length === 1 ? (
        <p>
          {t("components.scopeErrors.budget.one_missing", {
            brand: displayBrand(brands[0]),
            activityType: activityType_,
          })}{" "}
          {t("components.scopeErrors.budget.contact_gallo")}
        </p>
      ) : (
        <>
          <p>{t("components.scopeErrors.budget.many_missing", { activityType: activityType_ })}</p>
          <ul>
            {message.brands.map(brand => (
              <li key={brand.name}>- {displayBrand(brand)}</li>
            ))}
          </ul>
          <p>{t("components.scopeErrors.budget.contact_gallo")}</p>
        </>
      )}
    </Text>
  );
};
