import cx from "classnames";
import * as React from "react";
import Icon from "components/Icon";
import removeIcon from "assets/cancel-nobg.svg";
import variables from "styles/variables";
import styles from "./styles.scss";
import { Color } from "components/Text";
import Text from "components/Text";
import Clickable from "components/Clickable";

interface Props {
  children: React.ReactNode;
  blue?: boolean;
  onRemove?: () => void;
  className?: string;
  onClick?: () => void;
  childrenClassName?: string;
}

export default function RoundedPill({ children, blue, className, onRemove, onClick, childrenClassName }: Props) {
  return (
    <div className={cx(styles.pill, blue && styles.blue, className)} onClick={onClick}>
      <Text size={12} font="lato" color={blue ? "blue2" : "gray1"} ellipsis className={childrenClassName}>
        {children}
      </Text>
      {onRemove && (
        <Clickable onClick={onRemove} className={styles.remove}>
          <Icon src={removeIcon} small fill={variables.teal1} />
        </Clickable>
      )}
    </div>
  );
}
