import * as React from "react";

import Text from "components/LegacyText";
import Money from "components/Money";
import { useUserInfo } from "modules/Dashboard/UserInfo";

import * as FieldValue from "../FieldValue";
import ProgramInfo from "../ProgramInfo";

export default function Partnership() {
  const programInfo = React.useContext(ProgramInfo);
  const { partnerOrganizations, executionType } = programInfo;
  const userInfo = useUserInfo();
  const isAdmin = !!userInfo.me?.isAdmin;
  const organizationType = userInfo.me?.organizationUser?.organizationType || "client";
  const isMaestro = executionType === "maestro";

  return (
    <>
      {partnerOrganizations
        .filter(({ organization }: any) => organization)
        .map(({ organization, roleRates }: any) => {
          return (
            <FieldValue.Base label="Partner" key={organization.id}>
              {_ => (
                <React.Fragment key={organization.id}>
                  <Text.H2>{organization.name}</Text.H2> <br />
                  {roleRates.map((roleRate: any) => (
                    <React.Fragment key={roleRate.role.id}>
                      <Text.H3>{roleRate.role.title}</Text.H3>
                      {isAdmin && isMaestro ? (
                        <>
                          Bill Rate:&nbsp;
                          <Money value={roleRate.pinataMaestroRate || 0} /> <br />
                          Pay Rate:&nbsp;
                          <Money value={roleRate.rate || 0} />
                        </>
                      ) : (
                        <>
                          Default Rate:&nbsp;
                          <Money
                            value={
                              (organizationType === "client" && isMaestro
                                ? roleRate.pinataMaestroRate
                                : roleRate.rate) || 0
                            }
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </FieldValue.Base>
          );
        })}
    </>
  );
}
