import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import arrowDown from "assets/chevron.svg";
import styles from "./styles.scss";

interface State {
  open: boolean;
}

type RenderFunction = (s: State) => React.ReactNode;

interface Props {
  testId?: string;
  menuClassName?: string;
  renderMenu: () => React.ReactNode;
  children: RenderFunction | React.ReactNode;
  arrowColor?: string;
  showArrow?: boolean;
  triggerOnHover?: boolean;
}

export default class DropdownMenu extends React.Component<Props, State> {
  readonly state: State = {
    open: false,
  };

  public UNSAFE_componentWillMount() {
    window.addEventListener("click", this.close);
  }

  public componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  public close = (e: any) => {
    this.setState({ open: false });
  };

  public toggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  public render() {
    const {
      children,
      renderMenu,
      menuClassName,
      arrowColor = "#fff",
      showArrow = true,
      triggerOnHover = false,
      testId,
    } = this.props;
    const { open } = this.state;

    let handlers: React.HTMLAttributes<HTMLDivElement> = { onClick: this.toggle };

    if (triggerOnHover) {
      handlers = { ...handlers, onMouseOver: this.toggle, onMouseOut: this.close };
    }

    return (
      <div className={styles.container}>
        <div className={styles.trigger} {...handlers} data-test={testId}>
          {typeof children === "function" ? (children as RenderFunction)(this.state) : children}
          {showArrow && (
            <div className={open ? styles.arrowUp : styles.arrow}>
              <Icon src={arrowDown} size={16} fill={arrowColor} />
            </div>
          )}
        </div>
        {open && <div className={cx(styles.menu, menuClassName)}>{renderMenu()}</div>}
      </div>
    );
  }
}
