import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { GigDateFieldFragment, CreateGigsInput, ProgramExecutionTypeFragment, ProgramDatesFragment } from "gql-gen";
import { differenceInMinutes, set, parseISO, addMinutes, add, isSameDay } from "date-fns";
import { FDuration, FTimePicker } from "components/TimePicker";
import { FCalendarDropdown } from "components/Calendar/CalendarDropdown";
import { ComposableField } from "modules/Connection/ComposableForm";
import Text, { TextGroup } from "components/Text";
import dateFragment from "./date.gql";
import styles from "./styles.scss";
import { GigLocationField, GigLocationValues } from "../Location";
import { utcToZonedTime } from "date-fns-tz";
import { GigProgramField, GigProgramValue } from "../Program";
import executionTypeFragment from "../programExecutionType.gql";
import { LeaveBlank } from "../LeaveBlank";
import { useProgram } from "../useProgram";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { getTimeWithZone, joinDateAndTime } from "utilities/time";
import programDatesFragment from "./programDates.gql";
import Clickable from "components/Clickable";
import { FieldOptionsText } from "../FieldOptionsText";
import cx from "classnames";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import BaseFormElement from "components/Form/BaseFormElement";
import { StatelessTabs } from "components/StateTabs";
import { usePrevious } from "hooks/usePrevious";
import { WindowAssign } from "../WindowAssign";

export interface GigDateValues {
  start: Date | null;
  date: Date | null;
  duration: number | null | undefined;
  windowStart: Date | null | undefined;
  windowEnd: Date | null | undefined;
  dateType: "scheduled" | "deadline";
  windowAssign?: boolean;
}

const ns = "gigs.fields.date";

const TODAY = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

enum Unit {
  Hours = "Hours",
  Minutes = "Minutes",
}

const programFragments = [executionTypeFragment, programDatesFragment];

export const GigDateField: ComposableField<
  GigDateFieldFragment,
  GigDateValues,
  CreateGigsInput,
  GigLocationValues & GigProgramValue & { general?: string }
> = {
  id: "date",

  component: ({
    values: { dateType, programId, start, duration, windowStart, windowAssign },
    data,
    inline,
    setFieldValue,
    formMeta,
  }) => {
    const program = useProgram<ProgramExecutionTypeFragment & ProgramDatesFragment>(programId, programFragments);
    const previousProgram = usePrevious(program);

    useEffect(() => {
      const tz = data?.timezone ?? "GMT";
      if (program && (previousProgram || !data) && program?.id !== previousProgram?.id) {
        if (program.endDate) {
          setFieldValue("windowEnd", utcToZonedTime(program.endDate, tz));
        }

        setFieldValue("windowStart", program.startDate ? utcToZonedTime(program.startDate, tz) : TODAY);
      }
    }, [program]);

    const { me } = useUserInfo();
    const { t } = useTranslation();
    const [unit, setUnit] = useState<Unit>(Unit.Hours);

    const handleDurationChange = (e: any) => {
      const { value } = e.target;
      const regexp = new RegExp(/^\d+$/g);
      if (!regexp.test(value) && value.length !== 0) return;

      if (value.length === 0) {
        return setFieldValue("duration", null);
      } else {
        const v = parseFloat(value);
        if (unit === Unit.Hours) {
          return setFieldValue("duration", v * 60);
        } else {
          return setFieldValue("duration", v);
        }
      }
    };

    const onEndChange = (date: Date) => {
      if (start) {
        const diff = differenceInMinutes(date, start);
        setFieldValue("duration", diff < 0 ? diff + 24 * 60 : diff);
      }
    };

    const scheduledView = () => (
      <div className={cx(styles.expandedWrapper)}>
        <Field
          name="date"
          label={t(`${ns}.scheduledDate`)}
          className={styles.dropdown}
          component={FCalendarDropdown}
          min={
            me?.isAdmin || me?.organizationUser.organizationType === "agency" || program?.executionType === "solo"
              ? undefined
              : TODAY
          }
          testId={`${ns}.dayPicker`}
        />
      </div>
    );

    return (
      <div className={styles.dates}>
        <FieldOptionsText inlineForm={inline}>{t("gigs.fields.date.copy")}</FieldOptionsText>
        {formMeta.forcedDateType ? (
          scheduledView()
        ) : (
          <StatelessTabs
            activeTab={dateType as string}
            onChange={dateType => setFieldValue("dateType", dateType)}
            className={styles.tabs}
            compact
            tabs={[
              {
                title: t(`${ns}.scheduled`),
                key: "scheduled",
                render: scheduledView,
              },
              {
                title: t(`${ns}.deadline`),
                key: "deadline",
                render: () => (
                  <div className={styles.deadlineContainer}>
                    <TextGroup>
                      <Text color={"gray3"} size={12}>
                        {t(`${ns}.timeWindow`)}
                      </Text>
                      <Clickable onClick={() => window.open("https://help.gopinata.com/en/articles/5091149")}>
                        <Text color={"blue2"} bottom="s" top="xs" size={14}>
                          {t(`${ns}.learnMore`)}
                        </Text>
                      </Clickable>
                    </TextGroup>
                    <div className={styles.windows}>
                      <div className={cx(styles.windowContainer)}>
                        <div className={cx(styles.expandedWrapper)}>
                          <Field
                            name="windowStart"
                            component={FCalendarDropdown}
                            label={t(`${ns}.windowStart`)}
                            inline={inline}
                            min={
                              me?.isAdmin ||
                              me?.organizationUser.organizationType === "agency" ||
                              program?.executionType === "solo"
                                ? undefined
                                : TODAY
                            }
                            testId={`${ns}.windowStartPicker`}
                          />
                        </div>
                      </div>
                      <div className={cx(styles.windowContainer)}>
                        <div className={cx(styles.expandedWrapper)}>
                          <Field
                            name="windowEnd"
                            label={t(`${ns}.windowEnd`)}
                            component={FCalendarDropdown}
                            inline={inline}
                            min={
                              me?.isAdmin ||
                              me?.organizationUser.organizationType === "agency" ||
                              program?.executionType === "solo"
                                ? windowStart
                                  ? windowStart
                                  : undefined
                                : windowStart
                                ? windowStart
                                : TODAY
                            }
                            testId={`${ns}.windowEndPicker`}
                          />
                        </div>
                      </div>
                    </div>
                    <Field
                      name="windowStart"
                      className={styles.optional}
                      component={LeaveBlank}
                      extra={["windowEnd"]}
                      white={inline}
                    />
                  </div>
                ),
              },
            ]}
          ></StatelessTabs>
        )}
        <div className={styles.scheduleContainer}>
          {!dateType ||
            (dateType === "scheduled" ? (
              <div className={styles.startEnd}>
                <Field
                  name="start"
                  disabled={windowAssign}
                  className={cx(styles.dropdown)}
                  component={FTimePicker}
                  compact
                  label={t(`${ns}.startTime`)}
                  testId={`${ns}.startTime`}
                />
                <Field
                  name="end"
                  disabled={!start || windowAssign}
                  className={cx(styles.dropdown, styles.endDate)}
                  component={FTimePicker}
                  onChange={onEndChange}
                  value={duration && start ? addMinutes(start, duration) : null}
                  compact
                  label={t(`${ns}.endTime`)}
                  testId={`${ns}.endTime`}
                  baseDate={start || undefined}
                />
              </div>
            ) : (
              <BaseFormElement
                className={styles.startTimeInput}
                label={"START TIME"}
                disabled
                element={<input placeholder="Self-scheduled" />}
                testId={`${ns}.scheduledStartTimeInput`}
              />
            ))}
          <div className={styles.timePickers}>
            <div className={styles.durationContainer}>
              <Field
                name="duration"
                component={FDuration}
                onChange={handleDurationChange}
                value={duration && unit === Unit.Hours ? duration / 60 : duration}
                compact
                placeholder="#"
                label={t(`${ns}.duration`)}
                testId={`${ns}.duration`}
              />
              <RadioGroup
                className={styles.unitPicker}
                onChange={newUnit => setUnit(newUnit === "Hours" ? Unit.Hours : Unit.Minutes)}
                value={unit}
              >
                {["Hours", "Minutes"].map(opt => (
                  <RadioButton key={opt} value={opt}>
                    {opt}
                  </RadioButton>
                ))}
              </RadioGroup>
            </div>
          </div>
        </div>
        {dateType === "deadline" && (
          <Text align="left" top="s" bottom="xs" color={"gray3"} size={12}>
            {t(`${ns}.deadlineDescription`)}
          </Text>
        )}
        {dateType === "scheduled" && (
          <div className={styles.windowAssignContainer}>
            <Field
              name="windowAssign"
              component={WindowAssign}
              darkBackground={inline}
              onChange={(checked: boolean) => {
                if (checked) {
                  setFieldValue("start", null);
                  setFieldValue("end", null);
                  setFieldValue("duration", null);
                }
              }}
            />
            <div
              onClick={() => {
                setFieldValue("start", null);
                setFieldValue("end", null);
                setFieldValue("duration", null);
                setFieldValue("date", null);
              }}
            >
              <Text font="wes" size={12} color="blue2" noSelect>
                Clear selection
              </Text>
            </div>
          </div>
        )}
        {duration && duration / 60 >= 9 ? (
          <Text align="right" top="m" color={"pink1"} size={14}>
            {t(`${ns}.longGig`)}
          </Text>
        ) : null}
      </div>
    );
  },

  inlineMaxWidth: 550,

  initialize: (data, formMeta) => {
    const timezone = data?.timezone ?? "GMT";
    if (data) {
      if (
        data.windowStartTime &&
        data.windowEndTime &&
        data.timezone &&
        isSameDay(
          utcToZonedTime(data.windowStartTime, data.timezone),
          utcToZonedTime(data.windowEndTime, data.timezone),
        )
      ) {
        const date = utcToZonedTime(data.windowStartTime, timezone);
        return {
          dateType: formMeta?.forcedDateType || "scheduled",
          date,
          start: null,
          duration: data.duration,
          windowStart: null,
          windowEnd: null,
          windowAssign: data.windowAssign,
        };
      } else if (data.windowStartTime) {
        const windowStart = utcToZonedTime(data.windowStartTime, timezone);
        const windowEnd = data.windowEndTime ? utcToZonedTime(data.windowEndTime, timezone) : null;
        return {
          dateType: formMeta?.forcedDateType || "deadline",
          date: null,
          start: null,
          duration: data.duration,
          windowStart,
          windowEnd,
          windowAssign: data.windowAssign,
        };
      } else if (data.startTime) {
        const start = utcToZonedTime(data.startTime, timezone);
        return {
          dateType: formMeta?.forcedDateType || "scheduled",
          date: start,
          start,
          duration: data.duration,
          windowStart: null,
          windowEnd: null,
          windowAssign: data.windowAssign,
        };
      }
    }
    return {
      start: null,
      end: null,
      date: null,
      duration: data?.duration,
      windowStart: null,
      windowEnd: null,
      dateType: formMeta?.forcedDateType || "scheduled",
      windowAssign: data?.windowAssign,
    };
  },
  finalize: async ({ start, date, location, duration, windowStart, windowEnd, dateType, windowAssign }, data) => {
    if (dateType === "scheduled" && date) {
      if (start) {
        const { zonedDate, timezone } = await getTimeWithZone(joinDateAndTime(date, start), location);

        return {
          startTime: zonedDate.toISOString(),
          timezone,
          duration,
          windowEndTime: null,
          windowStartTime: null,
          windowAssign: windowAssign || false,
        };
      } else {
        const { zonedDate: windowStartTime, timezone } = await getTimeWithZone(date, location);
        const unzonedWindowEndTime = add(date, { hours: 23, minutes: 59, seconds: 59 });
        const { zonedDate: windowEndTime } = await getTimeWithZone(unzonedWindowEndTime, location);

        return {
          startTime: null,
          duration,
          timezone,
          windowStartTime: windowStartTime.toISOString(),
          windowEndTime: windowEndTime.toISOString(),
          windowAssign: windowAssign || false,
        };
      }
    } else if (dateType === "deadline" && windowStart) {
      const { zonedDate: windowStartTime, timezone } = await getTimeWithZone(windowStart, location);
      const windowEndTime = windowEnd && (await getTimeWithZone(windowEnd, location)).zonedDate;

      return {
        startTime: null,
        duration,
        timezone,
        windowStartTime: windowStartTime.toISOString(),
        windowEndTime: windowEndTime?.toISOString(),
        windowAssign: true,
      };
    }
    return {
      startTime: null,
      duration,
      timezone: location ? (await getTimeWithZone(new Date(), location)).timezone : data?.timezone ?? null,
      windowStartTime: null,
      windowEndTime: null,
      windowAssign: false,
    };
  },
  validate: ({ start, duration, windowAssign }, { t, partialBatchUpdate }) => {
    if (!!start && !windowAssign && !duration && !partialBatchUpdate) {
      return { duration: t("gigs.fields.date.noDuration") };
    }

    return {};
  },
  fragment: dateFragment,

  dependencies: [{ dep: GigLocationField, save: true }, GigProgramField],
};
