import React from "react";
import { GigMyRateFieldFragment, UpdateGigsInput } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import DecimalBox from "components/Form/DecimalBox";
import myRateFragment from "./myRate.gql";
import Section from "components/Form/Section";

type Values = Pick<UpdateGigsInput, "myRate">;

export const GigPartnerRateField: ComposableField<GigMyRateFieldFragment, Values, Values> = {
  id: "myRate",

  component: props => {
    const {
      values: { myRate },
      setFieldValue,
    } = props;

    return (
      <Section>
        <DecimalBox value={myRate} onChange={amount => setFieldValue("myRate", amount)} />
      </Section>
    );
  },

  initialize: data => {
    return {
      myRate: data?.myRate || 0,
    };
  },

  fragment: myRateFragment,

  dependencies: [],
};
