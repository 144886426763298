import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";
export interface Props {
  children: React.ReactNode;
  newStyle?: boolean;
  kind?: "primary" | "secondary" | "tertiary" | "reverse" | "inherit";
}

export default function Button({ children, newStyle, kind = "primary" }: Props) {
  return <span className={cx(styles.button, newStyle && styles.newStyle, styles[kind])}>{children}</span>;
}
