import Button from "components/Button";
import Text from "components/LegacyText";
import { MessageProps } from "components/LegacyText/Message";
import { Mobile } from "components/Layout/Responsive";
import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children?: React.ReactNode;
  message?: MessageProps | null;
  actionName?: string;
  actionDisabled?: boolean;
  actionButtonType?: "button" | "submit";
  noActionMargin?: boolean;
  hideAction?: boolean;
  secondaryActionName?: string;
  secondaryActionDisabled?: boolean;
  secondaryActionButtonType?: "button" | "submit";
  cancelButtonText?: string;
  showCancelButton?: boolean;
  onAction?: React.MouseEventHandler;
  onSecondaryAction?: React.MouseEventHandler;
  onCancel?: React.MouseEventHandler;
  testId?: string;
}

export default function ModalFooter({
  children,
  message,
  actionName,
  actionDisabled,
  actionButtonType = "button",
  noActionMargin,
  hideAction = false,
  secondaryActionName,
  secondaryActionDisabled,
  secondaryActionButtonType = "button",
  showCancelButton = true,
  cancelButtonText = "CANCEL",
  onAction,
  onSecondaryAction,
  onCancel,
  testId,
}: Props) {
  const messageView = message && React.createElement(Text.Message, message);

  let bottomView: React.ReactNode;

  if (secondaryActionName) {
    bottomView = (
      <React.Fragment>
        <div className={styles.otherOptions}>
          <div className={styles.otherOptionsLine} />
          <div className={styles.otherOptionsText}>OTHER OPTIONS</div>
          <div className={styles.otherOptionsLine} />
        </div>

        <div className={styles.bottomActions}>
          {showCancelButton && (
            <Button className={styles.cancelAlt} kind={"translucent"} onClick={onCancel}>
              <Text.Label1 className={styles.cancelLabel}>{cancelButtonText}</Text.Label1>
            </Button>
          )}

          <Button
            kind={"translucent"}
            type={secondaryActionButtonType}
            disabled={secondaryActionDisabled}
            className={styles.secondaryAction}
            onClick={onSecondaryAction}
          >
            {secondaryActionName}
          </Button>
        </div>
      </React.Fragment>
    );
  } else {
    bottomView = (
      <Button className={styles.cancel} onClick={onCancel}>
        <Text.Label1 className={styles.cancelLabel}>{cancelButtonText}</Text.Label1>
      </Button>
    );
  }

  return (
    <Mobile>
      {isMobile => (
        <div className={cx(hideAction ? styles.noActionFooter : styles.modalFooter)}>
          {children}

          {!hideAction && (
            <div className={noActionMargin || isMobile ? "" : styles.actionPadding}>
              <Button
                kind={"primary"}
                type={actionButtonType}
                disabled={actionDisabled}
                className={styles.action}
                onClick={onAction}
                testId={testId}
              >
                {actionName}
              </Button>
            </div>
          )}

          <div className={styles.padding}>
            <div className={messageView ? styles.visibleMessageWrapper : styles.messageWrapper}>{messageView}</div>
            {bottomView}
          </div>
        </div>
      )}
    </Mobile>
  );
}
