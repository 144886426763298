import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GigExpenseNotesFieldFragment, UpdateGigsInput, GigProgramExpenseFragment } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import TextBox from "components/Form/TextBox";
import { GigProgramValue, GigProgramField } from "../Program";
import expenseNotesFragment from "./expenseNotes.gql";
import { useProgram } from "../useProgram";
import programExpenseFragment from "./programExpense.gql";
import RadioButton from "components/RadioButton";
import Section from "components/Form/Section";

type Values = Pick<UpdateGigsInput, "expenseNotes">;

const expenseFragments = [programExpenseFragment];

export const GigExpenseNotesField: ComposableField<GigExpenseNotesFieldFragment, Values, Values, GigProgramValue> = {
  id: "expenseNotes",

  component: props => {
    const {
      values: { expenseNotes, programId },
      setFieldValue,
      inline,
      partialBatchUpdate,
    } = props;
    const { t } = useTranslation();
    const program = useProgram<GigProgramExpenseFragment>(programId, expenseFragments);

    const setDefault = () => {
      if (!expenseNotes && program?.expenses) {
        setFieldValue("expenseNotes", program.expenses);
      }
    };

    useEffect(setDefault, [program?.expenses]);

    return (
      <>
        <Section>
          <TextBox
            label={t("gigs.fields.expenseNotes.expenseNotes")}
            testId={"gigs.fields.expenseNotes"}
            placeholder={t("gigs.fields.expenseNotes.placeholder")}
            multiline
            rows={5}
            value={expenseNotes || ""}
            onChange={e => setFieldValue("expenseNotes", e.target.value)}
          />
        </Section>

        {!partialBatchUpdate && (
          <RadioButton
            selected={program ? expenseNotes === program.expenses : false}
            onChange={() => setFieldValue("expenseNotes", program ? program.expenses : null)}
            reverse={inline}
          >
            {t("gigs.fields.expenseNotes.useProgramsExpensePolicy")}
          </RadioButton>
        )}
      </>
    );
  },

  initialize: data => {
    return {
      expenseNotes: data?.expenseNotes ?? "",
    };
  },

  fragment: expenseNotesFragment,

  dependencies: [GigProgramField],
};
