import { useMutation } from "@apollo/client";
import { pick } from "lodash";

import { UpdateGigsInput } from "gql-gen";

import { ConnectionConfig, connection, ConnectionVars } from "utilities/connections";
import { Item } from "components/LegacySearchable";

import saveGigsMut from "./saveGigs.gql";

export const gigsConnection: ConnectionConfig<Item> = connection({
  name: "_Gigs",
  entry: { name: "gigs" },
  variables: { programIds: ["ID"], orderBy: ["OrderingGig"] as any },
});

export function useGigMutation() {
  const [saveGigs, { loading }] = useMutation(saveGigsMut);

  return {
    saveGigs: async (variables: ConnectionVars, data: UpdateGigsInput) => {
      await saveGigs({
        variables: {
          ...pick(variables, ["filters", "programIds"]),
          input: data,
        },
      });
    },
    loading,
  };
}
