import React, { useState, useMemo, createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { useApolloClient } from "@apollo/client";
import { DocumentNode } from "graphql";
import { omit } from "lodash";

import { ConnectionConfig, SrcConnection } from "utilities/connections";
import { useReloader, useReloaderCallback } from "modules/Connection/Reloader";
import { useConnectionConfig } from "hooks/useConnectionConfig";
import { useConnectionItems } from "hooks/useConnectionItems";
import { buildLogicalExpression, idIn } from "utilities/knueppel";
import { usePromise } from "hooks/usePromise";

import {
  IBlock,
  Item,
  EditStack,
  EditingState,
  Edit,
  SaveFn,
  ActionProvider,
  AvailableAction,
  FormOption,
  FormProvider,
  ValidationMessageProvider,
} from "./types";

export interface SrcScope<CC extends ConnectionConfig<Item>> extends SrcConnection<CC> {
  onSave: SaveFn;
  actions?: ActionProvider;
  getErrorMessage: ValidationMessageProvider;
}

interface ScopeProps<CC extends ConnectionConfig<Item>> extends SrcScope<CC> {
  ids?: string[];
  ready: boolean;
}

interface BlockIndex {
  [id: string]: IBlock<any>;
}

export interface IEditState {
  editing: EditingState;
  setEditing: (address: EditingState) => void;
  editStack: EditStack;
  pushEdit: (edit: Edit) => Promise<void>;
}

export interface ActionOptions {
  actions: AvailableAction[];
  primary: AvailableAction | null;
}

export type ValidationCondition = {
  key: string;
  message?: string;
  values?: { [key: string]: unknown };
};

export type ValidationDetail = {
  resCode: string;
  index: number;
  name?: string;
  conditions?: ValidationCondition[];
};

export interface ValidationError {
  type: "validation";
  /**
   * A map of task IDs to error codes.
   */
  items: { [id: string]: string[] };
  /**
   * A list of objects that contain the error code, the index of the task, and
   * some extra information about what failed, which is specific to each type of
   * error.
   */
  details: ValidationDetail[];
}

export const fromServerValidation = (error: any): ValidationError => ({
  type: "validation",
  items: error.extensions?.exception?.args.validations ?? [],
  details: error.extensions?.exception?.args?.validationDetails ?? [],
});

export type ScopeError = ValidationError | "network" | "unknown" | null;

export interface IScope<CC extends ConnectionConfig<Item> = ConnectionConfig<Item>>
  extends SrcConnection<CC>,
    IEditState {
  ids?: string[];
  blocks: BlockIndex | null;
  actions?: ActionProvider;
  query: DocumentNode;
  error: ScopeError;
  getErrorMessage: ValidationMessageProvider;
  getActions: (batch?: boolean) => Promise<ActionOptions>;
  nodes: any[] | undefined;
  onSave: SaveFn;
  reload: () => void;
  clearErrors: () => void;
  setFormMeta: (meta: any) => void;
  formMeta: any;
}

export interface IScopeCtx<CC extends ConnectionConfig<Item> = ConnectionConfig<Item>> extends IScope<CC> {
  addBlock?: <T>(id: string, block: IBlock<T>) => void;
  removeBlock?: (id: string) => void;
}

export const ScopeCtx = createContext<IScopeCtx | null>(null);

export function Scope<CC extends ConnectionConfig<Item>>({
  children,
  ...props
}: ScopeProps<CC> & { children: ReactNode }) {
  const [blocks, setBlocks] = useState<BlockIndex>({});

  const addBlock = <T extends {}>(id: string, block: IBlock<T>) => {
    setBlocks(blocks => ({ ...blocks, [id]: block }));
  };

  const removeBlock = (id: string) => {
    setBlocks(blocks => omit(blocks, id));
  };

  const scope = useScope({ ...props, blocks });

  return (
    <ScopeCtx.Provider
      value={{
        ...scope,
        addBlock,
        removeBlock,
      }}
    >
      {children}
    </ScopeCtx.Provider>
  );
}

interface UseScopeProps<CC extends ConnectionConfig<Item>> extends ScopeProps<CC> {
  blocks: BlockIndex | null;
}

export function useScope<CC extends ConnectionConfig<Item>>({
  connection,
  ids,
  variables,
  blocks,
  ready,
  actions,
  onSave,
  getErrorMessage,
}: UseScopeProps<CC>): IScope<CC> {
  variables = ids
    ? {
        ...variables,
        filters: buildLogicalExpression("&&", [variables.filters, idIn(ids)]),
        first: ids.length,
      }
    : variables;

  const reload = useReloader();

  const [stack] = useState<EditStack>([]);

  const [editing, setEditing] = useState<EditingState>(null);
  const [formMeta, setFormMeta] = useState<any>({});

  const fragments = useMemo(() => {
    if (blocks) {
      const blocksArr = Object.values(blocks);

      return blocksArr
        .map(b => b.cell.fragment!)
        .concat(
          blocksArr.map(
            ({ form }) => (form && isFormProvider(form) ? (form as FormProvider<any>).providerFragment : undefined)!,
          ),
        )
        .filter(f => !!f);
    }

    return [];
  }, [blocks]);

  const query = useConnectionConfig(connection, fragments);
  const skip = fragments.length < 1 || !ready;

  const [error, setError] = useState<ScopeError>(null);
  const [items, refetchItems] = useConnectionItems<Item, CC>(connection, fragments, !ids || skip ? [] : ids);

  const pushEdit = async (edit: Edit) => {
    try {
      await onSave(variables, edit.changes);
      await Promise.all([...reload(connection.name), refetchItems()]);
      setError(null);
    } catch (err) {
      if (!err.graphQLErrors) throw err;

      const error = err.graphQLErrors[0];

      if (error && error.message === "validationError") {
        setError(fromServerValidation(error));
      } else if (error && error.networkError) {
        setError("network");
      } else {
        setError("unknown");
      }
      throw err;
    }
  };

  const nodes = ids ? Object.values(items) : [];

  const client = useApolloClient();

  const getActions = async (batch?: boolean): Promise<ActionOptions> => {
    if (!actions) return { actions: [], primary: null };

    let options = await actions(variables, client);

    if (batch) {
      options = options.filter(action => action.action.allowBatch);
    }

    options = options.sort((a, b) => a.order - b.order);

    return { actions: options, primary: options.find(action => action.primary) || null };
  };

  return {
    ids,
    query,
    connection,
    variables,
    actions,
    getActions,
    blocks,
    nodes,
    editing,
    setEditing,
    editStack: stack,
    pushEdit,
    error,
    getErrorMessage,
    reload: () => reload(connection.name),
    onSave,
    clearErrors: () => setError(null),
    setFormMeta,
    formMeta,
  };
}

export function isFormProvider(form: FormOption): form is FormProvider<any> {
  return "provide" in form && "providerFragment" in form;
}

export function useScopeContext() {
  const ctx = useContext(ScopeCtx);
  if (!ctx) throw new Error("Couldn't find a Connection Scope Context");
  return ctx;
}

export function useBlock<T>(id: string, block?: IBlock<T>): { nodes: T[] } {
  const scope = useScopeContext();

  useEffect(() => {
    if (!scope.addBlock || !scope.removeBlock) {
      throw new Error("Can't add blocks to this scope dynamically");
    }
    if (block) {
      scope.addBlock(id, block);
    }

    return () => {
      scope.removeBlock!(id);
    };
  }, [id, block && block.cell, block && block.form]);

  return { nodes: scope.nodes as T[] };
}

export function useActions() {
  const { getActions, connection } = useScopeContext();
  const [updateId, update] = useReducer(x => x + 1, 0);

  useReloaderCallback(
    connection.name,
    () => {
      update();
    },
    [update],
  );

  return usePromise(() => getActions(), [updateId]);
}
