// --- IMPORTANT ---
//
// This file is duplicated in ApiNacho/WebNacho. Please keep both files in sync.
//
// To reuse the code we need to figure out why the commons package build is not
// working for ApiNacho's build.
import * as date from "date-fns/fp";
import { match, P } from "ts-pattern";
import * as A from "fp-ts/ReadonlyArray";
import { pipe, identity } from "fp-ts/function";

type ViewportDateRange =
  | { days: number }
  | { start: string; end: string }
  | { unit: "week"; frame: "last" | "current" }
  | { unit: "month"; frame: "current" };

type Filter = { name: string; value: unknown | unknown[] };
type Filters = readonly Filter[];

export interface Viewport {
  aggregation: unknown;
  daterange?: ViewportDateRange;
  filters: Filters;
}

export type GroupedFilters = Record<string, (string | number)[]>;

export interface ParsedViewport {
  aggregation: unknown;
  daterange?: { start: string; end: string };
  filters: GroupedFilters;
  original: Viewport;
}

type PeriscopeName = string;
type ApiKey = string;

// NOTE: Casing is not consistent in the Periscope widget
export const PeriscopeToApiKeys: Record<PeriscopeName, ApiKey> = {
  activity: "activity_ids",
  agency: "agency_ids",
  brand: "brand_ids",
  brands: "brand_ids",
  client: "client_ids",
  gogetter: "go_getter_ids",
  location: "location_names",
  locations: "location_ids",
  loggedinorg: "organization_id",
  loggedinuser: "user_id",
  partner: "partner_ids",
  pos_types: "product_tags",
  product: "product_ids",
  product_tags: "product_tags",
  producttags: "product_tags",
  program: "program_ids",
  question: "question_ids",
  saved_locations: "location_ids",
  state: "states",
  status: "gig_states",
  talent: "talent_ids",
  week: "weeks",
};

const formatDate = date.format("yyyy-MM-dd");

const mkMonthRange = () => ({
  start: pipe(new Date(), date.startOfMonth, formatDate),
  end: pipe(new Date(), date.endOfMonth, formatDate),
});

// Note: the API takes a date format for this range, time should not be included
export const parseDates = (daterange?: ViewportDateRange) => {
  return match(daterange)
    .with(P.nullish, { days: P.nullish }, () => undefined)
    .with({ start: P.string, end: P.string }, identity)
    .with({ days: P.number }, ({ days }) => ({
      start: pipe(new Date(), date.subDays(days), date.startOfDay, formatDate),
      end: pipe(new Date(), formatDate),
    }))
    .with({ unit: "week", frame: "last" }, () => ({
      start: pipe(new Date(), date.subWeeks(1), date.startOfISOWeek, formatDate),
      end: pipe(new Date(), date.subWeeks(1), date.endOfISOWeek, formatDate),
    }))
    .with({ unit: "week", frame: "current" }, () => ({
      start: pipe(new Date(), date.startOfISOWeek, formatDate),
      end: pipe(new Date(), date.endOfISOWeek, formatDate),
    }))
    .with({ unit: "month", frame: "current" }, mkMonthRange)
    .exhaustive();
};

const numOrStr = (v: string | number) => {
  const parsed = parseInt(v.toString(), 10);
  return isNaN(parsed) ? v : parsed;
};

export const parseFilters = (filters: readonly Filter[]) =>
  pipe(
    filters,
    A.reduce<Filter, GroupedFilters>({}, (acc, filter) => {
      const parsed = Array.isArray(filter.value)
        ? filter.value.filter(v => typeof v === "string" || typeof v === "number").map(numOrStr)
        : typeof filter.value === "string" || typeof filter.value === "number"
        ? [numOrStr(filter.value)]
        : [];

      const periscopeName = filter.name.toLowerCase();

      const key = PeriscopeToApiKeys[periscopeName] ?? filter.name;

      acc[key] = (acc[key] ?? []).concat(parsed);

      return acc;
    }),
  );

export const parseViewport = (viewport: Viewport): ParsedViewport => ({
  aggregation: viewport.aggregation,
  daterange: parseDates(viewport.daterange),
  filters: parseFilters(viewport.filters),
  original: viewport,
});
