import React, { ReactNode } from "react";

import Text from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";

interface Props {
  testId?: string;
  active?: boolean;
  onClick: () => void;
  children: ReactNode;
}

export function RoundedTab({ testId, children, active, onClick }: Props) {
  return (
    <Clickable className={active ? styles.active : styles.tab} testId={testId} onClick={onClick}>
      <Text color="white" font="wes" bold size={12} noSelect>
        {children}
      </Text>
    </Clickable>
  );
}
