import * as React from "react";

import Text from "components/LegacyText";
import Money from "components/Money";

import * as FieldValue from "../FieldValue";
import ProgramInfo from "../ProgramInfo";

export default function InvitedBy() {
  const programInfo = React.useContext(ProgramInfo);
  const { clientOrganizations } = programInfo;

  return (
    <>
      {clientOrganizations
        .filter(({ organization }: any) => organization)
        .map(({ organization, roleRates }: any) => {
          return (
            <FieldValue.Base label="Client">
              {_ => (
                <React.Fragment key={organization.id}>
                  <Text.H2>{organization.name}</Text.H2>
                  <br />
                  {roleRates.map((roleRate: any) => (
                    <React.Fragment key={roleRate.role.id}>
                      <Text.H3>{roleRate.role.title}</Text.H3>
                      Rate:&nbsp;
                      <Money value={roleRate.rate || 0} />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </FieldValue.Base>
          );
        })}
    </>
  );
}
