import * as React from "react";
import Text from "components/LegacyText";

interface Props {
  children: React.ReactNode | null;
  className?: string;
  placeholder?: string;
  inline?: boolean;
}

export default function CellValue({ children, className, inline, placeholder = "None" }: Props) {
  if (!children && children !== 0) {
    return (
      <Text.P4 kind={"secondary"} className={className} inline={inline}>
        {placeholder}
      </Text.P4>
    );
  }

  return (
    <Text.P4 className={className} inline={inline}>
      {children}
    </Text.P4>
  );
}
