import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Text from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";

export interface DayProps {
  outOfRange: boolean;
  inMonth: boolean;
  active: boolean;
  date: Date;
  dateNumber: number;
  onClick?: () => void;
}

interface MonthProps {
  year: number;
  month: number;
  min?: Date;
  max?: Date;
  value?: Date | null;
  onChange?: (d: Date) => void;
  className?: string;
  rowClassName?: string;
  dayNames?: boolean;
  renderDay?: (props: DayProps) => ReactNode;
}

export const DAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const WEEKS = [0, 1, 2, 3, 4, 5];

export function MonthView({
  year,
  month,
  value,
  onChange,
  className,
  renderDay = DefaultCalendarDay,
  dayNames = true,
  min,
  max,
}: MonthProps) {
  const { t } = useTranslation();
  const firstDay = new Date(year, month, 1).getDay();
  const lastDate = new Date(year, month + 1, 0).getDate();
  const previousMonthLastDate = new Date(year, month, 0).getDate();
  return (
    <div className={cx(styles.calendar, className)}>
      {dayNames && (
        <div className={styles.row}>
          {DAYS.map(day => (
            <div key={day} className={styles.cell} aria-label={t(`components.calendar.days.${day}.long`)}>
              <Text font={"wes"} bold size={10}>
                {t(`components.calendar.days.${day}.short`)}
              </Text>
            </div>
          ))}
        </div>
      )}
      {WEEKS.map(week => (
        <div key={week} className={styles.row}>
          {DAYS.map((day, dayOfWeek) => {
            let num = week * 7 + 1 + dayOfWeek - firstDay;
            let cm = month;

            if (num <= 0) {
              num = previousMonthLastDate + num;
              cm--;
            } else if (num > lastDate) {
              num = num - lastDate;
              cm++;
            }

            const inMonth = cm === month;
            let active = false;

            const date = new Date(year, cm, num);

            if (value) {
              active =
                value.getFullYear() === date.getFullYear() &&
                value.getMonth() === date.getMonth() &&
                value.getDate() === date.getDate();
            }

            const outOfRange = (!!min && min > date) || (!!max && max < date);

            return (
              <React.Fragment key={dayOfWeek}>
                {renderDay({
                  inMonth,
                  active,
                  date,
                  dateNumber: num,
                  outOfRange,
                  onClick: onChange && !outOfRange ? () => onChange(new Date(year, cm, num)) : undefined,
                })}
              </React.Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
}

function DefaultCalendarDay({ outOfRange, inMonth, dateNumber, active, onClick }: DayProps) {
  return (
    <Clickable
      className={cx(styles.cell, styles.day, inMonth && !outOfRange && styles.inMonthDay, active && styles.active)}
      aria-selected={active}
      onClick={onClick}
      testId={`createGig.date.${dateNumber}`}
    >
      <Text font={"wes"} color={outOfRange ? "gray4" : active ? "white" : inMonth ? "gray3" : "gray4"} size={10}>
        {dateNumber}
      </Text>
    </Clickable>
  );
}
