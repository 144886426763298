import React from "react";
import _ from "lodash";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

import { GigRoleFieldFragment } from "gql-gen";

import Section from "components/Form/Section";
import Text from "components/Text";
import { ComposableField } from "modules/Connection/ComposableForm";
import { useUserInfo } from "modules/Dashboard/UserInfo";

import roleFragment from "./role.gql";

import styles from "./styles.scss";

import { FRolePicker } from "./RolePicker";
import FRateField from "./Rate";
import { GigProgramField, GigProgramValue } from "../Program";

export interface GigRoleValues {
  roleId?: string;
}

export const GigRoleField: ComposableField<GigRoleFieldFragment, GigRoleValues, GigRoleValues, GigProgramValue> = {
  id: "role",

  component: ({ values, data, partialBatchUpdate }) => {
    const { t } = useTranslation();
    const { me, loading } = useUserInfo();
    const gigVerified = data?.workflowStateSection !== "active" && data?.workflowStateSection !== undefined;

    if (loading) {
      return <Text color={"white"}>Loading...</Text>;
    }

    if (gigVerified && (!me || !me.isAdmin)) {
      return <Text color={"white"}>{t("gigs.fields.role.cannotChangeAfterHappening")}</Text>;
    }

    return (
      <>
        <Field
          name="roleId"
          component={FRolePicker}
          compact
          programId={values.programId}
          testId={"gigs.fields.role.rolePicker"}
          partialBatchUpdate={partialBatchUpdate}
        />

        {data?.program?.executionType === "pro" && me?.organizationUser?.organizationType === "agency" && (
          <Section className={styles.section}>
            <Field component={FRateField} name="myRate" testId={"gigs.fields.role.myRate"} compact />
          </Section>
        )}
      </>
    );
  },

  validate: ({ roleId }, { t, partialBatchUpdate }) => {
    if (!roleId && !partialBatchUpdate) return { roleId: t("gigs.fields.role.pleasePickARole") };
    return {};
  },

  initialize: data => {
    return {
      roleId: (data && data.programRole && data.programRole.role && data.programRole.role.id) || undefined,
    };
  },

  fragment: roleFragment,

  dependencies: [GigProgramField],
};
