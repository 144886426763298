import React from "react";
import verifyIcon from "assets/verify.svg";
import { colors } from "styles/variables";
import { useScopeContext } from "modules/Connection/Scope";
import { IAction } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { Elm } from "components/Elm";
import BatchVerify from "./BatchVerify.elm";
import Verify from "./Verify.elm";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = `gigs.actions.verify`;

export const VerifyAction: IAction = {
  key: "reportVerification",
  icon: verifyIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowBatch: true,
  allowInline: false,

  component: ({ variables, done }) => {
    const {
      match: {
        params: { gigId },
      },
    } = useRouter<ProgramsRouteParams & { gigId?: string }>();

    return (
      <ActionBody done={done}>
        {gigId ? <VerifyOne variables={variables} /> : <VerifyMany variables={variables}></VerifyMany>}
      </ActionBody>
    );
  },
};

function VerifyMany({ variables }: { variables: any }) {
  const { reload } = useScopeContext();
  const {
    match: {
      params: { orgId },
    },
    location,
  } = useRouter<ProgramsRouteParams>();

  const id = getProgramIdFromSearch(location.search);
  return (
    <Elm
      src={BatchVerify.Elm.BatchVerify}
      flags={{
        session: {
          serverUrl: getServerRootUrl() + "/graphql", // getNewServerRootUrl()
          haskellBackendBaseUrl: getHaskellBackendUrl(),
          rustBackendBaseUrl: getRustBackendUrl(),
          authToken: getJwt(),
        },
        organizationId: orgId,
        programIds: id === "all" ? [] : id.split(","),
        filters: variables.filters,
      }}
      ports={ports => {
        ports.reloadGigsAndClose.subscribe(_ => {
          reload();
        });
      }}
    />
  );
}

function VerifyOne({ variables }: { variables: any }) {
  const { reload } = useScopeContext();
  const {
    match: {
      url,
      params: { orgId, gigId },
    },
    history,
  } = useRouter<ProgramsRouteParams & { gigId?: string }>();

  const id = getProgramIdFromSearch(location.search);

  return (
    <Elm
      src={Verify.Elm.Verify}
      flags={{
        session: {
          serverUrl: getServerRootUrl() + "/graphql", // getNewServerRootUrl()
          haskellBackendBaseUrl: getHaskellBackendUrl(),
          rustBackendBaseUrl: getRustBackendUrl(),
          authToken: getJwt(),
        },
        gigId,
        organizationId: orgId,
        programIds: id === "all" ? [] : id.split(","),
        filters: variables.filters,
      }}
      ports={ports => {
        ports.reloadGigsAndClose.subscribe(_ => {
          reload();
        });
        ports.showUserModal.subscribe(userId => {
          history.push({ ...location, pathname: `${url}/+user-profile/${userId}` });
        });
        ports.showInvoice.subscribe(invoiceId => {
          history.push(`/${orgId}/invoices/${invoiceId}`);
        });
      }}
    />
  );
}
