import { pick } from "lodash";
import { ApolloClient } from "@apollo/client";
import { GigActionsQuery } from "gql-gen";

import actionsQuery from "./actions.gql";
import { RequestApproval } from "./RequestApproval";
import { ApproveAction } from "./Approve";
import { CancelAction } from "./Cancel";
import { AssignAction } from "./Assign";
import { AuthorizePayment } from "./AuthorizePayment";
import { DeleteAction } from "./Delete";
import { DuplicateAction } from "./Duplicate";
import { UntrashAction } from "./Untrash";
import { UpdateAction } from "./Update";
import { UpdateOwnerAction } from "./UpdateOwner";
import { DownloadReceiptsAction } from "./DownloadReceipts";
import { AvailableAction, IAction } from "modules/Connection/types";
import { UndoReviewAction } from "./UndoReview";
import { OwnerVerify } from "./OwnerVerify";
import { OwnerVerified } from "./OwnerVerified";
import { SelectPartnerAction } from "./SelectPartner";
import { VerifyAction } from "./Verify";
import { AddToInvoice } from "./AddToInvoice";
import { Confirmation } from "./Confirmation";
import { Billing } from "./Billing";
import { ToggleClosed, Close } from "./ToggleClosed";

const allActions = [
  RequestApproval,
  ApproveAction,
  CancelAction,
  DeleteAction,
  AssignAction,
  SelectPartnerAction,
  AuthorizePayment,
  DuplicateAction,
  UntrashAction,
  UpdateAction,
  UpdateOwnerAction,
  DownloadReceiptsAction,
  UndoReviewAction,
  OwnerVerify,
  OwnerVerified,
  VerifyAction,
  AddToInvoice,
  Billing,
  ToggleClosed(Close.Close),
  ToggleClosed(Close.UndoClose),
  Confirmation,
];

const index: { [key: string]: IAction } = {};

for (const action of allActions) {
  index[action.key] = action;
}

export async function selectGigActions(variables: unknown, client: ApolloClient<unknown>): Promise<AvailableAction[]> {
  const { data } = await client.query<GigActionsQuery>({
    query: actionsQuery,
    variables: pick(variables, ["programIds", "filters"]),
    fetchPolicy: "network-only",
  });

  if (data && data.gigActions) {
    return data.gigActions.map(({ actionKey, actionKeyCount, actionPrimary, actionOrder }) => ({
      action: index[actionKey],
      count: actionKeyCount,
      primary: actionPrimary,
      order: actionOrder,
    }));
  }
  return [];
}
