import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";
import cx from "classnames";

import Text from "components/Text";

import styles from "./styles.scss";
import { stringifyQuery, addQueries } from "utilities/routes";
import Clickable from "components/Clickable";
import { NEW_ID_PLACEHOLDER } from "modules/Dashboard/Organization/LegacySettings/LocationFormModal";
import { GigLocation } from "interfaces/GigLocation";
import locationCardQuery from "./locationCard.gql";
import { LocationFinderCardQuery, LocationFinderCardQueryVariables } from "gql-gen";
import { useQuery } from "@apollo/client";
import { Mobile } from "components/Layout/Responsive";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import { toBase64 } from "utilities/base64";

interface Props {
  googleLocation: GigLocation;
  orgLocationId?: string;
  // location: SelectedLocation;
  className?: string;
  getFormValues: () => unknown;
  inline?: boolean;
}

export default function LocationCard({
  orgLocationId,
  className,
  getFormValues,
  googleLocation,
  googleLocation: { latitude, longitude, address, name: googleName },
  inline,
}: Props) {
  const { t } = useTranslation();
  const {
    history,
    match,
    match: {
      params: { orgId },
    },
  } = useRouter<OrganizationRouteParams>();

  const i18nBase = "components.locationFinder.";

  const { data } = useQuery<LocationFinderCardQuery, LocationFinderCardQueryVariables>(locationCardQuery, {
    skip: !orgLocationId,
    variables: {
      id: orgLocationId,
    },
  });

  const savedLocation = data && data.organizationLocation;

  return (
    <Mobile>
      {isMobile => {
        return (
          <div className={cx(styles.card, inline && styles.workflowCard, className, isMobile && styles.mobileCard)}>
            <div className={cx(styles.map, inline && styles.workflowMap)}>
              <a href={`https://maps.google.com?q=${latitude},${longitude}`} target="_blank">
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?zoom=14&size=${
                    isMobile ? 310 : 120
                  }x120&maptype=roadmap
&markers=size:mid%7C${latitude},${longitude}&key=AIzaSyBfNJaJoX6eIqKDiRgQUOGsyM-iL7kS2Gs`}
                ></img>
              </a>
            </div>
            <div className={cx(styles.address, inline && styles.workflowAddress)}>
              <Text font="wes" size={10} transform="uppercase">
                {t(`${i18nBase}address`)}
              </Text>
              <Text size={14} color="gray3">
                {savedLocation ? savedLocation.name : googleName}
              </Text>
              <Text size={14} color="gray3">
                {address}
              </Text>
            </div>
            <div className={cx(styles.locationInfo, inline && styles.workflowLocationInfo)}>
              <Text font="wes" size={10} transform="uppercase" color={savedLocation ? "teal1" : "gray4"}>
                {t(`${i18nBase}locationDatabase`)}
              </Text>
              {savedLocation ? (
                <>
                  <Text size={14} color="gray3">
                    {savedLocation.name}
                  </Text>
                  <Text size={14} color="gray3">
                    {savedLocation.internalIdentifier}
                  </Text>
                </>
              ) : (
                <Text size={14} color="gray3" em>
                  This location is not in your location database
                </Text>
              )}
              <Clickable
                // href={history.createHref({
                //   ...routerLocation,
                //   pathname: `/${orgId || ""}/settings/locations/+edit-location/${id}`,
                // })}
                // target="_blank"
                onClick={() =>
                  history.push({
                    ...location,
                    pathname: `/${orgId || ""}/settings/locations/${
                      savedLocation ? `+edit-location/${savedLocation.id}` : "+add-location"
                    }`,
                    search: stringifyQuery({
                      continue: addQueries(match.url, {
                        values: toBase64(JSON.stringify(getFormValues())),
                        newLocId: savedLocation ? "" : NEW_ID_PLACEHOLDER,
                      }),

                      selectedItem: googleLocation ? toBase64(JSON.stringify(googleLocation)) : "",
                    }),
                  })
                }
              >
                <Text size={14} color="blue2" bold>
                  {savedLocation ? t(`${i18nBase}fullDBInfo`) : t(`${i18nBase}saveIt`)}
                </Text>
              </Clickable>
            </div>
          </div>
        );
      }}
    </Mobile>
  );
}
