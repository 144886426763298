@import "~styles/variables";

.container {
  padding: $l;
  flex: 1;
  display: flex;
  overflow: auto;

  .itemsList {
    flex: 1 1 auto;

    > li {
      display: flex;
      flex-direction: row;
      width: auto;
      white-space: nowrap;
      justify-content: flex-start;
      align-items: center;
      margin: 5px $s;

      &.listWithChildren {
        flex-direction: column;
      }

      > .checkboxContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
      }
    }

    > .mainItem {
      border-bottom: 1px solid $gray-4;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }

  .childrenList {
    padding-top: $xs;
    width: 100%;
  }

  .mainList {
    margin-bottom: $s;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
  }
}

.compactContainer {
  padding: $s $s 0px $s;
}

.verticalContainer {
  overflow: hidden;
  flex-direction: column;
}

.label {
  text-transform: capitalize;
  margin-left: $s;
}

.horizontalToColumn {
  flex-direction: column;
}

.verticalToColumn {
  flex-direction: column;
  margin-left: 40px;
  width: unset;
}

.listClosed {
  max-height: 0;
  transition: all 0.15s ease-out;
  opacity: 0;
  overflow: hidden;
}

.listOpen {
  max-height: 800px;
  opacity: 1;
  transition: all 0.15s ease-in;
}

.arrowBtn {
  margin-left: auto;
  margin-right: $s;
}

.close {
  padding: $s $s 0;
}
