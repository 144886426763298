import React from "react";
import ReactMarkdown from "markdown-to-jsx";

import styles from "./styles.scss";

export function Markdown({ children }: { children: string }) {
  return (
    <div className={styles.markdownWrapper}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  );
}
