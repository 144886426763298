import * as React from "react";
import { Program } from "../../../../interfaces/Program";
import Text from "components/LegacyText";
import ListItem, { ListItemProps } from "components/ListView/ListItem";
import Checkbox from "components/Form/Checkbox";
import GoButton from "components/GoButton";
import styles from "./styles.scss";
import Icon from "components/Icon";
import arrowUpToLineSolid from "assets/arrow-up-to-line-solid.svg";

interface Props {
  program: Program;
  selected?: boolean;
  onClick?: React.MouseEventHandler;
  onGo: React.MouseEventHandler;
  onToggle: (active: boolean) => void;
  itemRef?: ListItemProps["itemRef"];
  focused?: ListItemProps["focused"];
}

export default function ProgramItem({
  program: { name, managementState, atCapacity },
  selected,
  onToggle,
  onClick,
  onGo,
  focused,
  itemRef,
}: Props) {
  return (
    <ListItem className={atCapacity ? styles.atCapacityItem : ""} focused={focused} itemRef={itemRef} onClick={onClick}>
      <div className={styles.item}>
        {managementState === "accepted" && <Checkbox checked={selected} onCheck={onToggle} />}
        {atCapacity ? (
          <span className={styles.iconWrapper}>
            <Icon src={arrowUpToLineSolid} size={14} fill="#c12454" />
          </span>
        ) : null}
        <Text.P2 className={atCapacity ? "" : styles.name}>
          {name}
          {atCapacity ? <span className={styles.atCapacityText}>AT CAPACITY</span> : null}
        </Text.P2>
        <div className={"spacer"} />

        <GoButton linkProps={{ onClick: onGo }} />
      </div>
    </ListItem>
  );
}
