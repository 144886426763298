import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withFormik, FormikProps, Field } from "formik";
import * as yup from "yup";
import Form from "components/Form";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { withAccordionContext, AccordionContextProps } from "components/AccordionLayout/AccordionContext";
import { inputProps } from "utilities/formik";
import { User } from "interfaces/user";
import SaveButton from "../SaveButton";
import { withProgramFormContext, validateAndNotify, ProgramFormContext } from "../Context";
import userDetails from "./userDetails.gql";
import styles from "./styles.scss";
import { OptionField } from "modules/Dashboard/interfaces/Option";
import { ProgramsRouteParams } from "../../index";
import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";
import billingContactFragment from "./billingContact.gql";
import RadioButton from "components/RadioButton";

interface BillingValues {
  payer: OptionField;
  accountingCode: string;
  billingContactId: string;
}

interface Props extends FormikProps<BillingValues>, RouteComponentProps<ProgramsRouteParams> {
  programForm: ProgramFormContext;
  userDetailsQuery: {
    organization: { id: string; name: string } | null;
    me: User | null;
  };

  accordionState: AccordionContextProps;
}

const billingContactsConnection: ConnectionConfig<any> = connection({
  name: "OrgBillingContactOptions",
  entry: { name: "billingContactsConnections" },
  variables: { search: "String", organizationId: "ID" },
});

const ID = "adminInfo";

function AdminInfo(props: Props) {
  const {
    values,
    programForm: { canEdit },
    userDetailsQuery: { organization },
    history,
    location,
    setFieldValue,
  } = props;

  const input = inputProps(props, undefined, !canEdit);

  return (
    <React.Fragment>
      <AccordionPart>
        <AccordionPartHelper />

        <AccordionPartContent>
          <Form.Section>
            <Form.Dropdown {...input("payer.key")} label={"WHO's PAYING?"}>
              <option value={"organization"}>{organization ? organization.name : "My organization"}</option>
              <option value={"other"}>Other</option>
            </Form.Dropdown>
            {values.payer && values.payer.key === "other" && (
              <Form.TextBox {...input("payer.description")} placeholder={"Please describe..."} />
            )}
          </Form.Section>
          <Form.HorizontalSectionGroup>
            <Form.Section>
              <Field
                name="billingContactId"
                render={({ field, form }: any) => (
                  <SearchableSelect<any>
                    testId={"programs.billingContactPicker"}
                    label={"BILLING CONTACT"}
                    errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
                    multiple={false}
                    connection={billingContactsConnection}
                    fragment={billingContactFragment}
                    variables={{ organizationId: organization?.id }}
                    value={field.value ? [field.value] : []}
                    renderName={contact => contact.firstName + " " + contact.lastName}
                    onChange={ids => form.setFieldValue(field.name, ids[0])}
                    addNewConfig={{
                      text: "Add a Billing Contact",
                      onClick: () =>
                        history.push({
                          ...location,
                          search: location.search,
                          pathname: `${location.pathname}/+add-billing-contact`,
                        }),
                    }}
                  />
                )}
              />
            </Form.Section>

            <Form.Section>
              <Form.TextBox
                {...input("accountingCode")}
                label={"ACCOUNTING CODE / PO #"}
                placeholder={"Write here…"}
                optionalLabel={true}
              />
            </Form.Section>
          </Form.HorizontalSectionGroup>

          <RadioButton
            selected={!values.billingContactId}
            onChange={() => {
              setFieldValue("billingContactId", null);
            }}
            className={styles.clearBillingContact}
          >
            Leave Billing Contact unspecified
          </RadioButton>

          <SaveButton id={ID} topMargin />
        </AccordionPartContent>
      </AccordionPart>
    </React.Fragment>
  );
}

const schema = yup.object().shape({
  payer: yup.object().shape({
    key: yup
      .string()
      .matches(/^(organization|other)$/)
      .nullable()
      .required(),
    description: yup.string().nullable(),
  }),

  accountingCode: yup.string().nullable(),
});

export default compose(
  withRouter,
  withAccordionContext,
  withProgramFormContext,

  graphql<Props, unknown, { orgId: string }, unknown>(userDetails, {
    name: "userDetailsQuery",
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
    }),
  }),

  withFormik<Props, BillingValues>({
    mapPropsToValues: ({
      programForm: {
        values: { payer, ...values },
      },
    }) => {
      return {
        ...values,
        payer: payer || { key: "organization", description: null },
      };
    },
    validate: validateAndNotify(schema, schema, schema, ID),
    handleSubmit: () => {},
  }),
)(AdminInfo);
