import { flowRight as compose } from "lodash";
import * as React from "react";
import moment from "moment";
import LoadingContainer from "components/LoadingContainer";
import Table, { TableProps } from "components/Table";
import Cell from "components/Table/Cell";
import Row from "components/Table/Row";
import LegacyText from "components/LegacyText";
import Text from "components/Text";
import Form from "components/Form";
import { withSearch, SearchContext } from "components/Search";
import TableFilters from "components/TableFilters";
import { IAction } from "components/Table/actions";
import Pill from "components/Pill";
import styles from "./styles.scss";
import cx from "classnames";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { User, UserType, USER_TYPES, USER_TYPE_NAMES, isUserAtLeast } from "interfaces/user";
import { UserInfoQuery } from "../../../UserInfo";
import { UserLink } from "modules/Dashboard/UserLink";
import TagFilter from "components/TagFilter";
import { TagGroup } from "gql-gen";

export interface UsersQuery {
  users: User[];
}

export interface UsersTableProps extends SearchContext<User>, UserInfoQuery, RouteComponentProps {
  data: {
    loading: boolean;
  } & UsersQuery;

  tableProps?: Omit<TableProps, "children">;
  isUserTypeSelectable?: (userType?: string) => boolean;
  actions?: (userType: UserType, unverifiedAccount: boolean, userId: string) => IAction[];
  orgId: string;
  onlyAllowedToHavePrograms?: boolean;
  children?: React.ReactNode;
}

type StatusFilter = UserType | "all";

interface State {
  statusFilter: StatusFilter;
  //programsFilter: string[];
  tagFilter: string[];
}

const COLUMNS = [
  { label: "Team Member", size: 80 },
  { label: "Status", size: 35 },
  { label: "Email" },
  { label: "Phone" },
  { label: "Acct. created" },
];

class UsersTable extends React.Component<UsersTableProps, State> {
  readonly state: State = {
    statusFilter: "all",
    tagFilter: [],
  };

  public render() {
    const {
      data: { users },
      tableProps,
      search,
      onSearchChange,
      results,
      isUserTypeSelectable,
      userInfo: { me },
    } = this.props;

    const { statusFilter, tagFilter } = this.state;

    if (!users) {
      return <LoadingContainer message={"Loading users..."} />;
    }
    let filter = results;

    if (statusFilter !== "all") {
      filter = (filter || users).filter(u => u.userType === statusFilter);
    }

    if (tagFilter.length) {
      filter = (filter || users).filter(u =>
        tagFilter.every(t => (u?.organizationUser?.tags ?? []).map(tag => tag.id.toString()).indexOf(t) > -1),
      );
    }

    return (
      <React.Fragment>
        {this.props.children}
        <TableFilters>
          <Form.Dropdown
            label="PINATA STATUS"
            value={statusFilter}
            onChange={e => this.setState({ statusFilter: e.target.value as StatusFilter })}
          >
            <option value={"all"}>All statuses</option>
            {USER_TYPES.map(type => (
              <option key={type} value={type}>
                {USER_TYPE_NAMES[type]}
              </option>
            ))}
          </Form.Dropdown>

          <TagFilter
            group={TagGroup.Users}
            onChange={tagFilter => this.setState({ tagFilter: tagFilter || [] })}
            value={tagFilter || []}
          />

          <Form.TextBox label={"SEARCH"} placeholder={"by name, email..."} onChange={onSearchChange} value={search} />
        </TableFilters>
        <Table
          visibleColumns={2}
          selectable={true}
          rowHeight={"small"}
          filter={filter ? filter.map(d => d.id) : undefined}
          {...tableProps}
          columns={COLUMNS}
        >
          {users.map(user => {
            const {
              firstName,
              lastName,
              userType,
              email,
              unconfirmedEmail,
              unconfirmedPhone,
              phone,
              createdAt,
              id,
            } = user;

            const unverifiedAccount = !firstName || !lastName;
            const label = unverifiedAccount ? email ?? unconfirmedEmail : `${firstName} ${lastName}`;
            const actions = this.props.actions ? this.props.actions(userType || "gogetter", unverifiedAccount, id) : [];

            return (
              <Row
                key={id}
                id={id}
                label={label}
                actions={actions}
                selectable={isUserTypeSelectable ? isUserTypeSelectable(userType) : true}
              >
                <Cell size={80}>
                  <div className={styles.nameCell}>
                    <LegacyText.CellValue className={cx(unverifiedAccount && styles.unverifiedAccount)}>
                      {unverifiedAccount ? (
                        <Text color="blue2" font="wes" bold size={14} noSelect>
                          {label}
                        </Text>
                      ) : (
                        <UserLink id={id}>
                          <Text color="blue2" font="wes" bold size={14} noSelect>
                            {label}
                          </Text>
                        </UserLink>
                      )}
                    </LegacyText.CellValue>
                    {me && id === me.id ? <Pill className={styles.you}>you</Pill> : ""}
                    {unverifiedAccount && <Pill className={styles.pending}>pending</Pill>}
                  </div>
                </Cell>
                <Cell size={35}>
                  <LegacyText.CellValue>{userType && USER_TYPE_NAMES[userType]}</LegacyText.CellValue>
                </Cell>
                <Cell>
                  <LegacyText.CellValue>{email ?? unconfirmedEmail}</LegacyText.CellValue>
                </Cell>
                <Cell>
                  <LegacyText.CellValue>{phone ?? unconfirmedPhone}</LegacyText.CellValue>
                </Cell>
                <Cell>
                  <LegacyText.CellValue>{moment(createdAt).format("MM/DD/YYYY")}</LegacyText.CellValue>
                </Cell>
              </Row>
            );
          })}
        </Table>
      </React.Fragment>
    );
  }
}

export default compose(
  withSearch<User, UsersTableProps>({
    mapPropsToItems: props => {
      return props.data.users;
    },
    keys: ["firstName", "lastName", "email", "unconfirmedEmail"],
  }),
  withRouter,
)(UsersTable) as React.ComponentType<Omit<UsersTableProps, keyof SearchContext<User> | keyof RouteComponentProps>>;
