import React from "react";
import { useTranslation } from "react-i18next";
import { GigsRoleFragment } from "gql-gen";
import { ICell } from "modules/Connection/types";
import { CellBase, CR1, AddDetails } from "modules/Connection/CellBase";
import icon from "assets/info-circle.svg";
import fragment from "./role.gql";

export const RoleCell: ICell<GigsRoleFragment> = {
  component: props => {
    const { t } = useTranslation();
    const { data, handleOpenForm } = props;

    if (!data || !data.role) {
      return <AddDetails onClick={handleOpenForm}>{t("gigs.columns.role.addRole")}</AddDetails>;
    }

    return <CellBase {...props}>{() => <CR1>{data.role!.title}</CR1>}</CellBase>;
  },
  icon: icon,
  fragment: fragment,
};
