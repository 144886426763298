import * as React from "react";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface HelperProps {
  children: React.ReactNode;
  inline?: boolean;
}

export const HelperSubtitle = ({ children, inline }: HelperProps) => (
  <Text.Display4 kind={"secondary"} className={styles.helperSubtitle} inline={inline}>
    {children}
  </Text.Display4>
);

export const Helper1 = ({ children, inline }: HelperProps) => (
  <Text.P2 kind={"primary"} className={styles.helper} inline={inline}>
    {children}
  </Text.P2>
);

export const Helper2 = ({ children, inline }: HelperProps) => (
  <Text.P4 kind={"secondary"} className={styles.helper} inline={inline}>
    {children}
  </Text.P4>
);

export const HelperHiLi = ({ children }: HelperProps) => <span className={styles.helperHighlight}>{children}</span>;
