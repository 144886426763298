import * as React from "react";
import cx from "classnames";

import AccordionPartHelper from "../AccordionPartHelper";
import AccordionPartContent from "../AccordionPartContent";
import Line from "../Line";
import styles from "./styles.scss";

interface Props {
  children: React.ReactElement<typeof AccordionPartHelper | typeof AccordionPartContent>[];
  className?: string | null;
}

export default function AccordionPart({ children, className }: Props) {
  return <Line className={cx(styles.accPart, className)}>{children}</Line>;
}
