import * as React from "react";
import cx from "classnames";
import Icon, { IconSrc } from "components/Icon";
import Text from "components/LegacyText";
import Shortcuts, { shortcut } from "components/Shortcuts";
import variables from "styles/variables";
import closeIcon from "assets/cancel-nobg.svg";
import styles from "./styles.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  mainAction: React.ReactNode;
  noSpacer?: boolean;
  icon?: IconSrc;
  onMainActionClicked?: () => void;
  onClose: () => void;
}

export default function ModalHeader({
  icon,
  mainAction,
  onMainActionClicked,
  onClose,
  children,
  className,
  noSpacer,
}: Props) {
  return (
    <Shortcuts shortcuts={[shortcut("esc", onClose)]}>
      <header className={cx(styles.header, className)}>
        <div onClick={onMainActionClicked} className={styles.title}>
          {icon && <Icon src={icon} fill={variables.teal1} size={30} />}
          <Text.Display2 kind={"green"}>{mainAction}</Text.Display2>
        </div>
        {!noSpacer && <div className={"spacer"} />}
        <div>{children}</div>
        <div className={styles.close} onClick={onClose}>
          <Icon src={closeIcon} fill={variables.gray3} size={20} />
        </div>
      </header>
    </Shortcuts>
  );
}
