import * as React from "react";
import Table from "components/Table";
import Row from "components/Table/Row";
import Cell from "components/Table/Cell";
import Text from "components/LegacyText";
import Icon from "components/Icon";
import variables from "styles/variables";
import ProgramInfo from "../ProgramInfo";
import cancelIcon from "assets/cancel.svg";
import checkIcon from "assets/check.svg";

const COLUMNS = [
  { label: "Team Member", size: 60 },
  { label: "Status", size: 60 },
  { label: "Is contact?", size: 25 },
];

export default function Team() {
  return (
    <React.Fragment>
      <ProgramInfo.Consumer>
        {({ team }) => {
          return (
            <Table columns={COLUMNS} selectable={false} rowHeight={"large"} visibleColumns={4}>
              {team.map((teamMember: any) => (
                <Row key={teamMember.userId} id={teamMember.userId} label={teamMember.userType}>
                  <Cell size={60}>
                    <Text.CellValue placeholder={"——"}>
                      {`${teamMember.firstName} ${teamMember.lastName}`}
                    </Text.CellValue>
                  </Cell>
                  <Cell size={60}>
                    <Text.CellValue placeholder={"——"}>
                      {teamMember.userType ? <React.Fragment>{teamMember.userType}</React.Fragment> : null}
                    </Text.CellValue>
                  </Cell>
                  <Cell size={25}>
                    {() => {
                      const { isContact } = teamMember;
                      return (
                        <Icon
                          src={isContact ? checkIcon : cancelIcon}
                          fill={isContact ? variables.teal1 : variables.pink1}
                          size={20}
                        />
                      );
                    }}
                  </Cell>
                </Row>
              ))}
            </Table>
          );
        }}
      </ProgramInfo.Consumer>
    </React.Fragment>
  );
}
