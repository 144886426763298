import React from "react";
import { useTranslation } from "react-i18next";
import { GigsTalentPersonFragment } from "gql-gen";
import { CellBase, CRNone, CR1, CR2, AddDetails } from "modules/Connection/CellBase";
import UserAvatar from "components/UserAvatar";
import fragment from "./talentPerson.gql";
import icon from "assets/street-person.svg";
import { ICell } from "modules/Connection/types";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { UserLink } from "../../../../UserLink";

export const TalentPersonCell: ICell<GigsTalentPersonFragment> = {
  component: props => {
    const { t } = useTranslation();
    const { me } = useUserInfo();

    const { data, handleOpenForm } = props;

    const isTalentOrg =
      me && data && data.talentOrganization && me.organizationUser.organizationId === data.talentOrganization.id;

    if (!data || !data.assignedGogetter) {
      return (
        <CellBase {...props}>
          {() =>
            isTalentOrg ? (
              <AddDetails onClick={handleOpenForm}>{t("gigs.columns.talentPerson.assign")}</AddDetails>
            ) : (
              <CRNone>{t("gigs.columns.talentPerson.notAssigned")}</CRNone>
            )
          }
        </CellBase>
      );
    }

    const { assignedGogetter, talentRate } = data;

    return (
      <CellBase {...props} detailedSide={() => <UserAvatar user={assignedGogetter} square />}>
        {() => (
          <>
            <CR1 underline="hover">
              <UserLink id={assignedGogetter.id}>
                {assignedGogetter.firstName} {assignedGogetter.lastName && assignedGogetter.lastName[0]}.
              </UserLink>
            </CR1>

            {isTalentOrg && (
              <CR2>
                {talentRate
                  ? t("gigs.columns.talentPerson.rate", { talentRate })
                  : assignedGogetter?.organizationUser?.salaried
                  ? t("gigs.columns.talentPerson.salaried")
                  : t("gigs.columns.talentPerson.notSpecifiedRate")}
              </CR2>
            )}
          </>
        )}
      </CellBase>
    );
  },
  icon,
  fragment,
};
