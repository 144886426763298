import * as React from "react";

export type IconSrc = React.ComponentType<any>;

export interface IconProps {
  src: IconSrc;
  height?: number;
  width?: number;
  size?: number;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  fill?: string;
  rotate?: number;
}

export default function Icon({ src: Component, height, width, size, small, medium, large, fill, rotate }: IconProps) {
  let sizing = size;
  if (small) {
    sizing = 15;
  } else if (medium) {
    sizing = 40;
  } else if (large) {
    sizing = 60;
  }

  // This gets messed up if undefined is passed in as undefined.
  const fillProp = fill ? { fill } : {};
  let icon = <Component height={height || sizing} width={width || sizing} {...fillProp} />;

  if (rotate !== undefined) {
    icon = (
      <div
        style={{
          width: width || sizing,
          height: height || sizing,
          transform: `rotate(${rotate}deg)`,
          transition: "0.3s transform ease",
        }}
      >
        {icon}
      </div>
    );
  }

  return icon;
}
