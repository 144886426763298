import * as React from "react";
import Button from "components/Button";
import { ProgramFormCtx, ProgramFormContext } from "../Context";
import styles from "./styles.scss";

interface Props {
  id: string;
  topMargin?: boolean;
}

export default function SaveButton({ id, topMargin }: Props) {
  return (
    <ProgramFormCtx.Consumer>
      {({ saveSection, canEdit }: ProgramFormContext) =>
        canEdit ? (
          <Button
            kind={"primaryGradient"}
            type={"submit"}
            onClick={saveSection(id)}
            className={topMargin ? styles.topMargin : ""}
          >
            SAVE & CONTINUE
          </Button>
        ) : null
      }
    </ProgramFormCtx.Consumer>
  );
}
