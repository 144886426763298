import React from "react";
import Text from "components/Text";

export function ActionTitle({ children, className }: { children: string; className?: string }) {
  return (
    <Text font="wes" bold size={12} color="white" letterSpacing={1.2} bottom={"s"} className={className}>
      {children.toLocaleUpperCase()}
    </Text>
  );
}
