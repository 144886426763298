import { useMemo } from "react";
import { DocumentNode } from "graphql";
import { buildQuery } from "houbolt";
import { ConnectionConfig, PEnv } from "utilities/connections";

export function useConnectionConfig(
  config: ConnectionConfig | undefined,
  fragments: DocumentNode[] = [],
  name?: string,
): DocumentNode {
  return useMemo(
    () =>
      config
        ? buildQuery<PEnv, any>({ ...config, name: config.name + (name ? "_" + name : ""), fragments })
        : EMPTY_QUERY,
    [config, fragments],
  );
}

const EMPTY_QUERY: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "",
      },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fake",
            },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
