export function inflect(count: number, singular: string, plural: string, displayNumber?: boolean) {
  return (displayNumber ? count + " " : "") + (count === 1 ? singular : plural);
}

export function formatDistance(distance: number) {
  if (distance >= 10) {
    return Math.ceil(distance).toString();
  } else {
    return distance.toFixed(1).toString();
  }
}

export const isString = (s: unknown): s is string => typeof s === "string";
