import React from "react";
import { Field } from "formik";
import { GigProductFieldFragment } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import productFragment from "./product.gql";
import FProductPicker from "./ProductPicker/ProductPicker";
import { LeaveBlank } from "../LeaveBlank";

export interface GigProductValue {
  productIds: string[];
}

export const GigProductField: ComposableField<GigProductFieldFragment, GigProductValue> = {
  id: "product",

  component: ({ inline, partialBatchUpdate }) => {
    return (
      <>
        <Field
          name="productIds"
          component={FProductPicker}
          testId={"gigs.fields.product.productPicker"}
          inlineForm={inline}
          partialBatchUpdate={partialBatchUpdate}
        />
        <Field name="productIds" component={LeaveBlank} white={inline} />
      </>
    );
  },

  initialize: data => {
    return {
      productIds: data ? data.products.map(p => p.id) : [],
    };
  },

  fragment: productFragment,
  // dependency: GigTalentField,
};
