import React, { useMemo, ReactNode, useRef, useLayoutEffect } from "react";
import cx from "classnames";
import { Formik, FormikProps } from "formik";
import { ComposableField, useComposableForm } from "modules/Connection/ComposableForm";
import { useScopeContext } from "modules/Connection/Scope";
import styles from "./styles.scss";

interface Props<F> {
  form: F | F[];
  isBatchForm?: boolean;
  close: () => void;
  renderSubmit: (fp: FormikProps<any>) => ReactNode;
  onSaveStart?: () => void;
  onSaveEnd?: () => void;
  forceScroll?: boolean;
}

export function Form<
  F extends ComposableField<any, any>,
  V extends F extends ComposableField<unknown, infer VX> ? VX : never
>({ form, isBatchForm, close, renderSubmit, onSaveStart, onSaveEnd, forceScroll }: Props<F>) {
  const { ids, connection, variables, pushEdit, formMeta } = useScopeContext();

  const forms = useMemo(() => (Array.isArray(form) ? form : [form]), [form]);

  const { initialValues, validate, onSubmit, render, ready } = useComposableForm({
    forms,
    handleSubmit: async values => {
      if (onSaveStart) onSaveStart();

      try {
        await pushEdit({ time: new Date(), changes: values });
        close();
      } catch (err) {
        console.error(err);
      } finally {
        if (onSaveEnd) onSaveEnd();
      }
    },
    handleClose: close,
    inline: true,
    variables,
    connection,
    limit: ids && ids.length,
    formMeta: { ...formMeta, isBatchForm: isBatchForm ?? false },
  });

  const wrapper = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = wrapper;

    if (current && !forceScroll) {
      const maxHeight = Math.min(window.innerHeight, 750);

      if (current.clientHeight > maxHeight) {
        current.style.overflowY = "auto";
        current.style.maxHeight = maxHeight + "px";
      }
    }
  });

  return (
    <Formik<V & { general?: string }>
      key={initialValues}
      initialValues={initialValues || {}}
      validate={validate}
      onSubmit={onSubmit}
    >
      {fp => (
        <div
          style={forceScroll ? { maxHeight: `${Math.min(window.innerHeight, 750) - 50}px`, overflowY: "scroll" } : {}}
        >
          <div ref={wrapper} className={cx(!ready && styles.loading)}>
            {render(fp)}
          </div>
          {renderSubmit(fp)}
        </div>
      )}
    </Formik>
  );
}
