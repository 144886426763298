import React from "react";
import { Query } from "@apollo/client/react/components";

import { TagGroup, TagFilterItemsQueryVariables, TagFilterItemsQuery } from "gql-gen";

import tagsQuery from "./tags.gql";

import SearchableMultiSelect from "../LegacySearchable/SearchableMultiSelect";

interface Props {
  value: string[];
  onChange: (tags: string[] | null) => void;
  group: TagGroup;
}

function TagFilter({ value, onChange, group }: Props) {
  return (
    <Query<TagFilterItemsQuery, TagFilterItemsQueryVariables> query={tagsQuery} variables={{ group }}>
      {({ data: { tags } = { tags: null } }) => {
        return (
          <SearchableMultiSelect
            name={"TAG"}
            placeholder={"All Tags"}
            selectedItems={value}
            items={tags?.edges.map(t => t.node) ?? null}
            onChange={onChange}
          />
        );
      }}
    </Query>
  );
}

export default TagFilter;
