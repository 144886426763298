import React from "react";
import { FieldProps } from "formik";
import TextBox from "components/Form/TextBox";

export default function FCustomLocationName({
  field,
  form: { setFieldValue },
  testId,
}: FieldProps & { testId?: string }) {
  return (
    <TextBox
      label={"TASK LOCATION NAME"}
      placeholder={"Change the location name for this task only"}
      value={field.value || ""}
      onChange={v => {
        setFieldValue(field.name, v.target.value);
      }}
      testId={testId}
    />
  );
}
