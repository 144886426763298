import React, { useState } from "react";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  children: string;
  lines: number;
}

export default function ExpandableText({ children, lines }: Props) {
  const [expanded, setExpanded] = useState(false);
  const [switchVisible, setSwitchVisible] = useState(false);

  return (
    <>
      <div
        className={styles.text}
        ref={el => {
          if (el && el.offsetHeight < el.scrollHeight && !switchVisible) {
            setSwitchVisible(true);
          }
        }}
        style={{
          WebkitLineClamp: expanded ? "unset" : lines,
          WebkitBoxOrient: "vertical",
          display: "-webkit-box",
        }}
      >
        {children}
      </div>

      <Text.NewLink4
        className={switchVisible ? styles.switchVisible : styles.switch}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? "Show Less" : "Show more"}
      </Text.NewLink4>
    </>
  );
}
