import { flowRight as compose } from "lodash";
import * as React from "react";
import { Route, Switch, Redirect, RouteComponentProps, match } from "react-router-dom";
import _ from "lodash";
import { getUrlQueries, stringifyQuery } from "utilities/routes";
// TODO: Move ../Programs into Organization
import Programs, { programsRouteName } from "./Programs";
import ProductFormModal from "./LegacySettings/ProductFormModal";
import DocumentFormModal from "./LegacySettings/DocumentFormModal";
import InviteFormModal from "./InviteUsers";
import LocationFormModal from "./LegacySettings/LocationFormModal";
import EditUserPermissionsModal from "./LegacySettings/EditUserPermissionsModal";
import GogetterModal from "./LegacySettings/GogetterModal";
import BillingContactFormModal from "./LegacySettings/BillingContactFormModal";
import { ContinueParams, CloseModalHandler } from "utilities/modals";
import { getAppUrl } from "utilities/routes";
import { withUserInfoNoCache } from "../UserInfo";
import { SwitchToUser } from "./SwitchToUserModal";
import { isPinata } from "utilities/flavor";
import Branding from "./Branding";

const Invoices = React.lazy(() => import("./Invoices"));
const Settings = React.lazy(() => import("./LegacySettings"));

const OrganizationLocationsUploader = React.lazy(() => import("../Schema/Locations/Upload"));
const OrganizationProductsUploader = React.lazy(() => import("../Schema/Products/Upload"));

export interface OrganizationRouteParams {
  orgId: string;
}

interface Props extends RouteComponentProps<OrganizationRouteParams> {}

class Organization extends React.PureComponent<Props> {
  public closeModal = (modalMatch: match) => (modalParams: string[] = [], continueParams?: ContinueParams) => {
    const { history, location } = this.props;
    const params = getUrlQueries(location.search);

    if (params.continue) {
      let continueUrl = params.continue;

      if (continueParams) {
        continueUrl = continueUrl.replace(continueParams.placeholder, continueParams.value);
      }

      history.push(continueUrl);
    } else {
      history.push({
        search: stringifyQuery(_.omit(params, modalParams)),
        pathname: modalMatch.url.match(/(.+)\/\+/)?.[1],
      });
    }
  };

  public wrapModal(Component: React.ComponentType<{ handleClose: CloseModalHandler }>) {
    return (props: RouteComponentProps) => <Component {...props} handleClose={this.closeModal(props.match)} />;
  }

  componentDidMount() {
    if ("intercomSettings" in window) {
      (window as any).intercomSettings.orgId = this.props.match.params.orgId;
    }
  }

  public render() {
    const {
      location,
      match: { url, path },
    } = this.props;

    // redirect to gogetter app so gogetters can't access the dashboard.
    if (
      _.get(this.props, ["userInfo", "me", "organizationUser", "type"]) === "gogetter" &&
      !_.get(this.props, ["userInfo", "me", "organizationUser", "isAdmin"])
    ) {
      window.location.href = getAppUrl("go", `${this.props.match.params.orgId}/`);
    }

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${path}/settings`} component={Settings} />
          {isPinata && <Route path={`${path}/branding`} component={Branding} />}
          <Route path={`${path}/invoices`} component={Invoices} />

          <Route path={`${path}/program-group/:programGroupId/${programsRouteName}`} component={Programs} />

          <Redirect
            path={`${path}/${programsRouteName}/:id`}
            to={`${path}/program-group/all/${programsRouteName}/:id`}
          />

          <Redirect to={{ ...location, pathname: `${url}/program-group/all/${programsRouteName}` }} />
        </Switch>

        <Route path={`${path}/:opage*/+add-products`} render={this.wrapModal(ProductFormModal)} />
        <Route path={`${path}/:opage*/+edit-products/:productId`} render={this.wrapModal(ProductFormModal)} />
        <Route path={`${path}/:opage*/+add-documents`} render={this.wrapModal(DocumentFormModal)} />
        <Route path={`${path}/:opage*/+add-billing-contact`} render={this.wrapModal(BillingContactFormModal)} />
        <Route path={`${path}/:opage*/+invite-users`} render={this.wrapModal(InviteFormModal)} />
        <Route path={`${path}/:opage*/+add-location`} render={this.wrapModal(LocationFormModal)} />
        <Route
          path={`${path}/:opage*/+add-locations-like-a-boss`}
          render={this.wrapModal(OrganizationLocationsUploader)}
        />
        <Route
          path={`${path}/:opage*/+add-products-like-a-boss`}
          render={this.wrapModal(OrganizationProductsUploader)}
        />
        <Route
          path={`${path}/:opage*/+edit-user-permissions/:userId`}
          render={this.wrapModal(EditUserPermissionsModal)}
        />

        <Route path={`${path}/:opage*/+edit-location/:locationId`} render={this.wrapModal(LocationFormModal)} />
        <Route path={`${path}/:opage*/+user-profile/:userId`} render={this.wrapModal(GogetterModal)} />
        <Route path={`${path}/:opage*/+switch-user/:userId`} render={this.wrapModal(SwitchToUser)} />
      </React.Fragment>
    );
  }
}

export default compose(withUserInfoNoCache)(Organization);
