import * as React from "react";
import cx from "classnames";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  name: React.ReactNode;
  headerTop: number;
  children: React.ReactNode;
  headerClassName?: string;
  headerNameClassName?: string;
  className?: string;
  sticky?: boolean;
}

export default function ListGroup({
  name,
  children,
  headerClassName,
  headerNameClassName,
  className,
  headerTop = 0,
  sticky = true,
}: Props) {
  return (
    <div className={className}>
      <header
        className={cx(sticky ? styles.stickyHeader : styles.header, headerClassName)}
        style={{ top: headerTop + "px" }}
      >
        <Text.Label1 className={cx(styles.headerName, headerNameClassName)}>{name}</Text.Label1>
      </header>
      {children}
    </div>
  );
}
