import * as React from "react";
import Hammer from "hammerjs";
import cx from "classnames";
import Icon from "components/Icon";
import Clickable from "components/Clickable";
import Text from "components/LegacyText";
import { ColumnInfo } from "./columns";
import variables from "styles/variables";
import arrowIcon from "assets/chevron.svg";
import { IAction, ActionCallback, getActionsProps } from "./actions";
import SelectCell, { RenderCheckbox } from "./SelectCell";
import { CellProps } from "./Cell";
import styles from "./styles.scss";

export type RowChildren = React.ReactElement<CellProps>[];

type RenderFunction = (listMode: boolean) => RowChildren;

export interface RowProps {
  id: string;
  batchActions?: boolean;
  selectMultiple?: boolean;
  label?: string;
  expanded?: boolean;
  columns?: ColumnInfo[];
  visibleColumns?: number;
  children: RowChildren | RenderFunction;
  actions?: IAction[];
  disabled?: boolean;
  header?: boolean;
  selectDisabled?: boolean;
  listMode?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onExpand?: () => void;
  onAction?: ActionCallback;
  selectable?: boolean;
  className?: string;
  renderCheckbox?: RenderCheckbox;
  renderActions?: boolean;
}

interface State {
  isDrawerOpen: boolean;
  touchPull: number;
}

const ICON_SIZE = 20;
const PULL_THRESHOLD = 60;

export default class Row extends React.Component<RowProps, State> {
  readonly state: State = {
    isDrawerOpen: false,
    touchPull: 0,
  };

  private toggleExpanded = () => this.setState({ isDrawerOpen: !this.state.isDrawerOpen, touchPull: 0 });

  private handlePan = (event: { deltaX: number }) => {
    if (!this.props.actions) return;

    this.setState({ touchPull: -event.deltaX });
  };

  private handlePanEnd = () => {
    const { touchPull, isDrawerOpen } = this.state;

    let updates: State = { touchPull: 0, isDrawerOpen };

    if (touchPull > PULL_THRESHOLD) {
      updates = { ...updates, isDrawerOpen: true };
    } else if (touchPull < PULL_THRESHOLD) {
      updates = { ...updates, isDrawerOpen: false };
    }

    this.setState(updates);
  };

  private setHammerEvents = (div: HTMLDivElement | null) => {
    if (div) {
      const hammer = new Hammer.Manager(div);

      hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL }));

      hammer.on("panleft", this.handlePan);
      hammer.on("panright", this.handlePan);
      hammer.on("panend", this.handlePanEnd);
      hammer.on("pancancel", this.handlePanEnd);
    }
  };

  private renderExpander() {
    const { header, onExpand, expanded, renderActions } = this.props;

    if (header) {
      return <div className={styles.expanderWrapperPadding} />;
    }

    return (
      <div className={cx(styles.expanderWrapper, renderActions && styles.expanderBottom)}>
        <Clickable
          onClick={onExpand}
          actionLabel={"Toggle Expand"}
          className={expanded ? styles.expandedIcon : styles.expanderIcon}
        >
          <Icon src={arrowIcon} fill={variables.white} size={8} />
        </Clickable>
      </div>
    );
  }

  private renderActionsDrawer() {
    if (!this.props.actions) return null;

    const { actions, listMode = false, header, onAction, disabled } = this.props;
    const { isDrawerOpen, touchPull } = this.state;

    const drawerActions = actions.filter(action => action.icon);

    const { wrapperSize, drawerSize, expanderVisible, actionSize } = getActionsProps(
      drawerActions,
      listMode,
      isDrawerOpen,
    );

    if (header) {
      return <div style={{ width: wrapperSize }} />;
    }

    const actionViews = drawerActions.map(action => {
      return (
        <button
          aria-label={action.description}
          title={action.description}
          disabled={disabled}
          onClick={() => onAction && onAction(action)}
          key={action.name}
          className={styles.action}
          style={{ backgroundColor: action.color, minWidth: actionSize }}
        >
          {action.icon && <Icon src={action.icon} size={ICON_SIZE} fill={variables.white} />}
          {listMode && (
            <Text.Label2 kind={"reverse"} className={styles.actionLabel}>
              {action.description}
            </Text.Label2>
          )}
        </button>
      );
    });
    return (
      <React.Fragment>
        <div style={{ width: wrapperSize }}>
          <div className={cx(styles.actions, listMode && styles.listMode)}>
            {expanderVisible && (
              <div className={styles.arrowExpander} onClick={this.toggleExpanded}>
                <Icon src={arrowIcon} size={ICON_SIZE - 8} rotate={isDrawerOpen ? -90 : 90} fill={variables.white} />
              </div>
            )}

            <div
              className={cx(styles.actionsDrawer, touchPull !== 0 && styles.dragging)}
              style={{ width: drawerSize + touchPull || 0.01 }}
            >
              {actionViews}
              <div className={styles.drawerOverflow}>
                <span className="emoji" role="img" aria-label="...">
                  &#x1F92B;
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private renderRightSide() {
    const { columns, visibleColumns, renderActions } = this.props;

    if (columns && visibleColumns && visibleColumns < columns.length && !renderActions) {
      return this.renderExpander();
    }

    return this.renderActionsDrawer();
  }

  private renderRowContent() {
    const {
      id,
      header,
      batchActions = true,
      children,
      listMode = false,
      expanded,
      selectable,
      selected,
      onSelect,
      actions,
      selectMultiple,
      className,
      renderCheckbox,
      visibleColumns,
      renderActions,
      selectDisabled,
    } = this.props;

    let cells: RowChildren;

    if (typeof children === "function") {
      cells = children(listMode);
    } else {
      cells = children as RowChildren;
    }

    cells = React.Children.map(cells, cell => cell && React.cloneElement(cell, { listMode }));

    if (visibleColumns) {
      cells = cells.slice(0, visibleColumns);
    }

    return (
      <div
        ref={actions && (!visibleColumns || renderActions) ? this.setHammerEvents : null}
        data-id={id}
        onClick={selectable && listMode ? onSelect : undefined}
        className={cx(
          styles.row,
          listMode && styles.listMode,
          header && styles.header,
          expanded && styles.expandedRow,
          className,
          renderActions && !header && !expanded && styles.expandableRowActions,
        )}
      >
        <SelectCell
          batchActions={batchActions}
          renderCheckbox={renderCheckbox}
          visible={selectable}
          disabled={selectDisabled}
          listMode={listMode}
          selected={selected}
          selectMultiple={selectMultiple}
          header={header}
          onSelect={onSelect}
        />

        <div className={cx(styles.rowCells, listMode && styles.listMode)}>{cells}</div>

        {this.renderRightSide()}
        {renderActions && !expanded && this.renderExpander()}
      </div>
    );
  }

  private renderExpandedContentActions() {
    const { actions, disabled, onAction } = this.props;

    if (!actions) return null;

    const actionViews = actions.map(action => {
      return (
        <Text.NewLink4
          aria-label={action.description}
          aria-disabled={disabled}
          onClick={() => !disabled && onAction && onAction(action)}
          key={action.name}
          className={styles.expandedAction}
        >
          {action.description}
        </Text.NewLink4>
      );
    });

    return <div className={styles.expandedActionsContainer}>{actionViews}</div>;
  }

  private renderExpandedContent() {
    const { columns, children, visibleColumns, renderActions } = this.props;

    if (!columns || !visibleColumns) return null;

    const cells = React.Children.toArray(children as RowChildren);

    const expandedColumns = columns.slice(visibleColumns, columns.length).map((column, index) => {
      const cellChildren = cells[visibleColumns + index];
      return (
        <tr key={index}>
          <td className={styles.labelTd}>
            <Text.Label2 className={styles.colLabel}>{column.label}</Text.Label2>
          </td>
          <td className={styles.contentTd}>
            {typeof cellChildren === "string" ? <Text.P4>{cellChildren}</Text.P4> : cellChildren}
          </td>
        </tr>
      );
    });

    return (
      <div className={cx(styles.rowExpandedContent, renderActions && styles.expandedContentMargin)}>
        <table className={styles.rowExpandedTable}>
          <tbody>{expandedColumns}</tbody>
        </table>
        {this.renderExpandedContentActions()}
        {renderActions && this.renderExpander()}
      </div>
    );
  }

  public render() {
    const { expanded } = this.props;

    let content = this.renderRowContent();

    if (expanded) {
      content = (
        <div className={styles.rowWrapper}>
          {content}
          {this.renderExpandedContent()}
        </div>
      );
    }

    return content;
  }
}
