import * as React from "react";
import moment from "moment";
import cx from "classnames";
import Text from "components/LegacyText";
import Spinner from "components/Spinner";
import styles from "./styles.scss";

export type ProgramFormStatus =
  | "loading"
  | "new"
  | "changed"
  | "saving"
  | "saved"
  | "error"
  | "handingOff"
  | "handOffError"
  | "handedOff"
  | "accepted";

const STATUSES_WITH_TIME: ProgramFormStatus[] = ["saved", "error", "handOffError"];

export interface StatusBarProps {
  status: ProgramFormStatus;
  time: moment.Moment;
  helperMargins?: boolean;
}

export default class StatusBar extends React.Component<StatusBarProps> {
  private intervalId?: number;

  public static defaultProps = {
    helperMargins: true,
  };

  public componentDidMount() {
    this.intervalId = window.setInterval(() => {
      this.forceUpdate();
    }, 60000);
  }

  public componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  public render() {
    const { status, time, helperMargins } = this.props;

    if (status === "saving") {
      return (
        <div className={cx(styles.savingStatusBar, helperMargins && styles.helperMargins)} title={"Saving…"}>
          <Spinner scale={0.2} />
        </div>
      );
    }

    const content = {
      new: "",
      loading: "Loading",
      changed: "Waiting to save",
      saved: "Saved",
      error: `Couldn't save. Please try again!`,
      handingOff: "Sharing",
      handedOff: "Shared",
      handOffError: `Couldn't share. Please try again!`,
      accepted: "Accepted",
    }[status];

    let timeView;
    const timeFromNow = time.fromNow();

    if (STATUSES_WITH_TIME.includes(status) && timeFromNow !== "a few seconds ago") {
      timeView = ` (${timeFromNow})`;
    }

    return (
      <div className={cx(styles.textStatusBar, helperMargins && styles.helperMargins)}>
        <Text.P5
          kind={"secondary"}
          className={status === "error" || status === "handOffError" ? styles.error : ""}
          title={time.format("[updated at] h:mma")}
        >
          {content}
          {timeView}
        </Text.P5>
      </div>
    );
  }
}

export const makeStatus = (status: ProgramFormStatus) => ({
  status,
  time: moment(),
});
