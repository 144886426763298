import React from "react";
import { useTranslation } from "react-i18next";
import { ICell } from "modules/Connection/types";
import { Form } from "modules/Connection/Form";
import { GigFilesField } from "../../Fields/Files";
import { GigsAttachmentsFragment } from "gql-gen";
import Button from "components/Button";
import styles from "./styles.scss";

export const AttachmentsOverviewCell: ICell<GigsAttachmentsFragment> = {
  component: ({ data }) => {
    const isEditable = !data?.finalVerification?.isVerified;
    const { t } = useTranslation();
    return (
      <Form
        form={GigFilesField}
        close={() => null}
        renderSubmit={fp => (
          <Button disabled={!isEditable} kind="primary" onClick={() => fp.handleSubmit()} className={styles.button}>
            {t("gigs.fields.documents.save")}
          </Button>
        )}
      />
    );
  },
  icon: () => <></>,
};
