import React, { ReactNode, createContext, useContext, useMemo, useCallback } from "react";
import { Expression, buildLogicalExpression } from "utilities/knueppel";
import { FilterState, SetFilterState, FilterDefinition } from "./types";
import { useSearchState } from "hooks/useSearchState";
import { omit } from "lodash";

type IFiltersById = Partial<{
  [id: string]: FilterDefinition<any>;
}>;

export interface IFilterCollectionContext {
  filters: IFiltersById;
  state: FilterState;
  buildAST: (excludedFilters?: string[], extraFilters?: Expression[]) => Expression | null;
  ready: boolean;
  count: number;
  clear: (ids?: string[]) => void;
  reset: () => void;
  setFilterState: SetFilterState;
}

export const FilterCollectionCtx = createContext<IFilterCollectionContext | null>(null);

interface Props {
  children: ReactNode;
  filters: IFiltersById;
  defaultState: FilterState;
}

export function FilterCollection({ children, filters, defaultState }: Props) {
  const [state, setState] = useSearchState<FilterState>("f", defaultState, {
    serialize: newState => {
      return Object.entries(newState).reduce((obj, [id, filterState]) => {
        const filter = filters[id];

        return {
          ...obj,
          [id]: filter?.serialize ? filter.serialize(filterState) : filterState,
        };
      }, {});
    },
    parse: rawJson => {
      return Object.entries(rawJson).reduce((obj, [id, filterRaw]) => {
        const filter = filters[id];

        return {
          ...obj,
          [id]: filter?.parse ? filter.parse(filterRaw as {}) : filterRaw,
        };
      }, {});
    },
  });

  const setFilterState = (filterId: string, state: any) => {
    const filter = filters[filterId];

    if (filter?.validate) {
      state = filter.validate(state) ? state : null;
    }

    if (!state) {
      return clear([filterId]);
    }

    setState(filters => ({ ...filters, [filterId]: state }));
  };

  const ready = true;

  const clear = (filterIds?: string[]) => {
    if (filterIds) {
      setState(filters => omit(filters, filterIds));
    } else {
      setState({});
    }
  };

  const count = Object.keys(state).length;

  const buildAST = useCallback(
    (excludedFilters?: string[], extraFilters: Expression[] = []) => {
      return buildLogicalExpression(
        "&&",
        Object.entries(state)
          .map(([id, value]) => {
            if (excludedFilters && excludedFilters.includes(id)) return null;
            const filter = filters[id];
            return filter?.toAST(value) ?? null;
          })
          .concat(extraFilters),
      );
    },
    [filters, state],
  );

  const value = {
    filters,
    state,
    buildAST,
    count,
    clear,
    setFilterState,
    ready,
    reset: () => {
      setState(defaultState);
    },
  };

  return <FilterCollectionCtx.Provider value={value}>{children}</FilterCollectionCtx.Provider>;
}

export function useFilterCollectionCtx(
  excludedFilters?: string[],
  extraExpressions?: Expression[],
): IFilterCollectionContext & { ast: Expression | null } {
  const ctx = useContext(FilterCollectionCtx);
  if (!ctx) {
    throw new Error("Couldn't find a Filter Collection");
  }

  const ast = useMemo(() => {
    return ctx.buildAST(excludedFilters, extraExpressions || []);
  }, [ctx.buildAST, excludedFilters, extraExpressions]);

  return {
    ...ctx,
    ast,
  };
}
