import * as React from "react";
import cx from "classnames";

import { withAccordionContext, AccordionContextProps } from "../AccordionContext";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  accordionState: AccordionContextProps;
  inactive?: boolean;
}

function Line({ children, className, accordionState: { showHelpersColumn }, inactive }: Props) {
  return (
    <div
      className={cx(styles.line, showHelpersColumn ? "" : styles.hideHelpers, className, inactive && styles.inactive)}
    >
      {children}
    </div>
  );
}

export default withAccordionContext(Line);
