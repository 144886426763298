import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export interface CellProps {
  children: React.ReactNode | ((listMode: boolean) => React.ReactNode);
  listMode?: boolean;
  size?: number;
  className?: string;
}

export default function Cell({ children, className, listMode = false, size = 100 }: CellProps) {
  const flex = size / 100;

  return (
    <div className={cx(styles.cell, listMode && styles.listMode, className)} style={{ flex, msFlex: flex }}>
      {typeof children === "function" ? children(listMode) : children}
    </div>
  );
}
