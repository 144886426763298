interface LatLng {
  lat: number;
  lng: number;
}
interface Timezone {
  rawOffset: number;
  dstOffset: number;
  timeZoneId: string;
  timeZoneName: string;
}

const timezoneCache: { [url: string]: Timezone } = {};

export async function getTimeZone({ lat, lng }: LatLng, date: Date): Promise<Timezone> {
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${
    date.getTime() / 1000
  }&key=AIzaSyBfNJaJoX6eIqKDiRgQUOGsyM-iL7kS2Gs`;

  if (url in timezoneCache) {
    return timezoneCache[url];
  }

  return (timezoneCache[url] = await fetch(url).then(r => r.json()));
}
