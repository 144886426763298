import cx from "classnames";
import * as React from "react";
import Text from "components/LegacyText";
import Icon from "components/Icon";
import removeIcon from "assets/cancel-nobg.svg";
import variables from "styles/variables";
import styles from "./styles.scss";

interface Props {
  title?: React.ReactNode;
  children?: React.ReactNode;
  onRemove?: React.MouseEventHandler;
  className?: string;
  itemToggleComponent?: React.ReactNode;
}

export default function Pill({ title, children, className, onRemove, itemToggleComponent }: Props) {
  if (title) {
    console.warn("Pill: The title property is deprecated, use children instead");
  }

  title = title || children;
  return (
    <div className={cx(styles.pill, className, !!itemToggleComponent && styles.fullWidth)}>
      <div className={styles.pillData}>
        <Text.Label2 kind="reverse" className={styles.label}>
          {title}
          {onRemove ? " " : ""}
        </Text.Label2>
        {onRemove && (
          <div onClick={onRemove} className={styles.remove}>
            <Icon src={removeIcon} small fill={variables.white} />
          </div>
        )}
      </div>
      {!!itemToggleComponent && itemToggleComponent}
    </div>
  );
}
