import * as React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default React.forwardRef(({ children, className }: Props, ref: React.Ref<HTMLDivElement>) => (
  <div ref={ref} className={className}>
    {children}
  </div>
));
