import React, { useRef, useState, ReactNode, SetStateAction, Dispatch } from "react";
import { match, P } from "ts-pattern";
import { Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router";
import cx from "classnames";

import { GigsCountQuery, GigsCountQueryVariables, BatchUpdateGigsMutation } from "gql-gen";

import chevronIcon from "assets/chevron.svg";
import commentIcon from "assets/comment-solid.svg";
import downloadIcon from "assets/download.svg";
import infoIcon from "assets/info-circle.svg";
import messageCheckIcon from "assets/message-check.svg";
import messageExclamationIcon from "assets/message-exclamation.svg";
import moneyIcon from "assets/usd-circle-solid.svg";
import personIcon from "assets/user-circle.svg";
import productIcon from "assets/productSquare.svg";
import programIcon from "assets/megaphone.svg";
import reportIcon from "assets/report.svg";
import teamIcon from "assets/team.svg";

import { useComposableForm, ComposeValues, ComposeData } from "modules/Connection/ComposableForm";
import { useReloader } from "modules/Connection/Reloader";
import { ModalRouteParams } from "utilities/modals";
import { omitQueries, getProgramIdFromSearch } from "utilities/routes";
import Message from "components/LegacyText/Message";
import { Modal } from "components/Modal";
import Text, { TextNode } from "components/Text";
import Section from "components/Form/Section";
import { colors } from "styles/variables";
import Icon, { IconSrc } from "components/Icon";
import Button from "components/Button";
import { fromBase64 } from "utilities/base64";

import styles from "./styles.scss";

import gigsCountQuery from "./gigsCount.gql";
import updateGigs from "./updateGigs.gql";

import { ProgramsRouteParams } from "../../../Organization/Programs";
import { useTranslation } from "../../../i18n";
import { gigsConnection } from "../connection";
import { GigProductField } from "../Fields/Product";
import { GigDateField } from "../Fields/Date";
import { GigProgramField } from "../Fields/Program";
import { GigLocationField } from "../Fields/Location";
import { GigRoleField } from "../Fields/Role";
import { GigDescriptionField } from "../Fields/Description";
import { useMobileQuery } from "components/Layout/Responsive";
import { GigAgencyGuidanceField } from "../Fields/AgencyGuidance";
import { GigExpenseNotesField } from "../Fields/ExpenseNotes";
import { GigTitleField } from "../Fields/Title";
import { GigPartnerField } from "../Fields/Partner";
import { GigOwnerField } from "../Fields/Owner";
import { GigTaskManagerField } from "../Fields/TaskManager";
import { GigPartnerRateField } from "../Fields/PartnerRate";
import { GigTalentRateField } from "../Fields/TalentRate";
import { DateCell } from "../Cells/Date";
import { LocationCell } from "../Cells/Location";

const gu = "gigs.update";

const forms = [
  GigTitleField,
  GigDescriptionField,
  GigProgramField,
  GigDateField,
  GigPartnerField,
  GigLocationField,
  GigProductField,
  GigRoleField,
  GigOwnerField,
  GigTaskManagerField,
  GigAgencyGuidanceField,
  GigExpenseNotesField,
  GigPartnerRateField,
  GigTalentRateField,
];

type BookGigData = ComposeData<typeof forms>;
type BookGigValues = ComposeValues<typeof forms> & { general?: string };

type SaveState =
  | { type: "edit" }
  | { type: "review" }
  | { type: "saving" }
  | { type: "saved" }
  | { type: "error"; errorsCsvLink: string; invalidGigsCount: number }
  | { type: "networkError" };

export interface GigFormModalProps
  extends RouteComponentProps<ModalRouteParams & ProgramsRouteParams & { variables: string }> { }

const clearSearch = (search: string) => omitQueries(search, ["from", "date", "values", "newLocId"]);

export function UpdateGigs({
  location: { search },
  history,
  match: {
    params: { orgId, page, programGroupId, variables },
  },
}: GigFormModalProps) {
  const { t } = useTranslation();
  const { filters } = JSON.parse(fromBase64(variables));
  const id = getProgramIdFromSearch(search);
  const gigsCountResult = useQuery<GigsCountQuery, GigsCountQueryVariables>(gigsCountQuery, {
    variables: {
      programIds: id === "all" ? [] : id.split(","),
      filters,
    },
  });

  const [save] = useMutation<BatchUpdateGigsMutation>(updateGigs);

  const reload = useReloader();

  const [saveState, setSaveState] = useState<SaveState>({ type: "edit" });

  const [activeSections, setActiveSections] = useState<string[]>([]);

  const { initialValues, validate, onSubmit, renderField } = useComposableForm<BookGigData, BookGigValues>({
    forms,
    partialBatchUpdate: true,
    enabledFormIds: activeSections,
    defaultValues: {},
    formMeta: {},
    handleClose: () => { },
    handleSubmit: async data => {
      setSaveState({ type: "saving" });

      const result = await save({
        variables: {
          input: data,
          filters,
          programIds: id === "all" ? [] : id.split(","),
        },
      });

      if (result?.data?.batchUpdateGigs) {
        setSaveState({
          type: "error",
          errorsCsvLink: result?.data?.batchUpdateGigs.errorDetailsCsvLink,
          invalidGigsCount: result?.data?.batchUpdateGigs?.invalidCount,
        });
      } else {
        setSaveState({ type: "saved" });
        reload(gigsConnection.name);
      }
    },
  });

  const fixedScroll = useRef<boolean>(false);

  const scrollToTop = (div: HTMLDivElement) => {
    if (div && !fixedScroll.current) {
      setTimeout(() => {
        div.scrollTop = 0;
        fixedScroll.current = true;
      }, 200);
    }
  };

  const isMobile = useMobileQuery();

  const closeModal = () => {
    // TODO #512: Create and use Modal abstraction
    if (orgId && programGroupId && id && page) {
      let updatedSearch = clearSearch(search);

      match(saveState.type)
        .with("edit", "review", "error", "networkError", () =>
          history.push(
            `/${orgId}/program-group/${programGroupId}/programs/${page}${id === "all" ? "" : `?program=${id}`}`,
          ),
        )
        .with("saving", () => {
          /* noop */
        })
        .with("saved", () => {
          setTimeout(() => {
            history.replace({
              search: updatedSearch,
              pathname: `/${orgId}/program-group/${programGroupId}/programs/${page}`,
            });
          });
        })
        .exhaustive();
    }
  };

  const HeaderText = (props: { header: string; subHeader: string }) => (
    <>
      <TextNode size={24} color="white" font="wes" bold inline={false}>
        {props.header}
      </TextNode>
      <TextNode size={18} color="white" top="s" font="lato" inline={false}>
        {props.subHeader}
      </TextNode>
    </>
  );

  return (
    <Modal onClose={closeModal} className={styles.modal}>
      {initialValues && (
        <Formik<BookGigValues> initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
          {form => (
            <>
              <header className={styles.header}>
                <div
                  className={styles.headerBack}
                  style={{
                    visibility: match(saveState.type)
                      .with("review", "error", () => undefined)
                      .otherwise(() => "hidden"),
                  }}
                >
                  <Icon src={chevronIcon} fill="currentColor" rotate={90} size={16} />
                  <Button
                    className={styles.headerBackButton}
                    kind="secondary"
                    onClick={() => setSaveState({ type: "edit" })}
                  >
                    {t(`${gu}.batch.modal.back`)}
                  </Button>
                </div>
                <div className={styles.headerContent}>
                  {!isMobile && gigsCountResult.data && gigsCountResult.data.gigs && (
                    <>
                      <div className={styles.headerContentLeft}>
                        <Icon
                          src={match(saveState.type)
                            .with("saved", () => messageCheckIcon)
                            .otherwise(() => messageExclamationIcon)}
                          size={48}
                          fill={colors.white}
                        />
                      </div>
                      <div className={styles.headerContentRight}>
                        <HeaderText
                          {...match(saveState)
                            .with({ type: "edit" }, () => ({
                              header: `${gigsCountResult.data?.gigs?.pageInfo?.totalCount} Tasks Selected`,
                              subHeader: "Make your batch updates below, and click the NEXT button to preview.",
                            }))

                            .with({ type: P.union("review", "saving", "networkError") }, () => ({
                              header: `${gigsCountResult.data?.gigs?.pageInfo?.totalCount} Gigs Will Be Updated`,
                              subHeader: "Please review before committing the updates.",
                            }))

                            .with({ type: "saved" }, () => ({
                              header:
                                `Success! ` +
                                match(gigsCountResult.data?.gigs?.pageInfo?.totalCount)
                                  .with(1, () => "The Gig Was")
                                  .otherwise(() => "Several Gigs Were") +
                                ` Updated!`,
                              subHeader: "",
                            }))

                            .with({ type: "error" }, ({ invalidGigsCount }) => ({
                              header: invalidGigsCount === 1 ? "There was an error." : "There were errors.",
                              subHeader: `Updates were not applied. See below.`,
                            }))

                            .exhaustive()}
                        />
                      </div>
                    </>
                  )}
                </div>
              </header>
              <div className={styles.mainForm} ref={scrollToTop}>
                {match(saveState)
                  .with({ type: P.union("edit", "review", "saving", "networkError") }, state => {
                    const mode = match(state.type)
                      .with("edit", a => a)
                      .otherwise(() => "review" as const);
                    return (
                      <>
                        <FieldSection
                          inputIsEmpty={form.values.title === ""}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={commentIcon}
                          title={t(`${gu}.fields.title.title`)}
                          id="title"
                        >
                          {renderField(form, "title")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={form.values.notes === ""}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={infoIcon}
                          title={t(`${gu}.fields.description.title`)}
                          id="description"
                        >
                          {renderField(form, "description")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={typeof form.values.programId !== "string"}
                          mode={mode}
                          icon={programIcon}
                          title={t(`${gu}.fields.program.title`)}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          id="program"
                        >
                          <Section className={styles.programSection}>{renderField(form, "program")}</Section>
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with(
                              {
                                date: P.nullish,
                                duration: P.nullish,
                                end: P.nullish,
                                start: P.nullish,
                                windowAssign: P.nullish,
                                windowEnd: P.nullish,
                                windowStart: P.nullish,
                              },
                              () => true,
                            )
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={DateCell.icon}
                          title={t(`${gu}.fields.date.title`)}
                          id="date"
                        >
                          {renderField(form, "date")}
                        </FieldSection>

                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with(
                              {
                                location: P.nullish,
                                orgLocationId: P.nullish,
                                locationName: "",
                              },
                              () => true,
                            )
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={LocationCell.icon}
                          title={t(`${gu}.fields.location.title`)}
                          id="location"
                        >
                          {renderField(form, "location")}
                        </FieldSection>

                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ productIds: [] }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={productIcon}
                          title={t(`${gu}.fields.product.title`)}
                          id="product"
                        >
                          {renderField(form, "product")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ partnerOrganizationId: P.nullish }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={teamIcon}
                          title={t(`${gu}.fields.taskManager.title`)}
                          id="taskManager"
                        >
                          {renderField(form, "taskManager")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={form.values.agencyGuidance === ""}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={programIcon}
                          title={t(`${gu}.fields.agencyGuidance.title`)}
                          id="agencyGuidance"
                        >
                          <div className={styles.pndField}>{renderField(form, "agencyGuidance")}</div>
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ managerId: P.nullish }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={personIcon}
                          title={t(`${gu}.fields.owner.title`)}
                          id="manager"
                        >
                          {renderField(form, "manager")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ roleId: P.nullish }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={reportIcon}
                          title={t(`${gu}.fields.role.title`)}
                          id="role"
                        >
                          {renderField(form, "role")}
                        </FieldSection>
                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ expenseNotes: "" }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={programIcon}
                          title={t(`${gu}.fields.expenseNotes.title`)}
                          id="expenseNotes"
                        >
                          <div className={styles.pndField}>{renderField(form, "expenseNotes")}</div>
                        </FieldSection>

                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ myRate: 0 }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={moneyIcon}
                          title={t(`${gu}.fields.myRate.title`)}
                          id="myRate"
                        >
                          {renderField(form, "myRate")}
                        </FieldSection>

                        <FieldSection
                          inputIsEmpty={match(form.values)
                            .with({ talentRate: 0 }, () => true)
                            .otherwise(() => false)}
                          mode={mode}
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          icon={moneyIcon}
                          title={t(`${gu}.fields.talentBillRate.title`)}
                          id="talentBillRate"
                        >
                          {renderField(form, "talentBillRate")}
                        </FieldSection>

                        {form.isSubmitting && !saveState && (
                          <Message kind="neutral" className={styles.message}>
                            {t("gigs.create.submitting")}
                          </Message>
                        )}

                        {Object.keys(form.errors).length > 0 && form.submitCount > 0 && (
                          <Message kind="error" className={styles.message}>
                            {t("gigs.create.fixAllErrors")}
                          </Message>
                        )}
                      </>
                    );
                  })

                  .with({ type: "saved" }, () => {
                    return (
                      <>
                        <Text size={32}>
                          {match(gigsCountResult.data?.gigs?.pageInfo?.totalCount)
                            .with(undefined, () => "")
                            .with(1, () => "1 Gig was updated successfully.")
                            .otherwise(n => `${n} Gigs were updated successfully.`)}
                        </Text>
                      </>
                    );
                  })

                  .with({ type: "error" }, err => {
                    return (
                      <>
                        <Text bold size={32} bottom="xxxl">
                          {match(err.invalidGigsCount)
                            .with(1, () => "1 Gig has an error.")
                            .otherwise(n => `${n} Gigs have errors.`)}
                        </Text>

                        <div
                          className={styles.headerBack}
                          style={{
                            visibility: match(saveState.type)
                              .with("review", "error", () => undefined)
                              .otherwise(() => "hidden"),
                          }}
                        >
                          <Icon src={chevronIcon} fill="currentColor" rotate={90} size={16} />
                          <Button
                            className={cx(styles.headerBackButton, styles.underline)}
                            kind="secondary"
                            onClick={() => setSaveState({ type: "edit" })}
                          >
                            Go back to make changes
                          </Button>
                        </div>

                        <Text font="lato" size={20} bottom="m">
                          For details:
                        </Text>
                        <Text bold size={24}>
                          Download the CSV for a complete listing.
                        </Text>
                        <Text bold size={24} bottom="xl">
                          The first column will show you the error message for each task.
                        </Text>

                        <Button className={cx(styles.downloadButton)} kind="primary" href={err.errorsCsvLink}>
                          <Icon src={downloadIcon} size={16} fill={colors.white} />
                          <Text bold inline color="white" left="s" transform="uppercase">
                            {t("gigs.update.batch.modal.downloadCsv")}
                          </Text>
                        </Button>
                      </>
                    );
                  })

                  .exhaustive()}
              </div>
              <footer className={styles.footer}>
                {(function renderFooter() {
                  const cancelButton = (
                    <Button
                      kind="secondary"
                      className={cx(styles.button, styles.cancelButton)}
                      onClick={() => closeModal()}
                      data-test={"batch.modal.cancel"}
                    >
                      {t(`${gu}.batch.modal.cancel`)}
                    </Button>
                  );
                  return match(saveState)
                    .with({ type: "edit" }, () => {
                      return (
                        <>
                          <Button
                            kind="primary"
                            className={styles.button}
                            onClick={() => setSaveState({ type: "review" })}
                            disabled={activeSections.length === 0}
                            data-test={"batch.modal.nextPreview"}
                          >
                            {t(`${gu}.batch.modal.nextPreview`)}
                          </Button>
                          {cancelButton}
                        </>
                      );
                    })

                    .with({ type: "review" }, { type: "networkError" }, saveState_ => {
                      return (
                        <>
                          <Button
                            kind="primary"
                            className={styles.button}
                            onClick={() => form.handleSubmit()}
                            data-test={"batch.modal.commitUpdates"}
                          >
                            {t(`${gu}.batch.modal.submit`)}
                          </Button>
                          {cancelButton}
                        </>
                      );
                    })

                    .with({ type: "saving" }, () => {
                      return (
                        <Button disabled kind="primary" className={styles.button}>
                          {t(`${gu}.batch.modal.updating`)}
                        </Button>
                      );
                    })

                    .with({ type: "saved" }, saved => {
                      return (
                        <Button kind="primary" className={styles.button} onClick={() => closeModal()}>
                          {t(`${gu}.batch.modal.close`)}
                        </Button>
                      );
                    })

                    .with({ type: "error" }, () => cancelButton)

                    .exhaustive();
                })()}
              </footer>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
}

function FieldSection(props: {
  children: ReactNode;
  title: string;
  icon: IconSrc;
  activeSections: string[];
  setActiveSections: Dispatch<SetStateAction<string[]>>;
  id: string;
  mode: "edit" | "review";
  inputIsEmpty: boolean;
}) {
  const { t } = useTranslation();
  const isActive = props.activeSections.includes(props.id);
  return props.mode === "review" && !isActive ? (
    <></>
  ) : (
    <section className={cx(styles.fieldSection, isActive && styles.fieldSectionActive)}>
      <Icon src={props.icon} size={20} fill={colors.gray1} />

      <div className={styles.fieldSectionContent}>
        <Text font="wes" bold size={18} bottom={"xs"} className={styles.fieldSectionTitle}>
          {props.title}
        </Text>
        {props.mode === "review" && props.inputIsEmpty && (
          <Text font="lato" size={14} bottom={"xs"} className={cx(styles.fieldSectionWarningText)}>
            If you don't provide a value, this field will be updated to empty for all tasks.
          </Text>
        )}
        {props.children}
      </div>
      {props.mode === "edit" && (
        <Button
          kind={isActive ? "redGradient" : "blueTranslucent"}
          className={styles.button}
          onClick={() => {
            props.setActiveSections(
              isActive ? props.activeSections.filter(s => s !== props.id) : [...props.activeSections, props.id],
            );
          }}
          data-test={`${props.id}.${isActive ? "leaveUnchanged" : "update"}`}
        >
          {t(`${gu}.${isActive ? "leaveUnchanged" : "update"}`)}
        </Button>
      )}
    </section>
  );
}
