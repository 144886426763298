import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import approveIcon from "assets/check.svg";
import { colors } from "styles/variables";
import Section from "components/Form/Section";
import TextBox from "components/Form/TextBox";
import { inputProps } from "utilities/formik";
import { getProgramIdFromSearch } from "utilities/routes";
import { ActionBody } from "modules/Connection/ActionBody";
import requestApproval from "./requestApproval.gql";
import { useMutation } from "@apollo/client";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";

const i18n = "gigs.actions.requestApproval";

export const RequestApproval: IAction = {
  key: "approvalRequest",
  icon: approveIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, done, renderSubmit }) => {
    const { t } = useTranslation();
    const [mutate] = useMutation(requestApproval);
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Formik
          initialValues={{ message: "" }}
          onSubmit={async ({ message }, { setStatus }) => {
            try {
              await mutate({
                variables: {
                  ...variables,
                  programIds: id === "all" ? [] : id.split(","),
                  message,
                },
              });

              done();
            } catch (e) {
              setStatus({ error: true });
            }
          }}
        >
          {formik => {
            const input = inputProps(formik);

            return (
              <form onSubmit={formik.handleSubmit}>
                <Section>
                  <TextBox
                    {...input("message")}
                    label={t(`${i18n}.message`)}
                    placeholder={t(`${i18n}.writeHere`)}
                    multiline
                    rows={4}
                  />
                </Section>

                {renderSubmit({
                  ...formik,
                  i18n,
                })}
              </form>
            );
          }}
        </Formik>
      </ActionBody>
    );
  },
};
