import { Query } from "@apollo/client/react/components";
import * as React from "react";
import { DocumentNode } from "graphql";
import { withRouter, RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { ControlledPagination } from "interfaces/Pagination";
import { ConnectionResult } from "utilities/connections";
import { Ordering } from "gql-gen";

export interface CollectionState {
  filters: any;
  page: number;
  order: Ordering | null;
  parsedPage: number;
  submittedFilters: {
    [filterName: string]: any;
  } | null;
}

export interface CollectionContext<T> {
  filters: any;
  loading: boolean;
  pageItems: T[] | null;
  onFilterChange: (filterName: string) => (val: any, cb?: () => void) => void;
  submitFilters: () => void;
  totalCount: number | null;
  pagination: ControlledPagination;
  order: Ordering | null;
  onOrderChanged: (order: Ordering) => void;
  resetFilters: () => void;
  refetch: () => Promise<any>;
}

interface ArrayFilter {
  type: "array";
  default: string[] | null;
}

interface StringFilter {
  type: "string";
  default: string | null;
}

interface NumberFilter {
  type: "number";
  default: number | null;
}

interface DateFilter {
  type: "date";
  default: Date | null;
}

export type FilterConfig = ArrayFilter | NumberFilter | StringFilter | NumberFilter | DateFilter;

export interface CollectionConfig {
  query: DocumentNode;
  name: string;
  filters: {
    [filterName: string]: FilterConfig;
  };
  itemsPerPage?: number;
  defaultOrder?: Ordering;
  filterAutomatically?: boolean;
}

interface Variables {
  [varName: string]: any;
}
export interface CollectionWrapperProps extends CollectionConfig, RouteComponentProps {
  variables?: Variables | null;
  skip?: boolean;
  children: (param0: CollectionContext<any>) => JSX.Element;
}

class _CollectionWrapper extends React.Component<CollectionWrapperProps, CollectionState> {
  readonly state: CollectionState = {
    filters: null,
    page: 0,
    parsedPage: 0,
    submittedFilters: null,
    order: null,
  };

  public static getDerivedStateFromProps(
    { filters: filterConfig, defaultOrder }: CollectionWrapperProps,
    state: CollectionState,
  ) {
    let newState = null;

    if (filterConfig && (!state || !state.filters)) {
      newState = {
        ...(newState || {}),
        filters: _.mapValues(filterConfig, "default"),
        submittedFilters: _.mapValues(filterConfig, "default"),
      };
    }

    if (defaultOrder && (!state || !state.order)) {
      newState = {
        ...(newState || {}),
        order: defaultOrder,
      };
    }

    return newState;
  }

  public handleSubmitFilters = () => {
    const { filters } = this.state;

    if (filters) {
      this.setState({ submittedFilters: filters, page: 0 });
    }
  };

  private debouncedSubmitFilters = _.debounce(this.handleSubmitFilters, 300);

  private handleOrderChanged = (order: Ordering) => {
    this.setState({ order });
    this.handlePageChanged(0);
  };

  public handleFilterChange = (name: string) => (val: any, cb?: () => void) => {
    const eventValue = _.get(val, "target.value");

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: eventValue === undefined ? val : eventValue,
        },
      },
      () => {
        if (cb) cb();

        if (this.props.filterAutomatically) {
          this.debouncedSubmitFilters();
        }
      },
    );
  };

  public handlePageChanged = (page: number) => {
    this.setState({ page });
  };

  public getItemsPerPage() {
    return this.props.itemsPerPage || 10;
  }

  public resetFiters = () => {
    this.setState({ filters: null });
  };

  public render() {
    const { filters, submittedFilters, page, order } = this.state;
    const { children, query, name, variables, skip } = this.props;

    const itemsPerPage = this.getItemsPerPage();

    return (
      <Query<{ [k: string]: ConnectionResult<{}> }>
        query={query}
        variables={{
          first: itemsPerPage,
          offset: itemsPerPage * page,
          orderBy: [order],
          ...submittedFilters,
          ...variables,
        }}
        fetchPolicy={"cache-and-network"}
        skip={!submittedFilters || skip}
      >
        {({ loading, data = {}, refetch }) => {
          const connection: ConnectionResult<{}> = data[name];

          return children({
            filters,
            loading,
            pageItems: connection ? connection.edges.map(e => e.node) : null,
            totalCount: connection ? connection.pageInfo.totalCount : null,
            pagination: {
              currentPage: page,
              pageCount: connection ? Math.ceil(connection.pageInfo.totalCount / itemsPerPage) : 0,
              onChange: this.handlePageChanged,
            },
            order,
            onFilterChange: this.handleFilterChange,
            submitFilters: this.handleSubmitFilters,
            onOrderChanged: this.handleOrderChanged,
            resetFilters: this.resetFiters,
            refetch: refetch,
          });
        }}
      </Query>
    );
  }
}

export const CollectionWrapper = withRouter(_CollectionWrapper);
export interface WithCollectionConfig<P> extends CollectionConfig {
  mapPropsToVariables?: (props: P) => Variables;
  skip?: (props: P) => boolean;
}

export function withCollection<P>({
  mapPropsToVariables,
  query,
  name,
  filters,
  itemsPerPage,
  defaultOrder,
  filterAutomatically,
  skip,
}: WithCollectionConfig<P>) {
  return (Component: React.ComponentType<P>) => {
    return (props: P) => (
      <CollectionWrapper
        variables={mapPropsToVariables && mapPropsToVariables(props)}
        query={query}
        name={name}
        filters={filters}
        itemsPerPage={itemsPerPage}
        skip={skip ? skip(props) : false}
        defaultOrder={defaultOrder}
        filterAutomatically={filterAutomatically}
      >
        {collectionContext => {
          return <Component {...props} collection={collectionContext} />;
        }}
      </CollectionWrapper>
    );
  };
}
