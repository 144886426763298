import { getEnvironment } from "utilities/environment";

export type Endpoint = "nacho";

export default function getServerRootUrl() {
  return buildServerRootUrl(ServerApp.ApiNacho);
}

export function getNewServerRootUrl() {
  return buildServerRootUrl(ServerApp.Api);
}

export function getHaskellBackendUrl() {
  return buildServerRootUrl(ServerApp.Backend);
}

export function getRustBackendUrl() {
  return buildServerRootUrl(ServerApp.Rustend);
}

enum ServerApp {
  ApiNacho,
  Api,
  Backend,
  Rustend,
}

function appToUrl(app: ServerApp): { localUrl: string; appName: string } {
  switch (app) {
    case ServerApp.ApiNacho:
      return { localUrl: "http://localhost:10090", appName: "apinacho" };
    case ServerApp.Api:
      return { localUrl: "http://localhost:10001", appName: "api" };
    case ServerApp.Backend:
      return { localUrl: "http://localhost:10009", appName: "backend" };
    case ServerApp.Rustend:
      return { localUrl: "http://localhost:10010", appName: "rustend" };
    default:
      throw new Error(`No app named ${app} found`);
  }
}

export function buildServerRootUrl(serverApp: ServerApp) {
  const envName = getEnvironment();
  const { localUrl, appName } = appToUrl(serverApp);

  if (envName === "development") {
    return process.env.LOCAL_API_URL || localUrl;
  }

  return `https://${window.location.host.replace(/^([^-.]+)/, appName)}`;
}
