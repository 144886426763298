@import "~styles/variables";
@import "../../Table/ColumnHeader/styles";

$actions-width: 40px;
$rows-margin: $xs;

.tableHeader {
  display: flex;
  background-color: $gray-3;
  border-radius: $border-radius;
  top: 0;
  z-index: 3;
  max-width: $desktop + 200;
  margin: 0 auto;

  > :nth-child(2) {
    border-left: 1px solid white;
  }
}

.filterBar {
  background-image: $bar-gradient-up-down;
}

.selectAll {
  background-image: $bar-gradient-up-down;
  width: 40px;
  display: flex;
  justify-content: center;
  // borderRight: "1px solid white",
}

.compactHeader {
  flex-direction: column;
  height: 100%;
  flex: 1;
  margin: 15px;
  border-radius: 0;
  padding: 0;
  overflow-y: scroll;

  > :nth-child(2) {
    border: none;
  }

  > :first-child > :first-child {
    margin: unset;
    padding: unset;
  }
}

.clearFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  @include transitionify(background-color);

  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;

  box-sizing: border-box;
  width: $actions-width + $rows-margin;
  margin-right: -$rows-margin;
}

.clearFiltersActive {
  composes: clearFilters;
  background-color: $teal-1;

  &:hover,
  &:focus {
    background-color: $pink-1;
  }
}

.tableHeaderShadow {
  margin-top: -2px;
  height: 0;
  border-bottom: 8px solid $gray-1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(180deg);
}

.fullScreenFilters {
  height: 700px;
  width: 490px;
  background-color: $gray-1;
  display: flex;
  flex-direction: column;

  @include mobile {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 15px;
  margin-top: 20px;
}

.footer {
  margin-top: 20px;
  display: flex;
  flex: 0.25;
  background-color: $gray-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .actions {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    text-align: center;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      height: 60px;
    }

    > :first-child {
      background: $gray-3;
    }

    > :last-child {
      flex: 1;
      margin-left: 20px;
    }
  }
}

.portalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
