import * as React from "react";
import Checkbox from "components/Form/Checkbox";
import RadioButton from "components/RadioButton";
import Cell from "./Cell";
import styles from "./styles.scss";
export type RenderCheckbox = (param0: { selected?: boolean; onSelect?: (param0: {}) => void }) => React.ReactNode;

interface Props {
  batchActions?: boolean;
  selected?: boolean;
  listMode?: boolean;
  visible?: boolean;
  disabled?: boolean;
  selectMultiple?: boolean;
  header?: boolean;
  onSelect?: () => void;
  renderCheckbox?: RenderCheckbox;
}

export default function SelectCell({
  batchActions,
  selected,
  selectMultiple,
  header,
  listMode,
  onSelect,
  visible,
  disabled,
  renderCheckbox,
}: Props) {
  let content;

  if (renderCheckbox) {
    content = renderCheckbox({ selected, onSelect });
  } else if (selectMultiple) {
    content = <Checkbox disabled={disabled} checked={selected} onCheck={onSelect} />;
  } else {
    content = <RadioButton disabled={disabled} selected={selected} onChange={onSelect} />;
  }

  return (
    <Cell
      className={batchActions ? (visible ? styles.visibleSelectCell : styles.selectCell) : styles.hideCell}
      listMode={listMode}
    >
      {!header || selectMultiple ? content : null}
    </Cell>
  );
}
