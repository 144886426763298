import * as React from "react";
import OriginalResponsive, { useMediaQuery } from "react-responsive";
import variables from "styles/variables";

let Responsive: any = OriginalResponsive;

type MatchesFn = (matches: boolean) => React.ReactNode;

if (process.env.NODE_ENV === "test") {
  Responsive = ({ children }: { children: React.ReactNode | MatchesFn }) => {
    if (typeof children === "function") {
      return children(true);
    }
    return children;
  };
}

export interface Props {
  children: React.ReactNode | MatchesFn;
}

const { tablet, desktop, tinyMobile } = variables;

const max = (px: string) => Number(px ? px.substring(0, px.length - 2) : 0) - 1;

export const Desktop = ({ children }: Props) => <Responsive minWidth={desktop} children={children} />;

export const Tablet = ({ children }: Props) => (
  <Responsive minWidth={tablet} maxWidth={max(desktop)} children={children} />
);

// I'm not sure this is a great idea
export const TinyMobile = ({ children }: Props) => <Responsive maxWidth={max(tinyMobile)} children={children} />;

export const Mobile = ({ children }: Props) => <Responsive maxWidth={max(tablet)} children={children} />;

export const DesktopAndTablet = ({ children }: Props) => <Responsive children={children} minWidth={tablet} />;

export const TabletAndMobile = ({ children }: Props) => <Responsive children={children} maxWidth={max(desktop)} />;

export function useMobileQuery() {
  return useMediaQuery({ query: `(max-width: ${tablet})` });
}

export function useTabletQuery() {
  return useMediaQuery({ query: `(max-width: ${desktop})` });
}
