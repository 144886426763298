const pinata: {
  googleMapsLoaded: boolean;
  addEventListener: (n: "googleMapsLoaded", l: () => void) => void;
  removeEventListener: (n: "googleMapsLoaded", l: () => void) => void;
} = (window as any).pinata;

export function getGoogle(): Promise<any> {
  return new Promise(resolve => {
    if (pinata.googleMapsLoaded) {
      resolve(google);
    }

    const listener = () => {
      resolve(google);
      pinata.removeEventListener("googleMapsLoaded", listener);
    };

    pinata.addEventListener("googleMapsLoaded", listener);
  });
}
