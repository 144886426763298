import React, { useState, useEffect, useMemo } from "react";
import { Tooltip } from "react-tippy";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { debounce, omit } from "lodash";
import { useMutation } from "@apollo/client";
import {
  GigExpenseInput,
  Expense,
  ClientTalentRateUpdateMutation,
  ClientTalentRateUpdateMutationVariables,
  ClientTotalHoursUpdateMutation,
  ClientTotalHoursUpdateMutationVariables,
  GigReportExpensesQuery,
  UpdateGigExpenseMemoMutation,
  UpdateGigExpenseMemoMutationVariables,
  ClientUpdateGigExpensesMutationVariables,
  ClientUpdateGigExpensesMutation,
} from "gql-gen";
import DecimalBox from "components/Form/DecimalBox";

import { useArrayState } from "hooks/useArrayState";
import UserAvatar from "components/UserAvatar";
import RoundedPill from "components/RoundedPill";
import Icon from "components/Icon";
import Money from "components/Money";
import Text from "components/Text";
import Button from "components/Button";
import ConfirmBox from "components/ConfirmBox";

import arrowIcon from "assets/plus-circle.svg";
import thumbIcon from "assets/thumbs-up.svg";
import clientUpdateGigExpenses from "./ClientUpdateGigExpenses.gql";
import clientTalentRateUpdate from "./ClientTalentRateUpdate.gql";
import clientTotalHoursUpdate from "./ClientTotalHoursUpdate.gql";
import ClientExpenseCard from "./ClientExpenseCard";
import billingExpenses from "modules/Connection/DetailsModal/BillingExpenses.gql";
import updateGigs from "./updateGigs.gql";

import styles from "./styles.scss";
import { useScopeContext } from "modules/Connection/Scope";
import { UserLink } from "modules/Dashboard/UserLink";
import deleteIcon from "assets/delete.svg";
import TextBox from "components/Form/TextBox";
import { useDebouncedState } from "hooks/useDebouncedState";
import { idIn } from "utilities/knueppel";
import { getProgramIdFromSearch } from "utilities/routes";
import useRouter from "use-react-router";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { isUserAtLeast, UserType } from "interfaces/user";

type Props = {
  gig: GigReportExpensesQuery["gig"];
};

export default function AgencyBillingView(props: Props) {
  const { gig } = props;
  const { t } = useTranslation();
  const { reload } = useScopeContext();
  const {
    location: { search },
  } = useRouter();
  const id = getProgramIdFromSearch(search);

  const [talentRate, setTalentRate] = useState(0);
  const [myRate, setMyRate] = useState(0);
  const [talentExpenseApproved, setTalentExpenseApproved] = useState(false);
  const [totalHours, setTotalHours] = useState(0);

  const [expenses, setExpenses] = useState<GigExpenseInput[]>([]);
  const { push, removeIndex, setIndex } = useArrayState(expenses, setExpenses);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);

  const [expenseMemo, debouncedExpenseMemo, setExpenseMemo] = useDebouncedState(gig.expenseMemo, 1000);

  const { me } = useUserInfo();
  const canSeeExpenseMemo = me
    ? isUserAtLeast({ userType: me?.userType as UserType, organizationUser: me?.organizationUser }, "manager")
    : false;

  useEffect(() => {
    if (gig) {
      setExpenses(
        gig.expenses.map(
          ({
            chargeAmount,
            reimburseAmount,
            byAgency,
            expense: { id, __typename, modifiedByManager, ...expense },
          }) => ({
            expenseId: id,
            chargeAmount,
            reimburseAmount,
            byAgency,
            expense,
          }),
        ),
      );
      setTalentRate(gig.talentRate || 0);
      setMyRate(gig.myRate || 0);
      setTotalHours(gig.totalHours || 0);
      reload();
    }
  }, [gig]);

  const [mutateGig, { loading: updatingGigMemo }] = useMutation<
    UpdateGigExpenseMemoMutation,
    UpdateGigExpenseMemoMutationVariables
  >(updateGigs);

  const [mutate] = useMutation<ClientUpdateGigExpensesMutation, ClientUpdateGigExpensesMutationVariables>(
    clientUpdateGigExpenses,
    {
      refetchQueries: [{ query: billingExpenses, variables: { id: gig.id } }],
    },
  );

  const [mutateTalentRate] = useMutation<ClientTalentRateUpdateMutation, ClientTalentRateUpdateMutationVariables>(
    clientTalentRateUpdate,
    { refetchQueries: [{ query: billingExpenses, variables: { id: gig.id } }] },
  );

  const [mutateTotalHours] = useMutation<ClientTotalHoursUpdateMutation, ClientTotalHoursUpdateMutationVariables>(
    clientTotalHoursUpdate,
    { refetchQueries: [{ query: billingExpenses, variables: { id: gig.id } }] },
  );

  useEffect(() => {
    if (!updatingGigMemo) {
      mutateGig({
        variables: {
          input: { expenseMemo: debouncedExpenseMemo },
          filters: idIn([gig.id]),
          programIds: id === "all" ? [] : id.split(","),
        },
      });
    }
  }, [debouncedExpenseMemo]);

  const getRateInput = (kind: "myRate" | "talent", rate: number) => {
    switch (kind) {
      case "myRate":
        return { myRate: rate };
      case "talent":
        return { talentRate: rate };
    }
  };

  const debouncedRateUpdate = useMemo(
    () =>
      debounce((kind: "myRate" | "talent", newRate: number) => {
        mutateTalentRate({
          variables: {
            input: getRateInput(kind, newRate),
            filters: {
              type: "BinaryExpression",
              operator: "in",
              left: {
                type: "Identifier",
                name: "id",
              },
              right: {
                type: "ListLiteral",
                values: [gig.id],
              },
            },
          },
        });
      }, 1000),
    [mutateTalentRate],
  );

  const debouncedTotalHoursUpdate = useMemo(
    () =>
      debounce((newTotalHours: number) => {
        mutateTotalHours({
          variables: {
            input: { overriddenDuration: newTotalHours },
            filters: {
              type: "BinaryExpression",
              operator: "in",
              left: {
                type: "Identifier",
                name: "id",
              },
              right: {
                type: "ListLiteral",
                values: [gig.id],
              },
            },
          },
        });
      }, 1000),
    [mutateTotalHours],
  );

  const debouncedSave = useMemo(
    () =>
      debounce(
        (input: GigExpenseInput[]) => {
          mutate({
            variables: {
              gigId: gig.id,
              input: input.map(e => {
                return { ...e, expense: omit(e.expense, "createdAt") };
              }),
            },
          });
        },
        500,
        { trailing: true },
      ),
    [mutate],
  );

  // TODO: Move this to backend and add to each gig?

  const locked = gig?.finalVerification?.isVerified;

  const getFileName = (expense: { createdAt: string }) => {
    const location = gig?.location?.name || "";
    let talentName = "";
    if (gig?.talent?.firstName) {
      talentName = gig.talent.firstName;
      if (gig?.talent?.lastName) {
        talentName = `${gig.talent.firstName} ${gig.talent.lastName}`;
      }
    } else if (gig?.talent?.lastName) {
      talentName = gig.talent.lastName;
    }
    const date = expense.createdAt ? format(new Date(expense.createdAt), "yyyy MM dd") : "";
    const fileName = `Expense ${date} ${location} ${talentName}`.split(" ").join("_");
    return fileName;
  };
  return (
    <div className={styles.billing}>
      <div className={styles.talentAndExpenses}>
        <div className={styles.sectionTitle}>
          <Text color={"gray1"} size={18} font={"wes"}>
            {t("components.detailsModal.billRate")}
          </Text>
        </div>

        <div className={styles.talentContainer}>
          {gig.talent && (
            <UserLink id={gig.talent?.id} className={styles.talentLink}>
              <UserAvatar square user={gig.talent} className={styles.userPicture} />
            </UserLink>
          )}
          <div className={styles.rateAction}>
            <div className={styles.rateContainer}>
              <div className={styles.rates}>
                <Text color={"gray1"} size={10} font={"wes"} transform="uppercase" letterSpacing={0.98}>
                  {t("components.detailsModal.expensePayment.clientHourlyRate")}
                </Text>
                <Text
                  color={"gray1"}
                  size={14}
                  font={"lato"}
                  transform="uppercase"
                  letterSpacing={0.98}
                  testId="billing.clientHourlyRate"
                >
                  {gig?.program?.executionType === "pro" ? (
                    <DecimalBox
                      value={myRate}
                      onChange={(newRate: number) => {
                        setMyRate(newRate);
                        debouncedRateUpdate("myRate", newRate);
                      }}
                      className={styles.basicMoney}
                      testId="billing.talentOrganizationRate"
                      disabled={locked}
                    />
                  ) : (
                    <Money value={gig.myRate ?? gig.talentOrganizationRate ?? 0} />
                  )}
                </Text>
              </div>
              <div className={cx(styles.rates, styles.editableTalentRate)}>
                <Text color={"gray1"} size={10} font={"wes"} transform="uppercase" letterSpacing={0.98}>
                  {t("components.detailsModal.expensePayment.talentHourlyRate")}
                </Text>
                <Text color={"gray1"} size={14} font={"lato"} transform="uppercase" letterSpacing={0.98}>
                  <DecimalBox
                    value={talentRate}
                    onChange={(newTalentRate: number) => {
                      setTalentRate(newTalentRate);
                      debouncedRateUpdate("talent", newTalentRate);
                    }}
                    className={styles.basicMoney}
                    testId="billing.talentHourlyRate"
                    disabled={locked}
                  />
                </Text>
              </div>
              <div className={styles.rates}>
                <Text color={"gray1"} size={10} font={"wes"} transform="uppercase" letterSpacing={0.98}>
                  {t("components.detailsModal.expensePayment.hours")}
                </Text>
                <input
                  className={styles.totalHours}
                  type="number"
                  value={totalHours}
                  onChange={e => {
                    const totalHours = parseFloat(e.target.value);
                    setTotalHours(totalHours);
                    debouncedTotalHoursUpdate(totalHours);
                  }}
                  disabled={locked}
                ></input>
              </div>
            </div>
            <div className={styles.actionContainer}>
              <Button
                kind={talentExpenseApproved ? "greenGradient" : "primaryGradient"}
                className={styles.authorizeBtn}
                onClick={e => {
                  e.stopPropagation();
                  setTalentExpenseApproved(!talentExpenseApproved);
                }}
                disabled={locked}
              >
                <Icon src={thumbIcon} fill={"white"} size={20} />
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.gigTotalToBeCharged}>
          <Text className={styles.sumTotals}>
            {t("components.detailsModal.totalChargedToClient")}
            <div data-test="billing.hoursChargedToClient">
              <Money value={(gig.myRate ?? gig.talentOrganizationRate ?? 0) * gig.totalHours} />
            </div>
          </Text>
          <Text className={styles.sumTotals}>
            {t("components.detailsModal.totalPaidToTalent")}
            <div data-test="billing.hoursPayedToTalent">
              <Money value={(gig.talentRate || 0) * gig.totalHours} />
            </div>
          </Text>
        </div>

        <div className={styles.expensesSectionTitle}>
          <Text color={"gray1"} size={18} font={"wes"}>
            {t("components.detailsModal.expenses")}
          </Text>
          <Tooltip
            title={"Review expense policy"}
            position={"bottom"}
            trigger="click"
            html={
              <div className={styles.tooltip}>
                <Text size={14} color={"white"}>
                  {gig?.expenseNotes}
                </Text>
              </div>
            }
          >
            <Text size={14} color={"blue2"}>
              {t("components.detailsModal.reviewExpensePolicy")}
            </Text>
          </Tooltip>
        </div>
        {expenses.map((expense, index) => {
          const expenseId = expense.expenseId;
          const fileName = getFileName(expense.expense as Expense);

          return (
            <div key={expenseId || index}>
              <ClientExpenseCard
                value={expense}
                onChange={({ reimburseAmount, chargeAmount, expense, byAgency }) => {
                  debouncedSave(setIndex(index, { expenseId, reimburseAmount, chargeAmount, expense, byAgency }));
                }}
                onRemove={() => setIndexToDelete(index)}
                requiredReceipt
                disabled={locked}
                fileName={fileName}
              />
            </div>
          );
        })}
        <div className={styles.expenseTotals}>
          {locked ? null : (
            <RoundedPill
              className={cx(styles.addExpense, locked && styles.disabled)}
              onClick={() => {
                if (locked) {
                  return;
                }
                debouncedSave(
                  push({
                    chargeAmount: 0,
                    reimburseAmount: 0,
                    byAgency: true,
                    expense: {
                      amount: 0.0,
                      images: [],
                      memo: "",
                      personal: false,
                      classKey: "other",
                    },
                  }),
                );
              }}
              childrenClassName={styles.addExpenseItems}
            >
              <Icon src={arrowIcon} size={14} fill={"white"} />
              <Text color="white" font="wes" size={12} inline>
                Add another expense
              </Text>
            </RoundedPill>
          )}
          <div className={styles.gigTotalToBeCharged}>
            <Text className={styles.sumTotals} style={{ marginBottom: 10 }}>
              Total to be charged to client
              <div data-test="billing.expensesChargedToClientTotal">
                <Money
                  value={expenses.reduce((acc, expense) => {
                    return acc + (expense.chargeAmount || 0);
                  }, 0)}
                />
              </div>
            </Text>
            <Text className={styles.sumTotals}>
              Total to be paid to talent
              <div data-test="billing.expensesPaidToTalentTotal">
                <Money
                  value={expenses.reduce((acc, expense) => {
                    return acc + (expense.reimburseAmount || 0);
                  }, 0)}
                />
              </div>
            </Text>
          </div>
        </div>
        {canSeeExpenseMemo ? (
          <div className={styles.expenseMemoContainer}>
            <Text color={"gray1"} size={18} font={"wes"}>
              {t("components.detailsModal.expenseMemo")}
            </Text>
            <TextBox
              disabled={gig?.workflowStateGroup === "invoiced"}
              className={styles.expenseMemo}
              label={t("components.detailsModal.expenseMemoLabel")}
              testId={"components.detailsModal.expenseMemo"}
              placeholder={t("components.detailsModal.expenseMemoPlaceholder")}
              multiline
              rows={5}
              value={expenseMemo || ""}
              onChange={e => setExpenseMemo(e.target.value)}
            />
          </div>
        ) : null}
      </div>
      {gig?.organization?.invoicingActive ? null : (
        <div className={cx(styles.invoiceAndMessages, !gig?.organization?.invoicingActive && styles.noInvoicing)}>
          <>
            <Text bold size={14}>
              Want to send invoices directly on PINATA?
            </Text>
            <Text size={14}>
              Learn how PINATA's invoicing features can let you streamline your tasks from creation through billing -
              all in one place.
            </Text>
            <Text size={14}>
              Click{" "}
              <a target="_blank" href="https://help.gopinata.com/en/articles/4131651" rel="noopener noreferrer">
                HERE
              </a>{" "}
              to read more about it, or contact your PINATA Account Executive to add it to your subscription!
            </Text>
          </>
        </div>
      )}
      {indexToDelete !== null && (
        <ConfirmBox
          icon={deleteIcon}
          title="Delete this expense?"
          onYes={() => {
            debouncedSave(removeIndex(indexToDelete));
            setIndexToDelete(null);
          }}
          onNo={() => setIndexToDelete(null)}
        >
          <Text size={14} color="white">
            If you delete this expense, all added information will be lost.
          </Text>
        </ConfirmBox>
      )}
    </div>
  );
}
