import * as React from "react";
import cx from "classnames";
import variables from "styles/variables";
import styles from "./styles.scss";
export type RadioValue = string;

export interface RadioButtonProps {
  value?: RadioValue;
  reverse?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onChange?: (val: RadioValue | null) => void;
  className?: string;
  textClassName?: string;
  children?: React.ReactNode;
  testId?: string;
}

export default function RadioButton({
  children,
  selected,
  reverse,
  disabled,
  onChange,
  className,
  textClassName,
  value,
  testId,
}: RadioButtonProps) {
  const color = reverse ? variables.white : variables.gray1;

  const handleOnChange = () => !disabled && onChange && onChange(value || null);
  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      handleOnChange();
    }
  };

  return (
    <div
      role={"radio"}
      aria-checked={selected}
      aria-disabled={disabled}
      tabIndex={0}
      className={cx(styles.radioButton, className)}
      onKeyUp={handleKeyUp}
      onClick={handleOnChange}
      data-test={testId}
    >
      <div className={styles.radio}>
        <svg height={18} width={18}>
          <circle cx={"50%"} cy={"50%"} r={"44.44%"} stroke={color} strokeWidth={1.5} fill={"none"} />
          <circle
            className={styles.innerCircle}
            transform={selected ? "scale(1)" : "scale(0)"}
            cx={"50%"}
            cy={"50%"}
            r={"30%"}
            fill={color}
          />
        </svg>
      </div>

      {children && <span className={cx(reverse ? styles.reverseText : styles.text, textClassName)}>{children}</span>}
    </div>
  );
}
