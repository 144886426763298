import * as React from "react";
import _, { debounce } from "lodash";
import cx from "classnames";
import Geosuggest, { Suggest } from "react-geosuggest";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import Form from "components/Form";
import BaseFormElement from "components/Form/BaseFormElement";
import { IFile } from "components/Form/FilePicker";
import RadioGroup from "components/RadioGroup";
import Switch from "components/Switch";
import RadioButton from "components/RadioButton";
import ImageGroup from "components/ImageGroup";
import Clickable from "components/Clickable";
import { AnswerState, GigReportAnswer } from "interfaces/ReportStep";
import { ReportQuestion } from "interfaces/ReportQuestion";
import { CalendarDropdown } from "components/Calendar/CalendarDropdown";
import { ContactQuestionFieldValue } from "components/Form/ContactBox";
import { AgreementSignatureQuestionValue } from "components/Form/AgreementBox";
import { parseISO } from "date-fns";
import cancelIcon from "assets/cancel.svg";
import { GigFull } from "interfaces/Gig";
import FooterDistance from "components/FooterDistance";
import Button from "components/Button";
import variables from "styles/variables";
import StepItem from "../StepItem";
import styles from "./styles.scss";
import moment from "moment-timezone";
import { Desktop } from "components/Layout/Responsive";
import { AnswerType, TemperatureUnit } from "gql-gen";
import { toKelvin } from "utilities/temperature";
import { StepState, ReportQuestionWChildren } from "../StepState";
import { Markdown } from "components/Markdown";
import ProductInfoSection from "../ProductInfoSection";
import { useEffect, useState } from "react";
import SearchableSelect from "components/LegacySearchable/SearchableSelect";
import SearchableMultiSelect from "components/LegacySearchable/SearchableMultiSelect";
import { getRustBackendUrl } from "utilities/getServerRootUrl";
import useRouter from "use-react-router";
import { getJwt } from "utilities/authentication";

import { AsyncPaginate } from "react-select-async-paginate";
import type { GroupBase, MultiValue, OptionsOrGroups, SingleValue } from "react-select";


interface StepFormProps {
  gig: GigFull;
  disabled: boolean;
  step: StepState;
  setAnswer: (qId: string, answer: GigReportAnswer<any>, debounce: boolean) => void;
  returnToChecklist: (goToNext: boolean) => void;
  goToCheckin: () => void;
  verifyReport?: boolean;
  isMobile?: boolean;
}

const returnedObj = <T extends any>(value: T, state: AnswerState) => ({ value, state });
const answeredWithVal = <T extends any>(value: T) => returnedObj(value, "answered");
const nullAndPending = returnedObj(null, "pending");
const nullAndAnswered = returnedObj(null, "answered");
const nullNotApplicable = returnedObj(null, "na");
const answeredButPending = <T extends any>(value: T) => returnedObj(value, "pending");

function componentStateReducer(answerType: AnswerType, answerValue: any, answerState: AnswerState) {
  if (answerState === "na") {
    return nullNotApplicable;
  }
  switch (answerType) {
    case "expense":
      return answerValue === null
        ? nullAndAnswered
        : answerValue && (answerValue.amount === null || answerValue.images.length === 0)
          ? returnedObj(answerValue, "pending")
          : answeredWithVal(answerValue);
    case "image":
      return answerValue === null ? nullAndPending : answeredWithVal(answerValue);
    case "number":
    case "multiple_choice_one":
    case "single_select":
    case "text":
    case "money":
    case "money_with_negative":
      return answerValue === null || answerValue === undefined || answerValue === ""
        ? nullAndPending
        : answeredWithVal(answerValue);
    case "temperature":
      return answerValue === null
        ? nullAndPending
        : answerValue && answerValue.raw && answerValue.unit && answerValue.kelvin
          ? answeredWithVal(answerValue)
          : answeredButPending(answerValue);
    case "contact":
      return answerValue === null
        ? nullAndPending
        : answerValue && answerValue.name
          ? answeredWithVal(answerValue)
          : answeredButPending(answerValue);
    case "agreement_signature":
      return answerValue === null
        ? nullAndPending
        : answerValue && answerValue.agreed && answerValue.signature
          ? answeredWithVal(answerValue)
          : answeredButPending(answerValue);
    case "toggle":
      return answeredWithVal(answerValue);
    case "date":
      return answerValue ? answeredWithVal(answerValue) : nullAndPending;
    case "location":
      return answerValue ? answeredWithVal(answerValue) : nullAndPending;
    default:
      return returnedObj(answerValue, answerState);
  }
}

export type BombonQuestion = GigReportAnswer<any> & { id: string };

export const getStateId = (qId: string, productId?: string | null) => qId + "_" + (productId || "null");

export default class StepForm extends React.PureComponent<StepFormProps> {
  public renderQuestion = (question: ReportQuestionWChildren, indent: number): React.ReactNode => {
    const {
      step: {
        step: { productId },
        state,
      },
      setAnswer,
      disabled,
    } = this.props;

    const stateId = getStateId(question.id, productId);
    const answer: any = state[stateId];

    return React.createElement(resolveTypeComponent(question.answerType), {
      key: question.id,
      question,
      answer,
      indent,
      disabled,
      setValue: (value, state) => {
        return setAnswer(
          stateId,
          componentStateReducer(question.answerType, value, state || "pending"),
          shouldDebounce(question.answerType),
        );
      },
      childrenQuestions: question.children && question.children.map(q => this.renderQuestion(q, indent + 1)),
    });
  };

  public render() {
    const {
      step: stepState,
      step: { step, questions },
      returnToChecklist,
      goToCheckin,
      verifyReport,
      gig,
      isMobile,
    } = this.props;

    const { name, progress } = step;

    const questionViews = questions.map(q => this.renderQuestion(q, 0));
    const isCheckedIn = (gig && !!gig.checkinStatus) || !gig?.programRole?.role?.hasCheckin;
    const { startTime } = gig;
    const checkInAvailable = moment(startTime).diff(moment(), "hours") <= 3;
    const rtc = verifyReport || !checkInAvailable || isCheckedIn;

    const footer = (
      <Desktop>
        {isDesktop =>
          isDesktop && !verifyReport ? null : (
            <div className={cx(styles.footerContainer)}>
              <div className={styles.footerButtons}>
                <Button
                  kind={isDesktop ? "primary" : "translucent"}
                  type="submit"
                  className={cx(styles.footerButton, !isDesktop ? styles.footerButtonSecondary : "")}
                  onClick={rtc ? () => returnToChecklist(false) : goToCheckin}
                  data-test={"gogetter.report.saveAndReturn"}
                >
                  {rtc ? "Save & return to list" : "Ready? Let's check in."}
                </Button>
                {!isDesktop ? (
                  <Button
                    kind="primary"
                    type="submit"
                    className={styles.footerButton}
                    onClick={() => returnToChecklist(true)}
                    data-test={"gogetter.report.saveAndContinue"}
                  >
                    Save & Continue
                  </Button>
                ) : null}
              </div>
              <Text.H4 kind="reverse" className={styles.footerNote}>
                {isCheckedIn || verifyReport
                  ? "Remember: Scroll to see all questions"
                  : "You must check in to unlock this report."}
              </Text.H4>
            </div>
          )
        }
      </Desktop>
    );

    return (
      <div className={styles.step}>
        {isMobile ? (
          <StepItem
            title={name}
            progress={progress}
            open
            stepState={stepState}
            onClick={() => returnToChecklist(false)}
          />
        ) : null}

        <div className={cx(styles.stepForm, verifyReport && styles.noBottomPadding)}>
          {!verifyReport && <div className={!isCheckedIn ? styles.overlay : undefined} />}
          <div>
            {step.productId ? <ProductInfoSection productId={step.productId} /> : null}
            <ImageGroup>{questionViews}</ImageGroup>
          </div>
        </div>
        {verifyReport ? footer : <FooterDistance distance={0}>{footer}</FooterDistance>}
      </div>
    );
  }
}

const shouldDebounce = (t: AnswerType) =>
  t === "text" || t === "number" || t === "money" || t === "expense" || t === "temperature";

function resolveTypeComponent(answerType: AnswerType): React.ComponentType<QuestionFieldProps<any>> {
  switch (answerType) {
    case "atom_choice_one":
      return AtomChoiceOneQuestionField;
    case "image":
      return ImageQuestionField;
    case "multiple_choice_one":
      return MultipleChoiceQuestionField;
    case "single_select":
      return SingleSelectQuestionField;
    case "date":
      return DateQuestionField;
    case "multi_select":
      return MultiSelectQuestionField;
    case "multiple_choice_many":
      return MultipleChoiceManyQuestionField;
    case "atom_choice_one":
      return AtomChoiceOneQuestionField;
    case "atom_choice_many":
      return AtomChoiceMultipleQuestionField;
    case "expense":
      return ExpenseQuestionField;
    case "number":
      return NumberQuestionField;
    case "text":
      return TextQuestionField;
    case "money":
      return MoneyQuestionField;
    case "money_with_negative":
      return MoneyWithNegativeQuestionField;
    case "contact":
      return ContactQuestionField;
    case "agreement_signature":
      return AgreementSignatureQuestionField;
    case "toggle":
      return ToggleQuestionField;
    case "location":
      return LocationQuestionField;
    case "temperature":
      return TemperatureQuestionField;
    default:
      throw new Error(`Unimplemented question type: ${answerType} used`);
  }
}

interface QuestionFieldProps<T> {
  question: ReportQuestion;
  answer: GigReportAnswer<T>;
  setValue: (value: T | T[] | undefined | null, state?: AnswerState | null) => void;
  indent: number;
  disabled: boolean;
  childrenQuestions?: React.ReactNode[];
}

function TextQuestionField(props: QuestionFieldProps<string>) {
  const {
    question,
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props}>
      <Form.TextBox
        placeholder={question.inputPlaceholder || "Write here..."}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
        value={value || ""}
        multiline
        rows={4}
        disabled={disabled}
      />
    </FieldBase>
  );
}

function NumberQuestionField(props: QuestionFieldProps<number>) {
  const {
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props} transparent>
      <Form.NumberBox min={0} onChange={setValue} value={value} disabled={disabled} />
    </FieldBase>
  );
}

function MoneyQuestionField(props: QuestionFieldProps<number>) {
  const {
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props} transparent>
      <Form.DecimalBox onChange={setValue} value={value} disabled={disabled} maxDigits={10} />
    </FieldBase>
  );
}

function MoneyWithNegativeQuestionField(props: QuestionFieldProps<number>) {
  const {
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props} transparent>
      <Form.DecimalBox onChange={setValue} value={value} disabled={disabled} allowNegative={true} maxDigits={10} />
    </FieldBase>
  );
}

function MultipleChoiceQuestionField(props: QuestionFieldProps<string>) {
  const {
    question: { options },
    setValue,
    disabled,
    answer: { value },
    question,
  } = props;
  return (
    <FieldBase {...props} transparent>
      <RadioGroup onChange={setValue} value={value}>
        {options.map(opt => (
          <RadioButton key={opt} value={opt} disabled={disabled} testId={question?.key}>
            {opt}
          </RadioButton>
        ))}
      </RadioGroup>
    </FieldBase>
  );
}

function SingleSelectQuestionField(props: QuestionFieldProps<string>) {
  const {
    question: { options },
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props} transparent>
      <SearchableSelect
        selectedItem={value}
        compact
        name={null}
        onChange={e => setValue(e.name, "answered")}
        placeholder={"Select one (or search)"}
        items={options.map(option => ({ id: option, name: option }))}
        wrapperClassName={styles.searchableWrapper}
        inputClassName={styles.customInput}
        disabled={disabled}
      />
    </FieldBase>
  );
}

function MultiSelectQuestionField(props: QuestionFieldProps<string[]>) {
  const {
    question: { options },
    setValue,
    answer: { value },
  } = props;
  const values = value || [];
  const setFieldValue = (v: string[]) => (v.length ? setValue(v, "answered") : setValue(null, "pending"));

  return (
    <FieldBase {...props} transparent>
      <SearchableMultiSelect
        name={""}
        selectedItems={values}
        compact
        onChange={setFieldValue}
        placeholder={"Select multiple (or search)"}
        items={options.map(option => ({ id: option, name: option }))}
        className={styles.searchableWrapper}
      />
    </FieldBase>
  );
}

function DateQuestionField(props: QuestionFieldProps<string>) {
  const {
    setValue,
    disabled,
    answer: { value },
  } = props;
  return (
    <FieldBase {...props}>
      <CalendarDropdown
        value={value ? parseISO(value) : null}
        onChange={value => setValue(value.toISOString(), "answered")}
        testId={"dateQuestionField"}
        label="Date"
        placeholder="Please select..."
        disabled={disabled}
      />
    </FieldBase>
  );
}

type Atom = {
  hash_id: string;
  uuid: string;
  name: string;
  identifier?: string;
}

type Pagination = {
  limit: number;
  offset: number;
}

type LoadAtomsCfg = {
  pagination: Pagination;
  question: ReportQuestion;
  orgId: string;
}

async function loadPaginatedAtoms(search: string, _: OptionsOrGroups<Atom, GroupBase<Atom>>, loadCfg: LoadAtomsCfg) {
  const { pagination, question, orgId } = loadCfg;

  const qs = new URLSearchParams([
    ["atomScope", question.atomScope.charAt(0).toUpperCase() + question.atomScope.slice(1)],
    ...(question.elementHashids ?? []).map(hashid => ["elementHashids", hashid]),
    ...(question.atomTagIds ?? []).map(tagId => ["atomTagIds", tagId]),
    ["search", search],
    ["limit", pagination.limit.toString()],
    ["offset", pagination.offset.toString()],
  ]);

  const response = await fetch(
    `${getRustBackendUrl()}/api/v1/organizations/${orgId}/atoms_for_question?${qs.toString()}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${getJwt()}` },
    },
  );

  const { data, pagination: paginationRes }: { data: Atom[], pagination: { total_count: number } } = await response.json();

  const hasMore = paginationRes.total_count > pagination.offset + pagination.limit;

  return {
    options: data.map(atom => ({ uuid: atom.uuid, name: atom.name, identifier: atom.identifier, hash_id: atom.hash_id })),
    hasMore,
    additional: {
      ...loadCfg,
      question,
      pagination: {
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
      }
    },
  };
}


type AtomChoiceOneQuestion = { atom?: Atom; other?: string };

function AtomChoiceOneQuestionField(props: QuestionFieldProps<AtomChoiceOneQuestion>) {
  const {
    question,
    setValue,
    answer: { value },
  } = props;
  const [selectedOther, setSelectedOther] = useState<boolean>(value?.other !== undefined);

  useEffect(() => {
    setSelectedOther(value?.other !== undefined);
  }, [value?.other]);


  return (
    <FieldBase {...props} transparent>
      {!selectedOther ? (
        <PaginatedAtomSelector
          question={question}
          value={value?.atom}
          onChange={(v) => {
            if (isSingleValue(v)) {
              setValue({ atom: v!, other: undefined }, "answered");
            } else {
              setValue({ atom: undefined, other: undefined }, "pending");
            }
          }}
          isMulti={false}
        />
      ) : null}
      <AtomChoiceQuestionOther
        {...props}
        selectedOther={selectedOther}
        onCheck={v => {
          setSelectedOther(v);
          setValue({ atom: undefined, other: undefined }, "pending");
        }}
      />
    </FieldBase>
  );
}

function isSingleValue<Atom>(value: SingleValue<Atom> | MultiValue<Atom>): value is SingleValue<Atom> {
  return !Array.isArray(value) && value !== null;
}

type AtomChoiceMultipleQuestion = { atoms?: Atom[]; other?: string };

function AtomChoiceMultipleQuestionField(props: QuestionFieldProps<AtomChoiceMultipleQuestion>) {
  const {
    question,
    setValue,
    answer: { value, state },
  } = props;
  const [selectedOther, setSelectedOther] = useState<boolean>(value?.other !== undefined);

  useEffect(() => {
    setSelectedOther(value?.other !== undefined);
  }, [value?.other]);


  return (
    <FieldBase {...props} transparent>
      <PaginatedAtomSelector
        question={question}
        value={value?.atoms}
        onChange={v => {
          if (!isSingleValue(v) && v.length) {
            setValue({ ...value, atoms: [...v!] }, "answered");
          }
          else {
            setValue(null, "pending");
          }
        }}
        isMulti
      />
      <AtomChoiceQuestionOther
        {...props}
        selectedOther={selectedOther}
        onCheck={v => {
          setSelectedOther(v);
          if (!v) {
            setValue({ ...value, other: undefined }, state);
          }
        }}
      />
    </FieldBase>
  );
}


type AtomChoiceQuestionOtherProps = {
  selectedOther: boolean;
  onCheck: (v: boolean) => void;
}

function AtomChoiceQuestionOther(props: QuestionFieldProps<AtomChoiceOneQuestion | AtomChoiceMultipleQuestion> & AtomChoiceQuestionOtherProps) {
  const { question, disabled, answer: { value }, selectedOther, setValue, onCheck } = props;

  return (
    <>
      {question.hasOtherOption ? (
        <Form.Checkbox
          onCheck={onCheck}
          disabled={disabled}
          checked={selectedOther}
          className={styles.atomChoiceContainer}
        >
          <span className={styles.atomChoiceOther}>Other</span>
        </Form.Checkbox>
      ) : null}
      {question.hasOtherOption && selectedOther ? (
        <Form.TextBox
          placeholder={question.inputPlaceholder || "Write here..."}
          onChange={({ target: { value: v } }: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (v && v.length > 0) {
              setValue({ ...value, other: v }, "answered");
            } else {
              setValue({ ...value, other: undefined }, "pending");
            }
          }}
          value={value?.other || ""}
          className={styles.atomChoiceOtherInput}
          multiline
          rows={4}
          disabled={disabled}
        />
      ) : null}
    </>
  );
}

type IPaginatedAtomSelector = {
  value?: Atom | Atom[];
  onChange: (v: MultiValue<Atom> | SingleValue<Atom>) => void;
  isMulti?: boolean;
  question: ReportQuestion;
}

function PaginatedAtomSelector(props: IPaginatedAtomSelector) {
  const { value, onChange, isMulti, question } = props;

  const {
    match: {
      params: { orgId },
    },
  } = useRouter<{ orgId: string }>();

  const additional: LoadAtomsCfg = {
    pagination: { limit: 20, offset: 0 },
    question,
    orgId,
  };

  return (
    <AsyncPaginate
      additional={additional}
      //The reason to use ! here is that additional prop is defined as optional, but loadOptions has it defined as required.
      //We know that additional will always be defined, so we can safely use ! here.
      loadOptions={(search, atoms, cfg) => loadPaginatedAtoms(search, atoms, cfg!)}
      defaultOptions
      getOptionLabel={v => v.name + `${v.identifier ? ` - ${v.identifier}` : ""}`}
      getOptionValue={v => v.uuid}
      onChange={onChange}
      value={value ?? null}
      isMulti={isMulti}
      isClearable
      debounceTimeout={300}
      closeMenuOnSelect={!isMulti}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 10,
        }),
      }}
    />
  );
}


function MultipleChoiceManyQuestionField(props: QuestionFieldProps<string[]>) {
  const {
    question: { options },
    setValue,
    disabled,
    answer: { value },
  } = props;
  const values = value || [];
  const setFieldValue = (v: string[]) => (v.length ? setValue(v, "answered") : setValue(null, "pending"));

  return (
    <FieldBase {...props} transparent>
      <Form.CheckboxGroup onChange={setFieldValue} values={values}>
        {options.map(opt => (
          <Form.Checkbox key={opt} value={opt} disabled={disabled} className={styles.multipleChoiceManyLabel}>
            {opt}
          </Form.Checkbox>
        ))}
      </Form.CheckboxGroup>
    </FieldBase>
  );
}

function ImageQuestionField(props: QuestionFieldProps<string[]>) {
  const {
    question: { optional, notApplicableDesc },
    setValue,
    disabled,
    answer: { value, state },
  } = props;

  const images = value || [];
  const na = state === "na";

  return (
    <FieldBase {...props} transparent handleNA={false}>
      <Form.FilePicker
        size={77}
        required={!optional}
        disabled={na || disabled}
        value={images.map(url => ({ url }))}
        onChange={(images: IFile[]) =>
          setValue(
            images.map(({ url }) => url),
            state,
          )
        }
        onClick={() => {
          if (na) {
            setValue(value, "pending");
          }
        }}
        accepts="image"
      >
        {optional
          ? () => (
            <Clickable
              actionLabel={"Not applicable"}
              className={na ? styles.imagesNaActive : styles.imagesNa}
              onClick={() => {
                if (!disabled) setValue(value, "na");
              }}
            >
              <Icon src={cancelIcon} fill={na ? variables.white : variables.gray3} size={20} />
              <Text.H4 kind={na ? "reverse" : "secondary"} className={styles.imageNaText}>
                {notApplicableDesc || "Not Applicable"}
              </Text.H4>
            </Clickable>
          )
          : undefined}
      </Form.FilePicker>
    </FieldBase>
  );
}

interface ExpenseQuestionFieldValue {
  images: IFile[];
  amount: number | null;
}

function ExpenseQuestionField(props: QuestionFieldProps<ExpenseQuestionFieldValue>) {
  const {
    setValue,
    disabled,
    answer: { value, state },
  } = props;

  const onSetMoney = (amount: number | null) =>
    setValue({ images: value ? value.images : [], amount: amount || 0 }, state);
  const onSetImages = (images: IFile[]) => {
    setValue({ amount: value ? value.amount : 0, images }, state);
  };
  const onRadioChange = (val: string | null) => {
    switch (val) {
      case "Yes":
        setValue({ images: [], amount: null }, "pending");
        break;
      case "No":
      default:
        setValue(null, "answered");
        break;
    }
  };

  return (
    <FieldBase {...props} transparent>
      <RadioGroup
        onChange={onRadioChange}
        value={(state === "pending" && value === null) || state === "na" ? null : value !== null ? "Yes" : "No"}
      >
        {["Yes", "No"].map(opt => (
          <RadioButton key={opt} value={opt} disabled={disabled}>
            {opt}
          </RadioButton>
        ))}
      </RadioGroup>
      {value !== null && (
        <div className={styles.expensesContainer}>
          <Form.Section
            header={<Text.H2 className={styles.questionTitle}>What were your total other approved expenses?</Text.H2>}
            description={"Please add up all receipts"}
            transparent={true}
          >
            <Form.DecimalBox onChange={onSetMoney} value={value && value.amount} />
          </Form.Section>
          <Form.Section
            header={<Text.H2 className={styles.questionTitle}>Add photo(s) of receipt(s)</Text.H2>}
            description={"Include itemized receipts and credit card slips. Add captions if photos are unclear"}
            transparent={true}
          >
            <Form.FilePicker
              size={60}
              onChange={onSetImages}
              value={(value && value.images) || []}
              accepts="image"
              caption
            />
          </Form.Section>
        </div>
      )}
      {/*value !== null &&
        value &&
        value.images.map((val, index) => {
          return (
            <ExpenseCard
              expense={value}
              key={value.images[index].url}
              receiptNumber={index + 1}
              className={styles.expenseCard}
            />
          );
              })*/}
    </FieldBase>
  );
}

function ContactQuestionField(props: QuestionFieldProps<ContactQuestionFieldValue>) {
  const {
    question,
    setValue,
    disabled,
    answer: { value },
  } = props;

  const setFieldValue = (fieldName: keyof ContactQuestionFieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
    return setValue({
      ...value,
      [fieldName]: fieldName === "phone" ? e.target.value.replace(/\D/g, "") : e.target.value,
    });
  };

  return (
    <FieldBase {...props}>
      <Form.ContactBox
        placeholder={question.inputPlaceholder}
        setFieldValue={setFieldValue}
        value={value || null}
        disabled={disabled}
      />
    </FieldBase>
  );
}

function AgreementSignatureQuestionField(props: QuestionFieldProps<AgreementSignatureQuestionValue>) {
  const {
    setValue,
    answer: { value },
  } = props;

  const toggleAgreed = () =>
    setValue({
      signature: value ? value.signature : "",
      agreed: value ? !value.agreed : true,
    });

  const setSignatureVal = (newVal: string) => setValue({ signature: newVal, agreed: !!(value && value.agreed) });

  return (
    <FieldBase {...props} transparent>
      <Form.AgreementBox
        onSignatureChange={(e: React.ChangeEvent<HTMLInputElement>) => setSignatureVal(e.target.value)}
        onCheck={toggleAgreed}
        value={value || null}
        label={"TYPE YOUR NAME TO CERTIFY THIS REPORT"}
      />
    </FieldBase>
  );
}

function ToggleQuestionField(props: QuestionFieldProps<boolean>) {
  const {
    question: { id, options },
    setValue,
    answer: { value },
  } = props;

  const setToggleVal = () => setValue(!value);

  return (
    <div className={styles.toggleContainer}>
      <Switch
        id={id}
        checked={!!value}
        onChange={setToggleVal}
        className={styles.toggle}
        sliderClassName={value ? styles.toggled : styles.untoggled}
      />

      <div className={cx(value ? styles.toggled : styles.untoggled, styles.toggleLabel)}>
        <Text.Display5>{value ? options[0] : options[1]}</Text.Display5>
      </div>
    </div>
  );
}

function LocationQuestionField(
  props: QuestionFieldProps<{
    address: any;
    latitude: any;
    longitude: any;
    addressJson: google.maps.GeocoderAddressComponent[];
    description: string;
  }>,
) {
  const {
    setValue,
    answer: { value },
  } = props;

  const handleLocationChanged = (suggestion: Suggest) => {
    let locInfo = null;

    if (suggestion && suggestion.gmaps) {
      const {
        location: { lat: latitude, lng: longitude },
        gmaps: { formatted_address: address, address_components },
        label,
      } = suggestion;

      locInfo = {
        address,
        latitude,
        longitude,
        description: label,
        addressJson: address_components,
      };
    }

    setValue(locInfo);
  };

  const handleGeosuggestChanged = (value: string) => {
    if (value.replace(/\s/g, "") === "") {
      setValue(null);
    }
  };

  return (
    <FieldBase {...props} transparent>
      <BaseFormElement
        label={"Address"}
        inputClassNameProp="inputClassName"
        element={
          <Geosuggest
            className={styles.geosuggest}
            placeholder="ex. 6 Harrison Street New York"
            country={["us", "ca"]}
            initialValue={(value && value.description) || ""}
            onChange={handleGeosuggestChanged}
            onSuggestSelect={handleLocationChanged}
          />
        }
      />
    </FieldBase>
  );
}

type Temperature = {
  raw: number;
  unit: TemperatureUnit;
  kelvin: number;
};

function TemperatureQuestionField(props: QuestionFieldProps<Temperature>) {
  const {
    setValue,
    disabled,
    answer: { value },
    question: { inputPlaceholder },
  } = props;

  const [tempError, setTempError] = useState(false);

  const onChange = ({
    raw = value?.raw ?? 0,
    unit = value?.unit ?? TemperatureUnit.Fahrenheit,
  }: Partial<Omit<Temperature, "kelvin">>) => {
    setValue({
      raw,
      unit,
      kelvin: toKelvin(raw, unit),
    });
  };

  const userInputError = (kelvin?: number): boolean => {
    if (typeof kelvin === "number" && (kelvin < 305.37 || kelvin > 314.3)) {
      return true;
    }
    return false;
  };

  const debouncedError = debounce(() => {
    setTempError(userInputError(value?.kelvin));
  }, 400);

  useEffect(() => {
    debouncedError();
  }, [value?.kelvin]);

  return (
    <FieldBase {...props} transparent>
      <div style={{ display: "flex" }}>
        <Form.DecimalBox
          places={1}
          maxDigits={4}
          symbol={null}
          onChange={raw => onChange({ raw })}
          value={value?.raw ?? 0}
          disabled={disabled}
          placeholder={inputPlaceholder ?? "98.6"}
        />
        <Form.Section>
          <Form.Dropdown
            className={styles.temperatureUnit}
            value={value?.unit ?? TemperatureUnit.Fahrenheit}
            onChange={e => onChange({ unit: e.target.value as TemperatureUnit })}
          >
            <option value={TemperatureUnit.Fahrenheit}>&deg;F</option>
            <option value={TemperatureUnit.Celsius}>&deg;C</option>
          </Form.Dropdown>
        </Form.Section>
      </div>
      {tempError && <Text.Message kind={"error"}>Are you sure? Please enter a valid temperature.</Text.Message>}
    </FieldBase>
  );
}

interface FieldBaseProps extends QuestionFieldProps<any> {
  children: React.ReactNode;
  transparent?: boolean;
  handleNA?: boolean;
}

function FieldBase({
  question,
  setValue,
  answer: { state } = { state: "answered" },
  disabled,
  children,
  transparent,
  handleNA = true,
  indent,
  childrenQuestions,
}: FieldBaseProps) {
  return (
    <Desktop>
      {isDesktop => {
        return (
          <div className={styles.question}>
            {indent === 0 ? (
              <svg
                width={isDesktop ? 45 : 35}
                height={20}
                color={state === "pending" ? variables.pink1 : variables.teal1}
                className={styles.questionStatusIndicator}
              >
                <line x1={0} y1={13} x2={isDesktop ? 40 : 30} y2={13} strokeWidth={2} stroke={"currentColor"} />
                <circle cx={isDesktop ? 40 : 30} cy={13} r={5} fill={"currentColor"} />
              </svg>
            ) : (
              <svg width={23} className={styles.questionStatusIndicator}>
                {!isDesktop && <line x1={10} y1={0} x2={10} y2={"100%"} strokeWidth={1} stroke={variables.gray3} />}
                <circle cx={10} cy={13} r={5} fill={state === "pending" ? variables.pink1 : variables.teal1} />
              </svg>
            )}

            <div className={styles.questionField} data-test={`gogetter.report.questionField.${question.key}`}>
              <Form.Section
                header={
                  <Text.H2 className={styles.questionTitle}>
                    <Markdown>{question.gogetterTitle}</Markdown>
                  </Text.H2>
                }
                description={<Markdown>{question.helper}</Markdown>}
                transparent={transparent}
              >
                {children}
              </Form.Section>

              {handleNA && question.optional && (
                <RadioButton
                  selected={state === "na"}
                  onChange={() => setValue(null, "na")}
                  disabled={disabled}
                  className={styles.notApplicable}
                >
                  <Markdown>{question.notApplicableDesc || "Not Applicable"}</Markdown>
                </RadioButton>
              )}

              {question.footer && (
                <Text.P3>
                  <Markdown>{question.footer}</Markdown>
                </Text.P3>
              )}

              <div>{childrenQuestions}</div>

              <div className={styles.questionDiv} />
            </div>
          </div>
        );
      }}
    </Desktop>
  );
}
