// Typography
$WesFY-Black: "WesFy-Black", sans-serif;
$WesFY-Regular: "WesFy-Regular", sans-serif;
$WesFY-Bold: "WesFy-Bold", sans-serif;
$Lato: "Lato", sans-serif;
$Lato-Bold: "Lato-Bold", sans-serif;
$Lato-BoldItalic: "Lato-BoldItalic", sans-serif;
$Lato-Heavy: "Lato-Heavy", sans-serif;

/// group: spacing
$xxs: 2px;
$xs: 5px;
$s: 10px;
$m: 15px;
$l: 20px;
$xl: 25px;
$xxl: 30px;
$xxxl: 35px;
/// end group

$spacing-gutter-default: 38px;
$spacing-gutter-mobile: 15px;
$modal-spacing: $xl;
$v-modal-spacing: $xxxl;
$header-size: 82px;

// Form Elements Padding
$form-el-top-padding: 15px;
$form-el-side-padding: 15px;

// Other Sizes
$border-radius: 3px;
$border-radius-small: 2px;
$z-index-base: 0;

/// group: colors
$gray-1: #272f3f;
$gray-2: #424a5c;
$gray-3: #626c83;
$gray-4: #b1b5c1;
$gray-5: #f3f6fa;

$blue-1: #3a59a1;
$blue-2: #60b7f9;
$blue-3: #c6e0fc;
$blue-4: #e2effd;

$pink: #c12454;
$pink-1: #eb356c;
$pink-2: #fdeaf0;

$teal-1: #4dd9b1;
$teal-2: #ebfbf7;

$yellow-1: #d48900;
$yellow-2: #eca527;
$yellow-3: #f3ebd9;

$purple: #635070;
$green: #749379;
$white: #ffffff;
$black: #000000;
$darkgray: #434a5c;

$haydayLogo: #f4a41d;

/// end group

$shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

$border-light: 1px solid darken($gray-5, 2%);
$border-medium: 1px solid $gray-5;
$border-medium-thick: 2px solid $gray-3;
$border-dark: 1px solid $gray-3;
$border-darkest: 1px solid $gray-1;

//Gradients
$draft-gradient: rgba(238, 238, 238, 0.9)
  repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(0, 0, 0, 0.05) 4px, rgba(0, 0, 0, 0.05) 5px);

$side-bar-gradient-left: linear-gradient(to left, #eff2fa 0%, rgba(255, 255, 255, 0.001) 100%);
$side-bar-gradient-right: linear-gradient(to right, #eff2fa 0%, rgba(255, 255, 255, 0.001) 100%);
$bar-gradient-up-down: linear-gradient(to bottom, #686f80, #5e6577, #545c6f, #4a5266, #40495e);

// Breakpoints
// Please use min-width for media queries. ex:
// .some-class { mobile and default styles go here }
// @media (min-width: $tablet) { .some-class { tablet and/or desktop styles go here } }
// @media (min-width: $desktop) { .some-class { desktop styles go here } }

$tiny-mobile: 321px;
$tablet: 540px;
$desktop: 768px;
$narrow-container-width: 640px;

// Animations
$base-duration: 200ms;
$transition-duration: 300ms;
$base-timing: ease;

@mixin transitionify($prop) {
  transition: {
    duration: $transition-duration;
    property: $prop;
    timing-function: $base-timing;
  }
}

@mixin mobile {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin tiny-mobile {
  @media (max-width: $tiny-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @media (max-width: $desktop) {
      @content;
    }
  }
}

@mixin tablet-desktop {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin hide-on-mobile {
  @include mobile {
    display: none;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin card {
  background: $white;
  border-radius: $border-radius;
  box-shadow: $shadow;
}

@mixin button {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  padding: 11px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: $transition-duration background-color $base-timing;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

@mixin focus-glow {
  outline: 0;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 1px $blue-2;
}

@mixin focus {
  &:focus {
    @include focus-glow;
  }
}

@mixin transition-focus {
  box-shadow: none;
  @include transitionify(all);
  @include focus;
}

@mixin loading-animation($name) {
  animation-name: $name;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}

@mixin pulse-animation {
  @include loading-animation(pulse);
}

@keyframes subtle-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.9;
  }
}

@mixin subtle-pulse-animation {
  @include loading-animation(subtle-pulse);
}

@mixin input {
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  whitespace: nowrap;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-family: $Lato;
  color: $black;
  // padding: 0;
  box-shadow: none;
  @include transitionify(box-shadow);

  &::-ms-expand,
  &::-ms-clear {
    display: none;
  }

  @include focus;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//noinspection ALL
@mixin safe-top($property) {
  @if $property == margin {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  } @else if $property == padding {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  } @else if $property == height {
    height: constant(safe-area-inset-top);
    height: env(safe-area-inset-top);
  } @else if $property == top {
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
  } @else {
    @error "safe-top only supports padding, margin and height";
  }
}

//noinspection ALL
@mixin safe-bottom($property, $offset) {
  @if $property == margin {
    margin-bottom: calc(constant(safe-area-inset-bottom) + #{$offset});
    margin-bottom: calc(env(safe-area-inset-bottom) + #{$offset});
  } @else if $property == padding {
    padding-bottom: calc(constant(safe-area-inset-bottom) + #{$offset});
    padding-bottom: calc(env(safe-area-inset-bottom) + #{$offset});
  } @else if $property == height {
    height: calc(constant(safe-area-inset-bottom) + #{$offset});
    height: calc(env(safe-area-inset-bottom) + #{$offset});
  } @else if $property == bottom {
    bottom: constant(safe-area-inset-top);
    bottom: env(safe-area-inset-top);
  } @else {
    @error "safe-bottom only supports padding, margin and height";
  }
}

@function fix-vh($vh) {
  @return calc(#{$vh} - var(--vh-offset));
}
