import { graphql } from "@apollo/client/react/hoc";
import * as React from "react";
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom";
import { build, Scope } from "bombon";
import produce from "immer";
import _ from "lodash";
import moment from "moment-timezone";
import cx from "classnames";
import { option, readonlyRecord as record, function as fn } from "fp-ts";

import formStatusIcon from "assets/form-status.svg";

import Icon from "components/Icon";
import Text from "components/LegacyText";
import ListView from "components/ListView";
import LoadingContainer from "components/LoadingContainer";
import { GigReportStep, GigReportAnswer } from "interfaces/ReportStep";
import { GigFull, FINAL_REPORT_STATES, CheckInOutStatus } from "interfaces/Gig";
import { StorageScope } from "utilities/storageScope";
import useRouter from "use-react-router";
import variables from "styles/variables";
import ActionFooter from "components/ActionFooter";
import FooterDistance from "components/FooterDistance";
import { earthRadiusMi, getDistance } from "utilities/getPosition";
import { formatDistance } from "utilities/string";
import { Desktop } from "components/Layout/Responsive";
import { flowRight as compose } from "lodash";
import { getAppUrl } from "utilities/routes";
import { bombonNodeTypes } from "modules/Report/StepForm/bombonNodeTypes";
import { isPinata } from "utilities/flavor";

import saveStep from "./saveStep.gql";
import reportSteps from "./reportSteps.gql";
import submitReport from "./submitReport.gql";

import styles from "./styles.scss";

import { ggStorage } from "../Gogetter/storage";
import StepItem from "./StepItem";
import StepForm, { BombonQuestion, getStateId } from "./StepForm";
import { ReportQuestionWChildren, StepState } from "./StepState";
import CheckInOut from "./CheckInOut";
import Expenses from "./Expenses";

interface Data {
  loading: boolean;
  gigReportSteps: GigReportStep[];
  refetch: () => Promise<void>;
}

interface Props extends RouteComponentProps {
  gig: GigFull;
  gigRefetch: () => Promise<void>;
  data: Data;
  saveStep: (param0: {}) => Promise<{}>;
  submitReport: (param0: {}) => Promise<{}>;
  verifyReport?: boolean;
  onSaveAndClose?: () => void;
}

interface StepStates {
  [stepKey: string]: StepState;
}

interface Conditionals {
  [qId: string]: any;
}

interface State {
  steps: StepStates | null;
  anyError: boolean;
  submitState: null | "submitting" | "error";
  saveState: null | "saving" | "error";
  conditionals: Conditionals;
  expensesDirty: boolean;
}

const reportStorage = ggStorage.subScope<StepStates>("reports");

class Report extends React.Component<Props, State> {
  readonly state: State = {
    steps: null,
    anyError: false,
    saveState: null,
    submitState: null,
    conditionals: {},
    expensesDirty: false,
  };

  public componentDidMount() {
    window.addEventListener("online", this.saveErrored);
  }

  public componentWillUnmount() {
    window.removeEventListener("online", this.saveErrored);
  }

  public componentDidUpdate(_: Props, { steps: prevSteps }: State) {
    const { steps } = this.state;

    // Wait until we have the steps from getDerivedStateFromProps
    if (steps && !prevSteps) {
      this.saveErrored();
    }
  }

  public static getDerivedStateFromProps(props: Props, { steps: currentSteps }: State) {
    const { gig, data } = props;

    if (gig && data && data.gigReportSteps) {
      const { gigReportSteps } = data;
      let steps: StepStates = {};

      let conditionals: Conditionals = {};

      for (const step of gigReportSteps) {
        for (const question of step.questions) {
          if (question.bombonAST) {
            conditionals[question.id] = build(question.bombonAST, bombonNodeTypes);
          }
        }

        steps[step.key] = {
          queuedChanges: [],
          savingChanges: [],
          failedChanges: [],
          state: step.state,
          questions: step.questions,
          ...(currentSteps?.[step.key] ?? {}),
          step,
        };
      }
      if (!currentSteps) {
        const conditionalSteps = Report.updateConditionals(props, { conditionals, steps });

        if (conditionalSteps) {
          steps = conditionalSteps;
        }

        let anyError = false;

        const unsaved = reportStorage.subScope<StepStates>(gig.id).value;

        if (unsaved && Object.keys(unsaved).length) {
          steps = { ...steps, ...unsaved };
          anyError = true;
        }

        return { steps, anyError, conditionals };
      }

      return { steps };
    }

    return null;
  }

  private static updateConditionals = (
    props: Props,
    { conditionals, steps }: Pick<State, "steps" | "conditionals">,
  ): StepStates | null => {
    if (!steps) return null;

    const {
      data: { gigReportSteps },
      gig,
    } = props;

    const globalScope = new Scope();

    const makeQ = (gigReportSteps: GigReportStep[]) => {
      const q: { [qId: string]: BombonQuestion } = {};
      const qIndex: { [qId: string]: ReportQuestionWChildren } = {};

      for (const step of gigReportSteps) {
        for (const question of step.questions) {
          const id = question.id;

          q[question.key || id] = { id, ...steps[step.key].state[getStateId(id, step.productId)] };
          qIndex[id] = {
            ...question,
            children: [],
          };
        }
      }

      return { q, qIndex };
    };

    const { q: globalQ, qIndex: globalQIndex } = makeQ(gigReportSteps.filter(s => !s.productId));

    const report = {
      gig: {
        id: gig.id,
        startTime: new Date(gig.startTime),
        endTime: new Date(gig.endTime),
      },

      program: {
        id: gig.program.id,
        selfService: gig.program.id,
      },

      checkedIn: gig.checkinStatus && gig.checkinStatus.time,
      checkedOut: gig.checkoutStatus && gig.checkoutStatus.time,
    };

    globalScope.set("report", report);
    globalScope.set("log", console.log);

    return gigReportSteps.reduce((newSteps, gigReportStep) => {
      const { questions, productId } = gigReportStep;
      const stepState = steps[gigReportStep.key];
      const { state } = stepState;

      let activeQuestions: ReportQuestionWChildren[] = [];

      const toMarkAsPending: string[] = [];
      const toMarkAsUnasked: string[] = [];

      const formCondQuestions = [];
      const childrenQuestions: { [qId: string]: string[] } = {};

      const stepScope = globalScope.child("program");

      let q = globalQ;
      let qIndex = globalQIndex;

      if (productId) {
        const { q: stepQ, qIndex: stepQIndex } = makeQ([gigReportStep]);
        q = { ...globalQ, ...stepQ };
        qIndex = { ...globalQIndex, ...stepQIndex };
      }

      stepScope.set("q", q);

      for (const { id: questionId } of questions) {
        if (!(questionId in conditionals)) {
          continue;
        }

        let show = false;
        let under = null;

        const scope = stepScope.child("program");
        scope.set("currentStep", gigReportStep);
        scope.set("show", () => (show = true));
        scope.set("showUnder", (q: BombonQuestion) => {
          show = true;
          under = q.id;
        });
        scope.set("hide", () => {
          show = false;
          under = null;
        });

        conditionals[questionId].eval(scope);

        const stateId = getStateId(questionId, productId);

        if (show) {
          if (under) {
            childrenQuestions[under] = childrenQuestions[under] || [];
            childrenQuestions[under].push(questionId);
          } else {
            formCondQuestions.push(questionId);
          }

          if (state[stateId]?.state === "unasked") {
            toMarkAsPending.push(stateId);
          }
        } else {
          if (state[stateId]?.state !== "unasked") {
            toMarkAsUnasked.push(stateId);
          }
        }
      }

      for (let question of questions) {
        if (!question.bombonAST || formCondQuestions.includes(question.id)) {
          const childrenIds = childrenQuestions[question.id];
          let qToShow: ReportQuestionWChildren = { ...question, children: [] };

          if (childrenIds) {
            for (const childId of childrenIds) {
              if (qIndex[childId]) {
                qToShow.children!.push(qIndex[childId]);
              } else {
                console.warn(`Unknown question id: ${childId}`);
              }
            }
          }

          activeQuestions.push(qToShow);
        }
      }

      const newStepState = { ...stepState, questions: activeQuestions };
      if (toMarkAsPending.length || toMarkAsUnasked.length) {
        for (const qId of toMarkAsPending) {
          newStepState.state = { ...newStepState.state, [qId]: { state: "pending", value: null } };
        }

        for (const qId of toMarkAsUnasked) {
          newStepState.state = { ...newStepState.state, [qId]: { state: "unasked", value: null } };
        }

        newStepState.queuedChanges = [...stepState.queuedChanges, new Date()];
      }

      return { ...newSteps, [gigReportStep.key]: newStepState };
    }, {});
  };

  private debouncedUpdateConditionals = _.debounce(() => {
    this.setState({
      steps: Report.updateConditionals(this.props, this.state),
    });
  }, 500);

  public saveErrored = async () => {
    const { steps, anyError } = this.state;

    if (steps && anyError) {
      this.save();
    }
  };

  public handleAnswerChanged = (stepKey: string) => (qId: string, answer: GigReportAnswer<{}>, debounce: boolean) => {
    const { gig, verifyReport } = this.props;

    if (
      !verifyReport &&
      gig &&
      ((!gig.checkinStatus && gig?.programRole?.role?.hasCheckin) || FINAL_REPORT_STATES.includes(gig.workflowStateKey))
    ) {
      return;
    }

    this.setState(
      state =>
        produce(state, draft => {
          if (draft.steps) {
            draft.steps[stepKey].state[qId] = answer;
            draft.steps[stepKey].queuedChanges.push(new Date());

            if (!debounce) {
              draft.steps = Report.updateConditionals(this.props, draft);
            }
          }
        }),
      () => {
        if (debounce) this.debouncedUpdateConditionals();
      },
    );
  };

  reportHasUnsavedChanges = () => {
    return fn.pipe(
      option.fromNullable(this.state.steps),
      option.exists(record.some(step => step.queuedChanges.length > 0)),
    );
  };

  public save = async () => {
    const {
      gig: { id },
      saveStep,
    } = this.props;

    if (!this.state.steps) {
      throw new Error("save called before steps loaded");
    }

    const updateStep = (stepKey: string, update: (current: StepState) => Partial<StepState>) => {
      this.setState(
        state => {
          if (state.steps && state.steps[stepKey]) {
            return {
              ...state,
              steps: {
                ...state.steps,
                [stepKey]: {
                  ...state.steps[stepKey],
                  ...update(state.steps[stepKey]),
                },
              },
            };
          }

          return state;
        },
        () => {
          const {
            gig: { id },
          } = this.props;
          const { steps } = this.state;

          if (steps) {
            const storage: StorageScope<StepStates> = reportStorage.subScope(id);
            const failedSteps = _.pickBy(steps, s => s.failedChanges.length > 0);

            storage.value = failedSteps;

            this.setState({
              anyError: Object.keys(failedSteps).length > 0,
            });
          }
        },
      );
    };

    this.setState({ saveState: "saving" });
    let resultingSaveState: typeof this.state.saveState = null;
    let shouldRefetch = false;
    let savePromises = [];

    for (const stepKey of Object.keys(this.state.steps ?? {})) {
      const step = this.state.steps?.[stepKey];

      if (step.queuedChanges.length === 0 && step.failedChanges.length === 0) {
        continue;
      }

      shouldRefetch = true;
      const saving = step.queuedChanges.concat(step.failedChanges);
      const excludeSaving = (xs: Date[]) => xs.filter(x => !saving.includes(x));

      const [key, productId] = stepKey.split("_");

      updateStep(stepKey, s => ({
        queuedChanges: excludeSaving(s.queuedChanges),
        savingChanges: s.savingChanges.concat(saving),
        failedChanges: excludeSaving(s.failedChanges),
      }));
      savePromises.push(
        saveStep({
          variables: {
            input: {
              stepKey: key,
              gigId: id,
              productId: productId === "null" ? null : productId,
              state: step.state,
              stateChecksum: step.step.stateChecksum,
            },
          },
        })
          .then(async () => {
            updateStep(stepKey, s => ({
              savingChanges: excludeSaving(s.savingChanges),
            }));

            if (shouldRefetch) {
              await this.props.data.refetch();
            }

            this.setState({ saveState: resultingSaveState });
          })
          .catch(err => {
            resultingSaveState = "error";
            updateStep(stepKey, s => ({
              savingChanges: excludeSaving(s.savingChanges),
              failedChanges: s.failedChanges.concat(saving),
            }));

            throw err;
          }),
      );
    }
    await Promise.all(savePromises);
  };

  public debouncedSave = _.debounce(this.save, 1000);

  public handleSubmit = async () => {
    await this.save();

    const { submitReport, gig } = this.props;
    this.setState({ submitState: "submitting" });

    try {
      await submitReport({
        variables: {
          gigId: gig.id,
        },
      });

      this.setState({ submitState: null }, () => {
        requestAnimationFrame(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      });

      this.props.gigRefetch();
    } catch (err) {
      this.setState({ submitState: "error" });
      throw err;
    }
  };

  public formatDistance(checkStatus: CheckInOutStatus) {
    const {
      location: { latitude, longitude },
    } = this.props.gig;

    const distance = getDistance(
      checkStatus.latitude || 0,
      checkStatus.longitude || 0,
      latitude,
      longitude,
      earthRadiusMi,
    );

    return formatDistance(distance);
  }

  public formatTime(checkStatus: CheckInOutStatus) {
    return moment.tz(checkStatus.time, this.props.gig.timezone).format("h:mma");
  }

  public renderContent() {
    const {
      history,
      location,
      gig,
      gigRefetch,
      match: { isExact, url: checklistUrl, params },
      data,
      verifyReport,
      onSaveAndClose,
    } = this.props;
    const { steps, submitState } = this.state;
    const checkStepsOn = gig?.programRole?.role?.hasCheckin ?? true;

    const returnToGo = () => {
      const orgId = (params as any).orgId;
      const goBackUrl = orgId ? getAppUrl("go", `${orgId}/tasks/${gig?.id}`) : getAppUrl("go");
      window.location.href = goBackUrl;
    };

    const returnToChecklist = () => {
      history.push({ ...location, pathname: checklistUrl });
      gigRefetch();
    };

    const isCheckedIn = !!(gig && gig.checkinStatus);

    const goTo = (newStep: string) => () => {
      window.scrollTo(0, 0);
      history.push({ ...location, pathname: `${checklistUrl}/${newStep}` });
    };

    let stateText = "...";
    let allDone = false;
    let totalQuestions;
    let answered;

    if (data && data.gigReportSteps && steps && gig) {
      totalQuestions = checkStepsOn ? 2 : 0;
      answered = checkStepsOn ? (gig.checkinStatus ? 1 : 0) + (gig.checkoutStatus ? 1 : 0) : 0;

      for (const stepKey in steps) {
        if (steps.hasOwnProperty(stepKey)) {
          const { state } = steps[stepKey];

          for (const qId in state) {
            if (state.hasOwnProperty(qId)) {
              const answer = state[qId];
              if (answer.state !== "unasked") {
                totalQuestions++;

                if (answer.state !== "pending") {
                  answered++;
                }
              }
            }
          }
        }
      }

      if (totalQuestions > answered) {
        stateText = `${answered} of ${totalQuestions} answers`;
      } else {
        stateText = "All done!";
        allDone = true;
      }
    }

    const qSteps = (isDesktop: boolean) =>
      data &&
      steps &&
      data.gigReportSteps &&
      data.gigReportSteps
        .filter(s => steps[s.key]?.questions?.length > 0)
        .map(rs => {
          return (
            <>
              <StepItem
                key={rs.key}
                title={rs.name}
                subtitle={rs.shortDesc}
                progress={rs.progress}
                stepState={steps[rs.key]}
                onClick={async () => {
                  await this.debouncedSave();
                  goTo(rs.key)();
                }}
                testId={`gogetter.report.${rs.key}`}
                active={location.pathname.includes(rs.key)}
              >
                {verifyReport ? (
                  <ViewStep
                    isDesktop={isDesktop}
                    verifyReport={verifyReport}
                    steps={steps}
                    data={data}
                    gig={gig}
                    returnToChecklist={returnToChecklist}
                    returnToGo={returnToGo}
                    checkStepsOn={checkStepsOn}
                    gigRefetch={gigRefetch}
                    handleAnswerChanged={this.handleAnswerChanged}
                    save={this.save}
                    activeStepKey={rs.key}
                    setExpensesDirty={expensesDirty => this.setState(p => ({ ...p, expensesDirty }))}
                    expensesDirty={this.state.expensesDirty}
                  />
                ) : null}
              </StepItem>
            </>
          );
        });

    const isCheckedOut = gig && !!gig.checkoutStatus;

    const hasExpenses = gig?.expenses?.length > 0;
    const talentExpensesEnabled = gig?.talentExpenses;

    // TODO check this type
    let footer: any = null;

    if (verifyReport) {
      footer = onSaveAndClose && (
        <ActionFooter
          className={styles.actionFooter}
          title={"Save & Close"}
          disabled={this.state.saveState === "saving"}
          onAction={async () => {
            await this.save();
            onSaveAndClose();
          }}
        />
      );
    } else if (isCheckedIn || !checkStepsOn) {
      footer = gig && !FINAL_REPORT_STATES.includes(gig.workflowStateKey) && (
        <ActionFooter
          title={
            !allDone
              ? "Save Changes"
              : gig.workflowStateKey === "reportDraft" || (gig.workflowStateKey === "upcoming" && !checkStepsOn)
              ? "Submit my report"
              : "Update my report"
          }
          note={
            allDone
              ? "Your manager will be alerted to review your report."
              : "Don't forget to scroll. Please complete all sections."
          }
          errorMessage={
            submitState === "error" ? (
              <Text.Message kind={"error"} className={styles.submitError}>
                We couldn't submit your report. Try again later.
              </Text.Message>
            ) : null
          }
          disabled={submitState === "submitting" || (!allDone && !this.reportHasUnsavedChanges())}
          onAction={!allDone && this.reportHasUnsavedChanges() ? this.debouncedSave : this.handleSubmit}
          testId={"gogetter.report.actionButton"}
        />
      );
    }

    let checkOutTitle = isCheckedOut ? "Checked out!" : "Check out";

    return (
      <Desktop>
        {isDesktop => {
          return (
            <>
              <div
                className={cx(
                  styles.topStatus,
                  gig && FINAL_REPORT_STATES.includes(gig.workflowStateKey) && verifyReport && styles.topStatusMargin,
                )}
                data-test={"report.formState"}
              >
                <Icon src={formStatusIcon} fill={variables.gray1} size={24} />
                <Text.Display5 className={styles.formStatus}>{stateText}</Text.Display5>
              </div>

              <div className={cx(styles.reportColumns, verifyReport && styles.verifyReportColumns)}>
                {(isExact || isDesktop) && (
                  <ListView className={cx(isDesktop && !verifyReport && styles.stepList)}>
                    {!verifyReport && checkStepsOn && (
                      <StepItem
                        active={location.pathname.includes("/checkIn")}
                        title={isCheckedIn ? "Checked in!" : "Check in"}
                        subtitle={
                          gig && isCheckedIn
                            ? gig?.programRole?.role?.hasLocation
                              ? `${this.formatDistance(gig.checkinStatus!)} miles away at ${this.formatTime(
                                  gig.checkinStatus!,
                                )}`
                              : ""
                            : "Check in to unlock report"
                        }
                        progress={isCheckedIn ? 100 : 0}
                        onClick={isCheckedIn ? undefined : goTo("checkIn")}
                        red={!isCheckedIn}
                        testId="gogetter.report.checkIn"
                      />
                    )}

                    {qSteps(isDesktop) || (
                      <LoadingContainer message={"Loading report steps..."} center className={styles.loadingSteps} />
                    )}

                    {gig?.programRole?.allowExpenses && isPinata && (
                      <StepItem
                        title="Expenses"
                        key="expense"
                        progress={(hasExpenses || !talentExpensesEnabled) && !this.state.expensesDirty ? 100 : 0}
                        onClick={goTo("expenses")}
                        active={location.pathname.includes("/expenses")}
                      />
                    )}

                    {!verifyReport && checkStepsOn && (
                      <StepItem
                        active={location.pathname.includes("/checkOut")}
                        title={checkOutTitle}
                        subtitle={
                          isCheckedOut
                            ? gig?.programRole?.role?.hasLocation
                              ? `${this.formatDistance(
                                  gig.checkoutStatus!,
                                )} miles from task location at ${this.formatTime(gig.checkoutStatus!)}`
                              : ""
                            : "Do this when you're leaving the task venue"
                        }
                        progress={isCheckedOut ? 100 : 0}
                        onClick={goTo("checkOut")}
                        testId="gogetter.report.checkOut"
                      />
                    )}
                  </ListView>
                )}

                {verifyReport ? footer : <FooterDistance distance={0}>{footer}</FooterDistance>}
                {!verifyReport ? (
                  <ViewStep
                    isDesktop={isDesktop}
                    verifyReport={verifyReport}
                    steps={steps}
                    data={data}
                    gig={gig}
                    returnToChecklist={returnToChecklist}
                    returnToGo={returnToGo}
                    checkStepsOn={checkStepsOn}
                    gigRefetch={gigRefetch}
                    handleAnswerChanged={this.handleAnswerChanged}
                    save={this.save}
                    setExpensesDirty={expensesDirty => this.setState(p => ({ ...p, expensesDirty }))}
                    expensesDirty={this.state.expensesDirty}
                  />
                ) : null}
              </div>
            </>
          );
        }}
      </Desktop>
    );
  }

  public render() {
    const { anyError } = this.state;
    const { verifyReport } = this.props;

    return (
      <>
        <div className={cx(styles.fieldReport, verifyReport && styles.noPaddingReport)}>
          <div className={anyError ? styles.visibleErrorBanner : styles.errorBanner}>
            <Text.Display5 kind={"reverse"} className={styles.errorTitle}>
              Your report has not been saved.
            </Text.Display5>
            <Text.P4 kind={"reverse"}>
              Keep working — changes are stored temporarily on your device. Save again when you have better service.
            </Text.P4>
          </div>

          {this.renderContent()}
        </div>
        <div className={styles.iOSReport} />
      </>
    );
  }
}

interface ViewStep {
  isDesktop: boolean;
  verifyReport: boolean | undefined;
  steps: StepStates | null;
  data: Data;
  gig: GigFull;
  returnToChecklist: () => void;
  returnToGo: () => void;
  checkStepsOn: boolean;
  gigRefetch: () => Promise<void>;
  handleAnswerChanged: (stepKey: string) => (qId: string, answer: GigReportAnswer<{}>, debounce: boolean) => void;
  save: () => Promise<void>;
  activeStepKey?: string;
  setExpensesDirty: (dirty: boolean) => void;
  expensesDirty: boolean;
}

function ViewStep(props: ViewStep) {
  const {
    isDesktop,
    verifyReport,
    steps,
    data,
    gig,
    returnToChecklist,
    returnToGo,
    checkStepsOn,
    gigRefetch,
    handleAnswerChanged,
    save,
    activeStepKey,
    setExpensesDirty,
    expensesDirty,
  } = props;

  const {
    history,
    match: { path, isExact, url: checklistUrl },
    location,
  } = useRouter();

  return (
    <div className={cx(isDesktop && !verifyReport && styles.stepRender, !isExact && styles.flexStep)}>
      {!steps || !data || !data.gigReportSteps || !gig ? (
        <LoadingContainer message={"Loading report steps..."} center className={styles.loadingSteps} />
      ) : (
        <Switch>
          <Route
            exact
            path={path + "/"}
            render={() =>
              isDesktop && !verifyReport ? (
                gig.checkinStatus || !checkStepsOn ? (
                  <div className={styles.defaultStep}>
                    <Text.Display2>Select a report step on the left</Text.Display2>
                    <Text.P4>Remember to submit your report when you’re finished!</Text.P4>
                  </div>
                ) : (
                  <CheckInOut gig={gig} returnToChecklist={returnToChecklist} showHeader={isDesktop} />
                )
              ) : null
            }
          />

          {gig && checkStepsOn && (
            <Route
              path={path + "/checkOut"}
              render={() => (
                <CheckInOut
                  gig={gig}
                  returnToChecklist={returnToChecklist}
                  onCancel={returnToGo}
                  checkout
                  showHeader={isDesktop}
                />
              )}
            />
          )}

          {gig && checkStepsOn && (
            <Route
              path={path + "/checkIn"}
              render={() => (
                <CheckInOut
                  gig={gig}
                  returnToChecklist={returnToChecklist}
                  onCancel={returnToGo}
                  showHeader={isDesktop}
                />
              )}
            />
          )}

          {gig.programRole?.allowExpenses && (
            <Route
              path={path + "/expenses"}
              render={() => (
                <Expenses
                  gigId={gig.id}
                  expensesDirty={expensesDirty}
                  setExpensesDirty={setExpensesDirty}
                  returnToChecklist={returnToChecklist}
                  gigRefetch={gigRefetch}
                />
              )}
            />
          )}

          <Redirect
            path={path + "/startStep"}
            to={{
              ...location,
              pathname: checklistUrl + (gig && checkStepsOn ? "/checkIn" : "/" + data.gigReportSteps?.[0]?.key),
            }}
          />

          <Route
            path={`${path}/:stepKey`}
            render={({
              match: {
                params: { stepKey },
              },
            }) => {
              const gigReportStep = data.gigReportSteps.find(rs => rs.key === stepKey);
              const stepState = steps && steps[stepKey || ""];

              if (!gigReportStep || !stepState || !stepKey || !stepState.questions) {
                return <div>Step not found</div>;
              }
              if (activeStepKey !== stepKey && isDesktop && verifyReport) {
                return null;
              }

              const getNextStep = () => {
                const currentIndex = data.gigReportSteps.findIndex(rs => rs.key === stepKey);
                //there's no regular next step
                if (currentIndex === -1 || currentIndex === data.gigReportSteps.length - 1) {
                  if (gig.programRole?.allowExpenses) {
                    return { key: "expenses" };
                  }
                  return null;
                }
                return data.gigReportSteps[currentIndex + 1];
              };

              return (
                <>
                  <StepForm
                    gig={gig}
                    step={stepState}
                    disabled={!gig.checkinStatus && !verifyReport && checkStepsOn}
                    setAnswer={handleAnswerChanged(stepKey)}
                    returnToChecklist={async (goToNext: boolean = false) => {
                      try {
                        await save();
                      } catch (e) {
                        console.error(e);
                      } finally {
                        const nextStep = getNextStep();
                        const nextUrl = goToNext && nextStep ? `${checklistUrl}/${nextStep.key}` : checklistUrl;
                        history.push({ ...location, pathname: nextUrl });
                        if (nextStep && goToNext) {
                          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                        }
                      }
                    }}
                    verifyReport={verifyReport}
                    goToCheckin={() => history.push({ ...location, pathname: `checkIn` })}
                    isMobile={!isDesktop}
                  />
                </>
              );
            }}
          />
        </Switch>
      )}
    </div>
  );
}

export default compose(
  graphql(saveStep, {
    props: ({ mutate }) => ({
      saveStep: mutate,
    }),
  }),
  graphql<any, any, any, any>(submitReport, {
    props: ({ mutate }) => {
      return {
        submitReport: mutate,
      };
    },
  }),

  graphql<any, any, any, any>(reportSteps, {
    skip: ({ gig }: Props) => !gig,
    options: ({ gig, gigId }: Props & { gigId?: string }) => ({
      variables: {
        gigId: gig ? gig.id : gigId,
      },

      fetchPolicy: "cache-and-network",
    }),
  }),
)(Report);
