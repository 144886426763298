import * as React from "react";

interface State {
  currentNumber: number;
  previousNumber: number | null;
}

interface Props {
  value: number | null;
  duration: number;
  children: (current: number, previous: number | null) => React.ReactNode;
  renderAnyway?: boolean;
}

const MIN_TICK = 4; // Minimum significant value for window.setInterval

export default class NumberEasing extends React.Component<Props, State> {
  readonly state: State = {
    currentNumber: 0,
    previousNumber: null,
  };

  private interval?: number;
  public static defaultProps = {
    duration: 1000,
  };

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { value: newValue, duration } = nextProps;

    if (newValue !== this.props.value || duration !== this.props.duration) {
      if (this.props.value === null) {
        this.setIntialValue(newValue);
      } else {
        this.animate(newValue, duration);
      }
    }
  }

  public componentDidMount() {
    this.setIntialValue();
  }

  public componentWillUnmount() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  public setIntialValue(value: number | null = this.props.value) {
    this.setState({ currentNumber: value || 0 });
  }

  public shouldComponentUpdate({ renderAnyway }: Props, { currentNumber }: State) {
    return renderAnyway || currentNumber !== this.state.currentNumber;
  }

  public animate(newVal: number | null, duration: number) {
    const newValue = newVal || 0;

    let number = this.state.currentNumber;

    if (number === newValue) {
      return;
    }

    if (this.interval) {
      window.clearInterval(this.interval);
    }

    const difference = Math.abs(number - newValue);
    const up = newValue > number;
    let tick = Math.floor(duration / difference);

    let step = 1;

    if (tick < MIN_TICK) {
      step = Math.floor((MIN_TICK * difference) / duration);
      tick = MIN_TICK;
    }

    this.interval = window.setInterval(() => {
      const previousNumber = number;

      if (up) {
        if (number < newValue) {
          number += step;
        } else {
          number = newValue;
        }
      } else {
        if (number > newValue) {
          number -= step;
        } else {
          number = newValue;
        }
      }

      this.setState({ currentNumber: number, previousNumber });

      if (number === newValue) {
        window.clearInterval(this.interval);
      }
    }, tick);
  }

  public render() {
    const { children } = this.props;
    const { currentNumber, previousNumber } = this.state;

    return children(currentNumber, previousNumber);
  }
}
