import React from "react";
import { ICell, Item } from "modules/Connection/types";
import { CellBase, CR1 } from "modules/Connection/CellBase";
import icon from "assets/report.svg";

export const ReportCell: ICell<Item> = {
  component: props => {
    return <CellBase {...props}>{() => <CR1>Report</CR1>}</CellBase>;
  },
  icon,
};
