import React from "react";
import cx from "classnames";
import Text from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";
import { range, chunk } from "lodash";

interface YearProps {
  year: number;
  onPick?: (year: number) => void;
}

export const YEARS_PER_PAGE = 12;

export function YearsView({ year, onPick }: YearProps) {
  return (
    <div className={styles.calendar}>
      {chunk(range(year, year + YEARS_PER_PAGE), 3).map((row, index) => (
        <div key={index} className={styles.row}>
          {row.map(num => {
            const active = num === year;

            return (
              <Clickable
                key={num}
                className={cx(styles.cell, active && styles.active)}
                aria-selected={active}
                onClick={onPick && (() => onPick(num))}
                testId={`createGig.date.calendar.selectYear.${num}`}
              >
                <Text font={"wes"} color={active ? "white" : "gray2"} size={10}>
                  {num}
                </Text>
              </Clickable>
            );
          })}
        </div>
      ))}
    </div>
  );
}
