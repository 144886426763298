import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import moment from "moment-timezone";
import cx from "classnames";
import Text from "components/LegacyText";
import FooterDistance from "components/FooterDistance";
import { earthRadiusMi, getDistance, getPosition } from "utilities/getPosition";
import { getGoogleMapsUrl } from "modules/Dashboard/Schema/Gigs/locations";
import { formatDistance } from "utilities/string";
import { GigFull } from "interfaces/Gig";
import ActionFooter from "../../../components/ActionFooter";
import StepItem from "../StepItem";
import checkIn from "./checkIn.gql";
import checkOut from "./checkOut.gql";
import styles from "./styles.scss";
import { Desktop } from "components/Layout/Responsive";
import { RouterProps, withRouter } from "react-router";
import { isVirtualLocation } from "utilities/location";

interface Props {
  gig: GigFull;
  returnToChecklist: () => void;
  onCancel: () => void;
  mutate: (param0: {}) => Promise<{}>;
  checkout: boolean;
}

interface State {
  distance: string;
  latitude: number | null;
  longitude: number | null;
  status?: string;
}

class CheckInOut extends React.Component<Props & RouterProps, State> {
  readonly state: State = {
    distance: "-",
    latitude: null,
    longitude: null,
  };

  public componentDidMount() {
    this.updateLocation();
  }

  public async updateLocation() {
    const { location: gigLocation } = this.props.gig;

    if (!gigLocation || isVirtualLocation(gigLocation)) {
      return;
    }

    const {
      coords: { longitude, latitude },
    } = await getPosition(
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },

      true,
    );

    const checkinDistance = getDistance(
      gigLocation.latitude,
      gigLocation.longitude,
      latitude,
      longitude,
      earthRadiusMi,
    );

    this.setState({
      distance: formatDistance(checkinDistance),
      latitude: latitude,
      longitude: longitude,
    });
  }

  public timeToGig(startTime: string, endTime: string) {
    const now = moment();
    const gigTime = moment(this.props.checkout ? endTime : startTime);
    const past = gigTime.isBefore(now);

    const timeToGig = moment.duration(past ? now.diff(gigTime) : gigTime.diff(now));

    if (timeToGig.asHours() > 72) {
      return { time: timeToGig.asDays(), unit: "days", past };
    } else if (1 < timeToGig.asHours() && timeToGig.asHours() < 24) {
      return { time: timeToGig.asHours(), unit: "hours", past };
    } else {
      return { time: timeToGig.asMinutes(), unit: "minutes", past };
    }
  }
  public checkIn = async () => {
    const {
      mutate,
      gig: { id },
    } = this.props;

    try {
      await mutate({
        mutation: this.props.checkout ? checkOut : checkIn,
        variables: {
          gigId: id,
          latitude: this.state.latitude?.toString(),
          longitude: this.state.longitude?.toString(),
        },
      });

      this.props.returnToChecklist();
    } catch (error) {
      console.log(error);
    }
  };

  public checkCopy() {
    const {
      gig: { checkinStatus, checkoutStatus },
      checkout,
    } = this.props;
    if (!checkout) {
      return checkinStatus ? "Checked In!" : "Check In";
    } else {
      return checkoutStatus ? "Checked Out!" : "Check Out";
    }
  }

  public checkInOutAction() {
    const {
      gig: { checkinStatus, checkoutStatus, startTime, windowStartTime, location: gigLocation },
      checkout,
      onCancel,
    } = this.props;

    const checkInAvailable = startTime
      ? moment(startTime).diff(moment(), "hours") <= 3
      : moment().isAfter(windowStartTime);

    const gigWithLocation = !this.state.latitude && !this.state.longitude && !isVirtualLocation(gigLocation);

    if ((checkout && !checkoutStatus) || !checkinStatus) {
      const status = checkout ? "out" : "in";
      let checkOutTitle = `Confirm check ${status}`;

      return (
        <FooterDistance distance={0} className={styles.alignLeft}>
          <ActionFooter
            title={checkOutTitle}
            disabled={gigWithLocation || (status === "in" && !checkInAvailable)}
            onAction={() => {
              this.setState({ status: `checking ${status}` });
              this.checkIn();
            }}
            onCancel={onCancel}
            testId={"gogetter.checkInOut.action"}
            className={styles.footerHeight}
          />
        </FooterDistance>
      );
    }
  }

  public render() {
    if (!this.props.gig) {
      return <h1>loading</h1>;
    }

    const {
      gig: { startTime, timezone, checkinStatus, checkoutStatus, organizationLocation, location, windowEndTime },
      checkout,
    } = this.props;

    const [first, ...rest] = location ? location.address.split(", ") : "";

    return (
      <div className={styles.step}>
        <Desktop>
          {isDesktop =>
            isDesktop ? null : (
              <StepItem
                title={this.checkCopy()}
                red={!checkout && !checkinStatus}
                progress={checkout ? (checkoutStatus ? 100 : 0) : checkinStatus ? 100 : 0}
                open
                onClick={this.props.returnToChecklist}
              />
            )
          }
        </Desktop>

        <div
          className={styles.container}
          style={{
            background: `linear-gradient(0deg,rgba(39, 47, 63, 0.85),rgba(39, 47, 63, 0.85)),url(https://maps.googleapis.com/maps/api/staticmap?center=${location.latitude},${location.longitude}&zoom=15&size=600x600&key=AIzaSyBfNJaJoX6eIqKDiRgQUOGsyM-iL7kS2Gs)`,
          }}
        >
          {checkout ? (
            <React.Fragment>
              <Text.Display3 kind="reverse" className={styles.checkInTime}>
                Check out to complete your time on site
              </Text.Display3>
              <Text.P4 kind="reverse" className={cx(styles.center, styles.checkOutFooter)}>
                You’ll still be able to edit all other answers before you submit this report for review.
              </Text.P4>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Text.Display3 kind="green" className={styles.checkInTime}>
                {"Check in by "}
                {startTime
                  ? moment
                      .tz(startTime, timezone)
                      .subtract(5, "minutes")
                      .format("h:mma")
                  : windowEndTime
                  ? moment
                      .tz(windowEndTime, timezone)
                      .subtract(5, "minutes")
                      .format("MM/DD/YYYY")
                  : null}
              </Text.Display3>
              <Text.Display4 kind="reverse" className={styles.orgName}>
                {organizationLocation ? organizationLocation.name : location?.name?.split(", ")[0]}
              </Text.Display4>
              <Text.NewLink3
                href={getGoogleMapsUrl(location.externalId, location.name, location.address)}
                target="_blank"
              >
                <Text.P kind="royal" className={cx(styles.center, styles.orgName)}>
                  {first}
                </Text.P>
                <Text.P4 kind="royal" className={cx(styles.center, styles.orgName)}>
                  {rest.join(", ")}
                </Text.P4>
              </Text.NewLink3>
            </React.Fragment>
          )}

          <div className={styles.timeAndDistance}>
            {/* <div className={styles.greenSquare} data-test="gogetter.checkInOut.distanceToGig">
              <Text.Display kind="reverse">{this.state.distance}</Text.Display>
              <Text.Display4 kind="reverse">miles</Text.Display4>
              <Text.Display5 kind="reverse" className={styles.smallerText}>
                from venue
              </Text.Display5>
            </div> */}
            <Text.P kind="royal" className={cx(styles.center, styles.proximityDesc)}>
              {"Proximity recorded at check in & check out"}
            </Text.P>
          </div>
          {organizationLocation && organizationLocation.contacts && organizationLocation.contacts.length && (
            <React.Fragment>
              <Text.Display5>VENUE CONTACT</Text.Display5>
              <Text.P2>{organizationLocation.contacts[0].name}</Text.P2>
            </React.Fragment>
          )}

          {this.checkInOutAction()}
        </div>
      </div>
    );
  }
}

export default compose(graphql(checkIn), withRouter)(CheckInOut);
