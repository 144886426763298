import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ApolloProvider, ApolloLink } from "@apollo/client";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { withRouter, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { getAppUrl } from "utilities/routes";

import "styles/index.scss";

import { usesHashRouter } from "utilities/environment";
import { getJwt } from "utilities/authentication";
import urlRedirect from "utilities/urlRedirect";
import EnvIndicator from "components/EnvIndicator";
import PrivateRoute from "components/PrivateRoute";
import UserNoOrgPage from "components/UserNoOrgPage";
import { handleStandaloneLocation } from "utilities/standalone";
import { redirectIfNotLoggedIn } from "utilities/authentication";
import { getEnvironment } from "utilities/environment";
import { Rollbar } from "utilities/rollbar";
import { ConnectionReloadProvider } from "modules/Connection/Reloader";
import LoadingContainer from "components/LoadingContainer";

import { UserInfoQuery, withUserInfoNoCache } from "./UserInfo";
import Downloads from "./Downloads";
import Organization from "./Organization";
import styles from "./dashboard.scss";
import i18n from "./i18n";
import { createClient } from "../../apollo";
import { getIntercomProps } from "utilities/intercom";

// We need this to manage staging routes
urlRedirect();

function getProgramIds(): string | null {
  //match on programs/all and programs/{uuid,uuid,uuid}
  const regexp = /(programs\/(all|[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12},?)*)/g;
  const split = window.location.href.split(regexp);
  if (split.length > 1) {
    return split[1].split("/")[1];
  } else {
    return null;
  }
}

function redirectOldUrlScheme() {
  const programIds = getProgramIds();
  if (programIds) {
    const noIds = window.location.href.replace(`/${programIds}`, "");
    const newUrl = `${noIds}?program=${programIds}`;
    window.location.replace(newUrl);
  }
}

const matchOrgId = usesHashRouter()
  ? /https?:\/\/[^#]+#\/([0-9a-f-]{36})(\/)?/
  : /https?:\/\/.+(?:gopinata\.com|letsgopinata\.com|gohayday\.co|gopronto\.info)\/([0-9a-f-]{36})(\/)?/;

const organizationLink = new ApolloLink((operation, forward) => {
  const match = location.href.match(matchOrgId);
  if (match && match.length > 0) {
    const context = operation.getContext();
    const headers = (context && context.headers) || {};
    if (match[1]) {
      context.headers["X-Pinata-Organization-Id"] = match[1];
    }
    operation.setContext({ headers });
  }
  return forward ? forward(operation) : null;
});

const client = createClient("nacho", getJwt, [organizationLink]);

const Router: React.ComponentType<{}> = usesHashRouter() ? HashRouter : BrowserRouter;

interface Props extends RouteComponentProps, UserInfoQuery {}

redirectOldUrlScheme();
redirectIfNotLoggedIn();

class PApp extends Component<Props> {
  public UNSAFE_componentWillMount() {
    handleStandaloneLocation(this.props.history);
  }

  public componentDidUpdate(prevProps: Props) {
    const { userInfo } = this.props;
    if (userInfo?.me?.organizationUser?.accountMandatesExclusivelyActive && !userInfo?.me?.isAdmin) {
      window.location.href = getAppUrl("theApp", userInfo?.me?.organizationUser.organizationId);
    }

    if (userInfo?.me?.id && userInfo.me.id !== prevProps.userInfo?.me?.id) {
      const { me } = userInfo;

      Rollbar?.configure({
        payload: {
          person: {
            id: me.id,
            email: me.email,
          },

          environment: getEnvironment(),
        },

        enabled: getEnvironment() !== "development",
      });

      if (me.language) {
        i18n.changeLanguage(me.language);
      }

      (window as any).Intercom?.(
        "boot",
        getIntercomProps(me, { customSelector: ".intercom-selector", hideDefaultLauncher: false }),
      );
    }
  }

  public render() {
    const {
      userInfo: { me },
      location,
    } = this.props;

    if (me && !me.firstOrganizationId) {
      return <UserNoOrgPage userInfo={me} />;
    }

    return (
      <div className={styles.dashboard}>
        <Downloads>
          <EnvIndicator />

          <ConnectionReloadProvider>
            <React.Suspense fallback={<LoadingContainer />}>
              <Switch>
                <PrivateRoute path="/:orgId" component={Organization} />
                {me && <Redirect to={{ ...location, pathname: `/${_.get(me, "firstOrganizationId")}` }} />}
              </Switch>
            </React.Suspense>
          </ConnectionReloadProvider>
        </Downloads>
      </div>
    );
  }
}

const PinataApp = compose(withRouter, withUserInfoNoCache)(PApp);

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <Router>
        <PinataApp />
      </Router>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
