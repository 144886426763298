import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DetailsModal } from "modules/Connection/DetailsModal";
import { DetailsBlock } from "modules/Connection/DetailsModal/DetailsBlock";
import { useScopeContext } from "modules/Connection/Scope";
import { useUserInfo } from "modules/Dashboard/UserInfo";

import styles from "./styles.scss";

import { gigsConnection, useGigMutation } from "../connection";
import { StatusCell } from "../Cells/Status";
import { DetailsCell } from "../Cells/Details";
import { LocationCell } from "../Cells/Location";
import { DateCell } from "../Cells/Date";
import { CompanyCell } from "../Cells/Company";
import { TalentPersonCell } from "../Cells/TalentPerson";
import { RoleCell } from "../Cells/Role";
import { ReportCell } from "../Cells/Report";
import { ReportOverviewCell } from "../Cells/ReportOverview";
import { OwnerCell } from "../Cells/Owner";
import { TrackingCell } from "../Cells/Tracking";
import { TrackingExpandedCell } from "../Cells/TrackingExpanded";
import { GigDateField } from "../Fields/Date";
import { GigLocationField } from "../Fields/Location";
import { GigPartnerField } from "../Fields/Partner";
import { GigOwnerField } from "../Fields/Owner";
import { selectGigActions } from "../Actions";
import { getStateGroupClassName } from "../stateGroups";
import { GigDetailsActions } from "./Actions";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { GigRoleField } from "../Fields/Role";
import { GigProgramField } from "../Fields/Program";
import { RoleOverviewCell } from "../Cells/RoleOverview";
import { DetailsExpandedCell } from "../Cells/DetailsExpanded";
import { getGigErrorMessage } from "../errors";
import { AttachmentsCell } from "../Cells/Attachments";
import { AttachmentsOverviewCell } from "../Cells/AttachmentsOverview";
import { GigTitleField } from "../Fields/Title";
import { GigDescriptionField } from "../Fields/Description";
import { GigExpenseNotesField } from "../Fields/ExpenseNotes";
import { GigAgencyGuidanceField } from "../Fields/AgencyGuidance";
import { GigProductField } from "../Fields/Product";
import { GigAssignmentFormProvider } from "../Fields/Assignment";
import { BillingCell } from "../Cells/Billing";
import { BillingOverviewCell } from "../Cells/BillingOverview";
import { isNoLocation, isVirtualLocation } from "utilities/location";

export function GigDetailsModal({
  location,
  history,
  match: {
    url,
    params: { gigId },
  },
}: RouteComponentProps<ProgramsRouteParams & { gigId?: string }>) {
  const { saveGigs, loading } = useGigMutation();
  const { t } = useTranslation();

  const handleClose = () => {
    history.push({ ...location, pathname: url.replace(`/+view/${gigId}`, "") });
  };

  return (
    <DetailsModal
      ids={gigId ? [gigId] : []}
      connection={gigsConnection}
      variables={{}}
      actions={selectGigActions}
      onSave={saveGigs}
      onClose={handleClose}
      getErrorMessage={getGigErrorMessage}
      loading={loading}
    >
      <div className={styles.detailBlocks}>
        <DetailsBlock id={"status"} cell={StatusCell} em header getClassName={data => getStateGroupClassName(data)} />
        <DetailsBlock
          id={"details"}
          cell={DetailsCell}
          forceScroll={true}
          form={[
            GigProgramField,
            GigProductField,
            GigTitleField,
            GigDescriptionField,
            GigExpenseNotesField,
            GigAgencyGuidanceField,
          ]}
          formClassName={styles.detailsForm}
          em
          className={styles.start}
          expand={{
            label: t("gigs.details.expandDetails"),
            cell: DetailsExpandedCell,
          }}
        />

        <div className={styles.grid}>
          <DetailsBlock id={"date"} cell={DateCell} form={GigDateField} />
          <DetailsBlock
            id={"location"}
            cell={LocationCell}
            form={GigLocationField}
            isEditable={data => !isNoLocation(data?.location ?? undefined)}
          />
          <DetailsBlock id={"company"} cell={CompanyCell} form={GigPartnerField} />
          <DetailsBlock id={"talentPerson"} cell={TalentPersonCell} form={GigAssignmentFormProvider} />
        </div>

        <DetailsBlock
          id={"role"}
          cell={RoleCell}
          form={GigRoleField}
          expand={{
            label: t("gigs.details.expandRole"),
            cell: RoleOverviewCell,
          }}
        />
        <TrackingBlock />
        <DetailsBlock
          id={"report"}
          cell={ReportCell}
          expand={{ label: t("gigs.details.expandReport"), cell: ReportOverviewCell }}
        />
        <DetailsBlock
          id={"attachments"}
          cell={AttachmentsCell}
          expand={{ label: t("gigs.details.attachments"), cell: AttachmentsOverviewCell }}
        />
        <DetailsBlock
          id={"billing"}
          cell={BillingCell}
          expand={{ label: t("gigs.details.billing"), cell: BillingOverviewCell }}
        />
        <DetailsBlock id={"contact"} cell={OwnerCell} form={GigOwnerField} />
      </div>

      <GigDetailsActions onClose={handleClose} onPrint={() => window.print()} gigId={gigId} />
    </DetailsModal>
  );
}

export function TrackingBlock({ showTracker = false }) {
  const { nodes } = useScopeContext();
  const orgId = nodes && nodes.length && nodes[0] && nodes[0].talentOrganization && nodes[0].talentOrganization.id;
  const { t } = useTranslation();
  const { me } = useUserInfo();
  if ((me && me.organizationUser.organizationId === orgId) || showTracker) {
    return (
      <DetailsBlock
        id={"tracking"}
        cell={TrackingCell}
        expand={{ label: t("gigs.details.expandTracking"), cell: TrackingExpandedCell }}
      />
    );
  }
  return null;
}

export default GigDetailsModal;
