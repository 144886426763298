export const spacing = {
  xxs: "2px",
  xs: "5px",
  s: "10px",
  m: "15px",
  l: "20px",
  xl: "25px",
  xxl: "30px",
  xxxl: "35px",
};

export const colors = {
  gray1: "#272f3f",
  gray2: "#424a5c",
  gray3: "#626c83",
  gray4: "#b1b5c1",
  gray5: "#f3f6fa",
  blue1: "#3a59a1",
  blue2: "#60b7f9",
  blue3: "#c6e0fc",
  blue4: "#e2effd",
  pink1: "#eb356c",
  pink2: "#fdeaf0",
  teal1: "#4dd9b1",
  teal2: "#ebfbf7",
  purple: "#635070",
  green: "#749379",
  white: "#fff",
  black: "#000",
  darkgray: "#434a5c",
  haydayLogo: "#f4a41d",
};

export default {
  wesFYBlack: '"WesFy-Black",sans-serif',
  wesFYRegular: '"WesFy-Regular",sans-serif',
  wesFYBold: '"WesFy-Bold",sans-serif',
  lato: '"Lato",sans-serif',
  latoBold: '"Lato-Bold",sans-serif',
  latoBoldItalic: '"Lato-BoldItalic",sans-serif',
  latoHeavy: '"Lato-Heavy",sans-serif',
  xxs: "2px",
  xs: "5px",
  s: "10px",
  m: "15px",
  l: "20px",
  xl: "25px",
  xxl: "30px",
  xxxl: "35px",
  spacingGutterDefault: "38px",
  spacingGutterMobile: "15px",
  modalSpacing: "25px",
  vModalSpacing: "35px",
  headerSize: "82px",
  formElTopPadding: "15px",
  formElSidePadding: "15px",
  borderRadius: "3px",
  borderRadiusSmall: "2px",
  zIndexBase: "0",
  gray1: "#272f3f",
  gray2: "#424a5c",
  gray3: "#626c83",
  gray4: "#b1b5c1",
  gray5: "#f3f6fa",
  blue1: "#3a59a1",
  blue2: "#60b7f9",
  blue3: "#c6e0fc",
  blue4: "#e2effd",
  pink1: "#eb356c",
  pink2: "#fdeaf0",
  teal1: "#4dd9b1",
  teal2: "#ebfbf7",
  purple: "#635070",
  green: "#749379",
  white: "#fff",
  black: "#000",
  darkgray: "#434a5c",
  haydayLogo: "#f4a41d",
  shadow: "0 1px 2px 0 rgba(0,0,0,.1)",
  borderLight: "1px solid #ecf1f7",
  borderMedium: "1px solid #f3f6fa",
  borderMediumThick: "2px solid #626c83",
  borderDark: "1px solid #626c83",
  borderDarkest: "1px solid #272f3f",
  draftGradient:
    "rgba(238,238,238,.9) repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(0, 0, 0, 0.05) 4px, rgba(0, 0, 0, 0.05) 5px)",
  sideBarGradientLeft: "linear-gradient(to left, #eff2fa 0%, rgba(255, 255, 255, 0.001) 100%)",
  sideBarGradientRight: "linear-gradient(to right, #eff2fa 0%, rgba(255, 255, 255, 0.001) 100%)",
  barGradientUpDown: "linear-gradient(to bottom, #686f80, #5e6577, #545c6f, #4a5266, #40495e)",
  tinyMobile: "321px",
  tablet: "540px",
  desktop: "768px",
  narrowContainerWidth: "640px",
  baseDuration: "200ms",
  transitionDuration: "300ms",
  baseTiming: "ease",
};
