import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  labelText?: string;
  checked: boolean;
  onChange: () => void;
  className?: string;
  labelClassName?: string;
  sliderClassName?: string;
  labelTextClassName?: string;
  id: string;
  testId?: string;
}

const Switch = ({
  labelText,
  checked,
  onChange,
  className,
  labelClassName,
  sliderClassName,
  id,
  testId,
  labelTextClassName,
}: Props) => {
  return (
    <div className={cx(styles.container, className)} data-test={testId}>
      <p className={cx(styles.labelText, labelTextClassName)}>{labelText}</p>
      <label className={cx(styles.switch, labelClassName)} htmlFor={id}>
        <input type="checkbox" id={id} onChange={() => onChange()} checked={checked} />
        <div className={cx(styles.slider, styles.round, sliderClassName)} />
      </label>
    </div>
  );
};

export default Switch;
