import { Rollbar } from "./rollbar";

const MAPS_API_KEY = "AIzaSyA0-9NlWVN2RWud8zAam1BtUKyQQ0huDmU";

interface GoogleCoords {
  accuracy: number;
  location: {
    lat: number;
    lng: number;
  };
}

function getGoogleGeolocation(): Promise<GoogleCoords> {
  return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${MAPS_API_KEY}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ operationType: null }),
  }).then(response => response.json());
}

function getPositionFallback(): Promise<Position> {
  Rollbar?.info("Using location fallback");

  return getGoogleGeolocation()
    .then(coords => ({
      timestamp: Date.now(),
      coords: {
        latitude: coords.location.lat,
        longitude: coords.location.lng,
      },

      fallback: true,
    }))
    .catch(error => {
      Rollbar.error("Location Fallback error", error);
      return error;
    });
}

interface Position extends GeolocationPosition {
  fallback?: boolean;
}

export function getPosition(options?: PositionOptions, useFallback?: boolean): Promise<Position> {
  return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position);
        },
        useFallback ? () => getPositionFallback().then(resolve).catch(reject) : reject,
        options,
      );
    } catch (e) {
      Rollbar.warning(e);
      reject(e);
    }
  });
}

export const earthRadiusMi = 3950;
let radFactor: number = Math.PI / 180;

export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number, radius: number) {
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  return 2 * radius * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

function deg2rad(deg: number) {
  return deg * radFactor;
}
