import React from "react";
import useRouter from "use-react-router";

import { FilterDefinition } from "modules/Connection/types";
import { IdsFilterState, makeIdsFilterState, IdsFilterOptions } from "modules/Connection/Filters/idsFilter";
import { ListFilter, ListFilterProps } from "modules/Connection/Filters/ListFilter";
import { SearchableItem } from "components/Searchable";
import { SrcConnection, ConnectionConfig, ConnectionVars } from "utilities/connections";
import { getProgramIdFromSearch } from "utilities/routes";

import { ProgramsRouteParams } from "../../../Organization/Programs";

export const programListFilterVars: ProgramFilterVars = {
  search: "String",
  programIds: ["ID"],
} as const;

export type ProgramFilterVars = {
  search: "String";
  programIds: readonly ["ID"];
};

export function makeProgramListFilter<
  Node extends SearchableItem,
  CC extends ConnectionConfig<Node, ConnectionVars & ProgramFilterVars> = ConnectionConfig<
    Node,
    ConnectionVars & ProgramFilterVars
  >
>(
  config: {
    connection: SrcConnection<CC>["connection"];
    variables?: SrcConnection<CC>["variables"];
    testId?: string;
  } & IdsFilterOptions &
    Pick<ListFilterProps<Node, CC>, "renderName" | "fragment">,
): FilterDefinition<IdsFilterState> {
  const { connection, renderName, fragment, variables, testId } = config;
  return {
    component: ({ state, setState, mode }) => {
      const { location } = useRouter<ProgramsRouteParams>();
      const id = getProgramIdFromSearch(location.search);

      return (
        <ListFilter<Node, CC>
          connection={connection}
          variables={{ ...variables, search: "", programIds: id === "all" ? [] : id.split(",") }}
          value={state.ids}
          onChange={ids => setState({ ids })}
          renderName={renderName}
          fragment={fragment}
          mode={mode}
          testId={testId}
        />
      );
    },

    ...makeIdsFilterState(config),
  };
}
