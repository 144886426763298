import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Text from "components/Text";
import Clickable from "components/Clickable";
import styles from "./styles.scss";

interface YearProps {
  year: number;
  value?: Date | null;
  onChange?: (d: Date) => void;
}

export function YearView({ year, value, onChange }: YearProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.calendar}>
      {[0, 1, 2, 3].map(row => (
        <div key={row} className={styles.row}>
          {[0, 1, 2].map(month => {
            const num = row * 3 + month;
            const active = value && num === value.getMonth();

            return (
              <Clickable
                key={num}
                className={cx(styles.cell, active && styles.active)}
                aria-selected={active}
                onClick={onChange && (() => onChange(new Date(year, num, 1)))}
                testId={`createGig.date.calendar.selectMonth.${num + 1}`}
              >
                <Text font={"wes"} color={active ? "white" : "gray2"} size={10}>
                  {t(`components.calendar.months.${num + 1}.short`)}
                </Text>
              </Clickable>
            );
          })}
        </div>
      ))}
    </div>
  );
}
