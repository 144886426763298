import React from "react";
import cx from "classnames";
import Text, { TextGroup, TextProps } from "components/Text";
import { Tooltip } from "react-tippy";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";
import Money from "components/Money";
import LoadingContainer from "components/LoadingContainer";
import { format } from "date-fns";
import Thumbnail from "components/Thumbnail";
import ImageGroup from "components/ImageGroup";
import ImageOpener from "components/ImageGroup/ImageOpener";
import { GigReportExpensesQuery, Maybe } from "gql-gen";

interface ClientExpenseItem {
  type: "Talent" | "Expense";
  leftPane: {
    images?: Maybe<string>[];
    title: string;
    total: number | undefined | null;
    note: React.ReactNode;
  };
  rightPane: {
    title?: string;
    person?: {
      id: Maybe<string> | undefined;
      firstName: Maybe<string> | undefined;
      lastName?: Maybe<string> | undefined;
    };
    date: Maybe<string> | undefined;
  };
}

function Title({ children, ...props }: TextProps) {
  return (
    <Text letterSpacing={1} color="gray1" transform="uppercase" font="wes" size={10} {...props}>
      {children}
    </Text>
  );
}

function Body({ children, ...props }: TextProps) {
  return (
    <Text color="gray1" font="lato" bold size={14} {...props}>
      {children}
    </Text>
  );
}

function Footer({ children, ...props }: TextProps) {
  return (
    <Text size={12} color="gray3" {...props}>
      {children}
    </Text>
  );
}

function Total({ label, value }: { label: string; value: number }) {
  return (
    <TextGroup top="l" className={styles.totalTalent} size={12} color="gray1">
      <Text font="lato" right="s">
        {label}
      </Text>
      <Text font="lato" bold>
        <Money value={value} />
      </Text>
    </TextGroup>
  );
}

function ClientExpenseItem({ type, leftPane, rightPane }: ClientExpenseItem) {
  return (
    <div className={styles.clientExpenseItem}>
      <ImageGroup>
        {leftPane.images && leftPane.images[0] && (
          <ImageOpener className={styles.images} src={leftPane.images[0]} caption="">
            {leftPane.images.map(img => (
              <Thumbnail size={80} image={img} />
            ))}
          </ImageOpener>
        )}
      </ImageGroup>
      <div className={styles.details}>
        <div className={styles.leftPane}>
          <Title>{leftPane.title}</Title>
          <Body>
            <Money value={leftPane.total || 0} />
          </Body>
          <Footer>{leftPane.note}</Footer>
        </div>
        <div className={cx(styles.rightPane, type !== "Talent" && styles.onlyDateRightPane)}>
          {type === "Talent" && rightPane.person && (
            <>
              <Title color="teal1">{rightPane.title}</Title>
              <Body color="blue2">
                {rightPane.person.firstName} {rightPane.person.lastName}
              </Body>
            </>
          )}
          <Footer em>{rightPane.date && format(new Date(rightPane.date), "MM/dd/yy")}</Footer>
        </div>
      </div>
    </div>
  );
}

export default function ClientBillingView({ gig }: { gig: GigReportExpensesQuery["gig"] }) {
  const { t } = useTranslation();
  if (!gig) {
    return <LoadingContainer />;
  }
  const {
    talentOrganization,
    expenses,
    managerLaborAmount,
    totalHours,
    talentOrganizationRate,
    role,
    approvalReviewedBy,
    approvalReviewedAt,
    managerExpenseAmount,
  } = gig;

  return (
    <div className={styles.billing}>
      <div className={styles.talentAndExpenses}>
        <div className={styles.talent}>
          <Text color={"gray3"} size={24} font={"wes"}>
            {t("components.detailsModal.payment")}
          </Text>
        </div>
        <hr className={styles.divider} />
        <div className={styles.paymentTitle}>
          <Text color={"gray1"} size={18} font={"wes"}>
            {t("components.detailsModal.billRate")}
          </Text>
          <Tooltip
            title={t("components.detailsModal.reviewAgencyAgreement")}
            position={"bottom"}
            trigger="click"
            html={
              <div className={styles.tooltip}>
                <Text size={14} color={"white"}>
                  {t("components.detailsModal.agencyAgreement")}
                </Text>
              </div>
            }
          >
            <Text size={14} color={"blue2"}>
              {t("components.detailsModal.reviewAgencyAgreement")}
            </Text>
          </Tooltip>
        </div>
        <ClientExpenseItem
          type="Talent"
          leftPane={{
            title: talentOrganization?.name || "",
            total: managerLaborAmount,
            note: (
              <>
                {totalHours} hrs at <Money value={talentOrganizationRate ?? 0} /> ({role?.title || ""})
              </>
            ),
          }}
          rightPane={{
            title: approvalReviewedBy ? "Approved By" : "",
            person: {
              id: approvalReviewedBy?.id,
              firstName: approvalReviewedBy?.firstName,
              lastName: approvalReviewedBy?.lastName,
            },
            date: approvalReviewedAt,
          }}
        />
        <Total value={managerLaborAmount} label={t("components.detailsModal.totalBillRate")} />
        <hr className={styles.divider} />
        <div className={styles.paymentTitle}>
          <Text color={"gray1"} size={18} font={"wes"}>
            {t("components.detailsModal.expenses")}
          </Text>
          <Tooltip
            title={"Review expense policy"}
            position={"bottom"}
            trigger="click"
            html={
              <div className={styles.tooltip}>
                <Text size={14} color={"white"}>
                  Expense policy text, do we show it like this?
                </Text>
              </div>
            }
          >
            <Text size={14} color={"blue2"}>
              {t("components.detailsModal.reviewExpensePolicy")}
            </Text>
          </Tooltip>
        </div>
        {expenses.map(expense => (
          <ClientExpenseItem
            type="Expense"
            leftPane={{
              images: expense.expense.images,
              title: t("components.detailsModal.talentExpense"),
              total: expense.chargeAmount,
              note: expense.expense.memo,
            }}
            rightPane={{
              title: "",
              date: expense.expense.createdAt,
            }}
          />
        ))}
        <Total value={managerExpenseAmount} label={t("components.detailsModal.totalExpenses")} />
      </div>
    </div>
  );
}
