import * as React from "react";
import * as yup from "yup";
import * as H from "history";
import { yupValidate } from "utilities/formik";
import { ProgramExecutionType } from "interfaces/Program";
import { StatusBarProps, ProgramFormStatus, makeStatus } from "./StatusBar";
import { makeContextHoC } from "utilities/context";
import { User } from "interfaces/user";
import { ISavedProgramRequest } from "./IProgramRequest";

// TODO: Define this properly
export type ProgramFormValues = any;

export interface ProgramFormValidation {
  [field: string]: boolean | undefined;
}

export interface ProgramFormState {
  id: string | null;
  values: ProgramFormValues;
  validation: ProgramFormValidation;
  executionType: ProgramExecutionType;
  status: StatusBarProps;
  sectionsBeingSaved: string[];
}

export type ProgramFormContext = ProgramFormState & {
  setState: (values: Partial<ProgramFormValues>, validation: Partial<ProgramFormValidation>, section?: string) => void;
  saveSection: (currentSectionId: string | null) => () => void;
  saveAll: () => Promise<void>;
  locationToSection: ((section: string) => H.Location) | null;
  setStatus: (status: ProgramFormStatus) => Promise<void>;
  canEdit: boolean;
};

export const ProgramFormCtx = React.createContext<ProgramFormContext>({
  sectionsBeingSaved: [],
  values: {},
  validation: {},
  canEdit: true,
  id: null,
  executionType: "solo",
  saveSection: cSection => () => {},
  saveAll: () => Promise.resolve(),
  setStatus: () => Promise.resolve(),
  setState: (values, validation) => {},
  locationToSection: null,
  status: makeStatus("loading"),
});

export interface ProgramFormContextWrapper {
  programForm: ProgramFormContext;
}

export const withProgramFormContext = makeContextHoC(ProgramFormCtx, "programForm");

export function validateAndNotify(
  transactionalSchema: yup.BaseSchema<any>,
  soloSchema: yup.BaseSchema<any>,
  proSchema: yup.BaseSchema<any>,
  section: string,
) {
  return async (values: Partial<ProgramFormValues>, props: ProgramFormContextWrapper) => {
    const { errors, valid } = await yupValidate(
      props.programForm.executionType === "solo"
        ? soloSchema
        : props.programForm.executionType === "pro"
        ? proSchema
        : transactionalSchema,
      values,
    );

    props.programForm.setState(values, { [section]: valid }, section);

    if (!valid) {
      return errors;
    }
  };
}

export const optionSchema = yup
  .object()
  .shape({
    key: yup.string().required().nullable(),
    description: yup.string().nullable(),
  })
  .nullable();
