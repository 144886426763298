import React from "react";
import { useTranslation } from "react-i18next";
import { GigsDetailsExpandedFragment } from "gql-gen";
import Text from "components/Text";
import icon from "assets/megaphone.svg";
import { ICell } from "modules/Connection/types";
import styles from "./styles.scss";
import fragment from "./details.gql";
import { DetailCell } from "components/DetailCell";

export const DetailsExpandedCell: ICell<GigsDetailsExpandedFragment> = {
  component: props => {
    const { t } = useTranslation();

    if (!props.data) {
      return null;
    }

    const { agencyGuidance, expenseNotes } = props.data;
    const gigExpenses = expenseNotes;
    return (
      <div className={styles.detailsExpanded}>
        <DetailCell title={t("gigs.cells.detailsExpanded.expenseNotes")}>
          {gigExpenses && gigExpenses.trim() && <Text className={styles.multiLine}>{gigExpenses}</Text>}
        </DetailCell>

        {agencyGuidance && agencyGuidance.trim() && (
          <DetailCell title={t("gigs.cells.detailsExpanded.agencyGuidance")}>
            <Text>{agencyGuidance}</Text>
          </DetailCell>
        )}
      </div>
    );
  },
  icon,
  fragment,
};
