import * as React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { MutationFunction } from "@apollo/client";
import { withRouter, RouteComponentProps } from "react-router-dom";
import _ from "lodash";

import { TagGroup } from "gql-gen";

import attachmentIcon from "assets/attachment.svg";
import editIcon from "assets/edit.svg";
import inactiveIcon from "assets/ban.svg";
import activeIcon from "assets/check.svg";

import Pill from "components/Pill";
import Table, { TableProps, SetOfIds, SelectedItem } from "components/Table";
import Cell from "components/Table/Cell";
import Row from "components/Table/Row";
import Text from "components/LegacyText";
import TableFilters from "components/TableFilters";
import Form from "components/Form";
import variables from "styles/variables";
import { IAction } from "components/Table/actions";
import TagFilter from "components/TagFilter";
import { BrandFilter } from "components/BrandsFilter";
import { CollectionContext, withCollection } from "components/ServerCollection";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import Money from "components/Money";
import { getProgramIdFromSearch } from "utilities/routes";

import styles from "./styles.scss";

import setProductActive from "./updateProduct.gql";
import productsQuery from "../LegacySettings/ProductsTable/products.gql";

import { Product } from "../../interfaces/Product";

export interface ProductsQuery {
  products: Product[];
}

export type AtomsFilter = "objects" | "notGroups";

interface Props extends RouteComponentProps<ProgramsRouteParams> {
  collection: CollectionContext<Product>;
  tableProps?: TableProps;
  children?: (cx: { collection: CollectionContext<Product> }) => React.ReactNode;
  setProductActive: MutationFunction<unknown, unknown>;
  viewOnly?: boolean;
  canToggleStatus?: boolean;
  atomsFilter?: AtomsFilter;
}

interface State {
  selectedItems: SelectedItem[];
}

const COLUMNS = [
  { label: "Product name", size: 220 },
  { label: "ID", size: 60 },
  { label: "Programs", size: 50 },
  { label: "Group", size: 100 },
  { label: "Documents Count", icon: attachmentIcon },
  { label: "Name" },
  { label: "Description" },
  { label: "Files" },
  { label: "Tags" },
  { label: "MSRP" },
];

class ProductsTable extends React.Component<Props, State> {
  state: State = { selectedItems: [] };

  private handleAction = (action: IAction, ids: SetOfIds) => {
    const {
      history,
      match: {
        url,
        params: { orgId },
      },
      setProductActive,

      location,
    } = this.props;
    const productId = ids.toArray()[0];
    // change product status to the opposite of what we are showing to the user at the moment
    switch (action.name) {
      case "edit":
        history.push({ ...location, pathname: `${url}/+edit-products/${productId}` });
        break;
      case "changeProductStatus":
        const status = this.props.collection.filters.status;

        setProductActive({
          variables: {
            input: {
              ids: ids.toArray(),
              active: status === "inactive",
            },
          },
        })
          .then(this.props.collection.refetch)
          .then(() => this.setState({ selectedItems: [] }));

        break;
      default:
        break;
    }
  };

  private getActions = () => {
    const status = this.props.collection.filters.status;

    if (this.props.viewOnly === true) {
      return [];
    }

    return [
      {
        name: "edit",
        description: "EDIT",
        color: variables.blue1,
        icon: editIcon,
        multiple: false,
      },

      ...(this.props.canToggleStatus === undefined || this.props.canToggleStatus === true
        ? [
            {
              name: "changeProductStatus",
              description: status === "active" ? "MAKE INACTIVE" : "MAKE ACTIVE",
              color: variables.blue1,
              icon: status === "active" ? inactiveIcon : activeIcon,
              multiple: true,
            },
          ]
        : []),
    ];
  };

  private renderProducts = (products: Product[]) => {
    return products.map(product => (
      <Row key={product.id} id={product.id} label={product.name}>
        <Cell size={220}>
          <Text.CellValue className={styles.productName}>{product.name}</Text.CellValue>
        </Cell>

        <Cell size={60}>
          <Text.CellValue>{product.sku}</Text.CellValue>
        </Cell>

        <Cell size={50}>
          <Text.CellValue>{product.programs.length}</Text.CellValue>
        </Cell>

        <Cell size={100}>
          <Text.CellValue className={styles.brand}>{product.brandName ?? "-"}</Text.CellValue>
        </Cell>

        {product.documents ? (
          <Cell>
            <Text.CellValue className={styles.docs}>{product.documents.length}</Text.CellValue>
          </Cell>
        ) : (
          <Cell>
            <Text.CellValue>0</Text.CellValue>
          </Cell>
        )}

        <Cell>
          <Text.CellValue>{product.name}</Text.CellValue>
        </Cell>

        <Cell>
          <Text.CellValue>{product.description}</Text.CellValue>
        </Cell>

        <Cell>
          <Text.CellValue>
            {product.documents && product.documents.length
              ? product.documents.map((document, index) => (
                  <React.Fragment key={document.id}>
                    <Text.NewLink3 href={document.url} target={"blank"}>
                      {document.name}
                    </Text.NewLink3>
                    {index + 1 < product.documents.length ? "; " : ""}
                  </React.Fragment>
                ))
              : null}
          </Text.CellValue>
        </Cell>

        <Cell>
          {product.tags.length ? (
            <div className={styles.tags}>
              {product.tags.map(tag => (
                <Pill key={tag.id}>{tag.name}</Pill>
              ))}
            </div>
          ) : (
            <Text.P4 kind={"secondary"}>None</Text.P4>
          )}
        </Cell>

        <Cell>
          <Money value={product.msrp || 0} />
        </Cell>
      </Row>
    ));
  };

  public render() {
    const {
      tableProps,
      collection,
      collection: { loading, pageItems, filters, onFilterChange, submitFilters, pagination },
      viewOnly = false,
      children,
    } = this.props;
    return (
      <React.Fragment>
        {children && children({ collection })}
        <React.Fragment>
          {!viewOnly && (
            <TableFilters onGo={submitFilters} disabledGo={loading}>
              <Form.TextBox
                label={"SEARCH"}
                placeholder={"by name, id or description"}
                onChange={onFilterChange("search")}
                value={filters.search}
              />

              <Form.Dropdown label="PRODUCT STATUS" value={filters.status} onChange={onFilterChange("status")}>
                <option value={""}>All</option>
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </Form.Dropdown>

              <TagFilter group={TagGroup.Products} onChange={onFilterChange("tagIds")} value={filters.tagIds} />

              <BrandFilter onChange={onFilterChange("brandIds")} value={filters.brandIds} />
            </TableFilters>
          )}

          <Table
            columns={COLUMNS}
            visibleColumns={4}
            actions={this.getActions()}
            selectable={true}
            rowHeight={"small"}
            onAction={this.handleAction}
            selected={this.state.selectedItems.map(item => item.id)}
            onSelectionChange={ids =>
              this.setState(oldState => {
                const currentIds = oldState.selectedItems.map(item => item.id);
                const removedIds = _.difference(currentIds, ids);

                if (removedIds.length > 0) {
                  return {
                    selectedItems: oldState.selectedItems.filter(item => !removedIds.includes(item.id)),
                  };
                }

                const addedIds = _.difference(ids, currentIds);

                if (addedIds.length > 0 && this.props.collection && this.props.collection.pageItems) {
                  return {
                    selectedItems: [
                      ...oldState.selectedItems,
                      ...this.props.collection.pageItems
                        .filter(product => addedIds.includes(product.id))
                        .map(product => ({ label: product.name, id: product.id })),
                    ],
                  };
                }

                return { selectedItems: oldState.selectedItems };
              })
            }
            displaySelected={{
              data: this.state.selectedItems,
              renderCounter: count => (count === 1 ? `One product selected` : `${count} products selected`),
            }}
            controlledPagination={pagination}
            viewOnly={viewOnly}
            {...tableProps}
          >
            {pageItems ? this.renderProducts(pageItems) : []}
          </Table>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const isObjectElementTypeKey = {
  type: "BinaryExpression",
  left: {
    type: "Identifier",
    name: "elementtypekey",
  },
  operator: "=",
  right: {
    type: "Literal",
    value: "objects",
  },
};

const isNotGroupElementTypeKey = {
  type: "BinaryExpression",
  left: {
    type: "Identifier",
    name: "elementtypekey",
  },
  operator: "!=",
  right: {
    type: "Literal",
    value: "groups",
  },
};

type AtomsKnueppelFilterAst = typeof isObjectElementTypeKey | typeof isNotGroupElementTypeKey;

const getKnueppelFilterAst = (atomsFilter: AtomsFilter): AtomsKnueppelFilterAst => {
  switch (atomsFilter) {
    case "objects":
      return isObjectElementTypeKey;
    case "notGroups":
      return isNotGroupElementTypeKey;
    default:
      return isObjectElementTypeKey;
  }
};

export default compose(
  withRouter,

  withCollection({
    query: productsQuery,
    name: "orgProducts",
    filters: {
      search: { type: "string", default: "" },
      tagIds: { type: "array", default: [] },
      brandIds: { type: "array", default: [] },
      status: { type: "string", default: "active" },
    },
    mapPropsToVariables: (props: Props) => {
      const defVars = props.atomsFilter ? { filters: getKnueppelFilterAst(props.atomsFilter) } : {};

      if (!props?.tableProps?.limitByProgram) {
        return defVars;
      }
      const id = getProgramIdFromSearch(props.location.search);

      return { ...defVars, programId: id };
    },
  }),
  graphql<any, any, any, any>(setProductActive, {
    props: ({ mutate }) => ({ setProductActive: mutate }),
  }),
)(ProductsTable);
