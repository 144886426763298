import React from "react";
import cx from "classnames";

import Icon from "components/Icon";
import searchIcon from "assets/search-solid.svg";
import { ConnectionConfig } from "utilities/connections";
import { colors } from "styles/variables";
import { Searchable, SearchableItem, SearchableProps } from "components/Searchable";
import { TableMode } from "modules/Connection/Table";

import styles from "./styles.scss";

export interface ListFilterProps<Node extends SearchableItem, CC extends ConnectionConfig<Node>>
  extends Omit<SearchableProps<Node, CC>, "children"> {
  mode: TableMode;
  testId?: string;
}

export function ListFilter<Node extends SearchableItem, CC extends ConnectionConfig<Node> = ConnectionConfig<Node>>({
  testId,
  ...searchableProps
}: ListFilterProps<Node, CC>) {
  return (
    <Searchable {...searchableProps} multiple>
      {({ inputProps, list }) => (
        <div
          className={cx(styles.listFilter, searchableProps.mode === "compact" && styles.compactListFilter)}
          data-test={testId}
        >
          <form onSubmit={e => e.preventDefault()}>
            <label className={styles.searchBox}>
              <Icon src={searchIcon} size={16} fill={colors.gray1} />
              <input {...inputProps} type="search" placeholder={"Search..."} className={styles.searchInput} />
            </label>
          </form>
          {list}
        </div>
      )}
    </Searchable>
  );
}
