import React, { useState } from "react";

import cx from "classnames";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";
import backspaceIcon from "assets/backspace.svg";
import { Column } from "modules/Connection/types";
import { ColumnHeader } from "../../Table/ColumnHeader";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import { colors } from "styles/variables";
import { TableMode } from "modules/Connection/Table";

import closeIcon from "assets/cancel-nobg.svg";

import Text from "components/Text";
import Button from "components/Button";
import DotDotDot from "components/DotDotDot";
import Portal from "components/Portal";
import Shortcuts, { shortcut } from "components/Shortcuts";
import { useFilterCollectionCtx } from "modules/Connection/FilterCollection";
import Checkbox from "components/Form/Checkbox";

interface Props {
  columns: Column<any>[];
  anyFilters: boolean;
  onReset: () => void;
  mode: TableMode;
  filtersVisible: boolean;
  setFiltersVisible: (v: boolean) => void;
  selectAllActive?: boolean;
  onSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  loading: boolean;
  allSelected?: boolean;
  className?: string;
  isSelectable?: boolean;
}

function FiltersBody({
  className,
  columns,
  anyFilters,
  onReset,
  mode,
  setFiltersVisible,
  count: gigsCount,
  loading,
  onSelectAll,
  selectAllActive,
  allSelected,
  isSelectable = true,
}: Props) {
  const { t } = useTranslation();
  const { state, setFilterState } = useFilterCollectionCtx();
  const [filtersDraftState, _setFiltersDraftState] = useState<any>(state);
  const setFiltersDraftState = (filterId: string) => (state: any) => {
    _setFiltersDraftState({ ...filtersDraftState, [filterId]: state });
  };
  const checked = selectAllActive;

  const isCompact = mode === "compact";
  return (
    <div role="rowgroup" className={cx(styles.filterBar, isCompact && styles.fullScreenFilters, className)}>
      {isCompact && (
        <div className={styles.header}>
          <Text size={36} color="white" font="wes">
            {t("components.filters.filterGigs")}
          </Text>
          <Clickable onClick={() => setFiltersVisible(false)}>
            <Icon src={closeIcon} fill={colors.white} size={24} />
          </Clickable>
        </div>
      )}
      <div role="row" className={cx(styles.tableHeader, isCompact && styles.compactHeader)}>
        {isSelectable && (
          <div className={styles.selectAll}>
            {onSelectAll && (
              <Checkbox onCheck={onSelectAll} checked={selectAllActive && !allSelected ? "interm" : checked} />
            )}
          </div>
        )}
        {columns.map(column => (
          <ColumnHeader setFiltersDraftState={setFiltersDraftState} key={column.id} column={column} mode={mode} />
        ))}
        {!isCompact && (
          <Clickable
            actionLabel={t("components.table.resetFilters")}
            className={anyFilters ? styles.clearFiltersActive : styles.clearFilters}
            onClick={() => onReset()}
            testId={"filters.resetFilters"}
          >
            <Icon size={20} src={backspaceIcon} fill={colors.white} />
          </Clickable>
        )}
      </div>
      {isCompact && (
        <div className={styles.footer}>
          <Text size={24} color="white" inline bottom="s">
            {loading ? (
              <DotDotDot ticks={5} />
            ) : (
              t("components.filters.matches", { count: gigsCount, formattedCount: gigsCount.toLocaleString() })
            )}
          </Text>
          <div className={cx(styles.actions)}>
            <Button onClick={() => onReset()}>
              <Text size={20} color="white" font="wes" bold>
                {t("components.filters.resetAll")}
              </Text>
            </Button>
            <Button
              kind="primaryGradient"
              onClick={() => {
                Object.keys(filtersDraftState).forEach(key => setFilterState(key, filtersDraftState[key]));
                setFiltersVisible(false);
              }}
            >
              <Text size={28} color="white" font="wes" bold>
                {t("components.filters.applyAll")}
              </Text>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function FiltersGroup(props: Props) {
  const { filtersVisible, mode, setFiltersVisible } = props;
  const isCompact = mode === "compact";
  const body = <FiltersBody {...props} />;
  const hideFilters = () => setFiltersVisible(false);
  return isCompact ? (
    filtersVisible ? (
      <Portal id="portal" onClick={hideFilters} className={styles.portalContainer}>
        <Shortcuts shortcuts={[shortcut("esc", hideFilters)]}>{body}</Shortcuts>
      </Portal>
    ) : null
  ) : (
    body
  );
}
