import { SheetMap } from "./sources";
import { mapValues } from "lodash";

const depSym = "$UploaderFieldDependency$";

interface Dependency<T> {
  [depSym]: true;
  name: string;
  fallback?: T;
  unwrap?: boolean;
}

export function dependency<T>(name: string, config: Omit<Dependency<T>, "name" | typeof depSym>): Dependency<T> {
  return {
    [depSym]: true,
    name,
    ...config,
  };
}

export function isDependency(subject: any): subject is Dependency<any> {
  return subject[depSym];
}

export function resolveDependencies(subject: any, sheetMap: SheetMap): any {
  if (isDependency(subject)) {
    const sources = sheetMap[subject.name];
    if (!sources || sources.length !== 1) return subject.fallback;
    const [source] = sources;
    if (source.type !== "value" || source.val === undefined) return subject.fallback;
    return source.val;
  }

  if (Array.isArray(subject)) {
    return subject.map(item => resolveDependencies(item, sheetMap));
  }

  if (typeof subject === "object") {
    return mapValues(subject, item => resolveDependencies(item, sheetMap));
  }

  return subject;
}
