import React, { useMemo, useState } from "react";
import { Uploader } from "modules/Uploader";
import getServerRootUrl from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import useRouter from "use-react-router";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import { mapValues } from "lodash";
import { useMutation } from "@apollo/client";
import createGigs from "./createGigs.gql";
import {
  Product,
  CreateGigsInput,
  UploaderCreateGigsMutation,
  UploaderCreateGigsMutationVariables,
  SelectionType,
} from "gql-gen";
import { useReloader } from "modules/Connection/Reloader";
import { gigsConnection } from "../connection";
import { serializeSearchState } from "hooks/useSearchState";
import { useTranslation } from "react-i18next";
import { getTimeWithZone, joinDateAndTime } from "utilities/time";
import * as D from "date-fns";
import Text from "components/Text";
import styles from "./styles.scss";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import chevronIcon from "assets/chevron.svg";
import { createAdHocLocation, createLocationForOrgLocation, RustLocation } from "utilities/location";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { adminFieldsConfig, userFieldsConfig } from "./config";
import LoadingContainer from "components/LoadingContainer";
import LegacyModal from "components/LegacyModal";

const endpoint = `${getServerRootUrl()}/graphql`;

const Help = ({ toggleHelp, isOpen }: { toggleHelp: () => void; isOpen: boolean }) => {
  return (
    <div className={styles.helpContainer}>
      <div className={styles.header}>
        <Text color="white" font="wes" size={18}>
          GET HELP:
        </Text>

        <Clickable onClick={toggleHelp}>
          <Icon src={chevronIcon} size={20} rotate={isOpen ? 180 : 0} fill={"white"} />
        </Clickable>
      </div>
      <div className={isOpen ? styles.helpOpen : styles.helpClosed}>
        <ul className={styles.helpResources}>
          <li>
            <a href="https://help.gopinata.com/en/articles/6641588" target="_blank">
              <Text color="blue2" size={14} font="wes" underline="hover">
                Read Tips &amp; Tricks &gt;
              </Text>
            </a>
          </li>
          <li>
            <a href="https://pinata-assets-web.s3.amazonaws.com/Bulk.Upload.Template.for.Users.csv" target="_blank">
              <Text color="blue2" size={14} font="wes" underline="hover">
                Download CSV template &gt;
              </Text>
            </a>
          </li>
          <li>
            <a href="https://youtu.be/7Xjla6FsZ8A" target="_blank">
              <Text color="blue2" size={14} font="wes" underline="hover">
                Watch a video tour &gt;
              </Text>
            </a>
          </li>
        </ul>
        <Text color="white" size={14} font="lato" noSelect lineHeight={18}>
          Select from the file that you have uploaded or choose the field name directly from your PINATA dashboard.
          Remember, if selecting from the CSV that you are uploading, the field name spelling must EXACTLY match what is
          currently on your PINATA dashboard. You can also choose the field name directly from your PINATA dashboard by
          selecting from the "Select from PINATA" dropdown.
        </Text>
      </div>
    </div>
  );
};

export function GigsUploader() {
  const { t } = useTranslation();
  const { match, history, location } = useRouter<OrganizationRouteParams & { programId: string }>();
  const [save] = useMutation<UploaderCreateGigsMutation, UploaderCreateGigsMutationVariables>(createGigs);
  const reload = useReloader();
  const { me } = useUserInfo();
  const isAdmin = me?.isAdmin;
  const programId = match.params.programId;
  const objectConfig = useMemo(() => (isAdmin ? adminFieldsConfig(t) : userFieldsConfig(t, programId)), [isAdmin]);
  const [helpOpen, setHelpOpen] = useState(true);

  if (!me) {
    return (
      <LegacyModal className={styles.loadingPlaceholder}>
        <LoadingContainer />
      </LegacyModal>
    );
  }

  return (
    <Uploader
      objectConfig={objectConfig}
      endpoint={endpoint}
      title={t("gigs.upload.uploadGigs")}
      getHeaders={() => ({
        authorization: getJwt(),
        "X-PINATA-Organization-Id": match.params.orgId,
        Accept: "application/json",
        "Content-Type": "application/json",
      })}
      info={!isAdmin ? <Help toggleHelp={() => setHelpOpen(h => !h)} isOpen={helpOpen} /> : null}
      onBatch={async (result, previousUploadId) => {
        const gigs = [];
        let fetchedLocations: { [key: string]: RustLocation } = {};
        let adHocLocations: { [key: string]: RustLocation } = {};

        for (let row of result) {
          if (row.fields && !row.error) {
            const {
              date,
              startTime,
              endTime,
              windowStart,
              windowEnd,
              copies,
              program,
              duration,
              products,
              talent,
              role,
              savedLocation,
              location: gLocation,
              manager,
              partnerOrganization,
              taskPartnerBillRate,
              talentRate,
              ...rest
            } = row.fields;

            const talentRateValue = talentRate.value ? parseFloat(talentRate.value) : undefined;
            let loc = null;
            if (savedLocation.value) {
              if (savedLocation.value[0].location) {
                loc = {
                  latLng: {
                    lat: savedLocation.value[0].location.latitude,
                    lng: savedLocation.value[0].location.longitude,
                  },
                  uuid: savedLocation.value[0].location.id,
                };
              } else if (fetchedLocations[savedLocation.value[0].id]) {
                loc = fetchedLocations[savedLocation.value[0].id];
              } else {
                const location = await createLocationForOrgLocation(savedLocation.value[0], match.params.orgId);
                loc = location;
                fetchedLocations[savedLocation.value[0].id] = location;
              }
            }
            if (gLocation.value && !savedLocation.value) {
              if (adHocLocations[gLocation.value.place_id]) {
                loc = adHocLocations[gLocation.value.place_id];
              } else {
                const location = await createAdHocLocation(gLocation.value, match.params.orgId);
                loc = location;
                adHocLocations[gLocation.value.place_id] = location;
              }
            }

            let newGig: CreateGigsInput = {
              programId: (program && program.value && program.value[0].id) || programId,
              roleId: role.value && role.value[0].id,
              talentId: talent.value && talent.value[0].id,
              managerId: manager.value && manager.value[0].id,
              partnerOrganizationId: partnerOrganization.value && partnerOrganization.value[0].id,
              talentRate: talentRateValue,
              locationId: loc?.uuid,
              productSelection: {
                type: SelectionType.Include,
                values: products?.value ? products.value.map((p: Product) => p.id) : [],
              },
              copies: copies?.value ? parseInt(copies.value) : 1,
              ...mapValues(rest, fv => (fv.error ? null : fv.value)),
            };

            const calculateDuration = (start: Date, end: Date) =>
              D.isAfter(start, end)
                ? Math.abs(D.differenceInMinutes(start, D.addDays(end, 1)))
                : Math.abs(D.differenceInMinutes(start, end));

            const gigDuration = duration.value
              ? duration.value * 60
              : endTime.value && startTime.value
                ? calculateDuration(startTime.value, endTime.value)
                : null;

            let times: any = { duration: gigDuration };
            const latLon = loc
              ? {
                latitude: loc.latLng.lat,
                longitude: loc.latLng.lng,
              }
              : {};

            if (date.value) {
              if (startTime.value) {
                const { zonedDate: startDate, timezone } = await getTimeWithZone(
                  joinDateAndTime(date.value, startTime.value),
                  latLon,
                );

                const start = startDate.toISOString();

                times = {
                  ...times,
                  startTime: start,
                  timezone,
                };
              } else {
                const { zonedDate: windowStartTime, timezone } = await getTimeWithZone(date.value, latLon);
                const unzonedWindowEndTime = D.add(windowStartTime, { hours: 23, minutes: 59, seconds: 59 });
                const { zonedDate: windowEndTime } = await getTimeWithZone(unzonedWindowEndTime, latLon);
                times = {
                  ...times,
                  startTime: null,
                  timezone,
                  windowStartTime: windowStartTime.toISOString(),
                  windowEndTime: windowEndTime.toISOString(),
                };
              }
            } else if (windowStart.value) {
              const { zonedDate: windowStartTime, timezone } = await getTimeWithZone(windowStart.value, latLon);
              const windowEndTime = windowEnd.value ? (await getTimeWithZone(windowEnd.value, latLon)).zonedDate : null;

              const formattedStartTime = D.format(windowStartTime, "MMddyyyy");
              const windowAssign = windowEndTime ? formattedStartTime === D.format(windowEndTime, "MMddyyyy") : false;

              times = {
                ...times,
                startTime: null,
                timezone,
                windowStartTime: windowStartTime.toISOString(),
                windowEndTime: windowEndTime?.toISOString(),
                windowAssign,
              };
            }

            newGig = {
              ...newGig,
              ...times,
            };

            gigs.push(newGig);
          }
        }

        try {
          const { data } = await save({
            variables: {
              gigs,
              uploadId: previousUploadId,
            },
          });

          if (data && data.createGigs.uploadId) {
            return { uploadId: data.createGigs.uploadId || undefined, erroredRows: { range: [], count: 0 } };
          }
        } catch (err) {
          throw JSON.stringify(err, null, 2);
        }

        return { erroredRows: { range: [], count: 0 } };
      }}
      onDone={async uploadId => {
        reload(gigsConnection.name);

        history.push({
          search: uploadId
            ? serializeSearchState(
              "f",
              {
                upload: {
                  uploadId: uploadId,
                },
              },
              location.search,
            )
            : "",
          pathname: isAdmin
            ? location.pathname.replace(`/+book-gigs-like-a-boss`, "").replace(`/+gigs-bulk-uploader/${programId}`, "")
            : location.pathname.replace(`/+gigs-bulk-uploader/${programId}`, ""),
        });
      }}
      confirmMessage={() => t("gigs.upload.areYouSure")}
    />
  );
}
