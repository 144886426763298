import React, { createContext, useEffect, useContext, DependencyList, useRef, useMemo, ReactNode } from "react";
import { makeContextHoC } from "utilities/context";

interface ReloadCallback {
  (): void;
}

export interface IReloadContext {
  subscribe: (name: string, callback: ReloadCallback) => void;
  unsubscribe: (name: string, callback: ReloadCallback) => void;
  reload: (name: string) => unknown[];
}

export const ConnectionReloader = createContext<IReloadContext | null>(null);

export function ConnectionReloadProvider({ children }: { children: ReactNode }) {
  const map = useRef<Map<string, ReloadCallback[]>>(new Map());
  const context: IReloadContext = useMemo(
    () => ({
      subscribe: (name, callback) => {
        if (!map.current.has(name)) map.current.set(name, []);
        map.current.get(name)!.push(callback);
      },
      unsubscribe: (name, callback) => {
        const listeners = map.current.get(name) || [];
        listeners.splice(listeners.indexOf(callback), 1);
      },
      reload: name => (map.current.get(name) || []).map(listener => listener()),
    }),
    [],
  );

  return <ConnectionReloader.Provider value={context}>{children}</ConnectionReloader.Provider>;
}

export function useReloaderCallback(name: string, callback: ReloadCallback, deps: DependencyList = []) {
  const reloader = useContext(ConnectionReloader);

  useEffect(() => {
    if (!reloader) throw new Error("Couldn't find a Connection Reloader Provider");

    reloader.subscribe(name, callback);

    return () => {
      reloader.unsubscribe(name, callback);
    };
  }, deps);
}

export function useReloader() {
  const reloader = useContext(ConnectionReloader);
  if (!reloader) throw new Error("Couldn't find a Connection Reloader Provider");
  return reloader.reload;
}

export const withReloader = makeContextHoC(ConnectionReloader, "reloader");
