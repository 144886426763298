import React from "react";
import { FieldProps } from "formik";
import DecimalBox from "components/Form/DecimalBox";
import styles from "./styles.scss";

export default function FRateField({ field, form }: FieldProps) {
  return (
    <div className={styles.rateContainer}>
      <DecimalBox
        label="HOURLY RATE"
        optionalLabel
        value={field.value}
        onChange={(v: number) => form.setFieldValue(field.name, v)}
      />
    </div>
  );
}
