import * as React from "react";

import Text from "components/LegacyText";

import * as FieldValue from "../FieldValue";
import ProgramInfo from "../ProgramInfo";

export default function TalentRequirements() {
  const programInfo = React.useContext(ProgramInfo);
  const { roles, organization } = programInfo;

  const gigRequestsActive = organization?.gigRequestsActive;
  const gigSelfAssignmentActive = organization?.gigSelfAssignmentActive;

  return (
    <>
      {roles
        .filter((role: any) => Boolean(role?.title))
        .map((role: any) => (
          <FieldValue.Base label="Activity" key={role.id}>
            {_ => (
              <React.Fragment key={role.id}>
                <Text.H2>{role.title}</Text.H2> <br />
                {role.duties && (
                  <div style={{ marginBottom: 8 }}>
                    <Text.H3>Responsibilities (shown to end user)</Text.H3>
                    {role.duties}
                  </div>
                )}
                {role.profile && (
                  <div style={{ marginBottom: 8 }}>
                    <Text.H3>Staffing Guidance (not shown to end user)</Text.H3>
                    {role.profile}
                  </div>
                )}
                {role.uniform && (role.uniform.label || role.uniform.description) && (
                  <div style={{ marginBottom: 8 }}>
                    <Text.H3>Dress Code</Text.H3>
                    {role.uniform.label} <br />
                    {role.uniform.description}
                  </div>
                )}
                {gigRequestsActive && (
                  <div style={{ marginBottom: 8 }}>
                    <Text.H3>Make Requestable?</Text.H3>
                    {role.requestable ? "Yes" : "No"}
                  </div>
                )}
                {gigSelfAssignmentActive && (
                  <div style={{ marginBottom: 8 }}>
                    <Text.H3>Make Autonomous?</Text.H3>
                    {role.selfAssignable ? "Yes" : "No"}
                  </div>
                )}
              </React.Fragment>
            )}
          </FieldValue.Base>
        ))}
    </>
  );
}
