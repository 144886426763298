import { createContext, Dispatch, SetStateAction } from "react";
import { SelectMode } from "./Spreadsheet";
import { SheetInfo } from "./Worker/sheetInfo";
import { SheetMap } from "./sources";
import { ObjectConfig } from "./fields";

export interface IUploaderContext {
  sheetInfo: SheetInfo | null;
  requestSelect: (mode: SelectMode, cb: (range: number) => void) => void;
  headerIndex: number;
  sheetMap: SheetMap;
  objectConfig: ObjectConfig;
  isAdmin: boolean;
  openSections: Set<string>;
  setOpenSections: Dispatch<SetStateAction<Set<string>>>;
}

export const UploaderContext = createContext<IUploaderContext>({
  requestSelect: () => {},
  sheetInfo: null,
  headerIndex: 1,
  sheetMap: {},
  objectConfig: { worker: {}, ui: {} },
  isAdmin: false,
  openSections: new Set([]),
  setOpenSections: () => {},
});
