import * as React from "react";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import { NewLinkProps } from "components/LegacyText/NewLink";
import variables from "styles/variables";
import chevronIcon from "assets/chevron.svg";
import styles from "./styles.scss";

interface Props {
  direction?: "forward" | "back";
  children?: string;
  linkProps?: Partial<NewLinkProps>;
}

export default function GoButton({ direction = "forward", children = "Go", linkProps }: Props) {
  const forward = direction === "forward";

  return (
    <span className={styles.container} style={{ flexDirection: forward ? "row" : "row-reverse" }}>
      <Text.NewLink3 {...linkProps}>{children}</Text.NewLink3>

      <Icon src={chevronIcon} size={15} rotate={forward ? -90 : 90} fill={variables.blue2} />
    </span>
  );
}
