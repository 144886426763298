import React from "react";
import { getSpan, getDate } from "pinata-common/gig_dates";
import { useTranslation } from "react-i18next";
import { format, utcToZonedTime } from "date-fns-tz";
import { isSameDay } from "date-fns";
import { GigsDateFragment } from "gql-gen";
import icon from "assets/calendar-range.svg";
import { ICell } from "modules/Connection/types";
import { CellBase, CR1, CR2, CR3, AddDetails } from "modules/Connection/CellBase";
import dateFragment from "./date.gql";

export const DateCell: ICell<GigsDateFragment> = {
  component: props => {
    const { t } = useTranslation();

    const { data, handleOpenForm } = props;

    if (!data || (!data.startTime && !data.windowStartTime) || !data.timezone) {
      return (
        <CellBase {...props}>
          {_ => <AddDetails onClick={handleOpenForm}>{t(`gigs.columns.date.addDate`)}</AddDetails>}
        </CellBase>
      );
    }

    const date = getDate(data, { scheduled: "eee, MMM d, yyyy" });
    const span = getSpan(data, { windowed: "MM/dd/yy", scheduled: "h:mma" });

    return (
      <CellBase {...props}>
        {mode => (
          <>
            <CR1>{date}</CR1>
            <CR2>
              {data.windowEndTime &&
              data.windowStartTime &&
              data.timezone &&
              !data.windowAssign &&
              !data.startTime &&
              isSameDay(
                utcToZonedTime(data.windowEndTime, data.timezone),
                utcToZonedTime(data.windowStartTime, data.timezone),
              ) ? (
                <AddDetails onClick={handleOpenForm}>{t(`gigs.columns.date.addDate`)}</AddDetails>
              ) : (
                span
              )}
            </CR2>
            {mode !== "compact" && (
              <CR3>
                {data.duration &&
                  t("gigs.columns.date.duration", {
                    count: Math.round((data.duration / 60 + Number.EPSILON) * 100) / 100,
                  })}
              </CR3>
            )}
          </>
        )}
      </CellBase>
    );
  },
  icon,
  fragment: dateFragment,
};
