import React, { useState, ReactNode } from "react";
import cx from "classnames";
import Clickable from "components/Clickable";

import styles from "./styles.scss";

export interface IStateTab {
  title: string;
  key: string;
  render: () => ReactNode;
  testId?: string;
}

interface Props {
  compact?: boolean;
  className?: string;
  invertedBg?: boolean;
  tabs: Array<IStateTab>;
  onChange?: (key: string) => void;
}

export function StateTabs(props: Props) {
  const [activeTab, setActiveTab] = useState(props.tabs[0] && props.tabs[0]!.key);

  return (
    <StatelessTabs
      {...props}
      activeTab={activeTab}
      onChange={v => {
        setActiveTab(v);
        props.onChange?.(v);
      }}
    />
  );
}

export function StatelessTabs({
  activeTab,
  compact,
  tabs,
  invertedBg,
  className,
  onChange,
}: Props & { activeTab?: string }) {
  if (!activeTab) return null;
  const activeTabInstance = tabs.find(tab => tab.key === activeTab);
  if (!activeTabInstance) return null;
  return (
    <>
      <div className={cx(compact ? styles.compactTabs : styles.tabs, className)}>
        {tabs.map((tab, index) => {
          return (
            tab && (
              <Clickable
                key={index}
                onClick={() => {
                  onChange && onChange(tab.key);
                }}
                className={cx(
                  styles.tab,
                  compact && styles.compactTab,
                  invertedBg && styles.invertedBg,
                  tab.key === activeTab && styles.activeTab,
                )}
                testId={tab.testId}
              >
                {tab.title}
              </Clickable>
            )
          );
        })}
      </div>
      {activeTabInstance!.render()}
    </>
  );
}
