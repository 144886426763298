import { flowRight as compose } from "lodash";
import React, { useEffect, useMemo } from "react";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import _, { pick } from "lodash";

import {
  GigAssignmentFieldFragment,
  GigAssignmentOptionsQuery,
  Direction,
  AssignmentTalentQuery,
  TagGroup,
} from "gql-gen";

import commentDots from "assets/comment-dots-solid.svg";

// FIXME: Use new Table
import Table from "components/Table";
import { ColumnInfo } from "components/Table/columns";
import Cell from "components/Table/Cell";
import Row from "components/Table/Row";
import Pill from "components/Pill";

import LoadingContainer from "components/LoadingContainer";
import TableFilters from "components/TableFilters";
import LegacyText from "components/LegacyText";
import Text from "components/Text";
import Form from "components/Form";
import TagFilter from "components/TagFilter";
import { CollectionContext, withCollection } from "components/ServerCollection";
import { getAppUrl } from "utilities/routes";
import { serializeSearchState } from "hooks/useSearchState";
import Clickable from "components/Clickable";
import useDebounce from "hooks/useDebounce";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import Icon from "components/Icon";
import { colors } from "styles/variables";

import assignableUsersByGigQuery from "./assignableUsersByGig.gql";
import styles from "./styles.scss";
import { isVirtualLocation } from "utilities/location";

const ITEMS_PER_PAGE = 5;
const ns = "gigs.fields.talent";

type GigAssignableUser = GigAssignmentOptionsQuery["gigAssignableUsers"]["edges"][0]["node"];
type AssignableGig = GigAssignmentFieldFragment;

type Props = FieldProps & { data: GigAssignmentFieldFragment; orgId?: string } & {
  collection: CollectionContext<GigAssignableUser>;
  gigs?: AssignableGig[];
  userData?: AssignmentTalentQuery["user"];
  testId?: string;
};

const COLUMNS: ColumnInfo[] = [
  { label: "Name", size: 190, orderId: "name" },
  { label: "Distance", size: 60, orderId: "distance" },
  { label: "Status", size: 100, orderId: "status" },
  { label: "Email" },
  { label: "Phone" },
  { label: "Acct. created" },
  { label: "Note" },
];

function FTalentPicker(props: Props) {
  const {
    field,
    form: { setFieldValue, values },
    orgId,
    gigs,
    collection: { pageItems, filters, onFilterChange, submitFilters, pagination, order, onOrderChanged },
    userData,
    testId,
  } = props;
  const value = values[field.name];
  const {
    location,
    match: { params },
  } = useReactRouter<ProgramsRouteParams>();
  const { t } = useTranslation();

  const debouncedFilters = useDebounce(filters, 300);

  const hasLocation = useMemo(
    // At least one gig has a location
    () => (gigs && gigs.length > 0 ? gigs.some(g => !isVirtualLocation(g.location ?? undefined)) : true),
    [gigs],
  );

  const columns: ColumnInfo[] = useMemo(() => COLUMNS.filter(c => (c.label === "Distance" ? hasLocation : true)), [
    hasLocation,
  ]);

  useEffect(() => {
    submitFilters();
  }, [debouncedFilters]);

  return (
    <div className={value ? styles.size : styles.notAssignedSize} data-test={testId}>
      <TableFilters onGo={submitFilters} filterOnChange>
        <Form.TextBox
          label={"SEARCH MEMBERS"}
          placeholder={"Type name or email..."}
          value={filters.search}
          onChange={onFilterChange("search")}
        />
        {orgId && <TagFilter group={TagGroup.Users} value={filters.tagIds} onChange={onFilterChange("tagIds")} />}
      </TableFilters>

      <Table
        headerClassName={styles.tableHeader}
        actions={[]}
        columns={columns}
        selectMultiple={false}
        selectable={true}
        rowHeight={"small"}
        visibleColumns={hasLocation ? 3 : 2}
        itemsPerPage={ITEMS_PER_PAGE}
        selected={value ? [value] : []}
        onSelectionChange={([applicationId]) => {
          setFieldValue(field.name, applicationId);
        }}
        displaySelected={{
          data: !!userData ? [{ id: userData.id, label: userData?.firstName + " " + userData?.lastName }] : [],
          renderCounter: () => "User to assign:",
        }}
        controlledPagination={pagination}
        smallPagination
        order={order}
        onOrderChanged={onOrderChanged}
      >
        {!pageItems
          ? [<LoadingContainer message={""} color="blue" className={styles.loadingSize} center key="loading" />]
          : pageItems?.map(({ user, status, distance, note }) => {
              const unverifiedAccount = !user.firstName || !user.lastName;

              const viewOverlapping = () => {
                if (gigs) {
                  const url = getAppUrl(
                    "dashboard",
                    `${params.orgId}/programs/gigs${serializeSearchState(
                      "f",
                      {
                        date: {
                          type: "overlaps",
                          times: gigs.map(g => pick(g, ["startTime", "endTime", "timezone"])),
                        },
                        talent: {
                          type: "gogetter",
                          ids: [user.id],
                        },
                      },
                      location.search,
                    )}`,
                  );

                  window.open(url, "_blank");
                }
              };
              return (
                <Row
                  key={user.id}
                  id={user.id}
                  label={user.firstName + " " + user.lastName}
                  selectDisabled={
                    unverifiedAccount || (status !== "available" && status !== "applied" && status !== "unavailable")
                  }
                >
                  {// Cells are correlated to columns by their position
                  // Since some columns are optional, we have to remove the
                  // elements from the row as well, otherwise the values of
                  // the cells are off by one
                  [
                    <Cell size={190}>
                      <LegacyText.CellValue className={styles.userName}>
                        <>
                          {!unverifiedAccount && (
                            <LegacyText.NewLink3
                              component={Link}
                              className={styles.link}
                              title={`${user.firstName} ${user.lastName}`.trim()}
                              to={{
                                ...location,
                                pathname: `${location.pathname}/+user-profile/${user.id}`,
                              }}
                            >
                              {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
                            </LegacyText.NewLink3>
                          )}

                          {unverifiedAccount && (
                            <div className={styles.nameCell}>
                              <LegacyText.CellValue>{user.email}</LegacyText.CellValue>
                              <Pill className={styles.pending}>pending</Pill>
                            </div>
                          )}
                        </>
                      </LegacyText.CellValue>
                    </Cell>,

                    hasLocation ? (
                      <Cell size={60}>
                        <LegacyText.P5>{distance ? `${(distance * 0.6213712).toFixed(1)}mi` : "-"}</LegacyText.P5>
                      </Cell>
                    ) : null,

                    <Cell size={100}>
                      {status !== "unknown" &&
                        (status === "conflict" ? (
                          <Clickable onClick={viewOverlapping} actionLabel={t(`${ns}.viewConflictingGigs`)}>
                            <Text className={styles.link} color="blue2" font="lato" bold underline="hover" noSelect>
                              {t(`${ns}.status.${status}`)}
                            </Text>
                          </Clickable>
                        ) : (
                          <LegacyText.P5>
                            {t(`${ns}.status.${status}`)}
                            {!!note && (
                              <span style={{ marginLeft: 6 }}>
                                <Icon src={commentDots} size={16} fill={colors.teal1} />
                              </span>
                            )}
                          </LegacyText.P5>
                        ))}
                    </Cell>,

                    <Cell>
                      <LegacyText.CellValue>{user.email}</LegacyText.CellValue>
                    </Cell>,

                    <Cell>
                      <LegacyText.CellValue>{user.phone}</LegacyText.CellValue>
                    </Cell>,

                    <Cell>
                      <LegacyText.CellValue>{format(parseISO(user.createdAt), "MM/dd/yyyy")}</LegacyText.CellValue>
                    </Cell>,

                    status === "applied" ? (
                      <Cell>
                        <LegacyText.CellValue>{note}</LegacyText.CellValue>
                      </Cell>
                    ) : null,
                  ].filter((el: JSX.Element | null): el is JSX.Element => Boolean(el))}
                </Row>
              );
            })}
      </Table>
    </div>
  );
}

export default compose(
  withCollection({
    query: assignableUsersByGigQuery,
    name: "gigAssignableUsers",
    filters: {
      search: { type: "string", default: "" },
      tagIds: { type: "array", default: [] },
    },
    mapPropsToVariables: (props: Props) => ({
      gigIds: props.gigs?.map(g => g.id),
    }),
    skip: props => !props || !props.gigs || !props.gigs.length,
    itemsPerPage: ITEMS_PER_PAGE,
    defaultOrder: { sort: "status", direction: Direction.Asc },
  }),
)(FTalentPicker);
