import Overview from "../Form/Overview";
import Activities from "../Form/Activities";
import TalentRequirements from "../Form/TalentRequirements";
import ProgramLogistics from "../Form/ProgramLogistics";
import Products from "../Form/Products";
import Education from "../Form/Education";
import AdminInfo from "../Form/AdminInfo";
import Partnership from "../Form/Partnership";
import InvitedBy from "../Form/InvitedBy";
import Team from "../Form/Team";
import { isUserAtLeast, User } from "interfaces/user";

type Section = {
  id: string;
  title: string;
  headline: string;
  component: React.ComponentType<any>;
  optional: boolean;
  /** Blend this section into the previous one. Skip this section when continuing. */
  minimal?: boolean;
};

const SECTIONS: Section[] = [
  {
    id: "overview",
    title: "Overview",
    headline: `Let’s start with the basics`,
    component: Overview,
    optional: false,
  },

  {
    id: "activities",
    title: "Activities",
    headline: `Select Activities for this Program`,
    component: Activities,
    optional: false,
  },

  {
    id: "talentRequirements",
    title: "Customize Activities",
    headline: `Modify your chosen Activities for this program alone`,
    component: TalentRequirements,
    optional: true,
    minimal: true,
  },

  {
    id: "products",
    title: "Products",
    headline: `Select Products for this Program`,
    component: Products,
    optional: false,
  },

  {
    id: "invitedBy",
    title: "Invited By...",
    headline: "",
    component: InvitedBy,
    optional: true,
  },

  {
    id: "partnership",
    title: "My Partners",
    headline: "Add external Partner Teams to this Program",
    component: Partnership,
    optional: true,
  },

  {
    id: "training",
    title: "End-User Education",
    headline: `Add information for users assigned to tasks`,
    component: Education,
    optional: true,
  },

  {
    id: "team",
    title: "Sharing & Notifications",
    headline: `Select Users for this Program`,
    component: Team,
    optional: true,
  },

  {
    id: "billingInfo",
    title: "Billing Info",
    headline: `Add info visible only to your team's admins/managers`,
    component: AdminInfo,
    optional: true,
  },

  {
    id: "programLogistics",
    title: "Additional info",
    headline: `Add extra info about materials, shipping, or expenses`,
    component: ProgramLogistics,
    optional: true,
  },
];

const NON_ADMIN_SECTIONS = SECTIONS.filter(s => s.id !== "billingInfo");

export function getSectionsForUser(
  user: User | null,
  isPartnershipVisible: boolean,
  isInvitedByVisible: boolean,
): Section[] {
  const filterSections = (sections: Section[]) =>
    sections.filter(s => {
      if (!isPartnershipVisible) {
        return s.id !== "partnership";
      }

      if (!isInvitedByVisible) {
        return s.id !== "invitedBy";
      }

      return true;
    });

  if (isUserAtLeast(user, "manager")) {
    return filterSections(SECTIONS);
  }

  return filterSections(NON_ADMIN_SECTIONS);
}

export function getRequiredSectionsForUser(
  user: User | null,
  isPartnershipVisible: boolean,
  isInvitedByVisible: boolean,
): Section[] {
  return getSectionsForUser(user, isPartnershipVisible, isInvitedByVisible).filter(s => !s.optional);
}
