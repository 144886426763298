import React from "react";
import { ICell } from "modules/Connection/types";
import * as types from "gql-gen";
import billingExpenses from "./billingExpenses.gql";
import AgencyBillingView from "./AgencyBillingView";
import ClientBillingView from "./ClientBillingView";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { useQuery } from "@apollo/client";
import Blurred from "../Blurred";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner";
import { apply, option, function as fn } from "fp-ts";

export const BillingOverviewCell: ICell<{ id: string }> = {
  component: props => {
    const userInfo = useUserInfo();
    const { t } = useTranslation();

    const gigReportExpense = useQuery<types.GigReportExpensesQuery, types.GigReportExpensesQueryVariables>(
      billingExpenses,
      {
        variables: { id: props.data?.id },
        fetchPolicy: "network-only",
      },
    );
    const iAmTalentOrg = fn.pipe(
      apply.sequenceS(option.Apply)({
        userOrgId: option.fromNullable(userInfo.me?.organizationUser?.organizationId),
        talentOrgId: option.fromNullable(gigReportExpense.data?.gig?.talentOrganization?.id),
      }),
      option.exists(({ userOrgId, talentOrgId }) => userOrgId === talentOrgId),
    );
    const gigIsVerified = fn.pipe(
      option.fromNullable(gigReportExpense.data?.gig?.workflowStateOrder),
      option.exists(wso => wso > 140),
    );
    const sectionIsVisible = iAmTalentOrg || gigIsVerified;

    if (userInfo.loading || gigReportExpense.loading || !gigReportExpense.data) return <Spinner />;
    return (
      <Blurred
        blur={!sectionIsVisible}
        link={
          <a href="https://help.gopinata.com/en/articles/4231577" target="_blank">
            {t("gigs.cells.reportHelp")}
          </a>
        }
        bannerText={t(`gigs.cells.blurredSectionBanner`)}
      >
        {iAmTalentOrg ? (
          <AgencyBillingView gig={gigReportExpense.data.gig} />
        ) : (
          <ClientBillingView gig={gigReportExpense.data.gig} />
        )}
      </Blurred>
    );
  },
  icon: () => <></>,
};
