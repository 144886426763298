import { useState, useEffect, createElement, useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";
import { FormikErrors, FormikProps, FormikHelpers } from "formik";
import { DocumentNode } from "graphql";
import { SrcConnection, ConnectionConfig, ConnectionResult } from "utilities/connections";
import { useConnectionConfig } from "hooks/useConnectionConfig";
import { pick, merge } from "lodash";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export type ComposableFormErrors<T> = FormikErrors<T> & {
  general?: string;
};

export type ValidateResult<V> = ComposableFormErrors<V> | void;

export type ComposableFormProps<O, V, DepValues> = FormikProps<V & DepValues> & {
  data?: O;
  formMeta?: any;
  allItems?: O[];
  inline: boolean;
  /** Not all Fields are present so dependency validation shouldn't be performed */
  partialBatchUpdate: boolean;
  close: () => void;
};

interface ValidateUtil<Data> {
  data?: Data;
  t: TFunction;
  partialBatchUpdate?: boolean;
}
export type SavingDependency = { dep: _FieldDependency; save: true };
export type _FieldDependency = ComposableField<any, any, any>;
export type FieldDependency = _FieldDependency | SavingDependency;

export interface ComposableField<Data, Values, SaveValues = Values, DepValues = any> {
  id: string;
  component: React.ComponentType<ComposableFormProps<Data, Values, DepValues>>;
  fragment?: DocumentNode;
  initialize: (data?: Data, formMeta?: any) => Values | Promise<Values>;
  validate?: (values: Values, util: ValidateUtil<Data>) => ValidateResult<Values> | Promise<ValidateResult<Values>>;
  dependencies?: FieldDependency[] | (() => FieldDependency[]);
  finalize?: (values: DepValues & Values, data?: Data) => Partial<SaveValues> | Promise<Partial<SaveValues>>;
  inlineMaxWidth?: number;
}

// For some reason this doesn't work. Will fix later. We should use them regardless.
export type ComposeData<FA extends ComposableField<any, any>[]> = FA[number] extends ComposableField<infer O, any>
  ? O
  : any; // Use never when fixed

// Same deal
export type ComposeValues<FA extends ComposableField<any, any>[]> = FA[number] extends ComposableField<any, infer V>
  ? V
  : any;

export type UseComposableFormOpts<O, V, CC extends ConnectionConfig> = Partial<SrcConnection<CC>> & {
  forms: readonly ComposableField<O, V>[];
  copying?: boolean;
  inline?: boolean;
  /** Not all Fields are present so dependency validation shouldn't be performed */
  partialBatchUpdate?: boolean;
  enabledFormIds?: string[];
  defaultValues?: V;
  handleSubmit: (finalObject: O) => Promise<void> | void;
  handleClose: () => void;
  limit?: null | number;
  formMeta: any;
};

export function isSavingDepedency(dependency: FieldDependency): dependency is SavingDependency {
  return "dep" in dependency && "save" in dependency && dependency.save;
}

export function useComposableForm<
  O extends { id: string },
  V extends {},
  CC extends ConnectionConfig = ConnectionConfig
>({
  forms,
  connection,
  variables,
  copying,
  handleSubmit,
  inline,
  enabledFormIds,
  partialBatchUpdate,
  defaultValues,
  limit,
  formMeta,
  handleClose,
}: UseComposableFormOpts<O, V, CC>) {
  const [initForms, formsToSave] = useMemo(() => {
    const iforms: ComposableField<any, any, any>[] = [...forms];
    const formsToSave = [...forms];

    for (const { dependencies } of forms) {
      if (dependencies) {
        const deps = typeof dependencies === "function" ? dependencies() : dependencies;

        for (let fdep of deps) {
          if (isSavingDepedency(fdep)) {
            fdep = fdep.dep;

            if (!formsToSave.includes(fdep)) {
              formsToSave.push(fdep);
            }
          }

          if (!iforms.includes(fdep)) {
            iforms.push(fdep);
          }
        }
      }
    }

    return [iforms, formsToSave];
  }, []);

  const fragments = useMemo(() => initForms.filter(f => f.fragment).map(f => f.fragment!), [initForms]);
  const query = useConnectionConfig(connection, fragments, "Form");
  const { t } = useTranslation();
  const entry = (connection && connection.entry.name) || "";

  const queryResult = useQuery<{ [x in typeof entry]: ConnectionResult<O> }>(query, {
    fetchPolicy: "network-only",
    skip: !connection,
    variables: {
      ...variables,
      first: limit || 1,
      skipPageInfo: true,
    },
  });

  const allEdges =
    connection &&
    queryResult &&
    queryResult.data &&
    queryResult.data[connection.entry.name] &&
    queryResult.data[connection.entry.name].edges;

  const allItems = useMemo(() => allEdges?.map(e => e.node), [allEdges]);

  const data = allItems?.[0];

  const [initialValues, setInitialValues] = useState<V | null>(null);
  const ready = !!(data || !connection);
  const keys = useRef(new Map());

  useEffect(() => {
    let formValues: Partial<V> | null = null;

    if (ready && !initialValues) {
      const initForm = async () => {
        formValues = {};

        for (const form of initForms) {
          const subFormValues = await form.initialize(data, formMeta);
          formValues = Object.assign(formValues, subFormValues);
          keys.current.set(form, Object.keys(subFormValues));
        }

        setInitialValues(merge(formValues as V, defaultValues));
      };

      initForm();
    }
  }, [ready]);

  const onSubmit = async (values: V, { setSubmitting }: FormikHelpers<V>) => {
    let finalObject: Partial<O> = {};

    for (const form of formsToSave) {
      if (enabledFormIds && !enabledFormIds.includes(form.id)) {
        continue;
      }
      // TODO: This should only pass relevant values
      const saveValues = form.finalize
        ? await form.finalize(values, copying ? undefined : data)
        : pick(values, keys.current.get(form));

      finalObject = Object.assign(finalObject, saveValues);
    }

    try {
      await handleSubmit(finalObject as O);
    } catch (e) {
      throw e;
    } finally {
      setSubmitting(false);
    }
  };

  const validate = async (values: V) => {
    let errors = {};

    for (const form of forms) {
      if (form.validate) {
        Object.assign(errors, form.validate(values, { data, t, partialBatchUpdate }));
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }
  };

  const _renderField = (formikProps: FormikProps<V>, form: ComposableField<O, V>, key?: number) => {
    return createElement(form.component, {
      ...formikProps,
      data: copying ? undefined : data,
      allItems: copying ? undefined : allItems,
      key,
      inline: !!inline,
      partialBatchUpdate: !!partialBatchUpdate,
      formMeta,
      close: handleClose,
    });
  };

  const renderField = (formikProps: FormikProps<V>, id: typeof forms[number]["id"]) => {
    const field = forms.find(f => f.id === id);
    return field ? _renderField(formikProps, field) : null;
  };

  const render = (formikProps: FormikProps<V>) => {
    return forms.map((form, fIndex) => _renderField(formikProps, form, fIndex));
  };

  return {
    initialValues,
    onSubmit,
    validate,
    render,
    renderField,
    ready,
  };
}
