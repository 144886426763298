import * as React from "react";
import Text from "components/LegacyText";
import { getEnvironment } from "utilities/environment";
import styles from "./styles.scss";

const currentEnv = getEnvironment();

export default function EnvIndicator() {
  if (currentEnv === "production" || currentEnv === "development") {
    return null;
  }

  return (
    <div className={styles.env}>
      <Text.P4 inline kind={"reverse"}>
        {currentEnv.toUpperCase()}
      </Text.P4>
    </div>
  );
}
