import React from "react";

import BrandingPreview from "./BrandingPreview.elm";
import { getJwt } from "utilities/authentication";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import styles from "./styles.scss";
import { Elm } from "components/Elm";
import useRouter from "use-react-router";
import { OrganizationRouteParams } from "..";
import { getNewServerRootUrl, getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";

export default function BrandingPortal() {
  const { me, loading } = useUserInfo();
  const {
    match: {
      params: { orgId },
    },
  } = useRouter<OrganizationRouteParams>();

  if (!me || loading) return null;

  return (
    <div className={styles.elmContainer}>
      <Elm
        src={BrandingPreview.Elm.BrandingPreview}
        flags={{
          userId: me.id,
          orgId,
          jwt: getJwt(),
          serverUrl: `${getNewServerRootUrl()}/graphql`,
          haskellBackendBaseUrl: getHaskellBackendUrl(),
          rustBackendBaseUrl: getRustBackendUrl(),
        }}
      />
    </div>
  );
}
