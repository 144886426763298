import * as React from "react";
import cx from "classnames";
import BaseFormElement from "components/Form/BaseFormElement";
import { IconProps } from "components/Icon";
import styles from "./styles.scss";

export interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  testId?: string;
  arrowClassName?: string;
  children: React.ReactNode;
  label?: string;
  wrapperClassName?: string;
  className?: string;
  dropdownClassName?: string;
  errorMessage?: string | false;
  selectRef?: any;
  optionalLabel?: boolean;
  iconProps?: Partial<IconProps>;
}

export default function Dropdown({
  children,
  label,
  className,
  arrowClassName,
  dropdownClassName,
  wrapperClassName,
  errorMessage,
  optionalLabel,
  disabled,
  selectRef,
  iconProps,
  testId,
  ...dropdownProps
}: DropdownProps) {
  return (
    <div className={wrapperClassName}>
      <BaseFormElement
        arrow={"down"}
        disabled={disabled}
        optionalLabel={optionalLabel}
        className={cx(className)}
        arrowClassName={arrowClassName}
        elementClassName={cx(dropdownClassName, styles.dropdown)}
        label={label}
        errorMessage={errorMessage}
        element={
          <select ref={selectRef} {...dropdownProps} data-test={testId}>
            {children}
          </select>
        }
        testId={testId}
      />
    </div>
  );
}
