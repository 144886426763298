import _ from "lodash";
import { setLocale, SchemaOf } from "yup";
import { FormikProps, FormikValues, FormikErrors } from "formik";

export interface InputProps {
  onBlur?: (v?: any) => unknown;
  onChange: (v?: any) => unknown;
  value: any;
  name?: string;
  errorMessage: string | false;
  optionalLabel?: boolean;
  disabled?: boolean;
}

interface FieldPropsOptions {
  dom?: boolean;
}

export function inputProps<V>(props: FormikProps<V>, requiredFields?: string[], disableAll?: boolean) {
  return (field: string, { dom = true }: FieldPropsOptions = {}): InputProps => {
    let p: InputProps = {
      value: _.get(props.values, field) || "",
      disabled: disableAll,
      errorMessage: _.get(props.touched, field) && _.get(props.errors, field),
      onChange: () => undefined,
    };

    if (dom) {
      p = {
        ...p,
        onBlur: props.handleBlur,
        onChange: props.handleChange,
        name: field,
      };
    } else {
      p = {
        ...p,
        onBlur: () => props.setTouched(_.set(props.touched, field, true)),
        onChange: v => props.setFieldValue(field, v),
      };
    }

    if (requiredFields) {
      p = { ...p, optionalLabel: !requiredFields.includes(field) };
    }

    return p;
  };
}

export function nullify<T extends { [k: string]: any }>(
  obj: T,
): { [P in keyof T]: T[P] | { [k: string]: any } | null } {
  return _.mapValues(obj, val => {
    if (_.isObject(val) && !_.isArray(val)) {
      return nullify(val);
    }

    return val === "" ? null : val;
  });
}

setLocale({
  mixed: {
    required: "Please fill this field",
    notType: ({ type }: { type: any }) => `Must be a ${type}`,
  },

  string: {
    email: "This is not a valid email",
  },
});

export function yupValidate<V extends FormikValues, T>(
  schema: SchemaOf<T>,
  values: V,
): Promise<{ valid: boolean; errors: FormikErrors<V> }> {
  return new Promise(resolve => {
    return schema
      .validate(values, { abortEarly: false })
      .then(() => {
        resolve({ valid: true, errors: {} });
      })
      .catch((yupError: any) => {
        let errors: FormikErrors<V> = {};

        for (const err of yupError.inner) {
          if (!errors[err.path]) {
            errors = _.set(errors, err.path, err.message);
          }
        }

        resolve({ valid: false, errors });
      });
  });
}
