import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  kind?: "primary" | "secondary";
  style?: { [prop: string]: any };
}

export default function Nav({ children, className, kind = "primary", style }: Props) {
  return (
    <span className={cx(styles.nav, styles[kind], className)} style={style}>
      {children}
    </span>
  );
}
