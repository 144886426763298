import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { FormikProps, withFormik, FieldArray } from "formik";
import _ from "lodash";
import * as yup from "yup";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import Text from "components/LegacyText";
import Form from "components/Form";
import LoadingContainer from "components/LoadingContainer";
import { inputProps } from "utilities/formik";
import OptionsDropdown from "../../../../../../components/OptionsDropdown";
import SaveButton from "../SaveButton";
import { Helper2, HelperHiLi } from "../FormHelpers";
import { withProgramFormContext, validateAndNotify, optionSchema, ProgramFormContext } from "../Context";
import { IProgramRoles } from "../IProgramRequest";
import rolesQuery from "../roles.gql";
import styles from "./styles.scss";
import { ProgramsRouteParams } from "../../index";
interface RolesQuery {
  organization: {
    id: string;
    gigRequestsActive: boolean;
    gigSelfAssignmentActive: boolean;
    roles: Array<{ id: string; title: string; clientRate: number }>;
  };
}

const Fields: IProgramRoles = {
  roles: [],
};

export const ID = "talentRequirements";

interface Props extends RouteComponentProps<ProgramsRouteParams>, FormikProps<IProgramRoles> {
  programForm: ProgramFormContext;
  data: {
    loading: boolean;
  } & RolesQuery;
}

class TalentRequirements extends React.PureComponent<Props> {
  public render() {
    const {
      data: { organization, loading },
      values: { roles },
      programForm: { canEdit, locationToSection },
      setFieldValue,
    } = this.props;
    const input = inputProps(this.props, undefined, !canEdit);

    let content;

    if (loading) {
      content = <LoadingContainer message="Loading your activities..." />;
    } else if (roles.length) {
      content = (
        <FieldArray
          name={"roles"}
          render={() =>
            roles.map((role, index) => {
              return (
                <Form.Section
                  key={index}
                  header={
                    <React.Fragment>
                      <span className={styles.roleName}>
                        {organization &&
                          _.get(
                            organization.roles.find(r => r.id === role.roleId),
                            "title",
                          )}
                      </span>
                    </React.Fragment>
                  }
                >
                  <Form.TextBox
                    {...input(`roles.${index}.duties`)}
                    type={"text"}
                    label={"RESPONSIBILITIES (Shown to end user)"}
                    placeholder={"Write here…"}
                    multiline
                    rows={3}
                  />

                  <Form.TextBox
                    {...input(`roles.${index}.profile`)}
                    type={"text"}
                    label={"STAFFING GUIDANCE (Not shown to end user)"}
                    placeholder={"Industry certifications, interests, experience, etc."}
                  />

                  <Form.HorizontalGroup>
                    <OptionsDropdown
                      group={"dressCode"}
                      ddProps={{ ...input(`roles.${index}.uniform.key`), label: "DRESS CODE (Shown to end user)" }}
                      descProps={{
                        ...input(`roles.${index}.uniform.description`),
                        label: "DRESS CODE",
                        optionalLabel: true,
                      }}
                    />
                  </Form.HorizontalGroup>
                  {organization.gigRequestsActive && (
                    <Form.Checkbox
                      className={styles.gigOptionCheckbox}
                      checked={!!role.requestable}
                      onCheck={isChecked => {
                        setFieldValue(`roles.${index}.requestable`, isChecked);
                      }}
                    >
                      Make Requestable
                    </Form.Checkbox>
                  )}
                  {organization.gigSelfAssignmentActive && (
                    <Form.Checkbox
                      className={styles.gigOptionCheckbox}
                      checked={!!role.selfAssignable}
                      onCheck={isChecked => {
                        setFieldValue(`roles.${index}.selfAssignable`, isChecked);
                      }}
                    >
                      Make Autonomous
                    </Form.Checkbox>
                  )}
                </Form.Section>
              );
            })
          }
        />
      );
    } else {
      content = (
        <div className={styles.emptyState}>
          <Text.Display3 kind={"secondary"}>We need some activities</Text.Display3>
          <Text.P3 kind={"secondary"} className={styles.selectARole}>
            Select at least one in{" "}
            {locationToSection ? (
              <Text.NewLink3 component={Link} to={locationToSection("activities")}>
                Activities
              </Text.NewLink3>
            ) : (
              "Activities"
            )}{" "}
            to continue
          </Text.P3>
        </div>
      );
    }

    return (
      <React.Fragment>
        <AccordionPart>
          <AccordionPartHelper>
            <HelperHiLi>
              Remember: Any changes you make here will apply to this Program alone. Your company’s standard Activities
              will remain unchanged.
            </HelperHiLi>
          </AccordionPartHelper>
          <AccordionPartContent>
            {content}

            <SaveButton id={ID} />
          </AccordionPartContent>
        </AccordionPart>
      </React.Fragment>
    );
  }
}

const transactionalSchema = yup.object().shape({
  roles: yup
    .array()
    .required("Please select at least one role")
    .of(
      yup
        .object()
        .shape({
          duties: yup.string().required().nullable(),
          profile: yup.string().required().nullable(),
          uniform: optionSchema,
        })
        .nullable(),
    )
    .nullable(),
});

const soloSchema = yup.object().shape({
  roles: yup
    .array()
    .required("Please select at least one role")
    .of(
      yup
        .object()
        .shape({
          duties: yup.string().nullable(),
          profile: yup.string().nullable(),
          uniform: optionSchema,
        })
        .nullable(),
    )
    .nullable(),
});

export default compose(
  withRouter,
  withProgramFormContext,

  withFormik<Props, IProgramRoles>({
    mapPropsToValues: props => ({ ...Fields, ...props.programForm.values }),
    validate: validateAndNotify(transactionalSchema, soloSchema, soloSchema, ID),
    handleSubmit: () => {},
  }),

  graphql<Props, RolesQuery, { orgId: string }, unknown>(rolesQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
      },
    }),
  }),
)(TalentRequirements);
