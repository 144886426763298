import * as React from "react";
import cx from "classnames";
import { Mobile } from "components/Layout/Responsive";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function HorizontalGroup({ children, className }: Props) {
  return <div className={cx(styles.group, className)}>{children}</div>;
}

export function DesktopHorizontalGroup({ children, className }: Props) {
  return (
    <Mobile>{isMobile => (isMobile ? children : <HorizontalGroup children={children} className={className} />)}</Mobile>
  );
}
