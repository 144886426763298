import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { RouteComponentProps, Redirect, Route, Switch, Link } from "react-router-dom";
import _ from "lodash";

import lockIcon from "assets/lock.svg";
import archiveIcon from "assets/archive.svg";
import editIcon from "assets/edit.svg";

import styles from "./styles.scss";

import { OverviewQuery } from "gql-gen";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import AccordionLayout from "components/AccordionLayout";
import AccordionSection from "components/AccordionLayout/AccordionSection";
import LoadingContainer from "components/LoadingContainer";
import Button from "components/Button";
import Clickable from "components/Clickable";
import variables from "styles/variables";
import { isUserAtLeast } from "interfaces/user";
import { isPinata } from "utilities/flavor";
import { getProgramIdFromSearch } from "utilities/routes";

import overviewQuery from "./overview.gql";
import upsertProgram from "./upsertProgram.gql";
import archiveProgram from "./archiveProgram.gql";

import ProgramForm from "../Form";
import { UserInfoQuery, withUserInfo } from "../../../UserInfo";
import { getSections } from "./sections";
import ProgramInfo from "./ProgramInfo";
import { ProgramsRouteParams } from "../index";
import ConfirmBox from "components/ConfirmBox";

interface ProgramOverviewRouteParams extends ProgramsRouteParams {
  programOverviewSections: string;
}

interface Props extends RouteComponentProps<ProgramOverviewRouteParams>, UserInfoQuery {
  data: {
    program: OverviewQuery["program"];
    organization: OverviewQuery["organization"];
    loading: boolean;
    error: any;
  };

  mutate: (m: any) => Promise<any>;
  archiveProgram: (m: any) => Promise<any>;
}

class ProgramOverview extends React.Component<Props> {
  state = {
    archiveConfirmation: false,
  };

  isMyPartnershipsSectionVisible() {
    const {
      data: { program, organization, loading, error },
      userInfo: { me, impersonator },
    } = this.props;
    const iAmSuperAdmin = (impersonator && impersonator.isAdmin) || (me && me.isAdmin);
    const iAmAtLeastOrgManager = isUserAtLeast(me, "manager");
    const myOrgOwnsProgram = program?.isOwnedByCurrentOrganization ?? false;
    const myOrgIsInvitedToProgramByOrgOwner =
      program?.clientOrganizations?.some(org => org.organization.id === program?.ownerOrganization?.id) ?? false;
    const myOrgHasRelevantFeatureFlags = !!organization?.partnershipsVisible || !!organization?.workspacesActive;
    if (loading || !!error) return false;
    return (
      (iAmSuperAdmin || iAmAtLeastOrgManager) &&
      (myOrgOwnsProgram || myOrgIsInvitedToProgramByOrgOwner) &&
      myOrgHasRelevantFeatureFlags
    );
  }

  isInvitedBySectionVisible() {
    const {
      data: { program, loading, error },
    } = this.props;
    if (loading || !!error) return false;
    return !!program && !!program.clientOrganizations && program.clientOrganizations.length > 0;
  }

  componentDidUpdate(prevProps: Props) {
    const {
      data,
      userInfo: { me },
    } = prevProps;

    if (!!data && !!data.program && !!this.props.data?.program && data?.program?.id !== this.props.data?.program?.id) {
      this.props.history.replace({
        ...this.props.location,
        pathname: `${this.props.match.url}/${getSections(
          false,
          me,
          this.isMyPartnershipsSectionVisible(),
          this.isInvitedBySectionVisible(),
        )
          .map(s => s.id)
          .join(",")}`,
      });
    }
  }

  private renderLayout: {
    (props: RouteComponentProps<ProgramOverviewRouteParams>): JSX.Element;
    (props: RouteComponentProps): JSX.Element;
  } = props => {
    const { programOverviewSections } = props.match.params as ProgramOverviewRouteParams;
    const {
      data: { program, organization, loading, error },
      userInfo: { me },
      mutate,
    } = this.props;

    const isOwnedByCurrentOrganization = program?.isOwnedByCurrentOrganization ?? false;
    const isMaestro = program?.executionType === "maestro";

    const sectionsDesc = getSections(
      isOwnedByCurrentOrganization,
      me,
      this.isMyPartnershipsSectionVisible(),
      this.isInvitedBySectionVisible(),
    );

    const openSections = getOpenSections(programOverviewSections);

    const updateSections = (sections: { [id: string]: boolean }) => {
      let openSections = [];

      for (const id in sections) {
        if (sections.hasOwnProperty(id)) {
          if (sections[id]) {
            openSections.push(`-${id}`);
          } else {
            openSections.push(id);
          }
        }
      }

      this.props.history.replace({
        ...this.props.location,
        pathname: `${this.props.match.url}/${openSections.join(",") || "closed"}`,
      });
    };

    const toggleSectionMode = (id: string) => {
      updateSections({ ...openSections, [id]: !openSections[id] });
    };

    const sections = sectionsDesc.map(
      ({ id, title, component: SectionComponent, form: SectionForm, nonOwnerEditable }) => {
        const canEdit =
          id === "partnership" && isMaestro
            ? me && me.isAdmin
            : me && isUserAtLeast(me, "manager") && (isOwnedByCurrentOrganization || nonOwnerEditable);

        const editing = canEdit && openSections[id];

        return (
          <AccordionSection
            key={id}
            id={id}
            className={styles.section}
            title={
              <React.Fragment>
                {title}
                &nbsp;&nbsp;
                {canEdit ? (
                  !editing && (
                    <Clickable
                      actionLabel={`Edit ${title}`}
                      className={styles.edit}
                      onClick={() => toggleSectionMode(id)}
                    >
                      <Icon src={editIcon} size={16} fill={variables.white} />
                    </Clickable>
                  )
                ) : (
                  <span data-tooltip="This section cannot be edited." className={styles.lock}>
                    <Icon src={lockIcon} size={16} fill={variables.gray3} />
                  </span>
                )}
              </React.Fragment>
            }
          >
            {() =>
              program ? (
                editing ? (
                  <SectionForm />
                ) : (
                  <SectionComponent executionType={program.executionType} />
                )
              ) : (
                <LoadingContainer message={"Just a sec..."} className={styles.sectionLoading} center />
              )
            }
          </AccordionSection>
        );
      },
    );

    const handleSectionsChanged = (sections: string[]) => {
      updateSections(sections.reduce((obj, sid) => ({ ...obj, [sid]: openSections[sid] }), {}));
    };

    const programRequest = program && {
      ..._.omit(program, ["highLevelStats", "myPartner", "type", "states", "singleTalentOrganization"]),
      roles: program?.roles?.map(role => (role ? _.omit(role, ["title", "myRate"]) : role)),
      payer: _.omit(program.payer, ["label", "group"]),
      demoKit: program.demoKit && {
        ...program.demoKit,
        shippingRequirements: _.omit(program.demoKit.shippingRequirements, ["label", "group"]),
      },

      sampleProductsSource: _.omit(program.sampleProductsSource, ["label", "group"]),
      schedulingPolicy: _.omit(program.schedulingPolicy, ["label", "group"]),
      minimumNotice: _.omit(program.minimumNotice, ["label", "group"]),
    };

    return (
      <ProgramInfo.Provider
        value={{
          ...program,
          organization,
        }}
      >
        <ProgramForm
          key={program && program.id}
          id={program && program.id}
          data={{ programRequest, loading, error }}
          sections={sectionsDesc.map(s => s.id)}
          mutate={mutate}
          canEdit={true}
          helperMargins={false}
          onSave={(sid: string) => toggleSectionMode(sid)}
        >
          <AccordionLayout
            className={styles.accLayout}
            openSections={Object.keys(openSections)}
            onSectionsChanged={handleSectionsChanged}
            showHelpersColumn={false}
          >
            {sections}
          </AccordionLayout>
        </ProgramForm>
      </ProgramInfo.Provider>
    );
  };

  public renderSideBar() {
    return (
      <aside className={styles.sideBar}>
        <div className={styles.sideBarContent}>
          <Text.H2 className={styles.needHelp}>Need help?</Text.H2>

          <Text.P4>
            <br />
            If you need technical support with the PINATA platform,{" "}
            <Text.NewLink3 kind={"secondary"} className={"intercom-selector"}>
              chat with us
            </Text.NewLink3>
            .
          </Text.P4>
        </div>
      </aside>
    );
  }

  doArchiveProgram = (programId: string) => {
    this.props
      .archiveProgram({
        variables: {
          id: programId,
        },
      })
      .then(() => {
        this.props.history.push(`/${this.props.match.params.orgId || ""}`);
        this.setState({ archiveConfirmation: false });
      });
  };

  public render() {
    const {
      location,
      match: {
        url,
        path,
        params: { orgId },
      },
      userInfo: { me },

      data: { program, error } = { program: null, loading: true, error: null },
    } = this.props;

    const id = getProgramIdFromSearch(location.search);

    if (!id || id.includes(",") || id === "all") {
      return (
        <div className={styles.overview}>
          <div className={styles.selectOnlyOne}>
            <Text.Display1>Slow down there!</Text.Display1>
            <Text.P2 className={styles.selectOnlyOneMessage}>
              You need to select a specific program to see its details.
            </Text.P2>

            <Link to={{ ...location, pathname: location.pathname + "/+select" }}>
              <Button kind={"primary"}>SELECT A PROGRAM</Button>
            </Link>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.overview}>
          <div className={styles.selectOnlyOne}>
            <Text.Display1>An error occurred</Text.Display1>
            <Text.P2 className={styles.selectOnlyOneMessage}>
              Something went wrong while loading this program's details.
            </Text.P2>

            <Button kind={"primary"} className={"intercom-selector"}>
              CONTACT SUPPORT
            </Button>
          </div>
        </div>
      );
    }

    const isInvitedByVisible = !!program && !!program.clientOrganizations && program.clientOrganizations.length > 0;

    return (
      <div className={styles.overview}>
        <div className={styles.overviewHeader}>
          <Text.Display1 kind={"tertiary"} className={styles.status}>
            Status:{" "}
            {program ? (
              program.archived ? (
                <span className={styles.statusInactive}>Inactive</span>
              ) : (
                <span className={styles.statusActive}>Active{program.atCapacity ? " (At Capacity)" : ""}</span>
              )
            ) : null}
          </Text.Display1>
          {program && program.managementState === "accepted" && me?.isAdmin ? (
            <Button
              kind={"redGradient"}
              className={styles.archiveProgramButton}
              onClick={() => this.setState({ archiveConfirmation: true })}
              testId={`archiveProgram`}
            >
              <Icon src={archiveIcon} size={14} fill={variables.white} />
              Archive
            </Button>
          ) : null}
        </div>
        {this.state.archiveConfirmation && program && me?.isAdmin ? (
          <ConfirmBox
            icon={archiveIcon}
            title={"Archive Program"}
            onNo={() => this.setState(p => ({ ...p, archiveConfirmation: false }))}
            onYes={() => this.doArchiveProgram(program.id)}
          >
            {`Are you sure you want to archive this program?`}
          </ConfirmBox>
        ) : null}

        <div className={styles.content}>
          <main className={styles.leftSide}>
            {isPinata && (
              <>
                <Text.Display3 className={styles.sectionTitle}>Performance Summary</Text.Display3>

                <Link to={`/${orgId || ""}/programs/${id}/reports`}>
                  <Button kind={"translucent"} className={styles.fullStatsBtn}>
                    Full reporting on this program
                  </Button>
                </Link>
              </>
            )}

            <Text.Display3 className={styles.sectionTitle}>
              {isPinata ? "Program" : "Check In Group"} Settings
            </Text.Display3>

            <Switch>
              <Route path={path + "/:programOverviewSections"} render={this.renderLayout} />
              <Redirect
                to={{
                  pathname:
                    url +
                    "/" +
                    getSections(false, me, this.isMyPartnershipsSectionVisible(), isInvitedByVisible)
                      .map(s => s.id)
                      .join(","),
                  search: location.search,
                }}
              />
            </Switch>
          </main>

          {this.renderSideBar()}
        </div>
      </div>
    );
  }
}

function getOpenSections(sectionsStr: string | null): { [key: string]: boolean } {
  if (!sectionsStr || sectionsStr === "closed") return {};

  return sectionsStr.split(",").reduce((obj, sid) => {
    const editing = sid.startsWith("-");
    return { ...obj, [editing ? sid.slice(1) : sid]: editing };
  }, {});
}

export default compose(
  withUserInfo,
  graphql<any, any, any, any>(overviewQuery, {
    skip: ({ location }) => {
      const id = getProgramIdFromSearch(location.search);
      return id.includes(",") || id === "all";
    },
    options: ({
      match: {
        params: { orgId },
      },
      location,
    }) => {
      const id = getProgramIdFromSearch(location.search);
      return {
        variables: {
          id,
          orgId,
        },
      };
    },
  }),

  graphql(upsertProgram),
  graphql(archiveProgram, {
    name: "archiveProgram",
  }),
)(ProgramOverview);
