import * as React from "react";
import Dropzone from "react-dropzone";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import Shortcuts, { shortcut } from "components/Shortcuts";
import variables from "styles/variables";
import file from "assets/file.svg";
import fileExcel from "assets/file-excel.svg";
import styles from "./styles.scss";

interface Props {
  accept?: string[] | null;
  title?: string;
  content?: string;
  rejectTitle?: string;
  rejectContent?: string;
  onDrop: (f: File) => void;
  large?: boolean;
  className?: string;
}

export default class DropFile extends React.PureComponent<Props> {
  private dropzone?: Dropzone | null;

  private onDrop = (files: File[]) => {
    if (!files[0]) {
      console.log("No files");
      return;
    }

    this.props.onDrop(files[0]);
  };

  private shortcuts = [shortcut("o", () => this.dropzone && this.dropzone.open())];

  public render() {
    const {
      accept,
      title = "Drag and Drop or click here",
      content = "to upload a document from your computer",
      rejectTitle = "Hmmm",
      rejectContent = "That file is not allowed",
      className,
      large,
    } = this.props;

    return (
      <Shortcuts shortcuts={this.shortcuts}>
        <Dropzone
          accept={accept ? accept.join(",") : undefined}
          ref={dropzone => (this.dropzone = dropzone)}
          onDrop={this.onDrop}
          className={cx(styles.dropzone, className)}
          activeClassName={styles.activeDropzone}
          rejectClassName={styles.rejectDropzone}
        >
          {({ isDragActive, isDragReject }: { isDragActive: boolean; isDragReject: boolean }) => (
            <div className={styles.dropzoneContent}>
              <div className={styles.icons}>
                <Icon src={file} size={large ? 80 : 60} fill={variables.blue1} />
                <Icon src={fileExcel} size={large ? 80 : 60} fill={variables.teal1} />
              </div>

              <Text.Display variant={large ? "v1" : "v4"} className={styles.dndText}>
                {isDragActive ? (isDragReject ? rejectTitle : "Drop it!") : title}
              </Text.Display>

              <Text.P variant={large ? "v2" : "v4"} kind={"secondary"} className={styles.dropzoneExp}>
                {isDragReject ? rejectContent : content}
              </Text.P>
            </div>
          )}
        </Dropzone>
      </Shortcuts>
    );
  }
}
