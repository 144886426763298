import React from "react";
import { IAction } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";
import icon from "assets/address-book.svg";
import { colors } from "styles/variables";
import { Form } from "modules/Connection/Form";
import { GigOwnerField } from "modules/Dashboard/Schema/Gigs/Fields/Owner";

const i18n = "gigs.actions.updateOwner";

export const UpdateOwnerAction: IAction = {
  key: "updateOwner",
  icon,
  labelKey: `${i18n}.label`,
  color: colors.blue2,
  allowInline: false,
  allowBatch: true,

  component: ({ done, renderSubmit }) => {
    return (
      <ActionBody done={done}>
        <Form
          form={[GigOwnerField]}
          close={done}
          renderSubmit={fp =>
            renderSubmit({
              ...fp,
              i18n,
            })
          }
        />
      </ActionBody>
    );
  },
};
