import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import Clickable, { ClickableProps } from "components/Clickable";
import arrowIcon from "assets/chevron.svg";
import styles from "./styles.scss";

interface Props extends Omit<ClickableProps, "children"> {
  direction: "back" | "forward" | "up" | "down";
  size: number;
  fill: string;
}

const PADDING = 5;

export default function ArrowButton({ size = 20, fill = "#fff", direction = "forward", ...cProps }: Props) {
  return (
    <Clickable {...cProps} className={cx(styles[direction], cProps.className)} style={{ minWidth: size, height: size }}>
      <Icon src={arrowIcon} size={size - PADDING * 2} fill={fill} />
    </Clickable>
  );
}

ArrowButton.defaultProps = {
  size: 20,
  fill: "#fff",
  direction: "forward",
};
