import { Program } from "bombon/lib/program";
import { ArrowFunctionExpression } from "bombon/lib/expression/arrowFunctionExpression";
import { BinaryExpression } from "bombon/lib/expression/binaryExpression";
import { CallExpression } from "bombon/lib/expression/callExpression";
import { Identifier } from "bombon/lib/expression/identifier";
import { Literal } from "bombon/lib/expression/literal";
import { LogicalExpression } from "bombon/lib/expression/logicalExpression";
import { MemberExpression } from "bombon/lib/expression/memberExpression";
import { UnaryExpression } from "bombon/lib/expression/unaryExpression";
import { BlockStatement } from "bombon/lib/statement/blockStatement";
import { ExpressionStatement } from "bombon/lib/statement/expressionStatement";
import { IfStatement } from "bombon/lib/statement/ifStatement";
import { ReturnStatement } from "bombon/lib/statement/returnStatement";

export const bombonNodeTypes = {
  Program,
  ArrowFunctionExpression,
  BinaryExpression,
  CallExpression,
  Identifier,
  Literal,
  LogicalExpression,
  MemberExpression,
  UnaryExpression,
  BlockStatement,
  ExpressionStatement,
  IfStatement,
  ReturnStatement,
};
