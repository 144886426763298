import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import LoadingContainer from "components/LoadingContainer";
import { addQueries } from "utilities/routes";
import { isLoggedIn, goToLogin } from "utilities/authentication";

interface Props extends RouteProps {
  redirect?: string;
  path: string;
  component: any;
}

export default function PrivateRoute({ path, redirect, component: Component, ...rest }: Props) {
  const search = addQueries(window.location.search, { next: path });

  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        if (isLoggedIn()) {
          return <Component {...props} />;
        }

        if (redirect) {
          return <Redirect to={{ pathname: redirect, search }} />;
        }

        goToLogin();

        return <LoadingContainer message="Taking you to login" />;
      }}
    />
  );
}
