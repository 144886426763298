import { graphql } from "@apollo/client/react/hoc";
import { MutationFunction } from "@apollo/client/react";
import * as React from "react";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { RouteComponentProps } from "react-router-dom";
import * as filestack from "filestack-js";
import _ from "lodash";
import * as yup from "yup";
import Icon from "components/Icon";
import Form from "components/Form";
import LegacyModal from "components/LegacyModal";
import Shortcuts, { shortcut } from "components/Shortcuts";
import Text from "components/LegacyText";
import Pill from "components/Pill";
import LoadingContainer from "components/LoadingContainer";
import { inputProps } from "utilities/formik";
import { FS_API_KEY, FS_SECURE_API_KEY, FS_SECURITY } from "utilities/filestack";
import { getUrlQueries, getProgramIdFromSearch } from "utilities/routes";
import checkIcon from "assets/check.svg";
import variables from "styles/variables";
import DropFile from "../../../../../components/DropFile";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import documentsQuery from "../DocumentsTable/documents.gql";
import upsertDocument from "./upsertDocument.gql";
import styles from "./styles.scss";
import { OrganizationRouteParams } from "../../index";
import { CloseModalHandler } from "utilities/modals";
import { Document } from "modules/Dashboard/interfaces/Document";
import { DocumentsQuery } from "modules/Dashboard/Organization/LegacySettings/DocumentsTable/Table";
import { flowRight as compose } from "lodash";

interface DocumentFormValues {
  name: string;
  description: string;
  url: string;
  isLink: boolean;
}

interface Props extends FormikProps<DocumentFormValues>, RouteComponentProps<OrganizationRouteParams> {
  mutate: MutationFunction<
    { upsertDocument: Document },
    { input: Omit<DocumentFormValues, "isLink"> & { organizationId: string } }
  >;
  handleClose: CloseModalHandler;
}

interface State {
  uploadState: "none" | "uploading" | "uploaded" | "error";
  savingState: "none" | "saving" | "error";
  fileName: string | null;
}

const INITIAL_STATE: State = {
  savingState: "none",
  uploadState: "none",
  fileName: null,
};

export const NEW_ID_PLACEHOLDER = "NEW_ID_HERE";

class DocumentFormModal extends React.Component<Props, State> {
  readonly state: State = INITIAL_STATE;

  private addAnother: boolean = false;

  private handleSubmit = async (values: DocumentFormValues, { resetForm }: FormikHelpers<DocumentFormValues>) => {
    const {
      mutate,
      match: {
        params: { orgId },
      },
    } = this.props;

    this.setState({ savingState: "saving" });

    try {
      const result = await mutate({
        variables: {
          input: {
            ..._.omit(values, "isLink"),
            organizationId: orgId,
          },
        },

        update: (cache, { data }) => {
          const query = { query: documentsQuery, variables: { orgId } };
          const queryResult = cache.readQuery<DocumentsQuery>(query);

          if (!data || !queryResult) return;

          cache.writeQuery({
            ...query,
            data: { documents: queryResult.documents.concat([data.upsertDocument]) },
          });
        },
      });

      if (this.addAnother) {
        this.addAnother = false;
        this.setState(INITIAL_STATE);
        resetForm();
      } else if (result && result.data && result.data.upsertDocument.id) {
        this.handleClose(result.data.upsertDocument.id);
      } else {
        this.handleClose();
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingState: "error" });
    }
  };

  private handleClose = (newId?: string) => {
    const {
      handleClose,
      location: { search },
      history,
    } = this.props;
    let { next } = getUrlQueries(search);
    const programId = getProgramIdFromSearch(search);

    if (next) {
      if (newId) {
        next = next.replace(NEW_ID_PLACEHOLDER, newId);
      }

      history.replace(`${next}${programId ? `&program=${programId}` : ""}`);
    } else {
      handleClose();
    }
  };

  private handleFileDropped = (formikProps: FormikProps<DocumentFormValues>) => async (file: File) => {
    formikProps.setFieldValue("name", file.name);

    this.setState({
      uploadState: "uploading",
    });

    try {
      const { url } = await filestack.init(FS_SECURE_API_KEY).upload(file, {}, {}, {}, FS_SECURITY);

      formikProps.setFieldValue("url", url);
      this.setState({ uploadState: "uploaded", fileName: file.name });
    } catch (e) {
      console.error(e);
      this.setState({ uploadState: "error" });
    }
  };

  private renderUploader(formikProps: FormikProps<DocumentFormValues>) {
    const { uploadState, fileName } = this.state;

    if (uploadState === "uploading") {
      return (
        <LoadingContainer
          message={"Uploading your file…"}
          tip={"You can fill in the details in the meantime"}
          className={styles.uploadingFile}
          center
        />
      );
    }

    if (uploadState === "uploaded") {
      const handleRemove = () => {
        this.setState({ uploadState: "none", fileName: null });
        formikProps.setFieldValue("url", "");
      };

      return (
        <div className={styles.uploaded}>
          <div className={styles.checkIcon}>
            <Icon src={checkIcon} size={60} fill={variables.teal1} />
          </div>
          <Text.P3 className={styles.uploadedMessage}>Your file has been uploaded!</Text.P3>
          <div className={styles.filePill}>
            <Pill onRemove={handleRemove}>{fileName || "File"}</Pill>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.dropZone}>
        <DropFile onDrop={this.handleFileDropped(formikProps)} />
      </div>
    );
  }

  private renderForm = (formikProps: FormikProps<DocumentFormValues>) => {
    const {
      handleSubmit,
      values: { isLink },
      setFieldValue,
    } = formikProps;
    const { savingState, uploadState } = this.state;

    let message = <Text.Message>&nbsp;&nbsp;</Text.Message>;

    if (savingState === "saving") {
      message = <Text.Message kind={"neutral"}>Saving…</Text.Message>;
    } else if (savingState === "error" || uploadState === "error") {
      message = <Text.Message kind={"error"}>An error has occurred. Try again.</Text.Message>;
    }

    const input = inputProps(formikProps, ["name", "url"]);

    const handleClose = () => this.handleClose();
    const hs = () => handleSubmit();

    return (
      <Shortcuts shortcuts={[shortcut("s s", hs)]}>
        <LegacyModal noPadding className={styles.modal}>
          <ModalHeader mainAction={"New document"} onClose={handleClose} />

          <div className={styles.form}>
            <Form.Section className={isLink ? styles.linkSection : styles.section}>
              {isLink ? (
                <Form.TextBox {...input("url")} label={"INSERT URL"} placeholder={"https://www.example.com"} />
              ) : (
                this.renderUploader(formikProps)
              )}

              <Text.P4 className={styles.linkOption}>
                Or you can&nbsp;
                <span
                  onClick={() => {
                    setFieldValue("url", "", false);
                    setFieldValue("isLink", !isLink);
                  }}
                >
                  {isLink ? "upload a file" : "provide a link to a webpage"}
                </span>
              </Text.P4>
            </Form.Section>

            <Form.Section>
              <Form.TextBox {...input("name")} label={"SAVE AS"} placeholder={"Write document name here…"} />
            </Form.Section>

            <Form.Section>
              <Form.TextBox
                {...input("description")}
                multiline
                rows={3}
                label={"DESCRIPTION"}
                placeholder={"Describe your document here..."}
              />
            </Form.Section>

            {message}
          </div>

          <ModalFooter
            actionName={"SAVE DOCUMENT"}
            actionButtonType={"submit"}
            actionDisabled={savingState === "saving"}
            secondaryActionName={"SAVE AND ADD ANOTHER"}
            onAction={hs}
            onSecondaryAction={() => {
              this.addAnother = true;
              handleSubmit();
            }}
            onCancel={handleClose}
          />
        </LegacyModal>
      </Shortcuts>
    );
  };

  public render() {
    return (
      <Formik
        initialValues={{ name: "", description: "", url: "", isLink: false }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }
}

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().nullable(),
  url: yup.string().required(),
  isLink: yup.bool(),
});

export default compose(graphql(upsertDocument))(DocumentFormModal);
