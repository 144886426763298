import { useState, Dispatch, SetStateAction, useRef } from "react";

export function useDebouncedState<S>(initialState: S | (() => S), wait: number): [S, S, Dispatch<SetStateAction<S>>] {
  const [realState, setRealState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(initialState);
  const timeoutId = useRef<number | null>(null);

  return [
    realState,
    debouncedState,
    state => {
      setRealState(state);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = window.setTimeout(() => {
        timeoutId.current = null;
        setDebouncedState(state);
      }, wait);
    },
  ];
}
