import React from "react";

import { GigsSavedLocationsFilterFragment } from "gql-gen";

import { ItemLabel } from "components/Searchable";
import { connection } from "utilities/connections";
import { ListFilter } from "modules/Connection/Filters/ListFilter";
import { makeIdsFilterState, IdsFilterState } from "modules/Connection/Filters/idsFilter";
import { FilterDefinition } from "modules/Connection/types";

import savedLocationsFragment from "./savedLocations.gql";

export const locationsConnection = connection<GigsSavedLocationsFilterFragment, any>({
  name: "GigsWorkflowSavedLocationFilter",
  entry: { name: "organizationLocations" },
  variables: { search: "String" },
});

const renderName = (item: GigsSavedLocationsFilterFragment) => (
  <>
    <ItemLabel>{item.name}</ItemLabel> {item.internalIdentifier && <ItemLabel>({item.internalIdentifier})</ItemLabel>}
  </>
);

export const SavedLocationFilter: FilterDefinition<IdsFilterState> = {
  component: ({ state, setState, mode }) => (
    <ListFilter<GigsSavedLocationsFilterFragment>
      connection={locationsConnection}
      value={state.ids}
      fragment={savedLocationsFragment}
      onChange={ids => setState({ ids })}
      variables={{ search: "" }}
      renderName={renderName}
      mode={mode}
      testId={"filters.location.savedLocation"}
    />
  ),

  ...makeIdsFilterState<GigsSavedLocationsFilterFragment>({
    labelKey: "gigs.filters.location.locations.label",
    fieldName: "savedlocationid",
    connection: locationsConnection,
    renderName,
    fragment: savedLocationsFragment,
  }),
};
