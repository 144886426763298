import { useState, useCallback } from "react";
import { getJwt } from "utilities/authentication";

type OnSuccess<T> = (data: T) => void;

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RequestOptions = {
  method?: Method;
  headers?: Record<string, string>;
} & ({ method?: "GET" | "DELETE"; body?: never } | { method?: "POST" | "PUT" | "PATCH"; body?: string });

type RequestResult<T> = {
  loading: boolean;
  error: string | null;
  data: T | null;
  request: (url: string, options?: RequestOptions, orgId?: string, onSuccess?: OnSuccess<T>) => Promise<T>;
  clearError: () => void;
};

export function useRequest<T>(): RequestResult<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);

  const request = useCallback(async (url: string, options: RequestOptions = {}, orgId, onSuccess?: OnSuccess<T>) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${getJwt()}`,
          "X-Pinata-Organization-Id": orgId,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      if (onSuccess) {
        onSuccess(data);
      }
      setData(data);
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      setError(e.message);
      throw e;
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError, data };
}
