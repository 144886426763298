import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export type NewLinkVariant = "v1" | "v2" | "v3" | "v4";

export interface NewLinkProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
  variant?: NewLinkVariant;
  kind?: "primary" | "secondary" | "reverse";
  underline?: "always" | "hover" | "never";
  component?: React.ComponentType<any>;
  target?: string;
  [k: string]: any;
}

export default function NewLink({
  href = "javascript:void(0);", //eslint-disable-line
  kind = "primary",
  variant = "v1",
  component: LinkComponent,
  underline = "hover",
  className,
  children,
  ...props
}: NewLinkProps) {
  const op = {
    className: cx(styles.link, styles[kind], styles[variant], styles[underline], className),
    href: href,
    ...props,
  };

  if (LinkComponent) {
    return <LinkComponent {...op}>{children}</LinkComponent>;
  }

  return <a {...op}>{children}</a>;
}
