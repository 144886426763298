import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export type DisplayVariant = "v1" | "v2" | "v3" | "v4" | "v5";

export interface DisplayProps {
  children: React.ReactNode;
  inline?: boolean;
  variant?: DisplayVariant;
  kind?: "primary" | "reverse" | "secondary" | "tertiary" | "green" | "highlight";
  className?: string;
  tooltip?: string;
}

export default function Display({
  variant = "v1",
  children,
  inline,
  kind = "primary",
  tooltip,
  className,
}: DisplayProps) {
  return (
    <div
      className={cx(styles.display, styles[variant], styles[kind], className, inline && styles.inline)}
      title={typeof tooltip === "string" ? tooltip : typeof children === "string" ? children : undefined}
    >
      {children}
    </div>
  );
}
