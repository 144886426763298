import * as React from "react";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
}

export default function AccordionPartContent({ children }: Props) {
  return <div className={styles.dataContainer}>{children}</div>;
}
