import React, { ReactNode } from "react";
import { GigsStatusFragment } from "gql-gen";
import { format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { CellBase, CR1, CR2, CR3, CRNone } from "modules/Connection/CellBase";
import { ImpatientFragmentPopup } from "modules/Connection/ImpatientFragmentPopup";
import styles from "./styles.scss";
import Text from "components/Text";
import { CancellationDetails } from "./CancellationDetails";
import cancellationDetailsFragment from "./CancellationDetails/cancellationDetails.gql";
import { ICell } from "modules/Connection/types";
import fragment from "./status.gql";
import icon from "assets/bell.svg";

const DATE_FORMAT = "M/dd/yy";

export const StatusCell: ICell<GigsStatusFragment> = {
  component: props => {
    const { t } = useTranslation();

    const { data } = props;

    if (!data) {
      return <CellBase {...props}>{() => <CR1>{t("gigs.columns.status.newGig")}</CR1>}</CellBase>;
    }

    const {
      id,
      workflowStateKey,
      workflowStateNameKey,
      cancellationReason,
      cancelledAt,
      workflowStateGroup,
      approvalReviewedAt,
      startTime,
      approvalRequestedAt,
      cancelledBy,
    } = data;

    // useEffect(() => {
    //   if (!group) {
    //     Rollbar.error(`Unknown workflow state: ${workflowStateKey}`);
    //   }
    // }, [group]);

    if (!workflowStateGroup) {
      return <CellBase {...props}>{() => <CRNone>Unknown</CRNone>}</CellBase>;
    }

    let stateLabel: string = t(`gigs.stateNames.${workflowStateNameKey}`);

    let secondLine: ReactNode;
    let thirdLine: string | undefined = undefined;

    const unapproved = workflowStateGroup === "unapproved";

    // FIXME: Update to use new states
    if (unapproved) {
      if (approvalReviewedAt) {
        const time = format(parseISO(approvalReviewedAt), DATE_FORMAT);

        if (workflowStateKey === "approvalReturned") stateLabel = t("gigs.columns.status.returned", { time });
        else if (workflowStateKey === "approvalRejected") stateLabel = t("gigs.columns.status.rejected", { time });
      } else if (approvalRequestedAt) {
        stateLabel = t("gigs.columns.status.lastRequest", { time: format(parseISO(approvalRequestedAt), DATE_FORMAT) });
      }

      if (workflowStateKey === "approvalNeeded") {
        secondLine = <CR2 underline="always">Review</CR2>;
      } else {
        secondLine = <CR2 underline="always">Request approval</CR2>;
      }

      thirdLine = stateLabel;
    } else if (workflowStateGroup === "cancelled") {
      secondLine = (
        <ImpatientFragmentPopup
          id={id}
          fragment={cancellationDetailsFragment}
          component={CancellationDetails}
          dependencies={[id]}
        >
          <CR2 placeholder={t("gigs.columns.status.noReason")} color="white" underline="hover">
            {cancellationReason && cancellationReason.label}
          </CR2>
        </ImpatientFragmentPopup>
      );

      if (cancelledAt)
        thirdLine = t("gigs.columns.status.cancelledAt", { time: format(parseISO(cancelledAt), DATE_FORMAT) });
    } else {
      secondLine = <CR2>{stateLabel}</CR2>;

      if (startTime) {
        const start = parseISO(startTime);
        thirdLine = t(isPast(start) ? "time.ago" : "time.away", {
          distance: formatDistanceToNow(start),
        });
      }
    }

    const firstTwoLines = (
      <>
        <CR1>{t(`gigs.stateGroups.${workflowStateGroup}`)}</CR1>
        {secondLine}
      </>
    );

    return (
      <CellBase dark {...props}>
        {mode =>
          mode === "detailed" ? (
            <div className={styles.bigStatus}>
              <div data-test="gigs.viewGig.status">
                {firstTwoLines}{" "}
                {cancellationReason && (
                  <CR3 color="white">
                    {cancellationReason.label}{" "}
                    {cancelledBy && `- cancelled by ${cancelledBy.firstName} ${cancelledBy.lastName}`}
                  </CR3>
                )}
              </div>

              <Text font="wesR" color="white" size={24} className={styles.detailedDate}>
                {thirdLine}
              </Text>
            </div>
          ) : (
            <>
              {firstTwoLines}

              {mode === "compact" && thirdLine ? <CR2>{thirdLine}</CR2> : <CR3 color="white">{thirdLine}</CR3>}
            </>
          )
        }
      </CellBase>
    );
  },
  icon,
  fragment,
  transparent: true,
};
