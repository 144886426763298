import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import ProgressCircle from "components/ProgressCircle";
import ListItem from "components/ListView/ListItem";
import ArrowButton from "components/ArrowButton";
import checkIcon from "assets/check.svg";
import variables from "styles/variables";
import styles from "./styles.scss";
import { useRef, useLayoutEffect } from "react";
import { StepState } from "../StepState";

interface ReportStepItemProps {
  title: string;
  subtitle?: string;
  progress: number;
  stepState?: StepState;
  open?: boolean;
  onClick?: React.MouseEventHandler;
  red?: boolean;
  testId?: string;
  active?: boolean;
  children?: React.ReactNode;
}

export default function StepItem({
  title,
  subtitle,
  progress,
  stepState,
  open,
  onClick,
  red,
  testId,
  active = false,
  children,
}: ReportStepItemProps) {
  const savingState = (() => {
    if (!stepState) {
      return null;
    } else if (stepState.savingChanges.length) {
      return "saving";
    } else if (stepState.failedChanges.length) {
      return "error";
    } else {
      return "saved";
    }
  })();

  const completed = progress === 100;
  const greenBg = completed && (!savingState || savingState !== "error");
  let progressIcon;

  if (completed && (!savingState || savingState === "saved")) {
    progressIcon = <Icon size={24} fill={greenBg ? variables.white : variables.teal1} src={checkIcon} />;
  } else {
    progressIcon = (
      <ProgressCircle.Animated
        size={24}
        trackColor={active ? { empty: "rgba(255, 255, 255, 0.3)", progress: "rgba(255, 255, 255, 0.4)" } : undefined}
        value={savingState === "saving" ? "indeterminate" : progress}
        color={savingState === "error" ? variables.pink1 : greenBg ? variables.white : variables.teal1}
      />
    );
  }

  const itemRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (active && arrowRef.current && itemRef.current) {
      const borderHeight = itemRef.current.getBoundingClientRect().height / 2;

      arrowRef.current.style.borderTop = `${borderHeight}px solid transparent`;
      arrowRef.current.style.borderBottom = `${borderHeight}px solid transparent`;
    }
  }, [name, active]);

  return (
    <div style={{ position: "relative" }}>
      <ListItem
        itemRef={itemRef}
        className={cx(
          greenBg ? styles.greenItem : styles.item,
          red && styles.redBg,
          active && styles.activeBg,
          active && styles.active,
        )}
        onClick={onClick}
        testId={testId}
      >
        <div className={styles.progress}>{progressIcon}</div>

        <div>
          <Text.Display4 kind={greenBg || red ? "reverse" : "primary"}>{title}</Text.Display4>
          {subtitle && (
            <Text.Display5 kind={greenBg || red ? "reverse" : "secondary"} className={styles.subtitle}>
              {subtitle}
            </Text.Display5>
          )}
        </div>

        <div className={"spacer"} />

        <ArrowButton
          direction={open ? "up" : "forward"}
          className={cx(
            styles.arrowBtn,
            greenBg ? styles.whiteArrow : red ? styles.whiteArrowRedStroke : null,
            active && styles.invisible,
          )}
        />

        <div className={styles.arrow} ref={arrowRef}>
          {" "}
        </div>
      </ListItem>
      {children}
    </div>
  );
}
