import * as React from "react";

import BaseFormElement from "components/Form/BaseFormElement";

import styles from "./styles.scss";

import Searchable, { SearchableProps } from "../index";

export interface SearchableSelectProps extends Pick<SearchableProps, Exclude<keyof SearchableProps, "children">> {
  name: string | null;
  className?: string;
  wrapperClassName?: string;
  errorMessage?: string;
  disabled?: boolean;
  testId?: string;
}

export default function SearchableSelect({
  name,
  className,
  wrapperClassName,
  errorMessage,
  disabled,
  testId,
  ...props
}: SearchableSelectProps) {
  return (
    <Searchable {...props} optionsYOffset={1} className={typeof wrapperClassName === "string" ? wrapperClassName : ""}>
      {({ inputProps, focusInput, isOpen }) => (
        <div onClick={focusInput} className={isOpen ? styles.focused : ""}>
          <BaseFormElement
            elementClassName={inputProps.className ? inputProps.className : ""}
            arrow={isOpen ? "up" : "down"}
            errorMessage={typeof errorMessage === "string" ? errorMessage : false}
            className={className}
            label={name}
            disabled={!!disabled}
            element={<input {...inputProps} />}
            testId={testId}
          />
        </div>
      )}
    </Searchable>
  );
}
