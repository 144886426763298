import { useState, useEffect, DependencyList } from "react";

export type PromiseState =
  /** No promise has been provided */
  | "standby"
  /** The promise is running */
  | "pending"
  /** The promise finished without any errors */
  | "resolved"
  /** The promise finished with errors */
  | "rejected";

type Standby<T> = { result: T | undefined; error: undefined; state: "standby" };
type Pending<T> = { result: T | undefined; error: undefined; state: "pending" };
type Rejected<T, Error> = { result: T | undefined; error: Error; state: "rejected" };
type Resolved<T> = { result: T; error: undefined; state: "resolved" };

export type UsePromiseState<T, Error> = Standby<T> | Pending<T> | Rejected<T, Error> | Resolved<T>;

/**
 * Executes and tracks the state of a promise.
 *
 * @param promiseFn A function that returns a promise or null if it should be skipped
 * @param deps `promiseFn` will be executed whenever at least one of these dependencies change (a lá useEffect).
 *
 * @returns A tuple containing, in order, the result, error, and state of a promise
 */
export function usePromise<T, Error = any>(
  promiseFn?: () => Promise<T>,
  deps?: DependencyList,
): UsePromiseState<T, Error> {
  const [result, setResult] = useState<T | undefined>(undefined);
  const [state, setState] = useState<PromiseState>("standby");
  const [error, setError] = useState<any>();

  useEffect(() => {
    setError(undefined);

    if (promiseFn) {
      setState("pending");
      // setResult(undefined);

      const r = promiseFn();
      r.then(result => {
        setResult(result);
        setState("resolved");
      }).catch(error => {
        setError(error);
        setState("rejected");
      });
    } else {
      setResult(undefined);
      setState("standby");
    }
  }, deps);

  return { result, error, state } as UsePromiseState<T, Error>;
}
