import React, { useState, useLayoutEffect } from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
}

export function Working({ active, className, ...props }: Props) {
  const [delayedActive, setDelayedActive] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (active) setDelayedActive(true);
    if (!active) setDelayedActive(false);
  }, [active]);

  return (
    <div
      className={cx(className, delayedActive && styles.working)}
      onAnimationIteration={e => {
        if (!active) {
          e.currentTarget.classList.remove(styles.working);
          setDelayedActive(false);
        }
      }}
      {...props}
    />
  );
}
