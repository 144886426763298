@import "~styles/variables";

.action {
  font-size: 14px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gray-2;
  box-sizing: border-box;
  padding: $m $m $m $s;
}

.side {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $m;
  background-color: white;

  &:first-child {
    border-right: 1px solid $gray-4;
  }
}
