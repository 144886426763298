const PREFIXES = ["", "k", "M", "G", "T", "P", "E"];

export function formatNumber(value: number, decimals: number = 1, cutOff: number = 10000) {
  if (value < cutOff) {
    return value.toLocaleString();
  }

  let len = value.toString().length;
  len -= len % 3;

  const d = Math.pow(10, decimals);

  return Math.round((value * d) / Math.pow(10, len)) / d + PREFIXES[len / 3];
}

export function parseIntIfNeeded(value: string | number): number {
  return typeof value === "string" ? parseInt(value) : value;
}

export function parseFloatIfNeeded(value: string | number): number {
  return typeof value === "string" ? parseFloat(value) : value;
}

export function parseIntWithFallback(value: string | null | undefined, fallback: number): number {
  if (value === null || value === undefined) return fallback;

  const parsed = parseInt(value, 10);
  return Number.isNaN(parsed) ? fallback : parsed;
}

export const isNumber = (n: unknown): n is number => typeof n === "number";
