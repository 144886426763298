import * as React from "react";
import cx from "classnames";
import Text from "components/Text";
import styles from "./styles.scss";

export interface ListItemProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  className?: string;
  focused?: boolean;
  itemRef?: React.Ref<HTMLDivElement>;
  testId?: string;
}

export default function ListItem({
  children,
  className,
  onClick,
  onMouseDown,
  focused,
  itemRef,
  testId,
}: ListItemProps) {
  if (typeof children === "string") {
    children = <Text>{children}</Text>;
  }

  return (
    <div
      className={cx(styles.item, focused && styles.focused, className)}
      ref={itemRef}
      onMouseDown={onMouseDown}
      onClick={onClick}
      data-test={testId}
    >
      {children}
    </div>
  );
}
