import * as React from "react";
import NumberEasing from "components/NumberEasing";
import variables from "styles/variables";
import styles from "./styles.scss";

interface Props {
  value: "indeterminate" | number;
  size?: number;
  color?: string;
  trackColor?: { empty: string; progress: string };
  showValue?: boolean;
  svgRef?: any;
}

const INDETERMINATE_SIZE = 15;

export default function ProgressCircle({
  value,
  size = 50,
  color = variables.teal1,
  trackColor = { empty: "rgba(0, 0, 0, 0.05)", progress: "rgba(0, 0, 0, 0.1)" },
  svgRef,
}: Props) {
  const progress = value === "indeterminate" ? INDETERMINATE_SIZE : value;

  const half = size / 2;
  const sw = size / 8;
  const r = half - sw / 2;
  const c = r * 2 * Math.PI;
  const offset = c * (1 - progress / 100);

  return (
    <svg
      ref={svgRef}
      className={value === "indeterminate" ? styles.indeterminateProgressCircle : styles.progressCircle}
      width={size}
      height={size}
    >
      <circle
        r={r}
        cx={half}
        cy={half}
        fill="transparent"
        stroke={progress > 0 ? trackColor.progress : trackColor.empty}
        strokeWidth={sw}
        strokeDasharray={c}
        strokeDashoffset="0"
      />

      <circle
        r={r}
        cx={half}
        cy={half}
        fill="transparent"
        stroke={color}
        strokeLinecap={"round"}
        strokeWidth={sw}
        strokeDasharray={c}
        strokeDashoffset={offset}
      />
    </svg>
  );
}

interface AnimatedState {
  wait: boolean;
}

ProgressCircle.Animated = class extends React.Component<Props, AnimatedState> {
  public svg = React.createRef<Element>();
  readonly state: AnimatedState = { wait: false };

  public UNSAFE_componentWillReceiveProps({ value: nextValue }: Props) {
    const { value } = this.props;

    if (value === "indeterminate" && nextValue !== "indeterminate") {
      const svg = this.svg.current;

      if (!svg) return;

      this.setState({
        wait: true,
      });

      let timeout: number;

      const listener = () => {
        // This is for cosmetic purposes, I'm allowed :)
        if (svg && svg.classList) {
          svg.classList.remove(styles.indeterminateProgressCircle);
          svg.classList.add(styles.progressCircle);
        }

        this.setState({
          wait: false,
        });

        svg.removeEventListener("animationiteration", listener);
        window.clearTimeout(timeout);
      };

      svg.addEventListener("animationiteration", listener);
      timeout = window.setTimeout(() => {
        listener();
      }, 2000); // Just in case the event never comes
    }
  }

  public render() {
    const { value, ...props } = this.props;
    const { wait } = this.state;

    const indeterminate = value === "indeterminate" || wait;

    return (
      <NumberEasing
        value={
          indeterminate
            ? value !== "indeterminate" && value < INDETERMINATE_SIZE * 2
              ? Math.floor(value / 2)
              : INDETERMINATE_SIZE
            : (value as number)
        }
        duration={800}
        renderAnyway
      >
        {av => <ProgressCircle value={indeterminate ? "indeterminate" : av} svgRef={this.svg} {...props} />}
      </NumberEasing>
    );
  }
};
