import * as React from "react";
import Checkbox from "components/Form/Checkbox";
import styles from "./styles.scss";

interface Props {
  items: string[];
  value: string[];
  onChange: (param0: string[]) => void;
  label?: string;
}

export default function SelectAllBar({ items, value, onChange, label }: Props) {
  const allSelected = items.length === value.length;

  return (
    <div className={styles.selectAllBar}>
      <Checkbox checked={allSelected} onCheck={c => onChange(c ? items : [])}>
        {label || "Select all"}
      </Checkbox>
    </div>
  );
}
