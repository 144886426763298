import React, { MouseEventHandler, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/Text";
import Icon from "components/Icon";
import Button from "components/Button";
import xIcon from "assets/times.svg";
import { colors } from "styles/variables";
import Clickable from "components/Clickable";

import styles from "./styles.scss";

export interface Action {
  label: string;
  onAction: () => unknown;
  testId: string;
}

export type SaveState = "saving" | "saved" | "error" | "networkError" | "validationError";

export interface Props {
  children: string;
  actions: Action[];
  primaryAction: Action;
  onSave: MouseEventHandler;
  state: SaveState | null;
  stateLabel: (state: SaveState) => string;
  errorLabel: React.ReactNode;
  testId: string;
  showErrorIcon?: boolean;
  onDiscard?: () => void;
  disabled?: boolean;
}

export function SaveButton({
  actions,
  primaryAction,
  children,
  onSave,
  state,
  stateLabel,
  errorLabel,
  testId,
  showErrorIcon,
  onDiscard,
  disabled,
}: Props) {
  const [online, setOnline] = useState(false);

  const [runningAction, setRunningAction] = useState<null | Action>(null);

  const callAction = (action: Action) => async () => {
    setRunningAction(action);
    await action.onAction();
    setRunningAction(null);
  };

  useEffect(() => {
    if (state === "networkError" || !navigator.onLine) {
      const on = () => setOnline(true);
      const off = () => setOnline(false);

      window.addEventListener("online", on);
      window.addEventListener("offline", off);

      return () => {
        window.removeEventListener("online", on);
        window.removeEventListener("offline", off);
      };
    }
  }, [state]);

  const { t } = useTranslation();

  return (
    <div className={styles.container} data-state={state}>
      {state && (
        <div className={styles.overlay} data-test={`${testId}.overlay`}>
          {state === "error" || state === "validationError" ? (
            <div className={styles.errorMessage} data-test={`${testId}.otherError`}>
              {onDiscard && (
                <Clickable onClick={onDiscard} className={styles.discardIcon}>
                  <Icon src={xIcon} fill={colors.white} size={14} />
                </Clickable>
              )}
              {showErrorIcon && <Icon src={xIcon} fill={colors.pink1} size={80} />}
              {typeof errorLabel === "string" ? (
                <Text color="white" font="lato" size={16} top="m" style={{ textAlign: "center" }}>
                  {errorLabel}
                </Text>
              ) : (
                errorLabel
              )}
            </div>
          ) : state === "networkError" ? (
            <div className={styles.networkError} data-test={`${testId}.networkError`}>
              <svg
                viewBox="0 0 640 512"
                width="80"
                height="80"
                fill={online ? colors.teal1 : colors.white}
                className={online ? styles.onlineIcon : styles.offlineIcon}
              >
                <path d="M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98z"></path>
                <path d="M523 268.59c -115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z"></path>
                <path d="M320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64z"></path>
              </svg>
              {online ? (
                <>
                  <Text color="white" font="wes" bold size={18} bottom="xs">
                    {t("components.saveButton.online.youAreBack")}
                  </Text>
                  <Text color="white" font="lato">
                    {t("components.saveButton.online.clickTryAgain")}
                  </Text>
                </>
              ) : (
                <>
                  <Text color="white" font="wes" size={18} bottom="xs">
                    {t("components.saveButton.offline.seemOffline")}
                  </Text>
                  <Text color="white" font="lato">
                    {t("components.saveButton.offline.checkAndTryAgain")}
                  </Text>
                </>
              )}
            </div>
          ) : (
            <div className={styles.actions}>
              {actions.map(action => (
                <Button
                  key={action.label}
                  kind="translucent"
                  onClick={callAction(action)}
                  className={styles.action}
                  testId={action.testId}
                  disabled={state === "saving" || !!runningAction || disabled}
                >
                  {action.label}
                </Button>
              ))}

              <Button
                key={primaryAction.label}
                kind="primaryGradient"
                onClick={callAction(primaryAction)}
                className={styles.action}
                testId={primaryAction.testId}
                disabled={state === "saving" || !!runningAction || disabled}
              >
                {primaryAction.label}
              </Button>
            </div>
          )}

          {state === "networkError" || state === "error" || state === "validationError" ? (
            <Button
              kind={"primaryGradient"}
              className={styles.saveButton}
              onClick={onSave}
              disabled={!!runningAction || disabled}
              testId={`${testId}.tryAgain`}
            >
              {t("components.saveButton.tryAgain")}
            </Button>
          ) : (
            <Text font="wes" color="white" size={24} className={styles.status}>
              {stateLabel(state)}
            </Text>
          )}
        </div>
      )}

      <Button
        type={"submit"}
        kind={"primaryGradient"}
        className={styles.saveButton}
        onClick={onSave}
        testId={testId}
        disabled={state === "saving" || !!runningAction || disabled}
      >
        {children}
      </Button>
    </div>
  );
}
