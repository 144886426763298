import React, { useMemo } from "react";

import { Identifier, UUIDListLiteral, ListLiteral } from "utilities/knueppel";
import RoundedPill from "components/RoundedPill";
import { ConnectionConfig, SrcConnection } from "utilities/connections";
import { useConnectionItems } from "hooks/useConnectionItems";
import { SearchableItem } from "components/Searchable";

import { FilterDefinition } from "../types";
import { ListFilterProps } from "./ListFilter";

export interface IdsFilterState {
  ids: string[];
}
export interface IdsFilterOptions {
  labelKey?: string;
  fieldName: string;
  fieldKind?: "value" | "array" | "function";
  listLiteralType?: "UUIDListLiteral" | "ListLiteral";
}
export function makeIdsFilterState<
  Node extends SearchableItem,
  CC extends ConnectionConfig<Node, any> = ConnectionConfig<Node, any>
>({
  fieldName,
  connection,
  renderName,
  fragment,
  fieldKind = "value",
  listLiteralType,
}: Pick<SrcConnection<CC>, "connection"> &
  IdsFilterOptions &
  Pick<ListFilterProps<Node, CC>, "renderName" | "fragment">): Omit<FilterDefinition<IdsFilterState>, "component"> {
  return {
    initialState: () => ({
      ids: [],
    }),
    validate: state => state.ids.length > 0,
    toAST: state => {
      const ids: UUIDListLiteral | ListLiteral = {
        type: listLiteralType || "UUIDListLiteral",
        values: state.ids,
      };
      const fieldId: Identifier = {
        type: "Identifier",
        name: fieldName,
      };
      switch (fieldKind) {
        case "function":
          return {
            ...fieldId,
            args: [ids],
          };
        case "array":
          return {
            type: "BinaryExpression",
            left: fieldId,
            operator: "&&",
            right: ids,
          };
        default:
          return {
            type: "BinaryExpression",
            left: fieldId,
            operator: "=",
            right: {
              type: "AnyExpression",
              values: ids,
            },
          };
      }
    },
    stateComponent: ({ state, setState }) => {
      const fragments = useMemo(() => [fragment], [fragment]);
      const [items] = useConnectionItems<Node, CC>(connection, fragments, state.ids);
      return (
        <>
          {state.ids.map(id => (
            <RoundedPill key={id} onRemove={() => setState({ ...state, ids: state.ids.filter(i => i !== id) })}>
              {items[id] ? renderName(items[id]) : "..."}
            </RoundedPill>
          ))}
        </>
      );
    },
  };
}
