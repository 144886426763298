import { useEffect, useReducer, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { FragmentDefinitionNode, DocumentNode } from "graphql";
import { ConnectionConfig, PEnv } from "utilities/connections";
import { idIn } from "utilities/knueppel";
import { buildQuery } from "houbolt";
import { merge, omit } from "lodash";

export interface ConnectionItems<T> {
  [nodeId: string]: T;
}

export function useConnectionItems<
  Node extends { id: string },
  CC extends ConnectionConfig<Node> = ConnectionConfig<Node>
>(
  connection: CC,
  fragments: DocumentNode[],
  ids: string[],
  extraVars?: any,
): [ConnectionItems<Node>, () => Promise<void>] {
  const client = useApolloClient();

  const [nodesUpdateId, refreshNodes] = useReducer(x => x + 1, 0);

  const items = useMemo(() => {
    if (ids.length === 0 || fragments.length === 0) return {};

    // Iterates over the selected ids and finds the correspondent nodes.
    // If it fails, it adds the id (and fragment) to an array so that they can be fetched (see useEffect below).

    const selectedNodes: ConnectionItems<Node> = {};

    const typeName = (fragments[0].definitions[0] as FragmentDefinitionNode).typeCondition.name.value;

    for (const id of ids) {
      for (const fragment of fragments) {
        const handleNode = (fnode: Node | null) => {
          if (fnode) {
            selectedNodes[id] = merge(selectedNodes[id], fnode);
          }
        };

        try {
          const fnode = client.readFragment({
            id: `${typeName}_${id}`,
            fragment,
          });

          handleNode(fnode);
        } catch (e) {
          handleNode(null);
        }
      }
    }

    return selectedNodes;
  }, [ids, nodesUpdateId, fragments]);

  const fetch = async () => {
    // Queries all missing nodes
    if (ids.length && fragments.length) {
      const query = buildQuery<PEnv, any>({ ...connection, fragments });
      await client.query({
        query,
        fetchPolicy: "network-only",
        variables: {
          first: ids.length,
          filters: idIn(ids),
          skipPageInfo: true,
          ...omit(extraVars, ["first", "filters", "skipPageInfo"]),
        },
      });
      refreshNodes();
    }
  };

  useEffect(() => {
    fetch();
  }, [ids ? ids.toString() : "", fragments]);

  return [items, fetch];
}
