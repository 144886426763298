import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import DocumentsTable, { DocumentsQuery } from "./Table";
import documentsQuery from "./documents.gql";

export default compose(
  graphql<{ organizationId: string }, DocumentsQuery, { orgId: string }, any>(documentsQuery, {
    options: props => ({
      variables: {
        orgId: props.organizationId,
      },
    }),
  }),
)(DocumentsTable);
