import * as React from "react";

import Table from "components/Table";
import Row from "components/Table/Row";
import Cell from "components/Table/Cell";
import Text from "components/LegacyText";
import { ProgramRole } from "modules/Dashboard/interfaces/ProgramRole";

import styles from "./styles.scss";

import ProgramInfo from "../ProgramInfo";

const COLUMNS = [
  { label: "Actitivity", size: 100 },
  { label: "Responsibilities" },
  { label: "Profile" },
  { label: "Dress Code" },
];

export default function Activities() {
  return (
    <React.Fragment>
      <ProgramInfo.Consumer>
        {({ roles }) => (
          <Table columns={COLUMNS} selectable={false} rowHeight={"large"} visibleColumns={1}>
            {roles.map((role: ProgramRole) => (
              <Row key={role.roleId} id={role.roleId} label={role.title}>
                <Cell size={100}>
                  <Text.P2 className={styles.roleTitle}>{role.title}</Text.P2>
                </Cell>
                <Cell>
                  <Text.CellValue>{role.duties}</Text.CellValue>
                </Cell>
                <Cell>
                  <Text.CellValue>{role.profile}</Text.CellValue>
                </Cell>
                <Cell>
                  <Text.CellValue>
                    {role.uniform ? (
                      <React.Fragment>
                        {role.uniform.label && (
                          <u>
                            {role.uniform.label}
                            {role.uniform.description && ":"}
                          </u>
                        )}{" "}
                        {role.uniform.description}
                      </React.Fragment>
                    ) : null}
                  </Text.CellValue>
                </Cell>
              </Row>
            ))}
          </Table>
        )}
      </ProgramInfo.Consumer>

      <Text.P4 kind={"secondary"} className={styles.note}>
        Note: Information on this page was generated at the time of the original program request
      </Text.P4>
    </React.Fragment>
  );
}
