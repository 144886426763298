import React from "react";
import moment from "moment-timezone";

import icon from "assets/megaphone.svg";

import { ICell } from "modules/Connection/types";
import Money from "components/Money";
import { Mobile, DesktopAndTablet } from "components/Layout/Responsive";
import { earthRadiusMi, getDistance } from "utilities/getPosition";
import { getGigDuration } from "utilities/time";
import { isVirtualLocation } from "utilities/location";

import styles from "./styles.scss";

import fragment from "./tracking.gql";

import TrackingItem from "./TrackingItem";
import TotalDurationItem from "./TotalDurationItem";

const MISSING_LABEL = "Missing";

interface CheckInOutStatus {
  time: Date;
  longitude: number;
  latitude: number;
}

export interface TrackingGig {
  startTime: Date;
  endTime: Date;
  timezone: string;
  totalExpenses: number;
  checkinStatus: CheckInOutStatus | null;
  checkoutStatus: CheckInOutStatus | null;
  location: {
    externalId?: string;
    longitude: number;
    latitude: number;
  };
}

const round = (n: number) => Math.round(n * 100) / 100;

export const TrackingExpandedCell: ICell<TrackingGig> = {
  component: props => {
    if (!props.data || !props.data.timezone || !props.data.location) {
      return null;
    }

    const {
      timezone,
      checkinStatus,
      checkoutStatus,
      totalExpenses,
      location,
      startTime: gStartTime,
      endTime: gEndTime,
    } = props.data;

    const startTime = moment.tz(gStartTime, timezone);
    const endTime = moment.tz(gEndTime, timezone);
    const checkinTime = checkinStatus && checkinStatus.time && moment.tz(checkinStatus.time, timezone);
    const checkoutTime = checkoutStatus && checkoutStatus.time && moment.tz(checkoutStatus.time, timezone);
    const checkinDistance =
      location.latitude &&
      checkinStatus &&
      checkinStatus.latitude &&
      getDistance(
        location.latitude,
        location.longitude,
        checkinStatus.latitude,
        checkinStatus.longitude,
        earthRadiusMi,
      );
    const checkoutDistance =
      location.latitude &&
      checkoutStatus &&
      checkoutStatus.latitude &&
      getDistance(
        location.latitude,
        location.longitude,
        checkoutStatus.latitude,
        checkoutStatus.longitude,
        earthRadiusMi,
      );
    const scheduledDuration = endTime && getGigDuration({ startTime, endTime });
    const duration = checkoutTime && checkinTime && checkoutTime.diff(checkinTime, "hours", true);

    return (
      <div className={styles.trackingBanner}>
        <div className={styles.trackingItems}>
          <TrackingItem
            title="Check in"
            primaryInvalid={!startTime || !checkinTime || checkinTime.diff(startTime, "minutes", true) > 15}
            invariant={startTime?.isValid() ? `Start: ${startTime.format("h:mma")}` : undefined}
            secondaryValue={
              isVirtualLocation(location)
                ? undefined
                : checkinDistance
                ? `${checkinDistance.toFixed(2)} mi away`
                : MISSING_LABEL
            }
            link={checkinStatus && `https://maps.google.com?q=${checkinStatus.latitude},${checkinStatus.longitude}`}
            secondaryInvalid={!checkinDistance || checkinDistance > 1}
          >
            {(checkinTime && checkinTime.format("h:mma")) || MISSING_LABEL}
          </TrackingItem>
          <TrackingItem
            title="Check out"
            primaryInvalid={!endTime || !checkoutTime || checkoutTime.diff(endTime, "minutes", true) > 15}
            invariant={endTime?.isValid() ? `End: ${endTime.format("h:mma")}` : undefined}
            secondaryValue={
              isVirtualLocation(location)
                ? undefined
                : checkoutDistance
                ? `${checkoutDistance.toFixed(2)} mi away`
                : MISSING_LABEL
            }
            link={checkoutStatus && `https://maps.google.com?q=${checkoutStatus.latitude},${checkoutStatus.longitude}`}
            secondaryInvalid={!checkoutDistance || checkoutDistance > 1}
          >
            {(checkoutTime && checkoutTime.format("h:mma")) || MISSING_LABEL}
          </TrackingItem>
          <DesktopAndTablet>
            <TrackingItem
              title="Total duration"
              primaryInvalid={!duration || Math.abs(duration - scheduledDuration) > 0.5}
              invariant={`Scheduled: ${(scheduledDuration && `${round(scheduledDuration)} hrs`) || MISSING_LABEL}`}
            >
              {`${(duration && `${round(duration)} hrs`) || MISSING_LABEL}`}
            </TrackingItem>
          </DesktopAndTablet>
          <TrackingItem title="Expense request">
            <Money value={totalExpenses || 0} />
          </TrackingItem>
        </div>
        <Mobile>
          <TotalDurationItem
            invariant={`Schedule: ${(scheduledDuration && `${round(scheduledDuration)} hrs`) || MISSING_LABEL}`}
            primaryInvalid={!duration || Math.abs(duration - scheduledDuration) > 0.5}
            duration={`${(duration && `${round(duration)} hrs`) || MISSING_LABEL}`}
          />
        </Mobile>
      </div>
    );
  },
  icon,
  fragment,
};
