import React, { useState } from "react";
import cx from "classnames";
import { useBlock } from "modules/Connection/Scope";
import styles from "./styles.scss";
import { IBlock, ICell } from "modules/Connection/types";
import { Block } from "modules/Connection/Block";
import Clickable from "components/Clickable";
import Text from "components/Text";
import { colors } from "styles/variables";
import Icon from "components/Icon";
import chevronIcon from "assets/chevron.svg";

interface Props<T> extends IBlock<T> {
  id: string;
  em?: boolean;
  header?: boolean;
  className?: string;
  formClassName?: string;
  expand?: {
    label: string;
    cell: ICell<any>;
  };
  forceScroll?: boolean;
  /** A function that returns one or many classnames based on the result for the Cell fragment.
   * Node type is automatically inferred from the cell.
   * */
  getClassName?: (node: T) => string;
  isEditable?: (node: T) => boolean;
}

/**
 * Renders a block in the details modal.
 */
export function DetailsBlock<C extends ICell<any>, T extends C extends ICell<infer IT> ? IT : never>({
  id,
  em,
  header,
  className,
  formClassName,
  cell,
  expand,
  form,
  getClassName,
  isEditable,
  forceScroll,
}: Props<T>) {
  const { nodes: [node] = [null] } = useBlock<T>(id, { cell, form });

  const editable = typeof isEditable === "function" ? isEditable : !node?.finalVerification?.isVerified;

  const [expanded, setExpanded] = useState(false);
  const expandedId = id + "Expanded";
  useBlock(expandedId, expand && expanded ? { cell: expand.cell } : undefined);

  return (
    <>
      <div className={cx(styles.detailsBlock, header && styles.detailsHeader)}>
        <Block<T>
          id={id}
          em={em}
          cell={cell}
          form={form}
          node={node}
          className={cx(expanded && styles.expanded, className, getClassName && node && getClassName(node))}
          formClassName={formClassName}
          mode={"detailed"}
          isEditable={editable}
          forceScroll={forceScroll}
        >
          {expand && (
            <Clickable
              className={styles.expander}
              onClick={() => setExpanded(expanded => !expanded)}
              testId={expand.label}
            >
              <Text font="wes" bold color={"blue2"} right={"xs"} top={"xxs"} noSelect underline="hover">
                {expand.label}
              </Text>
              <Icon src={chevronIcon} size={12} fill={colors.blue2} rotate={expanded ? 180 : 0} />
            </Clickable>
          )}
        </Block>

        {expanded && expand && (
          <Block<T>
            id={id}
            cell={expand.cell}
            node={node}
            mode={"detailed"}
            className={styles.expanded}
            collapse={() => setExpanded(false)}
            isEditable={editable}
            forceScroll={forceScroll}
          />
        )}
      </div>
    </>
  );
}
