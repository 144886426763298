import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import productInfo from "./productInfo.gql";
import styles from "./styles.scss";
import cx from "classnames";
import RoundedPill from "components/RoundedPill";
import Icon from "components/Icon";
import chevronIcon from "assets/chevron.svg";
import Text from "components/Text";
import { colors } from "styles/variables";
import { ProductInfoQuery, ProductInfoQueryVariables } from "gql-gen";
import linkIcon from "assets/link.svg";
import chevronsIcon from "assets/chevrons-right-regular.svg";
import Money from "components/Money";
import { TabletAndMobile } from "components/Layout/Responsive";
import ImageGallery from "components/ImageGallery";
import Thumbnail from "components/Thumbnail";
import Clickable from "components/Clickable";
import searchIcon from "assets/search-plus-solid.svg";
import imagesIcon from "assets/images-solid.svg";

interface Props {
  productId: string;
}

export default function ProductInfoSection({ productId }: Props) {
  const { data, loading } = useQuery<ProductInfoQuery, ProductInfoQueryVariables>(productInfo, {
    variables: { id: productId },
  });
  const show =
    !loading &&
    (data?.product?.msrp ||
      data?.product?.description ||
      data?.product?.documents?.length ||
      data?.product?.profilePictures?.length);

  const [open, setOpen] = useState(false);
  if (!show || !data) {
    return null;
  }
  return (
    <TabletAndMobile>
      {tm => (
        <div className={cx(styles.container, open ? styles.open : styles.closed)}>
          <RoundedPill
            className={cx(styles.detailsPill, !tm ? styles.desktopPill : "")}
            onClick={() => setOpen(o => !o)}
            childrenClassName={styles.detailsPillChildren}
          >
            <Text color="blue1" font="wes" size={14} inline weight={900} letterSpacing={0.5} lineHeight={28}>
              Reference Details
            </Text>
            <Icon src={chevronIcon} size={14} fill={colors.blue1} rotate={open ? 180 : 0} />
          </RoundedPill>
          {open ? <Sections productInfo={data.product} /> : null}
        </div>
      )}
    </TabletAndMobile>
  );
}

function SectionLabel({ children }: { children: React.ReactNode }) {
  return (
    <Text className={styles.sectionLabel} font="lato" size={14} inline>
      {children}
    </Text>
  );
}

function SectionText({ children }: { children: React.ReactNode }) {
  return (
    <Text font="lato" className={styles.textBody} size={14} weight={400} lineHeight={19} letterSpacing={0.3}>
      {children}
    </Text>
  );
}

interface Asset {
  id: string;
  name: string;
  url: string;
}

function Assets({ assets }: { assets: Array<Asset | null> }) {
  if (assets.length === 0) {
    return null;
  }
  return (
    <div>
      <SectionLabel>Select assets below to preview:</SectionLabel>
      <ul className={styles.assets}>
        {assets.map(asset =>
          asset ? (
            <li key={asset.id}>
              <a href={asset.url} target="_blank" className={styles.asset}>
                <Icon src={linkIcon} size={16} fill={colors.blue1} />
                <Text color="blue1" font="lato" size={14} inline letterSpacing={0.3} weight={700}>
                  {asset.name}
                </Text>
                <Icon src={chevronsIcon} size={16} fill={colors.blue1} />
              </a>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
}

function Description({ description }: { description: string | null | undefined }) {
  if (!description) {
    return null;
  }
  return (
    <div>
      <SectionLabel>Description:</SectionLabel>
      <SectionText>{description}</SectionText>
    </div>
  );
}

function Msrp({ msrp, name }: { msrp: number | null | undefined; name: string }) {
  if (!msrp) {
    return null;
  }
  return (
    <div>
      <SectionLabel>{name} MSRP:</SectionLabel>
      <SectionText>
        <Money value={msrp} />
      </SectionText>
    </div>
  );
}

function Pictures({ pics }: { pics?: Asset[] }) {
  if (!pics || !pics.length) {
    return null;
  }
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TabletAndMobile>
      {tm => (
        <div className={cx(tm ? styles.images : styles.imagesDesktop)}>
          <Clickable onClick={() => setIsOpen(true)} className={styles.thumb}>
            {pics.length > 1 ? (
              <>
                <span className={styles.searchIconWrapper}>
                  <Icon src={searchIcon} size={20} fill="white" />
                </span>
                <span className={styles.imagesIconWrapper}>
                  <Icon src={imagesIcon} size={50} fill="white" />
                  <span
                    className={cx(
                      styles.picsCount,
                      pics.length < 10 ? styles.picsCountSingle : styles.picsCountMultiple,
                    )}
                  >
                    {pics.length}
                  </span>
                </span>
              </>
            ) : null}
            <Thumbnail image={pics[0].url} size={150} />
          </Clickable>
          <ImageGallery
            isOpen={isOpen}
            images={pics.map(p => ({ src: p.url, caption: "" }))}
            currentImage={currentImage}
            onImageChange={setCurrentImage}
            onClose={() => setIsOpen(false)}
          />
        </div>
      )}
    </TabletAndMobile>
  );
}

function Sections({ productInfo }: { productInfo: ProductInfoQuery["product"] }) {
  if (!productInfo) {
    return null;
  }
  return (
    <TabletAndMobile>
      {tm => (
        <div className={cx(styles.sections, !tm ? styles.desktopSections : "")}>
          <Pictures pics={productInfo.profilePictures as any} />
          <Assets assets={productInfo.documents!} />
          <Description description={productInfo.description} />
          <Msrp msrp={productInfo.msrp} name={productInfo.name} />
        </div>
      )}
    </TabletAndMobile>
  );
}
