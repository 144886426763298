import { TFunction } from "i18next";
import { bin } from "utilities/knueppel";
import { ownersConnection, ownerNameRenderer, ownerFilters } from "../Fields/Owner/Picker";
import savedLocationsFragment from "../Filters/Location/SavedLocation/savedLocations.gql";
import ownerFragment from "../Fields/Owner/Picker/owner.gql";
import roleFragment from "../Fields/Role/RolePicker/role.gql";
import { programConnection } from "../Fields/Program/ProgramPicker/ProgramPicker";
import { locationsConnection } from "../Filters/Location/SavedLocation";
import productsFragment from "../Fields/Product/ProductPicker/products.gql";
import programFragment from "../Fields/Program/ProgramPicker/program.gql";
import { roleConnection } from "../Fields/Role/RolePicker";
import { dependency } from "modules/Uploader/references";
import { partnersConnection } from "modules/Dashboard/Organization/LegacySettings/PartnersPicker";
import partnerFragment from "modules/Dashboard/Organization/LegacySettings/PartnersPicker/partners.gql";
import { connection, ConnectionConfig } from "utilities/connections";
import { GigOwnerOptionsFragment } from "gql-gen";
import talentFragment from "./talent.gql";
import { productsConnection } from "../Fields/Product/ProductPicker/ProductPicker";
import { createObjectConfig } from "modules/Uploader";

export const talentConnection: ConnectionConfig<GigOwnerOptionsFragment> = connection({
  name: "GigsImporterTalent",
  entry: { name: "userConnectionsByOrganization" },
  variables: { search: "String" },
});

function adminFieldsConfig(t: TFunction) {
  return createObjectConfig({
    copies: {
      label: t("gigs.upload.copies"),
      type: "number",
      min: 1,
      max: 20,
      defaultSources: [{ type: "value", join: "+", val: 1 }],
    },

    program: {
      label: t("gigs.upload.program"),
      type: "ref",
      connection: programConnection,
      variables: {
        filters: bin("active", "=", true),
      },
      fragment: programFragment,
      renderName: p => p.name,
      required: true,
    },

    role: {
      label: t("gigs.upload.role"),
      type: "ref",
      connection: roleConnection,
      variables: {
        programIds: dependency("program", { fallback: [] }),
        filters: bin("active", "=", true),
      },
      fragment: roleFragment,
      renderName: r => r.title,
      required: true,
    },

    partnerOrganization: {
      label: t("gigs.upload.partnerOrganization"),
      type: "ref",
      connection: partnersConnection,
      variables: {},
      fragment: partnerFragment,
      renderName: a => a.name,
    },

    talent: {
      label: t("gigs.upload.talent"),
      type: "ref",
      connection: talentConnection,
      variables: {
        filters: {
          type: "BinaryExpression",
          left: {
            type: "Identifier",
            name: "state",
          },
          operator: "=",
          right: {
            type: "Literal",
            value: "active",
          },
        },
      },
      fragment: talentFragment,
      renderName: a => (a ? a.firstName + " " + a.lastName : "..."),
    },

    talentRate: {
      label: t("gigs.upload.talentRate"),
      type: "number",
    },

    products: {
      label: t("gigs.upload.products"),
      type: "ref",
      connection: productsConnection,
      variables: {
        programIds: dependency("program", { fallback: [] }),
        filters: bin("active", "=", true),
      },
      fragment: productsFragment,
      renderName: p => p.name,
      multiple: true,
    },

    date: {
      label: t("gigs.upload.date"),
      type: "date",
    },

    startTime: {
      label: t("gigs.upload.startTime"),
      type: "time",
    },

    endTime: {
      label: t("gigs.upload.endTime"),
      type: "time",
    },

    duration: {
      label: t("gigs.upload.duration"),
      type: "number",
    },

    windowStart: {
      label: t("gigs.upload.windowStart"),
      type: "date",
    },

    windowEnd: {
      label: t("gigs.upload.windowEnd"),
      type: "date",
    },

    location: {
      label: t("gigs.upload.location"),
      type: "location",
    },

    locationName: {
      label: t("gigs.upload.locationName"),
      type: "text",
    },

    savedLocation: {
      label: t("gigs.upload.savedLocation"),
      type: "ref",
      connection: locationsConnection,
      variables: {},
      fragment: savedLocationsFragment,
      renderName: p => p.name,
    },

    manager: {
      label: t("gigs.upload.owner"),
      type: "ref",
      connection: ownersConnection,
      variables: ownerFilters,
      fragment: ownerFragment,
      renderName: ownerNameRenderer,
    },

    title: {
      label: t("gigs.upload.title"),
      type: "text",
    },

    notes: {
      label: t("gigs.upload.notes"),
      type: "text",
    },

    agencyGuidance: {
      label: t("gigs.upload.agencyGuidance"),
      type: "text",
    },

    expenseNotes: {
      label: t("gigs.upload.expenseNotes"),
      type: "text",
    },
  });
}

function userFieldsConfig(t: TFunction, pId?: string) {
  return createObjectConfig({
    role: {
      label: t("gigs.upload.role"),
      sublabel: t("gigs.upload.required"),
      type: "ref",
      connection: roleConnection,
      variables: {
        programIds: dependency("program", { fallback: pId ? [pId] : [] }),
        filters: bin("active", "=", true),
      },
      fragment: roleFragment,
      renderName: r => r.title,
      required: true,
    },
    products: {
      label: t("gigs.upload.products"),
      sublabel: t("gigs.upload.optional"),
      type: "ref",
      connection: productsConnection,
      variables: {
        programIds: dependency("program", { fallback: pId ? [pId] : [] }),
        filters: bin("active", "=", true),
      },
      fragment: productsFragment,
      renderName: p => p.name,
      multiple: true,
    },
    location: {
      label: t("gigs.upload.location"),
      sublabel: t("gigs.upload.optional"),
      type: "location",
    },
    locationName: {
      label: t("gigs.upload.locationName"),
      sublabel: t("gigs.upload.required"),
      type: "text",
    },
    savedLocation: {
      label: t("gigs.upload.savedLocation"),
      sublabel: t("gigs.upload.required"),
      type: "ref",
      connection: locationsConnection,
      variables: {},
      fragment: savedLocationsFragment,
      renderName: p => p.name,
      required: true,
    },
    date: {
      label: t("gigs.upload.date"),
      sublabel: t("gigs.upload.optional"),
      type: "date",
    },

    startTime: {
      label: t("gigs.upload.startTime"),
      sublabel: t("gigs.upload.optional"),
      type: "time",
    },

    endTime: {
      label: t("gigs.upload.endTime"),
      sublabel: t("gigs.upload.optional"),
      type: "time",
    },
    partnerOrganization: {
      label: t("gigs.upload.partnerOrganization"),
      sublabel: t("gigs.upload.ifApplicable"),
      helpText: t("gigs.upload.partnerOrganizationHelp"),
      type: "ref",
      connection: partnersConnection,
      variables: {},
      fragment: partnerFragment,
      renderName: a => a.name,
    },

    morePeople: {
      type: "section",
      helpText: t("gigs.upload.morePeopleHelp"),
      open: false,
      label: t("gigs.upload.morePeople"),
      children: {
        talent: {
          label: t("gigs.upload.talent"),
          type: "ref",
          connection: talentConnection,
          variables: {
            filters: {
              type: "BinaryExpression",
              left: {
                type: "Identifier",
                name: "state",
              },
              operator: "=",
              right: {
                type: "Literal",
                value: "active",
              },
            },
          },
          fragment: talentFragment,
          renderName: a => (a ? a.firstName + " " + a.lastName : "..."),
        },
        talentRate: {
          label: t("gigs.upload.talentRate"),
          type: "money",
        },
        manager: {
          label: t("gigs.upload.owner"),
          type: "ref",
          connection: ownersConnection,
          variables: ownerFilters,
          fragment: ownerFragment,
          renderName: ownerNameRenderer,
        },
      },
    },

    moreTiming: {
      type: "section",
      helpText: t("gigs.upload.timingHelp"),
      open: false,
      label: t("gigs.upload.timing"),
      children: {
        windowStart: {
          label: t("gigs.upload.windowStart"),
          type: "date",
        },

        windowEnd: {
          label: t("gigs.upload.windowEnd"),
          type: "date",
        },
        duration: {
          label: t("gigs.upload.duration"),
          type: "number",
        },
      },
    },

    moreDetails: {
      type: "section",
      helpText: t("gigs.upload.moreDetailsHelp"),
      label: t("gigs.upload.moreDetails"),
      open: false,
      children: {
        title: {
          label: t("gigs.upload.title"),
          type: "text",
        },

        notes: {
          label: t("gigs.upload.notes"),
          sublabel: t("gigs.upload.visibleToUser"),
          type: "text",
        },

        agencyGuidance: {
          label: t("gigs.upload.agencyGuidance"),
          sublabel: t("gigs.upload.notVisibleToUser"),
          type: "text",
        },

        expenseNotes: {
          label: t("gigs.upload.expenseNotes"),
          type: "text",
        },
      },
    },
  });
}

export { adminFieldsConfig, userFieldsConfig };
