import _ from "lodash";
import * as React from "react";
import AutosizeInput from "react-input-autosize";
import arrowIcon from "assets/chevron.svg";
import cx from "classnames";
import Icon from "components/Icon";
import Pill from "components/Pill";
import Text from "components/LegacyText";
import Searchable, { Item, SearchableProps } from "../index";
import styles from "./styles.scss";

type ID = string;

interface Props extends Pick<SearchableProps, Exclude<keyof SearchableProps, "children">> {
  name: string;
  selectedItems?: ID[];
  onChange: (v: ID[]) => void;
  className?: string;
  testId?: string;
  allItems?: Item[];
}

export default class SearchableMultiSelect extends React.Component<Props> {
  private getSelectedItems() {
    return this.props.selectedItems || [];
  }

  private handleChange(items: ID[]) {
    this.props.onChange(items);
  }

  private handleAdd = (item: Item | null) => {
    if (item) {
      this.handleChange([...this.getSelectedItems(), item.id]);
    }
  };

  private handleRemove = (id: ID) => {
    this.handleChange(this.getSelectedItems().filter(i => !id || i !== id));
  };

  private handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Backspace" && e.target instanceof HTMLInputElement && e.target.value === "") {
      const selectedItems = this.getSelectedItems();

      if (selectedItems.length > 0) {
        this.handleRemove(selectedItems[selectedItems.length - 1]);
      }
    }
  };

  public render() {
    const { selectedItems, onChange, name, className, items, allItems, placeholder, ...props } = this.props;

    const selectedItemsP = this.getSelectedItems();

    const selectedItemViews = selectedItemsP.map(id => {
      const filterItems = allItems && allItems.length ? allItems : items;
      const name = _.get(filterItems && filterItems.find(item => item.id === id), "name", "");

      return (
        name && (
          <Pill key={id} onRemove={() => this.handleRemove(id)}>
            {name}
          </Pill>
        )
      );
    });

    const unselectedItems = (items || []).filter(i => !selectedItemsP.find(ii => i.id === ii));

    let emptyMessage = this.props.emptyMessage;

    if (!emptyMessage && selectedItemsP.length > 0) {
      emptyMessage = "No more items available";
    }

    return (
      <Searchable
        items={unselectedItems}
        clearSearchOnSelect={false}
        keepCurrentSearch={true}
        keepFocusOnSelect={true}
        placeholder={selectedItemsP.length > 0 ? "" : placeholder}
        onChange={this.handleAdd}
        emptyMessage={emptyMessage}
        optionsYOffset={1}
        className={className}
        {...props}
      >
        {({ inputProps, focusInput, isOpen, openOptions, closeOptions }) => {
          return (
            <div
              tabIndex={0}
              className={cx(styles.multiSelect, isOpen ? styles.openMultiSelect : "", className)}
              onFocus={openOptions}
              onBlur={closeOptions}
            >
              <span className={styles.arrow} onFocus={openOptions} onBlur={closeOptions}>
                <Icon size={14} rotate={isOpen ? 180 : 0} src={arrowIcon} />
              </span>

              <Text.Label2 className={styles.label}>{name}</Text.Label2>
              <div className={styles.items}>
                {selectedItemViews}
                <AutosizeInput
                  {...inputProps}
                  className={styles.input}
                  ref={(ref: any) => ref && (inputProps.ref as any)(ref.getInput())}
                  key={"input"}
                  onKeyDown={this.handleKeyDown}
                  onClick={e => {
                    e.stopPropagation();
                    focusInput();
                  }}
                  inputStyle={{ width: "100%" }}
                  placeholderIsMinWidth={false}
                />
              </div>
            </div>
          );
        }}
      </Searchable>
    );
  }
}
