import React, { useRef, useEffect } from "react";
import { ElmModule, UnknownElmPorts } from "elm";

interface Props<F extends {}, P extends UnknownElmPorts> {
  src: ElmModule<F, P>;
  flags?: F;
  ports?: (ports: P) => void;
}

export function Elm<F extends {}, P extends UnknownElmPorts>({ src, flags, ports }: Props<F, P>) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const app = src.init({ node: ref.current!, flags });

    if (app && ports) {
      ports(app.ports);
    }
  }, [src]);

  return (
    <div>
      <div ref={ref}></div>
    </div>
  );
}
