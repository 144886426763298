import React, { ReactNode } from "react";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import pointerIcon from "assets/mouse-pointer.svg";
import styles from "./styles.scss";

interface Props {
  onSelectStart: () => void;
  children: ReactNode;
}

export function RangeSelect({ children, onSelectStart }: Props) {
  return (
    <div className={styles.rangeSelect}>
      {children}
      <span className={styles.sep} />
      <Clickable testId={"importer.selectRange"} className={styles.selectBtn} onClick={onSelectStart}>
        <Icon src={pointerIcon} fill={"white"} size={16} />
      </Clickable>
    </div>
  );
}
