import * as React from "react";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { ProgramFormCtx } from "../Context";
import styles from "./styles.scss";
import LoadingContainer from "components/LoadingContainer";
import Text from "components/LegacyText";
import { AccessLevel, InvitedByFormFragment } from "gql-gen";
import Money from "components/Money";

export const ID = "invitedBy";

type ClientOrganizations = InvitedByFormFragment["clientOrganizations"];

interface ConfigurePartnersSectionProps {
  clientOrganizations: ClientOrganizations;
}

function TaskAccessLevel({ accessLevel }: { accessLevel: AccessLevel }) {
  switch (accessLevel) {
    case AccessLevel.Full:
      return (
        <>
          <Text.H2 className={styles.roleName}>Program Partner</Text.H2>&nbsp;
          <Text.H2 className={styles.description}>(You’ll see all tasks in this program)</Text.H2>
        </>
      );
    case AccessLevel.Limited:
      return (
        <>
          <Text.H2 className={styles.roleName}>Task Partner</Text.H2>&nbsp;
          <Text.H2 className={styles.description}>(You’ll see tasks assigned to you)</Text.H2>
        </>
      );
    case AccessLevel.None:
      return (
        <>
          <Text.H2 className={styles.roleName}>View-Only Partner</Text.H2>&nbsp;
          <Text.H2 className={styles.description}>(You'll see your previous tasks)</Text.H2>
        </>
      );
  }
}

function ClientList({ clientOrganizations }: ConfigurePartnersSectionProps) {
  return (
    <>
      {clientOrganizations.map(({ organization, roleRates, accessLevel }) => (
        <div>
          <Text.H1 className={styles.partnerName}>{organization.name}</Text.H1>
          <div style={{ marginTop: 15 }}>
            <Text.Label1>MY ROLE</Text.Label1>
            <div style={{ marginTop: 8 }}>
              <div style={{ display: "flex" }}>
                <TaskAccessLevel accessLevel={accessLevel} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <Text.Label1>DEFAULT RATES</Text.Label1>
            <div style={{ display: "flex", marginTop: 8 }}>
              {roleRates.map(({ role, rate }) => (
                <div style={{ display: "flex" }}>
                  <Text.H2>{role.title}:</Text.H2>&nbsp;
                  <Text.H2 className={styles.description}>
                    <Money value={rate || 0} />
                  </Text.H2>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

function InvitedBy() {
  const { values } = React.useContext(ProgramFormCtx);
  const clientOrganizations: ClientOrganizations | null | undefined = values.clientOrganizations;

  if (!clientOrganizations) {
    return <LoadingContainer message={"Loading"} />;
  }

  return (
    <>
      <AccordionPart>
        <AccordionPartHelper />
        <AccordionPartContent>
          <ClientList clientOrganizations={clientOrganizations} />
        </AccordionPartContent>
      </AccordionPart>
    </>
  );
}

export default InvitedBy;
