import _ from "lodash";
import { History } from "history";
import storage, { LocalStorage } from "utilities/localStorage";

export function isStandalone() {
  function _guard(it: Navigator): it is Navigator & { standalone: boolean } {
    return typeof (it as any).standalone === "boolean";
  }
  return _guard(window.navigator) && window.navigator.standalone;
}

interface RedirectPath {
  pathname?: string;
  hash?: string;
  search?: string;
}

const LocationStorage: LocalStorage<RedirectPath> = storage("standaloneLocation");

export function handleStandaloneLocation(history: History) {
  if (isStandalone()) {
    history.listen(location => {
      LocationStorage.update("location", _.pick(location, ["pathname", "search", "hash"]));
    });

    const sdLocation = LocationStorage.get("location");

    if (sdLocation && "pathname" in sdLocation && sdLocation.pathname) {
      history.replace({ ...sdLocation });
    }
  }
}
