import { flowRight as compose } from "lodash";
import * as React from "react";
import { FormikProps, withFormik, Field } from "formik";
import * as yup from "yup";
import Form from "components/Form";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { inputProps } from "utilities/formik";
import OptionsDropdown from "../../../../../../components/OptionsDropdown";
import SaveButton from "../SaveButton";
import { withProgramFormContext, validateAndNotify, optionSchema, ProgramFormContext } from "../Context";
import styles from "./styles.scss";
import { OptionField } from "modules/Dashboard/interfaces/Option";

import FilePicker from "components/Form/FilePicker";
import LegacyText from "components/LegacyText";
interface ProgramLogisticsValues {
  demoKit: {
    client: string;
    agency: string;
    shippingRequirements: OptionField;
  };

  setupReferenceImageIds: string[];
  expenses: string;
}

const Fields: ProgramLogisticsValues = {
  demoKit: {
    client: "",
    agency: "",
    shippingRequirements: { key: "", description: "" },
  },

  setupReferenceImageIds: [],
  expenses: "",
};

//TODO Create flatten Fields fn

const requiredFields = [
  "demoKit.client",
  "demoKit.agency",
  "demoKit.shippingRequirements.key",
  // 'demoKit.shippingRequirements.description',
  "setupReferenceImageIds",
  "expenses",
];

//const requiredFields = Object.keys(Fields);
interface Props extends FormikProps<ProgramLogisticsValues> {
  programForm: ProgramFormContext;
}

export const ID = "programLogistics";

class ProgramLogistics extends React.PureComponent<Props> {
  // TODO manage expense list (v2)
  // expenseList = () => {
  //   return (
  //     <div className={styles.expenseList}>
  //       {['travel', 'parking', 'meal', 'other'].map(expenseItem => {
  //         return (
  //           <div className={styles.capSection}>
  //             <Form.Checkbox
  //               checked={!!values.budgetCap}
  //               onCheck={isChecked => {
  //                 setFieldValue('budgetCap', isChecked ? { value: 0, capType: 'cost' } : null);
  //               }}
  //               labelClassName={styles.label}
  //             >
  //               {_.startCase(expenseItem)}
  //             </Form.Checkbox>
  //
  //             <Form.TextBox
  //               // {...input('providedItems')}
  //               type={'text'}
  //               className={styles.inputWrapper}
  //             />
  //             <Operand
  //               className={styles.capOperand}
  //               editable={true}
  //               value={this.state.capValue}
  //               onChange={({ target: { value } }) => {
  //                 this.setState({ capValue: value });
  //               }}
  //             />
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };
  public render() {
    const {
      programForm: { canEdit },
    } = this.props;
    const input = inputProps(this.props, requiredFields, !canEdit);
    const isSolo = this.props.programForm.executionType === "solo";

    return (
      <React.Fragment>
        <AccordionPart>
          <AccordionPartHelper></AccordionPartHelper>
          <AccordionPartContent>
            <Form.Section className={styles.demoKitSection}>
              <div className={styles.demoKit}>
                <span>Additional Materials (Optional): </span>
                <span>What else does the assigned user need to know?</span>
              </div>

              <Form.TextBox
                {...input("demoKit.client")}
                label={
                  <React.Fragment>
                    WHAT WILL <span className={styles.pinkLabel}>YOU</span> PROVIDE? (IF APPLICABLE)
                  </React.Fragment>
                }
                multiline
                rows={2}
                placeholder={"Please specify..."}
                type={"text"}
              />

              <OptionsDropdown
                group={"shippingRequirements"}
                ddProps={{
                  ...input("demoKit.shippingRequirements.key"),
                  label: "HOW WILL YOU SHIP THE ABOVE ITEMS?",
                }}
                descProps={{
                  ...input("demoKit.shippingRequirements.description"),
                  label: "SHIPPING DETAILS",
                }}
              />

              {isSolo ? null : (
                <Form.HorizontalGroup>
                  <Form.TextBox
                    {...input("demoKit.agency")}
                    label={
                      <React.Fragment>
                        WHAT IS YOUR <span className={styles.pinkLabel}>STAFFING PARTNER</span> EXPECTED TO PROVIDE? (IF
                        APPLICABLE)
                      </React.Fragment>
                    }
                    multiline
                    rows={2}
                    placeholder={"Please specify..."}
                    type={"text"}
                  />
                </Form.HorizontalGroup>
              )}
            </Form.Section>
          </AccordionPartContent>
        </AccordionPart>
        <AccordionPart>
          <AccordionPartHelper></AccordionPartHelper>
          <AccordionPartContent>
            <Form.Section className={styles.demoKitSection}>
              <div className={styles.demoKit}>
                <span>Expense Policy (Optional): </span>
                <span>What expenses will you reimburse?</span>
                <div className={styles.expenseLabel}>VISIBLE TO ASSIGNEE</div>
              </div>
              {/* {this.expenseList()} */}
              <Form.TextBox
                {...input("expenses")}
                type={"text"}
                multiline
                placeholder={"Travel, parking, product, materials, etc."}
              />
            </Form.Section>
          </AccordionPartContent>
        </AccordionPart>
        <AccordionPart>
          <AccordionPartHelper></AccordionPartHelper>
          <AccordionPartContent>
            <Form.Section className={styles.demoKitSection}>
              <div className={styles.demoKit}>
                <span>Program Files</span>
              </div>

              <div className={styles.filePicker}>
                <Field
                  name="internalFiles"
                  render={({ field, form }: any) => (
                    <FilePicker
                      accepts="*"
                      value={field.value || []}
                      onChange={value =>
                        form.setFieldValue(
                          field.name,
                          value.map(({ caption, url, name }) => ({ caption: caption ?? name, url })),
                        )
                      }
                      caption={true}
                      size={70}
                    />
                  )}
                />
              </div>
            </Form.Section>

            <SaveButton id={ID} />
          </AccordionPartContent>
        </AccordionPart>
      </React.Fragment>
    );
  }
}

const transactionalSchema = yup.object().shape({
  demoKit: yup
    .object()
    .shape({
      client: yup.string().required().nullable(),
      agency: yup.string().required().nullable(),
      shippingRequirements: optionSchema,
    })
    .nullable(),
  expenses: yup.string().required().nullable(),
});

const soloSchema = yup.object().shape({
  demoKit: yup
    .object()
    .shape({
      client: yup.string().required().nullable(),
      shippingRequirements: optionSchema,
    })
    .nullable(),
  expenses: yup.string().required().nullable(),
});

export default compose(
  withProgramFormContext,
  withFormik<Props, ProgramLogisticsValues>({
    mapPropsToValues: props => ({ ...Fields, ...props.programForm.values }),
    validate: validateAndNotify(transactionalSchema, soloSchema, soloSchema, ID),
    handleSubmit: () => {},
  }),
)(ProgramLogistics);
