import Overview from "./Overview";
import OverviewForm from "../../Form/Overview";
import Activities from "./Activities";
import ActivitiesForm from "../../Form/Activities";
import Products from "./Products";
import ProductsForm from "../../Form/Products";
import ProgramLogistics from "./ProgramLogistics";
import ProgramLogisticsForm from "../../Form/ProgramLogistics";
import Education from "./Education";
import EducationForm from "../../Form/Education";
import AdminInfo from "./AdminInfo";
import AdminInfoForm from "../../Form/AdminInfo";
import Team from "./Team";
import TeamForm from "../../Form/Team";
import { isUserAtLeast, User } from "interfaces/user";
import PartnershipForm from "../../Form/Partnership";
import Partnership from "./Partnership";
import TalentRequirements from "./TalentRequirements";
import TalentRequirementsForm from "../../Form/TalentRequirements";
import InvitedBy from "./InvitedBy";
import InvitedByForm from "../../Form/InvitedBy";
import { match } from "ts-pattern";

type Section = {
  id: string;
  title: string;
  component: React.ComponentType<any>;
  form: any;
  // Is this section editable by organizations that are not marked as owners of this program?
  nonOwnerEditable: boolean;
};

export const SECTIONS: Section[] = [
  {
    id: "overview",
    title: "Overview",
    component: Overview,
    form: OverviewForm,
    nonOwnerEditable: false,
  },

  {
    id: "activities",
    title: "Activities",
    component: Activities,
    form: ActivitiesForm,
    nonOwnerEditable: false,
  },

  {
    id: "talentRequirements",
    title: "Customize Activities",
    component: TalentRequirements,
    form: TalentRequirementsForm,
    nonOwnerEditable: false,
  },

  {
    id: "products",
    title: "Products",
    component: Products,
    form: ProductsForm,
    nonOwnerEditable: false,
  },

  {
    id: "invitedBy",
    title: "Invited By",
    component: InvitedBy,
    form: InvitedByForm,
    nonOwnerEditable: false,
  },

  {
    id: "partnership",
    title: "My Partners",
    component: Partnership,
    form: PartnershipForm,
    nonOwnerEditable: true,
  },

  {
    id: "training",
    title: "Education",
    component: Education,
    form: EducationForm,
    nonOwnerEditable: true,
  },

  {
    id: "team",
    title: "Team",
    component: Team,
    form: TeamForm,
    nonOwnerEditable: true,
  },

  {
    id: "billingInfo",
    title: "Billing Info",
    component: AdminInfo,
    form: AdminInfoForm,
    nonOwnerEditable: false,
  },

  {
    id: "programLogistics",
    title: "Additional Info",
    component: ProgramLogistics,
    form: ProgramLogisticsForm,
    nonOwnerEditable: true,
  },
];

export const NON_ADMIN_SECTIONS = SECTIONS.filter(s => s.id !== "billingInfo");

export function getSections(
  isOwnedByCurrentOrganization: boolean,
  user: User | null,
  isPartnershipVisible: boolean,
  isInvitedByVisible: boolean,
) {
  const filterSections = (sections: Section[]) =>
    sections.filter(s => {
      return match(s.id)
        .with("partnership", () => isPartnershipVisible)
        .with("invitedBy", () => isInvitedByVisible)
        .otherwise(() => true);
    });

  if (isOwnedByCurrentOrganization && isUserAtLeast(user, "manager")) {
    return filterSections(SECTIONS);
  }

  return filterSections(NON_ADMIN_SECTIONS);
}
