import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import Clickable, { ClickableProps } from "components/Clickable";
import Icon from "components/Icon";
import Portal from "components/Portal";
import Shortcuts, { shortcut } from "components/Shortcuts";
import closeIcon from "assets/cancel-nobg.svg";
import variables from "styles/variables";
import styles from "./styles.scss";

const MARGIN = 20;
const MARGIN_PX = `${MARGIN}px`;

type RelativePosition = "start" | "center" | "end";

/** Props that the Popup body component receives */
export interface PopupBodyProps {
  /** Closes the popup */
  close: () => void;
  forceScroll?: boolean;
}

export interface PopupProps {
  /**
   * The element that toggles the popup.
   * Unless `anchorElementId` is specified, children is also the element the popup is attached to.
   */
  children?: React.ReactNode;
  /** Props passed to the Clickable element around children. */
  childrenWrapperProps?: Omit<ClickableProps, "children">;
  /** Attach the popup to an element somewhere else in the DOM. */
  anchorElementId?: string;
  /** A component that renders the content of the popup. Only rendered when open. */
  body: React.ComponentType<PopupBodyProps>;
  /** When true, shows a green stripe on top of the body. */
  showHeader?: boolean;
  /** Whether the popup is open or not. Only applies to ControlledPopup. */
  open: boolean;
  /** Called when the popup is closed and children is clicked. */
  onOpen?: () => void;
  /** Called when the popup is open and children is clicked or the user clicked outside the popup. */
  onClose?: () => void;
  className?: string;
  forceScroll?: boolean;
}

/**
 * A stateless popup that shows some content (body) attached to an anchor element.
 *
 * The content can be any React component. See `body` prop.
 * The content is automatically positioned to the direction with the most room.
 * The content dimensions are observered and positions are recalculated when they change.
 *
 * In the cases where the popup should always be attached to the element that opened it and that element
 * is in the same React tree, you should wrap your anchor in this component, passing it as children.
 *
 * However, some times you can't pass the anchor as children, for example when you want to attach the popup to:
 *   1. something other than the button that opens it
 *   2. something different based on the situation
 *   3. an element that's rendered by something other than React
 *
 * In those cases, the `anchorElementId` prop should be used. It only requries that the element is somewhere
 * in the DOM but it doesn't have to be in the same React component or be rendered by React at all.
 * For example, it can be rendered by Elm.
 */
export function ControlledPopup({
  body,
  open,
  children,
  anchorElementId,
  showHeader,
  childrenWrapperProps: anchorProps,
  onClose,
  onOpen,
  className,
  forceScroll,
}: PopupProps) {
  const childrenRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const triangleRef = useRef<HTMLDivElement>(null);

  const calculatePosition = () => {
    const { current: content } = contentRef;
    const { current: triangle } = triangleRef;
    const anchor = anchorElementId ? document.getElementById(anchorElementId) : childrenRef.current;

    if (!content || !anchor || !triangle) return;

    content.style.top = "unset";
    content.style.bottom = "unset";
    content.style.left = "unset";
    content.style.right = "unset";

    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const { width: contentWidth, height: contentHeight } = content.getBoundingClientRect();
    const { width: triangleWidth, height: triangleHeight } = triangle.getBoundingClientRect();
    const { left, right, top, bottom, width, height } = anchor.getBoundingClientRect();

    // Positions relative to the opposite side of the screen
    const relativeRight = windowWidth - right;
    const relativeBottom = windowHeight - bottom;

    // 1. Find the direction with the most room
    const max = Math.max(left, relativeRight, top, relativeBottom);

    // 2. Assign triangle rotation and coordinates relative to the anchor
    //    in order to position the popup in the direction found in the previous step.
    //
    //      start  center  end
    //  start  +----------+
    //         |          |
    // center  |  ANCHOR  |  y axis
    //         |          |
    //    end  +----------+
    //           x axis

    let x: RelativePosition = "start";
    let y: RelativePosition = "center";

    // Triangle rotation
    let tr = 0;

    if (top === max) {
      x = "center";
      y = "start";
      tr = 0;
    } else if (relativeRight === max) {
      x = "end";
      y = "center";
      tr = 90;
    } else if (relativeBottom === max) {
      x = "center";
      y = "end";
      tr = 180;
    } else if (left === max) {
      x = "start";
      y = "center";
      tr = 270;
    }

    // 3. Set the triangle element rotation
    triangle.style.transform = `rotate(${tr}deg)`;

    // 4. Calculate and set absolute pixel coordinates from the relative ones assigned in step 2

    if (x === "start") {
      const inverseLeft = windowWidth - left;
      content.style.right = inverseLeft + triangleWidth + "px";
      triangle.style.right = inverseLeft + "px";
    }

    if (x === "center") {
      content.style.left = right - contentWidth / 2 - width / 2 + "px";
      triangle.style.left = right - width / 2 - triangleWidth / 2 + "px";
    }

    if (x === "end") {
      content.style.left = right + triangleWidth + "px";
      triangle.style.left = right + "px";
    }

    if (y === "start") {
      const inverseTop = windowHeight - top;
      content.style.bottom = inverseTop + triangleHeight + "px";
      triangle.style.bottom = inverseTop + "px";
    }

    if (y === "center") {
      content.style.top = bottom - contentHeight / 2 - height / 2 + "px";
      triangle.style.top = bottom - height / 2 - triangleHeight / 2 + "px";
    }

    if (y === "end") {
      content.style.top = bottom + triangleHeight + "px";
      triangle.style.top = bottom + "px";
    }

    // 4. Get the dimensions of the popup after positioning
    const newContentRect = content.getBoundingClientRect();

    // 5. Fix sides offscreen

    if (newContentRect.left < MARGIN) {
      content.style.left = MARGIN_PX;
      content.style.right = "unset";
    }

    if (newContentRect.top < MARGIN) {
      content.style.top = MARGIN_PX;
      content.style.bottom = "unset";
    }

    if (newContentRect.right > windowWidth) {
      content.style.left = "unset";
      content.style.right = MARGIN_PX;
    }

    if (newContentRect.bottom > windowHeight) {
      content.style.top = "unset";
      content.style.bottom = MARGIN_PX;
    }
  };

  useEffect(() => {
    if (open) {
      try {
        const observer = new window.ResizeObserver(() => {
          calculatePosition();
        });

        observer.observe(contentRef.current!);

        return () => {
          observer.disconnect();
        };
      } catch (err) {
        console.error(err);
        console.warn("Resize Observer is not available. Sizing won't be watched.");
      }
    }
  }, [open]);

  const togglePopup = () => {
    if (open) {
      if (onClose) onClose();
    } else {
      if (onOpen) onOpen();
    }
  };

  return (
    <Clickable
      {...anchorProps}
      className={cx(styles.button, anchorProps && anchorProps.className, className)}
      onClick={togglePopup}
      clickableRef={childrenRef}
    >
      {children}

      {open && (
        <Portal id="portal" onClick={togglePopup}>
          <Shortcuts shortcuts={[shortcut("esc", () => onClose && onClose())]}>
            <div className={styles.popup} ref={contentRef}>
              <div className={styles.body} onClick={e => e.stopPropagation()}>
                {showHeader && (
                  <div className={styles.header}>
                    <Clickable onClick={togglePopup}>
                      <Icon src={closeIcon} fill={variables.white} size={22} />
                    </Clickable>
                  </div>
                )}

                {React.createElement(body, { close: () => togglePopup(), forceScroll })}
              </div>
            </div>
            <div ref={triangleRef} className={styles.triangle} />
          </Shortcuts>
        </Portal>
      )}
    </Clickable>
  );
}

/** Stateful Popup that keeps track of whether it is open or not */
export default function Popup(props: Omit<PopupProps, "open">) {
  const [open, setOpen] = useState(false);
  return (
    <ControlledPopup
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (props.onOpen) props.onOpen();
      }}
      onClose={() => {
        setOpen(false);
        if (props.onClose) props.onClose();
      }}
    />
  );
}
