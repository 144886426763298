import React from "react";
import { useQuery } from "@apollo/client";
import { useConnectionConfig } from "hooks/useConnectionConfig";
import programDetailsFragment from "./programDetails.gql";
import { useTranslation, Trans } from "react-i18next";
import { useScopeContext } from "modules/Connection/Scope";
import Text from "components/Text";
import styles from "./styles.scss";

const programDetails = [programDetailsFragment];

function PopupContainer({ children }: { children: React.ReactNode }) {
  return <div className={styles.popupContainer}>{children}</div>;
}

export default function NoActions({ inPopup }: { inPopup: boolean }) {
  const { connection, variables } = useScopeContext();

  const query = useConnectionConfig(connection, programDetails);

  const { data } = useQuery(query, { variables: { ...variables, first: 1, skipPageInfo: true } });
  const isProgramArchived = data?.gigs?.edges[0]?.node?.program?.archived;
  const { t } = useTranslation();
  if (inPopup) {
    if (isProgramArchived) {
      return (
        <PopupContainer>
          <Text size={12} color="pink1">
            {t("components.noActions.archived")}
            <Text size={12} color="blue2" inline className={"intercom-selector"}>
              {t("components.noActions.needHelp")}
            </Text>
          </Text>
        </PopupContainer>
      );
    } else {
      return (
        <PopupContainer>
          <Text size={12} color="white">
            {t("components.noActions.noActionsAvailable")}
          </Text>
        </PopupContainer>
      );
    }
  } else {
    if (isProgramArchived) {
      return (
        <Text size={16} color="pink1" inline className={styles.container}>
          <Trans i18nKey="components.noActions.archivedProgramActionsError">
            This program has been archived & no actions can be made. If you need assistance with this task, please click
            <Text color={"blue2"} inline size={16} className={"intercom-selector"}>
              here
            </Text>
            to reach out to support.
          </Trans>
        </Text>
      );
    } else {
      return (
        <Text color={"white"} size={16} inline className={styles.container}>
          {t("components.noActions.noActionsAvailable")}
        </Text>
      );
    }
  }
}
