import * as React from "react";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  title: string;
  invariant?: string;
  primaryInvalid?: boolean;
  secondaryValue?: string;
  secondaryInvalid?: boolean;
  link?: string | null;
  children?: React.ReactNode;
}

export default function TrackingItem(props: Props) {
  return (
    <div className={styles.trackingItem}>
      <Text.H4 className={styles.title}>{props.title}</Text.H4>
      <Text.Display4 kind={props.primaryInvalid ? "highlight" : "green"} className={styles.primary}>
        {props.children}
      </Text.Display4>
      <Text.P5 kind="secondary" className={styles.invariant}>
        <em>{props.invariant || <React.Fragment>&nbsp;</React.Fragment>}</em>
      </Text.P5>
      {props.link ? (
        <Text.NewLink3 href={props.link} className={styles.link} target="_blank">
          {props.secondaryValue}
        </Text.NewLink3>
      ) : (
        <Text.P5 kind={props.secondaryInvalid ? "error" : "green"}>{props.secondaryValue}</Text.P5>
      )}
    </div>
  );
}
