import * as React from "react";
import { Formik, FormikHandlers, FormikProps } from "formik";
import FormProps from "components/Form";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { Helper1, Helper2 } from "../FormHelpers";
import SaveButton from "../SaveButton";
import { ProgramFormContext, ProgramFormCtx } from "../Context";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import LoadingContainer from "components/LoadingContainer";
import { useQuery } from "@apollo/client";
import partnerOrganizationsQuery from "./partnerOrganizations.gql";
import Text from "components/LegacyText";
import {
  PartnerOrganizationsQuery,
  PartnerOrganizationsQueryVariables,
  NumberAvailablePartnerOrganizationsQuery,
  NumberAvailablePartnerOrganizationsQueryVariables,
} from "gql-gen";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { User } from "interfaces/user";
import * as Activities from "../Activities";
import PartnersPicker from "modules/Dashboard/Organization/LegacySettings/PartnersPicker";
import { inputProps } from "utilities/formik";
import { bin } from "utilities/knueppel";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import Form from "components/Form";
import numberAvailablePartnerOrganizationsQuery from "./numberAvailablePartnerOrganizations.gql";

export const ID = "partnership";

type FormValues = Pick<NonNullable<PartnerOrganizationsQuery["programRequest"]>, "partnerOrganizations"> & {
  partnerOrganizationIds: string[];
};

type FormProps = FormikProps<FormValues>;

interface PartnerSelectionProps {
  form: FormProps;
  canEdit: Boolean;
  //Used to filter out the program owner from your partner list. Since second level invites can't have partners on the program this is sufficient
  //to prevent circular "dependencies" (A invites B invites A)
  programOwnerId: string;
  user: User;
}

function AddPartners({ form, canEdit, programOwnerId, user }: PartnerSelectionProps) {
  const input = inputProps(form, [], !canEdit);
  const partnerOrganizationIds = input("partnerOrganizationIds");

  return (
    <>
      <Text.H2 style={{ marginBottom: 15 }}>Step 1: Add partners</Text.H2>
      <FormProps.Section>
        <PartnersPicker
          form={form}
          field={{
            value: partnerOrganizationIds.value,
            name: partnerOrganizationIds.name || "partnerOrganizationIds",
            onChange: partnerOrganizationIds.onChange as FormikHandlers["handleChange"],
            onBlur: partnerOrganizationIds.onBlur as FormikHandlers["handleBlur"],
          }}
          multiple
          meta={{
            value: partnerOrganizationIds.value,
            touched: false,
            initialTouched: false,
          }}
          filters={bin("id", "!=", programOwnerId)}
          onlyVisible={!user.isAdmin}
        />
      </FormProps.Section>
    </>
  );
}

interface ConfigurePartnersProps {
  form: FormProps;
  programForm: ProgramFormContext;
  user: User;
  isLoading: Boolean;
}

function ConfigurePartners({ form, programForm, user, isLoading }: ConfigurePartnersProps) {
  if (isLoading) {
    return <LoadingContainer message={"Loading"} />;
  }

  const { executionType, sectionsBeingSaved } = programForm;
  const isEditingDisabled = sectionsBeingSaved.includes(Activities.ID);
  const partnerOrganizations = form.values.partnerOrganizations;

  type PartnerOrganization = typeof partnerOrganizations[number];
  type RoleRate = PartnerOrganization["roleRates"][number];

  const mapPartnerOrganization = (
    organizationId: string,
    updateFn: (partnerOrganization: PartnerOrganization) => PartnerOrganization,
  ) => (partnerOrganization: PartnerOrganization) => {
    if (partnerOrganization.organization.id === organizationId) {
      return updateFn(partnerOrganization);
    }
    return partnerOrganization;
  };

  const mapRoleRates = (organizationId: string, roleId: string, updateFn: (roleRate: RoleRate) => RoleRate) => {
    return mapPartnerOrganization(organizationId, partnerOrganization => {
      const roleRates = partnerOrganization.roleRates.map(roleRate => {
        if (roleRate.role.id === roleId) {
          return updateFn(roleRate);
        }

        return roleRate;
      });

      return {
        ...partnerOrganization,
        roleRates,
      };
    });
  };

  return (
    <div style={{ marginBottom: 15, marginTop: 15 }}>
      <Text.H2 style={{ marginBottom: 15 }}>Step 2: Configure Partner-specific options</Text.H2>
      <div className={styles.partnerList}>
        {form.values.partnerOrganizations.map(({ organization, roleRates, accessLevel }) => {
          return (
            <div key={organization.id}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text.H1 className={styles.partnerName}>{organization.name}</Text.H1>
                {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 10 }}>
                  <Text.H2 className={styles.activityTasksCount}>No tasks yet</Text.H2>
                  <button className={styles.removeFromProgramBtn}>Remove from Program</button>
                </div> */}
                {/* <div style={{ display: "flex" }}>
                  <Text.H2 className={styles.activityTasksCount}>
                    <span className={styles.countNumber}>0</span> Active tasks
                  </Text.H2>
                  &nbsp;<Text.H2>+</Text.H2>&nbsp;
                  <Text.H2 className={styles.activityTasksCount}>
                    <span className={styles.countNumber}>0</span> Finished
                  </Text.H2>
                </div> */}
              </div>
              <div style={{ marginTop: 20 }}>
                <Text.Label1>VISIBILITY & PERMISSIONS</Text.Label1>
                <RadioGroup
                  className={styles.visibilityPermissionsRadioGroup}
                  value={accessLevel}
                  onChange={value => {
                    form.setFieldValue(
                      "partnerOrganizations",
                      form.values.partnerOrganizations.map(
                        mapPartnerOrganization(organization.id, partnerOrganization => ({
                          ...partnerOrganization,
                          ...({ accessLevel: value } as Pick<PartnerOrganization, "accessLevel">),
                        })),
                      ),
                    );
                  }}
                >
                  <RadioButton value="full">
                    <span style={{ fontWeight: "bold" }}>Program Partner</span> (Sees all activity and Partners in this
                    Program)
                  </RadioButton>
                  <RadioButton value="limited">
                    <span style={{ fontWeight: "bold" }}>Task Partner</span> (Sees only those tasks where designated as
                    Task Manager. Cannot see other Task Partners.)
                  </RadioButton>
                  <RadioButton value="none">
                    <span style={{ fontWeight: "bold" }}>View-Only Partner</span> (Can only view their previous tasks.
                    Cannot create new tasks nor see other Task Partners.)
                  </RadioButton>
                </RadioGroup>
              </div>
              <div style={{ marginTop: 20 }}>
                <Text.Label1>DEFAULT RATES</Text.Label1>
                <p style={{ marginTop: 10 }}>
                  Your inputs below should reflect your standard rate with this partner in this Program. Your Partner
                  will be able to review rates on a per-gig basis and make modifications per task (e.g. to enter a
                  special holiday rate).
                </p>
                {roleRates.map(({ role, rate, pinataMaestroRate }) => (
                  <div className={styles.partnerActivity} key={role.id}>
                    <Text.H3 className={styles.activityName}>{role.title}</Text.H3>
                    {!!user?.isAdmin && executionType === "maestro" ? (
                      <Form.Section noMargin>
                        <Form.HorizontalGroup>
                          <Form.DecimalBox
                            value={pinataMaestroRate}
                            label="BILL RATE"
                            placeholder="#.##/hour"
                            disabled={isEditingDisabled}
                            onChange={value => {
                              form.setFieldValue(
                                "partnerOrganizations",
                                form.values.partnerOrganizations.map(
                                  mapRoleRates(organization.id, role.id, roleRate => ({
                                    ...roleRate,
                                    pinataMaestroRate: value,
                                  })),
                                ),
                              );
                            }}
                          />
                          <Form.DecimalBox
                            value={rate}
                            label="PAY RATE"
                            placeholder="#.##/hour"
                            disabled={isEditingDisabled}
                            onChange={value => {
                              form.setFieldValue(
                                "partnerOrganizations",
                                form.values.partnerOrganizations.map(
                                  mapRoleRates(organization.id, role.id, roleRate => ({
                                    ...roleRate,
                                    rate: value,
                                  })),
                                ),
                              );
                            }}
                          />
                        </Form.HorizontalGroup>
                      </Form.Section>
                    ) : (
                      <Form.Section noMargin>
                        <Form.DecimalBox
                          value={rate}
                          label="DEFAULT RATE"
                          placeholder="#.##/hour"
                          disabled={isEditingDisabled}
                          onChange={value => {
                            form.setFieldValue(
                              "partnerOrganizations",
                              form.values.partnerOrganizations.map(
                                mapRoleRates(organization.id, role.id, roleRate => ({
                                  ...roleRate,
                                  rate: value,
                                })),
                              ),
                            );
                          }}
                        />
                      </Form.Section>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Partnership() {
  const { data: numberAvailablePartnerOrganizationsData } = useQuery<
    NumberAvailablePartnerOrganizationsQuery,
    NumberAvailablePartnerOrganizationsQueryVariables
  >(numberAvailablePartnerOrganizationsQuery);

  const programForm = React.useContext(ProgramFormCtx);
  const { data: partnerOrganizationsData, refetch, loading } = useQuery<
    PartnerOrganizationsQuery,
    PartnerOrganizationsQueryVariables
  >(partnerOrganizationsQuery, {
    variables: { id: programForm.id || "" },
    fetchPolicy: "no-cache",
  });

  const { status, canEdit } = programForm;

  React.useEffect(() => {
    if (status.status === "saved") {
      refetch({ id: programForm.id || "" });
    }
  }, [status.status]);

  const userInfo = useUserInfo();
  const user = userInfo.me;

  if (
    !partnerOrganizationsData ||
    !partnerOrganizationsData?.programRequest ||
    !user ||
    !numberAvailablePartnerOrganizationsData
  ) {
    return <LoadingContainer message={"Loading"} />;
  }

  const programRequest = partnerOrganizationsData.programRequest;
  const numberAvailablePartnerOrganizations =
    numberAvailablePartnerOrganizationsData.organizationConnectionsPartners.pageInfo.totalCount;

  return (
    <>
      <AccordionPart>
        <AccordionPartHelper>
          <Helper1>
            You have access to [{numberAvailablePartnerOrganizations}] Partner Teams on PINATA. If you plan to
            collaborate with any of them on this Program, please add them here. Then you can configure their
            responsibilites for this Program.
          </Helper1>
          <Helper2>
            <Link to={{ ...location, pathname: "" }}>
              <Text.NewLink4>Help Center: All about Partnerships</Text.NewLink4>
            </Link>
          </Helper2>
        </AccordionPartHelper>
        <AccordionPartContent>
          <Formik<FormValues>
            initialValues={{
              partnerOrganizationIds: programRequest.partnerOrganizations.map(({ organization }) => organization.id),
              partnerOrganizations: programRequest.partnerOrganizations,
            }}
            validate={values => programForm.setState(values, { partnership: true }, ID)}
            onSubmit={() => {}}
            enableReinitialize
          >
            {formik => {
              const updatedPartnersList =
                formik.dirty &&
                formik.initialValues.partnerOrganizationIds.length !== formik.values.partnerOrganizationIds.length;

              return (
                <>
                  <AddPartners
                    user={user}
                    form={formik}
                    canEdit={canEdit}
                    programOwnerId={programRequest.ownerOrganization.id}
                  />
                  <ConfigurePartners
                    form={formik}
                    programForm={programForm}
                    user={user}
                    isLoading={loading || (status.status !== "saved" && updatedPartnersList)}
                  />
                </>
              );
            }}
          </Formik>
          <SaveButton id="partnership" />
        </AccordionPartContent>
      </AccordionPart>
    </>
  );
}

export default Partnership;
