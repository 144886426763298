import React from "react";
import Text from "components/Text";
import cx from "classnames";
import variables from "styles/variables";
import styles from "./styles.scss";

export type CheckboxValue = string;

export interface CheckboxProps {
  testId?: string;
  value?: CheckboxValue;
  children?: React.ReactNode;
  checked?: boolean | "interm";
  disabled?: boolean;
  onCheck?: (checked: boolean) => any;
  labelClassName?: string;
  className?: string;
  large?: boolean;
  inverseColors?: boolean;
}

export default class Checkbox extends React.Component<CheckboxProps> {
  private handleChange = () => {
    if (!this.props.disabled && this.props.onCheck) {
      this.props.onCheck(!this.props.checked);
    }
  };

  private handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      this.handleChange();
    }
  };

  public render() {
    const { testId, children, checked, disabled, labelClassName, className, large, inverseColors } = this.props;

    const transform = checked
      ? `scale(${large ? "1.5" : "1"}) translate(${large ? "1.6 8" : "3 4"})`
      : `scale(0) translate(${large ? "1.6 8" : "3 4"})`;

    return (
      <label
        data-test={testId}
        title={typeof children === "string" ? children : undefined}
        className={cx(disabled ? styles.container : styles.enabledContainer, className)}
        tabIndex={0}
        onClick={this.handleChange}
        onKeyUp={this.handleKeyUp}
      >
        <div
          role={"checkbox"}
          aria-checked={!!checked}
          aria-disabled={disabled}
          className={cx(styles.checkbox, large && styles.checkboxLarge)}
        >
          <svg className={cx(styles.checkboxSvg, large && styles.checkboxSvgLarge)}>
            <rect
              className={
                checked
                  ? inverseColors
                    ? styles.inversedCheckedBg
                    : styles.checkedBg
                  : inverseColors
                  ? styles.inversedBg
                  : styles.bg
              }
              width={large ? "19" : "15"}
              height={large ? "19" : "15"}
              strokeWidth=".5"
              x=".5"
              y=".5"
              rx="2"
            />

            {checked === "interm" ? (
              <line x1="4" y1="10" x2="16" y2="10" className={styles.line} />
            ) : (
              <path
                className={checked ? (inverseColors ? styles.inversedTick : styles.checkedTick) : styles.tick}
                fill={variables.white}
                transform={transform}
                d="M9.724 1.692L4 7.71a.91.91 0 0 1-1.333 0L.276 5.197a1.03 1.03 0 0 1 0-1.403.911.911 0 0 1 1.333 0l1.724 1.813L8.39.291a.91.91 0 0 1 1.334 0 1.026 1.026 0 0 1 0 1.401"
              />
            )}
          </svg>
        </div>

        {typeof children === "string" ? (
          <Text inline size={12} ellipsis noSelect left={"s"} className={labelClassName}>
            {children}
          </Text>
        ) : (
          children
        )}
      </label>
    );
  }
}
