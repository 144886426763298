import * as React from "react";
import * as filestack from "filestack-js";
import _, { omit } from "lodash";
import cx from "classnames";
import Text from "components/LegacyText";
import Icon from "components/Icon";
import TextBox from "components/Form/TextBox";
import Clickable from "components/Clickable";
import ProgressCircle from "components/ProgressCircle";
import NumberEasing from "components/NumberEasing";
import Button from "components/Button";
import plusIcon from "assets/plus.svg";
import { FS_API_KEY, FS_SECURE_API_KEY, FS_SECURITY, transformImage } from "utilities/filestack";
import { resizeImage } from "utilities/files";
import styles from "./styles.scss";
import fileIcon from "assets/file.svg";
import removeIcon from "assets/delete.svg";
import xIcon from "assets/times.svg";
import { colors } from "styles/variables";
import ImageOpener from "components/ImageGroup/ImageOpener";
import { Rollbar } from "utilities/rollbar";

export interface IFile {
  id?: string;
  url: string;
  caption?: string;
  name?: string;
  isPrimary?: boolean;
}

interface Props {
  value: IFile[];
  onChange: (files: IFile[]) => void;
  size?: number;
  maxFileSize?: number;
  disabled?: boolean;
  required?: boolean;
  caption?: boolean;
  onClick?: React.MouseEventHandler;
  children?: () => React.ReactNode;
  accepts: string;
  testId?: string;
  fileName?: string;
  multi?: boolean;
  compactRemove?: boolean;
  secure?: boolean;
  onError?: (uploadState: UploadState) => void;
}

type UploadState = number | "uploadError" | "tooBig";

interface State {
  uploading: { [key: string]: UploadState };
  nonImageUrls: string[];
}

const DEFAULT_MAX_SIZE = 52428800;

export default class FilePicker extends React.Component<Props, State> {
  readonly state: State = {
    uploading: {},
    nonImageUrls: [],
  };

  public handleUpload = (e: any) => {
    let files = _.sortBy(e.target.files, "size");

    for (const file of files) {
      const id = file.name;

      if (file.size > (this.props.maxFileSize ?? DEFAULT_MAX_SIZE)) {
        this.setState(state => ({
          uploading: {
            ...state.uploading,
            [id]: "tooBig",
          },
        }));

        continue;
      }

      this.setState(
        state => ({
          uploading: {
            ...state.uploading,
            [id]: 0,
          },
        }),

        async () => {
          const { accepts } = this.props;
          const isImage = accepts.includes("image") && file.type.includes("image") && !(file.type === "image/heic");
          let resizedFile = file;
          try {
            if (isImage) {
              resizedFile = await resizeImage(file, 1080);
            }
          } catch (e) {
            alert("Unable to resize image. Please try again or use different file.");
            Rollbar?.error("Couldn't resize image provided by user", {
              error: e,
              state: JSON.stringify(this.state),
              props: JSON.stringify(this.props),
            });
            throw new Error(e);
          }

          const onProgress = ({ totalPercent }: { totalPercent: number }) => {
            this.setState(state => ({
              uploading: {
                ...state.uploading,
                [id]: totalPercent,
              },
            }));
          };

          const security = this.props.secure ? FS_SECURITY : undefined;

          filestack
            .init(this.props.secure ? FS_SECURE_API_KEY : FS_API_KEY)
            .upload(resizedFile, { onProgress }, {}, {}, security)
            .then(({ url }) => {
              this.props.onChange([...this.props.value, { url, caption: !isImage ? file.name : undefined }]);

              this.setState(state => ({ ...state, uploading: _.omit(state.uploading, id) }));
            })
            .catch(() => {
              this.setState(state => ({
                uploading: {
                  ...state.uploading,
                  [id]: "uploadError",
                },
              }));
            });
        },
      );
    }
    files = null as any;
  };

  public render() {
    const {
      value,
      children,
      accepts,
      disabled,
      size = 50,
      required,
      onChange,
      onClick,
      caption,
      testId,
      multi = true,
      onError,
    } = this.props;
    const { uploading } = this.state;

    const uploadingViews = [];

    for (const file in uploading) {
      if (uploading.hasOwnProperty(file)) {
        const state = uploading[file];

        if (typeof state === "number") {
          uploadingViews.push(
            <NumberEasing key={file} value={state} duration={800}>
              {val => (
                <div className={cx(caption && styles.vertical)}>
                  <div className={styles.upload} style={{ width: size, height: size }}>
                    <ProgressCircle value={val === 0 ? "indeterminate" : val} color={"white"} size={size / 2} />
                    {val ? (
                      <Text.H4 kind={"reverse"} className={styles.pickerLabel}>
                        {val}%
                      </Text.H4>
                    ) : null}
                  </div>
                  {caption && (
                    <TextBox
                      placeholder="Write here..."
                      label={onlyImages ? "CAPTION" : "FILENAME"}
                      inputClassName={styles.textBoxInput}
                      className={styles.textBox}
                      optionalLabel={onlyImages}
                      disabled
                    />
                  )}
                </div>
              )}
            </NumberEasing>,
          );
        } else if (!!onError) {
          onError(state);
        } else {
          uploadingViews.push(
            <div key={file} className={cx(caption && styles.vertical)}>
              <div className={styles.uploadError} style={{ width: size, height: size }}>
                <Icon src={xIcon} size={size / 2} fill={"white"} />
                <Clickable
                  className={styles.remove}
                  actionLabel={"Remove"}
                  onClick={e => {
                    this.setState(state => ({ uploading: omit(state.uploading, file) }));
                    e.stopPropagation();
                  }}
                >
                  <Icon src={removeIcon} size={15} fill={"white"} />
                </Clickable>
              </div>
              <div>
                <Text.H3>{file}</Text.H3>

                {state === "tooBig" ? (
                  <Text.P4>
                    This file exceeds the size limit of{" "}
                    {((this.props.maxFileSize ?? DEFAULT_MAX_SIZE) / 1024 / 1024).toLocaleString()}MB
                  </Text.P4>
                ) : (
                  <Text.P4>
                    Something went wrong while uploading this file. Make sure you're online and try again.
                  </Text.P4>
                )}
              </div>
            </div>,
          );
        }
      }
    }

    const any = uploadingViews.length > 0 || value.length > 0;
    const multiUpload = multi || (value.length < 1 && uploadingViews.length === 0);

    const onlyImages = this.props.accepts.startsWith("image/");

    return (
      <div className={styles.imagesArray} data-test={testId}>
        {value.map((file, index) => {
          const url = file.url;

          const showFallback = this.state.nonImageUrls.includes(url);

          const valueView = (
            <>
              {" "}
              {showFallback ? (
                <Icon src={fileIcon} size={30} fill={colors.white} />
              ) : (
                <img
                  src={transformImage(url, {
                    rotate: null,
                    resize: { fit: "crop", width: size * 2, height: size * 2 },
                  })}
                  onError={() => this.setState(state => ({ nonImageUrls: state.nonImageUrls.concat([url]) }))}
                  width={size}
                  height={size}
                />
              )}
              {!disabled && (
                <Clickable
                  className={styles.remove}
                  actionLabel={"Remove"}
                  onClick={e => {
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                    e.preventDefault();

                    onChange(value.filter(({ url }) => url !== file.url));
                  }}
                >
                  <Icon src={removeIcon} size={15} fill={"white"} />
                </Clickable>
              )}
            </>
          );

          return (
            <div className={cx(caption && styles.vertical)} key={index}>
              {!accepts.includes("image") || showFallback ? (
                <Button
                  className={showFallback ? styles.fileButton : styles.fileButtonImg}
                  style={{ width: size, height: size }}
                  onClick={() => window.open(url, "_blank")}
                >
                  {valueView}
                </Button>
              ) : (
                <ImageOpener
                  className={showFallback ? styles.fileButton : styles.fileButtonImg}
                  style={{ width: size, height: size }}
                  src={url}
                  caption={file.caption ?? "Image"}
                  fileName={this.props.fileName}
                >
                  {valueView}
                </ImageOpener>
              )}

              {caption ? (
                <TextBox
                  inputClassName={styles.textBoxInput}
                  className={styles.textBox}
                  placeholder="Write here..."
                  label={onlyImages ? "CAPTION" : "FILENAME"}
                  value={file.caption}
                  onChange={e => {
                    this.props.onChange(
                      this.props.value.map(f => (f.url === file.url ? { url: file.url, caption: e.target.value } : f)),
                    );
                  }}
                />
              ) : null}
            </div>
          );
        })}

        {uploadingViews}
        {multiUpload && (
          <label onClick={onClick}>
            <Clickable
              actionLabel={onlyImages ? "Add an Image" : "Add a File"}
              className={disabled ? styles.disabledPicker : any ? styles.pickerExtra : styles.picker}
              style={{ width: size, height: size }}
            >
              <Icon src={plusIcon} fill={"white"} size={25} />
              <Text.H4 kind={"reverse"} className={styles.pickerLabel}>
                {required ? (any ? "Optional" : "Required") : "Upload"}
              </Text.H4>
            </Clickable>
            <input
              type="file"
              disabled={disabled}
              accept={this.props.accepts}
              multiple={multi}
              style={{ display: "none" }}
              onChange={this.handleUpload}
            />
          </label>
        )}

        {!any && children && children()}
      </div>
    );
  }
}
