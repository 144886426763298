import React from "react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik";
import { find } from "lodash";

import RadioButton from "components/RadioButton";
import styles from "./styles.scss";
import cx from "classnames";

export function LeaveBlank({
  form,
  field: { name },
  extra,
  white,
  clearValue = null,
  className,
}: FieldProps & { extra?: string[]; white?: boolean; clearValue?: any; className?: string }) {
  const { t } = useTranslation();
  const allFields = [name].concat(extra || []);
  const blank = !find(
    form.values,
    (value, key) => allFields.includes(key) && value && (!Array.isArray(value) || value.length > 0),
  );

  return (
    <div className={cx(styles.removeValues, className)}>
      <RadioButton
        selected={!!blank}
        reverse={white}
        onChange={() => {
          const values = { ...form.values };

          for (let field of allFields) {
            values[field] = clearValue;
          }
          form.setValues(values);
        }}
      >
        {t("gigs.leaveBlankForNow")}
      </RadioButton>
    </div>
  );
}
