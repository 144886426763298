import { isPinata } from "utilities/flavor";

export const helpCenter = isPinata ? "https://help.gopinata.com" : "https://help.gohayday.co";

export const articles = {
  clients: `${helpCenter}/clients`,
  organizationLocations: `${helpCenter}/en/articles/3898050`,
  userPermissions: `${helpCenter}/en/articles/3995591`,
  clientsVerifyingReports: `${helpCenter}/clients/internal-team-management/clients-verifying-reports`,
  agenciesVerifyingReports: `${helpCenter}/agency-partners/manage-gogetters/agency-partners-verifying-gigs`,
  technicalSupport: `${helpCenter}/en/collections/159985-technical-support`,
  fieldReportTips: `${helpCenter}/gogetters#during-and-after-a-gig`,
  gigTroubleshooting: `${helpCenter}/gogetters`,
  gigCancellation: `${helpCenter}/gogetters/gogetter-faqs/cancel-on-a-gig-withdraw`,
  gigsAndCalendar: `${helpCenter}/en/articles/3411798-gigs-and-calendar-views}`,
  cdcGuidelines: "https://www.cdc.gov/coronavirus/2019-nCoV/index.html",
  haydayTroubleshooting: `${helpCenter}`,
  haydayLearnMore: `https://gohayday.co`,
};
