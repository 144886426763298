import * as React from "react";
import { ImageInfo, IImageGroupContext, withImageGroupContext } from "./context";

interface Props extends ImageInfo {
  className?: string;
  style?: any;
  children: React.ReactNode;
  imageGroup: IImageGroupContext;
}

class ImageOpener extends React.Component<Props> {
  private getContext() {
    if (!this.props.imageGroup)
      throw new Error("Could not find an ImageGroup Context, please add a wrapping <ImageGroup> component.");

    return this.props.imageGroup;
  }

  private getImageInfo(): ImageInfo {
    const { src, caption, fileName } = this.props;
    return { src, caption, fileName };
  }

  public componentDidMount() {
    this.getContext().addImage(this.getImageInfo());
  }

  public componentWillUnmount() {
    this.getContext().removeImage(this.getImageInfo());
  }

  public render() {
    const { className, style, children } = this.props;

    return (
      <div className={className} style={style} onClick={() => this.getContext().openImage(this.getImageInfo())}>
        {children}
      </div>
    );
  }
}

export default withImageGroupContext(ImageOpener);
