import * as React from "react";
import Button from "components/Button";
import Text from "components/LegacyText";
import Icon from "components/Icon";
import styles from "./styles.scss";
import copy from "copy-to-clipboard";
import linkIcon from "assets/link.svg";
import downloadIcon from "assets/download.svg";
import calendarIcon from "assets/calendar-day.svg";
import variables from "styles/variables";
import { useQuery } from "@apollo/client";
import gigIcsDetails from "./gigIcsDetails.gql";
import { GigIcsQuery } from "gql-gen";
import Clickable from "components/Clickable";

interface Props {
  onClose: () => void;
  onPrint: () => void;
  gigId?: string;
}

export function GigDetailsActions({ onClose, onPrint, gigId }: Props) {
  const { data } = useQuery<GigIcsQuery>(gigIcsDetails, { variables: { gigId } });

  const onAddToCalendar = (ics: string) => {
    const blob = new Blob([ics], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.download = "gig.ics";
    link.href = URL.createObjectURL(blob);
    link.click();
  };
  return (
    <div className={styles.actionsContainer}>
      {/*TODO use this button again when there's an actual dashboard to move user to*/}
      {/*<Button kind={"translucent"} onClick={onClose} className={styles.dashboardBtn}>*/}
      {/*Go to full dashboard*/}
      {/*</Button>*/}
      <Text.Display className={styles.otherOptions}>
        <span>Other options</span>
      </Text.Display>

      <div className={styles.buttonsContainer}>
        <Button kind={"translucent"} onClick={onPrint} className={styles.actionButton}>
          <Icon src={downloadIcon} fill={variables.gray3} size={16} />
          Download
        </Button>

        <Button kind={"translucent"} onClick={() => copy(window.location.href)} className={styles.actionButton}>
          <Icon src={linkIcon} fill={variables.gray3} size={16} />
          Copy direct link
        </Button>
        {(data?.gigIcs?.startTime || data?.gigIcs?.windowStartTime) && data?.gigIcs?.ics && (
          <div className={styles.addToCalendarContainer}>
            <Button
              kind={"translucent"}
              onClick={() => onAddToCalendar(data.gigIcs!.ics)}
              className={styles.actionButton}
            >
              <Icon src={calendarIcon} fill={variables.gray3} size={16} />
              Add to calendar
            </Button>
            <Text.Display className={styles.calDescription}>
              <span>Note: Future time/date changes will not automatically update on your calendar.</span>
            </Text.Display>
          </div>
        )}
      </div>
      <Clickable onClick={onClose}>
        <Text.Display1 className={styles.close}>Close</Text.Display1>
      </Clickable>
    </div>
  );
}
