import * as React from "react";
import Display, { DisplayProps, DisplayVariant } from "./Display";
import H, { HProps, HVariant } from "./H";
import Label1 from "./Label1";
import Label2 from "./Label2";
import P, { PProps, PVariant } from "./P";
import Nav from "./Nav";
import Link from "./Link";
import NewLink, { NewLinkVariant, NewLinkProps } from "./NewLink";
import LabeledDisplay from "./LabeledDisplay";
import ErrorMessage from "./ErrorMessage";
import Button from "./Button";
import Message from "./Message";
import CellValue from "./CellValue";

const hVariant = (v: HVariant) => (props: HProps) => <H {...props} variant={v} />;
const pVariant = (v: PVariant) => (props: PProps) => <P {...props} variant={v} />;
const newLinkVariant = (v: NewLinkVariant) => (props: NewLinkProps) => <NewLink {...props} variant={v} />;
const displayVariant = (v: DisplayVariant) => (props: DisplayProps) => <Display {...props} variant={v} />;

export default {
  Display,
  Display1: displayVariant("v1"),
  Display2: displayVariant("v2"),
  Display3: displayVariant("v3"),
  Display4: displayVariant("v4"),
  Display5: displayVariant("v5"),
  H1: hVariant("v1"),
  H2: hVariant("v2"),
  H3: hVariant("v3"),
  H4: hVariant("v4"),
  P,
  P1: pVariant("v1"),
  P2: pVariant("v2"),
  P3: pVariant("v3"),
  P4: pVariant("v4"),
  P5: pVariant("v5"),
  Label1,
  Label2,
  Nav,
  Link,
  NewLink1: newLinkVariant("v1"),
  NewLink2: newLinkVariant("v2"),
  NewLink3: newLinkVariant("v3"),
  NewLink4: newLinkVariant("v4"),
  LabeledDisplay,
  ErrorMessage,
  Button,
  Message,
  CellValue,
};
