import React, { useRef, useState, useMemo, ReactNode, useEffect } from "react";
import { TFunction } from "i18next";
import { Formik, FormikProps } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation, Trans } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { parseISO } from "date-fns";
import { GigSubmitProgramDetailsFragment, GigFormCreateGigsMutation, AccessLevel } from "gql-gen";
import useRouter from "use-react-router";
import * as O from "fp-ts/Option";
import * as Rec from "fp-ts/Record";
import { pipe } from "fp-ts/lib/function";
import { omit } from "lodash";

import lockIcon from "assets/lock.svg";
import chevronIcon from "assets/chevron.svg";
import bullseyeIcon from "assets/bullseye-arrow-solid.svg";
import productIcon from "assets/productSquare.svg";
import programIcon from "assets/megaphone.svg";
import copiesIcon from "assets/duplicate.svg";
import infoIcon from "assets/info-circle.svg";
import filesIcon from "assets/file.svg";

import { useComposableForm, ComposeValues, ComposeData } from "modules/Connection/ComposableForm";
import { useReloader } from "modules/Connection/Reloader";
import { ModalRouteParams } from "utilities/modals";
import { omitQueries, addQueries, getProgramIdFromSearch, getUrlQueries } from "utilities/routes";
import { useQueryParams } from "hooks/useQueryParams";
import { idIn } from "utilities/knueppel";
import Message from "components/LegacyText/Message";
import { useConnectionItems } from "hooks/useConnectionItems";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { Modal } from "components/Modal";
import Text, { TextGroup, TextNode } from "components/Text";
import Section from "components/Form/Section";
import Button from "components/Button";
import { colors } from "styles/variables";
import Icon, { IconSrc } from "components/Icon";
import { useMobileQuery } from "components/Layout/Responsive";
import { SaveButton, Action } from "components/SaveButton";
import { fromBase64 } from "utilities/base64";
import { isUserAtLeast } from "interfaces/user";
import { fromServerValidation, ValidationError } from "modules/Connection/Scope";
import {
  isBudgetError,
  extractErrors as extractBudgetErrors,
  Display as DisplayBudgetError,
} from "modules/Connection/ScopeErrors/BudgetErrors";

import createGigs from "./createGigs.gql";
import programDetails from "./programDetails.gql";
import requestApprovalMut from "../Actions/RequestApproval/requestApproval.gql";
import organizationQuery from "./organizationQuery.gql";

import { programsRouteName, ProgramsRouteParams } from "../../../Organization/Programs";
import { gigsConnection } from "../connection";
import { GigPartnerField } from "../Fields/Partner";
import { GigProductField } from "../Fields/Product";
import styles from "./styles.scss";
import { programConnection } from "../Fields/Program/ProgramPicker/ProgramPicker";
import { GigDateField } from "../Fields/Date";
import { CopiesField } from "./Copies";
import { GigProgramField } from "../Fields/Program";
import { GigLocationField } from "../Fields/Location";
import { GigOwnerField } from "../Fields/Owner";
import { GigRoleField } from "../Fields/Role";
import { GigTitleField } from "../Fields/Title";
import { DateCell } from "../Cells/Date";
import { LocationCell } from "../Cells/Location";
import { FieldOptionsText, FieldOptionsBold } from "../Fields/FieldOptionsText";
import { GigDescriptionField } from "../Fields/Description";
import { GigAgencyGuidanceField } from "../Fields/AgencyGuidance";
import { GigExpenseNotesField } from "../Fields/ExpenseNotes";
import { GigFilesField } from "../Fields/Files";

const ns = "gigs.create";

const forms = [
  GigTitleField,
  GigDescriptionField,
  CopiesField,
  GigProgramField,
  GigProductField,
  GigPartnerField,
  GigRoleField,
  GigLocationField,
  GigDateField,
  GigAgencyGuidanceField,
  GigExpenseNotesField,
  GigOwnerField,
  GigFilesField,
];

type BookGigData = ComposeData<typeof forms>;
type BookGigValues = ComposeValues<typeof forms> & { general?: string };

type SaveState =
  | null
  | { type: "saving" }
  | { type: "saved"; ids: string[] }
  | { type: "networkError" }
  | { type: "error"; error: string }
  | { type: "validationError"; error: ValidationError };

export interface GigFormModalProps extends RouteComponentProps<ModalRouteParams & ProgramsRouteParams> { }

const clearSearch = (search: string) => omitQueries(search, ["from", "date", "values", "newLocId"]);

export function BookAGig({
  location: { search, pathname },
  history,
  match: {
    params: { orgId, programGroupId, page },
  },
}: GigFormModalProps) {
  const programIdParam = getProgramIdFromSearch(search);
  const programId = !programIdParam.includes(",") && programIdParam !== "all" ? programIdParam : undefined;
  const { t } = useTranslation();

  const handleClose = (gigId?: string) => {
    // TODO #512: Create and use Modal abstraction
    if (orgId && programIdParam) {
      let updatedSearch = clearSearch(search);
      if (typeof gigId === "string") {
        updatedSearch = addQueries(search, { goto: gigId });
      }
      history.push({
        search: updatedSearch,
        pathname: `/${orgId}/program-group/${programGroupId}/${programsRouteName}/${programIdParam}${page ? `/${page}` : ""
          }`,
      });
    }
  };

  const { me } = useUserInfo();
  const { from, date, values, newLocId } = useQueryParams(search);
  const { data, loading } = useQuery(organizationQuery, { variables: { orgId } });
  const canBatchUpload =
    data?.organization?.bulkUploaderActive &&
    !loading &&
    me &&
    me.organizationUser &&
    isUserAtLeast({ organizationUser: me.organizationUser }, "manager");

  const openBatchUploadModal = (programId: string) => {
    history.push({
      pathname: pathname.replace("/+book-gigs", "") + "/+gigs-bulk-uploader/" + programId,
      search: search,
    });
  };

  const [programsIx] = useConnectionItems<GigSubmitProgramDetailsFragment>(
    programConnection,
    programDetailsFragment,
    programId ? [programId] : [],
  );

  const preselectProgram: boolean = pipe(
    programsIx,
    O.fromNullable,
    O.chain(Rec.lookup(programId || "")),
    O.exists(program => !program.archived && !program.atCapacity),
  );

  const [save] = useMutation<GigFormCreateGigsMutation>(createGigs);

  const reload = useReloader();

  const [saveState, setSaveState] = useState<SaveState>(null);

  const defaultValues = useMemo(() => {
    if (values) {
      const parsedValues = JSON.parse(fromBase64(values));
      if (parsedValues.start) {
        parsedValues.start = new Date(parsedValues.start);
      }
      if (parsedValues.end) {
        parsedValues.end = new Date(parsedValues.end);
      }
      if (parsedValues.date) {
        parsedValues.date = new Date(parsedValues.date);
      }

      return parsedValues;
    }
    return {
      date: date ? parseISO(date) : undefined,
      programId,
    };
  }, [programId, date, values]);

  const { initialValues, validate, onSubmit, renderField } = useComposableForm<BookGigData, BookGigValues>({
    connection: from ? gigsConnection : undefined,
    variables: from ? { filters: idIn([from]) } : undefined,
    copying: !!from,
    forms,
    defaultValues: newLocId ? { ...defaultValues, orgLocationId: newLocId } : defaultValues,
    formMeta: {},
    handleClose: () => { },
    handleSubmit: async data => {
      try {
        setSaveState({ type: "saving" });

        const { data: createGigsData } = await save({
          variables: {
            gigs: [data],
          },
        });

        reload(gigsConnection.name);

        setSaveState({ type: "saved", ids: createGigsData?.createGigs.gigIds ?? [] });
      } catch (e) {
        const error = e.graphQLErrors?.[0];

        if (error?.message === "validationError") {
          setSaveState({
            type: "validationError",
            error: fromServerValidation(error),
          });
        } else if (e.networkError) {
          setSaveState({ type: "networkError" });
        } else {
          setSaveState({ type: "error", error: "other" });
        }

        throw e;
      }
    },
  });

  const withForcedProgramSelection = {
    ...initialValues,
    ...(preselectProgram ? { programId } : {}), // even if programId is undefined, the key will override the value set by `initialize` in `GigProgramField`
  };

  const fixedScroll = useRef<boolean>(false);

  const scrollToTop = (div: HTMLDivElement) => {
    if (div && !fixedScroll.current) {
      setTimeout(() => {
        div.scrollTop = 0;
        fixedScroll.current = true;
      }, 200);
    }
  };

  const isMobile = useMobileQuery();

  return (
    <Modal onClose={handleClose} className={styles.modal}>
      {initialValues && (
        <Formik<BookGigValues>
          enableReinitialize
          initialValues={withForcedProgramSelection}
          validate={validate}
          onSubmit={onSubmit}
        >
          {fp => (
            <>
              <header className={styles.header}>
                {isMobile && (
                  <div className={styles.headerBackIcon}>
                    <Icon src={chevronIcon} fill="white" rotate={90} size={10} />
                  </div>
                )}

                <div className={styles.headerContent}>
                  <div className={styles.labels}>
                    <div>
                      <Text bottom="s" font="wes" color="white" letterSpacing={0.98}>
                        {t(`${ns}.${isMobile ? "newGig" : "letsCreateANewGig"}`)}
                      </Text>

                      {!isMobile && (
                        <>
                          <TextGroup color="white" font="wesR" size={24} bottom="l">
                            <Trans i18nKey={`${ns}.firstChooseAProgram`}>
                              First,&nbsp;
                              <TextNode font="wes" bold>
                                choose a program
                              </TextNode>
                            </Trans>
                          </TextGroup>
                        </>
                      )}
                    </div>
                  </div>

                  <div className={styles.programPicker}>
                    <Section noMargin>{renderField(fp, "program")}</Section>
                    {!isMobile && !loading && canBatchUpload && (
                      <Button
                        kind="primaryGradient"
                        onClick={() => openBatchUploadModal(fp.values.programId)}
                        disabled={!fp.values.programId}
                        className={styles.batchUploadButton}
                      >
                        {t(`${ns}.batchUpload`)}
                      </Button>
                    )}
                  </div>
                </div>
              </header>

              <div className={styles.mainForm} ref={scrollToTop}>
                {!isMobile && (
                  <TextGroup font="wesR" size={24} color="gray3" bottom="m">
                    <Trans i18nKey={`${ns}.nextAddDetails`}>
                      Next,
                      <TextNode font="wes" bold>
                        add details
                      </TextNode>
                    </Trans>
                  </TextGroup>
                )}

                <section className={styles.fieldSection}>
                  <Icon src={infoIcon} size={20} fill={colors.gray1} />

                  <div className={styles.fieldSectionContent}>
                    {renderField(fp, "title")}
                    {renderField(fp, "description")}
                  </div>
                </section>

                <FieldSection icon={DateCell.icon} title={t(`${ns}.fields.date.title`)}>
                  {renderField(fp, "date")}
                </FieldSection>

                <FieldSection icon={LocationCell.icon} title={t(`${ns}.fields.location.title`)}>
                  {renderField(fp, "location")}
                </FieldSection>

                <FieldSection icon={productIcon} title={t(`${ns}.fields.product.title`)}>
                  {renderField(fp, "product")}
                </FieldSection>

                <FieldSection icon={bullseyeIcon} title={t(`${ns}.fields.role.title`)}>
                  {renderField(fp, "role")}
                </FieldSection>

                <FieldSection icon={programIcon} title={t(`${ns}.fields.programAndGigDetails.title`)}>
                  <FieldOptionsText>
                    <Trans i18nKey={`${ns}.fields.programAndGigDetails.explanation`}>
                      If needed, you can edit this program's
                      <FieldOptionsBold> default settings </FieldOptionsBold>
                      for this task.
                    </Trans>
                  </FieldOptionsText>
                  <div className={styles.pndField}>{renderField(fp, "agencyGuidance")}</div>
                  <div className={styles.pndField}>{renderField(fp, "expenseNotes")}</div>
                </FieldSection>

                <FieldSection icon={filesIcon} title={t(`${ns}.fields.files.title`)}>
                  <FieldOptionsText>
                    <Trans i18nKey={`${ns}.fields.files.explanation`}>
                      Add any additional documents, images, audio, or videos here. Max 50 mgb
                    </Trans>
                  </FieldOptionsText>
                  {renderField(fp, "files")}
                </FieldSection>

                {fp.isSubmitting && !saveState && (
                  <Message kind="neutral" className={styles.message}>
                    {t("gigs.create.submitting")}
                  </Message>
                )}

                {Object.keys(fp.errors).length > 0 && fp.submitCount > 0 && (
                  <Message kind="error" className={styles.message}>
                    {t("gigs.create.fixAllErrors")}
                  </Message>
                )}
              </div>

              <div className={styles.sideForm}>
                {typeof fp.values.programId === "string" && <ProgramOwnerView programId={fp.values.programId} />}

                {renderField(fp, "partner")}

                <div className={styles.sideFormSection}>
                  <TextGroup font="wesR" color="gray3" size={24} bottom={"l"}>
                    <Trans i18nKey={`${ns}.confirmTeam`}>
                      Confirm&nbsp;
                      <TextNode font="wes" bold>
                        team
                      </TextNode>
                    </Trans>
                  </TextGroup>

                  {renderField(fp, "manager")}
                </div>
                <AccessLevelNote program={programsIx[fp.values.programId]} />
              </div>

              <footer className={styles.footer}>
                <div className={styles.copiesIcon}>
                  <Icon src={copiesIcon} size={20} fill={colors.white} />
                </div>

                <div className={styles.copies}>{renderField(fp, "copies")}</div>

                {!isMobile && (
                  <>
                    <div className={styles.copiesChevron}>
                      <Icon src={chevronIcon} size={11} fill={colors.white} rotate={90} />
                    </div>

                    <div>
                      <Text font="wes" bold size={18} color="white" bottom="xs">
                        {t(`${ns}.copies.question`)}
                      </Text>
                      <Text font="lato" size={14} color={fp.errors.copies ? "pink1" : "white"}>
                        {fp.errors.copies || t(`${ns}.copies.answer`)}
                      </Text>
                    </div>

                    <span className={"spacer"} />
                  </>
                )}

                <BookAGigSubmit
                  {...fp}
                  saveState={saveState}
                  handleClose={handleClose}
                  resetSaveState={() => {
                    setSaveState(null);
                  }}
                  reload={() => {
                    reload(gigsConnection.name);
                  }}
                />
              </footer>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
}

function AccessLevelNote({ program }: { program?: GigSubmitProgramDetailsFragment }) {
  const { t } = useTranslation();
  if (program?.myOrgAccessLevel === AccessLevel.None) {
    return (
      <Text font="lato" size={14} color={"pink1"} className={styles.accessLevelNote}>
        {t(`${ns}.noAccess`)}
      </Text>
    );
  }
  return null;
}

function FieldSection({ children, title, icon }: { children: ReactNode; title: string; icon: IconSrc }) {
  return (
    <section className={styles.fieldSection}>
      <Icon src={icon} size={20} fill={colors.gray1} />

      <div className={styles.fieldSectionContent}>
        <Text font="wes" bold size={18} bottom={"xs"} className={styles.fieldSectionTitle}>
          {title}
        </Text>
        {children}
      </div>
    </section>
  );
}

interface ProgramOwnerViewProps {
  programId: string;
}

function ProgramOwnerView(props: ProgramOwnerViewProps) {
  const { programId } = props;
  const [programsIx] = useConnectionItems<GigSubmitProgramDetailsFragment>(programConnection, programDetailsFragment, [
    programId,
  ]);
  const program = programsIx[programId];

  return (
    <div className={styles.sideFormSection}>
      <Text color="gray3" font="wes" bold size={22} bottom="s">
        Program Owner
      </Text>
      <Text color="gray2" size={14} bottom="m">
        Can view all activity in this program
      </Text>
      <div className={styles.programOwnerNameContainer}>
        <span className={styles.programOwnerNameIcon}>
          <Icon src={lockIcon} fill={colors.gray1} size={18} />
        </span>
        <Text color="gray1" size={14} font="wes" bold>
          {program?.ownerOrganization.name}
        </Text>
      </div>
    </div>
  );
}

const programDetailsFragment = [programDetails];

interface BookAGigSubmitProps extends FormikProps<BookGigValues> {
  handleClose: (id?: string) => void;
  reload: () => void;
  resetSaveState: () => void;
  saveState: SaveState;
}

const errorLabel = (t: TFunction, saveState: SaveState) => {
  if (!saveState) {
    return null;
  }

  if (saveState.type === "error") {
    return t(`gigs.errors.${saveState.error}`);
  }

  if (saveState.type === "validationError") {
    const simpleErrors = Object.values(saveState.error.items)
      .flatMap(item => item.filter(errKey => !isBudgetError(errKey)))
      .map(errKey => t(`gigs.errors.${errKey}`));

    const messages = [...extractBudgetErrors(saveState.error), ...simpleErrors];

    const message = messages[0];

    return typeof message === "string" ? (
      <Text color="white" size={12} key={message}>
        {message}
      </Text>
    ) : message.scope === "budget" ? (
      <DisplayBudgetError color="white" key={message.tag} message={message} />
    ) : null;
  }

  return null;
};

function BookAGigSubmit(props: BookAGigSubmitProps) {
  const { handleClose, handleSubmit, reload, resetForm, setValues, resetSaveState, saveState, values } = props;
  const { programId, copies } = values;
  const { me } = useUserInfo();
  const { t } = useTranslation();
  const [programsIx] = useConnectionItems<GigSubmitProgramDetailsFragment>(
    programConnection,
    programDetailsFragment,
    programId ? [programId] : [],
  );

  const program = typeof programId === "string" ? programsIx[programId] : undefined;

  const disableSave = program?.myOrgAccessLevel === AccessLevel.None;

  const needsApproval = () =>
    program?.isApprovalRequired &&
    (me?.organizationUser.type === "member" || me?.organizationUser.type === "limited_user");
  const canAssignTalent = () =>
    me?.organizationUser.organizationType === "agency" ||
    program?.executionType === "solo" ||
    !values.partnerOrganizationId;

  const [requestApproval] = useMutation(requestApprovalMut);

  const {
    location: { pathname, search },
    history,
  } = useRouter();

  const isCopy = "from" in getUrlQueries(search);

  useEffect(() => {
    if (isCopy) {
      const documents = Array.isArray(values.documents)
        ? values.documents.map((doc: any) => omit(doc, "id"))
        : values.documents;
      setValues({ ...values, documents });
    }
  }, [isCopy]);

  const idsOrNothing = (cb: (ids: string[]) => unknown) => () =>
    saveState?.type === "saved" ? cb(saveState.ids) : null;

  const allActions: (Action | null | false)[] = [
    needsApproval()
      ? {
        testId: `${ns}.requestApproval`,
        label: t(`${ns}.requestApproval`),
        onAction: idsOrNothing(ids =>
          requestApproval({
            variables: {
              filters: idIn(ids),
              programIds: [],
            },
          }).then(() => {
            reload();

            setTimeout(() => {
              handleClose();
            }, 100);
          }),
        ),
      }
      : canAssignTalent() && {
        testId: `${ns}.continueToAssignment`,
        label: t(`${ns}.continueToAssignment`),
        onAction: idsOrNothing(([id]) => {
          history.push({
            search: clearSearch(search),
            pathname: `${pathname.replace("/+book-gigs", "/+view")}/${id}/assignment`,
          });
        }),
      },

    {
      testId: `${ns}.backToGigsPage`,
      label: t(`${ns}.backToGigsPage`),
      onAction: idsOrNothing(([id]) => handleClose(id)),
    },

    {
      testId: `${ns}.duplicate`,
      label: t(`${ns}.duplicate`),
      onAction: resetSaveState,
    },

    {
      testId: `${ns}.createAnotherGig`,
      label: t(`${ns}.createAnotherGig`),
      onAction: () => {
        history.push({ search: clearSearch(search), pathname });

        setTimeout(() => {
          resetSaveState();
          resetForm();
        }, 100);
      },
    },
  ];

  const [primary, ...secondary] = allActions.filter(a => !!a) as Action[];

  const isMobile = useMobileQuery();

  return (
    <SaveButton
      testId={`${ns}.save`}
      actions={secondary}
      primaryAction={primary}
      disabled={disableSave}
      onSave={() => {
        handleSubmit();

        setTimeout(() => {
          // Scroll to the first validation error

          document.querySelector(`[data-test^=${ns.split(".")[0]}][data-test$=validationError]`)?.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          });
        }, 100);
      }}
      state={saveState && saveState.type}
      stateLabel={state => t(`${ns}.saveState.${state}`)}
      errorLabel={errorLabel(t, saveState)}
    >
      {t(`${ns}.${isMobile ? "submitMobile" : "submit"}`, { count: copies })}
    </SaveButton>
  );
}
