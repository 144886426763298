import React, { useState } from "react";
import Text from "components/Text";
import { Document, DocumentLinkUrlQuery, DocumentLinkUrlQueryVariables } from "gql-gen";
import { useApolloClient } from "@apollo/client";
import Clickable from "components/Clickable";
import query from "./documentUrl.gql";

export function DocumentLink({ document: { name, id } }: { document: Pick<Document, "id" | "name"> }) {
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Clickable onClick={openLink} disabled={loading} actionLabel={loading ? "Loading file..." : "Open file"}>
      <Text font="wes" bold color="blue2" size={14}>
        {name}
      </Text>
    </Clickable>
  );

  async function openLink() {
    setLoading(true);

    const newTab = window.open("", "_blank");

    // When opening the URL needs to be updated because it could've expired
    const {
      data: { document },
    } = await client.query<DocumentLinkUrlQuery, DocumentLinkUrlQueryVariables>({
      query,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });

    if (document?.url) {
      newTab!.location.href = document.url;
    } else {
      newTab!.document.write("Something went wrong. You can close this tab now.");
    }

    setLoading(false);
  }
}
