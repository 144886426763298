import React from "react";
import { GigTalentRateFieldFragment, UpdateGigsInput } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import DecimalBox from "components/Form/DecimalBox";
import talentRateFragment from "./talentRate.gql";
import Section from "components/Form/Section";

type Values = Pick<UpdateGigsInput, "talentRate">;

export const GigTalentRateField: ComposableField<GigTalentRateFieldFragment, Values, Values> = {
  id: "talentBillRate",

  component: props => {
    const {
      values: { talentRate },
      setFieldValue,
    } = props;

    return (
      <Section>
        <DecimalBox value={talentRate} onChange={amount => setFieldValue("talentRate", amount)} />
      </Section>
    );
  },

  initialize: data => {
    return {
      talentRate: data?.talentRate || 0,
    };
  },

  fragment: talentRateFragment,

  dependencies: [],
};
