import React from "react";
import useReactRouter from "use-react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GigsLocationFragment } from "gql-gen";

import icon from "assets/retailSquare.svg";

import { CellBase, CR1, CR2, CR3, AddDetails } from "modules/Connection/CellBase";
import { ICell } from "modules/Connection/types";
import { getGoogleMapsUrl } from "modules/Dashboard/Schema/Gigs/locations";
import Popup from "components/Popup";
import { isNoLocation, isVirtualLocation } from "utilities/location";

import styles from "./styles.scss";

import locationFragment from "./location.gql";

export const LocationCell: ICell<GigsLocationFragment> = {
  component: props => {
    const { t } = useTranslation();
    const { location: routeLocation } = useReactRouter();

    const { data, handleOpenForm } = props;

    if (!data || !data.location) {
      return (
        <CellBase {...props}>
          {() => <AddDetails onClick={handleOpenForm}>{t("gigs.columns.location.addLocation")}</AddDetails>}
        </CellBase>
      );
    }

    const { location, organizationLocationId, customLocationName } = data;

    const { address, city, state, name } = location;
    const nameCR = <CR1 underline={organizationLocationId ? "hover" : "never"}>{customLocationName || name}</CR1>;

    return (
      <CellBase {...props}>
        {mode =>
          isNoLocation(location) ? (
            <CR2>No location</CR2>
          ) : (
            <>
              {organizationLocationId ? (
                <Link to={{ pathname: `${routeLocation.pathname}/+edit-location/${organizationLocationId}` }}>
                  {nameCR}
                </Link>
              ) : (
                <Popup
                  body={() => (
                    <div className={styles.namePopup}>
                      <CR1 color={"white"}>{customLocationName}</CR1>
                    </div>
                  )}
                >
                  {nameCR}
                </Popup>
              )}

              {mode !== "compact" && address && (
                <a href={getGoogleMapsUrl(undefined, location.name, address)} target={"_blank"}>
                  <CR2 underline="hover">{address}</CR2>
                </a>
              )}

              {mode === "compact" && city && state && address && (
                <a href={getGoogleMapsUrl(undefined, location.name, address)} target={"_blank"}>
                  <CR2 underline="hover">
                    {city}, {state}
                  </CR2>
                </a>
              )}

              {city && state && mode !== "compact" && (
                <CR3>
                  {city}, {state}
                </CR3>
              )}
            </>
          )
        }
      </CellBase>
    );
  },
  icon,
  fragment: locationFragment,
};
