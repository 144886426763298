import React, { MouseEvent, useRef } from "react";
import { setRect } from "utilities/screen";
import { getScrollParent } from "utilities/scroll";
import { SheetInfo } from "../Worker/sheetInfo";
import styles from "./styles.scss";

export type SelectMode = null | "row" | "column";

interface Props {
  sheetInfo: SheetInfo;
  selectMode: SelectMode;
  onSelect?: (index: number) => void;
  onSelectionEnd: () => void;
  headerIndex: number;
}

export const Spreadsheet = React.forwardRef<HTMLDivElement, Props>(
  ({ sheetInfo, selectMode, onSelect, onSelectionEnd, headerIndex }, ref) => {
    const selectorRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);

    const timeoutRef = useRef<number | null>(null);

    const handleMouseOver = ({ target }: MouseEvent<HTMLTableElement>) => {
      const { current } = selectorRef;

      if (current && target instanceof HTMLTableCellElement && target.dataset.col && target.dataset.row) {
        if (timeoutRef.current) window.clearTimeout(timeoutRef.current);

        let top, right, bottom, left;

        if (selectMode === "row") {
          ({ top, right, bottom, left } = target.parentElement!.getBoundingClientRect());
        } else {
          ({ top, bottom } = target.parentElement!.parentElement!.getBoundingClientRect());
          ({ left, right } = target.getBoundingClientRect());
        }

        setRect(current, { top, right, bottom, left }, getScrollParent(current).getBoundingClientRect());
        current.style.opacity = "1";

        timeoutRef.current = window.setTimeout(() => {
          let range;
          if (selectMode === "column") {
            range = parseInt(target.dataset.col!, 10);
          } else {
            range = parseInt(target.dataset.row!, 10);
          }
          if (onSelect) onSelect(range);
          timeoutRef.current = null;
        }, 300);
      }
    };

    return (
      <div className={styles.tableWrapper} ref={ref}>
        {selectMode && <div className={styles.selector} ref={selectorRef} />}
        <table
          className={styles.table}
          ref={tableRef}
          onMouseOver={selectMode ? handleMouseOver : undefined}
          onClick={onSelectionEnd}
        >
          <Rows sheetInfo={sheetInfo} headerIndex={headerIndex} />
        </table>
      </div>
    );
  },
);

const Rows = React.memo(
  ({ sheetInfo: { matrix, startingRow }, headerIndex }: { sheetInfo: SheetInfo; headerIndex: number }) => {
    const [header] = matrix;

    let rows = [];

    const ly = matrix.length;
    const lx = header.length;

    for (let y = 1; y < ly; y++) {
      let cells = [];
      const row = matrix[y];

      for (let x = 0; x < lx; x++) {
        cells.push(
          <td key={x} data-col={x + 1} data-row={startingRow + y}>
            {row[x]}
          </td>,
        );
      }

      rows.push(
        <tr key={y} className={headerIndex === y ? styles.header : undefined}>
          <td>{startingRow + y}</td>
          {cells}
        </tr>,
      );
    }

    return (
      <tbody>
        <tr>
          <th />
          {header.map(row => (
            <th key={row}>{row}</th>
          ))}
        </tr>
        {rows}
      </tbody>
    );
  },
);
