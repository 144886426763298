import * as React from "react";
import { withFormik, FormikProps, Field } from "formik";
import * as yup from "yup";
import produce from "immer";
import Form from "components/Form";
import DecimalBox from "components/Form/DecimalBox";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import { CalendarDropdown } from "components/Calendar/CalendarDropdown";
import { inputProps } from "utilities/formik";
import { Helper1, Helper2 } from "../FormHelpers";
import SaveButton from "../SaveButton";
import { withProgramFormContext, validateAndNotify, ProgramFormContext } from "../Context";
import styles from "./styles.scss";
import { OptionField } from "modules/Dashboard/interfaces/Option";
import { withRouter, RouteComponentProps } from "react-router";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import { compareAsc, parseISO } from "date-fns";
import { flowRight as compose } from "lodash";
import cx from "classnames";

import { SearchableSelect } from "components/SearchableSelect";
import { connection, ConnectionConfig } from "utilities/connections";
import stateFragment from "./state.gql";
import programGroupFragment from "./programGroup.gql";
import newProgramGroupMutation from "./newProgramGroup.gql";

import LegacyText from "components/LegacyText";
import Text from "components/Text";
import NumberBox from "components/Form/NumberBox";
import { useMutation, useQuery } from "@apollo/client";
import RadioButton from "components/RadioButton";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import organizationQuery from "./organization.gql";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "../..";
import { USER_ORGANIZATION_PLACEHOLDER, getProgramIdFromSearch } from "utilities/routes";
import { Link } from "react-router-dom";
import RadioGroup from "components/RadioGroup";
import BaseFormElement from "components/Form/BaseFormElement";
import { idIn } from "utilities/knueppel";
import Switch from "components/Switch";

const statesConnection: ConnectionConfig<any> = connection({
  name: "USStatePicker",
  entry: { name: "statesConnection" },
  variables: { search: "String" },
});

const programGroupsConnection: ConnectionConfig<any> = connection({
  name: "PRFProgramGroupsPicker",
  entry: { name: "programGroupConnections" },
  variables: { search: "String" },
});

export const ID = "overview";

export interface OverviewValues {
  name: string;
  typeKey: string;
  startDate: string | null;
  endDate: string | null;
  description: string;
  keyMetric: OptionField;
  stateKeys: string[];
  locationDescription: string;
  budget: number;
  estimatedGigsCount: null | number;
  capacityLimit: null | number;
  autoActivation: boolean;
}

const Fields: OverviewValues = {
  name: "",
  typeKey: "",
  startDate: null,
  endDate: null,
  description: "",
  keyMetric: { key: "", description: "" /*targetValue: 0, unit: 'gig' */ },
  stateKeys: [],
  locationDescription: "",
  budget: 0,
  estimatedGigsCount: 0,
  capacityLimit: null,
  autoActivation: false,
};

const requiredFields = (executionType: string) => {
  if (executionType === "solo") {
    return ["name"];
  }
  return ["name", "typeKey", "startDate", "endDate", "description", "keyMetric.key", "stateKeys"];
};

type Props = RouteComponentProps<OrganizationRouteParams> &
  FormikProps<OverviewValues> & {
    programForm: ProgramFormContext;
  };

function Overview(props: Props) {
  const { values, errors, touched, programForm, setFieldValue, setValues, initialValues } = props;
  const { canEdit } = programForm;
  const input = inputProps(props, requiredFields(props.programForm.executionType), !canEdit);
  const programBudget = input("budget");
  const programNameChanged =
    compareAsc(new Date(programForm.values.nameChangedAt), new Date(programForm.values.createdAt)) === 1;
  const [newProgramGroup] = useMutation(newProgramGroupMutation);
  const {
    match: {
      params: { orgId },
    },
    location,
  } = useRouter<ProgramsRouteParams>();
  const id = getProgramIdFromSearch(location.search);

  const { data: orgData } = useQuery<
    { organization: { programGroupsVisible: boolean; programCapacityActive: boolean; type: "client" | "agency" } },
    { orgId: string }
  >(organizationQuery, { variables: { orgId }, skip: orgId === USER_ORGANIZATION_PLACEHOLDER });

  const me = useUserInfo();

  const leaveProgramDatesUnspecified = !values.startDate && !values.endDate;

  return (
    <React.Fragment>
      <AccordionPart>
        <AccordionPartHelper>
          <Helper1>
            Add details about your program to keep everyone on the same page. This form automatically saves, so you can
            start now and finish later.
          </Helper1>
          <Helper2>
            Need help?
            <br />
            <LegacyText.NewLink4
              href="https://help.gopinata.com/en/articles/3855344-how-to-create-a-new-program"
              target="_blank"
            >
              Learn about Program Creation »
            </LegacyText.NewLink4>
          </Helper2>
        </AccordionPartHelper>

        <AccordionPartContent>
          <Form.Section>
            <Field
              name="archived"
              render={({ field, form }: any) => (
                <BaseFormElement label="PROGRAM STATUS">
                  <RadioGroup
                    direction="horizontal"
                    value={field.value ? "inactive" : "active"}
                    onChange={value => form.setFieldValue(field.name, value === "inactive")}
                  >
                    <RadioButton value={"active"} className={styles.radioButton}>
                      Active
                    </RadioButton>

                    <RadioButton value={"inactive"} className={styles.radioButton}>
                      Inactive{" "}
                      <small>(Prevents new tasks from being created and hides the Program from most views)</small>
                    </RadioButton>
                  </RadioGroup>
                </BaseFormElement>
              )}
            />
          </Form.Section>

          <LegacyText.Label1 className={styles.sectionLabel}>REQUIRED INFORMATION</LegacyText.Label1>

          <Form.Section>
            <Form.HorizontalGroup>
              {orgData?.organization.programGroupsVisible && (
                <Field
                  name="programGroupIds"
                  render={({ field, form }: any) => (
                    <SearchableSelect<any>
                      testId={"programs.programGroupPicker"}
                      label={"PROGRAM GROUP"}
                      placeholder={"None"}
                      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
                      multiple={true}
                      connection={programGroupsConnection}
                      fragment={programGroupFragment}
                      variables={{}}
                      value={field.value ? field.value : []}
                      renderName={group => group.name}
                      onChange={ids => form.setFieldValue(field.name, ids)}
                      addNewConfig={
                        me?.me?.isAdmin
                          ? (name: string) => {
                              if (name.trim()) {
                                return {
                                  text: `Create "${name}"`,
                                  onClick: async () => {
                                    const result = await newProgramGroup({
                                      variables: { name, programFilters: idIn([id]) },
                                    });

                                    return { id: result.data.upsertProgramGroup };
                                  },
                                };
                              }

                              return { text: "Enter a name above to create a new group" };
                            }
                          : undefined
                      }
                      itemNamePlural="program groups"
                    />
                  )}
                />
              )}

              <Form.TextBox
                {...input("name")}
                type={"text"}
                label={"PROGRAM NAME"}
                placeholder={'e.g. "Labor Day Sampling Program (CA)"'}
                errorMessage={
                  input("name").value.startsWith("Copy") &&
                  !programForm.values.nameChangedAt &&
                  !programNameChanged &&
                  initialValues.name === input("name").value
                    ? "Don't forget to update the name of your program!"
                    : input("name").errorMessage
                }
              />
            </Form.HorizontalGroup>
          </Form.Section>

          {orgData?.organization?.programGroupsVisible && (
            <Text font="wes" color="gray3" bold bottom="m">
              Visit{" "}
              <Link to={`/${orgId}/settings/program-groups`}>
                <Text color="blue2" inline bold font="wes">
                  Company Settings
                </Text>
              </Link>{" "}
              to create new Program Groups.
            </Text>
          )}

          <div className={styles.optionalSection}>
            <LegacyText.Label1 className={styles.sectionLabel}>OPTIONAL DETAILS FOR PROGRAM TEAM</LegacyText.Label1>

            <Form.Section className={cx(values.autoActivation && styles.autoActivationSwitchHighlight)}>
              <Form.HorizontalGroup>
                <CalendarDropdown
                  value={values.startDate ? parseISO(values.startDate) : null}
                  onChange={value => setFieldValue("startDate", value.toISOString())}
                  testId={"programStartDate"}
                  label="START DATE"
                  placeholder="Please select..."
                />

                <CalendarDropdown
                  value={values.endDate ? parseISO(values.endDate) : null}
                  onChange={value => setFieldValue("endDate", value.toISOString())}
                  testId={"programEndDate"}
                  label={<React.Fragment>END DATE &nbsp;&nbsp;</React.Fragment>}
                  placeholder="Please select..."
                />
              </Form.HorizontalGroup>
            </Form.Section>

            <div className={styles.flexContainer}>
              <RadioButton
                selected={leaveProgramDatesUnspecified}
                onChange={() => {
                  setFieldValue("startDate", null);
                  setFieldValue("endDate", null);
                  setFieldValue("autoActivation", false);
                }}
                className={styles.clearDates}
              >
                Leave program dates unspecified
              </RadioButton>
              <Switch
                id="autoActivation"
                testId="autoActivation"
                className={`${styles.autoActivationSwitchContainer} ${styles.autoActivationSwitchContainer}`}
                labelText="Automatically make this program active/inactive according to the dates above"
                labelTextClassName={`${styles.autoActivationSwitchLabel} ${styles.autoActivationSwitchLabel}`}
                checked={values.autoActivation}
                onChange={() => {
                  if (!leaveProgramDatesUnspecified) {
                    setFieldValue("autoActivation", !values.autoActivation);
                  }
                }}
              />
            </div>

            {props.programForm.executionType === "solo" ? null : (
              <Form.Section>
                <Field
                  name="stateKeys"
                  render={({ field, form }: any) => (
                    <SearchableSelect<any>
                      testId={"programs.statePicker"}
                      label={"TARGET MARKETS"}
                      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
                      multiple={true}
                      connection={statesConnection}
                      fragment={stateFragment}
                      variables={{}}
                      value={field.value ? field.value : []}
                      renderName={state => state.name}
                      onChange={ids => form.setFieldValue(field.name, ids)}
                      itemNamePlural="target markets"
                    />
                  )}
                />
              </Form.Section>
            )}

            <Form.Section>
              <Form.TextBox
                {...input("description")}
                label="PROGRAM DESCRIPTION"
                multiline={true}
                rows={4}
                placeholder={"Type here..."}
                optionalLabel={false}
              />
            </Form.Section>

            <Form.Section>
              <Form.TextBox
                {...input("locationDescription")}
                label="LOCATION DESCRIPTION"
                multiline={true}
                rows={4}
                placeholder={"Type here..."}
                optionalLabel={false}
              />
            </Form.Section>

            <Form.HorizontalSectionGroup>
              <Form.Section
                className={cx(styles.numberContainer, values.capacityLimit !== null && styles.taskCapacityLimitText)}
              >
                <LegacyText.Label2>
                  {values.capacityLimit === null ? "TARGET TASK COUNT" : "TASK CAPACITY LIMIT"}
                </LegacyText.Label2>
                <NumberBox
                  label=""
                  value={values.capacityLimit === null ? values.estimatedGigsCount : values.capacityLimit}
                  onChange={v => {
                    setValues(vs => ({
                      ...vs,
                      estimatedGigsCount: v,
                      capacityLimit: values.capacityLimit !== null ? v : null,
                    }));
                  }}
                  className={styles.numberInput}
                />
              </Form.Section>

              <Form.Section className={styles.numberContainer}>
                <LegacyText.Label2>TARGET SPEND</LegacyText.Label2>

                <DecimalBox
                  errorMessage={touched["budget"] ? (errors["budget"] as string) : undefined}
                  value={programBudget.value}
                  onChange={v => {
                    setFieldValue("budget", v);
                  }}
                  maxDigits={9}
                  className={styles.numberInput}
                />
              </Form.Section>
            </Form.HorizontalSectionGroup>
            {orgData?.organization?.programCapacityActive ? (
              <Form.HorizontalSectionGroup>
                <Form.Section transparent>
                  <Switch
                    id="capacityLimitCheckInput"
                    testId="capacityLimitCheckInput"
                    className={`${styles.capacityLimitSwitchContainer} ${styles.capacityLimitSwitchContainer}`}
                    labelText="This is a strict capacity limit"
                    labelTextClassName={`${styles.capacityLimitSwitchLabel} ${styles.capacityLimitSwitchLabel}`}
                    checked={values.capacityLimit !== null}
                    onChange={() => {
                      setFieldValue("capacityLimit", values.capacityLimit === null ? values.estimatedGigsCount : null);
                    }}
                  />

                  {values.capacityLimit !== null && (
                    <Text font="lato" weight={400} size={14} color={"gray3"} top="s" lineHeight={18}>
                      New task creation will be disabled once your Target Task Count is reached, including both Finished
                      and Pending tasks.
                    </Text>
                  )}
                </Form.Section>
                <Form.Section transparent>{null}</Form.Section>
              </Form.HorizontalSectionGroup>
            ) : null}
          </div>

          <SaveButton id={ID} />
        </AccordionPartContent>
      </AccordionPart>
    </React.Fragment>
  );
}

// See #519
// const TARGET_VALUE_LABEL = {
//   unitsSold: 'UNITS SOLD',
//   samplesDistributed: 'UNITS DISTRIBUTED',
//   customerInteractions: 'INTERACTIONS'
// };

const soloShape = {
  name: yup.string().required(),
  description: yup.string().nullable(),
  locationDescription: yup.string().nullable(),
  preferredAgenciesDescription: yup.string().nullable(),
};

const proShape = {
  name: yup.string().required(),
  description: yup.string().nullable(),
  locationDescription: yup.string().nullable(),
  preferredAgenciesDescription: yup.string().nullable(),
};

const transactionalShape = produce(soloShape, (draft: any) => {
  draft.stateKeys = yup.array().required("Please select at least one state").of(yup.string()).nullable();
});

const transactionalSchema = yup.object().shape(transactionalShape);
const soloSchema = yup.object().shape(soloShape);
const proSchema = yup.object().shape(proShape);

export default compose(
  withProgramFormContext,
  withRouter,

  withFormik({
    mapPropsToValues: props => {
      return { ...Fields, ...props.programForm.values };
    },
    validate: validateAndNotify(transactionalSchema, soloSchema, proSchema, ID),
    handleSubmit: () => {},
  }),
)(Overview);
