import React from "react";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { AccessLevel, OrgPartnerOptionsFragment } from "gql-gen";
import { useTranslation } from "react-i18next";
import partnerFragment from "modules/Dashboard/Organization/LegacySettings/PartnersPicker/partners.gql";
import Section from "components/Form/Section";
import { bin, Expression } from "utilities/knueppel";
import { connection, ConnectionConfig } from "utilities/connections";

export const partnersConnection: ConnectionConfig<OrgPartnerOptionsFragment> = connection({
  name: "OrgPartnersOptions",
  entry: { name: "organizationConnectionsPartners" },
  variables: { search: "String", programId: "ID", onlyVisible: "Boolean" },
});

export default function FPartnerPicker({
  field,
  form,
  programId,
  label,
  partialBatchUpdate,
  className,
  testId,
  disabled,
  filters,
}: FieldProps & {
  programId?: string;
  label?: string;
  partialBatchUpdate?: boolean;
  className?: string;
  testId?: string;
  disabled?: boolean;
  filters?: Expression;
}) {
  const { t } = useTranslation();

  const discardViewOnlyOrgs = bin("programaccesslevel", "!=", AccessLevel.None);
  const f = filters ? bin(filters, "&&", discardViewOnlyOrgs) : discardViewOnlyOrgs;

  return (
    <Section>
      <SearchableSelect<OrgPartnerOptionsFragment>
        label={label ?? t("gigs.fields.partner.partner")}
        multiple={false}
        connection={partnersConnection}
        fragment={partnerFragment}
        value={field.value ? [field.value] : []}
        variables={{
          programId: partialBatchUpdate ? null : programId,
          filters: f,
        }}
        errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
        renderName={({ name }) => name}
        selectFirst
        clearSearchOnSelect
        onChange={r => form.setFieldValue(field.name, r[0])}
        className={className}
        testId={testId}
        disabled={disabled}
      ></SearchableSelect>
    </Section>
  );
}
