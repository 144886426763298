import { Mutation, Query } from "@apollo/client/react/components";
import * as React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import plusIcon from "assets/plus.svg";
import Form from "components/Form";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import { inputProps } from "utilities/formik";
import { CreateProps } from "../LegacySearchable";
import SearchableMultiSelect from "../LegacySearchable/SearchableMultiSelect";
import styles from "./styles.scss";
import tagsQuery from "./tags.gql";
import upsertTag from "./upsertTag.gql";
import {
  TagsInputItemsQueryVariables,
  TagsInputItemsQuery,
  TagGroup,
  UpsertTagMutationVariables,
  UpsertTagMutation,
} from "gql-gen";

interface Props {
  value: string[];
  onChange: (v: string[] | null) => void;
  group: TagGroup;
  organizationId: string;
}

export default function TagsInput({ value, onChange, organizationId, group }: Props) {
  return (
    <Query<TagsInputItemsQuery, TagsInputItemsQueryVariables> {...makeQuery(group)}>
      {({ data: { tags } = { tags: null } }) => (
        <SearchableMultiSelect
          selectedItems={value}
          onChange={v => onChange(v)}
          createOption={{
            createText: search => (search.trim().length ? `Create "${search}"` : "Create new Tag"),
            render: p => <CreateTag {...p} organizationId={organizationId} group={group} />,
          }}
          name={"TAGS"}
          items={tags?.edges.map(t => t.node) ?? null}
          renderItem={tag => (
            <React.Fragment>
              <Text.P3>{tag.name}</Text.P3>
              <Text.P4 kind={"secondary"} className={styles.tagDescription}>
                {tag.description}
              </Text.P4>
              <div className={styles.tagColor} style={{ backgroundColor: tag.color }} />
            </React.Fragment>
          )}
          loadingOnNull
        />
      )}
    </Query>
  );
}

const makeQuery = (group: TagGroup) => ({
  query: tagsQuery,
  variables: { group: group },
});

const NEW_TAG_SCHEMA = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string(),
});

interface CreateTagsValues {
  name: string;
  description: string;
}

function CreateTag({
  search,
  done,
  organizationId,
  group,
}: {
  organizationId: string;
  group: TagGroup;
} & CreateProps) {
  return (
    <Mutation<UpsertTagMutation, UpsertTagMutationVariables>
      mutation={upsertTag}
      update={(cache, { data }) => {
        if (!data) return;

        const query = makeQuery(group);
        const tagsQuery = cache.readQuery<TagsInputItemsQuery>(query);

        if (tagsQuery && data.upsertTag) {
          cache.writeQuery<TagsInputItemsQuery, TagsInputItemsQueryVariables>({
            ...query,
            data: {
              __typename: "Query",
              tags: {
                __typename: "PaginatedTag",
                edges: tagsQuery.tags.edges.concat([
                  {
                    node: data.upsertTag,
                    __typename: "PaginatedTagEdge",
                  },
                ]),
              },
            },
          });
        }
      }}
    >
      {upsertTag => {
        const handleSave = async (values: CreateTagsValues) => {
          const newTag = await upsertTag({
            variables: {
              input: {
                name: values.name,
                description: values.description,
                groups: [group],
                organizationId,
              },
            },
          });

          if (newTag && newTag.data) done(newTag.data.upsertTag);
        };

        return (
          <Formik
            initialValues={{ name: search, description: "" }}
            validationSchema={NEW_TAG_SCHEMA}
            onSubmit={handleSave}
          >
            {props => {
              const input = inputProps(props);

              return (
                <form onSubmit={props.handleSubmit}>
                  <Form.HorizontalGroup className={styles.newTagWrapper}>
                    <Form.TextBox
                      {...input("name")}
                      autoFocus
                      placeholder={"New tag..."}
                      className={styles.newTagTitle}
                    />

                    <Form.TextBox
                      {...input("description")}
                      placeholder={"Description"}
                      className={styles.newTagDescription}
                    />

                    <div className={styles.saveTagWrapper}>
                      <button type={"submit"} aria-label={"Create Tag"} className={styles.saveTag}>
                        <Icon src={plusIcon} fill={"white"} size={10} />
                      </button>
                    </div>
                  </Form.HorizontalGroup>
                </form>
              );
            }}
          </Formik>
        );
      }}
    </Mutation>
  );
}
