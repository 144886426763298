import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "components/Text";
import Clickable from "components/Clickable";

import { useArrayState } from "hooks/useArrayState";
import plusCircleIcon from "assets/plus-circle.svg";
import plusIcon from "assets/plus.svg";
import chevronIcon from "assets/chevron.svg";
import cancelIcon from "assets/cancel.svg";
import { Source, defaultSource } from "./Source";
import styles from "./styles.scss";
import Icon from "components/Icon";
import { colors } from "styles/variables";
import { ISourceConfig } from "../sources";
import { FieldConfig } from "../fields";
import { UploaderContext } from "modules/Uploader/context";
import cx from "classnames";

type Props = {
  field: FieldConfig;
  id: string;
  sources: ISourceConfig[];
  onSourcesChange: (id: string) => (sources: ISourceConfig[]) => void;
};

export function UploaderField({ id, field, sources, onSourcesChange }: Props) {
  const { isAdmin, openSections, setOpenSections, sheetMap } = useContext(UploaderContext);
  const isOpen = openSections.has(id);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setRender(true);
      }, 100);
    } else {
      setRender(false);
    }
  }, [isOpen]);

  if (field.type === "section") {
    const sources = Object.entries(field.children).map(([cKey, cVal], index) => {
      return (
        <React.Fragment key={cKey}>
          <Text color="white" font="wes" bold size={16} className={styles.label}>
            {cVal.label} {cVal.sublabel}
          </Text>
          {cVal.helpText && (
            <Text color="white" font="lato" size={14} className={styles.helpText}>
              {cVal.helpText}
            </Text>
          )}
          {sheetMap[cKey].map((source, sourceIdx) => {
            return (
              <React.Fragment key={index + sourceIdx}>
                <SourceView
                  isAdmin={isAdmin}
                  onSourcesChange={onSourcesChange(cKey)}
                  index={sourceIdx}
                  field={cVal}
                  key={index + sourceIdx}
                  source={source}
                  sources={sheetMap[cKey]}
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    });
    return (
      <div data-test={`importer.field.${id}`} className={styles.importerSectionField}>
        <button
          className={styles.importerFieldHeader}
          onClick={() => {
            setOpenSections(sections => (sections.delete(id) ? new Set(sections) : new Set(sections.add(id))));
          }}
        >
          <Text
            color="white"
            letterSpacing={1}
            font="wes"
            bold
            size={20}
            transform={"uppercase"}
            className={styles.headerLabel}
          >
            {field.label}
          </Text>
          <Icon src={chevronIcon} fill={colors.white} width={20} rotate={isOpen ? 180 : 0} />
        </button>
        {field.helpText && (
          <Text color="white" font="lato" size={14} className={styles.helpText}>
            {field.helpText}
          </Text>
        )}

        {render && <div className={styles.sources}>{sources}</div>}
      </div>
    );
  }

  return (
    <div data-test={`importer.field.${id}`} className={styles.importerField}>
      <Text color="white" font="wes" bold size={16} className={styles.label}>
        {field.label} {field.sublabel}
      </Text>
      {field.helpText && (
        <Text color="white" letterSpacing={1} font="lato" size={12} className={styles.helpText}>
          {field.helpText}
        </Text>
      )}

      <div className={cx(styles.sources, styles.showSources)}>
        {sources.map((source, index) => (
          <SourceView
            isAdmin={isAdmin}
            onSourcesChange={onSourcesChange(id)}
            index={index}
            field={field}
            key={index}
            source={source}
            sources={sources}
          />
        ))}
      </div>
    </div>
  );
}

interface SourceViewProps {
  index: number;
  onSourcesChange: (sources: ISourceConfig[]) => void;
  field: FieldConfig;
  sources: ISourceConfig[];
  isAdmin: boolean;
  source: ISourceConfig;
}

function SourceView({ source, index, sources, field, onSourcesChange, isAdmin }: SourceViewProps) {
  const { push, setIndex, removeIndex } = useArrayState(sources, onSourcesChange);
  const { t } = useTranslation();
  return (
    <div key={index} className={styles.sourceWrapper}>
      <Source
        value={source}
        addedToPrevious={sources[index - 1] && sources[index - 1].join === "+"}
        onChange={value => setIndex(index, value)}
        field={field}
      />

      <Clickable className={styles.addSource} onClick={() => push(defaultSource)}>
        <Icon src={plusCircleIcon} size={12} fill={colors.blue2} />
        <Text color="blue2" size={12} left="xs" underline="hover" noSelect>
          {t(`importer.field.${isAdmin ? "admin" : "user"}.addSource`)}
        </Text>
      </Clickable>

      {(isAdmin || index > 0) && (
        <Clickable className={styles.removeSource} onClick={() => removeIndex(index)}>
          <Icon src={cancelIcon} size={14} fill={"white"} />
        </Clickable>
      )}
      {sources.length !== index + 1 && (
        <div className={styles.joinBtn}>
          <div className={source.join === "+" ? styles.joinOptionsPlus : styles.joinOptionsOr}>
            <div onClick={() => setIndex(index, { ...source, join: "+" })}>
              <Icon src={plusIcon} size={14} fill={colors.gray1} />
            </div>
            <div onClick={() => setIndex(index, { ...source, join: "||" })}>
              <Text color="gray1" noSelect size={10}>
                {t("importer.field.or")}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
