export class StorageScope<T> {
  public key: string;
  private _value?: T;

  constructor(key: string) {
    this.key = key;
  }

  public subScope<ST>(subKey: string): StorageScope<ST> {
    return new StorageScope(`${this.key}:${subKey}`);
  }

  public get value(): T | undefined {
    if (this._value) {
      return this._value;
    }

    const raw = localStorage.getItem(this.key);

    if (raw) {
      return (this._value = JSON.parse(raw));
    }

    return undefined;
  }

  public set value(newVal: T | undefined) {
    this._value = newVal;
    localStorage.setItem(this.key, JSON.stringify(newVal));
  }

  public clear() {
    this.value = undefined;
    localStorage.removeItem(this.key);
  }
}
