import React from "react";
import { DocumentNode } from "graphql";
import { useScopeContext } from "../Scope";
import { useApolloClient } from "@apollo/client";
import { buildQuery } from "houbolt";
import { PEnv, ConnectionResult } from "utilities/connections";
import { ImpatientPopupProps, ImpatientPopup } from "components/ImpatientPopup";
import { bin } from "utilities/knueppel";
import styles from "./styles.scss";

interface Props<T> extends Omit<ImpatientPopupProps<T>, "fetch"> {
  id: string;
  fragment: DocumentNode;
}

export function ImpatientFragmentPopup<T>({ fragment, id, ...props }: Props<T>) {
  const { connection, variables } = useScopeContext();
  const client = useApolloClient();
  const entry = (connection && connection.entry.name) || "";

  const fetch = async () => {
    const query = buildQuery<PEnv, any>({ ...connection, fragments: [fragment] });

    const queryResult = await client.query<{ [x in typeof entry]: ConnectionResult<T> }>({
      query,
      variables: {
        ...variables,
        filters: bin("id", "=", id),
        first: 1,
        skipPageInfo: true,
      },
    });

    return queryResult && queryResult.data ? queryResult.data[connection.entry.name].edges[0].node : null;
  };

  return <ImpatientPopup containerClassName={styles.impatientPopup} {...props} fetch={fetch} dependencies={[id]} />;
}
