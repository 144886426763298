import { TemperatureUnit } from "../../generated/ts/graphql";

const K_START = 273.15;

export function toKelvin(value: number, unit: TemperatureUnit): number {
  switch (unit) {
    case TemperatureUnit.Kelvin:
      return value;
    case TemperatureUnit.Celsius:
      return value + K_START;
    case TemperatureUnit.Fahrenheit:
      return toKelvin(toCelsius(value, unit), TemperatureUnit.Celsius);
  }
}

export function toCelsius(value: number, unit: TemperatureUnit): number {
  switch (unit) {
    case TemperatureUnit.Celsius:
      return value;
    case TemperatureUnit.Kelvin:
      return value - K_START;
    case TemperatureUnit.Fahrenheit:
      return ((value - 32) * 5) / 9;
  }
}

export function toFahrenheit(value: number, unit: TemperatureUnit): number {
  switch (unit) {
    case TemperatureUnit.Fahrenheit:
      return value;
    case TemperatureUnit.Kelvin:
      return toFahrenheit(toCelsius(value, unit), TemperatureUnit.Celsius);
    case TemperatureUnit.Celsius:
      return (value * 9) / 5 + 32;
  }
}
