import React from "react";
import { LogicalOperator } from "utilities/knueppel";
import TabbedContent from "components/TabbedContent";
import Button from "components/Button";
import { FilterDefinition } from "../types";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";

export function createParentFilter<
  FF extends FilterDefinition<any> | undefined,
  FD extends { [key: string]: FilterDefinition<any> },
  K extends keyof FD,
  F extends { type: K } & ReturnType<FD[K]["initialState"]> &
    ReturnType<FF extends FilterDefinition<any> ? FF["initialState"] : never>
>({
  filters,
  footerFilter,
  order,
  i18nBase,
  footerOperator,
}: {
  filters: FD;
  footerFilter?: FF;
  order: K[];
  i18nBase: string;
  footerOperator?: LogicalOperator;
}): FilterDefinition<F> {
  return {
    initialState: () => ({
      ...(order[0] && filters[order[0]].initialState()),
      ...(footerFilter && footerFilter.initialState()),
    }),

    component: filterProps => {
      const { t } = useTranslation();
      const { state, setState, apply, mode, clear, close } = filterProps;

      return (
        <TabbedContent<K>
          activeTab={state && state.type}
          onSelect={type => {
            setState({
              type,
              ...filters[type].initialState(),
            } as F);
          }}
          tabProps={filterProps}
          tabsConfig={order.map(key => ({
            key,
            label: t(`${i18nBase}.${key}.name`),
            component: filters[key].component,
          }))}
          mode={mode}
          onClear={clear}
          onClose={close}
        >
          <div className={styles.footer}>
            {footerFilter ? React.createElement(footerFilter.component, filterProps) : <div />}
            <Button kind={"primaryBold"} onClick={apply} className={styles.action} testId={"filters.applyButton"}>
              Apply
            </Button>
          </div>
        </TabbedContent>
      );
    },

    validate: state => {
      const filter = filters[state.type];

      return filter && filter.validate ? filter.validate(state) : true;
    },

    toAST: state => {
      let ast = filters[state.type].toAST(state);

      if (footerFilter) {
        const ffAST = footerFilter.toAST(state);

        if (ffAST) {
          if (!ast) return ffAST;

          return {
            type: "LogicalExpression",
            left: ast,
            operator: footerOperator || "&&",
            right: ffAST,
          };
        }
      }

      return ast;
    },

    stateComponent: ({ state, setState, clear }) => {
      const label = React.createElement(filters[state.type].stateComponent, { key: "main", state, setState, clear });

      return footerFilter ? (
        <>{[label, React.createElement(footerFilter.stateComponent, { key: "footer", state, setState, clear })]}</>
      ) : (
        label
      );
    },

    serialize: state => {
      const filter = filters[state.type];

      return filter && filter.serialize ? filter.serialize(state) : state;
    },

    parse: state => {
      const filter = "type" in state && filters[(state as F).type];

      return filter && filter.parse ? filter.parse(state) : state;
    },
  };
}
