import * as React from "react";
import ReactModal from "react-modal";
import cx from "classnames";
import styles from "./styles.scss";
import useRouter from "use-react-router";
import { getUrlQueries } from "utilities/routes";

interface Props {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  onRequestClose?: () => void;
  isOpen?: boolean;
}

export default function LegacyModal({ children, className, isOpen = true, onRequestClose, noPadding }: Props) {
  const { location } = useRouter();
  const { fullScreen } = getUrlQueries(location.search);
  return (
    <ReactModal
      isOpen={isOpen}
      className={cx(styles.content, noPadding && styles.noPadding, className)}
      overlayClassName={cx(styles.overlay, fullScreen && styles.transparent)}
      onRequestClose={onRequestClose}
      appElement={getRoot()}
    >
      {children}
    </ReactModal>
  );
}

let root: HTMLElement | undefined;

function getRoot() {
  if (!root) root = document.getElementById("root") || undefined;

  return root;
}
