import * as React from "react";
import Text from "components/LegacyText";
import Pill from "components/Pill";
import styles from "./styles.scss";

interface Props {
  selected: { label: string; id: string }[];
  renderCounter?: (count: number) => string;
  renderItemToggle?: (id: string) => React.ReactElement<any>;
  onRemove?: ((id: string, label: string) => () => void) | null;
  renderToggleAll?: React.ReactElement<any>;
}

export default function SelectedItemsList({
  selected,
  renderCounter = RENDER_COUNTER_DEFAULT,
  onRemove,
  renderItemToggle,
  renderToggleAll,
}: Props) {
  if (selected.length < 1) return <div />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text.H3 className={styles.counter} kind={"reverse"}>
          {renderCounter(selected.length)}
        </Text.H3>
        {!!renderToggleAll && renderToggleAll}
      </div>
      <div className={styles.pills}>
        {selected.map(({ label, id }) => (
          <Pill
            key={id}
            onRemove={onRemove ? onRemove(id, label) : undefined}
            itemToggleComponent={renderItemToggle ? renderItemToggle(id) : null}
            className={styles.pills}
          >
            {label}
          </Pill>
        ))}
      </div>
    </div>
  );
}

const RENDER_COUNTER_DEFAULT = (count: number) => (count === 1 ? `One item selected` : `${count} items selected`);
