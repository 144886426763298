import React, { ReactNode, useState, useRef } from "react";
import cx from "classnames";
import Icon from "components/Icon";
import LegacyModal from "components/LegacyModal";
import Text from "components/Text";
import closeIcon from "assets/cancel-nobg.svg";
import styles from "./styles.scss";
import Button from "components/Button";
import { Scope, useScopeContext, useActions, SrcScope } from "../Scope";
import { Item } from "../types";
import { ConnectionConfig } from "utilities/connections";
import { Route, Switch, Redirect } from "react-router";
import useRouter from "use-react-router";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Shortcuts, { shortcut } from "components/Shortcuts";
import { ActionTitle } from "../ActionTitle";
import Clickable from "components/Clickable";
import { Mobile } from "components/Layout/Responsive";
import { ScopeErrors } from "../ScopeErrors";

import NoActions from "modules/Dashboard/Schema/Gigs/Actions/NoActions";
import LoadingContainer from "components/LoadingContainer";

interface DetailsModalProps<CC extends ConnectionConfig<Item>> extends SrcScope<CC> {
  children: ReactNode;
  ids: string[];
  onClose: () => void;
  loading: boolean;
}

export function DetailsModal<CC extends ConnectionConfig<Item>>({
  ids,
  children,
  onClose,
  loading,
  ...srcScope
}: DetailsModalProps<CC>) {
  // TODO remove task from here on new modal
  return (
    <Mobile>
      {isMobile => {
        return (
          <Shortcuts shortcuts={[shortcut("esc", onClose)]}>
            <LegacyModal noPadding className={styles.modal}>
              <Clickable className={styles.close} onClick={onClose}>
                <Icon src={closeIcon} fill={"white"} size={15} />
              </Clickable>
              {/* <Scope {...srcScope} ids={ids} ready={true}>
                <div className={cx(styles.leftSide, isMobile && styles.mobileLeftSide)}>{children}</div>

                {srcScope.Actions && <ActionSide onClose={onClose} />}
              </Scope> */}
              <div style={{ display: "flex", flexDirection: isMobile ? "column-reverse" : "row" }}>
                <Scope {...srcScope} ids={ids} ready={true}>
                  <div className={cx(styles.leftSide, isMobile && styles.mobileLeftSide)}>{children}</div>
                  {srcScope.actions && <ActionSide onClose={onClose} loading={loading} />}
                </Scope>
              </div>
            </LegacyModal>
          </Shortcuts>
        );
      }}
    </Mobile>
  );
}

function ActionSide({ onClose, loading }: Pick<DetailsModalProps<any>, "onClose" | "loading">) {
  const [hovering, setHovering] = useState<number | null>(null);

  const { connection, variables, reload } = useScopeContext();
  const { match, history, location } = useRouter();
  const { t } = useTranslation();
  const actions = useActions();

  const handleContinue = () => {
    // FIXME: Somehow (maybe using the after paramater?) get the id of the next task matching
    // Filters and redirect there. We can use the filter collection context to get the AST.
  };

  const nextAction = useRef<"continue" | "close" | "noOp" | null>("noOp");

  //const [active, setActive] = useState<AvailableAction | null>(null);

  const renderNavButtons = () => (
    <>
      <ScopeErrors className={styles.errors} />
      <div className={styles.navButtons}>
        <Button kind={"primaryGradient"} className={styles.closeBtn} onClick={onClose}>
          {t("components.detailsModal.close")}
        </Button>

        {/* TODO MAKE VISIBLE AGAIN <Button kind={"translucent"} className={styles.continueBtn} onClick={handleContinue}>
        {t("components.detailsModal.continue")}
      </Button> */}
      </div>
    </>
  );

  return (
    <Mobile>
      {isMobile => {
        return (
          <div className={styles.rightSide}>
            {loading || actions.state === "pending" ? (
              <LoadingContainer className={styles.actionsLoading} color="white" />
            ) : (
              <>
                <header className={cx(styles.actions, (styles as any)[`h${hovering}`])}>
                  {actions.state === "resolved" &&
                    actions.result.actions.map(({ action }, index) => (
                      <NavLink
                        to={{ ...location, pathname: `${match.url}/${action.key}` }}
                        key={action.key}
                        className={styles.action}
                        title={t(action.labelKey)}
                        activeStyle={{ backgroundColor: action.color }}
                        onMouseEnter={() => setHovering(index)}
                      >
                        <Icon src={action.icon} fill={"white"} size={30} />
                      </NavLink>
                    ))}
                </header>

                {actions.state === "resolved" && actions?.result?.actions.length === 0 && (
                  <>
                    <NoActions inPopup={false} />
                    {renderNavButtons()}
                  </>
                )}
                {actions.state === "resolved" && actions?.result?.actions.length > 0 ? (
                  <Switch>
                    {actions.result.actions.map(({ action, count }) => (
                      <Route
                        key={action.key}
                        path={`${match.path}/${action.key}`}
                        render={() => (
                          <div className={styles.actionBody}>
                            <ActionTitle>{t(action.labelKey)}</ActionTitle>

                            {React.createElement(action.component, {
                              isBatchAction: false,
                              count,
                              done: () => {
                                reload();

                                switch (nextAction.current) {
                                  case "continue":
                                    return handleContinue();

                                  case "close":
                                    return onClose();

                                  case "noOp":
                                    return;

                                  default:
                                    history.replace({ ...location, pathname: `${match.url}/options` });
                                    nextAction.current = null;
                                }
                              },
                              connection,
                              variables,

                              renderSubmit: props => {
                                const { i18n, handleSubmit, disabled, isSubmitting } = props;
                                return (
                                  <>
                                    <ScopeErrors className={styles.errors} />
                                    {action.key === "reportVerification" && (
                                      <Text color={"white"} size={14}>
                                        {t(`${i18n}.goToBilling`)}
                                      </Text>
                                    )}
                                    <div className={styles.navButtons}>
                                      {action.key !== "selectPartner" ? (
                                        <Button
                                          kind={"primaryGradient"}
                                          className={styles.closeBtn}
                                          onClick={() => {
                                            nextAction.current = "noOp";
                                            handleSubmit();
                                          }}
                                          type="button"
                                          disabled={disabled || isSubmitting}
                                        >
                                          {t(`${i18n}.${isSubmitting ? "saving" : "submit"}`)}
                                        </Button>
                                      ) : (
                                        <Button
                                          kind={"greenGradient"}
                                          className={styles.closeBtn}
                                          onClick={() => {
                                            nextAction.current = "continue";
                                            handleSubmit();
                                            history.replace({ ...location, pathname: `${match.url}/assignment` });
                                          }}
                                          type="button"
                                          disabled={disabled || isSubmitting}
                                        >
                                          {t(`${i18n}.submitAndContinue`)}
                                        </Button>
                                      )}
                                    </div>
                                  </>
                                );
                              },
                              renderNavButtons,
                            })}
                          </div>
                        )}
                      />
                    ))}
                    <Route
                      path={`${match.path}/options`}
                      render={() => (
                        <div className={styles.actionPlaceholder}>
                          <div className={styles.actionList}>
                            <Text font="wes" color="white" size={12} bottom="s">
                              {t("components.detailsModal.availableActions")}
                            </Text>
                            {actions.result.actions.map(({ action }) => (
                              <Link
                                to={{ ...location, pathname: `${match.url}/${action.key}` }}
                                key={action.key}
                                className={styles.actionItem}
                                title={t(action.labelKey)}
                              >
                                <Icon src={action.icon} fill={"white"} size={18} />
                                <Text left={"xs"} color="blue2" size={16} underline="hover">
                                  {t(action.labelKey)}
                                </Text>
                              </Link>
                            ))}
                          </div>
                          {renderNavButtons()}
                        </div>
                      )}
                    />

                    <Redirect
                      to={{
                        ...location,
                        pathname: `${match.url}/${
                          actions.result.primary ? actions.result.primary.action.key : "options"
                        }`,
                      }}
                    />
                  </Switch>
                ) : isMobile ? null : (
                  <div style={{ height: 100 }} />
                )}

                {/*

        FIXME: We need backend support for this. Getting total count is easy but
        determining the index of an edge is not possible at the moment.
      <div className={styles.count}>
        <TextGroup color="white" font="wesR" size={14}>
          This is{" "}
          <TextNode font="wes" bold>
            1 of 42
          </TextNode>{" "}
          tasks in your search
        </TextGroup>
      </div> */}
              </>
            )}
          </div>
        );
      }}
    </Mobile>
  );
}
