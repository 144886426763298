import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { MutationFunction } from "@apollo/client";
import * as React from "react";
import { withFormik, FormikProps, FormikErrors } from "formik";
import moment from "moment";
import { RouteComponentProps } from "react-router";

import Form from "components/Form";
import LoadingContainer from "components/LoadingContainer";
import LegacyModal from "components/LegacyModal";
import Text from "components/LegacyText";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import { CloseModalHandler } from "utilities/modals";
import { ProgramExecutionType } from "interfaces/Program";
import SearchableSelect from "components/LegacySearchable/SearchableSelect";
import { UserInfoQuery, withUserInfo } from "modules/Dashboard/UserInfo";

import styles from "./styles.scss";

import createProgramRequestMutation from "./createProgramRequest.gql";
import programsQuery from "./programsQuery.gql";
import duplicatePrograms from "./duplicateProgram.gql";

import { ProgramsRouteParams } from "../index";
import { programsRouteName } from "../";

interface Program {
  id: string;
  name: string;
  executionType: ProgramExecutionType;
}

interface ProgramsQuery {
  programs: Program[];
}

interface Values {
  startingPoint: "fromScratch" | "copyFromExisting" | null;
  programId: string | null;
  programType: "internal" | "external" | null;
}

interface State {
  program: Program | null;
}

interface Props extends FormikProps<Values>, ProgramsQuery, UserInfoQuery, RouteComponentProps<ProgramsRouteParams> {
  loading: boolean;
  handleClose: CloseModalHandler;
  mutate: MutationFunction<{ upsertProgramRequest: { id: string } }, unknown>;
  duplicatePrograms: MutationFunction<{ duplicateProgram: string }, unknown>;
}

class CreateProgramRequestModal extends React.Component<Props, State> {
  readonly state: State = { program: null };
  public handleProgramChanged = (program: Program) => {
    this.setState({ program });
    this.props.setFieldValue("programId", program.id);
  };

  public actionDisabled(values: Values) {
    if (values.startingPoint !== null) {
      if (values.startingPoint === "copyFromExisting") {
        return values.programId === null;
      } else {
        return values.programType === null;
      }
    }
    return true;
  }

  public render() {
    const {
      handleClose,
      handleSubmit,
      isSubmitting,
      values,
      setFieldValue,
      loading,
      programs,
      userInfo: { me },
    } = this.props;

    if (loading) {
      return <LoadingContainer message={"We are loading your programs"} />;
    }

    const hc = () => handleClose();

    return (
      <LegacyModal noPadding className={styles.modal}>
        <ModalHeader mainAction="Create a program" onClose={hc} />

        <div className={styles.content}>
          <Text.P3 className={styles.copy}>How do you want to start?</Text.P3>
          <RadioGroup
            value={values.startingPoint}
            onChange={v => {
              setFieldValue("startingPoint", v);
            }}
          >
            <RadioButton value={"fromScratch"}>Start from scratch</RadioButton>
            <RadioButton value={"copyFromExisting"}>Start from a copy of an existing program</RadioButton>
          </RadioGroup>

          {values.startingPoint === "fromScratch" && !(me?.organizationUser?.organizationType === "agency") && (
            <>
              <div className={styles.programSelect}>
                <Text.P3 className={styles.copy}>Who will execute tasks in this Program?</Text.P3>
                <RadioGroup value={values.programType} onChange={v => setFieldValue("programType", v)}>
                  <RadioButton value={"internal"}>My internal team members</RadioButton>
                  <RadioButton value={"external"}>
                    Talent provided by external Partner(s), such as talent agencies
                  </RadioButton>
                </RadioGroup>
              </div>
            </>
          )}

          {values.startingPoint === "copyFromExisting" && (
            <Form.Section className={styles.programSelect}>
              <SearchableSelect
                direction="up"
                name={null}
                selectedItem={this.state.program ? this.state.program.id : null}
                compact
                onChange={this.handleProgramChanged}
                placeholder={`Type a Program name`}
                items={programs ?? []}
              />
            </Form.Section>
          )}
        </div>
        <ModalFooter
          actionName={"CREATE PROGRAM"}
          actionButtonType={"submit"}
          actionDisabled={this.actionDisabled(values) || isSubmitting}
          onAction={() => {
            handleSubmit();
          }}
          onCancel={this.props.history.goBack}
        />
      </LegacyModal>
    );
  }
}

export default compose(
  graphql(duplicatePrograms, {
    props: ({ mutate }) => ({ duplicatePrograms: mutate }),
  }),
  graphql(createProgramRequestMutation),
  graphql<Props, ProgramsQuery, { orgId: string }, unknown>(programsQuery, {
    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
        ownedByMyOrg: true,
      },
      fetchPolicy: "network-only",
    }),

    props: ({ data: { programs } = { programs: undefined } }) => ({ programs }),
  }),

  withUserInfo,

  withFormik<Props, Values>({
    mapPropsToValues: props => ({
      programType: props.userInfo?.me?.organizationUser?.organizationType === "agency" ? "external" : null,
      startingPoint: null,
      programId: null,
    }),
    enableReinitialize: true,

    validate: values => {
      const errors: FormikErrors<Values> = {};

      if (values.startingPoint === null) {
        errors.startingPoint = "Please select a starting point for your new program request.";
      }

      if (values.startingPoint === "fromScratch" && values.programType === null) {
        errors.programType = "Please select the type of program you want to create.";
      }

      return errors;
    },

    handleSubmit: async (values, formikProps) => {
      const {
        mutate,
        duplicatePrograms,
        match: {
          params: { orgId },
        },
        history,
      } = formikProps.props;

      if (values.startingPoint === "fromScratch") {
        const result = await mutate({
          variables: {
            input: {
              executionType: values.programType === "internal" ? "solo" : "pro",
              organizationId: orgId,
              name: `Unnamed Program ${moment().format("YYYY-MM-DD h:mma")}`,
              isApprovalRequired: false,
            },
          },
        });

        if (!result || !result.data) return;

        const {
          upsertProgramRequest: { id },
        } = result.data;

        if (id) {
          history.push({
            pathname: `/${orgId}/program-group/all/${programsRouteName}/request`,
            search: `?program=${id}`,
          });
        } else {
          formikProps.setFieldError("type", "An error occurred while attempting to create your program.");
        }
      } else if (values.startingPoint === "copyFromExisting") {
        if (values.programId) {
          const result = await duplicatePrograms({
            variables: { programId: values.programId },
          });

          if (result && result.data && result.data.duplicateProgram) {
            history.push({
              pathname: `/${orgId}/program-group/all/${programsRouteName}/request`,
              search: `?program=${result.data.duplicateProgram}`,
            });
          } else {
            formikProps.setFieldError("type", "An error occurred while attempting to create your program.");
          }
        }
      }
    },
  }),
)(CreateProgramRequestModal);
