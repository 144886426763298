import React from "react";
import verifyIcon from "assets/verify.svg";
import { colors } from "styles/variables";
import { useScopeContext } from "modules/Connection/Scope";
import { IAction } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { Elm } from "components/Elm";
import OwnerVerifyElm from "./OwnerVerify.elm";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = `gigs.actions.verify`;

export const OwnerVerify: IAction = {
  key: "reportOwnerVerification",
  icon: verifyIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowBatch: false,
  allowInline: false,

  component: ({ done }) => {
    const { reload } = useScopeContext();
    const {
      match: {
        url,
        params: { orgId, gigId },
      },
      history,
      location,
    } = useRouter<ProgramsRouteParams & { gigId?: string }>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Elm
          key={"active"}
          src={OwnerVerifyElm.Elm.OwnerVerify}
          flags={{
            session: {
              serverUrl: getServerRootUrl() + "/graphql",
              haskellBackendBaseUrl: getHaskellBackendUrl(),
              rustBackendBaseUrl: getRustBackendUrl(),
              authToken: getJwt(),
            },
            gigId: gigId,
            organizationId: orgId,
            programIds: id === "all" ? [] : id.split(","),
          }}
          ports={ports => {
            ports.reloadGigsAndClose.subscribe(gigId => {
              reload();
            });
            ports.showUserModal.subscribe(userId => {
              history.push({ ...location, pathname: `${url}/+user-profile/${userId}` });
            });
            ports.showInvoice.subscribe(invoiceId => {
              history.push(`/${orgId}/invoices/${invoiceId}`);
            });
          }}
        />
      </ActionBody>
    );
  },
};
