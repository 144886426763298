import * as React from "react";
import cx from "classnames";
import ReactGeosuggest from "react-geosuggest";
import { getGoogle } from "utilities/google";

interface State {
  loaded: boolean;
}

export default class GeoSuggest extends React.Component<any, { loaded: boolean }> {
  readonly state: State = {
    loaded: !!(window as any).google,
  };

  private amIMounted: boolean = false;

  public async componentDidMount() {
    this.amIMounted = true;

    await getGoogle();

    if (this.amIMounted) {
      this.setState({ loaded: true });
    }
  }

  public componentWillUnmount() {
    this.amIMounted = false;
  }

  public render() {
    if (this.state.loaded) {
      return <ReactGeosuggest {...this.props} />;
    }

    return (
      <div className={cx("geosuggest", this.props.className)}>
        <div className="geosuggest__input-wrapper">
          <input placeholder={"Loading..."} className={this.props.inputClassName} />
        </div>
        <div className="geosuggest__suggests-wrapper" />
      </div>
    );
  }
}
