import React, { ReactNode, useState, useEffect, createContext, useContext } from "react";
import cx from "classnames";
import { IBlock, Item, FormProvider } from "modules/Connection/types";
import { ControlledPopup, PopupBodyProps } from "components/Popup";
import Icon from "components/Icon";
import Button from "components/Button";
import { TableMode } from "modules/Connection/Table";
import { Form } from "modules/Connection/Form";
import editIcon from "assets/edit.svg";
import closeIcon from "assets/cancel-nobg.svg";
import styles from "./styles.scss";
import { useTranslation } from "react-i18next";
import { useScopeContext, isFormProvider } from "../Scope";
import { Working } from "components/Animation/Working";
import { ComposableField } from "../ComposableForm";
import { ScopeErrors } from "../ScopeErrors";
import useRouter from "use-react-router";
import { addQueries } from "utilities/routes";

interface Props<T> extends IBlock<T> {
  id: string;
  node: T | null;
  index?: number;
  mode: TableMode;
  em?: boolean;
  className?: string;
  children?: ReactNode;
  collapse?: () => void;
  formClassName?: string;
  onCellClick?: (id: string) => void;
  isEditable?: boolean | ((node: T) => boolean);
  forceScroll?: boolean;
}

export function Block<T extends Item>({
  id,
  cell,
  index,
  mode,
  form,
  node,
  em,
  className,
  formClassName,
  collapse,
  children,
  onCellClick,
  isEditable = true,
  forceScroll,
}: Props<T>) {
  const ctx = useScopeContext();

  const handleStartEdit = () => {
    ctx.setEditing(id);
  };

  const handleEndEdit = () => {
    ctx.setFormMeta({});
    ctx.setEditing(null);
  };

  const editing = ctx.editing === id;

  const cellView = React.createElement(cell.component, {
    data: node,
    cell,
    mode,
    em,
    collapse,
    index,
    handleOpenForm: handleStartEdit,
  });

  let [forms, setForms] = useState<ComposableField<any, any>[]>([]);

  let formProviderElement = null;

  if (form && isFormProvider(form)) {
    formProviderElement = React.createElement(() => {
      const newForms = (form as FormProvider<any>).provide({
        data: node,
      });

      useEffect(() => {
        if (newForms.length !== forms.length || newForms.find((f, i) => f !== forms[i])) {
          setForms(newForms);
        }
      });

      return null;
    });
  } else if (form) {
    forms = Array.isArray(form) ? form : [form];
  }

  const [saving, setSaving] = useState(false);
  const [canSave, setCanSave] = useState(true);

  const editable = typeof isEditable === "boolean" ? isEditable : node ? isEditable(node) : true;

  const editPopup = forms.length > 0 && mode !== "compact" && editable && (
    <ControlledPopup
      forceScroll={Boolean(forceScroll)}
      open={editing || false}
      body={BlockForm}
      childrenWrapperProps={{
        className: styles.action,
        tabIndex: undefined,
        testId: "connections.block.edit",
      }}
      onOpen={handleStartEdit}
      onClose={handleEndEdit}
    >
      <Icon src={editing ? closeIcon : editIcon} fill={"white"} size={20} />
    </ControlledPopup>
  );

  return (
    <Working
      active={saving}
      onClick={() => onCellClick && onCellClick(node?.id ?? "")}
      className={cx(
        styles.block,
        cell.transparent && styles.transparent,
        styles[mode],
        editing && styles.editing,
        className,
      )}
      data-test="connections.block"
    >
      {cellView}
      {children}

      {formProviderElement}

      {editing ? (
        <BlockFormContext.Provider
          value={{ forms, saving, setSaving, nodeId: node?.id, formClassName, setCanSave, canSave }}
        >
          {editPopup}
        </BlockFormContext.Provider>
      ) : (
        editPopup
      )}
    </Working>
  );
}

export const BlockFormContext = createContext<{
  forms: ComposableField<any, any>[];
  saving: boolean;
  setSaving: (saving: boolean) => void;
  setCanSave: (canSave: boolean) => void;
  canSave: boolean;
  nodeId: string | undefined;
  formClassName?: string;
}>({
  forms: [],
  saving: false,
  setSaving: () => { },
  nodeId: undefined,
  setCanSave: () => { },
  canSave: true,
});

function BlockForm({ close, forceScroll }: PopupBodyProps) {
  const { forms, saving, setSaving, nodeId, formClassName, canSave, setCanSave } = useContext(BlockFormContext);
  const { t } = useTranslation();
  const { history, location } = useRouter();

  const maxWidth = Math.max(...forms.map(f => f.inlineMaxWidth || 0));

  return (
    <div
      className={cx(styles.formContainer, formClassName)}
      style={{ maxWidth: maxWidth || undefined }}
      data-popup-form
    >
      <Form
        forceScroll={forceScroll}
        form={forms}
        renderSubmit={fp => {
          return (
            <>
              <ScopeErrors />
              {canSave ? (
                <div className={styles.submit}>
                  <Button
                    kind={"primary"}
                    type={"submit"}
                    disabled={saving}
                    className={styles.submitBtn}
                    onClick={() => fp.handleSubmit()}
                  >
                    {t(saving ? "components.tableForm.saving" : "components.tableForm.save")}
                  </Button>
                </div>
              ) : null}
            </>
          );
        }}
        close={() => {
          setCanSave(true);
          close();
        }}
        onSaveStart={() => {
          setSaving(true);
        }}
        onSaveEnd={() => {
          setSaving(false);

          if (nodeId) {
            history.push({ ...location, search: addQueries(location.search, { goto: nodeId }) });
          }
        }}
      />
    </div>
  );
}
