import React from "react";
import { Field } from "formik";
import { GigAgencyGuidanceFieldFragment, UpdateGigsInput, ProgramExecutionTypeFragment } from "gql-gen";
import { ComposableField } from "modules/Connection/ComposableForm";
import { FTextBox } from "components/Form/TextBox";
import { GigProgramValue } from "../Program";
import agencyGuidanceFragment from "./agencyGuidance.gql";
import { useTranslation } from "react-i18next";
import { LeaveBlank } from "../LeaveBlank";
import Section from "components/Form/Section";
import { useProgram } from "modules/Dashboard/Schema/Gigs/Fields/useProgram";

import executionTypeFragment from "../programExecutionType.gql";
const execTypeFragments = [executionTypeFragment];

type Values = Pick<UpdateGigsInput, "agencyGuidance">;

export const GigAgencyGuidanceField: ComposableField<
  GigAgencyGuidanceFieldFragment,
  Values,
  Values,
  GigProgramValue
> = {
  id: "agencyGuidance",

  component: ({ values: { programId }, inline, partialBatchUpdate }) => {
    const { t } = useTranslation();
    const program = useProgram<ProgramExecutionTypeFragment>(programId, execTypeFragments);

    if ((!program || program.executionType === "solo") && !partialBatchUpdate) return null;

    return (
      <>
        <Section>
          <Field
            name="agencyGuidance"
            component={FTextBox}
            label={t("gigs.fields.agencyGuidance.agencyGuidance")}
            testId={"gigs.fields.agencyGuidance"}
            placeholder={t("gigs.fields.agencyGuidance.placeholder")}
            multiline
            rows={5}
          />
        </Section>

        <Field name="agencyGuidance" component={LeaveBlank} white={inline} clearValue={null} />
      </>
    );
  },

  initialize: data => {
    return {
      agencyGuidance: data?.agencyGuidance ?? "",
    };
  },

  fragment: agencyGuidanceFragment,
};
