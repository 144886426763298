import * as React from "react";
import cx from "classnames";
import Text from "components/LegacyText";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
  transparent?: boolean;
  description?: React.ReactNode | null;
  noMargin?: boolean;
}

export default function Section({ children, className, header, transparent, description, noMargin }: Props) {
  return (
    <section className={className}>
      {!!header && <Text.H2>{header}</Text.H2>}
      {!!description && <Text.P3 className={styles.description}>{description}</Text.P3>}
      <div
        className={cx(
          styles.base,
          !transparent && styles.section,
          header ? styles["spacing-top"] : null,
          noMargin && styles.noMargin,
        )}
      >
        {children}
      </div>
    </section>
  );
}
