import React from "react";
import useReactRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { CancellationDetailsFragment } from "gql-gen";
import { TextGroup, TextNode } from "components/Text";
import { ImpatientContentPopupProps } from "components/ImpatientPopup";

import styles from "./styles.scss";

export function CancellationDetails({
  data: { cancellationReason, cancellationReasonDescription, cancelledAt, cancelledBy },
}: ImpatientContentPopupProps<CancellationDetailsFragment>) {
  const { location: routeLocation } = useReactRouter();
  const { t } = useTranslation();

  return (
    <div className={styles.cancelDetails}>
      <TextGroup color="white" size={14}>
        <TextNode font={"wes"} bold bottom="xs">
          {t("gigs.workflowStates.cancelled")}
        </TextNode>
        <TextNode font="wesR" bottom="s" size={12}>
          {cancelledBy && (
            <>
              by{" "}
              <Link
                className={styles.userLink}
                to={{ ...routeLocation, pathname: `${routeLocation.pathname}/+user-profile/${cancelledBy.id}/contact` }}
              >
                {cancelledBy.firstName} {cancelledBy.lastName && cancelledBy.lastName[0]}.
              </Link>{" "}
            </>
          )}
          {cancelledAt && <>on {format(parseISO(cancelledAt), "M/dd/yy")}</>}
        </TextNode>
        {cancellationReason && (
          <TextNode bottom="xs" em>
            {cancellationReason.label}
          </TextNode>
        )}
        <TextNode>{cancellationReasonDescription}</TextNode>
      </TextGroup>
    </div>
  );
}
