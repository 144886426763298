
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Answer": [
      "AgreementSignatureAnswer",
      "AtomChoiceManyAnswer",
      "AtomChoiceOneAnswer",
      "BooleanAnswer",
      "ContactAnswer",
      "ExpenseAnswer",
      "FloatAnswer",
      "ImageAnswer",
      "IntAnswer",
      "LocationAnswer",
      "MoneyAnswer",
      "MoneyWithNegativeAnswer",
      "MultipleChoiceManyAnswer",
      "MultipleChoiceOneAnswer",
      "TemperatureAnswer",
      "TextAnswer",
      "ToggleAnswer"
    ],
    "Document": [
      "FileDocument",
      "LinkDocument"
    ],
    "Question": [
      "AtomChoiceQuestion",
      "ExpenseQuestion",
      "FullQuestion",
      "ImageQuestion",
      "MoneyWithNegativeQuestion",
      "MultipleChoiceQuestion",
      "NumberQuestion",
      "ReportGroupQuestion",
      "TextQuestion"
    ]
  }
};
      export default result;
    