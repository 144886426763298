import React, { useMemo } from "react";

import copyToClipboard from "copy-to-clipboard";
import { Elm } from "components/Elm";
import RosterModal from "./RosterModal.elm";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { RouteComponentProps } from "react-router";
import { ModalRouteParams } from "utilities/modals";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import styles from "./styles.scss";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { getAppUrl, getProgramIdFromSearch } from "utilities/routes";
import { fromBase64 } from "utilities/base64";

type Props = RouteComponentProps<ModalRouteParams & ProgramsRouteParams & { variables: string; gigId?: string }>;

export function GigRoster({
  match: {
    params: { orgId, gigId, page, variables, programGroupId = "all" },
  },
  location,
  history,
}: Props) {
  const id = getProgramIdFromSearch(location.search);
  const handleClose = () => {
    // TODO #512: Create and use Modal abstraction
    if (orgId && id && page) {
      if (gigId) {
        history.push({
          search: location.search,
          pathname: `/${orgId}/program-group/${programGroupId}/programs/${page}/+view/${gigId}`,
        });
      } else {
        history.push({
          search: location.search,
          pathname: `/${orgId}/program-group/${programGroupId}/programs/${page}`,
        });
      }
    }
  };

  const { filters } = useMemo(() => JSON.parse(fromBase64(variables)), [variables]);
  const { me } = useUserInfo();

  return (
    <div className={styles.modal}>
      <Elm
        key={"active"}
        src={RosterModal.Elm.RosterModal}
        flags={{
          session: {
            serverUrl: getServerRootUrl() + "/graphql", // getNewServerRootUrl()
            haskellBackendBaseUrl: getHaskellBackendUrl(),
            rustBackendBaseUrl: getRustBackendUrl(),
            authToken: getJwt(),
          },
          organizationId: orgId,
          programIds: id === "all" ? [] : id.split(","),
          filters,
          saveToGig: location.pathname.includes("+open-to"),
          defaultReplyTo: me?.email || "",
        }}
        ports={ports => {
          ports.closeModalCalled.subscribe(() => handleClose());
          ports.copyDirectLinkCalled.subscribe(hash => {
            copyToClipboard(getAppUrl("report", `s/${encodeURIComponent(hash)}`));
          });
        }}
      />
    </div>
  );
}
