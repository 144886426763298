import React from "react";
import { Field } from "formik";
import { GigTaskManagerFieldFragment, ProgramExecutionTypeFragment, GigTaskManagerFormProviderFragment } from "gql-gen";
import { useTranslation } from "react-i18next";
import Text from "components/Text";
import { ComposableField } from "modules/Connection/ComposableForm";
import { GigProgramField, GigProgramValue } from "../Program";
import executionTypeFragment from "../programExecutionType.gql";

import taskManagerFragment from "./taskManager.gql";
import taskManagerFormProviderFragment from "./taskManagerFormProvider.gql";
import FTaskManagerPicker from "./TaskManagerPicker";
import styles from "./styles.scss";

import { LeaveBlank } from "../LeaveBlank";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { useProgram } from "../useProgram";
import { FormProvider } from "modules/Connection/types";

export interface GigTaskManagerValues {
  partnerOrganizationId?: string;
}

const execTypeFragments = [executionTypeFragment];

export const GigTaskManagerField: ComposableField<
  GigTaskManagerFieldFragment,
  GigTaskManagerValues,
  GigTaskManagerValues,
  GigProgramValue
> = {
  id: "taskManager",

  component: ({ values: { programId }, data, inline, partialBatchUpdate }) => {
    const { t } = useTranslation();
    const { me } = useUserInfo();

    const program = useProgram<ProgramExecutionTypeFragment>(programId, execTypeFragments);

    function message(message: string) {
      return (
        <div className={styles.message}>
          <Text color={inline ? "white" : "gray1"} size={14}>
            {t(`gigs.fields.agency.${message}`)}
          </Text>
        </div>
      );
    }

    if (data && data.partnerOrganization && (!me || !me.isAdmin)) return message("cannotBeChanged");

    if ((!program || !me || me.organizationUser.organizationType === "agency") && !partialBatchUpdate) return null;
    if (program && program.executionType === "solo") return inline ? message("internal") : null;

    return (
      <>
        <Field
          name="partnerOrganizationId"
          component={FTaskManagerPicker}
          compact
          programId={programId}
          inlineForm={inline}
          partialBatchUpdate={partialBatchUpdate}
        />
        <Field component={LeaveBlank} name="partnerOrganizationId" white={inline} />
      </>
    );
  },

  initialize: data => {
    return {
      partnerOrganizationId: (data && data.partnerOrganization && data.partnerOrganization.id) || undefined,
    };
  },

  fragment: taskManagerFragment,

  dependencies: [GigProgramField],
};

export const GigTaskManagerFormProvider: FormProvider<GigTaskManagerFormProviderFragment> = {
  provide: ({ data: { partnerOrganization } = { partnerOrganization: undefined } }) => {
    if (!partnerOrganization) {
      return [GigTaskManagerField];
    }

    return [];
  },
  providerFragment: taskManagerFormProviderFragment,
};
