import * as React from "react";
import { Route, Redirect, Switch, RouteComponentProps, withRouter } from "react-router-dom";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import LoadingContainer from "components/LoadingContainer";
import AccordionLayout from "components/AccordionLayout";
import AccordionSection from "components/AccordionLayout/AccordionSection";
import AccordionPart from "components/AccordionLayout/AccordionPart";
import AccordionPartHelper from "components/AccordionLayout/AccordionPartHelper";
import AccordionPartContent from "components/AccordionLayout/AccordionPartContent";
import lockIcon from "assets/lock.svg";
import variables from "styles/variables";
import { ISavedProgramRequest } from "../Form/IProgramRequest";
import { UserInfoQuery, withUserInfo } from "../../../UserInfo";
import ProgramForm from "../Form";
import { ProgramFormCtx } from "../Form/Context";
import { getSectionsForUser } from "./sections";
import ShareProgramRequest from "./ShareProgramRequest";
import upsertProgram from "./upsertProgram.gql";
import programQuery from "./program.gql";
import organizationQuery from "./organization.gql";
import styles from "./styles.scss";
import { ProgramsRouteParams } from "../index";
import { ApolloClient } from "@apollo/client";
import { DataValue, graphql, withApollo } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { PrfOrganizationQuery } from "gql-gen";
import { getProgramIdFromSearch } from "utilities/routes";

interface ProgramRequestQuery {
  programRequest: ISavedProgramRequest;
}

interface Props extends RouteComponentProps<ProgramsRouteParams>, UserInfoQuery {
  client: ApolloClient<any>;
  data: {
    loading: boolean;
    error: any;
  } & ProgramRequestQuery;
  organizationData: DataValue<PrfOrganizationQuery> | null;
  onCreate: () => void;
  mutate: (param0: {}) => Promise<{}>;
}

class ProgramCreation extends React.Component<Props> {
  private goToSection = (section: string) => {
    this.props.history.replace(this.locationToSection(section));
  };

  private handleSectionChanged = (sections: string[]) => {
    this.goToSection(sections[sections.length - 1]);
  };

  private locationToSection = (section: string | null) => {
    return { pathname: `${this.props.match.url}/${section || "closed"}`, search: this.props.location.search };
  };

  private canEdit() {
    const {
      data: { programRequest },
      userInfo: { impersonator, me },
    } = this.props;

    if ((impersonator && impersonator.isAdmin) || (me && me.isAdmin)) {
      return true;
    }

    return programRequest ? programRequest.managementState === "draft" : true;
  }

  private renderLayout: {
    (props: RouteComponentProps<ProgramsRouteParams & { programFormSection: string }>): JSX.Element;
    (props: RouteComponentProps): JSX.Element;
  } = props => {
    const { programFormSection } = props.match.params as ProgramsRouteParams & { programFormSection: string };
    const id = getProgramIdFromSearch(props.location.search);
    const {
      data,
      mutate,
      organizationData,
      userInfo: { impersonator, me },
    } = this.props;

    const canEdit = this.canEdit();
    const isPartnershipVisible =
      (impersonator && impersonator.isAdmin) ||
      (me && me.isAdmin) ||
      (!!organizationData?.organization?.financialPackageActive && data.programRequest?.executionType !== "solo");
    const isInvitedByVisible =
      !!data.programRequest &&
      !!data.programRequest?.clientOrganizations &&
      data.programRequest?.clientOrganizations.length > 0;
    const sectionsDesc = getSectionsForUser(this.props.userInfo.me, isPartnershipVisible, isInvitedByVisible);

    const goToNextSection = (currentSectionId: string) => {
      let section = sectionsDesc[sectionsDesc.findIndex(s => s.id === currentSectionId) + 1];

      if (section) {
        if (section.minimal) {
          // skip minimal sections
          goToNextSection(section.id);
        } else {
          this.goToSection(section.id);
        }
      }
    };

    const sections = [
      ...sectionsDesc.map(({ id, title, component: SectionComponent, headline, minimal, optional }) => {
        let header = <div />;

        if (headline.length > 0) {
          header = optional ? (
            <Text.P1 kind={programFormSection === id ? "primary" : "tertiary"}>Optional: {headline}</Text.P1>
          ) : (
            <div className={styles.requiredContainer}>
              <Text.Display2 className={styles.required}>Required:</Text.Display2>
              <Text.P1 kind={programFormSection === id ? "primary" : "tertiary"}>{headline}</Text.P1>
            </div>
          );
        }

        return (
          <ProgramFormCtx.Consumer key={id}>
            {({ validation, status: { status } }) => (
              <AccordionSection
                id={id}
                minimal={minimal}
                title={
                  <React.Fragment>
                    {title}
                    &nbsp;&nbsp;
                    {!canEdit && (
                      <span data-tooltip="This section cannot be edited." className={styles.lock}>
                        <Icon src={lockIcon} size={16} fill={variables.gray3} />
                      </span>
                    )}
                  </React.Fragment>
                }
                header={validation[id] ? <Text.Display2 className={styles.complete}>Complete</Text.Display2> : header}
              >
                {() =>
                  status !== "loading" ? (
                    <SectionComponent />
                  ) : (
                    <AccordionPart>
                      <AccordionPartHelper />
                      <AccordionPartContent>
                        <LoadingContainer message={"Just a sec..."} className={styles.sectionLoading} center />
                      </AccordionPartContent>
                    </AccordionPart>
                  )
                }
              </AccordionSection>
            )}
          </ProgramFormCtx.Consumer>
        );
      }),
      <ShareProgramRequest
        key="shareProgramReq"
        isFinancialPackageActive={!!this.props.organizationData?.organization?.financialPackageActive}
      />,
    ];

    return (
      <ProgramForm
        id={id}
        data={data}
        sections={sectionsDesc.map(s => s.id)}
        locationToSection={this.locationToSection}
        mutate={mutate}
        canEdit={canEdit}
        helperMargins={true}
        onSave={goToNextSection}
      >
        <AccordionLayout
          openSections={programFormSection === "closed" ? [] : [programFormSection || ""]}
          onSectionsChanged={this.handleSectionChanged}
        >
          {sections}
        </AccordionLayout>
      </ProgramForm>
    );
  };

  public render() {
    const { match } = this.props;

    return (
      <div className={styles.main}>
        <div className={styles.content}>
          <Text.Display kind={"secondary"} className={styles.title}>
            Create a new program
          </Text.Display>

          <Switch>
            <Route path={match.path + "/:programFormSection"} render={this.renderLayout} />
            <Redirect to={{ ...this.props.location, pathname: match.url + "/overview" }} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default compose(
  withUserInfo,
  withApollo,
  withRouter,
  graphql<Props, PrfOrganizationQuery, unknown, unknown>(organizationQuery, {
    options: props => {
      return {
        variables: {
          orgId: props.match.params.orgId,
        },
      };
    },
    props: ({ data }) => ({ organizationData: data }),
  }),
  graphql<Props, ProgramRequestQuery, { id: string }, unknown>(programQuery, {
    options: props => {
      return {
        variables: {
          id: getProgramIdFromSearch(props.location.search),
        },

        fetchPolicy: "network-only",
      };
    },

    props: ({ data, ownProps }) => {
      if (data && !("programRequest" in data) && !data.loading && ownProps.client) {
        try {
          data =
            ownProps.client.readQuery({
              query: programQuery,
              variables: { id: getProgramIdFromSearch(ownProps.location.search) },
            }) || undefined;
        } catch (e) {
          console.log("Program not yet in cache");
        }
      }
      return { data };
    },
  }),

  graphql(upsertProgram),
)(ProgramCreation);
