import React, { useState } from "react";
import Checkbox from "components/Form/Checkbox";
import styles from "./styles.scss";
import Text, { Color } from "components/Text";
import cx from "classnames";
import _ from "lodash";
import variables from "styles/variables";
import ArrowButton from "components/ArrowButton";
import closeIcon from "assets/cancel-nobg.svg";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import { TableMode } from "modules/Connection/Table";

export interface ListItem {
  id: string;
  label: string;
  count?: number;
  children?: ListItem[];
  mainItem?: boolean;
  large?: boolean;
}

interface ListConfig {
  itemsAlignment: "horizontal" | "vertical";
  listHeight: number;
  bgColor?: string;
  labelColor?: Color;
  largeParentItems?: boolean;
  collapsable?: boolean;
}

export interface SelectedItem {
  id: string;
  children?: SelectedItem[];
}

interface Props {
  onSelect: (selected: SelectedItem[]) => void;
  items: ListItem[];
  showSearch: boolean;
  selected: SelectedItem[];
  children: React.ReactNode;
  listConfig: ListConfig;
  mode?: TableMode;
  onClose?: () => void;
  initialOpenIds?: string[];
  testId?: string;
}

interface TreeItemProps extends Props {
  item: ListItem;
}

function TreeItem(props: TreeItemProps) {
  const {
    listConfig: { itemsAlignment, labelColor, largeParentItems, collapsable },
    item,
    item: { id, children, large: forceLarge },
    selected,
    onSelect,
    initialOpenIds = [],
  } = props;
  const [open, toggleOpen] = useState(initialOpenIds.includes(id));
  const large = (forceLarge || children) && largeParentItems;

  const selectedItem = selected.find(i => i.id === id);
  const parentInter = selectedItem && selectedItem.children && selectedItem.children.length;
  const checked = selectedItem && !selectedItem.children;

  return (
    <li
      key={item.label}
      className={cx(
        item && item.children && item.children.length ? styles.listWithChildren : "",
        item.mainItem && styles.mainItem,
      )}
      onClick={e => {
        e.stopPropagation();
        toggleOpen(!open);
      }}
    >
      <div className={styles.checkboxContainer}>
        <Checkbox
          inverseColors
          checked={parentInter ? "interm" : checked}
          large={large}
          onCheck={isChecked => {
            const selectedWithoutThis = selected.filter(i => i.id !== id);

            if (isChecked) {
              onSelect([...selectedWithoutThis, { id }]);
            } else {
              onSelect(selectedWithoutThis);
            }
          }}
        />
        <Text
          font={item.children && itemsAlignment === "horizontal" ? "wes" : "lato"}
          weight={600}
          size={large ? 18 : item.mainItem ? 16 : item.children ? 12 : 14}
          color={checked ? "teal1" : labelColor ? labelColor : "white"}
          className={styles.label}
        >
          {item.label}
        </Text>
        {item.count && (
          <Text font="lato" weight={400} size={children ? 14 : 18} color={"gray4"} className={styles.label}>
            ({item.count.toLocaleString()})
          </Text>
        )}
        {children && children.length && collapsable && (
          <ArrowButton
            direction={open ? "up" : "down"}
            size={20}
            fill={variables.blue3}
            className={styles.arrowBtn}
            onClick={() => toggleOpen(!open)}
          />
        )}
      </div>
      {children && children.length && (
        <ul
          className={cx(
            styles.itemsList,
            styles.childrenList,
            children.every(item => !Object.keys(item).includes("children")) &&
              itemsAlignment === "horizontal" &&
              styles.horizontalToColumn,
            itemsAlignment === "vertical" && styles.verticalToColumn,
            collapsable && styles[open ? "listOpen" : "listClosed"],
          )}
        >
          {children.map(child => (
            <TreeItem
              {...props}
              key={child.id}
              item={child}
              selected={selectedItem ? selectedItem.children || children.map(c => ({ id: c.id })) : []}
              onSelect={selectedChildren => {
                let selectedItem: SelectedItem = { id };

                if (selectedChildren.length < children.length || selectedChildren.find(c => c.children)) {
                  selectedItem = { ...selectedItem, children: selectedChildren };
                }

                const index = selected.findIndex(i => i.id === id);

                if (index !== -1) {
                  onSelect([...selected.slice(0, index), selectedItem, ...selected.slice(index + 1)]);
                } else {
                  onSelect([...selected, selectedItem]);
                }
              }}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

export default function TreeFilter(props: Props) {
  const {
    listConfig: { bgColor, listHeight, itemsAlignment },
    items,
    mode,
    onClose,
    initialOpenIds,
    testId,
  } = props;
  const vertical = itemsAlignment !== "horizontal";
  const isCompact = mode === "compact";

  return (
    <div
      style={{
        backgroundColor: bgColor || variables.gray1,
        height: listHeight,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        {!isCompact && onClose && vertical && (
          <Clickable onClick={onClose} className={styles.close}>
            <Icon src={closeIcon} fill={"white"} size={22} />
          </Clickable>
        )}
      </div>

      <div className={cx(styles.container, vertical && styles.verticalContainer, isCompact && styles.compactContainer)}>
        <ul className={cx(styles.itemsList, vertical && styles.mainList)} data-test={testId}>
          {items.map(child => (
            <TreeItem {...props} key={child.id} item={child} initialOpenIds={initialOpenIds} />
          ))}
        </ul>
        {props.children}
      </div>
    </div>
  );
}

export function removeId(items: SelectedItem[], id: string) {
  const newItems = [];

  for (let item of items) {
    if (item.id !== id) {
      if (item.children) {
        if (item.children.length === 1) {
          return;
        }
        item = { ...item, children: removeId(item.children, id) };
      }

      newItems.push(item);
    }
  }

  return newItems.length ? newItems : undefined;
}
