import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Fuse from "fuse.js";
import cx from "classnames";
import _ from "lodash";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import LegacyModal from "components/LegacyModal";
import LoadingContainer from "components/LoadingContainer";
import ListView from "components/ListView";
import ListGroup from "components/ListView/ListGroup";
import Button from "components/Button";
import Shortcuts, { shortcut } from "components/Shortcuts";
import Pill from "components/Pill";
import { UserType, isUserAtLeast } from "interfaces/user";
import xIcon from "assets/cancel-nobg.svg";
import { Program } from "../../../interfaces/Program";
import ProgramItem from "./ProgramItem";
import selectProgramsQuery from "./selectPrograms.gql";
import styles from "./styles.scss";
import { OrganizationUser } from "interfaces/user";
import { programsRouteName, ProgramsRouteParams } from "../index";
import { ModalRouteParams } from "utilities/modals";
import { omitQueries, addQueries, getProgramIdFromSearch } from "utilities/routes";

type Props = RouteComponentProps<ProgramsRouteParams & ModalRouteParams> & {
  me: {
    userType?: UserType;
    programs: Program[];
    organizationUser: OrganizationUser;
    isAdmin?: boolean;
  };

  organization: {
    id: string;
    programCreationActive: boolean;
  };

  impersonator: {
    isAdmin: boolean;
  } | null;
};

type ID = string;

interface State {
  search: string;
  matches: Program[];
  selected: ID[];
  focused: Program | null;
}

class SelectProgramModal extends React.Component<Props, State> {
  private fuse?: Fuse<Program, {}>;
  private inputRef?: HTMLInputElement | null;
  private fixedScroll: boolean = false;

  constructor(props: Props) {
    super(props);

    const id = getProgramIdFromSearch(props.location.search);

    let selected: string[] = [];

    if (id) {
      const ids = id.split(",");

      if (ids.length > 1) {
        selected = ids;
      }
    }

    this.state = {
      search: "",
      matches: [],
      selected,
      focused: null,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.me && (!this.fuse || this.props.me !== nextProps.me)) {
      const programs = nextProps.me.programs;

      if (programs) {
        this.fuse = new Fuse(programs, {
          shouldSort: true,
          threshold: 0.6,
          location: 0,
          distance: 100,
          keys: ["name"],
        });

        this.setState({
          ...this.state,
          ...this.doSearch(false),
          ...this.changeFocus("up", programs, false)(),
        });
      }
    }
  }

  public componentDidUpdate() {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  public shortcuts = [
    shortcut("esc", () => this.cancel()),

    shortcut(["down", "shift+down", "tab", "shift+tab"], () => {
      this.changeFocus("down")();
      return false;
    }),

    shortcut(["up", "shift+up"], () => this.changeFocus("up")()),

    shortcut("enter", () => this.go(undefined, true)),

    shortcut("shift+enter", () => {
      const { focused } = this.state;

      if (focused) {
        this.toggleProgram(focused)();
      }
    }),
  ];

  public isSearching() {
    return this.state.search.length > 0;
  }

  private isProgramSelected(program: Program) {
    return this.state.selected.includes(program.id);
  }

  private getListCollection() {
    const { me } = this.props;

    let result: Program[] = [];

    if (me && me.programs) {
      if (this.isSearching()) {
        result = this.state.matches;
      } else {
        result = me.programs;
      }
    }

    return result;
  }

  private doSearch(setState: boolean = true): Partial<State> {
    let matches: Program[] = [];

    let focused = this.state.focused;

    if (this.isSearching()) {
      if (this.fuse) {
        const { search } = this.state;

        matches = this.fuse.search(search);

        if (focused && !matches.find(p => !!(focused && p.id === focused.id))) {
          focused = matches[0];
        }
      }
    }

    let changes = { matches, focused };

    if (setState) {
      this.setState(changes);
    }

    return changes;
  }

  private updateSearch(search: string, callback?: () => void) {
    this.setState({ search }, () => {
      if (callback) {
        callback();
      }

      this.doSearch();
    });
  }

  private changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.updateSearch(event.target.value);
  };

  private clear = () => {
    this.updateSearch("");
  };

  private toggleProgram = (program: Program, setState: boolean = true) => () => {
    const selectedNow = this.state.selected;

    let selected;

    if (this.isProgramSelected(program)) {
      selected = selectedNow.filter(id => id !== program.id);
    } else {
      selected = [...selectedNow, program.id];
    }

    const changes = {
      selected,
    };

    if (setState) {
      this.setState(changes);
    }

    return changes;
  };

  private changeFocus = (direction: "up" | "down", collection?: Program[], setState: boolean = true) => (): Partial<
    State
  > => {
    if (!collection) {
      collection = this.getListCollection();
    }

    const { focused } = this.state;

    if (collection.length === 0) return {};

    let item;

    if (focused) {
      const currentIndex = collection.indexOf(focused);

      if (direction === "up") {
        if (currentIndex === 0) {
          item = collection[collection.length - 1];
        } else {
          item = collection[currentIndex - 1];
        }
      } else {
        if (currentIndex === collection.length - 1) {
          item = collection[0];
        } else {
          item = collection[currentIndex + 1];
        }
      }
    } else {
      item = collection[0];
    }

    const changes = { focused: item };

    if (setState) {
      this.setState(changes);
    }

    return changes;
  };

  private go(program?: Program, addFocused: boolean = false) {
    let id;

    if (program) {
      id = program.id;
    } else {
      const { focused, selected } = this.state;

      if (selected.length > 0) {
        id = selected.join(",");

        if (focused && !selected.includes(focused.id) && addFocused) {
          id += "," + focused.id;
        }
      } else if (focused && addFocused) {
        id = focused.id;
      }
    }

    if (id) this.openPrograms(id);
  }

  private cancel = () => {
    const { orgId, page } = this.props.match.params;
    const id = getProgramIdFromSearch(this.props.location.search);

    if (orgId && id && page) {
      this.props.history.replace({
        pathname: `/${orgId}/program-group/all/${programsRouteName}/${page}`,
        search: this.props.location.search,
      });
    } else {
      this.props.history.goBack();
    }
  };

  private viewAllPrograms() {
    const { orgId } = this.props.match.params;
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("program")) {
      queryParams.delete("program");
    }
    if (orgId) {
      this.props.history.replace({
        ...location,
        pathname: `/${orgId}/program-group/all/${programsRouteName}`,
        search: queryParams.toString(),
      });
    }
  }

  private openPrograms(id: ID | null) {
    if (id) {
      const { match, history, location } = this.props;
      const { search } = location;

      const withoutProgramId = omitQueries(search, ["program"]);

      const { page, orgId, programGroupId } = match.params;
      if (orgId) {
        history.replace({
          ...location,
          pathname: `/${orgId}/program-group/${programGroupId}/${programsRouteName}${page ? "/" + page : ""}`,
          search: addQueries(withoutProgramId, { program: id }),
        });
      }
    }
  }

  private renderProgram = (program: Program) => {
    const { focused } = this.state;

    const itemIsFocused = focused ? focused.id === program.id : false;

    return (
      <ProgramItem
        key={program.id}
        onClick={() => this.setState({ focused: program })}
        onGo={() => this.go(program)}
        program={program}
        focused={itemIsFocused}
        selected={this.isProgramSelected(program)}
        onToggle={this.toggleProgram(program)}
      />
    );
  };

  private renderContent() {
    const { me } = this.props;

    if (me && me.programs) {
      if (me.programs.length === 0) {
        return (
          <div className={styles.fullSize}>
            <Text.P2 kind="secondary">You don't have any programs yet</Text.P2>
          </div>
        );
      }

      const results = this.getListCollection();

      if (results.length === 0) {
        return (
          <div className={styles.fullSize}>
            <Text.P2 kind="secondary">
              We couldn't find anything matching that{" "}
              <span className="emoji" role="img" aria-label="sad">
                &#x1F615;
              </span>
              ️
            </Text.P2>
            <Text.P3 kind="secondary">Try refining your search, or clear it to see all your programs</Text.P3>
          </div>
        );
      }

      const { draft, accepted, archived } = _.chain(results)
        .filter(p => p.managementState !== "sow")
        .groupBy(p => {
          if (p.archived) return "archived";
          return p.managementState === "accepted" ? "accepted" : "draft";
        })
        .value();

      const searching = this.isSearching();

      return (
        <ListView
          className={styles.list}
          ref={list => {
            if (!this.fixedScroll && list) {
              setTimeout(() => {
                list.scrollTo(0, 0);
                this.fixedScroll = true;
              }, 0);
            }
          }}
        >
          {accepted && (
            <ListGroup
              name={searching ? "Active Results" : "Active Programs"}
              headerClassName={styles.activeHeader}
              headerNameClassName={styles.headerName}
              headerTop={0}
              sticky={false}
            >
              {accepted.map(this.renderProgram)}
            </ListGroup>
          )}

          {draft && (
            <ListGroup
              name={searching ? "Draft Results" : "Draft Programs"}
              headerClassName={styles.draftHeader}
              headerTop={0}
              sticky={false}
            >
              {draft.map(this.renderProgram)}
            </ListGroup>
          )}

          {archived && (
            <ListGroup
              name={searching ? "Inactive Results" : "Inactive Programs"}
              headerClassName={styles.draftHeader}
              headerTop={0}
              sticky={false}
            >
              {archived.map(this.renderProgram)}
            </ListGroup>
          )}
        </ListView>
      );
    } else {
      return <LoadingContainer tip={`You can start typing, we'll search ASAP`} className={styles.loader} />;
    }
  }

  private renderSelectedItems() {
    const { selected } = this.state;
    const { me } = this.props;

    if (!me || !me.programs) return;

    const items = selected.map(id => {
      const program = me.programs.find(p => p.id === id);

      return program && <Pill key={id} title={program.name} onRemove={this.toggleProgram(program)} />;
    });

    return (
      items.length > 0 && (
        <div className={styles.selectedItems}>
          <div className={styles.selectedItemsWrapper}>
            {items}
            <div className={styles.spacerAtEnd} />
          </div>
        </div>
      )
    );
  }

  private goToNewProgram = () => {
    const {
      match: {
        params: { orgId },
      },

      history,
      location,
    } = this.props;

    //me.programs.find(program => program.saas)
    if (orgId) {
      history.push({
        pathname: location.pathname.replace("/+select", "/+create-program"),
        search: location.search,
      });
    } else {
      // this should never happen (famous last words)
      history.push(`/new-program-tf`);
    }
  };

  public renderNewProgramLink() {
    const { me, organization } = this.props;

    if (!me || !isUserAtLeast(me, "manager") || !organization || !organization.programCreationActive) {
      return null;
    }

    return (
      <div className={styles.newProgram}>
        <Text.H3 kind={"reverse"} className={styles.question}>
          Need a new program?&nbsp;
          <Text.NewLink3 onClick={this.goToNewProgram}>Start here &gt;</Text.NewLink3>
        </Text.H3>
      </div>
    );
  }

  public render() {
    const { search } = this.state;
    const { programGroupId } = this.props.match.params;

    return (
      <Shortcuts shortcuts={this.shortcuts}>
        <LegacyModal className={styles.modal}>
          <header className={styles.header}>
            <input
              ref={input => (this.inputRef = input)}
              className={cx(styles.input, "mousetrap")}
              value={search}
              onChange={this.changeSearch}
              autoFocus
              placeholder={"Type a program name…"}
              data-test={"overview.selectProgramModal.programNameInput"}
            />

            <div className={this.isSearching() ? styles.clear : styles.clearHidden} onClick={this.clear}>
              <Icon src={xIcon} size={30} fill={"#fff"} />
            </div>
          </header>

          {this.renderContent()}

          <footer className={styles.footer}>
            {this.renderSelectedItems()}
            <div className={styles.buttons}>
              <Button
                onClick={() => this.viewAllPrograms()}
                kind={"gray"}
                testId={"overview.selectProgramModal.viewAllButton"}
              >
                View all
              </Button>
              <div className="spacer" />
              <Button onClick={this.cancel} testId={"overview.selectProgramModal.cancelButton"}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={() => this.go()}
                testId={"overview.selectProgramModal.viewSelectedButton"}
              >
                View Selected
              </Button>
            </div>
          </footer>

          {this.renderNewProgramLink()}
        </LegacyModal>
      </Shortcuts>
    );
  }
}

export default compose(
  graphql<any, any, any, any>(selectProgramsQuery, {
    props: props => {
      const { data } = props;
      if (data && data.me) {
        return {
          ...data,
          me: {
            ...data.me,
            programs: _.sortBy(data.me.programs, ["managementState", "name"]),
          },
        };
      }

      return data;
    },

    options: props => ({
      variables: {
        orgId: props.match.params.orgId,
        programGroupIds: props.match.params.programGroupId === "all" ? undefined : props.match.params.programGroupId,
      },

      fetchPolicy: "cache-and-network",
    }),
  }),

  withRouter,
)(SelectProgramModal);
