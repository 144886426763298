import * as React from "react";
import LegacyModal from "components/LegacyModal";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { IconSrc } from "../Icon";
import styles from "./styles.scss";
import cx from "classnames";

export interface ConfirmBoxPresentation {
  icon: IconSrc;
  title: string;
  children: React.ReactNode;
  yesText?: string;
  yesDisabled?: boolean;
  bodyClass?: string;
}

interface Props extends ConfirmBoxPresentation {
  onYes: () => void;
  onNo: () => void;
}

export default function ConfirmBox({
  bodyClass,
  title,
  icon,
  onYes,
  onNo,
  children,
  yesText = "YES",
  yesDisabled,
}: Props) {
  return (
    <LegacyModal noPadding className={styles.confirmBox}>
      <ModalHeader className={styles.header} mainAction={title} icon={icon} onClose={onNo} />

      <div className={cx(styles.body, bodyClass)}>{children}</div>

      <ModalFooter actionDisabled={yesDisabled} actionName={yesText} onAction={onYes} onCancel={onNo} />
    </LegacyModal>
  );
}
