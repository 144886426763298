import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Text from "components/Text";

import { useScopeContext } from "../Scope";
import styles from "./styles.scss";
import {
  Error as BudgetError,
  extractErrors as extractBudgetErrors,
  isBudgetError,
  Display as DisplayBudgetError,
} from "./BudgetErrors";

const UNKNOWN_ERROR = "components.scopeErrors.unknown";
const NETWORK_ERROR = "components.scopeErrors.network";

type ErrorMessage = string | BudgetError;

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export function ScopeErrors({ className, style }: Props) {
  const { getErrorMessage, error } = useScopeContext();
  const { t } = useTranslation();

  if (!error) return null;

  let messages: ErrorMessage[] = [];

  if (error === "network") {
    messages = [t(NETWORK_ERROR)];
  } else if (error === "unknown") {
    messages = [t(UNKNOWN_ERROR)];
  } else {
    const simpleErrors = Object.values(error.items)
      .flatMap(item => item.filter(errKey => !isBudgetError(errKey)))
      .map(errKey => t([getErrorMessage(errKey), UNKNOWN_ERROR]));

    messages = [...extractBudgetErrors(error), ...simpleErrors];
  }

  if (!messages.length) {
    return null;
  }

  return (
    <div className={cx(styles.errors, className)} style={style}>
      {[...new Set(messages)].map(message =>
        typeof message === "string" ? (
          <Text color="pink1" size={12} key={message}>
            {message}
          </Text>
        ) : message.scope === "budget" ? (
          <DisplayBudgetError key={message.tag} message={message} />
        ) : null,
      )}
    </div>
  );
}
