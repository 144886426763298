import React from "react";
import partnersFragment from "./partners.gql";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { connection, ConnectionConfig } from "utilities/connections";
import { useTranslation } from "react-i18next";
import { OrgPartnerOptionsFragment } from "gql-gen";
import { Expression } from "utilities/knueppel";

export const partnersConnection: ConnectionConfig<OrgPartnerOptionsFragment> = connection({
  name: "OrgPartnersOptions",
  entry: { name: "organizationConnectionsPartners" },
  variables: { search: "String", programId: "ID", onlyVisible: "Boolean" },
});

export default function PartnersPicker({
  field,
  form,
  multiple,
  filters,
  label,
  onChange,
  disabled,
  onlyVisible = false,
}: FieldProps & {
  multiple: boolean;
  filters?: Expression;
  label?: string;
  onChange?: () => void;
  disabled?: boolean;
  onlyVisible: boolean;
}) {
  const { t } = useTranslation();
  return (
    <SearchableSelect<OrgPartnerOptionsFragment>
      label={label || t("program.forms.partners.partners")}
      multiple={multiple}
      connection={partnersConnection}
      fragment={partnersFragment}
      value={field.value ? (Array.isArray(field.value) ? field.value : [field.value]) : []}
      variables={{ filters, onlyVisible }}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      renderName={({ name }) => name}
      onChange={r => {
        onChange && onChange();
        form.setFieldValue(field.name, r);
      }}
      disabled={disabled}
      itemNamePlural="partners"
    />
  );
}
