import React from "react";

import styles from "./styles.scss";
import Text from "components/Text";

interface Props {
  blur: boolean;
  bannerText: string;
  link?: React.ReactNode;
  children: JSX.Element;
}

export default function Blurred({ blur, bannerText, link, children }: Props) {
  return (
    <div className={styles.container}>
      {blur ? (
        <div className={styles.infoBar}>
          <Text font="wes" bold size={20} color="pink1">
            {bannerText}
          </Text>
          {link && (
            <Text font="wes" bold size={12} color="blue1" className={styles.link}>
              {link}
            </Text>
          )}
        </div>
      ) : null}
      <div className={blur ? styles.blurContent : ""}>{children}</div>
    </div>
  );
}
