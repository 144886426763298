import React, { useState } from "react";
import { IAction } from "modules/Connection/types";
import { ActionBody } from "modules/Connection/ActionBody";

import { useQuery } from "@apollo/client";
import icon from "assets/partners.svg";
import { colors } from "styles/variables";
import { ScopeErrors } from "modules/Connection/ScopeErrors";
import { Form } from "modules/Connection/Form";
import { GigPartnerField } from "../../Fields/Partner";
import { PartnerFieldProgramPartnersQuery, PartnerFieldProgramPartnersQueryVariables } from "gql-gen";
import programPartnersQuery from "./programPartners.gql";

const i18n = "gigs.actions.selectPartner";

export const SelectPartnerAction: IAction = {
  key: "selectPartner",
  icon,
  labelKey: "gigs.actions.selectPartner.label",
  color: colors.blue2,
  allowInline: true,
  allowBatch: true,

  component: ({ done, renderSubmit }) => {
    const [programId, setProgramId] = useState();
    const { data: programPartnersData } = useQuery<
      PartnerFieldProgramPartnersQuery,
      PartnerFieldProgramPartnersQueryVariables
    >(programPartnersQuery, {
      variables: { programId: programId! },
      skip: !programId,
    });

    const programPartners = programPartnersData?.organizationConnectionsPartners.edges.map(({ node }) => node);

    return (
      <ActionBody done={done}>
        <Form
          form={GigPartnerField}
          close={done}
          renderSubmit={fp => {
            setProgramId(fp.values.programId);
            return (
              <>
                <ScopeErrors />

                {programPartners?.length
                  ? renderSubmit({
                      ...fp,
                      i18n,
                    })
                  : null}
              </>
            );
          }}
        />
      </ActionBody>
    );
  },
};
