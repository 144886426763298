import React from "react";

import { TextGroup, TextProps, TextNode } from "components/Text";
import styles from "./styles.scss";

export function FieldOptionsText({ inlineForm, ...props }: TextProps & { inlineForm?: boolean }) {
  return (
    <TextGroup
      {...props}
      font="lato"
      color={inlineForm ? "white" : "gray3"}
      bottom="m"
      size={14}
      className={styles.optionsText}
    />
  );
}

export function FieldOptionsBold({ inlineForm, ...props }: TextProps & { inlineForm?: boolean }) {
  return <TextNode {...props} bold color={inlineForm ? "white" : "gray1"} />;
}
