import * as React from "react";
import styles from "./styles.scss";
import cx from "classnames";

interface Props {
  scale?: number;
  color?: "blue" | "white";
}

export default function Spinner({ scale = 0.5, color = "blue" }: Props) {
  const cubeClass = color === "blue" ? styles.blueCube : styles.whiteCube;
  return (
    <div className={styles["folding-cube"]} style={{ transform: `rotateZ(45deg) scale(${scale})` }}>
      <div className={cubeClass} />
      <div className={cx(styles.cube2, cubeClass)} />
      <div className={cx(styles.cube4, cubeClass)} />
      <div className={cx(styles.cube3, cubeClass)} />
    </div>
  );
}
