import * as React from "react";

import * as FieldValue from "../FieldValue";
import ProgramInfo from "../ProgramInfo";
import DocumentsTable from "../../../LegacySettings/DocumentsTable/Table";

export default function Education() {
  return (
    <React.Fragment>
      <FieldValue.Text name={"trainingMessage"} label={"Key Info"} />

      <ProgramInfo.Consumer>
        {({ trainingDocuments }) =>
          trainingDocuments && trainingDocuments.length > 0 ? (
            <DocumentsTable
              data={{ loading: false, documents: trainingDocuments }}
              tableProps={{ selectable: false }}
            />
          ) : null
        }
      </ProgramInfo.Consumer>
    </React.Fragment>
  );
}
