import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import checkIcon from "assets/check.svg";
import { colors } from "styles/variables";
import Text from "components/Text";
import { useMutation } from "@apollo/client";
import { ActionBody } from "modules/Connection/ActionBody";
import markClosed from "./markClosed.gql";
import undoMarkClosed from "./undoMarkClosed.gql";
import useRouter from "use-react-router";
import { getProgramIdFromSearch } from "utilities/routes";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";

export enum Close {
  Close = "markClosed",
  UndoClose = "undoMarkClosed",
}

export function ToggleClosed(action: Close): IAction {
  const i18n = `gigs.actions.close`;

  return {
    key: action,
    icon: checkIcon,
    labelKey: `${i18n}.${action}.label`,
    color: action === Close.Close ? colors.teal1 : colors.pink1,
    allowInline: true,
    allowBatch: true,

    component: ({ variables, count, done, renderSubmit }) => {
      const { t } = useTranslation();
      const mutation = action === Close.Close ? markClosed : undoMarkClosed;
      const [mutate] = useMutation(mutation);
      const [isSubmitting, setIsSubmitting] = useState(false);
      const { location } = useRouter<ProgramsRouteParams>();
      const id = getProgramIdFromSearch(location.search);

      return (
        <ActionBody done={done}>
          <Text color="white">{t(`${i18n}.areYouSure`, { count })}</Text>
          {renderSubmit({
            i18n,
            isSubmitting,
            handleSubmit: async () => {
              setIsSubmitting(true);
              await mutate({ variables: { ...variables, programIds: id === "all" ? [] : id.split(",") } });
              setIsSubmitting(false);
              done();
            },
          })}
        </ActionBody>
      );
    },
  };
}
