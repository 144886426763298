import React, { useRef, useState, useEffect } from "react";
import Popup, { PopupProps, PopupBodyProps } from "components/Popup";
import LoadingContainer from "components/LoadingContainer";

export interface ImpatientContentPopupProps<T> extends PopupBodyProps {
  data: T;
}

export interface ImpatientPopupProps<T> extends Omit<PopupProps, "open" | "body"> {
  component: React.ComponentType<ImpatientContentPopupProps<T>>;
  fetch: () => Promise<T | null>;
  containerClassName?: string;
  dependencies: unknown[];
}

export function ImpatientPopup<T>({
  component,
  fetch,
  children,
  containerClassName,
  dependencies,
  ...props
}: ImpatientPopupProps<T>) {
  const timer = useRef<number | null>(null);
  const [data, setData] = useState<T | null>(null);
  const fetching = useRef<boolean>(false);

  useEffect(() => {
    if (data) {
      setData(null);
    }
  }, dependencies);

  const loadData = async () => {
    if (fetching.current) return;
    fetching.current = true;

    try {
      setData(await fetch());
    } finally {
      fetching.current = false;
    }
  };

  return (
    <Popup
      {...props}
      onOpen={() => {
        loadData();
      }}
      body={({ close }) => (
        <div className={containerClassName}>
          {data ? (
            React.createElement(component, { data, close })
          ) : (
            <LoadingContainer color="white" center message="" />
          )}
        </div>
      )}
    >
      {children}
    </Popup>
  );
}
