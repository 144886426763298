import React from "react";
import { useTranslation } from "react-i18next";
import { GigsOwnerFragment } from "gql-gen";
import icon from "assets/team.svg";
import { CellBase, CR1, CR2, CR3, AddDetails } from "modules/Connection/CellBase";
import { ICell } from "modules/Connection/types";
import ownerFragment from "./owner.gql";
import { format, parseISO } from "date-fns";
import { UserLink } from "modules/Dashboard/UserLink";

export const OwnerCell: ICell<GigsOwnerFragment> = {
  component: props => {
    const { t } = useTranslation();

    const { data, handleOpenForm } = props;

    if (!data || !data.manager) {
      return (
        <CellBase {...props}>
          {() => <AddDetails onClick={handleOpenForm}>{t("gigs.columns.contact.addContact")}</AddDetails>}
        </CellBase>
      );
    }

    const {
      id: gigId,
      manager: { id, firstName, lastName },
      ownerOrganization,
      talentOrganization,
      managerOrganization,
      createdBy,
      createdAt,
    } = data;

    const name = `${firstName} ${lastName}`;

    return (
      <CellBase {...props}>
        {mode => {
          const shouldShowManagerOrg =
            mode !== "detailed" && managerOrganization && managerOrganization.id !== talentOrganization?.id;
          return (
            <>
              <CR1 underline="hover">
                <UserLink id={id} gigId={gigId} isOwner>
                  {mode === "detailed" ? t("gigs.columns.owner.ownedBy", { name }) : name}
                </UserLink>
              </CR1>
              {mode !== "detailed" && <CR3>Owner: {ownerOrganization && ownerOrganization.name}</CR3>}
              {mode === "detailed" && createdBy && (
                <CR3>{`${t("gigs.columns.owner.createdBy", {
                  name: `${createdBy.firstName} ${createdBy.lastName}`,
                })} on ${format(parseISO(createdAt), "MM/dd/yy")}`}</CR3>
              )}

              {shouldShowManagerOrg && (
                <CR3>Manager: {managerOrganization?.name}</CR3>
              )}
            </>
          );
        }}
      </CellBase>
    );
  },
  icon,
  fragment: ownerFragment,
};
