import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export type PVariant = "v1" | "v2" | "v3" | "v4" | "v5";

export interface PProps {
  children: React.ReactNode;
  variant?: PVariant;
  kind?: "primary" | "secondary" | "tertiary" | "error" | "reverse" | "pink" | "green" | "royal";
  inline?: boolean;
  tooltip?: string;
  className?: string;
  ellipsis?: boolean;
  title?: string;
}

export default function P({
  children,
  ellipsis,
  inline,
  variant = "v1",
  kind = "primary",
  className,
  title,
  tooltip,
}: PProps) {
  return (
    <div
      className={cx(
        styles.p,
        styles[kind],
        styles[variant],
        inline && styles.inline,
        ellipsis && styles.ellipsis,
        className,
      )}
      data-tooltip={tooltip}
      title={title}
    >
      {children}
    </div>
  );
}
