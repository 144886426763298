import React from "react";
import invoiceFragment from "./invoices.gql";
import { SearchableSelect } from "components/SearchableSelect";
import { FieldProps } from "formik";
import { bin } from "utilities/knueppel";
import { connection } from "utilities/connections";
import { useTranslation } from "react-i18next";
import { InvoiceOptionsFragment } from "gql-gen";
import styles from "./styles.scss";

export default function PartnersPicker({ field, form }: FieldProps) {
  const { t } = useTranslation();
  return (
    <SearchableSelect<InvoiceOptionsFragment>
      label={t("gigs.actions.addToInvoice.message")}
      multiple={false}
      connection={connection({
        name: "InvoiceOptions",
        entry: { name: "invoices" as any },
        variables: { search: "String" },
      })}
      fragment={invoiceFragment}
      value={field.value ? [field.value] : []}
      variables={{
        filters: bin("statekey", "in", { type: "ListLiteral", values: ["draft", "draftRejected", "draftRetracted"] }),
      }}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
      renderName={({ name, number, recipientOrganization }) =>
        `${name} ${number ? `(${number}) ` : ""} ${recipientOrganization ? `(${recipientOrganization.name})` : ""}`
      }
      onChange={r => form.setFieldValue(field.name, r[0])}
      className={styles.invoicePicker}
    />
  );
}
