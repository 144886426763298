import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Suggest } from "react-geosuggest";
import { get } from "lodash";

import BaseFormElement from "components/Form/BaseFormElement";
import GeoSuggest from "components/GeoSuggest";
import TextBox from "components/Form/TextBox";
import { CalendarPicker } from "components/Calendar/DayPicker";
import TimePicker from "components/TimePicker";
import { SearchableSelect } from "components/SearchableSelect";
import Section from "components/Form/Section";
import { UploaderContext } from "modules/Uploader/context";
import { resolveDependencies } from "modules/Uploader/references";
import { IValueSource } from "modules/Uploader/sources";
import { FieldConfig } from "modules/Uploader/fields";
import DecimalBox from "components/Form/DecimalBox";
import styles from "./styles.scss";

const ns = "importer.source.value";

export interface Props {
  field: FieldConfig;
  value: IValueSource;
  addedToPrevious: boolean;
  onChange: (value: IValueSource) => void;
}

export function ValueSource({ value: src, onChange, field, addedToPrevious }: Props) {
  const { t } = useTranslation();
  const { sheetMap: fieldSources } = useContext(UploaderContext);

  let sourceSettings;

  const setVal = (val: any) => {
    onChange({ ...src, val });
  };

  if (field.type === "money") {
    sourceSettings = (
      <Section className={styles.sourceSection}>
        <DecimalBox value={src.val} onChange={setVal} className={styles.basicMoney} />
      </Section>
    );
  } else if (field.type === "number") {
    sourceSettings = (
      <Section className={styles.sourceSection}>
        <TextBox
          placeholder={field.placeholder || t(`${ns}.writeHere`)}
          type={"number"}
          min={field.min}
          max={field.max}
          value={src.val || ""}
          onChange={e => setVal(parseInt(e.target.value))}
        />
      </Section>
    );
  } else if (field.type === "text" || (addedToPrevious && (field.type !== "ref" || !field.multiple))) {
    sourceSettings = (
      <Section className={styles.sourceSection}>
        <TextBox
          placeholder={field.placeholder || t(`${ns}.writeHere`)}
          value={src.val || ""}
          onChange={e => setVal(e.target.value)}
        />
      </Section>
    );
  } else if (field.type === "ref") {
    const { type, multiple, variables, ...searchableProps } = field;

    sourceSettings = (
      <Section className={styles.sourceSection}>
        <SearchableSelect
          variables={resolveDependencies(variables, fieldSources)}
          value={src.val || []}
          onChange={setVal}
          multiple={multiple}
          {...searchableProps}
        />
      </Section>
    );
  } else if (field.type === "location") {
    sourceSettings = (
      <Section className={styles.sourceSection}>
        <BaseFormElement
          inputClassNameProp="inputClassName"
          element={
            <GeoSuggest
              className={styles.geosuggest}
              placeholder={field.placeholder || t(`${ns}.searchByNameOrAddress`)}
              country={["us", "ca"]}
              initialValue={get(src, "val.formatted_address")}
              onChange={(value: string) => {
                if (value.replace(/\s/g, "") === "") {
                  setVal(undefined);
                }
              }}
              onSuggestSelect={(s: Suggest & { gmaps: google.maps.GeocoderResult & { name: string } }) => {
                //for some reason google decided that geometry.lat and geometry.lon
                //are now functions and not plain values.
                //We call the functions to have plain values inside the object to avoid worker crashes.
                if (s.gmaps) {
                  const val = {
                    place_id: s.gmaps.place_id,
                    name: s.gmaps.name,
                    address_components: s.gmaps.address_components,
                    geometry: {
                      location: { lat: s.gmaps.geometry.location.lat(), lng: s.gmaps.geometry.location.lng() },
                    },
                    formatted_address: s.gmaps.formatted_address,
                  };
                  setVal(val);
                }
              }}
            />
          }
        />
      </Section>
    );
  } else if (field.type === "date") {
    sourceSettings = <CalendarPicker value={src.val} onChange={setVal} min={field.min} max={field.max} />;
  } else if (field.type === "time") {
    sourceSettings = (
      <Section className={styles.sourceSection}>
        <TimePicker
          name={t(`${ns}.time`)}
          direction={"down"}
          placeholder={field.placeholder || t(`${ns}.timePlaceholder`)}
          value={src.val}
          onChange={setVal}
        />
      </Section>
    );
  }

  return <div className={styles.valueSource}>{sourceSettings}</div>;
}
