import Mousetrap, { MousetrapInstance, MousetrapStatic } from "mousetrap";
import * as React from "react";

type ShortcutCombo = string | string[];
type ShortcutHandler = (e: KeyboardEvent, action: string) => void;

interface Shortcut {
  combo: ShortcutCombo;
  handler: ShortcutHandler;
  event?: string;
}

interface Props {
  divProps?: { [key: string]: string };
  enabled?: boolean;
  children: React.ReactNode;
  shortcuts: Shortcut[];
  element?: HTMLElement | null;
}

export default class Shortcuts extends React.Component<Props> {
  private boundShortcuts: Shortcut[] = [];
  private mousetrap: MousetrapStatic | MousetrapInstance = Mousetrap;

  static defaultProps = {
    enabled: true,
  };

  public componentDidMount() {
    if (this.props.enabled) this.bind();
  }

  public componentWillUnmount() {
    this.unbind();
  }

  public UNSAFE_componentWillReceiveProps({ enabled, element }: Props) {
    const { enabled: currentlyEnabled, element: currentElement } = this.props;

    if (element !== currentElement) {
      if (element) {
        this.mousetrap = Mousetrap(element);
      } else {
        this.mousetrap = Mousetrap;
      }
    }

    if (enabled !== currentlyEnabled) {
      if (enabled) {
        this.bind();
      } else {
        this.unbind();
      }
    }
  }

  public bind() {
    if (this.boundShortcuts.length > 0) {
      this.unbind();
    }

    const { shortcuts } = this.props;

    shortcuts.forEach(shortcut => {
      this.mousetrap.bind(shortcut.combo, shortcut.handler, shortcut.event);
    });

    this.boundShortcuts = shortcuts;
  }

  public unbind() {
    if (this.boundShortcuts.length === 0) {
      return;
    }

    this.boundShortcuts.forEach(shortcut => {
      this.mousetrap.unbind(shortcut.combo);
    });

    this.boundShortcuts = [];
  }

  public render() {
    const { divProps, children } = this.props;

    if (divProps) {
      return <div {...divProps}>{children}</div>;
    }

    return children;
  }
}

export function shortcut(combo: ShortcutCombo, handler: ShortcutHandler): Shortcut {
  return { combo, handler };
}
