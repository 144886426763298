import { IReactIntercomProps } from "react-intercom";

import { User } from "interfaces/user";
import { flavor } from "utilities/flavor";

const avatar = (me: User) => {
  const avatar = me.assets?.find(a => a.assetType === "profile_image");

  return avatar ? { type: "avatar", image_url: avatar.url } : undefined;
};

export const getIntercomProps = (
  me: User,
  { customSelector, hideDefaultLauncher }: { customSelector: string; hideDefaultLauncher: boolean },
): Readonly<IReactIntercomProps> => ({
  // The React component uses `appID` and the JS snippet uses `app_id` ¯\_(ツ)_/¯
  appID: "xgos05ct",
  app_id: "xgos05ct",
  hide_default_launcher: hideDefaultLauncher,
  custom_launcher_selector: customSelector,

  // User Props
  name: `${me.firstName ?? "[no first name]"} ${me.lastName ?? "[no last name]"}`,
  email: me.email,
  user_id: me.id,
  user_hash: me.intercomHash,
  flavor,
  avatar: avatar(me),
  organization_type: me.organizationUser?.organizationType,
  organization_user_type: me.organizationUser?.type,
  is_corporate: me.isCorporate,

  companies: me.organizations.filter(Boolean).map(org => ({
    id: org.id,
    name: org.name,
    website: org.website,
    industry: org.industry?.name,
    kind: org.isWorkspace ? "Child organization (workspace)" : "Parent organization",
    type: org.type,
    customer_type: org.customerType,
    subscription_status: org.subscriptionStatus,
    primary_billing_contact: org.primaryBillingContact,
    // Flags ---
    active: org.active,
    availability_calendar_active: org.availabilityCalendarActive,
    branding_active: org.brandingActive,
    bulk_uploader_active: org.bulkUploaderActive,
    demo: org.isDemo,
    enable_new_gig_request_select_partner_ui: org.enableNewGigRequestSelectPartnerUi,
    final_approval_active: org.finalApprovalActive,
    financial_package_active: org.financialPackageActive,
    gig_apply_active: org.gigApplyActive,
    gig_confirmation_active: org.gigConfirmationActive,
    gig_requests_active: org.gigRequestsActive,
    gig_self_assignment_active: org.gigSelfAssignmentActive,
    invoicing_active: org.invoicingActive,
    one_off_locations: org.oneOffLocations,
    partnerships_visible: org.partnershipsVisible,
    program_capacity_active: org.programCapacityActive,
    program_creation_active: org.programCreationActive,
    program_groups_visible: org.programGroupsVisible,
    treasurer_active: org.treasurerActive,
    workspaces_active: org.workspacesActive,
    slides_active: org.slidesActive,
  })),
});
