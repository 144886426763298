import cx from "classnames";
import Fuse from "fuse.js";
import _ from "lodash";
import * as React from "react";
import ListView from "components/ListView";
import ListItem from "components/ListView/ListItem";
import ListGroup from "components/ListView/ListGroup";
import LoadingContainer from "components/LoadingContainer";
import Shortcuts, { shortcut } from "components/Shortcuts";
import Text from "components/LegacyText";
import Icon, { IconSrc } from "components/Icon";
import styles from "./styles.scss";
import { scrollIntoViewIfNeeded } from "utilities/scroll";

export type ID = string;

export type Item = any;

export interface RendererNeeds {
  focusInput: () => void;
  isOpen: boolean;
  openOptions: React.FocusEventHandler<EventTarget>;
  closeOptions: React.FocusEventHandler<EventTarget>;
  inputProps: {
    value: string;
    ref: React.Ref<HTMLInputElement>;
    onFocus: React.FocusEventHandler<HTMLInputElement>;
    onBlur: React.FocusEventHandler<HTMLInputElement>;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onMouseDown: React.MouseEventHandler<HTMLInputElement>;
    className?: string;
    placeholder?: string;
  };
}

export interface CreateProps {
  done: (item: Item) => void;
  search: string;
}

export interface SearchableProps {
  id?: string;
  items: Item[] | null;
  extraItems?: Item[];
  selectedItem?: ID | null;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  search?: (query: string, item: Item) => boolean;
  direction?: "up" | "down";
  compact?: boolean;
  onChange?: (item: Item) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  initialFocusedItem?: Item;
  optionsXOffset?: number;
  optionsYOffset?: number;
  clearSearchOnSelect?: boolean;
  keepFocusOnSelect?: boolean;
  loadingOnNull?: boolean;
  children: (rn: RendererNeeds) => React.ReactNode;
  emptyMessage?: string | null;
  renderItem?: (item: Item) => React.ReactNode;
  createOption?: {
    createText: (search: string) => string;
    render: (createProps: CreateProps) => React.ReactNode;
  };
  onSearchChange?: (val: string) => void;
  groups?: Array<{
    key: string;
    label: string;
    icon: IconSrc;
    loading?: boolean;
    onFetchMore?: () => void;
  }>;
  searchKeys?: string[];
  fallbackItem?: Item | null;
  keepCurrentSearch?: boolean;
}

interface State {
  open: boolean;
  matches: Item[];
  search: string;
  focused: Item | null;
  creating: boolean;
}

export default class Searchable extends React.Component<SearchableProps, State> {
  readonly state: State = {
    open: false,
    focused: null,
    search: "",
    matches: [],
    creating: false,
  };

  private fuse?: Fuse<Item, {}>;
  private inputRef?: HTMLInputElement | null;

  public UNSAFE_componentWillMount() {
    const { search, initialFocusedItem } = this.props;

    if (!search) this.updateFuse();

    if (initialFocusedItem) {
      this.setState({
        focused: initialFocusedItem,
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: SearchableProps) {
    const diffId = nextProps.id !== this.props.id;

    if (!nextProps.search && (diffId || !_.eq(nextProps.items, this.props.items))) {
      this.updateFuse(nextProps);
    }

    if (diffId) {
      this.setState({
        search: "",
        matches: [],
      });
    }
  }

  public focus() {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  public updateFuse({ items, searchKeys }: SearchableProps = this.props) {
    this.fuse = new Fuse(items || [], {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      keys: searchKeys || ["name"],
    });
  }

  public openOptions = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ open: true }, () => {});

    if (this.props.onFocus) this.props.onFocus(e);
  };

  public closeOptions = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ open: false });
    if (this.props.onBlur) this.props.onBlur(e);
  };

  public isSearching = () => this.state.search.length > 0;

  public getListCollection() {
    const { items, extraItems } = this.props;
    let collection = [];

    if (this.isSearching()) {
      collection = this.state.matches;
    } else {
      collection = items || [];
    }

    if (extraItems) {
      collection = collection.concat(extraItems);
    }

    return collection;
  }

  public doSearch(setState: boolean = true) {
    let matches = [];

    let focused = this.state.focused;

    if (this.isSearching()) {
      const { search } = this.state;

      const { search: searchFn, items } = this.props;

      if (searchFn) {
        if (items) {
          matches = items.filter(item => searchFn(search, item));
        }
      } else if (this.fuse) {
        matches = this.fuse.search(search);
      }

      if (focused && !matches.find(p => focused && p.id === focused.id)) {
        focused = matches[0];
      }
    }

    const changes = { matches: _.uniqBy(matches, "id"), focused };

    if (setState) {
      this.setState(changes);
    }

    return changes;
  }
  public canCreate = () => !!this.props.createOption;
  public changeFocus = (direction: "up" | "down", collection?: Item[], setState: boolean = true) => () => {
    if (!collection) {
      collection = this.getListCollection();
    }

    const { focused } = this.state;

    if (this.canCreate()) {
      collection = [...collection, { id: "create" }];
    }

    if (collection.length === 0) return;

    let item;

    if (focused) {
      const currentIndex = collection.findIndex(i => i.id === focused.id);

      if (direction === "up") {
        if (currentIndex === 0) {
          item = collection[collection.length - 1];
        } else {
          item = collection[currentIndex - 1];
        }
      } else {
        if (currentIndex === collection.length - 1) {
          item = collection[0];
        } else {
          item = collection[currentIndex + 1];
        }
      }
    } else {
      item = collection[0];
    }

    const changes = { focused: item };

    if (setState) {
      this.setState(changes);
    }

    return changes;
  };

  public scrollToFocusedItem = (item: HTMLElement | null) => {
    if (item) {
      scrollIntoViewIfNeeded(item);
    }
  };

  public selectItem(item: Item | null = this.state.focused) {
    if (!item || (this.props.selectedItem && item.id === this.props.selectedItem)) {
      return;
    }
    if (item.id === "create") {
      return this.handleCreate();
    }

    const { onChange, clearSearchOnSelect, keepFocusOnSelect, keepCurrentSearch } = this.props;

    if (onChange) onChange(item);

    const replacedText = !!keepCurrentSearch ? this.state.search : item.name;

    this.setState(
      {
        search: clearSearchOnSelect ? "" : replacedText,
      },

      () => {
        if (this.inputRef) {
          if (keepFocusOnSelect) {
            setTimeout(() => this.inputRef && this.inputRef.focus(), 0);
          } else {
            this.inputRef.blur();
          }
        }

        this.doSearch();
      },
    );
  }

  public getSelectedItem(): Item | null {
    const { selectedItem, items, extraItems, fallbackItem } = this.props;

    let item;

    if (items && selectedItem) {
      item = items.concat(extraItems).find(item => item && item.id === selectedItem);
    }

    return item || fallbackItem || null;
  }
  public searchChanged = (search: string = "", callback?: () => any) => {
    this.setState({ search }, () => {
      if (callback) {
        callback();
      }

      this.doSearch();
    });
  };
  public handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    this.searchChanged(val);
    if (this.props.onSearchChange) {
      this.props.onSearchChange(val);
    }
  };
  public focusInput = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };
  public blurInput = () => {
    if (this.inputRef) {
      this.inputRef.blur();
    }
  };
  public clearSearch = () => {
    this.searchChanged("", this.deferredFocus);
  };
  public deferredFocus = () => {
    setTimeout(this.focusInput, 100);
  };
  public handleCreate = () => {
    setTimeout(() => {
      this.setState({ creating: true, open: false });
    }, 200);
  };
  public handleDoneCreating = (newItem: Item) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(newItem);
    }

    this.setState({ creating: false, search: "" });
    this.doSearch();
  };
  public shortcuts = [
    shortcut("esc", () => this.blurInput()),

    shortcut(["up", "shift+tab"], () => this.changeFocus("up")()),

    shortcut(["down", "tab"], () => this.changeFocus("down")()),

    {
      combo: "enter",
      event: "keyup",
      handler: (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        this.selectItem();
      },
    },
  ];

  public render() {
    const {
      className,
      inputClassName,
      placeholder = "Search here...",
      direction = "down",
      compact,
      optionsYOffset,
      optionsXOffset,
      children,
      items,
      loadingOnNull,
      renderItem,
      createOption,
      emptyMessage = "No items available",
      groups,
    } = this.props;
    const { open, focused, search, creating } = this.state;

    const clearSearch = this.isSearching() ? (
      <div className={styles.message}>
        <Text.P3 kind={"reverse"} className={styles.clearMessageText}>
          <span className={styles.clear} onMouseDown={this.clearSearch}>
            {compact ? "See all" : "Clear your search"}
          </span>
          {!compact && <span> to see all options</span>}
        </Text.P3>
      </div>
    ) : null;

    const collection = this.getListCollection();

    const getViews = (coll: Item[] | null) => {
      return (
        coll &&
        coll.map((item, index) => {
          const itemIsFocused = item === focused;
          const { id, name } = item;
          return (
            <ListItem
              key={id + "-" + index}
              onMouseDown={() => this.selectItem(item)}
              focused={itemIsFocused}
              itemRef={open && itemIsFocused ? this.scrollToFocusedItem : null}
            >
              {typeof renderItem === "function" ? renderItem(item) : name}
            </ListItem>
          );
        })
      );
    };

    let createOptionView = null;

    if (createOption) {
      const createIsFocused = focused ? focused.id === "create" : false;

      createOptionView = (
        <ListItem
          key={"create"}
          focused={createIsFocused}
          onMouseDown={this.handleCreate}
          itemRef={createIsFocused ? this.scrollToFocusedItem : null}
        >
          {createOption.createText(search)}
        </ListItem>
      );
    }

    let dropdownContent;

    if (!items && loadingOnNull) {
      dropdownContent = <LoadingContainer className={styles.loader} message={""} />;
    } else if (collection.length === 0) {
      if (createOptionView) {
        dropdownContent = <ListView>{createOptionView}</ListView>;
      } else {
        dropdownContent = (
          <div className={styles.emptyMessage}>
            <Text.P variant={compact ? "v4" : "v2"} kind="tertiary">
              {emptyMessage}
            </Text.P>
          </div>
        );
      }
    } else {
      let itemViews;

      if (groups) {
        const groupedItems = _.groupBy(collection, "group");

        itemViews = groups.map(({ key, label, icon, onFetchMore, loading }) => {
          return (
            <ListGroup
              key={key}
              name={
                <div className={styles.groupHeaderName}>
                  <Icon src={icon} size={18} fill={"white"} />
                  {label}
                </div>
              }
              headerTop={0}
              headerClassName={styles.groupHeader}
              className={loading ? styles.pulse : ""}
            >
              {getViews(groupedItems[key])}
              {onFetchMore && (
                <ListItem
                  key={"viewMore"}
                  onMouseDown={e => {
                    e.preventDefault();
                    if (onFetchMore) onFetchMore();
                  }}
                >
                  View more...
                </ListItem>
              )}
            </ListGroup>
          );
        });
      } else {
        itemViews = getViews(collection);
      }

      dropdownContent = (
        <ListView>
          {itemViews}
          {createOptionView}
        </ListView>
      );
    }

    const content = children({
      inputProps: {
        value: open ? search : _.get(this.getSelectedItem(), "name", ""),
        ref: ref => (this.inputRef = ref),
        onFocus: this.openOptions,
        onBlur: this.closeOptions,
        onChange: this.handleSearchChange,
        onMouseDown: e => {
          if (open) {
            setTimeout(() => {
              if (this.inputRef) this.inputRef.blur();
            }, 200);
          }
        },
        className: cx(styles.searchInput, inputClassName),
        placeholder,
      },

      isOpen: open,
      focusInput: this.focusInput,
      openOptions: this.openOptions,
      closeOptions: this.closeOptions,
    });

    let customOptionsStyle = {};

    if (optionsXOffset) {
      customOptionsStyle = {
        ...customOptionsStyle,
        left: `${optionsXOffset}px`,
      };
    }

    if (optionsYOffset) {
      customOptionsStyle = {
        ...customOptionsStyle,
        [direction === "up" ? "bottom" : "top"]: `calc(100% + ${optionsYOffset}px)`,
      };
    }

    return (
      <Shortcuts shortcuts={this.shortcuts} enabled={open} element={this.inputRef}>
        <div className={cx(styles.container, className)}>
          {content}
          {creating && createOption ? createOption.render({ done: this.handleDoneCreating, search }) : null}
          <div
            className={cx(
              styles.options,
              open && styles.openOptions,
              direction === "up" ? styles["direction-up"] : styles["direction-down"],
            )}
            style={customOptionsStyle}
          >
            {direction === "up" && clearSearch}
            <div className={styles.optionsContent}>{dropdownContent}</div>
            {direction === "down" && clearSearch}
          </div>
        </div>
      </Shortcuts>
    );
  }
}
