import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  active?: boolean;
  className?: string;
}

export default function RoundButton({ children, active, className }: Props) {
  return <div className={cx(styles.roundButton, active && styles.active, className)}>{children}</div>;
}
