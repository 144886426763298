import { graphql } from "@apollo/client/react/hoc";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { RouteComponentProps, Route, Switch, Redirect } from "react-router-dom";

import Text from "components/LegacyText";
import { stringifyQuery, getUrlQueries, getProgramIdFromSearch } from "utilities/routes";
import { fromBase64, toBase64 } from "utilities/base64";
import SearchableBigSelect from "components/LegacySearchable/SearchableBigSelect";

import reportsQuery from "./reports.gql";
import styles from "./styles.scss";

import DashboardPlaceholder from "./DashboardPlaceholder";
import ReportDashboard, { IReportDashboard } from "./ReportDashboard";
import { Viewport } from "./ReportDashboard/viewport";

type ReportDashboardCollection = "reports" | "financials";

interface Props extends RouteComponentProps {
  data: {
    loading: boolean;
    reportDashboards: IReportDashboard[];
  };

  collection: ReportDashboardCollection;
}

interface State {
  viewport?: Viewport | null;
}

class DashboardCollection extends React.Component<Props, State> {
  readonly state: State = {
    viewport: undefined,
  };

  public componentDidMount() {
    this.updateViewport();
  }

  public componentDidUpdate({ location: { search: oldSearch } }: Props) {
    const {
      location: { search },
    } = this.props;

    if (search !== oldSearch) {
      this.updateViewport();
    }
  }

  public updateViewport() {
    const {
      location: { search },
    } = this.props;
    const { v } = getUrlQueries(search);

    if (v) {
      this.setState({
        viewport: JSON.parse(fromBase64(v)),
      });
    } else {
      this.setState({ viewport: null });
    }
  }
  public handleViewportChange = ({ aggregation, daterange, filters }: Viewport) => {
    const { location } = this.props;

    const viewport = toBase64(
      JSON.stringify({
        aggregation,
        daterange,
        filters: filters ? filters.filter(f => f.name !== "Program" && f.name !== "Client") : [],
      }),
    );

    const program = getProgramIdFromSearch(location.search);

    this.props.history.replace({
      ...location,
      search: stringifyQuery({ v: viewport, program }),
    });
  };

  public handleDashboardUnavailable = (key: string) => {
    const {
      data: {
        reportDashboards: [firstDashboard],
      },

      match,
      history,
    } = this.props;

    if (firstDashboard.key !== key) {
      history.replace(`${match.url}/${firstDashboard.key}`);
    }
  };

  public renderContent() {
    const {
      data: { loading, reportDashboards: dashboards },
      collection,
      match,
      location,
    } = this.props;
    const { viewport } = this.state;

    if (!loading && dashboards && dashboards.length < 1) {
      return (
        <div className={styles.noReports}>
          <Text.Display2>No reports yet</Text.Display2>
          <Text.P2 className={styles.noReportsExplanation}>
            There isn't enough data to show a report. Come back later!
          </Text.P2>
        </div>
      );
    }

    if (loading || !dashboards || viewport === undefined) {
      return (
        <React.Fragment>
          {collection !== "financials" && (
            <div className={styles.placeholderTabs}>
              <TabPlaceholder active />
              <TabPlaceholder />
              <TabPlaceholder />
            </div>
          )}

          <DashboardPlaceholder />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Switch>
          <Route
            path={`${match.path}/:dashboard`}
            render={(props: RouteComponentProps<{ dashboard: string }>) => {
              let { viewport } = this.state;
              const dashboard = dashboards.find(d => d.key === props.match.params.dashboard);

              if (!viewport) {
                viewport = dashboard ? dashboard.viewport.original : null;
              }

              return (
                <>
                  <div className={styles.reportSelectDropdownHelpTextContainer}>
                    <p className={styles.reportSelectDropdownHelpText}>New! Select a Report from this dropdown.</p>
                    <p>
                      New reports coming soon!{" "}
                      <button className={`intercom-selector ${styles.learnAboutCustomReportsBtn}`}>
                        Ask us about custom reports
                      </button>
                    </p>
                  </div>

                  <SearchableBigSelect
                    testId={"periscope-report-select-dropdown"}
                    className={styles.reportSelectDropdown}
                    clearSearchOnSelect={true}
                    items={dashboards.map(d => ({ id: d.key, name: d.title }))}
                    selectedItem={dashboard?.key}
                    onChange={item => {
                      props.history.push({
                        ...props.location,
                        pathname: props.location.pathname.replace(
                          new RegExp(`${props.match.params.dashboard}$`),
                          item.id,
                        ),
                      });
                    }}
                    optionsYOffset={1}
                    selectProps={{
                      name: "Select Report",
                      compact: false,
                      inverse: true,
                    }}
                  />
                  <ReportDashboard
                    {...props}
                    viewport={viewport}
                    onViewportChange={this.handleViewportChange}
                    onDashboardUnavailable={this.handleDashboardUnavailable}
                  />
                </>
              );
            }}
          />

          <Redirect to={{ ...location, pathname: `${match.url}/${dashboards[0].key}` }} />
        </Switch>
      </React.Fragment>
    );
  }

  public render() {
    return <div className={styles.container}>{this.renderContent()}</div>;
  }
}

export default graphql<any, any, any, any>(reportsQuery, {
  options: ({ collection, location }: any) => {
    const id = getProgramIdFromSearch(location.search);

    return {
      variables: {
        programIds: id === "all" ? null : id.split(","),
        collection,
      },
    };
  },
})(DashboardCollection);

const TabPlaceholder = ({ active }: { active?: boolean }) => (
  <ContentLoader
    width={1000}
    height={1000}
    speed={2}
    primaryColor={"rgba(0, 0, 0, 0.03)"}
    secondaryColor={"rgba(0, 0, 0, 0.08)"}
    preserveAspectRatio={"none"}
  >
    <rect x="0" y="0" rx="50" ry="50" width="1000" height="533" />
    {active && <rect x="0" y="850" rx="0" ry="0" width="1000" height="150" />}
  </ContentLoader>
);
