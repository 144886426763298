import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";
import { useUserInfo } from "modules/Dashboard/UserInfo";

interface Props {
  id: string;
  children: ReactNode;
  className?: string;
  gigId?: string;
  isOwner?: boolean;
}

export function UserLink({ id, children, className, gigId, isOwner }: Props) {
  const { location, history } = useRouter();
  const { me, impersonator } = useUserInfo();

  return (
    <Link
      className={className}
      onClick={
        (me && me.isAdmin) || impersonator
          ? e => {
              if (e.altKey) {
                e.preventDefault();

                history.push({
                  ...location,
                  pathname: `${location.pathname}/+switch-user/${id}`,
                });
              }
            }
          : undefined
      }
      to={{
        ...location,
        pathname: `${location.pathname}/+user-profile/${id}/contact${gigId ? "/" + gigId + "/" : ""}${
          isOwner ? "/owner" : ""
        }`,
      }}
    >
      {children}
    </Link>
  );
}
