import statuses from "./statuses.scss";
import { GigsStatusFragment } from "gql-gen";

type GigStateGroup = keyof typeof statuses;

export function getStateGroupClassName(data: GigsStatusFragment) {
  return statuses[
    (data?.finalVerification?.isVerified ? "finalVerified" : data.workflowStateGroup) as keyof typeof statuses
  ];
}
