import * as React from "react";
import ContentLoader from "react-content-loader";
import styles from "./styles.scss";

export default () => (
  <div className={styles.placeholder}>
    <div className={styles.placeholderFilters} />

    <SheetPlaceholder />
    <SheetPlaceholder />
  </div>
);

const SheetPlaceholder = () => (
  <React.Fragment>
    <div className={styles.placeholderRow}>
      <ChartPlaceholder />
      <ChartPlaceholder />
    </div>

    <div className={styles.placeholderRow}>
      <WideChartPlaceholder />
    </div>

    <div className={styles.placeholderRow}>
      <WideChartPlaceholder />
    </div>
  </React.Fragment>
);

const ChartPlaceholder = () => (
  <ContentLoader
    width={1000}
    height={1000}
    speed={2}
    primaryColor={"rgba(0, 0, 0, 0.03)"}
    secondaryColor={"rgba(0, 0, 0, 0.08)"}
    preserveAspectRatio={"none"}
  >
    <rect x="0" y="0" rx="16" ry="16" width="500" height="100" />
    <rect x="0" y="150" rx="16" ry="16" width="1000" height="850" />
  </ContentLoader>
);

const WideChartPlaceholder = () => (
  <ContentLoader
    width={1000}
    height={1000}
    speed={2}
    primaryColor={"rgba(0, 0, 0, 0.03)"}
    secondaryColor={"rgba(0, 0, 0, 0.08)"}
    preserveAspectRatio={"none"}
  >
    <rect x="0" y="0" rx="8" ry="15" width="250" height="100" />
    <rect x="0" y="150" rx="8" ry="15" width="880" height="850" />
    <rect x="890" y="150" rx="8" ry="15" width="110" height="250" />
  </ContentLoader>
);
