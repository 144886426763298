import * as React from "react";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import arrowDown from "assets/chevron.svg";
import styles from "./select.scss";
import variables from "styles/variables";

export interface BigSelectProps {
  name: string;
  className?: string;
  compact?: boolean;
  useText?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  inverse?: boolean;
  testId?: string;
}

export default function BigSelect({
  name,
  compact,
  children,
  useText,
  className,
  onClick,
  inverse = false,
  testId,
}: BigSelectProps) {
  let content = children;

  if (typeof children === "string" || useText) {
    if (compact) {
      content = (
        <Text.Display4 inline kind={"reverse"}>
          {children}
        </Text.Display4>
      );
    } else {
      content = (
        <Text.Display3 inline kind={"reverse"} className={styles.contentText}>
          {children}
        </Text.Display3>
      );
    }
  }

  return (
    <div onClick={onClick} className={className} data-test={testId}>
      <div className={compact ? styles.compactTop : styles.top}>
        {content}
        <Icon src={arrowDown} size={15} fill={inverse ? variables.gray1 : "#fff"} />
      </div>
      {!compact && (
        <div>
          <div className={inverse ? styles.inverseBorder : styles.border} />
          <Text.Label1 inverse={!inverse}>{name}</Text.Label1>
        </div>
      )}
    </div>
  );
}
