import * as React from "react";
import DropdownMenu from "components/DropdownMenu";
import Text from "components/LegacyText";
import cx from "classnames";
import styles from "./styles.scss";

export interface MessageProps {
  pRef?: (param0: HTMLParagraphElement | null) => void;
  children: React.ReactNode;
  className?: string;
  inline?: boolean;
  explanation?: React.ReactNode;
  kind?: "neutral" | "success" | "error" | "info";
}

export default function Message({ children, inline, explanation, className, pRef, kind = "neutral" }: MessageProps) {
  const message = (
    <p ref={pRef} className={cx(styles.message, styles[kind], { inline }, className)}>
      {children}
    </p>
  );

  if (explanation) {
    return (
      <DropdownMenu
        showArrow={false}
        triggerOnHover={true}
        renderMenu={() => <Explanation>{explanation}</Explanation>}
        menuClassName={styles.menu}
      >
        <div className={styles.messageAndExplanation}>
          {message}
          <p className={styles.why}>Why?</p>
        </div>
      </DropdownMenu>
    );
  }

  return message;
}

function Explanation({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.explanation}>
      <Text.P3 kind={"reverse"}>{children}</Text.P3>
    </div>
  );
}
