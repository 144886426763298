export interface ArrayStateFunctions<T> {
  /** Adds an item at the end of the array */
  push: (item: T) => T[];
  /** Removes an item from the array */
  removeIndex: (index: number) => T[];
  /** Sets the value of an item */
  setIndex: (index: number, newItem: T) => T[];
  /** Sets new state of whole array */
  setArray: (items: T[]) => T[];
}

/**
 * Utility functions for managing an immutable array state
 */
export function useArrayState<T>(array: T[], setArray: (a: T[]) => void): ArrayStateFunctions<T> {
  const setAndReturn = (newArray: T[]) => {
    setArray(newArray);
    return newArray;
  };

  return {
    push: item => setAndReturn([...array, item]),
    removeIndex: index => setAndReturn(array.filter((_, i) => index !== i)),
    setIndex: (index, item) => setAndReturn([...array.slice(0, index), item, ...array.slice(index + 1)]),
    setArray: items => setAndReturn(items),
  };
}
