import * as React from "react";

interface Props {
  value: number;
  symbol?: string;
  decimalsClassName?: string;
}

export default function Money({ value, symbol = "$", decimalsClassName }: Props) {
  const [base, decimals] = splitNumber(value);

  return (
    <React.Fragment>
      {symbol}
      {base}
      {DECIMAL_SEPARATOR}
      <small className={decimalsClassName}>{decimals}</small>
    </React.Fragment>
  );
}

export function InvoiceItem({ value, symbol = "$", decimalsClassName }: Props) {
  if (value < 0) {
    return (
      <React.Fragment>
        (<Money value={value * -1} symbol={symbol} decimalsClassName={decimalsClassName} />)
      </React.Fragment>
    );
  }
  return <Money value={value} symbol={symbol} decimalsClassName={decimalsClassName} />;
}

export const DECIMAL_SEPARATOR = (/^1(.+)1$/.exec((1.1).toLocaleString()) || ["."])[1];

export function splitNumber(num: number) {
  return (num || 0)
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .split(DECIMAL_SEPARATOR);
}
