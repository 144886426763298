import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowButton from "components/ArrowButton";
import Clickable from "components/Clickable";
import Text, { TextProps } from "components/Text";
import { MonthView } from "../MonthView";
import { YearView } from "../YearView";
import { YearsView, YEARS_PER_PAGE } from "../YearsView";
import styles from "./styles.scss";
import { FieldProps } from "formik";
import { subMonths } from "date-fns";

type Period = "day" | "month" | "year";

interface DayPickerProps {
  value?: Date | null;
  dual?: boolean;
  onRangeChanged?: (date: Date) => void;
  onChange?: (value: Date) => void;
  pick?: Period;
  min?: Date;
  max?: Date;
  testId?: string;
}

export function CalendarPicker({
  value,
  onChange,
  onRangeChanged,
  pick = "day",
  dual,
  min,
  max,
  testId,
}: DayPickerProps) {
  const { t } = useTranslation();

  const [range, _setRange] = useState(value || new Date());
  const [picking, setPicking] = useState<Period>(pick);

  const setRange = onRangeChanged
    ? (range: Date) => {
        _setRange(range);
        onRangeChanged(range);
      }
    : _setRange;

  const month = range.getMonth();
  const year = range.getFullYear();

  const go = (val: 1 | -1) => (e: React.MouseEvent | React.KeyboardEvent) => {
    if (picking === "year") {
      setRange(new Date(year + YEARS_PER_PAGE * val, 1, 1));
    } else if (e.altKey || picking === "month") {
      setRange(new Date(year + val, month, 1));
    } else {
      setRange(new Date(year, month + val, 1));
    }
  };

  function Range({ next }: { next?: boolean }) {
    let yearN = year;
    let monthN = month;

    if (next) {
      const d = new Date(yearN, monthN + 1, 1);
      yearN = d.getFullYear();
      monthN = d.getMonth();
    }

    return (
      <div className={styles.range}>
        {picking === "day" && (
          <Clickable
            actionLabel={t("components.calendar.pickMonth")}
            onClick={() => setPicking("month")}
            testId="components.calendar.selectMonth"
          >
            <RangeLabel>{t(`components.calendar.months.${monthN + 1}.long`)}</RangeLabel>
          </Clickable>
        )}

        {picking === "year" ? (
          <RangeLabel underline={"never"}>
            {yearN} - {yearN + YEARS_PER_PAGE - 1}
          </RangeLabel>
        ) : (
          <Clickable
            actionLabel={t("components.calendar.pickYear")}
            onClick={() => setPicking("year")}
            testId="components.calendar.selectYear"
          >
            <RangeLabel>{yearN}</RangeLabel>
          </Clickable>
        )}
      </div>
    );
  }

  return (
    <div className={styles.dayPicker} data-test={testId}>
      <div className={styles.navigator}>
        <ArrowButton
          direction={"back"}
          actionLabel={t("components.calendar.prevMonth")}
          onClick={go(-1)}
          testId="components.calendar.prevMonth"
        />
        <Range />
        {dual && (
          <>
            <div />
            <Range next />
          </>
        )}
        <ArrowButton
          direction={"forward"}
          actionLabel={t("components.calendar.nextMonth")}
          onClick={go(1)}
          testId="components.calendar.nextMonth"
        />
      </div>

      <div className={styles.view}>
        {picking === "day" ? (
          <MonthView min={min} max={max} month={month} year={year} value={value} onChange={onChange} />
        ) : picking === "month" ? (
          <YearView
            year={year}
            value={range}
            onChange={value => {
              if (pick === "month") {
                if (onChange) onChange(value);
              } else {
                setPicking("day");
              }
              setRange(value);
            }}
          />
        ) : (
          <YearsView
            year={year}
            onPick={value => {
              if (pick === "year") {
                if (onChange) onChange(new Date(value, 0, 1));
              } else {
                setPicking("month");
              }
              setRange(new Date(value, month));
              setPicking("month");
            }}
          />
        )}

        {dual && (
          <MonthView
            min={min}
            max={max}
            month={month + 1}
            year={year}
            value={value}
            onChange={d => {
              if (onChange) onChange(d);
              setRange(subMonths(d, 1));
            }}
          />
        )}
      </div>
    </div>
  );
}

const RangeLabel = (props: TextProps) => <Text font={"wes"} bold size={14} underline={"hover"} noSelect {...props} />;

export function FDayPicker({ field, form, ...props }: FieldProps & Omit<DayPickerProps, "onChange" | "value">) {
  return (
    <>
      <CalendarPicker value={field.value} onChange={date => form.setFieldValue(field.name, date)} {...props} />

      {form.touched[field.name] && form.errors[field.name] && (
        <Text size={12} color={"pink1"} top={"s"}>
          {form.errors[field.name]}
        </Text>
      )}
    </>
  );
}
