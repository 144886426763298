import * as React from "react";
import Lightbox from "react-images";
import Spinner from "components/Spinner";
import FileOptions from "components/FileOptions";
import { transformImage, getFsId } from "utilities/filestack";
import { ImageGroupContext, ImageInfo, IImageGroupContext } from "./context";

interface Props {
  children: React.ReactNode;
}

interface State {
  currentImage: number | null;
}

export default class ImageGroup extends React.Component<Props, State> {
  readonly state: State = {
    currentImage: null,
  };

  private images: ImageInfo[] = [];

  private addImage = (image: ImageInfo) => {
    if (!this.images.find(i => i.src === image.src)) {
      this.images.push(image);
    }
  };

  private removeImage = (image: ImageInfo) => {
    this.images = this.images.filter(i => i.src !== image.src);
  };

  private openImage = (image: ImageInfo) => {
    this.setState({
      currentImage: this.images.findIndex(i => i.src === image.src),
    });
  };

  private close = () => {
    this.setState({ currentImage: null });
  };

  private jump = (backwards: boolean) => () => {
    const { currentImage } = this.state;

    let newImage = 0;

    if (currentImage || currentImage === 0) {
      if (backwards) {
        newImage = currentImage > 0 ? currentImage - 1 : this.images.length - 1;
      } else {
        newImage = currentImage < this.images.length - 1 ? currentImage + 1 : 0;
      }
    }

    this.setState({ currentImage: newImage });
  };

  private handleThumbClick = (index: number) => {
    this.setState({ currentImage: index });
  };

  private getImages() {
    return this.images.map(image => {
      const id = getFsId(image.src);

      return {
        id,
        caption: image.caption,
        src: id ? transformImage(id) : image.src,
        thumbnail: id ? transformImage(id, { resize: { width: 50, fit: "max" } }) : image.src,
        fileName: image.fileName,
      };
    });
  }

  public render() {
    const { currentImage } = this.state;
    const { children } = this.props;

    const context: IImageGroupContext = {
      addImage: this.addImage,
      removeImage: this.removeImage,
      openImage: this.openImage,
    };

    const images = this.getImages();

    const currentObj = images[currentImage || 0];

    return (
      <ImageGroupContext.Provider value={context}>
        <Lightbox
          images={images}
          isOpen={currentImage !== null}
          currentImage={currentImage || undefined}
          onClose={this.close}
          onClickPrev={this.jump(true)}
          onClickNext={this.jump(false)}
          onClickThumbnail={this.handleThumbClick}
          spinner={() => <Spinner color={"white"} />}
          showThumbnails={true}
          closeButtonTitle={"Close (⎋)"}
          leftArrowTitle={"Previous (←)"}
          rightArrowTitle={"Next (→)"}
          customControls={
            currentObj && currentObj.id
              ? ([
                  <FileOptions
                    key={"controls"}
                    fileId={currentObj.id}
                    caption={currentObj.caption}
                    fileName={currentObj.fileName}
                  />,
                ] as any)
              : undefined
          }
        />

        {children}
      </ImageGroupContext.Provider>
    );
  }
}
