import { useState, useEffect, useRef, useMemo } from "react";
import { InfiniteLoader, Index, IndexRange } from "react-virtualized";
import { useApolloClient, ApolloQueryResult, DocumentNode } from "@apollo/client";

const lowPri =
  "requestIdleCallback" in window ? (window as any).requestIdleCallback : (cb: () => void) => setTimeout(cb, 500);

export function useInfiniteLoader({
  query,
  variables,
  entryName,
  initialCount,
  sendFirstQuery,
}: {
  query: DocumentNode;
  variables: any;
  entryName: string;
  initialCount: number;
  sendFirstQuery?: boolean;
}) {
  const loadingRanges = useRef<Set<number>>(new Set());

  const [loaded, setLoaded] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<null | number>(null);

  const [latestResult, setLatestResult] = useState<ApolloQueryResult<any> | null>(null);
  const client = useApolloClient();

  const loaderRef = useRef<InfiniteLoader>(null);

  const loadRows = async (range: IndexRange, clear?: boolean) => {
    const { startIndex, stopIndex } = range;

    if (clear && loaderRef.current) {
      loaderRef.current.resetLoadMoreRowsCache();
    }

    for (let x = startIndex; x <= stopIndex; x++) {
      loadingRanges.current.add(x);
    }

    setLatestResult(null);

    const result = await client.query({
      query,
      variables: {
        ...variables,
        first: stopIndex - startIndex + 1,
        offset: startIndex,
        skipPageInfo: !clear && totalCount !== null,
      },
      fetchPolicy: "no-cache",
    });

    const { edges: loadedEdges, pageInfo } = result.data[entryName];

    setLoaded(loaded => {
      const newLoaded = loaded.slice(0);

      for (let i = 0, l = loadedEdges.length; i < l; i++) {
        newLoaded[startIndex + i] = loadedEdges[i];
      }

      return newLoaded;
    });

    if (pageInfo && "totalCount" in pageInfo) {
      setTotalCount(pageInfo?.totalCount);
    }

    setLatestResult(result);

    lowPri(() => {
      for (let x = startIndex; x <= stopIndex; x++) {
        loadingRanges.current.delete(x);
      }
    });
  };

  const reset = () => {
    setLoaded([]);
    setTotalCount(null);
    return loadRows({ startIndex: 0, stopIndex: initialCount }, true);
  };

  useEffect(() => {
    if (latestResult || sendFirstQuery) {
      reset();
    }
  }, [JSON.stringify(variables)]);

  const isRowLoaded = ({ index }: Index) => {
    return index in loaded || loadingRanges.current.has(index);
  };

  const reload = () => {
    const loader = loaderRef.current as any;

    if (loader) {
      return loadRows({ startIndex: loader._lastRenderedStartIndex, stopIndex: loader._lastRenderedStopIndex }, true);
    }

    return reset();
  };

  return { latestResult, loading: !latestResult, loaded, isRowLoaded, loadRows, reload, totalCount, loaderRef, reset };
}
