import { ApolloClient } from "@apollo/client";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";

import { User } from "interfaces/user";
import { OrganizationRouteParams } from "./Organization";
import userQuery from "./userQuery.gql";
import { flowRight as compose } from "lodash";

interface UserInfo {
  me: User | null;
  impersonator: User | null;
  loading: boolean;
}

export interface UserInfoQuery {
  userInfo: UserInfo;
}

export function useUserInfo(): UserInfo {
  const { data } = useQuery(userQuery, {
    /**
     * The cache becomes invalid when the org id in the URL changes
     */
    fetchPolicy: "no-cache",
  });

  return data || { me: null, impersonator: null, loading: true };
}

export function withUserInfo<P extends RouteComponentProps<OrganizationRouteParams>>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof UserInfoQuery>>> {
  return compose(
    withApollo,
    graphql<P & { client: ApolloClient<any> }, UserInfo, any, any>(userQuery, {
      options: props => {
        if (props.match) {
          return {
            variables: {
              organizationId: props.match.params.orgId,
            },
          };
        }
        return {};
      },
      props: ({ data, ownProps }) => {
        if (data) {
          let { me, loading, impersonator, error } = data;

          if (!me && !loading && !error && ownProps.client) {
            try {
              const cached = ownProps.client.readQuery({
                query: userQuery,
                variables: {
                  organizationId: ownProps.match.params.orgId,
                },
              });

              if (cached) {
                ({ me, loading, impersonator } = cached);
              }
            } catch (e) {
              console.warn("user not yet in cache");
            }
          }

          return { userInfo: { me, impersonator, loading } };
        }

        return { userInfo: { me: null, impersonator: null, loading: true } };
      },
    }),
  )(Component);
}

export function withUserInfoNoCache<P extends RouteComponentProps<OrganizationRouteParams>>(
  Component: React.ComponentType<P>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof UserInfoQuery>>> {
  return compose(
    withApollo,
    graphql<P & { client: ApolloClient<any> }, UserInfo, any, any>(userQuery, {
      options: props => {
        if (props.match) {
          return {
            variables: {
              organizationId: props.match.params.orgId,
            },
            fetchPolicy: "no-cache",
            context: {
              /* For a reason unknown, even though  we set fetchPolicy to no-cache, the browser still caches the response.
               * Because of that we need to set the headers for this request manually to prevent this strange behavior caused by Apollo Client.
               */
              headers: {
                "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
                Pragma: "no-cache",
                Expires: "0",
                "Surrogate-Control": "no-store",
              },
            },
          };
        }
        return {};
      },
      props: ({ data, ownProps }) => {
        if (data) {
          let { me, loading, impersonator, error } = data;

          if (!me && !loading && !error && ownProps.client) {
            try {
              const cached = ownProps.client.readQuery({
                query: userQuery,
                variables: {
                  organizationId: ownProps.match.params.orgId,
                },
              });

              if (cached) {
                ({ me, loading, impersonator } = cached);
              }
            } catch (e) {
              console.warn("user not yet in cache");
            }
          }

          return { userInfo: { me, impersonator, loading } };
        }

        return { userInfo: { me: null, impersonator: null, loading: true } };
      },
    }),
  )(Component);
}
