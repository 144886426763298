import { ConfirmBoxPresentation } from "components/ConfirmBox";
import { SCREEN_WIDTH } from "utilities/screen";
import { IconSrc } from "../Icon";

export interface IAction {
  name: string;
  color?: string;
  icon?: IconSrc;
  description: string;
  multiple?: boolean;
  primary?: boolean;
  confirm?: (action: IAction, ids: string[]) => ConfirmBoxPresentation;
}
export type ActionCallback = (action: IAction) => void;

export const EXPANDER_WIDTH = 20;
export const ACTION_SIZE = 52;
export const LIST_ACTION_SIZE = 84;

export const getActionsProps = (actions: IAction[], listMode: boolean, expanded: boolean = false) => {
  let drawerSize = 0;
  let wrapperSize = 0;
  let actionSize = ACTION_SIZE;
  let expanderVisible = false;

  const actionsLength = actions.length;

  if (actionsLength > 0) {
    if (listMode) {
      expanderVisible = true;
      wrapperSize = EXPANDER_WIDTH;
      actionSize = LIST_ACTION_SIZE;

      if (expanded) {
        drawerSize = actionsLength * (LIST_ACTION_SIZE + 1);

        if (drawerSize > SCREEN_WIDTH) {
          actionSize = (SCREEN_WIDTH - EXPANDER_WIDTH) / actionsLength;
          drawerSize = SCREEN_WIDTH;
        }
      }
    } else {
      const primaryActions = actions.filter(a => a.primary).length;
      const primaryActionsWidth = primaryActions * (ACTION_SIZE + 1);

      if (expanded) {
        drawerSize = actionsLength * (ACTION_SIZE + 1);
      } else {
        drawerSize = primaryActionsWidth;
      }

      if (primaryActions < actionsLength) {
        expanderVisible = true;
      }

      wrapperSize = primaryActionsWidth + (expanderVisible ? EXPANDER_WIDTH : 0);
    }
  }

  return {
    expanderVisible,
    drawerSize,
    wrapperSize,
    actionSize,
  };
};
