import * as React from "react";
import cx from "classnames";
import StatsItem from "./StatsItem";
import styles from "./styles.scss";

interface Props {
  children: (React.ReactElement<typeof StatsItem> | null)[] | null;
  className?: string;
  loading: boolean;
  size?: "small" | "medium" | "large";
}

export default function StatsBoard({ children, loading, size = "medium", className }: Props) {
  return (
    <div className={cx(styles.statsBoard, styles[size], loading && styles.loadingStatsBoard, className)}>
      {children}
    </div>
  );
}
