import * as React from "react";

interface Props {
  ticks?: number;
  char?: string;
}

interface State {
  ticks: number;
  direction: number;
}

export default class DotDotDot extends React.Component<Props, State> {
  // In memory of Steve Pikelny
  readonly state: State = {
    ticks: 0,
    direction: -1,
  };
  private interval?: number;

  public componentDidMount() {
    this.interval = window.setInterval(() => {
      const { ticks: totalTicks = 3 } = this.props;
      const { ticks, direction } = this.state;

      const newDirection = ticks === totalTicks || ticks === 0 ? direction * -1 : direction;

      this.setState(state => ({
        ticks: state.ticks + newDirection,
        direction: newDirection,
      }));
    }, 200);
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
  }

  public render() {
    return (this.props.char || "·").repeat(this.state.ticks) || " ";
  }
}
