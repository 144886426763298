import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { FieldProps as FFieldProps } from "formik";
import { format } from "date-fns";
import BaseFormElement, { FieldProps } from "components/Form/BaseFormElement";
import Text from "components/Text";

import styles from "./styles.scss";
import { CalendarPicker } from "../DayPicker";

interface CalendarDropdownProps extends FieldProps {
  className?: string;
  testId: string;
  value: Date | null;
  onChange: (value: Date) => void;
  placeholder?: string;
}

export function CalendarDropdown({
  label,
  placeholder,
  errorMessage,
  className,
  disabled,
  testId,
  value,
  onChange,
}: CalendarDropdownProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLLabelElement>(null);
  const [open, setOpen] = useState<boolean>();

  useEffect(() => {
    if (!open) return;

    const handler = (e: Event) => {
      if (!(e.target instanceof Node) || !wrapperRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", handler);

    return () => document.body.removeEventListener("click", handler);
  }, [open]);

  return (
    <label ref={wrapperRef} className={styles.wrapper}>
      <BaseFormElement
        label={label}
        errorMessage={typeof errorMessage === "string" ? errorMessage : false}
        className={cx(open && styles.focused, className)}
        disabled={!!disabled}
        arrow={open ? "up" : "down"}
        testId={testId}
        element={
          <div className={styles.box} tabIndex={0} onFocus={() => setOpen(true)}>
            <Text font="lato" top="xxs" color={value ? "gray1" : "gray3"} size={14}>
              {value
                ? format(value, "EEEE, MMMM dd, yyyy")
                : placeholder ?? t("components.calendarDropdown.selectDate")}
            </Text>
          </div>
        }
      />

      {open && (
        <div className={styles.dropdown} onClick={e => e.stopPropagation()}>
          <CalendarPicker
            value={value}
            onChange={v => {
              onChange(v);
              setOpen(false);
            }}
          />
        </div>
      )}
    </label>
  );
}

export function FCalendarDropdown({
  field,
  form,
  ...props
}: FFieldProps & Omit<CalendarDropdownProps, "onChange" | "onBlur" | "value">) {
  return (
    <CalendarDropdown
      {...props}
      value={field.value}
      onChange={v => form.setFieldValue(field.name, v)}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
    />
  );
}
