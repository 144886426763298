import React from "react";
import { addDays, format, parseISO, isEqual } from "date-fns";
import { Expression, bin } from "utilities/knueppel";
import { CalendarPicker } from "components/Calendar/DayPicker";
import { FilterDefinition } from "modules/Connection/types";
import RoundedPill from "components/RoundedPill";
import Text from "components/Text";
import { RawDateState } from "modules/Dashboard/Schema/Gigs/Filters/Date/rawState";
import { useTranslation } from "react-i18next";
import styles from "./styles.scss";

interface RangeState {
  startDate?: Date;
  endDate?: Date;
}

type RangeField = "startdate" | "createdat" | "duedate" | "issueddate" | "updatedatdate";

const initState = {
  startDate: new Date(),
  endDate: addDays(new Date(), 15),
};

export const DateRangeFilter: (field: RangeField) => FilterDefinition<RangeState, RawDateState<RangeState>> = (
  field: RangeField,
) => ({
  component: ({ state, setState }) => {
    const { t } = useTranslation();

    return (
      <div className="flex">
        <div className={styles.side}>
          <Text font="wes" bold>
            {t("gigs.filters.date.range.startDate")}
          </Text>
          <CalendarPicker value={state.startDate} onChange={startDate => setState({ startDate })} />
        </div>
        <div className={styles.side}>
          <Text font="wes" bold>
            {t("gigs.filters.date.range.endDate")}
          </Text>
          <CalendarPicker value={state.endDate} onChange={endDate => setState({ endDate })} />
        </div>
      </div>
    );
  },

  initialState: () => initState,

  validate: ({ startDate, endDate }) => !!startDate && !!endDate,

  toAST: rangeToAST(field),

  stateComponent: ({ state, setState }) => {
    const { startDate, endDate } = state;
    return (
      <RoundedPill onRemove={() => setState({ ...state, startDate: undefined, endDate: undefined })}>
        {startDate && endDate
          ? isEqual(startDate, endDate)
            ? `${format(startDate, "MMM d")}`.toUpperCase()
            : `${format(startDate, "MMM d")} - ${format(endDate, "MMM d")}`.toUpperCase()
          : ""}
      </RoundedPill>
    );
  },

  parse: state => {
    return {
      ...state,
      startDate: state.startDate ? parseISO(state.startDate) : undefined,
      endDate: state.endDate ? parseISO(state.endDate) : undefined,
    };
  },
});

export function rangeToAST(field: RangeField) {
  return function (state: RangeState): Expression | null {
    const { startDate, endDate } = state;

    const fieldExpression: Expression = {
      type: "LogicalExpression",
      left: bin(field, ">=", format(startDate || new Date(), "yyyy-MM-dd")),
      operator: "&&",
      right: bin(field, "<=", format(endDate || new Date(), "yyyy-MM-dd")),
    };
    if (field !== "startdate") return fieldExpression;

    //Filtering windowed tasks by overlap with range. Only for startdate filter
    const windowExpression: Expression = {
      type: "LogicalExpression",
      left: bin("windowstartdate", "<=", format(endDate || new Date(), "yyyy-MM-dd")),
      operator: "&&",
      right: {
        type: "LogicalExpression",
        left: bin("windowenddate", "is", { type: "NullLiteral" }),
        operator: "||",
        right: bin("windowenddate", ">=", format(startDate || new Date(), "yyyy-MM-dd")),
      },
    };

    return {
      type: "LogicalExpression",
      left: fieldExpression,
      operator: "||",
      right: {
        type: "LogicalExpression",
        left: bin("startdate", "is", { type: "NullLiteral" }),
        operator: "&&",
        right: windowExpression,
      },
    };
  };
}
