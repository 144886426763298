import React, { useState, useEffect, useRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { wrap } from "comlink";
import useRouter from "use-react-router";
import { mapValues, range } from "lodash";
import { buildQuery } from "houbolt";
import LegacyModal from "components/LegacyModal";
import Text from "components/Text";

import { usePromise } from "hooks/usePromise";
import DropFile from "components/DropFile";
import { RoundedTab } from "components/RoundedTab";
import Clickable from "components/Clickable";
import Icon from "components/Icon";
import Pill from "components/Pill";
import cancelIcon from "assets/cancel-nobg.svg";
import excelIcon from "assets/file-excel.svg";
import { UploaderField } from "./Field";
import { Spreadsheet, SelectMode } from "./Spreadsheet";
import { Preview } from "./Preview";
import { defaultSource } from "./Field/Source";
import styles from "./styles.scss";
import { SpreadsheetPlaceholder } from "./Placeholder";
import { RangeSelect } from "./RangeSelect";
import { syncScroll } from "utilities/scroll";
import { SheetMap } from "./sources";
import { UIObjectConfig, WorkerObjectConfig, ObjectConfig, ConfigNoSections } from "./fields";
import { UploaderContext } from "./context";
import Button from "components/Button";
import ConfirmBox from "components/ConfirmBox";
import { MapResult } from "./Worker/map";
import { UploaderWorker, RawUploaderWorker } from "modules/Uploader/Worker";
import { SheetState, UploadState, BatchUploadResult } from "modules/Uploader/state";
import { downloadBlob } from "utilities/files";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import * as _ from "lodash";

interface Props {
  objectConfig: ObjectConfig;
  endpoint: string;
  getHeaders: () => {};
  title: string;
  onBatch: (result: MapResult, previousUploadId: string | null) => Promise<BatchUploadResult>;
  onDone: (uploadId: string | null, handleClose: () => void) => Promise<void>;
  confirmMessage: (count: number) => ReactNode;
  info?: ReactNode;
}

// when a fields type is section, we only want to take its children
// into account when displaying sheet/setting up the spreadsheet
const toSheetMap = (cfg: UIObjectConfig | ConfigNoSections): SheetMap => {
  return Object.entries(cfg).reduce((acc, [id, field]) => {
    if (field.type === "section") {
      return { ...acc, ...toSheetMap(field.children) };
    }
    return { ...acc, [id]: field.defaultSources || [defaultSource] };
  }, {});
};

const toUIObjectConfig = (cfg: UIObjectConfig): UIObjectConfig => {
  return Object.entries(cfg).reduce((acc, [id, field]) => {
    if (field.type === "section") {
      return { ...acc, ...toUIObjectConfig(field.children) };
    }
    return { ...acc, [id]: field };
  }, {});
};

export function Uploader({ objectConfig, endpoint, getHeaders, title, onBatch, onDone, confirmMessage, info }: Props) {
  const { t } = useTranslation();
  const spreadsheetRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const { history, location } = useRouter();
  const worker = useRef<UploaderWorker | null>(null);
  const [processingState, setProcessingState] = useState<"noDoc" | "reading" | "read" | UploadState | "error">("noDoc");
  const [sheets, setSheets] = useState<string[]>(["Sheet1"]);
  const [fileName, setFileName] = useState<string>();
  const [headerIndex, setHeaderIndex] = useState<number>(1);
  const [activeSheet, setActiveSheet] = useState<SheetState>({ name: "Sheet1", rowCount: null });
  const [selectState, setSelectState] = useState<{ mode: SelectMode; callback: (range: number) => void } | null>(null);
  const { me } = useUserInfo();
  const isAdmin = me?.isAdmin ?? false;
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  const [sheetMap, setSheetMap] = useState<SheetMap>(toSheetMap(objectConfig.ui));

  const [openSections, setOpenSections] = useState(
    new Set(
      Object.entries(objectConfig.ui)
        .filter(([_, val]) => val.type === "section" && val.open)
        .map(([key]) => key),
    ),
  );

  const [importConfirmation, setImportConfirmation] = useState(false);

  useEffect(() => {
    const actualWorker = new Worker("./Worker/index.ts");
    worker.current = wrap<RawUploaderWorker>(actualWorker);
    worker.current!.initClient(endpoint, getHeaders());

    return () => {
      actualWorker.terminate();
    };
  }, []);

  const sheetInfo = usePromise(
    activeSheet ? () => worker.current!.getSheetInfo({ sheet: activeSheet.name, columnNames: true }) : undefined,
    [activeSheet, fileName],
  );

  useEffect(() => {
    if (previewRef.current && spreadsheetRef.current) {
      return syncScroll(previewRef.current, spreadsheetRef.current);
    }
  }, [processingState, sheetInfo.state]);

  const handleFileSelected = (file: File) => {
    setProcessingState("reading");

    window.setTimeout(async () => {
      const fileStats = await worker.current!.loadFile(file);
      await worker.current!.read(0, 60);

      if (file.type !== "text/csv" && !file.name.endsWith(".csv") && file.size > 20 * 1024) {
        alert("Please use CSV for files bigger than 20kb");
        return;
      }

      const sheets = await worker.current!.sheetNames;
      setSheets(sheets);
      setActiveSheet({
        name: sheets[0],
        rowCount: fileStats?.rowCount ?? null,
      });
      setFileName(file.name);
      setProcessingState("read");
    }, 0);
  };

  const handleClose = () => {
    history.push({
      ...location,
      pathname: location.pathname.split("/+")[0],
    });
  };

  return (
    <LegacyModal className={styles.importer}>
      <UploaderContext.Provider
        value={{
          headerIndex,
          requestSelect: (mode, callback) => {
            setSelectState({ mode, callback });
          },
          sheetInfo: sheetInfo.result || null,
          sheetMap,
          objectConfig,
          isAdmin,
          openSections,
          setOpenSections,
        }}
      >
        <div className={styles.map}>
          <Clickable onClick={handleClose} className={styles.close}>
            <Icon src={cancelIcon} size={18} fill={"white"} />
          </Clickable>
          <div className={styles.mapContent}>
            <Text color="teal1" font="wes" size={30}>
              {title}
            </Text>

            {info && info}

            {isAdmin && (
              <div className={styles.headerSelect}>
                <Text color="white" font="wes" bold size={16} bottom="m">
                  {t(`importer.header`)}
                </Text>

                <RangeSelect
                  onSelectStart={() => setSelectState({ mode: "row", callback: range => setHeaderIndex(range) })}
                >
                  <Text color="white" top="m" left="m" size={16}>
                    {headerIndex}
                  </Text>
                </RangeSelect>
              </div>
            )}

            {Object.entries(objectConfig.ui).map(([id, config]) => {
              return (
                <UploaderField
                  field={config}
                  key={id}
                  id={id}
                  sources={sheetMap[id]}
                  onSourcesChange={fieldId => sources => {
                    setSheetMap({ ...sheetMap, [fieldId]: sources });
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.preview}>
          {processingState === "noDoc" ? (
            <DropFile
              title={t("importer.dragAndDrop")}
              content={t("importer.yourDocumentHere")}
              accept={["text/csv", ".csv"]}
              large
              onDrop={handleFileSelected}
              className={styles.dropZone}
            />
          ) : sheetInfo.result ? (
            <Spreadsheet
              ref={spreadsheetRef}
              sheetInfo={sheetInfo.result}
              headerIndex={headerIndex}
              selectMode={selectState && selectState.mode}
              onSelect={(selectState && selectState.callback) || undefined}
              onSelectionEnd={() => setSelectState(null)}
            />
          ) : (
            <SpreadsheetPlaceholder />
          )}

          <div className={styles.tabs}>
            {sheets.map(sheet => (
              <RoundedTab
                key={sheet}
                active={activeSheet.name === sheet}
                onClick={() => setActiveSheet({ name: sheet, rowCount: activeSheet.rowCount })}
              >
                {sheet}
              </RoundedTab>
            ))}

            <span className={"spacer"} />

            {fileName && (
              <Pill
                className={styles.fileName}
                onRemove={() => {
                  setProcessingState("noDoc");
                  setFileName("");
                }}
              >
                {fileName}
              </Pill>
            )}

            {importConfirmation && (
              <ConfirmBox
                icon={excelIcon}
                title={t("importer.confirm")}
                onNo={() => setImportConfirmation(false)}
                onYes={handleUpload}
              >
                {confirmMessage(20)}
              </ConfirmBox>
            )}

            <Button
              kind="greenGradient"
              disabled={typeof processingState === "number" || processingState === "noDoc"}
              onClick={() => setImportConfirmation(true)}
            >
              {t("importer.import")}
            </Button>
          </div>

          <Preview
            ref={previewRef}
            ready={processingState !== "noDoc" && sheetInfo.state === "resolved"}
            activeSheet={activeSheet}
            headerIndex={headerIndex}
            sheetMap={sheetMap}
            objectConfig={{ ...objectConfig, ui: toUIObjectConfig(objectConfig.ui) }}
            worker={worker.current}
            progress={typeof processingState !== "string" ? processingState : null}
            onDone={() => {
              onDone(typeof processingState !== "string" ? processingState.uploadId ?? null : null, handleClose);
            }}
            onDownloadFailedRows={handleDownloadFailedRows}
          />
        </div>
      </UploaderContext.Provider>
    </LegacyModal>
  );

  async function handleDownloadFailedRows() {
    if (typeof processingState !== "string") {
      const blob = await worker.current!.generateFailedRowsDocument(
        new Set([headerIndex - 1, ...processingState.erroredRows.range]),
        validationErrors,
      );
      downloadBlob(blob, `failed-${(fileName ?? "rows").split(".")[0]}.csv`);
    }
  }

  async function handleUpload() {
    previewRef.current!.scrollTo(0, 0);

    setImportConfirmation(false);

    try {
      const w = worker.current!;

      let startByte = 0;
      let uploadId: null | string = null;

      const batchSize = 60;

      let erroredRows: { range: number[]; count: number } = { range: [], count: 0 };
      let processed = 0;

      setProcessingState({
        processed,
        erroredRows,
        done: false,
      });

      while (true) {
        const readResult = await w.read(startByte, batchSize);

        if (readResult.length <= 0) break;

        startByte = readResult.endByte;

        const result = await w.map({
          sheet: activeSheet.name,
          offset: processed,
          header: processed > 0 ? -1 : headerIndex,
          fields: objectConfig.worker,
          map: sheetMap,
          preview: false,
        });

        try {
          const batchResult: BatchUploadResult | null = (await onBatch(result, uploadId)) || null;
          uploadId = batchResult.uploadId || null;
          erroredRows = {
            range: erroredRows.range.concat(batchResult?.erroredRows?.range ?? []),
            count: erroredRows.count,
          };
        } catch (err) {
          const errors = parseValidationErrors(err, t);
          setValidationErrors(errors);
          erroredRows = {
            range: erroredRows.range.concat(range(processed, processed + result.length)),
            count: errors.length,
          };
        }

        processed += result.length;

        setProcessingState({
          processed,
          erroredRows: { range: erroredRows.range.slice(1), count: erroredRows.count },
          uploadId,
          done: true,
        });

        await new Promise(resolve => setTimeout(resolve, 25));
      }
    } catch (err) {
      setProcessingState("error");
      throw err;
    }
  }
}

export function createObjectConfig(uiObjectConfig: UIObjectConfig): ObjectConfig {
  let _workerConfig: WorkerObjectConfig;

  return {
    ui: uiObjectConfig,

    get worker() {
      if (!_workerConfig) {
        const cfg: ConfigNoSections = Object.keys(uiObjectConfig).reduce((acc, key) => {
          const field = uiObjectConfig[key];
          if (field.type === "section") {
            return { ...acc, ...field.children };
          }
          return { ...acc, [key]: field };
        }, {});

        _workerConfig = mapValues(cfg, field => {
          if (field.type === "ref") {
            const { type, collectMismatches, label, multiple, connection, variables, fragment } = field;
            return {
              type,
              label,
              multiple,
              query: buildQuery({ ...connection, fragments: [fragment] }),
              entryName: connection.entry.name,
              variables,
              collectMismatches,
            };
          }

          return field;
        });
      }
      return _workerConfig;
    },
  };
}

interface ValidationError {
  [id: string]: string;
}

const parseValidationErrors = (err: any, t: TFunction): ValidationError[] => {
  const parsed = JSON.parse(err);
  const gqlExtensions = parsed?.graphQLErrors?.[0]?.extensions;
  const gqlException = gqlExtensions?.exception;
  const isValidationError = gqlException?.name === "validationError" || gqlExtensions?.code == "BAD_USER_INPUT";
  if (!isValidationError) {
    return [];
  }
  const badInputErrors = parsed?.graphQLErrors?.map((_: { [k: string]: string }, index: number) => ({
    [index]: "badUserInput",
  }));
  const errors = gqlException?.args?.indexedValidations;
  const updated = (errors ?? badInputErrors).map((e: ValidationError) =>
    mapValues(e, (value: string) => {
      return t(`importer.validationErrors.${value}`);
    }),
  );
  return updated;
};
