// @deprecated: Use New Link instead
import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export interface Props {
  children: React.ReactNode;
  className?: string;
  href?: string;
  kind?: "primary" | "secondary" | "tertiary" | "blue" | "blue1" | "button";
  component?: any;
  [linkProps: string]: any;
}

export default function Link({
  href = "javascript:void(0);", //eslint-disable-line
  kind = "primary",
  type = "link",
  component: LinkComponent,
  className,
  children,
  ...props
}: Props) {
  const op = { className: cx(styles.link, styles[kind], className), href: href, ...props };

  if (LinkComponent) {
    return <LinkComponent {...op}>{children}</LinkComponent>;
  }

  return <a {...op}>{children}</a>;
}
