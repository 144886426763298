import * as React from "react";
import cx from "classnames";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import lockIcon from "assets/lock.svg";
import arrowIcon from "assets/chevron.svg";
import variables from "styles/variables";
import styles from "./styles.scss";

export interface FieldProps {
  label?: React.ReactNode;
  labelReverse?: boolean;
  disabled?: boolean;
  optionalLabel?: boolean;
  errorMessage?: string | false;
}

type Props = FieldProps & {
  arrow?: "up" | "down";
  arrowClassName?: string;
  className?: string;
  elementClassName?: string;
  inputClassNameProp?: string | null;
  element?: React.ReactElement<any>;
  children?: React.ReactElement<any>;
  onArrowClick?: React.MouseEventHandler;
  testId?: string;
};

export default function BaseFormElement({
  className,
  elementClassName,
  element,
  children,
  inputClassNameProp,
  label,
  labelReverse,
  optionalLabel,
  errorMessage,
  arrow,
  arrowClassName,
  disabled,
  onArrowClick,
  testId,
}: Props) {
  element = element || children;

  if (!element) {
    throw new Error("children or element should be provided");
  }

  const elementClassNames = [styles.input, elementClassName, label ? styles["spacing-top"] : null];

  let elementProps = { disabled };

  if (inputClassNameProp !== null) {
    elementProps = {
      ...elementProps,
      [inputClassNameProp || "className"]: cx(...elementClassNames),
    };
  }

  const formElement = React.cloneElement(element, elementProps);

  return (
    <div
      className={cx(styles.formElement, className, label && styles.withLabel, errorMessage && styles.error)}
      data-test={testId}
      tab-index="-1"
    >
      {formElement}

      {label && (
        <span className={cx(styles.label, labelReverse && styles.labelReverse)}>
          <Text.Label2 kind={labelReverse ? "reverse" : "normal"}>{label}</Text.Label2>
          {disabled && (
            <span className={styles.lock}>
              <Icon src={lockIcon} size={10} fill={variables.gray3} />
            </span>
          )}

          {!disabled && optionalLabel && <div className={styles.optionalLabel}>&nbsp;&nbsp;Optional</div>}
        </span>
      )}

      {arrow && !disabled && (
        <span className={cx(styles.arrow, arrowClassName)} onClick={onArrowClick}>
          <Icon src={arrowIcon} size={10} rotate={arrow === "up" ? 180 : 0} />
        </span>
      )}

      {errorMessage && (
        <span className={styles["error-msg"]} data-test={`${testId}.validationError`}>
          <Text.ErrorMessage>{errorMessage}</Text.ErrorMessage>
        </span>
      )}
    </div>
  );
}
