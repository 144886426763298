import * as React from "react";
import cx from "classnames";
import Text from "components/LegacyText";
import { FieldProps } from "components/Form/BaseFormElement";
import styles from "./styles.scss";

export interface DecimalBoxProps extends FieldProps {
  value?: number | null;
  onChange: (val: number) => void;
  className?: string;
  label?: string;
  optionalLabel?: boolean;
  labelClassName?: string;
  maxDigits?: number;
  placeholder?: string;
  testId?: string;
  places?: number;
  symbol?: string | null;
  allowNegative?: boolean;
}

interface State {
  negative: boolean;
  rawValue: string;
  elementFocused: boolean;
}

export default class DecimalBox extends React.Component<DecimalBoxProps, State> {
  readonly state: State = {
    rawValue: "",
    elementFocused: false,
    negative: this.props.value && this.props.value < 0 ? true : false,
  };

  private textInput?: HTMLInputElement;

  public componentWillUnmount() {
    if (this.textInput) {
      if (window.navigator.userAgent.includes("Chrome")) {
        this.textInput.removeEventListener("input", this.handleTextInput);
        this.textInput.removeEventListener("keydown", this.handleChromeKeyDown);
      } else {
        this.textInput.removeEventListener("keydown", this.handleKeyDown);
      }
    }
  }

  private getKeyValue = (keyCode: number) => {
    if (keyCode >= 48 && keyCode <= 57) {
      return String.fromCharCode(keyCode);
    }
  };

  private formatNumber = (input: string) => {
    if (Number.isNaN(parseFloat(input))) {
      return 0;
    }
    const num = parseFloat(input);
    return Math.round(num) / Math.pow(10, this.props.places ?? 2);
  };

  private handleInputChange = (keyCode: number) => {
    const { rawValue } = this.state;
    const { onChange, maxDigits, allowNegative } = this.props;
    const key = this.getKeyValue(keyCode) || "";
    let input = "";
    const BACKSPACE = 8;
    const MINUS = [189, 45];

    if (keyCode === BACKSPACE && rawValue) {
      // Handle backspace
      input = rawValue.slice(0, rawValue.length - 1);
    } else if (MINUS.includes(keyCode) && rawValue.length === 0 && allowNegative) {
      // Allow negative values
      this.setState({ negative: true });
    } else {
      // Add new number to the input
      input = rawValue ? rawValue + key : key;
    }

    if (!rawValue && !MINUS.includes(keyCode) && !key) {
      this.setState({ negative: false });
    }

    //if there's nothing in the input and user tries to put in 0, ignore it
    if (!rawValue && keyCode === 48) {
      return;
    }

    if (input.length <= (maxDigits ?? 7) || keyCode === BACKSPACE) {
      const formattedInput = this.formatNumber(input);
      this.setState({ rawValue: input }, () => onChange(this.state.negative ? -formattedInput : formattedInput));
    }
  };

  private toggleElementGlow = () => this.setState({ elementFocused: !this.state.elementFocused });

  private handleTextInput = (e: any) => {
    if (!e.data) return;

    const keyCode = e.data.charCodeAt(0);
    this.handleInputChange(keyCode);
  };

  private handleKeyDown = ({ keyCode }: any) => {
    this.handleInputChange(keyCode);
  };

  private handleChromeKeyDown = ({ keyCode }: any) => {
    if (keyCode === 8) {
      this.handleInputChange(keyCode);
    }
  };

  public render() {
    const {
      value,
      errorMessage,
      disabled,
      places = 2,
      symbol = "$",
      maxDigits = 7,
      className,
      label,
      optionalLabel,
      labelClassName,
      testId,
      placeholder,
    } = this.props;

    const { elementFocused, negative } = this.state;
    const parsedVal = (typeof value === "string" ? parseFloat(value) : value) || 0;

    const displayedValue =
      value === null ? "" : value === 0 ? `0.${"0".repeat(places)}` : value && parsedVal.toFixed(places);

    return (
      <div className={cx(label && styles.withLabel, label && styles.containerGlow)} date-test={testId}>
        {label && (
          <span className={cx(styles.label, labelClassName && labelClassName)}>
            <Text.Label2>{label}</Text.Label2>
            {optionalLabel && <div className={styles.optionalLabel}>&nbsp;&nbsp;Optional</div>}
          </span>
        )}
        <div
          className={cx(
            errorMessage ? styles.errorDecimalBox : cx(styles.decimalBox, label && styles.inputWithLabel, className),
            !label && elementFocused && styles.containerGlow,
            disabled && styles.disabled,
          )}
          style={{ width: (symbol ? 15 : 0) + maxDigits * 18 + "px" }}
        >
          {symbol && (
            <Text.Display3 className={cx(styles.moneySymbol, negative ? styles.negative : null)}>
              {symbol}
            </Text.Display3>
          )}
          <div>
            <input
              className={cx(styles.input, negative ? styles.negative : null)}
              ref={input => {
                if (input && !this.textInput) {
                  if (window.navigator.userAgent.includes("Chrome")) {
                    input.addEventListener("input", this.handleTextInput);
                    input.addEventListener("keydown", this.handleChromeKeyDown);
                  } else {
                    input.addEventListener("keydown", this.handleKeyDown);
                  }
                  this.textInput = input;
                }
              }}
              onFocus={this.toggleElementGlow}
              onBlur={this.toggleElementGlow}
              onChange={() => null}
              value={displayedValue}
              placeholder={placeholder ?? `–.${"-".repeat(places)}`}
              disabled={disabled}
              type={"number"}
            />
          </div>
        </div>

        {errorMessage && <Text.Message kind={"error"}>{errorMessage}</Text.Message>}
      </div>
    );
  }
}
