import React, { useState, useEffect } from "react";
import { ImpersonateModalUserQuery, ImpersonateModalUserQueryVariables } from "gql-gen";
import useRouter from "use-react-router";
import { useMutation, useQuery } from "@apollo/client";

import secretIcon from "assets/secret.svg";
import ConfirmBox from "components/ConfirmBox";
import Text from "components/Text";
import TextBox from "components/Form/TextBox";
import Section from "components/Form/Section";
import { CloseModalHandler } from "utilities/modals";
import impersonateMutation from "./impersonate.gql";
import userQuery from "./user.gql";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { setJwt } from "utilities/authentication";

export function SwitchToUser({ handleClose }: { handleClose: CloseModalHandler }) {
  let { me, impersonator } = useUserInfo();
  me = impersonator || me;

  const {
    match: {
      params: { userId },
    },
  } = useRouter<{ userId: string }>();

  const { data } = useQuery<ImpersonateModalUserQuery, ImpersonateModalUserQueryVariables>(userQuery, {
    variables: {
      id: userId,
    },
  });

  const user = data?.adminUser;
  const [mutate, { error }] = useMutation<any, any>(impersonateMutation);
  const [password, setPassword] = useState<string>("");

  const [elapsing, setElapsing] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsing(x => x - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (elapsing <= 0) {
      handleClose();
    }
  }, [elapsing]);

  const submit = async () => {
    if (me && user && (user.email || user.unconfirmedEmail)) {
      const { data } = await mutate({
        variables: {
          impersonatorEmail: me.email,
          password,
          subjectEmail: user.email ?? user.unconfirmedEmail,
        },
        context: {
          clientName: "new-api",
        },
      });

      if (data?.impersonate) {
        setJwt(data.impersonate.authToken);

        handleClose();

        setTimeout(() => {
          location.reload();
        }, 100);
      }
    }
  };

  return (
    <ConfirmBox
      icon={secretIcon}
      title="Impersonate"
      yesText="IMPERSONATE"
      yesDisabled={!user || (!user.email && !user.unconfirmedEmail) || !me}
      onNo={handleClose}
      onYes={submit}
    >
      <Text color="white" size={16} bottom="m">
        Hi there! You're about to impersonate{" "}
        {user ? (
          <strong>
            {user.firstName} {user.lastName}
          </strong>
        ) : (
          "a user"
        )}
        . Please enter your admin password to continue.
      </Text>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <Section noMargin>
          <TextBox
            label="YOUR PASSWORD"
            type="password"
            placeholder="···················"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Section>
      </form>
      <Text color="pink1" size={16} top="m">
        {error
          ? "Something went wrong while impersonating, see console"
          : `This box will self-destruct in ${elapsing}s.`}
      </Text>
    </ConfirmBox>
  );
}
