import { useMemo, SetStateAction, Dispatch } from "react";
import { addQueries, getUrlQueries, omitQueries } from "utilities/routes";
import useRouter from "use-react-router";
import { fromBase64, toBase64 } from "utilities/base64";
import { getProgramIdFromSearch } from "utilities/routes";

function isSetStateActionFunction<S>(action: SetStateAction<S>): action is (prevState: S) => S {
  return typeof action === "function";
}

function isInitialStateFunction<S>(initialState: S | (() => S)): initialState is () => S {
  return typeof initialState === "function";
}

export interface SerializeStateOptions<State, SerializedState = State> {
  serialize?: (state: State) => SerializedState;
  parse?: (rawJson: SerializedState) => State;
}

export function useSearchState<S>(
  param: string,
  initialState: S | (() => S),
  { serialize, parse }: SerializeStateOptions<S> = {},
): [S, Dispatch<SetStateAction<S>>] {
  const { history, location } = useRouter();

  const getState = () => {
    const raw = getUrlQueries(history.location.search)[param];

    if (raw) {
      const rawJson = JSON.parse(fromBase64(raw));
      return parse ? parse(rawJson) : rawJson;
    }

    if (isInitialStateFunction(initialState)) return initialState();
    return initialState;
  };

  const setState = (action: SetStateAction<S>) => {
    let newState: S;

    if (isSetStateActionFunction(action)) {
      newState = action(getState());
    } else {
      newState = action;
    }

    history.push({
      ...history.location,
      search: serializeSearchState(param, serialize ? serialize(newState) : newState, location.search),
    });
  };

  const state = useMemo(getState, [history.location.search]);

  return [state, setState];
}

export function serializeSearchState<S>(param: string, state: S, search: string): string {
  const programId = getProgramIdFromSearch(search);

  const withSerializedParam =
    state === undefined || state === null
      ? omitQueries(search, [param])
      : addQueries(search, {
          [param]: toBase64(JSON.stringify(state)),
        });
  return addQueries(withSerializedParam, {
    program: programId,
  });
}
