import useRouter from "use-react-router";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GigsDetailsFragment } from "gql-gen";
import Popup from "components/Popup";
import Text from "components/Text";
import ExpandableText from "components/ExpandableText";
import RoundedPill from "components/RoundedPill";
import icon from "assets/megaphone.svg";
import { ICell } from "modules/Connection/types";
import { CellBase, CR1, CR2, CR3, AddDetails } from "modules/Connection/CellBase";
import styles from "./styles.scss";
import fragment from "./details.gql";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";

export const DetailsCell: ICell<GigsDetailsFragment> = {
  component: props => {
    const { t } = useTranslation();
    const {
      match: {
        params: { orgId },
      },
    } = useRouter<OrganizationRouteParams>();

    const { data, handleOpenForm } = props;

    if (!data || !data.program || !data.products) {
      return (
        <CellBase {...props}>
          {() => <AddDetails onClick={handleOpenForm}>{t("gigs.columns.details.addDetails")}</AddDetails>}
        </CellBase>
      );
    }

    const { program, products, title, notes } = data;

    const noProduct = products.length === 0;

    const productLine = noProduct ? (
      <AddDetails onClick={handleOpenForm} className={styles.addProducts}>
        {t("gigs.columns.details.addProducts")}
      </AddDetails>
    ) : (
      <CR2 underline="hover" font={"lato"} color={"gray3"}>
        {products.length > 1 ? `${products.length} products` : products[0] && products[0].name}
      </CR2>
    );

    return (
      <CellBase {...props}>
        {mode =>
          mode === "detailed" ? (
            <>
              <Link to={`/${orgId}/overview?program=${program?.id}`}>
                <Text bottom="s" inline size={18} bold underline="hover" color="blue2">
                  {program?.name}&nbsp;
                </Text>
              </Link>
              {title && (
                <Text font="wesR" size={28} bottom="s">
                  {title}
                </Text>
              )}
              {notes && (
                <Text size={14} bottom="m" lineHeight={18}>
                  <ExpandableText lines={3}>{notes}</ExpandableText>
                </Text>
              )}

              <div className={styles.productPills}>
                {products.map(product => (
                  <RoundedPill key={product.id} blue>
                    {product.name}
                  </RoundedPill>
                ))}
              </div>
            </>
          ) : (
            <>
              <Popup
                body={() => (
                  <div className={styles.programPopup}>
                    <CR1 color={"white"}>{program?.name}</CR1>
                  </div>
                )}
              >
                <CR1 underline="hover">{program?.name}</CR1>
              </Popup>

              {products.length > 1 ? (
                <Popup
                  body={() => (
                    <div className={styles.productsPopup}>
                      <ul className={styles.productsList}>
                        {products.map(product => (
                          <li key={product.id}>{product.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                >
                  {productLine}
                </Popup>
              ) : (
                productLine
              )}

              <CR3>{title}</CR3>
            </>
          )
        }
      </CellBase>
    );
  },
  icon,
  fragment,
};
