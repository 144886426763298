import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Note({ children, className }: Props) {
  return <div className={cx(styles.note, className)}>{children}</div>;
}
