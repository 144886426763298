import { MutationFunction } from "@apollo/client";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as H from "history";
import moment from "moment";
import _, { groupBy, omit } from "lodash";
import { omitTypeName } from "utilities/connections";
import { nullify } from "utilities/formik";
import StatusBar, { makeStatus, ProgramFormStatus } from "./StatusBar";
import { IProgramTeamMember, ISavedProgramRequest } from "./IProgramRequest";
import {
  ProgramFormCtx,
  ProgramFormContext,
  ProgramFormValues,
  ProgramFormValidation,
  ProgramFormState,
} from "./Context";
import programFragment from "./updatedProgram.gql";
import { ProgramsRouteParams } from "../index";
import { ProgramRole } from "modules/Dashboard/interfaces/ProgramRole";
import { isHayday } from "utilities/flavor";
import { getProgramIdFromSearch } from "utilities/routes";
import { flowRight as compose } from "lodash";
import * as fp from "fp-ts";

export interface Props extends RouteComponentProps<ProgramsRouteParams> {
  data: {
    programRequest: ISavedProgramRequest;
    loading: boolean;
    error: {} | null;
  };

  mutate: MutationFunction<{ upsertProgramRequest: ISavedProgramRequest }, { input: any }>;
  onSave: (sid: string | null, validation: ProgramFormValidation) => void;
  children: React.ReactNode;
  id: string | null;
  sections: string[];
  locationToSection: ((section: string | null) => H.Location) | null;
  canEdit: boolean;
  helperMargins: boolean;
}

type State = ProgramFormState;

const STATE_DEBOUNCE = 400;
const SAVE_DEBOUNCE = 3000;

const makeInitialState = (sections: string[]): State => ({
  id: null,
  status: makeStatus("loading"),
  values: {
    name: `Unnamed ${isHayday ? "Check In Group " : "Program"} ${moment().format("YYYY-MM-DD h:mma")}`,
  },

  validation: sections.reduce((v, id) => ({ ...v, [id]: false }), {}),
  executionType: "maestro",
  sectionsBeingSaved: [],
});

class ProgramFormProvider extends React.Component<Props, State> {
  readonly state: State = makeInitialState(this.props.sections);

  public static getDerivedStateFromProps(
    { data: { programRequest, loading }, sections }: Props,
    { validation, values, status: { status }, executionType: executionTypeOld }: State,
  ): Partial<State> {
    if (status !== "loading" && loading) {
      return {
        ...makeInitialState(sections),
        status: makeStatus("loading"),
      };
    }

    if (status === "loading" && !loading) {
      if (programRequest) {
        const {
          id,
          meta,
          products,
          trainingDocuments,
          preliminaryScheduleDocuments,
          billingContact,
          startDate,
          endDate,
          executionType,
          roles,
          programGroups,
          partnerOrganizations,
          team,
          clientOrganizations,
          ...savedValues
        } = programRequest;

        const mapToIds = (collection: { id: string }[] | null) => (collection ? collection.map(p => p.id) : []);
        const mapToSelectedProducts = (collection: { id: string; name: string }[] | null) =>
          collection ? collection.map(p => ({ id: p.id, name: p.name })) : [];

        let updatedValidation;
        if (executionType !== executionTypeOld) {
          updatedValidation = sections.reduce((v, id) => ({ ...v, [id]: validation[id] || false }), {});
          if (executionType === "solo") {
            updatedValidation = { ...updatedValidation, overview: true };
          }
        } else {
          updatedValidation = validation;
        }

        let status: ProgramFormStatus = "saved";

        if (programRequest.managementState === "handedOff") {
          status = "handedOff";
        } else if (programRequest.managementState === "accepted") {
          status = "accepted";
        }

        const { dashboardUsers, selfSchedulers, requesters } = {
          dashboardUsers: [],
          selfSchedulers: [],
          requesters: [],
          ...groupBy(team, u => {
            if (u.userType === "admin" || u.userType === "owner") {
              return "dashboardUsers";
            } else if (u.canSelfAssign) {
              return "selfSchedulers";
            } else if (u.canRequest) {
              return "requesters";
            } else {
              return "dashboardUsers";
            }
          }),
        };

        return {
          id: id || null,
          executionType,
          status: makeStatus(status),
          values: omitTypeName({
            ...values,
            ...savedValues,
            roles: roles.map(role => omit(role, ["id"])),
            startDate: startDate && moment(startDate),
            endDate: endDate && moment(endDate),
            productIds: mapToIds(products),
            programGroupIds: mapToIds(programGroups),
            selectedProducts: mapToSelectedProducts(products),
            trainingDocumentIds: mapToIds(trainingDocuments),
            preliminaryScheduleDocumentIds: mapToIds(preliminaryScheduleDocuments),
            billingContactId: billingContact && billingContact.id,
            partnerOrganizationIds: partnerOrganizations.map(({ organization }) => organization.id),
            partnerOrganizations,
            dashboardUsers,
            selfSchedulers,
            requesters,
            clientOrganizations,
          }),

          validation: {
            ...updatedValidation,
            ...(meta && meta.validation),
          },
        };
      }

      return {
        status: makeStatus("new"),
      };
    }

    return {};
  }

  public componentWillUnmount() {
    this.setFormState.cancel();
    this.debouncedBackendSave.cancel();
  }

  public componentDidUpdate(prevProps: Props) {
    const prevId = getProgramIdFromSearch(prevProps.location.search);
    const newId = getProgramIdFromSearch(this.props.location.search);
    if (prevId !== newId) {
      this.setFormState.cancel();
      this.debouncedBackendSave.cancel();
    }
  }

  private backendSave = async () => {
    if (this.props.data.error) return;

    const { values, validation } = this.state;
    const {
      match: {
        params: { orgId },
      },

      id,
    } = this.props;

    if (!id) return;

    this.setSavingStatus("saving");

    const currentSectionsBeingSaved = this.state.sectionsBeingSaved;

    try {
      const roleParams: (keyof ProgramRole)[] = ["hoursPerGig", "volume"];

      const newValues = {
        ..._.omit(
          values,
          "createdAt",
          "nameChangedAt",
          "isOwnedByCurrentOrganization",
          "dashboardUsers",
          "selfSchedulers",
          "requesters",
          "partnerOrganizationIds",
          "clientOrganizations",
          "ownerOrganization",
        ),

        roles: (values.roles || []).map((role: ProgramRole) => {
          return {
            ..._.omit(role, "partnerRates", "clientRate", "agencyRate"),
            ...roleParams.reduce(
              (acc, val) => ({
                ...acc,
                [val]: role[val] === "" ? 0 : role[val],
              }),
              {},
            ),
            hoursPerGig: 3,
            volume: 1,
          };
        }),

        partnerOrganizations: values.partnerOrganizationIds.map((organizationId: string) => {
          const existingPartnerOrganization = values.partnerOrganizations.find(
            ({ organization }: any) => organization.id === organizationId,
          );

          if (!!existingPartnerOrganization) {
            return {
              organizationId: existingPartnerOrganization.organization.id,
              partnerRates: existingPartnerOrganization.roleRates.map((roleRate: any) => ({
                roleId: roleRate.role.id,
                rate: roleRate.rate,
                pinataMaestroRate: roleRate.pinataMaestroRate,
              })),
              accessLevel: existingPartnerOrganization.accessLevel,
            };
          }

          return { organizationId };
        }),
        team: (values.dashboardUsers ?? [])
          // must not contain any overlapping users with dashboardUsers or requesters
          .concat(values.selfSchedulers ?? [])
          // must not contain any overlapping users with dashboardUsers or selfSchedulers
          .concat(values.requesters ?? [])
          // strip extra props from selfSchedulers && requesters
          .map((member: IProgramTeamMember) => {
            return omit(member, "canSelfAssign", "canRequest");
          }),
      };

      const gqlValues = nullify(newValues);
      const updatedValues = _.omit(gqlValues, "atCapacity", "capacityGigCount", "proPartners", "selectedProducts");

      await this.props.mutate({
        variables: {
          input: {
            id,
            organizationId: orgId,
            meta: {
              validation,
            },
            ...updatedValues,
          },
        },

        update: (client, { data }) => {
          if (data) {
            client.writeFragment({
              id: `Program_${id}`,
              fragment: programFragment,
              data: {
                ...data.upsertProgramRequest,
                __typename: "Program",
              },
            });
          }
        },
      });

      this.setSavingStatus("saved");
      this.setState(currentState => ({
        sectionsBeingSaved: currentState.sectionsBeingSaved.filter(
          section => !currentSectionsBeingSaved.includes(section),
        ),
      }));
    } catch (e) {
      console.error(e);
      this.setSavingStatus("error");
    }
  };

  private debouncedBackendSave = _.debounce(this.backendSave, SAVE_DEBOUNCE);

  private setFormState = _.debounce(
    (values: Partial<ProgramFormValues>, validation: Partial<ProgramFormValidation>, section?: string) => {
      if (this.props.data.error) return;

      if (!this.props.canEdit) {
        throw new Error("setFormState was called but user cannot edit");
      }

      this.setState(
        currentState => ({
          status: makeStatus("changed"),
          values: { ...currentState.values, ...values },
          validation: { ...currentState.validation, ...validation },
          sectionsBeingSaved: section
            ? fp.array.uniq(fp.string.Eq)([...currentState.sectionsBeingSaved, section])
            : currentState.sectionsBeingSaved,
        }),

        () => {
          this.debouncedBackendSave();
        },
      );
    },
    STATE_DEBOUNCE,
  );

  private saveSection = (sid: string | null) => () => {
    if (this.props.onSave) {
      this.props.onSave(sid, this.state.validation);
    }
    return this.backendSave();
  };

  private setSavingStatus = (status: ProgramFormStatus): Promise<void> => {
    return new Promise(resolve => {
      this.setState(
        {
          status: makeStatus(status),
        },

        () => resolve(),
      );
    });
  };

  public render() {
    const { children, canEdit, locationToSection, helperMargins } = this.props;
    const { status } = this.state;

    const context: ProgramFormContext = {
      ...this.state,
      saveSection: this.saveSection,
      saveAll: this.backendSave,
      setStatus: this.setSavingStatus,
      setState: this.setFormState,
      locationToSection,
      canEdit,
    };

    return (
      <ProgramFormCtx.Provider value={context}>
        {children}
        <StatusBar {...status} helperMargins={helperMargins} />
      </ProgramFormCtx.Provider>
    );
  }
}

export default compose(withRouter)(ProgramFormProvider);
