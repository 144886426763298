import * as React from "react";
import cx from "classnames";
import _ from "lodash";
import styles from "./styles.scss";

export type ButtonKind =
  | "primary"
  | "secondary"
  | "gray"
  | "translucent"
  | "blueTranslucent"
  | "primaryBold"
  | "primaryGradient"
  | "redGradient"
  | "greenGradient";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  testId?: string;
  children: React.ReactNode;
  kind?: ButtonKind;
  section?: boolean;
  className?: string;
  disabled?: boolean;
  href?: string;
  noPadding?: boolean;
}

function openInNewTab(href: string) {
  const win = window.open(href, "_blank");
  if (win) win.focus();
}

export default function Button({
  testId,
  children,
  kind = "secondary",
  className,
  href,
  section,
  noPadding,
  ...props
}: Props) {
  let onClick;
  if (href) {
    onClick = () => openInNewTab(href);
  } else if (props.onClick) {
    onClick = props.onClick;
  } else {
    onClick = _.noop;
  }

  return (
    <button
      data-test={testId}
      className={cx(styles.button, styles[kind], section && styles.section, noPadding && styles.noPadding, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}
