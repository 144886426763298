import React from "react";
import { IAction } from "modules/Connection/types";
import confirmIcon from "assets/calendar-check.svg";
import { colors } from "styles/variables";
import { ActionBody } from "modules/Connection/ActionBody";
import { useScopeContext } from "modules/Connection/Scope";
import useRouter from "use-react-router";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { getProgramIdFromSearch } from "utilities/routes";
import { Elm } from "components/Elm";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import GigConfirmationElm from "./Confirmation.elm";

const i18n = "gigs.actions.confirmation";

export const Confirmation: IAction = {
  key: "confirmation",
  icon: confirmIcon,
  labelKey: `${i18n}.label`,
  color: colors.blue2,
  allowInline: false,
  allowBatch: true,

  component: ({ done }) => {
    const {
      reload,
      variables: { filters },
    } = useScopeContext();
    const {
      match: {
        params: { orgId, gigId },
      },
      history,
      location,
    } = useRouter<ProgramsRouteParams & { gigId?: string }>();

    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Elm
          key={"gigConfirmation"}
          src={GigConfirmationElm.Elm.Confirmation}
          flags={{
            session: {
              serverUrl: getServerRootUrl() + "/graphql",
              haskellBackendBaseUrl: getHaskellBackendUrl(),
              rustBackendBaseUrl: getRustBackendUrl(),
              authToken: getJwt(),
            },
            gigId: gigId ? gigId : null,
            organizationId: orgId,
            programIds: id === "all" ? [] : id.split(","),
            filters,
          }}
          ports={ports => {
            ports.reloadGigsAndClose.subscribe(() => {
              reload();
            });
            ports.goToAssignment.subscribe(() => {
              history.push({
                ...location,
                pathname: location.pathname.replace("confirmation", "assignment"),
              });
            });
          }}
        />
      </ActionBody>
    );
  },
};
