import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpenseCard from "components/ExpenseCard";
import Text from "components/Text";
import styles from "./styles.scss";
import { useArrayState } from "hooks/useArrayState";
import { Expense, ExpenseInput, GigExpenseInput, User, GigExpense } from "gql-gen";
import StepItem from "../StepItem";
import { Desktop } from "components/Layout/Responsive";
import Switch from "components/Switch";
import FilePicker from "components/Form/FilePicker";
import ConfirmBox from "components/ConfirmBox";
import deleteIcon from "assets/delete.svg";
import LegacyText from "components/LegacyText";
import ActionFooter from "components/ActionFooter";
import FooterDistance from "components/FooterDistance";
import * as fp from "fp-ts";
import { omit } from "lodash";
import getServerRootUrl from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import useRouter from "use-react-router";
import LoadingContainer from "components/LoadingContainer";

const gigExpensesQuery = `
query GigExpenses($id: ID!) {
  gig(id: $id) {
    id
    expenseNotes
    expenses {
      gigId
      expense {
        id
        amount
        memo
        personal
        classKey
        createdBy {
          id
          firstName
        }
        images
      }
    }
    talentExpenses
  }
}
`;

const gigExpensesMutation = `
mutation UpdateGigExpenses($gigId: ID!, $input: [GigExpenseInput], $hasTalentExpenses: Boolean) {
  updateGigExpenses(gigId: $gigId, input: $input, hasTalentExpenses: $hasTalentExpenses)
}
`;

interface Props {
  gigId: string;
  returnToChecklist: () => void;
  gigRefetch: () => Promise<void>;
  setExpensesDirty: (dirty: boolean) => void;
  expensesDirty: boolean;
}

const expenseInputEq: fp.eq.Eq<ExpenseInput> = fp.eq.struct<ExpenseInput>({
  amount: fp.eq.eqStrict,
  classKey: fp.eq.eqStrict,
  images: fp.eq.contramap<fp.option.Option<Array<string>>, ExpenseInput["images"]>(it =>
    fp.function.pipe(fp.option.fromNullable(it), fp.option.map(fp.array.filter(fp.string.isString))),
  )(fp.option.getEq(fp.array.getEq(fp.string.Eq))),
  memo: fp.eq.eqStrict,
  personal: fp.eq.eqStrict,
});

const gigExpenseInputEq = fp.eq.struct<GigExpenseInput>({
  byAgency: fp.eq.eqStrict,
  expense: expenseInputEq,
  expenseId: fp.eq.eqStrict,
});

function mapServerExpensesToLocalExpense(
  expense: {
    __typename: "GigExpense";
  } & Pick<GigExpense, "gigId"> & {
      expense: {
        __typename: "Expense";
      } & Pick<Expense, "id" | "amount" | "memo" | "personal" | "classKey" | "images"> & {
          createdBy: {
            __typename: "User";
          } & Pick<User, "id" | "firstName">;
        };
    },
) {
  return {
    expenseId: expense.expense.id,
    expense: omit(expense.expense, ["__typename", "createdBy", "id"]),
  };
}

function haveExpensesChanged(modifiedExpenses: GigExpenseInput[], originalExpenses: GigExpenseInput[]) {
  return !fp.array.getEq(gigExpenseInputEq).equals(modifiedExpenses, originalExpenses);
}

const goPortalUrl = getServerRootUrl();
const graphqlUrl = goPortalUrl + "/graphql";

export default function Expenses({ expensesDirty, gigId, returnToChecklist, gigRefetch, setExpensesDirty }: Props) {
  const { t } = useTranslation();
  const { match } = useRouter<{ orgId: string }>();

  const [modifiedExpenses, setModifiedExpenses] = useState<GigExpenseInput[]>([]);
  const [originalExpenses, setOriginalExpenses] = useState<GigExpenseInput[]>([]);

  const { push, removeIndex, setIndex, setArray } = useArrayState(modifiedExpenses, setModifiedExpenses);

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<{ gig: { talentExpenses: boolean; expenseNotes: string } } | undefined>(undefined);

  useEffect(() => {
    const getGigExpenses = async (gigId: string) => {
      setLoading(true);

      const response = await fetch(graphqlUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getJwt()}`,
          "X-Pinata-Organization-Id": match.params.orgId,
        },
        body: JSON.stringify({
          query: gigExpensesQuery,
          variables: { id: gigId },
          operationName: "GigExpenses",
        }),
      });

      const res = await response.json();

      setLoading(false);
      setData(data);
      setArray(res?.data?.gig?.expenses.map(mapServerExpensesToLocalExpense));
      setOriginalExpenses(res?.data?.gig?.expenses.map(mapServerExpensesToLocalExpense));
      setHasTalentExpenses(!!res?.data?.gig?.talentExpenses);
    };
    getGigExpenses(gigId);
  }, []);

  const [hasTalentExpenses, setHasTalentExpenses] = useState<boolean>(!!data?.gig?.talentExpenses);

  const [uploadError, setUploadError] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);

  const [loadingGigExpenses, setLoadingGigExpenses] = useState<boolean>(false);

  const mutate = async ({
    gigId,
    input,
    hasTalentExpenses,
  }: {
    gigId?: string;
    hasTalentExpenses?: boolean;
    input: GigExpenseInput[];
  }) => {
    await fetch(graphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
        "X-Pinata-Organization-Id": match.params.orgId,
      },
      body: JSON.stringify({
        operationName: "UpdateGigExpenses",
        query: gigExpensesMutation,
        variables: { gigId, input, hasTalentExpenses },
      }),
    });
    setLoadingGigExpenses(false);
    gigRefetch();
  };

  if (loading || loadingGigExpenses) {
    return <LoadingContainer />;
  }

  const totalAmount = modifiedExpenses.reduce((total, expense) => {
    return total + expense.expense.amount;
  }, 0);

  const saveDisabled =
    (!haveExpensesChanged(modifiedExpenses, originalExpenses) && hasTalentExpenses) ||
    modifiedExpenses.some(expense => !expense.expense.amount) ||
    (modifiedExpenses.length === 0 && hasTalentExpenses);

  function onSave(isDesktop: boolean) {
    return async function () {
      setLoadingGigExpenses(true);
      await mutate({
        gigId,
        hasTalentExpenses,
        input: modifiedExpenses,
      })
        .then(() => {
          setLoadingGigExpenses(false);
        })
        .catch(_ => {
          alert("There was an error creating expense. Please try again or contact support.");
          setLoadingGigExpenses(false);
        });
      setExpensesDirty(false);
      if (!isDesktop) {
        returnToChecklist();
      }
    };
  }

  return (
    <>
      <Desktop>
        {isDesktop =>
          isDesktop ? null : (
            <StepItem
              title="Expenses"
              progress={
                !expensesDirty &&
                ((!haveExpensesChanged(modifiedExpenses, originalExpenses) && hasTalentExpenses) || !hasTalentExpenses)
                  ? 100
                  : 0
              }
              onClick={async () => {
                returnToChecklist();
              }}
            />
          )
        }
      </Desktop>

      <div className={styles.expensePolicy}>
        <Text color="teal1" font="wes" size={10} transform="uppercase">
          {t("components.reportExpenses.reminder")}
        </Text>
        <Text color="white" font="wes" size={14} lineHeight={18} transform="uppercase">
          {t("components.reportExpenses.expensePolicy")}
        </Text>
        <Text color="white" font="lato" size={14} lineHeight={18}>
          {data?.gig?.expenseNotes}
        </Text>
      </div>

      {uploadError && (
        <div className={styles.errorBanner}>
          <Text color="white" font="wes" size={16} lineHeight={24}>
            Your image upload has not been saved. Please try again.
          </Text>
          <Text color="white" font="wesR" size={12} lineHeight={18}>
            Ensure you have a stable internet connection and/or try reducing the image size.
          </Text>
        </div>
      )}

      <div className={styles.expenseContainer}>
        <div className={styles.expenseList}>
          <Switch
            labelText="I don't have any expenses"
            checked={!hasTalentExpenses}
            onChange={() => {
              setHasTalentExpenses(!hasTalentExpenses);
              setExpensesDirty(true);
            }}
            id="talentExpenses"
            className={styles.reverseSwitch}
            labelTextClassName={styles.switchText}
          ></Switch>
          {hasTalentExpenses && (
            <Text size={12}>
              Note: Receipt photo and value are required. Expenses without photos or value may be rejected
            </Text>
          )}
          {hasTalentExpenses &&
            !loading &&
            modifiedExpenses.map(({ expenseId, expense }, index) => {
              return (
                <div key={expenseId || index}>
                  <ExpenseCard
                    key={expenseId || index}
                    value={expense}
                    onChange={expense => {
                      if (expense.amount === 0) {
                        setIndex(index, { expenseId, expense });
                      } else {
                        setIndex(index, { expenseId, expense });
                      }
                      setExpensesDirty(true);
                    }}
                    onRemove={() => setIndexToDelete(index)}
                    requiredReceipt
                    error={expense.amount === 0 ? "Enter a value greater than 0" : undefined}
                  />
                </div>
              );
            })}
        </div>
        {hasTalentExpenses && !loading && modifiedExpenses.length > 0 && (
          <>
            <div className={styles.totalExpenses}>
              <Text size={18}>Total expenses</Text>
              <Text size={18} bold>
                {modifiedExpenses.length}
              </Text>
            </div>
            <div className={styles.totalValueExpenses}>
              <Text size={18}>Total value of expenses</Text>
              <Text size={18} bold>
                ${totalAmount.toFixed(2)}
              </Text>
            </div>
            <div className={styles.expensesSeparator}></div>
          </>
        )}
        {hasTalentExpenses && (
          <div className={styles.addExpenseContainer}>
            <Text size={18}>Click below to add a new expense</Text>
            <div className={styles.addExpenseButton}>
              <FilePicker
                size={120}
                value={[]}
                onChange={values => {
                  setUploadError(false);
                  push({
                    expense: {
                      amount: 0.0,
                      images: values.map(({ url }) => url) || [],
                      memo: "",
                      personal: true,
                      classKey: "other",
                    },
                  });
                  setExpensesDirty(true);
                }}
                accepts={".pdf, image/*, image/heic"}
                testId={"addExpense.image"}
                multi={false}
                onError={() => setUploadError(true)}
              />
            </div>
          </div>
        )}
      </div>
      <Desktop>
        {isDesktop => (
          <FooterDistance distance={0}>
            <ActionFooter
              title={isDesktop ? "Save" : "Save & return to checklist"}
              note="Don't forget to scroll. Please complete all sections."
              errorMessage={
                uploadError ? (
                  <LegacyText.Message kind={"error"} className={styles.submitError}>
                    We couldn't save your expenses. Try again later.
                  </LegacyText.Message>
                ) : null
              }
              disabled={saveDisabled}
              onAction={onSave(isDesktop)}
              testId={"gogetter.report.expenses.saveButton"}
            />
          </FooterDistance>
        )}
      </Desktop>

      {indexToDelete !== null && (
        <ConfirmBox
          icon={deleteIcon}
          title="Delete this expense?"
          bodyClass={styles.deleteExpenseConfirmation}
          onYes={async () => {
            const newExp = removeIndex(indexToDelete);

            setLoadingGigExpenses(true);

            await mutate({
              gigId,
              input: newExp,
            });

            setIndexToDelete(null);
          }}
          onNo={() => setIndexToDelete(null)}
        >
          <Text size={14} color="white">
            If you delete this expense, all added information will be lost.
          </Text>
        </ConfirmBox>
      )}
    </>
  );
}
