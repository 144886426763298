import * as React from "react";
import cx from "classnames";
import BigSelect, { BigSelectProps } from "modules/Dashboard/Organization/Programs/BigSelect";
import Searchable, { SearchableProps } from "../index";
import styles from "./styles.scss";

export interface SearchableBigSelectProps extends Omit<SearchableProps, "children"> {
  searchableRef?: (ref: Searchable | null) => void;
  selectProps: BigSelectProps;
  testId?: string;
}

export default function SearchableBigSelect({
  selectProps,
  searchableRef,
  testId,
  ...props
}: SearchableBigSelectProps) {
  return (
    <Searchable ref={typeof searchableRef === "function" ? searchableRef : null} optionsYOffset={-26} {...props}>
      {({ inputProps, focusInput }) => (
        <BigSelect onClick={focusInput} {...selectProps} inverse={selectProps.inverse}>
          <input
            {...inputProps}
            data-test={testId}
            className={cx(
              inputProps.className,
              selectProps.compact ? styles.compact : styles.normal,
              selectProps.inverse && styles.inverse,
            )}
          />
        </BigSelect>
      )}
    </Searchable>
  );
}
