import * as React from "react";
import styles from "./styles.scss";
import Form from "components/Form";

export interface AgreementSignatureQuestionValue {
  agreed: boolean;
  signature: string;
}

interface Props {
  onSignatureChange: React.ChangeEventHandler<HTMLInputElement>;
  onCheck: () => void;
  value: AgreementSignatureQuestionValue | null;
  label: string;
}

export default function AgreementBox(props: Props) {
  const { onSignatureChange, value, onCheck, label } = props;
  return (
    <div className={styles.agreementContainer}>
      <Form.TextBox
        inputClassName={styles.inputCls}
        placeholder={"Type here..."}
        label={label}
        onChange={onSignatureChange}
        value={(value && value.signature) || ""}
      />

      <Form.Checkbox large labelClassName={styles.checkboxLabel} onCheck={onCheck} checked={!!(value && value.agreed)}>
        I certify that this report is accurate to the best of my knowledge.
      </Form.Checkbox>
    </div>
  );
}
