import React, { useState, useRef, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AutosizeInput from "react-input-autosize";
import cx from "classnames";

import BaseFormElement from "components/Form/BaseFormElement";
import { Searchable, SearchableProps, SearchableItem } from "components/Searchable";
import { ConnectionConfig } from "utilities/connections";

import styles from "./styles.scss";

export interface SearchableSelectProps<Node extends SearchableItem, CC extends ConnectionConfig<Node>>
  extends Omit<SearchableProps<Node, CC>, "children"> {
  className?: string;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  testId?: string;
  renderName: (node: Node) => string;
  /** Use this to render something on top of the element using the count of available options */
  children?: (props: { count: number | null; select: ReactNode }) => ReactNode;
  placeholder?: string;
  itemNamePlural?: string;
}

/**
 * Utilizes Searchable to render an options dropdown from a GraphQL connection query.
 *
 * Behaviour:
 * - In multiple mode, the input focus is mantained while selecting items
 * - ...
 */
export function SearchableSelect<
  Node extends SearchableItem,
  CC extends ConnectionConfig<Node> = ConnectionConfig<Node>
>({
  errorMessage,
  label,
  placeholder,
  disabled,
  className,
  children,
  itemNamePlural,
  ...searchableProps
}: SearchableSelectProps<Node, CC>) {
  const [open, setOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | undefined>();
  const doNotClose = useRef<boolean>(false);
  const listRef = useRef<HTMLDivElement>(null);
  const [listClass, setListClass] = useState<string>();
  const { t } = useTranslation();

  const { multiple, onChange, value, renderName, testId } = searchableProps;

  const handleBlur = () => {
    setTimeout(() => {
      if (doNotClose.current) {
        doNotClose.current = false;
      } else {
        if (inputRef.current) {
          inputRef.current.blur();
        }
        setOpen(false);
      }
    }, 200);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleOnChange = (v: string[], nodes: Node[], autoSelected?: boolean) => {
    doNotClose.current = !!multiple;
    onChange(v, nodes);
    if (multiple && !autoSelected) deferredFocus();
  };

  const deferredFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleScroll = (_: React.UIEvent<HTMLDivElement>) => {
    doNotClose.current = true;
  };

  const removeId = (idToRemove: string, nodes: Node[]) => {
    handleOnChange(
      value.filter(id => id !== idToRemove),
      nodes,
    );
  };

  useEffect(() => {
    if (listRef.current) {
      if (window.innerHeight / 2 < listRef.current.getBoundingClientRect().top) {
        return setListClass(styles.listToTop);
      }
    }
    return setListClass(styles.listToBottom);
  }, [open]);

  return (
    <Searchable {...searchableProps} onChange={handleOnChange}>
      {({ inputProps, list, selectedNodes, loadedNodes, totalCount }) => {
        const selectedNames = value.map(id => ({
          id,
          name: selectedNodes[id] && renderName(selectedNodes[id]).trim(),
        }));

        let placeholderText = placeholder || t(`components.searchableSelect.search`);

        if (!open) {
          switch (selectedNames.length) {
            case 0:
              placeholderText;
              break;

            case 1:
              placeholderText = selectedNames[0].name;
              break;

            default:
              placeholderText = `${selectedNames.length} ` + (itemNamePlural ? itemNamePlural : "items") + " selected";
              break;
          }
        }

        const select = (
          <>
            <label className={styles.select}>
              <BaseFormElement
                label={label}
                errorMessage={typeof errorMessage === "string" ? errorMessage : false}
                className={className}
                disabled={!!disabled}
                arrow={open ? "up" : "down"}
                testId={testId}
                element={
                  <div className={styles.box} onFocus={handleFocus} onBlur={handleBlur}>
                    <AutosizeInput
                      {...{
                        ...inputProps,
                        placeholder: placeholderText,
                      }}
                      ref={
                        ((input: AutosizeInput) => {
                          if (input) {
                            inputRef.current = input.getInput();
                          }
                        }) as any
                      }
                      className={value.length === 1 && !multiple ? styles.darkPlaceholderInput : styles.input}
                      value={inputProps.value}
                      onFocus={handleFocus}
                      onChange={e => {
                        inputProps.onChange && inputProps.onChange(e);
                        if (open && e.target.value === inputProps.value) handleBlur();
                      }}
                      disabled={disabled}
                      onKeyDown={e => {
                        if (
                          e.key === "Backspace" &&
                          e.target instanceof HTMLInputElement &&
                          e.target.value === "" &&
                          value.length > 0
                        ) {
                          removeId(value[value.length - 1], loadedNodes);
                        }
                      }}
                      onMouseDown={() => {
                        if (open) {
                          setTimeout(() => {
                            if (inputRef.current) {
                              inputRef.current.blur();
                            }
                          }, 200);
                        }
                      }}
                    />
                  </div>
                }
              />
              <div
                ref={listRef}
                onScroll={handleScroll}
                className={cx(styles.list, open && styles.listVisible, listClass)}
              >
                {open && list}
              </div>
            </label>
          </>
        );

        return children ? children({ count: totalCount, select }) : select;
      }}
    </Searchable>
  );
}
