import * as React from "react";
import cx from "classnames";
import BaseFormElement from "components/Form/BaseFormElement";
import styles from "./styles.scss";
import { FieldProps } from "formik";

export interface TextBoxProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: React.ReactNode;
  rows?: number;
  className?: string;
  inputClassName?: string;
  errorMessage?: string | false;
  multiline?: boolean;
  optionalLabel?: boolean;
  inputRef?: any;
  disabled?: boolean;
  testId?: string;
}

export default function TextBox({
  label,
  className,
  inputClassName,
  errorMessage,
  multiline,
  optionalLabel,
  inputRef,
  disabled,
  rows,
  testId,
  ...inputProps
}: TextBoxProps) {
  const inputClassNames = [rows && styles.noHeight, inputClassName, multiline && styles.textarea];

  const element = multiline ? (
    <textarea rows={rows} {...inputProps} ref={inputRef} data-test={testId} />
  ) : (
    <input {...inputProps} ref={inputRef} data-test={testId} />
  );

  return (
    <BaseFormElement
      disabled={disabled}
      className={cx(className)}
      elementClassName={cx(...inputClassNames)}
      label={label}
      errorMessage={errorMessage}
      optionalLabel={optionalLabel}
      element={element}
      testId={testId}
    />
  );
}

export function FTextBox({ field, form, ...props }: FieldProps & Omit<TextBoxProps, "onChange" | "onBlur" | "value">) {
  return (
    <TextBox
      {...field}
      {...props}
      errorMessage={form.touched[field.name] ? (form.errors[field.name] as string) : undefined}
    />
  );
}
