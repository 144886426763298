import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { MutationFunction } from "@apollo/client";
import * as React from "react";
import * as _ from "lodash";
import * as yup from "yup";
import { RouteComponentProps, withRouter, Switch, Route, Redirect } from "react-router-dom";
import moment from "moment";
import { User, UserProfile } from "interfaces/user";
import LoadingContainer from "components/LoadingContainer";
import LegacyModal from "components/LegacyModal";
import ModalFooter from "components/ModalFooter";
import RouteTabs from "components/RouteTabs";
import LegacyText from "components/LegacyText";
import UserAvatar from "components/UserAvatar";
import Button from "components/Button";
import RadioButton from "components/RadioButton";
import RadioGroup from "components/RadioGroup";
import Form from "components/Form";
import ImageGroup from "components/ImageGroup";
import ImageOpener from "components/ImageGroup/ImageOpener";
import FilePicker from "components/Form/FilePicker";
import Shortcuts, { shortcut } from "components/Shortcuts";
import { getUrlQueries } from "utilities/routes";
import { Formik } from "formik";
import { inputProps } from "utilities/formik";
import TagsInput from "../../../../../components/TagsInput";
import styles from "./styles.scss";
import userQuery from "./userQuery.gql";
import organizationUserQuery from "./organizationUserQuery.gql";
import upsertOrganizationUser from "./upsertOrganizationUser.gql";
import { OrganizationUser } from "interfaces/user";
import { CloseModalHandler } from "utilities/modals";
import { OrganizationRouteParams } from "../../index";
import { TagGroup } from "gql-gen";
import { DocumentLink } from "modules/Dashboard/Schema/Documents/DocumentLink";
import cx from "classnames";
import { withUserInfoNoCache } from "modules/Dashboard/UserInfo";
interface GoGetterRouteParams extends OrganizationRouteParams {
  userId: string;
}

interface Props extends RouteComponentProps<GoGetterRouteParams> {
  handleClose: CloseModalHandler;
  user: User;
  userInfo: { me: User };
  data: {
    organizationUser: OrganizationUser;
    loading: boolean;
  };

  mutate: MutationFunction<any, any>;
}

interface UserDocument {
  id: string;
  url: string;
  name: string;
}

interface InternalValues {
  legalName: string | null;
  employeeId: string | null;
  tagIds: string[];
  organizationNotes: string;
  documents: UserDocument[];
  defaultRate: number | null;
  salaried: boolean;
}

interface State {
  isEditingInternal: boolean;
  initialValues: InternalValues;
}

interface Column {
  title?: string;
  content?: React.ReactNode | null;
}

interface OrgUserQuery {
  organizationUser: OrganizationUser;
}

const INTERNAL_DATA_SCHEMA = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string(),
});

class GogetterModal extends React.Component<Props, State> {
  readonly state: State = {
    isEditingInternal: false,
    initialValues: {
      legalName: null,
      employeeId: null,
      tagIds: [],
      organizationNotes: "",
      defaultRate: null,
      documents: [],
      salaried: false,
    },
  };

  static getDerivedStateFromProps(props: Props) {
    const {
      data: { organizationUser },
    } = props;

    if (organizationUser) {
      const { tags } = organizationUser;
      return {
        initialValues: {
          ..._.omit(organizationUser, ["__typename", "tags"]),
          tagIds: (tags && tags.map(t => t.id)) || [],
        },
      };
    }

    return {
      initialValues: {
        tagIds: [],
        organizationNotes: "",
        defaultRate: null,
        documents: [],
      },
    };
  }

  private renderInfoTable = (columns: Column[]) => {
    return (
      <div className={styles.infoTable}>
        {columns.map(({ title, content }) => {
          return content ? (
            <div className={styles.infoRow} key={title}>
              <LegacyText.H4 className={styles.title}>{title}</LegacyText.H4>
              <LegacyText.P4 kind={content ? "primary" : "tertiary"}>{content || "Not specified"}</LegacyText.P4>
            </div>
          ) : null;
        })}
      </div>
    );
  };

  private renderProfile = () => {
    const { profile, documents } = this.props.user;

    let data: UserProfile = {
      selfSummary: null,
      zip: null,
      shirtSize: null,
      birthday: null,
      canDrive: null,
      hasCar: null,
      languages: [],
      areas: null,
      address: null,
      apartment: null,
      shippingAddress: null,
      shipHome: null,
    };

    if (profile) {
      data = { ...profile };
    }

    const { selfSummary, zip, shirtSize, birthday, canDrive, hasCar, languages, areas, address, apartment } = data;

    const goGetterInfoColumns = [
      { title: "About", content: selfSummary },
      { title: "Home", content: zip },
      { title: "Shirt Size", content: shirtSize },
      {
        title: "Birthday",
        content: birthday && moment(birthday).format("MM/DD/YYYY").toString(),
      },

      { title: "Can drive?", content: canDrive === null ? undefined : canDrive ? "Yes" : "No" },
      { title: "Has car?", content: hasCar === null ? undefined : hasCar ? "Yes" : "No" },
      { title: "Languages spoken", content: languages && languages.map(({ label }) => label).join(", ") },
      { title: "Work area", content: areas },
      { title: "Address", content: address },
      { title: "Apt/Floor/Unit", content: apartment },
      {
        title: "Files",
        content: documents && documents.length > 0 && (
          <div className={styles.filesContainer}>
            {documents.map(d => (
              <DocumentLink key={d.id} document={d} />
            ))}
          </div>
        ),
      },
    ].filter(({ content }) => Boolean(content));

    const showProfile = goGetterInfoColumns.length > 0;

    return showProfile ? (
      this.renderInfoTable(goGetterInfoColumns)
    ) : (
      <LegacyText.P4 kind="tertiary">This user has no profile information</LegacyText.P4>
    );
  };

  private renderContact = (routeProps: RouteComponentProps<{ [x: string]: string | undefined }>) => {
    const { firstName, lastName, email, unconfirmedEmail, phone, unconfirmedPhone, isCorporate } = this.props.user;
    const {
      data: { organizationUser },
    } = this.props;
    const gigId = routeProps.match.params.gigId;
    const baseInfo = [{ title: "Full Name", content: `${firstName} ${lastName}` }];
    const contact = [
      { title: "Email", content: email || unconfirmedEmail },
      { title: "Mobile", content: phone || unconfirmedPhone },
    ];
    const contactInfo = isCorporate ? (organizationUser || gigId ? contact : []) : contact;
    return this.renderInfoTable([...baseInfo, ...contactInfo]);
  };

  private handleSubmit = async (values: InternalValues) => {
    const {
      mutate,
      match: {
        params: { orgId, userId },
      },
    } = this.props;

    await mutate({
      variables: {
        input: {
          ...values,
          documents: values.documents.map(d => ({ id: d.id, name: d.name, url: d.url })),
          organizationId: orgId,
        },
      },
      update: (cache, { data: { upsertOrganizationUser } }) => {
        const query = {
          query: organizationUserQuery,
          variables: {
            userId,
            orgId,
          },
        };
        const orgUserQuery = cache.readQuery<OrgUserQuery>(query);

        if (orgUserQuery && orgUserQuery.organizationUser) {
          cache.writeQuery({
            ...query,
            data: { organizationUser: upsertOrganizationUser },
          });
        }
      },
    });

    this.setState({ isEditingInternal: false });
  };

  private renderInternal = () => {
    const {
      data: { organizationUser, loading },
      match: {
        params: { orgId },
      },
    } = this.props;

    if (loading || !organizationUser) {
      return null;
    }
    const { initialValues } = this.state;

    const form = (
      <Formik<InternalValues>
        initialValues={initialValues}
        validationSchema={INTERNAL_DATA_SCHEMA}
        onSubmit={this.handleSubmit}
      >
        {props => {
          const input = inputProps(props);
          const { values, setFieldValue } = props;

          return (
            <React.Fragment>
              <LegacyText.Label2 className={styles.label}>Basic info</LegacyText.Label2>
              <Form.Section className={styles.section}>
                <Form.HorizontalGroup>
                  <Form.TextBox
                    {...input("legalName")}
                    className={cx(styles.textBox, styles.legalName)}
                    label="Legal Name"
                    placeholder="Type here..."
                  />
                  <Form.TextBox
                    {...input("employeeId")}
                    className={styles.textBox}
                    label="Employee Id"
                    placeholder="Type here..."
                  />
                </Form.HorizontalGroup>
              </Form.Section>
              <Form.Section className={styles.section}>
                <TagsInput
                  organizationId={orgId ? orgId : ""}
                  group={TagGroup.Users}
                  value={values.tagIds}
                  onChange={t => setFieldValue("tagIds", t)}
                />
              </Form.Section>
              <LegacyText.Label2 className={styles.label}>My notes</LegacyText.Label2>
              <Form.Section className={styles.section}>
                <Form.TextBox {...input("organizationNotes")} placeholder={"Organization Notes"} multiline />
              </Form.Section>
              <LegacyText.Label2 className={styles.label}>Default hourly rate</LegacyText.Label2>
              <Form.Section className={styles.section}>
                <div className={styles.moneyBoxInputContainer}>
                  <LegacyText.H3 className={styles.rate}>Default hourly rate</LegacyText.H3>
                  <Form.DecimalBox
                    onChange={rate => setFieldValue("defaultRate", rate)}
                    value={values && values.defaultRate}
                    className={styles.noBorderMoneyBox}
                  />
                </div>
              </Form.Section>
              <LegacyText.Label2 className={styles.label}>Employment type</LegacyText.Label2>
              <RadioGroup
                className={styles.section}
                value={values && values.salaried ? "w2" : "1099"}
                onChange={value => {
                  setFieldValue("salaried", value === "w2" ? true : false);
                }}
              >
                <RadioButton value="w2">Salaried/W2</RadioButton>
                <RadioButton value="1099">Freelancer/1099</RadioButton>
              </RadioGroup>
              <div className={styles.section}>
                <LegacyText.Label2 className={styles.label}>Documents</LegacyText.Label2>
                <FilePicker
                  size={77}
                  value={values.documents.map(({ url, name }) => ({ url, caption: name })) || []}
                  onChange={vals => {
                    setFieldValue(
                      "documents",
                      vals.map(({ url, caption }) => ({ url, name: caption })),
                    );
                  }}
                  caption
                  accepts={".pdf,.doc,.txt,.docx,.xls,.xlsx"}
                  secure
                />
              </div>
              <Button kind="primary" onClick={() => this.handleSubmit(values)} type="submit" className={styles.button}>
                Save internal info on gogetter
              </Button>
            </React.Fragment>
          );
        }}
      </Formik>
    );

    const tableColumns = [
      {
        title: "Legal Name",
        content: organizationUser.legalName,
      },
      {
        title: "Employee Id",
        content: organizationUser.employeeId,
      },
      {
        title: "Tags",
        content:
          organizationUser.tags && organizationUser.tags.length > 0 ? (
            <div className={styles.tagsContainer}>
              {organizationUser.tags.map((tag, i, arr) => (
                <LegacyText.P3 key={i} className={styles.tag}>
                  {tag.name}
                  {arr.length - 1 > i && ", "}
                </LegacyText.P3>
              ))}
            </div>
          ) : null,
      },
      {
        title: "My notes",
        content: organizationUser.organizationNotes,
      },
      {
        title: "Default hourly rate",
        content: organizationUser.defaultRate ? `$${organizationUser.defaultRate}/h` : null,
      },
      {
        title: "Employment type",
        content: organizationUser.salaried ? "Salaried/W2" : "Freelancer/1099",
      },
      {
        title: "Files",
        content:
          organizationUser.documents && organizationUser.documents.length > 0 ? (
            <div className={styles.filesContainer}>
              {organizationUser.documents.map(d => (
                <DocumentLink key={d.id} document={d} />
              ))}
            </div>
          ) : null,
      },
    ].filter(({ content }) => Boolean(content));

    const body = this.state.isEditingInternal ? form : this.renderInfoTable(tableColumns);

    return (
      <React.Fragment>
        {body}
        {!this.state.isEditingInternal && (
          <Button kind="primary" onClick={() => this.setState({ isEditingInternal: true })} className={styles.button}>
            Edit internal info on gogetter
          </Button>
        )}
      </React.Fragment>
    );
  };

  public render() {
    const {
      handleClose,
      user,
      data: { organizationUser, loading },
      match: { path, url },
      userInfo: { me },
      location,
    } = this.props;

    const viewingOwner = this.props.location.pathname.includes("/owner");

    const showContact =
      !!(user && (user.email || user.unconfirmedEmail)) &&
      (viewingOwner || organizationUser || me?.organizationUser?.organizationType !== "client");
    const showInternal = !!organizationUser;
    const showProfile =
      user && user.isCorporate
        ? organizationUser
          ? !!(user && user.profile)
          : false
        : !!(user && user.profile) && (organizationUser || me?.organizationUser?.organizationType !== "client");

    const showCount = [showProfile, showContact, showInternal].filter(f => f).length;

    const pics =
      user && user.assets
        ? _.filter(
            _.sortBy(user.assets, a => (a.isPrimary ? 0 : 1)),
            { assetType: "profile_image" },
          )
        : [];

    const hc = () => handleClose();

    return (
      <Shortcuts shortcuts={[shortcut("esc", hc)]}>
        <LegacyModal noPadding className={styles.modal}>
          {user ? (
            <ImageGroup>
              {pics.map((p, i) => (
                <ImageOpener src={p.url} caption="" key={i}>
                  {i === 0 && <UserAvatar user={user} square fadeToWhite className={styles.userModal} />}
                </ImageOpener>
              ))}
            </ImageGroup>
          ) : (
            <UserAvatar user={user} square fadeToWhite className={styles.userModal} />
          )}

          {!user ? (
            <LoadingContainer message="Loading details..." center className={styles.loadingDetails} />
          ) : (
            <div className={styles.userInfo}>
              <LegacyText.Display2 className={styles.userName}>
                {user.firstName}{" "}
                {user.kind === "human" && !showInternal ? `${user.lastName.charAt(0)}.` : user.lastName}
              </LegacyText.Display2>
              {showCount > 1 && (
                <RouteTabs
                  compact
                  invertedBg
                  tabs={[
                    showContact ? { title: "Contact", path: "contact/:gigId" } : null,
                    showProfile ? { title: "Profile", path: "profile" } : null,
                    showInternal ? { title: "Internal", path: "internal" } : null,
                  ]}
                />
              )}
              <Switch>
                {(loading || showContact) && <Route path={`${path}/contact/:gigId`} render={this.renderContact} />}
                {(loading || showProfile) && <Route path={path + "/profile"} render={this.renderProfile} />}
                {(loading || showInternal) && <Route path={path + "/internal"} render={this.renderInternal} />}
                {showCount > 0 && <Redirect to={{ ...location, pathname: url + "/profile" }} />}
              </Switch>
            </div>
          )}

          <ModalFooter cancelButtonText="CLOSE" onCancel={hc} hideAction />
        </LegacyModal>
      </Shortcuts>
    );
  }
}

export default compose(
  withRouter,
  withUserInfoNoCache,
  graphql<Props, { organizationUser: OrganizationUser }, { userId: string; orgId: string }, unknown>(
    organizationUserQuery,
    {
      options: props => {
        return {
          variables: {
            userId: props.match.params.userId,
            orgId: props.match.params.orgId,
          },
        };
      },
    },
  ),

  graphql<any, any, any, any>(userQuery, {
    options: props => {
      const params = getUrlQueries(props.location.search);
      let gigId;

      if (params.continue) {
        const match = params.continue.match(/\+assign-gigs\/([0-9a-f-]+)\//);
        if (match && match.length > 1) {
          gigId = match[1];
        }
      } else if (props.location.pathname.includes("/contact/")) {
        const match = props.location.pathname.match(/\/contact\/([0-9a-f-]+)\//);
        if (match && match.length > 1) {
          gigId = match[1];
        }
      } else {
        const match = props.location.pathname.match(/\+view\/([0-9a-f-]+)\//);
        if (match && match.length > 1) {
          gigId = match[1];
        }
      }

      return {
        variables: {
          userId: props.match.params.userId,
          gigId,
        },
      };
    },
    props: ({ data }) => data,
  }),

  graphql(upsertOrganizationUser),
)(GogetterModal);
