import React, { useEffect, useState } from "react";
import cx from "classnames";
import styles from "./styles.scss";
import { useQuery } from "@apollo/client";
import useRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";

import Icon from "components/Icon";
import Text from "components/Text";
import RoundButton from "components/RoundButton";
import DropdownMenu from "components/DropdownMenu";
import Button from "components/Button";
import { Mobile } from "components/Layout/Responsive";
import { helpCenter } from "support-articles";
import {
  getAppUrl,
  getBaseAppUrl,
  USER_ORGANIZATION_PLACEHOLDER,
  addQueries,
  getProgramIdFromSearch,
} from "utilities/routes";
import { logout } from "utilities/authentication";
import variables, { colors } from "styles/variables";
import SearchableBigSelect from "../../../../components/LegacySearchable/SearchableBigSelect";
import myOrganizationsQuery from "./myOrganizations.gql";
import organizationQuery from "./organization.gql";
import programGroupsQuery from "./programGroupsQuery.gql";
import { usesHashRouter } from "utilities/environment";
import { isHayday } from "utilities/flavor";

import userIcon from "assets/user-circle.svg";
import secretIcon from "assets/secret.svg";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { UserLink } from "modules/Dashboard/UserLink";
import { NavHeaderOrganizationQueryVariables, NavHeaderOrganizationQuery } from "gql-gen";
import { canSeeCompanySettings, isUserAtLeast } from "interfaces/user";
import { programsRouteName } from "../Programs";

interface NavAction {
  text: string;
  onClick?: React.MouseEventHandler;
  href?: string;
  testId?: string;
}

export interface Tab {
  name: string;
  link?: string;
  remote?: boolean;
  disabled?: string | null;
  icon: any;
}

interface Props {
  children?: React.ReactNode;
  action?: NavAction;
  tabs?: Tab[];
  client?: any;
}
export default function NavHeader(props: Props) {
  const {
    match: {
      params: { id, orgId, page, programGroupId },
    },
    location,
    history,
  } = useRouter<{ id: string; orgId: string; page: string; programGroupId: string }>();

  const programIds = getProgramIdFromSearch(location.search);
  const { data: userOrgs, loading: organizationsLoading } = useQuery(myOrganizationsQuery, { variables: { id } });
  const {
    data: programGroupsData,
    loading: programGroupsLoading,
    refetch: refetchProgramGroups,
  } = useQuery(programGroupsQuery, { fetchPolicy: "network-only" });

  const { data: orgData, loading: orgDataLoading } = useQuery<
    NavHeaderOrganizationQuery,
    NavHeaderOrganizationQueryVariables
  >(organizationQuery, { variables: { orgId }, skip: orgId === USER_ORGANIZATION_PLACEHOLDER });
  const { me, impersonator } = useUserInfo();

  const { t } = useTranslation();

  const [showShadow, setShowShadow] = useState(false);

  function handleScroll() {
    if (!showShadow && window.scrollY > 0) {
      setShowShadow(true);
    } else if (showShadow && window.scrollY <= 0) {
      setShowShadow(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function renderMenu() {
    return (
      <div className={styles.menuContent}>
        <Button className={styles.menuItem} href={getAppUrl("go", `${orgId || "_"}`)}>
          <Text size={12} font={"wes"} bold>
            {t("goToGo")}
          </Text>
        </Button>
        {me?.isAdmin && (
          <Button className={styles.menuItem} href={getAppUrl("admin")}>
            <Text size={12} font={"wes"} bold>
              {t("goToAdmin")}
            </Text>
          </Button>
        )}

        <Button
          className={styles.menuItem}
          href={getAppUrl("accounts", `${orgId || "_"}/my-account`, { ref: "dashboard" })}
        >
          <Text size={12} font={"wes"} bold>
            {t("myaccount")}
          </Text>
        </Button>

        {me?.organizationUser &&
          (orgData?.organization?.isWorkspace === false || me.isAdmin || me?.organizationUser?.type === "owner") &&
          canSeeCompanySettings(me?.organizationUser?.type) && (
            <Link to={{ ...location, pathname: `/${orgId || ""}/settings` }}>
              <Button className={styles.menuItem}>
                <Text size={12} font={"wes"} bold>
                  {t("mycompany")}
                </Text>
              </Button>
            </Link>
          )}
        {isHayday && isUserAtLeast(me, "admin") && (
          <Button className={styles.menuItem} href={getBaseAppUrl("dashboard") + "#/self-setup"}>
            <Text size={12} font={"wes"} bold>
              {t("setupWizard")}
            </Text>
          </Button>
        )}
        {isUserAtLeast(me, "manager") && (
          <Link to={{ ...location, pathname: `/${orgId || ""}/invoices`, search: "" }}>
            <Button className={styles.menuItem}>
              <Text size={12} font={"wes"} bold>
                {t("invoices")}
              </Text>
            </Button>
          </Link>
        )}
        <a href="mailto:support@gopinata.com" className={cx(styles.menuItem, styles.mailto)}>
          <Text size={12} font={"wes"} bold>
            {t("chatWithUs")}
          </Text>
        </a>
        <Button className={styles.menuItem} href={helpCenter}>
          <Text size={12} font={"wes"} bold>
            {t("helpCenter")}
          </Text>
        </Button>
        <Button className={styles.menuItem} onClick={() => logout()}>
          <Text size={12} font={"wes"} bold>
            {t("logOut")}
          </Text>
        </Button>
      </div>
    );
  }

  function renderCustomAction(onClick?: () => void) {
    const { action } = props;

    if (action) {
      const { text, onClick: onAction, href, testId } = action;

      let actionView = (
        <Button
          testId={testId}
          kind="primaryGradient"
          onClick={e => {
            onClick && onClick();
            onAction && onAction(e);
          }}
        >
          {text}
        </Button>
      );

      if (href) {
        actionView = <Link to={href}>{actionView}</Link>;
      }

      return <div>{actionView}</div>;
    }

    return null;
  }

  function renderTabs(isMobile: boolean) {
    const { tabs } = props;

    const activeTab = tabs && tabs.find(tab => tab.link && location.pathname.includes(tab.link));

    const items =
      tabs &&
      tabs.map((tab, index) => {
        const active = tab === activeTab;

        const label = isMobile ? (
          <div className={cx(styles.mobileTab)}>
            <Icon src={tab.icon} fill={isMobile && active ? variables.gray1 : variables.white} width={30} />
            {active && (
              <Text
                size={isMobile ? 10 : 12}
                font={"wes"}
                bold
                color={active ? "gray1" : "white"}
                className={cx(tab.disabled ? styles.disabledLabel : styles.label)}
                tooltip={tab.disabled || undefined}
                transform={"uppercase"}
                letterSpacing={1.2}
              >
                {tab.name}
              </Text>
            )}
          </div>
        ) : (
          <Text
            size={12}
            font={"wes"}
            bold
            color={active ? "gray1" : "white"}
            className={cx(tab.disabled ? styles.disabledLabel : styles.label)}
            tooltip={tab.disabled || undefined}
            transform={"uppercase"}
            letterSpacing={1.2}
          >
            {tab.name}
          </Text>
        );

        return (
          <div
            className={cx(styles.tab, active && styles.activeTab)}
            key={index}
            data-test={`${tab.name.toLowerCase()}-tab`}
          >
            {tab.disabled ? (
              label
            ) : tab.remote ? (
              <a
                href={!tab.disabled && tab.link ? tab.link : "javascript:void()"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </a>
            ) : (
              <Link to={{ pathname: tab.link || "", search: location.search }}>{label}</Link>
            )}
          </div>
        );
      });

    return (
      items &&
      items.length > 0 && (
        <div className={styles.tabBackground}>
          <div className={isHayday ? styles.contentWrapperPronto : styles.contentWrapper}>
            <div className={styles.tabs}>{items}</div>
          </div>
        </div>
      )
    );
  }

  return (
    <>
      <Mobile>
        {mobile => (
          <>
            <div className={cx(styles.topNav, showShadow && styles.topNavShadow)}>
              <div className={isHayday ? styles.contentWrapperPronto : styles.contentWrapper}>
                <div className={styles.whiteLeft}>
                  <div className={styles.orgAndLogo}>
                    {!orgDataLoading && !mobile && (
                      <Text
                        size={12}
                        color={"gray1"}
                        font={"wes"}
                        letterSpacing={1.2}
                        transform={"uppercase"}
                        className={styles.alignOrg}
                        lineHeight={10}
                      >
                        {_.get(orgData, "organization.name")}
                      </Text>
                    )}
                  </div>

                  {me && impersonator && (
                    <div className={styles.impersonating}>
                      <Icon src={secretIcon} size={14} fill={colors.purple} />
                      <UserLink id={impersonator.id}>
                        <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                          {impersonator.firstName[0]} {impersonator.lastName[0]}
                        </Text>
                      </UserLink>
                      <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                        as
                      </Text>
                      <UserLink id={me.id}>
                        <Text size={12} font="wes" color="purple" bold lineHeight={10} left="xs">
                          {me.firstName} {me.lastName[0]}. ({me.organizationUser?.type})
                        </Text>
                      </UserLink>
                    </div>
                  )}
                </div>
                <div className={styles.accountAndGigs} style={{ display: "flex" }}>
                  <DropdownMenu
                    showArrow={!mobile}
                    arrowColor={"gray4"}
                    menuClassName={styles.menu}
                    renderMenu={renderMenu}
                    testId={"navHeader.settings"}
                  >
                    {mobile ? (
                      <RoundButton>
                        <Icon src={userIcon} size={42} fill={variables.gray4} />
                      </RoundButton>
                    ) : (
                      <Button>
                        <Text
                          size={12}
                          color={"gray1"}
                          font={"wes"}
                          letterSpacing={1.5}
                          transform={"uppercase"}
                          className={styles.alignOrg}
                          lineHeight={10}
                        >
                          {t("menu")}
                        </Text>
                      </Button>
                    )}
                  </DropdownMenu>
                  {renderCustomAction(location.pathname.includes("settings") ? refetchProgramGroups : undefined)}
                </div>
              </div>
            </div>
            <div className={styles.children}>
              <div
                className={cx(isHayday ? styles.contentWrapperPronto : styles.contentWrapper, mobile && styles.pickers)}
              >
                {!organizationsLoading && userOrgs?.me?.organizations?.length > 1 && (
                  <SearchableBigSelect
                    testId={"navHeader.organizationSelector"}
                    className={styles.organizationPicker}
                    inputClassName={styles.orgInput}
                    clearSearchOnSelect={true}
                    items={userOrgs.me.organizations}
                    selectedItem={orgId}
                    onChange={item => {
                      if (usesHashRouter()) {
                        window.location.href = `#/${item.id}`;
                        window.location.reload();
                      } else {
                        window.location.href = `/${item.id}`;
                      }
                    }}
                    optionsYOffset={1}
                    selectProps={{
                      name: "Organization",
                      compact: mobile,
                    }}
                  />
                )}
                {!programGroupsLoading &&
                  orgId &&
                  location.pathname.includes("programs") &&
                  orgData?.organization?.programGroupsVisible && (
                    <SearchableBigSelect
                      testId={"navHeader.programGroupSelector"}
                      className={styles.organizationPicker}
                      inputClassName={styles.orgInput}
                      clearSearchOnSelect={true}
                      items={[{ name: "All", id: "all" }, ...programGroupsData?.programGroups]}
                      selectedItem={programGroupId}
                      onChange={item => {
                        if (item.id === "all") {
                          history.replace({
                            ...location,
                            pathname: `/${orgId}/program-group/all/${programsRouteName}/${page ? "/" + page : ""}`,
                          });
                        } else {
                          const programIds = item.programIds.join(",");
                          history.replace({
                            ...location,
                            pathname: `/${orgId}/program-group/${item.id}/${programsRouteName}/${page ? "/" + page : ""
                              }`,
                            search: addQueries(location.search, { program: programIds }),
                          });
                        }
                      }}
                      optionsYOffset={1}
                      selectProps={{
                        name: "Program Group",
                        compact: mobile,
                      }}
                    />
                  )}
                <div className={cx(styles.programPicker)}>{props.children}</div>
              </div>
            </div>
            {renderTabs(mobile)}
          </>
        )}
      </Mobile>
    </>
  );
}
