import * as React from "react";
import cx from "classnames";
import Form from "components/Form";
import Button from "components/Button";
import styles from "./styles.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  groupClassName?: string;
  onGo?: () => void;
  goText?: string;
  disabledGo?: boolean;
  transparent?: boolean;
  alreadyWrapped?: boolean;
  filterOnChange?: boolean;
}

export default function TableFilters({
  children,
  className,
  groupClassName,
  disabledGo,
  onGo,
  goText = "Go",
  transparent,
  alreadyWrapped,
  filterOnChange = false,
}: Props) {
  const content = (
    <Form.Section className={cx(styles.tableFilters, transparent && styles.transparent, className)}>
      <Form.HorizontalGroup className={groupClassName}>
        {children}
        {onGo && !filterOnChange && (
          <Button
            kind={"primary"}
            className={styles.goButton}
            disabled={disabledGo}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onGo();
            }}
          >
            {goText}
          </Button>
        )}
      </Form.HorizontalGroup>
    </Form.Section>
  );

  if (onGo && !disabledGo && !alreadyWrapped) {
    return <form onSubmit={() => onGo && onGo()}>{content}</form>;
  }

  return content;
}
