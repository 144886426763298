import moment from "moment";
import { zonedTimeToUtc } from "date-fns-tz";

import { Location } from "gql-gen";

import { isVirtualLocation } from "./location";
import { getTimeZone } from "./timezones";

export function getGigDuration(gig: {
  startTime?: moment.MomentInput | null;
  endTime?: moment.MomentInput | null;
}): number {
  if (!gig.startTime || !gig.endTime) return 0;
  return moment(gig.endTime).diff(moment(gig.startTime), "hours", true);
}

type Loc = Pick<Location, "externalId" | "latitude" | "longitude">;

export async function getTimeWithZone(date: Date, location?: Loc | null) {
  if (isVirtualLocation(location ?? undefined)) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return { zonedDate: zonedTimeToUtc(date, timezone), timezone };
  }

  let timezone = "GMT";

  const hasLatAndLong = location && location.latitude && location.longitude;

  if (hasLatAndLong) {
    const coords = { lat: location.latitude!, lng: location.longitude! };
    timezone = (await getTimeZone(coords, date)).timeZoneId;
  }

  return { zonedDate: zonedTimeToUtc(date, timezone), timezone: hasLatAndLong ? timezone : null };
}

export function joinDateAndTime(date: Date, time: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  );
}
