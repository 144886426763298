import * as React from "react";
import cx from "classnames";
import styles from "./styles.scss";

export interface ClickableProps {
  testId?: string;
  children: React.ReactNode;
  style?: any;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  className?: string;
  actionLabel?: string;
  disabled?: boolean;
  role?: string;
  tabIndex?: number;
  clickableRef?: React.RefObject<HTMLDivElement>;
}

export default function Clickable({
  children,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  actionLabel,
  style,
  role = "button",
  tabIndex = 0,
  clickableRef,
  testId,
}: ClickableProps) {
  const handleKey = (e: React.KeyboardEvent) => {
    if (onClick && e.currentTarget === e.target && e.key === "Enter") {
      onClick(e);
    }
  };

  return (
    <div
      data-test={testId}
      tabIndex={tabIndex}
      onKeyUp={handleKey}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(styles.clickable, className)}
      style={style}
      role={role}
      title={actionLabel}
      aria-label={actionLabel}
      aria-disabled={disabled}
      ref={clickableRef}
    >
      {children}
    </div>
  );
}
