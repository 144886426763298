@import "~styles/variables";

$header-padding: $s;

.columnHeader {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  background-image: $bar-gradient-up-down;
  border-right: 1px solid white;

  &:focus {
    box-shadow: none;
    border-radius: 0;
    background-color: darken($gray-3, 10%);
  }
}

.ieHeader {
  flex: 1 1 auto;
}

.title,
.icon {
  z-index: 1;
}

.filterIcon {
  @include transitionify(transform);
  transform: scale(1);
}

.filterIcon,
.clearIcon {
  &:focus {
    box-shadow: none;
  }
}

.expandedFilterIcon {
  composes: filterIcon;
  transform: scale(80);
  // transform-origin: 0.4625em 0.30625em;
}

.clearIcon {
  position: absolute;
  top: 9px;
  right: $s;
  opacity: 0;
  @include transitionify(opacity);
}

.clearIconVisible {
  composes: clearIcon;
  opacity: 1;
  z-index: 2;
}

.clearIconInitial {
  position: initial;
  margin-right: $l;
}

.columnHeaderBody {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 34px;
  align-items: center;
  flex: 1;
  padding: 0 $header-padding;
}

.activeCompact {
  background-color: $teal-1;
}

.compact {
  flex-direction: column;

  > :last-child {
    width: 100%;
  }

  @include mobile {
    > :last-child {
      width: unset;
      overflow: hidden;
    }
  }
}

.openCompactPopup {
  > :last-child {
    overflow: unset;
  }
}

.compactColumnHeaderBody {
  height: 100%;
  padding: 0;
  align-items: unset;
  flex-direction: column;
}

.compactColumnHeader {
  align-items: center;
  border-right: none;
}

.compactHeaderBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: normal;
  min-height: 70px;
  padding: 0 10px;
  width: 100%;
  z-index: 1;
}

.open {
  max-height: 100%;
}

.closed {
  max-height: 0;
}
