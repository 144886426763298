import React from "react";
import { useTranslation } from "react-i18next";
import { IAction } from "modules/Connection/types";
import verifyIcon from "assets/verify.svg";
import { colors } from "styles/variables";
import { ActionBody } from "modules/Connection/ActionBody";
import useRouter from "use-react-router";
import { Elm } from "components/Elm";
import OwnerVerifiedElm from "./OwnerVerified.elm";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import getServerRootUrl, { getHaskellBackendUrl, getRustBackendUrl } from "utilities/getServerRootUrl";
import { getJwt } from "utilities/authentication";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = "gigs.actions.requestApproval";

export const OwnerVerified: IAction = {
  key: "reportOwnerVerified",
  icon: verifyIcon,
  //labelKey: `${i18n}.label`,
  labelKey: `report verification`,
  color: colors.teal1,
  allowInline: false,
  allowBatch: false,

  component: ({ variables, done, renderSubmit }) => {
    const { t } = useTranslation();
    const {
      match: {
        params: { orgId, gigId },
      },
      location,
    } = useRouter<ProgramsRouteParams & { gigId?: string }>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <ActionBody done={done}>
        <Elm
          key={"active"}
          src={OwnerVerifiedElm.Elm.OwnerVerified}
          flags={{
            session: {
              serverUrl: getServerRootUrl() + "/graphql", // getNewServerRootUrl()
              haskellBackendBaseUrl: getHaskellBackendUrl(),
              rustBackendBaseUrl: getRustBackendUrl(),
              authToken: getJwt(),
            },
            gigId: gigId,
            organizationId: orgId,
            programIds: id === "all" ? [] : id.split(","),
            // filters,
            // saveToGig: location.pathname.includes("+open-to"),
            // defaultReplyTo: me?.email || "",
          }}
          ports={() => {}}
        />
      </ActionBody>
    );
  },
};
