import { Query } from "@apollo/client/react/components";
import * as React from "react";
import Form from "components/Form";
import { DropdownProps } from "components/Form/Dropdown";
import { TextBoxProps } from "components/Form/TextBox";
import optionsQuery from "./options.gql";
import { OptionConfig } from "modules/Dashboard/interfaces/OptionConfig";

interface Props {
  group: string | null;
  ddProps: Partial<DropdownProps>;
  descriptionPolicy?: "always" | "onOther";
  descProps?: Partial<TextBoxProps>;
  testId?: string;
  className?: string;
}

interface OptionsQuery {
  options: OptionConfig[];
}

export default function OptionsDropdown({
  group,
  ddProps,
  descriptionPolicy = "always",
  descProps,
  testId,
  className,
}: Props) {
  return (
    <Query<OptionsQuery> query={optionsQuery} variables={{ group: [group] }} skip={!group}>
      {({ data: { options } = { options: null } }) => {
        let optionViews;

        if (options) {
          optionViews = [
            <option key={"_"} value={""} disabled>
              Please select…
            </option>,

            ...options.map(({ key, label }) => (
              <option key={key} value={key}>
                {label}
              </option>
            )),
          ];
        } else {
          optionViews = (
            <option disabled value={""}>
              Loading…
            </option>
          );
        }

        const dropdown = (
          <Form.Dropdown value={""} {...ddProps} testId={testId} className={className}>
            {optionViews}
          </Form.Dropdown>
        );

        let description;

        if (
          descProps &&
          (descriptionPolicy === "always" || (descriptionPolicy === "onOther" && ddProps.value === "other"))
        ) {
          description = (
            <Form.TextBox
              placeholder={
                ddProps.value === "other" ? "Please describe…" : `Add details... ${descProps.label ? "" : "(Optional)"}`
              }
              {...descProps}
              disabled={!ddProps.value || ddProps.value === "" || descProps.disabled}
            />
          );
        }

        return (
          <React.Fragment>
            {dropdown}
            {description}
          </React.Fragment>
        );
      }}
    </Query>
  );
}
