import React, { useEffect, useState, useContext } from "react";
import { Field, FieldProps, FormikHelpers } from "formik";
import { match, P } from "ts-pattern";
import {
  GigPartnerFieldFragment,
  GigPartnerFormProviderFragment,
  PartnerFieldMyOrganizationQuery,
  PartnerFieldMyOrganizationQueryVariables,
  PartnerFieldProgramRequestQuery,
  PartnerFieldProgramRequestQueryVariables,
  PartnerFieldProgramPartnersQuery,
  PartnerFieldProgramPartnersQueryVariables,
} from "gql-gen";
import SearchableSelect from "components/LegacySearchable/SearchableSelect";
import Text from "components/Text";
import { bin } from "utilities/knueppel";
import { ComposableField } from "modules/Connection/ComposableForm";
import { GigProgramField, GigProgramValue } from "../Program";

import partnerFragment from "./partner.gql";
import partnerFormProviderFragment from "./partnerFormProvider.gql";
import FPartnerPicker from "./PartnerPicker";
import styles from "./styles.scss";

import { FormProvider } from "modules/Connection/types";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import Clickable from "components/Clickable";
import lockIcon from "assets/lock.svg";
import { useQuery } from "@apollo/client";
import myOrganizationQuery from "./myOrganization.gql";
import programRequestQuery from "./programRequest.gql";
import programPartnersQuery from "./programPartners.gql";
import useRouter from "use-react-router";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import Icon from "components/Icon";
import { colors } from "styles/variables";
import LoadingContainer from "components/LoadingContainer";
import { isUserAtLeast } from "interfaces/user";
import { useUserInfo } from "modules/Dashboard/UserInfo";
import { usePrevious } from "hooks/usePrevious";
import { BlockFormContext } from "modules/Connection/Block";
import Section from "components/Form/Section";

type ProgramRequest = Exclude<PartnerFieldProgramRequestQuery["programRequest"], undefined | null>;
type ClientOrg = ProgramRequest["clientOrganizations"][number];

export interface GigPartnerValues {
  iAmTalentOrg: boolean;
  partnerOrganizationId?: string;
  /**
   * If you have no partners to delegate to but have clients that added you to
   * the program, you're a "talent organization" and can select the client
   * you're executing the task for, meaning they are the manager org.
   */
  clientOrganizationId?: string;
  myOrgId?: string;
}

function GigClientAssignmentForm(props: {
  clientOrgs: ReadonlyArray<ClientOrg>;
  isEditing: boolean;
  hasSubmitted: boolean;
  inline: boolean;
  setFieldValue: (fieldName: string, value: string) => void;
}) {
  const { clientOrgs, isEditing, hasSubmitted, inline, setFieldValue } = props;
  const fieldName = "clientOrganizationId";

  React.useEffect(() => {
    match(clientOrgs)
      .with([{}], ([client]) => setFieldValue(fieldName, client.organization.id))
      .otherwise(() => {});
  }, [clientOrgs]);

  function viewClientsSelect(clients: ReadonlyArray<ClientOrg>) {
    return (
      <Field
        name={fieldName}
        component={({ form }: FieldProps<GigPartnerValues, GigPartnerValues>) => {
          return (
            <div className={inline ? undefined : styles.clientAssignmentForm}>
              <Text color={inline ? "white" : "gray3"} font="wes" bold size={22} bottom="s">
                Task Manager
              </Text>
              {!isEditing && (
                <Text color={inline ? "white" : "gray2"} size={14} bottom="m">
                  Select a partner for this task:
                </Text>
              )}
              <Section>
                <SearchableSelect
                  testId="select-program-manager-partner"
                  disabled={
                    // Currently not allowing talent orgs to edit the manager partner
                    isEditing || hasSubmitted
                  }
                  name="SELECT MANAGER PARTNER"
                  items={clients.map(client => ({
                    id: client.organization.id,
                    name: client.organization.name,
                  }))}
                  selectedItem={form.values.clientOrganizationId}
                  errorMessage={form.touched.clientOrganizationId ? form.errors.clientOrganizationId : undefined}
                  onChange={org => setFieldValue(fieldName, org.id)}
                />
              </Section>
            </div>
          );
        }}
      />
    );
  }

  return match(clientOrgs)
    .with([{ isOwner: true }], ([client]) => {
      return isEditing ? viewClientsSelect([client]) : <></>;
    })
    .otherwise(viewClientsSelect);
}

interface PartnerAssignmentFormProps extends Pick<FormikHelpers<GigPartnerValues>, "setFieldValue"> {
  areAllPartnersWorkspaces: boolean;
  disabled: boolean;
  hasProgramPartners: boolean;
  inline: boolean;
  isAssignedInternally: boolean;
  myOrganizationName: string;
  partialBatchUpdate: boolean;
  programId?: string;
  programOwnerId?: string;
}

function PartnerAssignmentForm(props: PartnerAssignmentFormProps) {
  const {
    areAllPartnersWorkspaces,
    disabled,
    hasProgramPartners,
    inline,
    isAssignedInternally,
    myOrganizationName,
    partialBatchUpdate,
    programId,
    programOwnerId,
    setFieldValue,
  } = props;
  const [seeAllPartners, setSeeAllPartners] = useState(false);
  const [assignmentType, setAssignmentType] = useState(
    hasProgramPartners && !isAssignedInternally ? "partner" : "internal",
  );
  const { me } = useUserInfo();
  const previousAssignmentType = usePrevious(assignmentType);
  const {
    location: { pathname },
  } = useRouter();

  useEffect(() => {
    if (assignmentType === "partner" && (previousAssignmentType === "internal" || pathname.includes("book-gigs"))) {
      setFieldValue("partnerOrganizationId", null);
    }
  }, [assignmentType]);

  return (
    <>
      {!areAllPartnersWorkspaces && (
        <RadioGroup
          className={styles.assignmentType}
          onChange={selectedAssignmentType => {
            if (!selectedAssignmentType || selectedAssignmentType === "internal") {
              setFieldValue("partnerOrganizationId", undefined);
            } else {
              setFieldValue("partnerOrganizationId", null);
            }

            setAssignmentType(selectedAssignmentType || "internal");
          }}
          value={assignmentType}
        >
          <RadioButton disabled={disabled} reverse={inline} value="internal" testId="assignment-type-internal-teams">
            <span style={inline ? { color: "white" } : undefined}>{myOrganizationName} Internal team</span>
          </RadioButton>
          <RadioButton disabled={disabled} reverse={inline} value="partner" testId="assignment-type-partner">
            <span style={inline ? { color: "white" } : undefined}>
              A <span className={styles.partnerHi}>Partner</span> of {myOrganizationName}
            </span>
          </RadioButton>
        </RadioGroup>
      )}

      {(assignmentType === "partner" || areAllPartnersWorkspaces) && (
        <>
          <Field
            name={seeAllPartners ? "disabledPartnerOrganizationId" : "partnerOrganizationId"}
            component={FPartnerPicker}
            compact
            disabled={seeAllPartners || disabled}
            programId={programId}
            inlineForm={inline}
            partialBatchUpdate={partialBatchUpdate}
            className={seeAllPartners ? styles.disabledSeeAllPartners : null}
            testId="select-program-manager-partner"
            label={areAllPartnersWorkspaces ? "SELECT WORKSPACE" : undefined}
          />
          {!partialBatchUpdate && (
            <>
              <Text size={14} bottom="m" color={inline ? "white" : "gray2"}>
                Note: This list includes Task Partners that are active on this Program.
              </Text>
              {isUserAtLeast(me, "manager") && (
                <Text size={14} bottom="m" color={inline ? "white" : "gray2"}>
                  Don’t see the Partner you’re looking for?
                  <Clickable onClick={() => setSeeAllPartners(!seeAllPartners)}>
                    <Text size={14} color={seeAllPartners ? "teal1" : "blue2"} top="xs" font="wes" bold>
                      Select from all Partners.
                    </Text>
                  </Clickable>
                </Text>
              )}

              {seeAllPartners && (
                <Field
                  name="partnerOrganizationId"
                  component={FPartnerPicker}
                  label="SELECT FROM ALL PARTNERS"
                  compact
                  programId={null}
                  inlineForm={inline}
                  partialBatchUpdate={partialBatchUpdate}
                  testId="select-all-manager-partner"
                  disabled={disabled}
                  filters={programOwnerId ? bin("id", "!=", programOwnerId) : undefined}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

let pickerType: "Partner" | "Workspace";

interface GigPartnerFieldContentProps extends Pick<FormikHelpers<GigPartnerValues>, "setFieldValue"> {
  clientOrganizationId?: string;
  inline: boolean;
  isAssignedInternally: boolean;
  isEditing: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  partialBatchUpdate: boolean;
  programId: string;
}

export const GigPartnerFieldContent = (props: GigPartnerFieldContentProps) => {
  const {
    inline,
    isAssignedInternally,
    isEditing,
    isSubmitting,
    isValid,
    partialBatchUpdate,
    programId,
    setFieldValue,
  } = props;
  const {
    match: {
      params: { orgId },
    },
  } = useRouter<OrganizationRouteParams>();

  const { data: myOrganizationData } = useQuery<
    PartnerFieldMyOrganizationQuery,
    PartnerFieldMyOrganizationQueryVariables
  >(myOrganizationQuery, {
    variables: { organizationId: orgId },
  });

  const { setCanSave } = useContext(BlockFormContext);
  const myOrganization = myOrganizationData?.organization;

  const { data: programPartnersData } = useQuery<
    PartnerFieldProgramPartnersQuery,
    PartnerFieldProgramPartnersQueryVariables
  >(programPartnersQuery, {
    variables: { programId: programId },
  });

  const programPartners = React.useMemo(() => {
    return programPartnersData?.organizationConnectionsPartners.edges.map(({ node }) => node);
  }, [programPartnersData]);

  const { data: programRequestData } = useQuery<
    PartnerFieldProgramRequestQuery,
    PartnerFieldProgramRequestQueryVariables
  >(programRequestQuery, {
    variables: { id: programId },
  });

  const programRequest = programRequestData?.programRequest;
  const hasPartners = (programPartners?.length ?? 0) > 0;
  const hasClients = (programRequest?.clientOrganizations?.length ?? 0) > 0;
  const isTalentOrg = !hasPartners && hasClients;
  const areAllPartnersWorkspaces = !programPartners?.some(({ isWorkspace }) => !isWorkspace) || false;
  const myOrganizationName = myOrganization?.name ?? "My company";
  const executingOrganizationName = myOrganization?.parentOrganization?.name ?? myOrganizationName;
  const isMyOrganizationWorkspace = myOrganization?.isWorkspace || false;
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  let title = "Task Manager";
  if (areAllPartnersWorkspaces) {
    title = "Workspaces";
  }
  if (isMyOrganizationWorkspace) {
    title = "Staffing";
  }

  React.useEffect(() => {
    if (isSubmitting && isValid) {
      setHasSubmitted(true);
    }
  }, [isSubmitting]);

  React.useEffect(() => {
    if (isTalentOrg) {
      setFieldValue("iAmTalentOrg", true);
    }
  }, [isTalentOrg]);

  React.useEffect(() => {
    if (programRequest?.gigPartnerAssignment?.canAssign) {
      setFieldValue("myOrgId", orgId);
    }
  }, [programRequest?.gigPartnerAssignment?.canAssign]);

  React.useEffect(() => {
    pickerType = areAllPartnersWorkspaces ? "Workspace" : "Partner";
  }, [areAllPartnersWorkspaces]);

  React.useEffect(() => {
    setCanSave(hasPartners || hasClients);
  }, [hasPartners, hasClients]);

  if (!programPartners || !programRequest || !programRequest.gigPartnerAssignment || !myOrganization) {
    return <LoadingContainer />;
  }

  const partnerAssignmentForm = (
    <PartnerAssignmentForm
      inline={inline}
      areAllPartnersWorkspaces={areAllPartnersWorkspaces}
      myOrganizationName={myOrganizationName}
      partialBatchUpdate={partialBatchUpdate}
      setFieldValue={setFieldValue}
      programId={programId}
      programOwnerId={programRequest.ownerOrganization.id}
      hasProgramPartners={programPartners.length > 0}
      isAssignedInternally={isAssignedInternally}
      disabled={hasSubmitted}
    />
  );

  return isTalentOrg ? (
    <GigClientAssignmentForm
      isEditing={isEditing}
      hasSubmitted={hasSubmitted}
      inline={inline}
      clientOrgs={programRequest.clientOrganizations}
      setFieldValue={setFieldValue}
    />
  ) : programPartners.length > 0 ? (
    <div style={inline ? { padding: 0 } : undefined} className={inline ? undefined : styles.partnerAssignmentForm}>
      <Text color={inline ? "white" : "gray3"} font="wes" bold size={22} bottom="s">
        {title}
      </Text>
      {programRequest.gigPartnerAssignment.isProgramOwnerOrganization ? (
        <>
          {!areAllPartnersWorkspaces && (
            <Text color={inline ? "white" : "gray2"} size={14} bottom="m">
              Who will be responsible for assignment?
            </Text>
          )}

          {partnerAssignmentForm}
        </>
      ) : (
        <>
          <Text color={inline ? "white" : "gray2"} size={14} bottom="m">
            Responsible for execution
          </Text>

          <div className={styles.responsibleForExecution}>
            <Icon src={lockIcon} fill={colors.gray1} size={18} />

            <Text color={inline ? "white" : "gray1"} size={14} font="wes" bold>
              {executingOrganizationName}
            </Text>
          </div>

          {programRequest.gigPartnerAssignment.canAssign && programRequest.gigPartnerAssignment.hasPartners && (
            <>
              <Text color={inline ? "white" : "gray3"} font="wes" bold size={22} bottom="s">
                Staffing
              </Text>

              <Text color={inline ? "white" : "gray2"} size={14} bottom="m">
                Who will staff this task?
              </Text>

              {partnerAssignmentForm}
            </>
          )}
        </>
      )}
    </div>
  ) : isEditing ? (
    <div className={inline ? undefined : styles.partnerAssignmentForm}>
      <Text color="pink1" font="wes" bold inline size={16}>
        There are no partners assigned to this program.
      </Text>
    </div>
  ) : (
    <></>
  );
};

export const GigPartnerField: ComposableField<
  GigPartnerFieldFragment,
  GigPartnerValues,
  GigPartnerValues,
  GigProgramValue
> = {
  id: "partner",

  component: ({ data, values: { programId }, setFieldValue, inline, partialBatchUpdate, isSubmitting, isValid }) => {
    if (!programId) {
      return null;
    }

    return (
      <GigPartnerFieldContent
        isEditing={!!data?.id}
        programId={programId}
        setFieldValue={setFieldValue}
        inline={inline}
        partialBatchUpdate={partialBatchUpdate}
        isAssignedInternally={false}
        isSubmitting={isSubmitting}
        isValid={isValid}
      />
    );
  },

  initialize: data => {
    return {
      iAmTalentOrg: false,
      clientOrganizationId: data?.clientOrganization?.id ?? undefined,
      partnerOrganizationId: data?.partnerOrganization?.id ?? undefined,
    };
  },

  finalize: data => {
    const { myOrgId, partnerOrganizationId, clientOrganizationId, iAmTalentOrg } = data;
    return iAmTalentOrg
      ? { clientOrganizationId }
      : { partnerOrganizationId: !!partnerOrganizationId ? partnerOrganizationId : myOrgId };
  },

  validate: ({ iAmTalentOrg, clientOrganizationId, partnerOrganizationId }, { t, partialBatchUpdate, data }) => {
    if (iAmTalentOrg) {
      if (!partialBatchUpdate && !clientOrganizationId) {
        return { clientOrganizationId: t("gigs.fields.partner.pleaseSelectPartner") };
      }
    } else if (partnerOrganizationId === null && !partialBatchUpdate) {
      return { partnerOrganizationId: t(`gigs.fields.partner.pleaseSelect${pickerType}`) };
    } else {
      return {};
    }
  },

  fragment: partnerFragment,

  dependencies: [GigProgramField],
};

export const GigPartnerFormProvider: FormProvider<GigPartnerFormProviderFragment> = {
  provide: ({ data: { partnerOrganization } = { talentOrganization: undefined } }) => {
    if (!partnerOrganization) {
      return [GigPartnerField];
    }

    return [];
  },
  providerFragment: partnerFormProviderFragment,
};
