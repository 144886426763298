import React from "react";
import ContentLoader from "react-content-loader";
import { range } from "lodash";

const rowHeight = 26;

export function SpreadsheetPlaceholder() {
  return (
    <div style={{ overflow: "auto" }}>
      <ContentLoader width={900} height={1400}>
        {range(0, 30).map(index => (
          <Line key={index} y={index * (rowHeight + 1)} />
        ))}
      </ContentLoader>
    </div>
  );
}

function Line({ y }: { y: number }) {
  return (
    <>
      <rect x={0} y={y} width={48} height={rowHeight} />
      <rect x={49} y={y} width={100} height={rowHeight} />
      <rect x={150} y={y} width={300} height={rowHeight} />
      <rect x={451} y={y} width={200} height={rowHeight} />
      <rect x={652} y={y} width={100} height={rowHeight} />
      <rect x={753} y={y} width={70} height={rowHeight} />
      <rect x={824} y={y} width={100} height={rowHeight} />
    </>
  );
}
