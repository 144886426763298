import * as React from "react";
import Text from "components/LegacyText";
import * as FieldValue from "../FieldValue";

export default function AdminInfo() {
  return (
    <React.Fragment>
      <FieldValue.Option name={"payer"} label={"Who's paying"} />
      <FieldValue.Text name={"accountingCode"} label={"Accounting code / PO #"} />

      <FieldValue.Base label={"Billing contact"}>
        {({ billingContact }) => {
          return billingContact ? (
            <React.Fragment key={billingContact.email}>
              {billingContact.firstName} {billingContact.lastName} (
              <Text.NewLink4 href={`mailto:${billingContact.email}`}>{billingContact.email}</Text.NewLink4>) <br />
              {billingContact.company}
              <br />
              {billingContact.address1}
              <br />
              {billingContact.address2}
              <br />
              {billingContact.city}
              {billingContact.city && billingContact.state ? "," : ""} {billingContact.state} {billingContact.zip}
              <br />
              <br />
            </React.Fragment>
          ) : null;
        }}
      </FieldValue.Base>
    </React.Fragment>
  );
}
