import { OrganizationLocation } from "./OrganizationLocation";
import { ProgramExecutionType } from "interfaces/Program";
import { Expense } from "gql-gen";
export type GigReportState = "pending" | "submitted" | "returned" | "verified" | "rejected";
export type WorkflowState =
  | "reportVerificationPending"
  | "reportVerificationReturned"
  | "reportVerificationRejected"
  | "verified"
  | string;

export const FINAL_REPORT_STATES = ["verified", "reportVerificationRejected"];
export type RelevantMaterial =
  | {
    title: string;
    address: string;
  }
  | {
    title: string;
    asset: {
      url: string;
    };
  };

export const GigStates = {
  NOTREADY: "NOTREADY",
  APPLIED: "APPLIED",
  CLIENT_ACCEPTED: "CLIENT_ACCEPTED",
  READY: "READY",
  CHECKEDIN: "CHECKEDIN",
  CHECKEDOUT: "CHECKEDOUT",
};
export interface Option {
  key: string;
  label: string;
  description?: string | null;
}
export interface Document {
  id: string;
  name: string;
  url: string;
}
export interface CheckInOutStatus {
  time: string;
  latitude?: number;
  longitude?: number;
}

type Application = {
  id: string;
  withdrawnAt: Date | null;
  talent: {
    id: string;
  } | null;
};

export interface GigFull {
  id: string;
  workflowStateKey: WorkflowState;
  title: string;
  timezone: string;
  startTime: string;
  endTime: string;
  windowStartTime: string;
  windowEndTime: string;
  windowLocalStartTime: string;
  windowLocalEndTime: string;
  duration?: number;
  notes?: string;
  talent?: {
    id: string;
  };

  manager?: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    unconfirmedPhone?: string;
  };

  updatedAt: string;
  checkinStatus?: CheckInOutStatus;
  checkoutStatus?: CheckInOutStatus;
  expenseNotes?: string;
  applications: Array<Application>;
  myApplication?: Application | null;
  documents: Document[];
  products: Array<{
    id: string;
    category: { label: string };
    name: string;
    description: string;
    sku: string;
    documents: Document[];
  }>;

  program: {
    id: string;
    agency: { id: string; name: string };
    client: { id: string; name: string };
    name: string;
    requirements: string;
    reimbursementNotes: string;
    description: string;
    trainingDocuments: Document[];
    keyMetric: Option | null;
    sampleProductsSource: Option | null;
    trainingType: Option[];
    demoKit: {
      venue: string;
      client: string;
      agency: string;
    } | null;

    executionType: ProgramExecutionType;
    locationDescription?: string;
  };

  location: {
    address: string;
    name: string;
    latitude: number;
    longitude: number;
    externalId: string;
  };

  talentRate: string;
  programRole: {
    title: string;
    uniform?: Option | null;
    duties: string;
    allowExpenses?: boolean;
    role: {
      id: string;
      hasCheckin: boolean;
      hasLocation: boolean;
    };
  };

  organizationLocation: OrganizationLocation;
  reportReviewedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };

  reportReviewMessage: string;
  customLocationName: string;
  workflowStateGroup: string;
  workflowStateSection: string;
  expenses: Expense[];
  talentExpenses: boolean;
}
export interface GigPartial {
  id: string;
  title: string;
  timezone: string;
  startTime: string;
  endTime: string;
  program: {
    name: string;
  };

  location?: {
    address?: string;
  };
}
export type GigApplication = GigPartial & {
  reportingThunk: string;
  state: keyof typeof GigStates;
};
