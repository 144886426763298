import { IAction } from "modules/Connection/types";
import icon from "assets/edit.svg";
import { colors } from "styles/variables";

const i18n = "gigs.actions.update";

export const UpdateAction: IAction = {
  key: "update",
  icon,
  labelKey: `${i18n}.label`,
  color: colors.blue2,
  allowInline: true,
  allowBatch: true,
  type: "modal",
  path: "/+update-gigs/",

  component: () => null,
};
