import * as React from "react";
import styles from "./styles.scss";
import Text from "components/LegacyText";

export interface Props {
  label?: string | null;
  display: string;
  p?: React.ReactNode | null;
  className?: string;
}

export default function LabledDisplay({ label, display, p, className }: Props) {
  return (
    <div className={className}>
      <Text.Label1>{label}</Text.Label1>
      <div className={styles.title}>
        <Text.Display>{display}</Text.Display>
      </div>
      {p && <Text.P2 kind="secondary">{p}</Text.P2>}
    </div>
  );
}
