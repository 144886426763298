@import "~styles/variables";
@import "../styles";

$row-border-radius: 7px;
$row-padding: $xxs;

.rowWrapper {
  padding: $row-padding;
  border-radius: $row-border-radius;
  @include transitionify(background);
}

.row {
  display: flex;
  border-radius: $row-border-radius + $row-padding;
  outline-style: none;

  @include mobile {
    padding: 0;
    flex-direction: row;
    display: flex;
  }

  &:focus {
    box-shadow: 1px 1px 2px #3c4f49;
  }
}

.cell:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  @include mobile {
    border-right: none;
  }
}

.rowWrapper,
.placeholder {
  margin: 0 auto;
  margin-top: $xxs;
  max-width: $desktop + 200px;

  @include mobile {
    margin: $xs $m;
  }
}

.checkboxWrapper {
  border-top-left-radius: $row-border-radius;
  border-bottom-left-radius: $row-border-radius;
  display: flex;
  justify-content: center;
  width: 40px;
  border-right: 1px solid $white;
  position: relative;
  > :first-child {
    position: absolute;
    top: -2px;
    left: -2px;
    background-color: #f3f6fa;
    padding: 4px 5px 5px 2px;
    border-bottom-right-radius: 15px;
    min-width: 10px;
    min-height: 10px;

    @include mobile {
      left: -14px;
    }
  }
}

.compactCheckbox {
  position: absolute;
  height: 36px;
  width: 20px;
  border: unset;
  left: 30px;
  z-index: 1111;
}

.checked {
  @include desktop {
    background: $gray-1;
  }
}

.actions {
  width: $actions-width;
  background-color: #d6dae9;
  border-top-right-radius: $row-border-radius;
  border-bottom-right-radius: $row-border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include mobile {
    background-color: $blue-2;
    min-width: 50px;
    position: absolute;
    right: 17px;
    top: 42px;
    bottom: 12px;
    border-top-right-radius: unset;
    height: 125px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    z-index: 1;
    border-radius: 0;
    background-color: $blue-3;
  }
}

.actionDiv {
  // Just so the action focus looks nice
  height: 1px;
  width: 100%;
  background: $white;
}

.mainAction {
  composes: action;
  flex: 1;

  &:focus {
    border-top-right-radius: $row-border-radius;
  }

  @include mobile {
    &:focus {
      border: none;
    }
  }
}

.allActions {
  composes: action;
  padding: $xs 0;

  &:focus {
    border-bottom-right-radius: $row-border-radius;
  }

  @include mobile {
    flex: 0.4;

    &:focus {
      border: none;
    }
  }
}

.actionsPopup {
  padding: 0;
  min-width: 150px;
  background-color: $gray-1;
}

.actionList {
  padding: 0;
}

.actionItem {
  display: flex;
  align-items: center;
  padding: $s;

  &:not(:last-child) {
    border-bottom: 1px solid white;
  }
}

.actionable:hover,
.actionable:focus,
.inAction {
  outline: 0;
  background: linear-gradient(to right, $gray-1, $blue-2);

  > .row > .actions {
    background: transparent;

    > .action {
      &:focus {
        background-color: transparent;
      }
    }

    @include mobile {
      background: $blue-2;
    }
  }

  @include desktop {
    > :first-child {
      background-color: transparent;

      > * {
        color: $white !important;
      }
    }
  }
}

.highlight {
  @include pulse-animation;
  animation-iteration-count: 5;
}

$cell-radius: $row-border-radius - $row-padding;
.mobileCells {
  width: 100%;
  height: 160px;
  > :first-child {
    display: flex;
    justify-content: space-between;
    height: 35px;
    width: 100%;
    border: none;
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: $cell-radius;
    padding: unset;
    @include mobile {
      padding: 0 $s 0 40px;
    }
    > :nth-child(2) {
      > :nth-child(2) {
        &::before {
          color: $white;
        }
      }
      > :last-child {
        &::before {
          color: $white;
        }
      }
    }
  }

  > :not(:first-child) {
    padding: 0px 13px;
    height: 25px;
    max-width: 300px;
    @include mobile {
      max-width: calc(100% - 50px);
    }
  }

  > :last-child {
    border: none;
    border-radius: 0;
    border-bottom-left-radius: $cell-radius;
  }
}

.actionContent {
  padding: $m;
  background-color: $gray-1;
}

.actionSubmit {
  margin-top: $m;
  width: 100%;
  box-sizing: border-box;
}

.actionErrors {
  margin-left: $m;
}
