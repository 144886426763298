import * as React from "react";
import cx from "classnames";
import { scrollIntoViewIfNeeded } from "utilities/scroll";
import Text from "components/LegacyText";
import ArrowButton from "components/ArrowButton";
import Line from "../Line";
import { withAccordionContext, AccordionContextProps } from "../AccordionContext";
import styles from "./styles.scss";
import { DesktopAndTablet } from "components/Layout/Responsive";

type RenderFunction = () => React.ReactNode;

interface Props {
  id: string;
  title: React.ReactNode;
  header?: React.ReactNode;
  children?: React.ReactNode | RenderFunction;
  expandable?: boolean;
  className?: string | null;
  inactive?: boolean;
  accordionState: AccordionContextProps;
  /** Hides the title and divisor making it look like it's part of the previous section. */
  minimal?: boolean;
}

class AccordionSection extends React.Component<Props> {
  private sectionDiv = React.createRef<HTMLDivElement>();

  public componentDidUpdate(prevProps: Props) {
    const {
      id,
      accordionState: { sections },
    } = this.props;

    if (sections.includes(id) && !prevProps.accordionState.sections.includes(id) && this.sectionDiv.current) {
      scrollIntoViewIfNeeded(this.sectionDiv.current);
    }
  }

  public render() {
    const {
      id,
      children,
      header,
      title,
      className,
      accordionState: { sections, toggle, showHelpersColumn },
      inactive,
      minimal,
    } = this.props;

    let headerView = header;

    if (typeof header === "string") {
      headerView = <Text.Display2 kind={"tertiary"}>{header}</Text.Display2>;
    }

    const expandable = !!children;

    const titleView = (forceDisplay?: boolean) => (
      <Text.Display3 className={forceDisplay ? styles.headerTitle : ""} kind={"secondary"}>
        {minimal ? "" : title}
      </Text.Display3>
    );

    const isOpen = sections.includes(id);

    return (
      <div className={cx(styles.accSection, className)} id={id} ref={this.sectionDiv}>
        <Line
          className={cx(styles.titleAndHeader, minimal && styles.minimalTitleAndHeader, inactive && styles.inactive)}
          inactive={inactive}
        >
          <DesktopAndTablet>
            {desktopOrTablet => (
              <React.Fragment>
                {(showHelpersColumn || !desktopOrTablet) && titleView(true)}

                <div className={styles.headerAndExpander}>
                  {minimal && <div className={styles.minimalHeaderBorder} />}
                  {desktopOrTablet ? (showHelpersColumn ? headerView : titleView()) : null}
                  {expandable && (
                    <ArrowButton direction={isOpen ? "up" : "down"} onClick={event => toggle(id, event)} />
                  )}
                </div>
              </React.Fragment>
            )}
          </DesktopAndTablet>
        </Line>
        {expandable && isOpen && (typeof children === "function" ? (children as RenderFunction)() : children)}
      </div>
    );
  }
}

export default withAccordionContext(AccordionSection);
