import React from "react";
import _ from "lodash";
import Fuse from "fuse.js";

export interface SearchState<T> {
  search: string;
  results: T[] | null;
}

export interface SearchContext<T> extends SearchState<T> {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface SearchConfig {
  keys: string[];
}

export interface SearchWrapperProps<T> extends SearchConfig {
  items: T[];
  children: (context: SearchContext<T>) => React.ReactNode;
}

const SEARCH_DEBOUNCE = 300;

/** @deprecated use: hooks/useFuse */
export default class SearchWrapper<T> extends React.Component<SearchWrapperProps<T>, SearchState<T>> {
  readonly state: SearchState<T> = {
    search: "",
    results: [],
  };

  private fuse?: Fuse<T, {}>;
  private debouncedDoSearch = _.debounce(this.doSearch, SEARCH_DEBOUNCE);

  public componentDidMount() {
    this.updateFuse();
  }

  public componentDidUpdate(prevProps: SearchWrapperProps<T>) {
    if (this.props.items !== prevProps.items) {
      this.updateFuse();
    }
  }

  private updateFuse() {
    this.fuse = new Fuse(this.props.items || [], {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      keys: this.props.keys,
    });

    this.doSearch();
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        search: e.currentTarget.value,
      },

      () => {
        this.debouncedDoSearch();
      },
    );
  };

  private get isSearching(): boolean {
    return this.state.search.length > 0;
  }

  private doSearch() {
    let results = [];

    if (this.isSearching && this.fuse) {
      const { search } = this.state;
      results = this.fuse.search(search);
    } else {
      results = this.props.items;
    }

    this.setState({ results });
  }

  public render() {
    const { search, results } = this.state;

    return this.props.children({
      search,
      results,
      onSearchChange: this.handleChange,
    });
  }
}

export interface WithSearchConfig<P, T> extends SearchConfig {
  mapPropsToItems: (props: P) => T[];
}

export function withSearch<T, P extends SearchContext<T>>({
  mapPropsToItems,
  keys,
}: WithSearchConfig<Pick<P, Exclude<keyof P, keyof SearchContext<T>>>, T>) {
  return (Component: React.ComponentType<P>) => {
    return (props: Pick<P, Exclude<keyof P, keyof SearchContext<T>>>) => (
      <SearchWrapper<T> items={mapPropsToItems(props)} keys={keys}>
        {searchContext => {
          return <Component {...(props as P)} {...searchContext} />;
        }}
      </SearchWrapper>
    );
  };
}
