import v from "styles/variables";

export const SCREEN_WIDTH = Math.max(window.document.documentElement.clientWidth, window.innerWidth || 0);

export const mobileSize = window.matchMedia && window.matchMedia(`(max-width: ${v.tablet})`);

interface Rect {
  top: number;
  right: number;
  left: number;
  bottom: number;
}

export function setRect(element: HTMLElement, rect: Rect, boundaries: Rect) {
  const { innerWidth, innerHeight } = window;

  element.style.top = (rect.top < boundaries.top ? boundaries.top : rect.top) + "px";
  element.style.right = innerWidth - (rect.right > boundaries.right ? boundaries.right : rect.right) + "px";
  element.style.bottom = innerHeight - (rect.bottom > boundaries.bottom ? boundaries.bottom : rect.bottom) + "px";
  element.style.left = (rect.left < boundaries.left ? boundaries.left : rect.left) + "px";
}
