import * as React from "react";
import * as FieldValue from "../FieldValue";
import ProductsTable from "../../../ProductsTable";
import styles from "./styles.scss";

export default function Products() {
  return (
    <React.Fragment>
      <ProductsTable
        atomsFilter="notGroups"
        tableProps={{ selectable: false, limitByProgram: true, className: styles.tableMargin }}
        viewOnly={true}
      />
      <FieldValue.Option name={"sampleProductsSource"} label={"Product mgmt."} />
    </React.Fragment>
  );
}
