import { ApolloError } from "@apollo/client";
import * as React from "react";
import Icon from "components/Icon";
import Text from "components/LegacyText";
import Button from "components/Button";
import pinataLogo from "assets/brand-logo.svg";
import haydayLogo from "assets/logo-hayday.svg";
import variables from "styles/variables";
import styles from "./styles.scss";
import { isPinata } from "utilities/flavor";

interface Props {
  error: ApolloError;
  retry: () => Promise<{}>;
  retryAutomatically?: boolean;
}

interface State {
  retryingIn: number | null;
  retryTimes: number;
}

const RETRY_TIMEOUT = 5;
const MAX_TIMEOUT = 45;

export default class ErrorPage extends React.Component<Props, State> {
  readonly state: State = {
    retryingIn: null,
    retryTimes: 0,
  };
  public interval?: number;

  public UNSAFE_componentWillMount() {
    const { retryAutomatically, retry, error } = this.props;

    if (retryAutomatically && retry) {
      if (error.networkError && !navigator.onLine) {
        window.addEventListener("online", () => this.retry());
      }

      this.startTrying();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("online", () => this.retry());

    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  public retry = (restartTimer?: boolean) => {
    if (restartTimer) {
      this.setState({ retryingIn: 0, retryTimes: 0 });
    }

    if (this.interval) {
      window.clearInterval(this.interval);
    }

    this.props.retry().catch(() => this.startTrying());
  };

  public startTrying() {
    if (this.props.retryAutomatically) {
      const retryTimes = this.state.retryTimes + 1;
      const timeout = RETRY_TIMEOUT * retryTimes;

      this.setState({ retryingIn: timeout > MAX_TIMEOUT ? MAX_TIMEOUT : timeout, retryTimes }, () => {
        this.interval = window.setInterval(() => {
          const retryingIn = this.state.retryingIn || 0;

          if (retryingIn <= 0) {
            this.retry();
          } else {
            this.setState({
              retryingIn: retryingIn - 1,
            });
          }
        }, 1000);
      });
    }
  }

  public render() {
    const { error } = this.props;
    const { retryingIn } = this.state;

    let title = "Oops... Something went wrong";
    let description = "We apologize for the inconvenience. Try refreshing the page.";

    if (error.networkError && !navigator.onLine) {
      title = "You're offline";
      description = "Please get connected to the Internet, we'll wait here :)";
    }

    let retrying = null;

    if (retryingIn !== null) {
      if (retryingIn === 0) {
        retrying = <Text.P3 kind={"reverse"}>Retrying...</Text.P3>;
      } else {
        retrying = <Text.P3 kind={"reverse"}>Retrying in {retryingIn}s</Text.P3>;
      }
    }

    return (
      <div className={styles.errorPage}>
        <div className={styles.content}>
          <div className={isPinata ? styles.pinataLogo : styles.haydayLogo}>
            <Icon
              src={isPinata ? pinataLogo : haydayLogo}
              fill={isPinata ? variables.pink1 : variables.haydayLogo}
              width={150}
            />
          </div>

          <Text.Display2 kind={"reverse"}>{title}</Text.Display2>
          <Text.P2 kind={"reverse"} className={styles.description}>
            {description}
          </Text.P2>

          <div className={styles.retry}>
            <Button kind={"translucent"} className={styles.button} onClick={() => this.retry(true)}>
              Retry Now
            </Button>
            {retrying}
          </div>
        </div>
      </div>
    );
  }
}
