import React, { useEffect, useRef, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import { get } from "lodash";
import styles from "./styles.scss";

const MAIN_CONTAINER = "portalContainer";

function createRootElement(id: string): HTMLDivElement {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
}

function addRootElement(rootElem: HTMLDivElement) {
  document.body.insertBefore(rootElem, get(document, "body.lastElementChild.nextElementSibling"));
}

const onClickHandler = (e: MouseEvent, onClick: () => void) => {
  const target = e.target as HTMLDivElement;
  if (target.classList[0] && target.classList[0].includes(MAIN_CONTAINER)) {
    onClick();
  }
};

function usePortal(id: string, onClick: () => void, className?: string) {
  const el = document.createElement("div");
  el.className = `${styles[MAIN_CONTAINER]} ${className}`;
  const rootElemRef = useRef<HTMLDivElement>(el);
  el.addEventListener("click", e => onClickHandler(e, onClick));

  useLayoutEffect(() => {
    const existingParent: HTMLDivElement | null = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    // The modal component also gets rid of the scrollbar.
    // We don't need to deal with that if a modal is open.
    const hideScrollbar = !document.body.className.includes("ReactModal__Body--open");

    if (hideScrollbar) {
      const spy = document.createElement("div");
      spy.style.width = "1px";
      spy.style.cssFloat = "right";
      spy.style.visibility = "hidden";
      document.body.appendChild(spy);

      const posBeforeHiding = spy.offsetLeft;
      document.body.style.overflow = "hidden";
      const scrollbarWidth = spy.offsetLeft - posBeforeHiding + "px";
      document.documentElement.style.setProperty("--fake-scrollbar", scrollbarWidth);

      document.body.style.marginRight = scrollbarWidth;
      document.body.removeChild(spy);
    }

    if (!existingParent) {
      addRootElement(parentElem);
    }

    parentElem.appendChild(rootElemRef.current);

    // unmount
    return () => {
      if (hideScrollbar) {
        document.body.style.overflow = "auto";
        document.body.style.marginRight = "0px";
        document.documentElement.style.setProperty("--fake-scrollbar", "0px");
      }

      rootElemRef.current.remove();
      el.removeEventListener("click", e => onClickHandler(e, onClick));
      parentElem.remove();
    };
  }, []);

  return rootElemRef.current;
}

interface Props {
  id: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export default function Portal({ id, onClick, children, className }: Props) {
  const target = usePortal(id, onClick || (() => null), className);
  return createPortal(children, target);
}
