import React, { useEffect, useState } from "react";
import cx from "classnames";
import { format } from "date-fns";
import { getGoogleMapsUrl } from "modules/Dashboard/Schema/Gigs/locations";
import Pill from "components/Pill";
import Text from "components/Text";
import { ObjectConfig, FieldConfig } from "../fields";
import { SheetMap } from "../sources";
import { UploaderWorker } from "../Worker";
import { MapResult, MapResultField } from "../Worker/map";
import styles from "./styles.scss";
import ProgressCircle from "components/ProgressCircle";
import { useTranslation } from "react-i18next";
import { SheetState, UploadState } from "modules/Uploader/state";
import Button from "components/Button";

interface Props {
  ready: boolean;
  activeSheet: SheetState;
  headerIndex: number;
  objectConfig: ObjectConfig;
  sheetMap: SheetMap;
  worker: UploaderWorker | null;
  progress: null | UploadState;
  onDone: () => void;
  onDownloadFailedRows: () => void;
}

export const Preview = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();
  const {
    ready,
    activeSheet,
    objectConfig,
    sheetMap,
    headerIndex,
    worker,
    progress,
    onDone,
    onDownloadFailedRows,
  } = props;
  const [mapResult, setMapResult] = useState<MapResult>();

  useEffect(() => {
    if (worker && ready) {
      worker
        .map({
          sheet: activeSheet.name,
          header: headerIndex,
          fields: objectConfig.worker,
          map: sheetMap,
          preview: true,
        })
        .then(setMapResult)
        .catch(console.error);
    } else if (!ready) {
      if (mapResult) setMapResult(undefined);
    }
  }, [sheetMap, objectConfig, headerIndex, worker, ready]);

  const done = progress?.done || false;

  // Default to 1 to account for the header (which is subtracted below)
  const count = activeSheet?.rowCount ?? 1;

  const errors = progress?.erroredRows.count && activeSheet.rowCount;

  return (
    <div className={cx(styles.tableWrapper, progress !== null && styles.importing)} ref={ref}>
      <table className={styles.table}>
        <Rows objectConfig={objectConfig} headerIndex={headerIndex} mapResult={mapResult} />
      </table>
      {progress !== null && (
        <div className={styles.importIndicator}>
          <ProgressCircle.Animated
            value={activeSheet.rowCount && progress.processed ? (progress.processed * 100) / count : "indeterminate"}
            size={100}
          />
          <Text color="white" size={18} top="m" font="wes">
            {done ? t("importer.finished") : t("importer.uploading")}
          </Text>

          {activeSheet.rowCount && progress.processed ? (
            <Text color="white" size={16} top="m" bottom="s" font="monospace">
              {// processed and rowCount include the header
                t("importer.progress", { rowCount: count - 1, rowsProcessed: progress.processed - 1 })}
            </Text>
          ) : null}

          {progress.erroredRows.count ? (
            <Text color="pink2" size={16} font="monospace" bottom="l">
              {t("importer.erroredRows", {
                // Errors do not count the header but processed does
                failedCount: progress.erroredRows.count,
                rowsProcessed: progress.processed - 1,
              })}
            </Text>
          ) : null}

          {done && (
            <div className={styles.doneContainer}>
              <div className={styles.buttons}>
                <Button kind="primaryGradient" onClick={onDone}>
                  {t("importer.done")}
                </Button>

                {errors ? (
                  <Button kind="redGradient" onClick={onDownloadFailedRows}>
                    {t("importer.downloadFailed")}
                  </Button>
                ) : null}
              </div>
              {errors ? (
                <Text
                  className={styles.downloadFailedExplanation}
                  color="white"
                  size={14}
                  top="m"
                  bottom="s"
                  font="monospace"
                >
                  {t("importer.downloadFailedExplain")}
                </Text>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

const Rows = React.memo(
  ({
    objectConfig,
    mapResult,
    headerIndex,
  }: Pick<Props, "objectConfig" | "headerIndex"> & { mapResult?: MapResult }) => {
    let rows = [];

    const fields = Object.entries(objectConfig.ui);

    const ly = mapResult ? mapResult.length : 60;

    for (let y = 0; y < ly; y++) {
      let cells = [];
      const row = mapResult && mapResult[y];

      const beforeHeader = y < headerIndex;

      for (const [id, field] of fields) {
        cells.push(
          <ResultCell
            key={id}
            field={field}
            beforeHeader={beforeHeader}
            resultField={row && row.fields && row.fields[id]}
          />,
        );
      }

      rows.push(
        <tr key={y} className={beforeHeader ? styles.beforeHeader : undefined}>
          <td>{y + 1}</td>
          {cells}
        </tr>,
      );
    }

    return (
      <tbody>
        <tr>
          <th />
          {fields.map(([id, field]) => (
            <th key={id}>{field.label}</th>
          ))}

          <th />
          <th />
          <th />
        </tr>
        {rows}
      </tbody>
    );
  },
);

function ResultCell({
  field,
  resultField,
  beforeHeader,
}: {
  field: FieldConfig;
  beforeHeader: boolean;
  resultField?: MapResultField;
}) {
  const { value, error, mismatches } = resultField || { value: null, error: null, mismatches: [] as string[] };

  let view = value;
  let className;

  if (beforeHeader) {
    view = "";
  } else if (error && (error !== "missing" || field.required)) {
    view = value;
    className = field.required ? styles.error : styles.warn;
  } else if (field.type === "ref" && (value || mismatches.length > 0)) {
    if (value) {
      view = value.map((item: any) => (
        <Pill key={item.id} className={styles.pill}>
          {field.renderName(item)}
        </Pill>
      ));
    }

    if (field.collectMismatches) {
      view = (
        <>
          {view}
          {mismatches.map(m => (
            <Pill key={m} className={styles.mismatchPill}>
              {m}
            </Pill>
          ))}
        </>
      );
    }
  } else if (value) {
    if (field.type === "date") {
      view = format(value, "M/dd/yyyy");
    } else if (field.type === "time") {
      view = format(value, "h:mma");
    } else if (field.type === "location") {
      view = value ? (
        <a
          href={getGoogleMapsUrl(value.place_id, value.name, value.formatted_address)}
          target={"_blank"}
          title={value.formatted_address}
        >
          {value.name || value.formatted_address}
        </a>
      ) : (
        ""
      );
    }
  } else {
    view = "-";
    className = styles.leaveBlank;
  }

  return (
    <td className={className} data-src-cell={resultField && resultField.cells.join(",")}>
      {view}
    </td>
  );
}
