import { flowRight as compose } from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";
import cx from "classnames";
import { Link } from "react-router-dom";
import { FieldProps } from "formik";
import { LocationFinderQuery, TagGroup, Location } from "gql-gen";

import HorizontalGroup from "components/Form/HorizontalGroup";
import TextBox from "components/Form/TextBox";
import Button from "components/Button";
import Text from "components/Text";
import Table from "components/Table";
import Row from "components/Table/Row";
import Cell from "components/Table/Cell";
import Geosuggest from "components/GeoSuggest";
import TableFilters from "components/TableFilters";
import TagFilter from "components/TagFilter";
import { withCollection, CollectionContext } from "components/ServerCollection";
import BaseFormElement from "components/Form/BaseFormElement";
import { LocationSuggestion } from "modules/Dashboard/Organization/LegacySettings/LocationFormModal";
import { stringifyQuery, addQueries } from "utilities/routes";
import { getGoogleMapsUrl } from "modules/Dashboard/Schema/Gigs/locations";
import LoadingContainer from "components/LoadingContainer";
import { Mobile } from "components/Layout/Responsive";
import Pill from "components/Pill";
import { OrganizationRouteParams } from "modules/Dashboard/Organization";
import { toBase64 } from "utilities/base64";

import locationsQuery from "./locations.gql";

import styles from "./styles.scss";

import LocationCard from "./LocationCard";
import variables from "styles/variables";

import { getRustBackendUrl } from "utilities/getServerRootUrl";
import { useRequest } from "hooks/useRequest";
import { createAdHocLocation, createLocationForOrgLocation, RustLocation } from "utilities/location";

const rustendUrl = `${getRustBackendUrl()}/api/v1`;

const COLUMNS = [
  { label: "Saved Location Name", size: 60 },
  { label: "ID", size: 20 },
  { label: "", size: 15 },
  { label: "Name" },
  { label: "Address" },
  { label: "ID" },
  { label: "Owner" },
  { label: "Contact" },
  { label: "Contact Phone" },
  { label: "Tags" },
  { label: "Products" },
  { label: "Scheduling" },
  { label: "Other Notes" },
];

const ACTIONS = [
  {
    name: "editLocation",
    description: "Edit",
    color: variables.teal1,
    // multiple: true,
  },
];

type FinderLocation = LocationFinderQuery["organizationLocations"]["edges"][0]["node"];

interface Props extends FieldProps {
  collection: CollectionContext<FinderLocation>;
  inline?: boolean;
}

function LocationFinder(props: Props) {
  const {
    collection: { loading, pageItems, filters, onFilterChange, pagination, resetFilters, totalCount, refetch },
    form: {
      values: { location, orgLocationId },
    },
    inline,
  } = props;

  const { t } = useTranslation();

  const {
    history,
    location: routerLocation,
    match: {
      url,
      params: { orgId },
    },
  } = useRouter<OrganizationRouteParams>();

  const [findNew, setFindNew] = useState(false);
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  const { loading: loadingRLocation, request } = useRequest<RustLocation>();

  useEffect(() => {
    if (!loading) {
      setAlreadyLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    if (totalCount === 0) {
      setFindNew(true);
    }
  }, [alreadyLoaded]);

  function productsCell(products: FinderLocation["products"]) {
    return (
      <div className={styles.productsCell}>
        {products &&
          products.map(p => (
            <Link
              key={p.id}
              to={{ ...routerLocation, pathname: url + `/+edit-products/${p.id}` }}
              className={styles.url}
            >
              {p.name}
              {p.sku ? ` (${p.sku})` : ""}
            </Link>
          ))}
      </div>
    );
  }

  function handleGeosuggestChanged(value: string) {
    if (value.replace(/\s/g, "") === "") {
      props.form.setFieldValue("location", null);
    }
  }

  async function handleLocationChanged(suggestion: LocationSuggestion) {
    if (suggestion) {
      const { placeId, location: coords, gmaps, label } = suggestion;
      const res = await createAdHocLocation(
        {
          place_id: placeId,
          name: label,
          address_components: gmaps.address_components,
          formatted_address: gmaps.formatted_address,
          geometry: {
            location: {
              lat: coords.lat,
              lng: coords.lng,
            },
          },
        },
        orgId,
      );
      compose(setNewLocation, rLocationToLocation(null, label))(res);
    }
  }

  function resetSearch() {
    props.form.setFieldValue("orgLocationId", null);
    props.form.setFieldValue("location", null);
    props.form.setFieldValue("locationName", "");
    resetFilters();
    refetch();
  }

  if (!alreadyLoaded) {
    return (
      <LoadingContainer message={t(`components.locationFinder.loadingLocations`)} className={styles.loadingContainer} />
    );
  }

  function findNewToggle() {
    if (!findNew) {
      props.form.setFieldValue("locationName", "");
      resetSearch();
    }
    setFindNew(!findNew);
  }

  function setNewLocation({ location, id, name }: { location: Location; name: string; id: string | null }) {
    props.form.setFieldValue("location", location);
    props.form.setFieldValue("orgLocationId", id);
    props.form.setFieldValue("locationName", name);
  }

  function rLocationToLocation(id: string | null, name: string) {
    return function(rLocation: RustLocation) {
      return {
        location: {
          address: rLocation.address,
          addressJson: rLocation.addressComponents,
          externalId: rLocation.externalId,
          id: rLocation.uuid,
          name: rLocation.name,
          latitude: rLocation.latLng?.lat,
          longitude: rLocation.latLng?.lng,
        },
        id,
        name,
      };
    };
  }

  async function getAndSetExistingLocationDetails({ id, name }: FinderLocation) {
    await request(
      `${rustendUrl}/organizations/${orgId}/locations/${id}`,
      { method: "GET" },
      orgId,
      compose(setNewLocation, rLocationToLocation(id, name)),
    );
  }

  async function getOrgLocationDetails(orgLocation: FinderLocation) {
    if (orgLocation?.location?.id) {
      await getAndSetExistingLocationDetails(orgLocation);
    } else {
      const place = await createLocationForOrgLocation(orgLocation, orgId);
      compose(setNewLocation, rLocationToLocation(orgLocation.id, orgLocation.name))(place);
    }
  }

  return (
    <Mobile>
      {isMobile => {
        return (
          <>
            {!location && (
              <>
                <div className={styles.filterHeader}>
                  <Text color="teal1" font="wes" size={18}>
                    {findNew
                      ? t("components.locationFinder.findNewLocation")
                      : t("components.locationFinder.searchMyLocationDatabase")}
                  </Text>
                  <div onClick={findNewToggle}>
                    <Text font="wes" size={12} color="blue2" className={styles.findNew} noSelect>
                      {findNew
                        ? t(`components.locationFinder.findInDatabase`)
                        : `or ${t(`components.locationFinder.findNew`)}`}
                    </Text>
                  </div>
                </div>
                {findNew ? (
                  <BaseFormElement
                    label={t(`components.locationFinder.findNewLocation`)}
                    inputClassNameProp="inputClassName"
                    className={cx(styles.tableFilters)}
                    element={
                      <Geosuggest
                        className={cx(styles.geosuggest, styles.geosuggestText)}
                        initialValue={""}
                        placeholder="Type a name or address and choose from list..."
                        country={["us", "ca"]}
                        autoActivateFirstSuggest={true}
                        onChange={handleGeosuggestChanged}
                        onSuggestSelect={handleLocationChanged}
                      />
                    }
                  />
                ) : (
                  <TableFilters filterOnChange className={styles.tableFilters}>
                    <div className={styles.filters}>
                      <HorizontalGroup>
                        <TextBox
                          label={"SEARCH"}
                          placeholder={"Name, address or ID"}
                          onChange={onFilterChange("search")}
                          value={filters.search || ""}
                        />
                        <TagFilter
                          group={TagGroup.Locations}
                          onChange={onFilterChange("tagIds")}
                          value={filters.tagIds || []}
                        />
                      </HorizontalGroup>
                    </div>
                  </TableFilters>
                )}
              </>
            )}
            <div onClick={resetSearch} className={styles.resetButton}>
              <Text font="wes" size={12} color="blue2" className={styles.reset} noSelect>
                {location ? t(`components.locationFinder.clearSelection`) : t(`components.filters.resetAll`)}
              </Text>
            </div>
            {!location && !findNew && (
              <>
                <Table
                  visibleColumns={3}
                  columns={COLUMNS}
                  rowHeight="small"
                  actions={ACTIONS}
                  onAction={(action, ids) => {
                    history.push({
                      ...location,
                      pathname: `/${orgId || ""}/settings/locations/+edit-location/${ids.toArray()[0]}`,
                      search: stringifyQuery({
                        continue: addQueries(url, {
                          values: toBase64(JSON.stringify({ ...props.form.values })),
                        }),
                      }),
                    });
                  }}
                  className={styles.table}
                  controlledPagination={pagination}
                  headerClassName={styles.tableHeader}
                  emptyMessage={t("components.locationFinder.empty")}
                >
                  {pageItems
                    ? pageItems.map(orgLocation => {
                      const {
                        id,
                        name,
                        location,
                        internalIdentifier,
                        owner,
                        contacts,
                        products,
                        otherNotes,
                        tags,
                        schedulingNotes,
                        displayAddress,
                      } = orgLocation;
                      return (
                        <Row key={id} id={id} className={cx(isMobile && styles.mobileRow)}>
                          <Cell size={60}>
                            <Text color="gray1" bold size={14}>
                              {name}
                            </Text>
                            {displayAddress && (
                              <a href={getGoogleMapsUrl(undefined, name, displayAddress)} target={"_blank"}>
                                <Text color="blue2" bold em size={12} underline="hover">
                                  {displayAddress}
                                </Text>
                              </a>
                            )}
                          </Cell>
                          <Cell size={isMobile ? 10 : 20}>
                            <Text size={14}>{internalIdentifier}</Text>
                          </Cell>
                          <Cell size={isMobile ? 30 : 15}>
                            <Button
                              kind={"primaryGradient"}
                              noPadding
                              onClick={() => getOrgLocationDetails(orgLocation)}
                            >
                              {loadingRLocation ? "..." : "SELECT"}
                            </Button>
                          </Cell>
                          <Cell>
                            <Text>{name}</Text>
                          </Cell>
                          <Cell>
                            <Text>{displayAddress}</Text>
                          </Cell>
                          <Cell>
                            <Text>{internalIdentifier}</Text>
                          </Cell>
                          <Cell>
                            <Text>{owner}</Text>
                          </Cell>
                          <Cell>
                            <Text>{contacts?.[0]?.name}</Text>
                          </Cell>
                          <Cell>
                            <Text>{contacts?.[0]?.phone}</Text>
                          </Cell>
                          <Cell>
                            {tags?.length && (
                              <div>
                                {tags.length === 0 ? "" : tags.map(tag => <Pill key={tag?.id}>{tag?.name}</Pill>)}
                              </div>
                            )}
                          </Cell>
                          <Cell>
                            <Text>{productsCell(products)}</Text>
                          </Cell>
                          <Cell>
                            <Text>{schedulingNotes}</Text>
                          </Cell>
                          <Cell>
                            <Text>{otherNotes}</Text>
                          </Cell>
                        </Row>
                      );
                    })
                    : []}
                </Table>
                <div onClick={findNewToggle}>
                  <Text font="wes" size={12} color="blue2" className={styles.findNew} noSelect>
                    {findNew
                      ? t(`components.locationFinder.findInDatabase`)
                      : `or ${t(`components.locationFinder.findNew`)}`}
                  </Text>
                </div>
              </>
            )}
            {location && (
              <LocationCard
                googleLocation={location}
                orgLocationId={orgLocationId}
                className={styles.locationCard}
                getFormValues={() => ({ ...props.form.values })}
                inline={inline}
              />
            )}
          </>
        );
      }}
    </Mobile>
  );
}

export default compose(
  withCollection({
    query: locationsQuery,
    name: "organizationLocations",
    filters: {
      search: { type: "string", default: "" },
      tagIds: { type: "array", default: [] },
    },
    filterAutomatically: true,
  }),
)(LocationFinder);
