import React, { ReactNode, MouseEventHandler } from "react";
import cx from "classnames";
import Text, { TextProps } from "components/Text";
import styles from "./styles.scss";
import { Item, CellProps } from "../types";
import Icon from "components/Icon";
import { TableMode } from "../Table";
import Clickable, { ClickableProps } from "components/Clickable";

interface Props<T> extends CellProps<T> {
  children: (mode: TableMode) => ReactNode;
  dark?: boolean;
  className?: string;
  detailedSide?: () => ReactNode;
  index?: number;
}

export function CellBase<T extends Item>({
  em,
  cell: { icon },
  children,
  dark,
  className,
  mode,
  detailedSide,
  index,
}: Props<T>) {
  return (
    <>
      {mode !== "regular" && icon && index !== 0 && <Icon src={icon} size={20} fill={dark ? "white" : "gray-1"} />}

      <div className={cx(styles.cellContent, className, styles[mode], dark && styles.dark, em && styles.em)}>
        {children(mode)}
      </div>

      {mode === "detailed" && detailedSide && detailedSide()}
    </>
  );
}

export function CRNone(props: TextProps) {
  return <Text font="wes" color="pink1" {...props} />;
}

export function CR1(props: TextProps) {
  return <Text {...props} lineHeight={16} className={styles.cr1} ellipsis />;
}

export function CR2(props: TextProps) {
  return <Text color="gray3" ellipsis lineHeight={16} {...props} className={styles.cr2} />;
}

export function CR3(props: TextProps) {
  return <Text color="gray3" size={10} lineHeight={16} ellipsis {...props} />;
}

export function AddDetails({ onClick, ...props }: TextProps & Pick<ClickableProps, "onClick">) {
  return (
    <Clickable onClick={onClick}>
      <Text {...props} font="wes" color="blue2" underline="hover" />
    </Clickable>
  );
}
