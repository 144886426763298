import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { IAction } from "modules/Connection/types";
// FIXME: Find authorize payment icon
import checkIcon from "assets/check.svg";
import { colors } from "styles/variables";
import { ActionBody } from "modules/Connection/ActionBody";
import authorizePayment from "./authorizePayment.gql";
import RadioGroup from "components/RadioGroup";
import RadioButton from "components/RadioButton";
import useRouter from "use-react-router";
import { ProgramsRouteParams } from "modules/Dashboard/Organization/Programs";
import { getProgramIdFromSearch } from "utilities/routes";

const i18n = "gigs.actions.authorizePayment";

export const AuthorizePayment: IAction = {
  key: "paymentAuthorization",
  icon: checkIcon,
  labelKey: `${i18n}.label`,
  color: colors.teal1,
  allowInline: true,
  allowBatch: true,

  component: ({ variables, done, renderSubmit }) => {
    const { t } = useTranslation();
    const [mutate] = useMutation(authorizePayment);
    const { location } = useRouter<ProgramsRouteParams>();
    const id = getProgramIdFromSearch(location.search);

    return (
      <Formik
        initialValues={{ willPay: "yes" }}
        onSubmit={async values => {
          await mutate({
            variables: {
              ...variables,
              programIds: id === "all" ? [] : id.split(","),
              willPay: values.willPay === "yes",
            },
          });
          done();
        }}
      >
        {formik => {
          return (
            <ActionBody done={done}>
              <form onSubmit={formik.handleSubmit}>
                <RadioGroup
                  reverse
                  value={formik.values.willPay}
                  onChange={value => formik.setFieldValue("willPay", value)}
                >
                  <RadioButton value="yes">{t(`${i18n}.label`)}</RadioButton>
                  <RadioButton value="no">{t(`${i18n}.willNotPay`)}</RadioButton>
                </RadioGroup>

                {renderSubmit({
                  ...formik,
                  i18n,
                })}
              </form>
            </ActionBody>
          );
        }}
      </Formik>
    );
  },
};
