import React, { useEffect } from "react";
import Icon from "components/Icon";
import Text from "components/Text";
import Clickable from "components/Clickable";
import chevronIcon from "assets/chevron.svg";
import { colors } from "styles/variables";
import styles from "./styles.scss";
import cx from "classnames";
import { TableMode } from "modules/Connection/Table";
import closeIcon from "assets/cancel-nobg.svg";

interface TabConfig<K> {
  key: K;
  label: string;
  component: React.ComponentType<any>;
}
interface Props<K> {
  tabsConfig: TabConfig<K>[];
  tabProps: any;
  activeTab?: K | null;
  onSelect: (key: K) => void;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  mode: TableMode;
  onClear: () => void;
  onClose: () => void;
}

export default function TabbedContent<K>(props: Props<K>) {
  const { children, tabsConfig, footer, activeTab, onSelect, tabProps, mode, onClear, onClose } = props;
  const activeTabComponent = activeTab && tabsConfig.find(tab => tab.key === activeTab);

  const isCompact = mode === "compact";

  useEffect(() => {
    if (!activeTab) {
      onSelect(tabsConfig[0].key);
    }
  }, [activeTab === null]);

  return (
    <div>
      <div className={cx(styles.container, isCompact && styles.compactContainer)}>
        <div
          className={cx(styles.navigation, isCompact && styles.compactNavigation)}
          style={{ justifyContent: "space-between" }}
        >
          {!isCompact && (
            <Clickable onClick={onClose} className={styles.close}>
              <Icon src={closeIcon} fill={"white"} size={22} />
            </Clickable>
          )}
          <div className={cx(styles.tabs, isCompact && styles.compactTabs)}>
            {tabsConfig.map(tab => {
              const { label, key } = tab;
              const isActive = !!(activeTab && activeTab === key);

              return (
                <Clickable
                  onClick={() => onSelect(key)}
                  className={cx(isActive ? styles.activeTab : styles.tab, isCompact && styles.compactTab)}
                  key={String(key)}
                  testId={`filters.tabbedContent.${key}`}
                >
                  <Text
                    font={"wes"}
                    size={18}
                    bold
                    weight={isActive ? "bolder" : "lighter"}
                    color={isActive ? "teal1" : "white"}
                    noSelect
                  >
                    {label}
                  </Text>
                  {/* <span className={isActive ? styles.chevronVisible : styles.chevron}>
                    <Icon src={chevronIcon} size={10} rotate={-90} fill={colors.teal1} />
                  </span> */}
                </Clickable>
              );
            })}
          </div>
          <Clickable onClick={onClear} className={cx(styles.clearButton, isCompact && styles.compactClearButton)}>
            <Text color="white" underline="always">
              Clear
            </Text>
          </Clickable>
        </div>

        <div className={styles.body}>
          {activeTabComponent && React.createElement(activeTabComponent.component, tabProps, mode)}
          {children}
        </div>
      </div>

      <div className={styles.footer}>{footer}</div>
    </div>
  );
}
